import { offerConstants } from "../../../../constants/Offer/offer.constants";

const initialState = {
    fetching   : false,
    offerPrice : [],
    storeOfferStatus:false,
    errorStatus:false,
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offerConstants.GET_OFFER_PRICE_PENDING:
            return {
                ...state,
                fetching:true,
                storeOfferStatus : false,
                errorStatus : false,
                offerPrice: [],
            };
        case offerConstants.GET_OFFER_PRICE_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerPrice: action.payload,
                storeOfferStatus : false,
                errorStatus : false
            };
        case offerConstants.GET_OFFER_PRICE_REJECTED:
            return {
                ...state,
                fetching : false,
                error    :{
                    message: action.payload
                },
                errorStatus : false,
                storeOfferStatus : false
            };
        case offerConstants.STORE_OFFER_PRICE_PENDING:
            return {
                ...state,
                storeOfferStatus : false,
                offerPrice: [],
                errorStatus : false
            };
        case offerConstants.STORE_OFFER_PRICE_FULFILLED:
            return {
                ...state,
                fetching : false,
                storeOfferStatus : action.payload,
                offerPrice: [],
                errorStatus : false
            };
        case offerConstants.STORE_OFFER_PRICE_REJECTED:
            return {
                ...state,
                fetching : false,
                offerPrice: [],
                errorStatus : action.payload
            }
        default:
            return state
    }
}
