import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function VisitorsCalendar ({guestDemographic}) {
    const { t } = useTranslation();
    const genderKey = guestDemographic && guestDemographic?.gender && Object.values(guestDemographic?.gender).map(data => {
        return data.title
    });
    const genderCount = guestDemographic && guestDemographic?.gender && Object.values(guestDemographic?.gender).map(data => {
        return data.percentage
    });
    const citizenCount = guestDemographic && guestDemographic?.citizen && Object.values(guestDemographic?.citizen).map(data => {
        return data.percentage
    });
    const citizenKey = guestDemographic && guestDemographic?.citizen && Object.values(guestDemographic?.citizen).map(data => {
        return t(data.language_key)
    });

    const ageCount = guestDemographic && guestDemographic?.age && Object.values(guestDemographic?.age).map(data => {
        return data.percentage
    });

    const ageKey = guestDemographic && guestDemographic?.age && Object.values(guestDemographic?.age).map(data => {
        return data.title
    });

    const citizenChart ={
        labels: citizenKey,
        datasets: [
            {
                label: t("enw_charts-citizen-count"),
                data: citizenCount,
                backgroundColor: [
                    'rgba(217, 237, 146, 1)',
                    'rgba(181, 228, 140, 1)',
                    'rgba(153, 217, 140, 1)',
                    'rgba(118, 200, 147, 1)',
                    'rgba(82, 182, 154, 1)',
                    'rgba(52, 160, 164, 1)',
                    'rgba(22, 138, 173, 1)',
                    'rgba(26, 117, 159, 1)',
                    'rgba(30, 96, 145, 1)',
                    'rgba(24, 78, 119, 1)',
                ],
                borderColor: [
                    'rgba(217, 237, 146, 1)',
                    'rgba(181, 228, 140, 1)',
                    'rgba(153, 217, 140, 1)',
                    'rgba(118, 200, 147, 1)',
                    'rgba(82, 182, 154, 1)',
                    'rgba(52, 160, 164, 1)',
                    'rgba(22, 138, 173, 1)',
                    'rgba(26, 117, 159, 1)',
                    'rgba(30, 96, 145, 1)',
                    'rgba(24, 78, 119, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const genderChart = {
        labels: genderKey,
        datasets: [
            {
                label: t("enw_charts-gender-count"),
                data: genderCount,
                backgroundColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(249, 65, 68, 1)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(249, 65, 68, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const ageChart = {
        labels: ageKey,
        datasets: [
            {
                label: t("enw_charts-age-count"),
                data: ageCount,
                backgroundColor: [
                    'rgba(249, 65, 68, 1)',
                    'rgba(243, 114, 44, 1)',
                    'rgba(248, 150, 30, 1)',
                    'rgba(249, 199, 79, 1)',
                    'rgba(144, 190, 109, 1)',
                    'rgba(67, 170, 139, 1)',
                    'rgba(87, 117, 144, 1)',
                ],
                borderColor: [
                    'rgba(249, 65, 68, 1)',
                    'rgba(243, 114, 44, 1)',
                    'rgba(248, 150, 30, 1)',
                    'rgba(249, 199, 79, 1)',
                    'rgba(144, 190, 109, 1)',
                    'rgba(67, 170, 139, 1)',
                    'rgba(87, 117, 144, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid xs={12} sm={4}>
                    <Pie data={genderChart} />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Pie data={citizenChart} />
                </Grid>
                <Grid xs={12} sm={4}>
                    <Pie data={ageChart} />
                </Grid>
            </Grid>
        </>
    );
}
