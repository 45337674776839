import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getChannelSetup, addChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import ChannelSetupForm from "./Components/ChannelSetupForm";
import {NETWORK, NETWORK_CHANNEL_ROOM_RATE_SHORT, NETWORK_CHANNELS} from "../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";

import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import {history} from "../../../../../utils/history";
import {Link} from "react-router-dom";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Grid} from "@material-ui/core";
import {isDefined} from "../../../../../utils";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";

let t =null;

class ChannelSetup extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup( { channel_id : this.props.match.params.id } );
            this.setState({
                channel_id: this.props.match.params.id
            })
        }
        else{
            this.props.history.replace(NETWORK);
        }
    }

    setVisible = () => {
        this.setState({
            isVisible: false
        })
        setTimeout(() => {
            history.push(NETWORK_CHANNELS)
        }, 800);
    }

    errorRedirect = () => {
        setTimeout(() => {
            history.push(NETWORK_CHANNELS)
        }, 2000);
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />

                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelSetupReducers.fetching && this.props.channelInformationData.name + " " + t("enw-channels-channel_setup-title") }
                />
                <ChannelMenu
                    menuClass="channel-menu"
                    infoColClass="active-menu-bg"
                    setupColClass="active-menu-bg"
                    menuId={this.props.channelInformationData.id}
                    channelSetup={this.props.channelSetupReducers}
                    isConnected={this.props.channelInformationData.is_connected}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="channel-setup-container-width">

                                { !this.props.channelSetupReducers.fetching &&
                                    <>
                                        {
                                            isDefined(this.props.channelSetupReducers.error) ?
                                                <>
                                                    <ErrorModal status={true} message={this.props.channelSetupReducers.error.response.data.message}/>
                                                    {this.errorRedirect()}
                                                </>
                                            :
                                                <ChannelSetupForm
                                                    channelSetup={this.props.channelSetupReducers}
                                                    channelInformationData={this.props.channelInformationData}
                                                    addChannelSetup={this.props.addChannelSetup}
                                                />
                                        }

                                    </>
                                }
                            </div>
                    </Container>
                </Animated>
                {
                    isDefined(this.props.channelInformationData.logo) &&
                    <Grid className="channel-logo-bottom">
                        <img src={`${this.props.channelInformationData.logoUrl}`} alt="mini-logo" />
                    </Grid>
                }
                {
                    (this.props.channelInformationData.is_connected || (this.props.channelSetupReducers.channelSetupSubmitData && this.props.channelSetupReducers.channelSetupSubmitData.status === 200)) &&
                    <FooterAtm
                        iconRightClassName="fas fa-arrow-right"
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextText={ t('btn-next') }
                        nextTo={NETWORK_CHANNEL_ROOM_RATE_SHORT + this.props.match.params.id}
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        channelSetupReducers : props.channelSetupReducers,
        channelSetupFilter : props.channelSetupReducers.channelSetupFilter,
        channelInformationData : props.channelSetupReducers.channelInformationData,
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    addChannelSetup
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelSetup));
