import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";

import {withTranslation} from "react-i18next";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import Contact from "./Contact";
import {isDefined} from "../../../../../../utils";

let t=null

class ContactList extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    showMessage = () => {

        if(isDefined(this.props.channelContactUpdateError) && isDefined(this.props.channelContactUpdateError.response) && this.props.channelContactUpdateError?.response?.status !== 200)
        {
            let message = this.props.channelContactUpdateError?.response.data.message
            return  <ErrorModal status={true} message={t(message)}/>
        }
    }

    render() {
        return (
            <div>
                <Contact
                    clearErrorMessage = {this.props.clearErrorMessage}
                    updateChannelContact={this.props.updateChannelContact}
                    deleteChannelContact={this.props.deleteChannelContact}
                    channelContactReducers={this.props.channelContactReducers}
                    getChannelContact={this.props.getChannelContact}
                    channelId={this.props.channelId}
                />
                {
                    this.showMessage()
                }
            </div>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        channelContactUpdateError : props.channelContactReducers?.channelContactUpdateStatusError,
    }
};

export default compose(withTranslation('common'))(connect(mapStateToProps)(ContactList));
