import {propertyRoomAndRateList} from "../../constants/Network/RoomAndRates/roomAndRate.constants";
import {roomDescriptionConstants} from "../../constants/Network/RoomAndRates/roomDescription.constants";

const initialState = {
    propertyRoomRateListFetching : false,
    propertyRoomRateListStatus   : false,
    propertyRoomRateList         : [],
    propertyRoomRateListForRateFetching : false,
    propertyRoomRateListForRateStatus   : false,
    propertyRoomRateListForRate  : [],
    roomAndBedAddFetching        : false,
    roomAndBedAddStatus          : false,
    ratePlanAddFetching          : false,
    ratePlanAddStatus            : true,
    ratePlanAddError             : null,
    factSearchListFetching       : false,
    factSearchListStatus         : false,
    factSearchList               : [],
    propertyRoomTypesFetching    : false,
    propertyRoomTypes            : [],
    roomRatesFetching            : false,
    roomRates                    : [],
    propertyAssignFetching       : false,
    propertyAssignStatus         : false,
    updateFetching               : false,
    accommodationFetching        : false,
    accommodationTypes           : [],
    updateStatus                 : null,
    updateError                  : {},
    errorAssignRate              : {},
    roomAddError                 : {},
    error                        : {},
    descriptionData              : {},
    ratePlanUpdateFetching       : false,
    ratePlanUpdateStatus         : true,
    ratePlanUpdateError          : null,
    getEditRateListFetching      : false,
    getEditRateListError         : {},
    getEditRateList              : [],
    editRateUpdateFetching       : false,
    editRateUpdateError          : null,
    editRateUpdateStatus         : true,
    connectRateFetching          : true,
    connectRateError             : null,
    connectRateData              : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_PENDING:
            return {
                ...state,
                propertyRoomRateListFetching: true,
                propertyRoomRateListStatus: false,
                updateStatus:null,
                ratePlanUpdateStatus:null,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_FULFILLED:
            return {
                ...state,
                propertyRoomRateListFetching: false,
                propertyRoomRateListStatus: true,
                propertyRoomRateList : action.payload,
                roomAndBedAddStatus: false,
                propertyAssignStatus:false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                roomAndBedAddStatus: false,
                propertyAssignStatus: false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_PENDING:
            return {
                ...state,
                propertyRoomRateListForRateFetching: true,
                propertyRoomRateListForRateStatus: false,
                updateStatus:null,
                ratePlanUpdateStatus:null,
                updateError: {},
                ratePlanAddError: null

            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_FULFILLED:
            return {
                ...state,
                propertyRoomRateListForRateFetching: false,
                propertyRoomRateListForRateStatus: true,
                propertyRoomRateListForRate : action.payload,
                roomAndBedAddStatus: false,
                propertyAssignStatus:false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                roomAndBedAddStatus: false,
                propertyAssignStatus: false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_TYPE_PENDING:
            return {
                ...state,
                propertyRoomTypesFetching: true,
                roomAndBedAddStatus: false,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_TYPE_FULFILLED:
            return {
                ...state,
                propertyRoomTypesFetching : false,
                status   : true,
                propertyRoomTypes : action.payload
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_TYPE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_RATE_PENDING:
            return {
                ...state,
                roomRatesFetching: true,
                ratePlanAddStatus:false,
                editRateUpdateStatus:false,
                status: false,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_RATE_FULFILLED:
            return {
                ...state,
                roomRatesFetching: false,
                status: true,
                roomRates : action.payload,
                ratePlanAddStatus:false,
                editRateUpdateStatus:false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_RATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_ADD_PENDING:
            return {
                ...state,
                roomAndBedAddFetching : true,
                roomAndBedAddStatus: false,
                roomAddError:{},
                errorAssignRate:{},
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_ADD_FULFILLED:
            return {
                ...state,
                roomAndBedAddFetching: false,
                roomAndBedAddStatus: true,
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_ADD_REJECTED:
            return {
                ...state,
                error : action.payload,
                roomAndBedAddFetching : false,
                roomAndBedAddStatus : false,
                roomAddError : action.payload
            };
        case propertyRoomAndRateList.PROPERTY_RATE_PLAN_ADD_PENDING:
            return {
                ...state,
                ratePlanAddFetching : true,
                ratePlanAddStatus: false,
                editRateUpdateStatus: false,
                ratePlanAddError:null,
                errorAssignRate:{},
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_RATE_PLAN_ADD_FULFILLED:
            return {
                ...state,
                ratePlanAddFetching: false,
                ratePlanAddStatus: true,
            };
        case propertyRoomAndRateList.PROPERTY_RATE_PLAN_ADD_REJECTED:
            return {
                ...state,
                error: action.payload,
                ratePlanAddFetching: false,
                ratePlanAddStatus: false,
                ratePlanAddError: action.payload
            };
        case propertyRoomAndRateList.PROPERTY_FACT_SEARCH_LIST_PENDING:
            return {
                ...state,
                factSearchListFetching : true,
                factSearchListStatus : false,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_FACT_SEARCH_LIST_FULFILLED:
            return {
                ...state,
                factSearchListFetching: false,
                factSearchListStatus : true,
                factSearchList: action.payload.get_facts
            };
        case propertyRoomAndRateList.PROPERTY_FACT_SEARCH_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                factSearchListStatus: false
            };
        case propertyRoomAndRateList.PROPERTY_ASSIGN_RATE_PENDING:
            return {
                ...state,
                propertyAssignFetching:true,
                propertyAssignStatus:false,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ASSIGN_RATE_FULFILLED:
            return {
                ...state,
                propertyAssignFetching:false,
                propertyAssignStatus:true,
            };
        case propertyRoomAndRateList.PROPERTY_ASSIGN_RATE_REJECTED:
            return {
                ...state,
                errorAssignRate: action.payload,
                propertyAssignStatus: false
            };
        //UPDATE ROOM
        case propertyRoomAndRateList.UPDATE_ROOM_PENDING:
            return {
                ...state,
                updateFetching: true,
                updateError: {}
            };
        case propertyRoomAndRateList.UPDATE_ROOM_FULFILLED:
            return {
                ...state,
                updateFetching: false,
                updateStatus: action.payload.status,
            };
        case propertyRoomAndRateList.UPDATE_ROOM_REJECTED:
            return {
                ...state,
                updateFetching: false,
                updateError : action.payload,
            };
        //GET Accommodation Types
        case propertyRoomAndRateList.GET_ACCOMMODATION_TYPES_PENDING:
            return {
                ...state,
                accommodationFetching: true,
                updateError: {}
            };
        case propertyRoomAndRateList.GET_ACCOMMODATION_TYPES_FULFILLED:
            return {
                ...state,
                accommodationFetching: false,
                accommodationTypes: action.payload,
            };
        case propertyRoomAndRateList.GET_ACCOMMODATION_TYPES_REJECTED:
            return {
                ...state,
                accommodationFetching : false,
                error : action.payload,
            };
        //GET Room Descriptions
        case roomDescriptionConstants.GET_ROOM_DESCRIPTION_PENDING:
            return {
                ...state,
            };
        case roomDescriptionConstants.GET_ROOM_DESCRIPTION_FULFILLED:
            return {
                ...state,
                descriptionData: action.payload,
            };
        case roomDescriptionConstants.GET_ROOM_DESCRIPTION_REJECTED:
            return {
                ...state,
                error : action.payload,
            };
            //UPDATE RATE

        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_UPDATE_PENDING:
            return {
                ...state,
                ratePlanUpdateFetching: true,
                ratePlanUpdateError: null,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_UPDATE_FULFILLED:
            return {
                ...state,
                ratePlanUpdateFetching: false,
                ratePlanUpdateError: null,
                ratePlanUpdateStatus: action.payload.status,
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_UPDATE_REJECTED:
            return {
                ...state,
                ratePlanUpdateFetching: false,
                ratePlanUpdateError : action.payload
            };
        //GET Edit Rate
        case propertyRoomAndRateList.GET_EDIT_RATE_PENDING:
            return {
                ...state,
                getEditRateListFetching: true,
                getEditRateListError   : {},
                getEditRateList        : []
            };
        case propertyRoomAndRateList.GET_EDIT_RATE_FULFILLED:
            return {
                ...state,
                getEditRateListFetching: false,
                getEditRateListError   : {},
                getEditRateList        : action.payload
            };
        case propertyRoomAndRateList.GET_EDIT_RATE_REJECTED:
            return {
                ...state,
                getEditRateListFetching: false,
                getEditRateListError   : action.payload
            };
        //UPDATE Edit Rate
        case propertyRoomAndRateList.PROPERTY_EDIT_RATE_UPDATE_PENDING:
            return {
                ...state,
                editRateUpdateFetching: true,
                editRateUpdateError: null,
                editRateUpdateStatus: false
            };
        case propertyRoomAndRateList.PROPERTY_EDIT_RATE_UPDATE_FULFILLED:
            return {
                ...state,
                editRateUpdateFetching: false,
                editRateUpdateError: null,
                editRateUpdateStatus: true
            };
        case propertyRoomAndRateList.PROPERTY_EDIT_RATE_UPDATE_REJECTED:
            return {
                ...state,
                editRateUpdateFetching: false,
                editRateUpdateStatus: false,
                editRateUpdateError: action.payload
            };
        //Connect Rate
        case propertyRoomAndRateList.CONNECT_RATE_UPDATE_PENDING:
            return {
                ...state,
                connectRateFetching: true,
                connectRateError: null,
                connectRateData: false
            };
        case propertyRoomAndRateList.CONNECT_RATE_UPDATE_FULFILLED:
            return {
                ...state,
                connectRateFetching: false,
                connectRateError: null,
                connectRateData: action.payload
            };
        case propertyRoomAndRateList.CONNECT_RATE_UPDATE_REJECTED:
            return {
                ...state,
                connectRateFetching: false,
                connectRateData: false,
                connectRateError: action.payload
            };
        case propertyRoomAndRateList.CONNECT_RATE_UPDATE_CLEAR_DATA:
            return {
                ...state,
                connectRateFetching: true,
                connectRateError: null,
                connectRateData: {}
            };
        case propertyRoomAndRateList.GET_EDIT_RATE_CLEAR_DATA:
            return {
                ...state,
                getEditRateListError: {},
                getEditRateList: [],
                editRateUpdateError: null
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_UPDATE_CLEAR_DATA:
            return {
                ...state,
                updateError: {}
            };
        case propertyRoomAndRateList.PROPERTY_ROOM_AND_BED_ADD_CLEAR_DATA:
            return {
                ...state,
                roomAddError: {}
            };
        default:
            return state
    }
}
