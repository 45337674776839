import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import FactCheckBox from "../../../../../molecules/FactContent/FactContent";
import {useDispatch,useSelector} from "react-redux";
import {PlaceFactUpdate} from "../../../../../../redux/actions/Content/Places/placesFactAction";

let lastSelectFactId = null;
const PlacesFactCheckBox = ({ fact, placeId, selectedFactCategory, factListId } ) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const placeFactUpdateStatus = useSelector( props => props.getPlaceListReducer?.updateFactStatus )
    const [ isSelected , setIsSelected ] = useState(fact.is_selected);

    const updatePlaceFact = (e) => {
        if (e.target.value === undefined) return 0;
        let param = {};

        if (isSelected){
            param = {
                place_id:placeId,
                place_fact_title_fact_mapping_id: fact.place_fact_title_fact_mapping_id,
                is_selected: false
            }
        }else{
            param = {
                place_id:placeId,
                place_fact_title_fact_mapping_id: fact.place_fact_title_fact_mapping_id,
                is_selected: true
            }
        }
        dispatch(PlaceFactUpdate(param));
        lastSelectFactId = fact.id+"place"+placeId+"fact_mapping_id"+fact.place_fact_title_fact_mapping_id;
    }


    useEffect( () => {
        if (placeFactUpdateStatus === 200 && lastSelectFactId === fact.id+"place"+placeId+"fact_mapping_id"+fact.place_fact_title_fact_mapping_id){
            if (isSelected){
                setIsSelected(false)
            }else{
                setIsSelected(true)
            }
        }
    },[placeFactUpdateStatus])

    return(
        <FactCheckBox
            checkboxId={fact.id+"place"+placeId+"fact_mapping_id"+fact.place_fact_title_fact_mapping_id}
            className={"fact-box"}
            colClassname={selectedFactCategory === factListId ? "offer-fact-col tablet-pb-20" : selectedFactCategory === "all" ? "offer-fact-col tablet-pb-20" : "d-none"}
            blockClass="offer-fact-border-box fact-border-box featured-fact"
            labelIconClass="fact-icon-label"
            iconClass={`offer-fact-icon fact-icon ${fact.icon}`}
            key={fact.id+"place"+placeId+"fact_mapping_id"+fact.place_fact_title_fact_mapping_id}
            sm={4} md={2}
            value={fact.id}
            tooltip={ t(fact.language_key) }
            checked={isSelected}
            factName={ t(fact.language_key) }
            text={ t(fact.language_key) }
            labelClass="fact-name"
            htmlFor={fact.id+"place"+placeId+"fact_mapping_id"+fact.place_fact_title_fact_mapping_id}
            onClick={ (e) => updatePlaceFact(e) }
        />
    )
}

export default PlacesFactCheckBox;
