import React from "react";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import ColAtm from "../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import LabelAtm from "../../../atomic/Label/Label";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {Link} from "react-router-dom";
import {CREATE_OFFER} from "../../../../constants/api.constants";
import {OFFER} from "../../../../constants/appUrls.constants";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

let t=null;
class FirstCreateOffer extends React.Component {
    constructor(props){
        super(props);
        t = props.t;
    }
    render() {
        return (
            <>
                <PageTitle title="Create New Offer - ExtraNetWork" />
                <PageHeaderAtm backButton={OFFER} exitText={ t('btn-dashboard') } text="Create New Offer" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Link to={CREATE_OFFER}>
                        <Container maxWidth="xl">
                            <div className="offer-container-width">
                                <ColAtm>
                                    <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                        <ColAtm>
                                            <div className="request-loader m-auto">
                                            <span>
                                                <i
                                                    className="fas fa-tag"
                                                    style={{margin: 'auto', fontSize: '100px', color: '#0286ff', backgroundColor: '#c4e0fc', borderRadius: '135px', padding: '74px'}}
                                                />
                                            </span>
                                            </div>
                                        </ColAtm>
                                        <LabelAtm labelClass="one-page-title" text="Create your first offer" />
                                        <LabelAtm labelClass="one-page-subtitle" text="Teklif, teklif listesi, iptal şartları gibi birçok kısmı buradan güncellemeye başlayabilirsin." />
                                    </ColAtm>
                                </ColAtm>
                            </div>
                        </Container>
                    </Link>
                </Animated>
            </>
        )
    }
}

export default compose(withTranslation('common')) (FirstCreateOffer);
