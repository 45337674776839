import React, {useEffect} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


import ColAtm from "../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {UPDATE_PLACE_PHOTO} from "../../../../../constants/api.constants";
import {getAppLang, getProperty, getUserToken} from "../../../../../utils";
import PhotoSelect from "../../../../molecules/PhotoSelect/PhotoSelect";

import {AllCheckerCheckbox, CheckboxGroup} from "@createnl/grouped-checkboxes";
import MultiPhotoSelect from "../../../../molecules/MultiPhotoSelect/MultiPhotoSelect";
import {getPlacePhoto} from "../../../../../redux/actions/Content/Places/placesActions"



const PlacePhotoList = props => {

    const placeList               = useSelector(props => props.getPlaceListReducer.placeList);
    const placePhotoList          = useSelector(props => props.getPlacePhotoListReducer.placePhotoList?.get_place_photos);
    const placePhotoListFetching  = useSelector(props => props.getPlacePhotoListReducer.fetching);
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (placeId) => (event, isExpanded) => {
        setExpanded(isExpanded ? placeId : false);
        if(isExpanded !== false){
            dispatch(getPlacePhoto(placeId))
        }
    };

    useEffect( () =>{

        },[placePhotoListFetching])

    const RoomPhotoList = ( placeId ) => {

        if(placePhotoList && placePhotoList.length === 0 ){
            return;
        }
        let roomPhotoListBox = '';
        if( expanded !== false && placePhotoListFetching === false && placePhotoList.show_recommended === true){

            roomPhotoListBox = placePhotoList.map( (photo, key) => (
                    photo.is_recommended === false && (
                        <PhotoSelect
                            checkboxId={photo.id}
                            className="channel-box"
                            colClassname="room-photo-grid"
                            blockClass="photo-select-border-box channel-border-box"
                            src={ photo.photo_url_thump}
                            value={photo.id}
                            defaultChecked={photo.is_selected}
                            onClick={ (e) => updateRoomPhoto(e,placeId)}
                            labelClass="fact-name"
                            htmlFor={photo.id}
                        />
                    )
                )
            );

            return roomPhotoListBox;

        } else if (expanded !== false && placePhotoListFetching === false) {
            roomPhotoListBox = placePhotoList.map( (photo, key) => (
                    <PhotoSelect
                        checkboxId={photo.id}
                        className="channel-box"
                        colClassname="room-photo-grid"
                        blockClass="photo-select-border-box channel-border-box"
                        src={ photo.photo_url_thump}
                        value={photo.id}
                        defaultChecked={photo.is_selected}
                        onClick={ (e) => updateRoomPhoto(e,placeId)}
                        labelClass="fact-name"
                        htmlFor={photo.id}
                    />
                )
            );

            return roomPhotoListBox;
        }

    };

    const RoomRecommendedPhotoList = ( roomId ) => {

        if(placePhotoList && placePhotoList.length === 0 ){
            return;
        }
        let roomPhotoListBox = '';
        if( expanded !== false && placePhotoListFetching === false  && placePhotoList.show_recommended === true){

            roomPhotoListBox = placePhotoList.map( (photo, key) => (

                    photo.is_recommended === true ? (
                        <MultiPhotoSelect
                            checkboxId={photo.id}
                            className="channel-box"
                            colClassname="room-photo-grid"
                            blockClass="photo-select-border-box channel-border-box"
                            src={ photo.photo_url_thump}
                            value={photo.id}
                            defaultChecked={photo.is_selected}
                            onClick={ (e) => updateRoomPhoto(e,roomId)}
                            labelClass="fact-name"
                            htmlFor={photo.id}
                        />
                    ) : (
                        <></>
                    )
                )
            );

            return roomPhotoListBox;
        }

    };

    const updateRoomPhoto = (e,placeId) => {
        const photoId      = e.target.value
        const photoChecked = e.target.checked

        if( photoId === undefined && photoChecked === undefined ) {
            return;
        }

        const url = UPDATE_PLACE_PHOTO;

        const data = ({
            params: {
                property_id: getProperty(),
                place_id: placeId,
                property_place_photo: photoId.split(',').map( (x) => (
                    { id :+x,
                        is_selected:  e.target.checked}
                ))
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: getUserToken(),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    if(placeList && placeList.length === 0 )
    {
        return (
            <h2>{ t('enw-notification-no_results') }</h2>
        )
    }else{
        return (

            <>

                {
                    props.selectedPlaceCategoryId === 0 ?
                        placeList && placeList.length && placeList.map( (place) => (
                            <ExpansionPanel className="accordion-room-table" key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-caret-down" />}
                                    aria-label="Expand">
                                    <ColAtm>
                                        <ColAtm md={10} xl={11}>
                                            <i className="far fa-archway room-rate-bed" />
                                            <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                    <ColAtm>
                                        {
                                            placePhotoList && placePhotoList.length > 0 && placePhotoList.show_recommended === true && <ColAtm className="recommended-box">
                                                <ColAtm xs={6} md={2}>
                                                    <LabelAtm text={ t('enw-room-recommended') } />
                                                </ColAtm>
                                                <CheckboxGroup>
                                                    <ColAtm xs={6} md={10} className="select-all">
                                                        <label className="select-all-chck">
                                                            <AllCheckerCheckbox
                                                                value={[
                                                                    placePhotoList.get_room_photos.map( (photo, key) => (
                                                                        photo.is_recommended === true && photo.is_recommended != "false"  && photo.is_recommended != false ?
                                                                            [photo.id + (",")]
                                                                            :
                                                                            null
                                                                    )).join('').slice(0, -1)
                                                                ]}
                                                                onChange={ (e) => updateRoomPhoto(e, place.id)}
                                                            />
                                                            <span className="select-all-text">
                                                                        { t('btn-deselect_all') }
                                                                    </span>
                                                            <span className="deselect-all-text">
                                                                        { t('btn-select_all') }
                                                                    </span>
                                                        </label>
                                                    </ColAtm>
                                                    { expanded === place.id ? RoomRecommendedPhotoList(place.id) : '' }
                                                </CheckboxGroup>
                                            </ColAtm>
                                        }
                                        <ColAtm>
                                            { expanded === place.id ? RoomPhotoList(place.id) : '' }
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )) :
                        placeList && placeList.length && placeList.filter( (place) => place.category_filters.includes(props.selectedPlaceCategoryId)  )
                            .map( (place) =>(
                                <ExpansionPanel className="accordion-room-table" key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                                    <ExpansionPanelSummary
                                        expandIcon={<i className="fas fa-caret-down" />}
                                        aria-label="Expand">
                                        <ColAtm>
                                            <ColAtm md={10} xl={11}>
                                                <i className="far fa-archway room-rate-bed" />
                                                <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                        <ColAtm>
                                            {
                                                placePhotoList && placePhotoList.length > 0 && placePhotoList.show_recommended === true && <ColAtm className="recommended-box">
                                                    <ColAtm xs={6} md={2}>
                                                        <LabelAtm text={ t('enw-room-recommended') } />
                                                    </ColAtm>
                                                    <CheckboxGroup>
                                                        <ColAtm xs={6} md={10} className="select-all">
                                                            <label className="select-all-chck">
                                                                <AllCheckerCheckbox
                                                                    value={[
                                                                        placePhotoList.get_room_photos.map( (photo, key) => (
                                                                            photo.is_recommended === true && photo.is_recommended != "false"  && photo.is_recommended != false ?
                                                                                [photo.id + (",")]
                                                                                :
                                                                                null
                                                                        )).join('').slice(0, -1)
                                                                    ]}
                                                                    onChange={ (e) => updateRoomPhoto(e, place.id)}
                                                                />
                                                                <span className="select-all-text">
                                                                        { t('btn-deselect_all') }
                                                                    </span>
                                                                <span className="deselect-all-text">
                                                                        { t('btn-select_all') }
                                                                    </span>
                                                            </label>
                                                        </ColAtm>
                                                        { expanded === place.id ? RoomRecommendedPhotoList(place.id) : '' }
                                                    </CheckboxGroup>
                                                </ColAtm>
                                            }
                                            <ColAtm>
                                                { expanded === place.id ? RoomPhotoList(place.id) : '' }
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))
                }


            </>
        );
    }


};


export default PlacePhotoList;
