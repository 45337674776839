import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../utils";
import {PROPERTY_PRODUCTS} from "../../constants/api.constants";


export function propertyProducts() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_PRODUCTS',
            payload: axios.post(PROPERTY_PRODUCTS, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
