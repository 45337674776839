import React, {useEffect} from 'react'
import Rates from "./Rates";
import {useSelector} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";

const Rooms = (props) => {

    const { channelCancellationListData } = props
    const updateCancellationPolicyError = useSelector(props => props.updateCancellationPolicyReducer?.error)

    useEffect( () => {},[updateCancellationPolicyError]);

   const showMessage = () => {

        if(updateCancellationPolicyError?.response?.status === 400 || updateCancellationPolicyError?.response?.status === 500)
        {
            let message = updateCancellationPolicyError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

    }

    return(
        <>
            {
                channelCancellationListData.map( room => (
                    <ExpansionPanel className="accordion-room-table" key={room.id} defaultExpanded>
                        <ExpansionPanelSummary
                            expandIcon={<i className="fas fa-caret-down" />}
                            aria-label="Expand">
                            <ColAtm>
                                <ColAtm md={10} xl={11}>
                                    <i className="fas fa-bed room-rate-bed ml-16" />
                                    <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                </ColAtm>
                            </ColAtm>
                        </ExpansionPanelSummary>

                        <Rates channelId={props.channelId}
                               rates={room.property_room_rate_mapping}
                               roomId={room.id}
                               UpdateCancellationPolicy={props.UpdateCancellationPolicy}
                               updatePolicyError={props.updatePolicyError}
                               updatePolicyStatus={props.updatePolicyStatus}
                               channelInformationData={props.channelInformationData}
                               getChannelCancellationList={props.getChannelCancellationList}
                        />

                    </ExpansionPanel>

                ))

            }
            {
                showMessage()
            }
        </>
    )
}

export default Rooms;
