import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LoginForm from "./LoginForm";
import { useSelector} from "react-redux";
import {setExpireTime, setPropertyId, setPropertyName, setUserName, userLogin, userLogout} from "../../../../../utils";
import ErrorModalRegister from "./modals/ErrorModal/ErrorModal";
import {history} from "../../../../../utils/history"
import {useTranslation} from "react-i18next";
import {CONTENT_INFORMATION} from "../../../../../constants/appUrls.constants";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `login-register-tab-${index}`,
        'aria-controls': `login-register-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'transparent',
    },
}));

const LoginRegisterTab = (props) => {
    const userRegisterReduser = useSelector(props => props.userRegisterReduser)
    const { t } = useTranslation();
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(props.selectedTabIndex);
    const [errorStatus, setErrorStatus] = useState(false); // modal ın aktif pasif durumu
    const [errorMessage, setErrorMessage] = useState(''); // api den dönen messaj'ı atıyoruz
    const [errorCode, setErrorCode] = useState(''); // Mail harici bir hata alındığında verilmesi gerekn kod

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect( () => {

        if(userRegisterReduser.fetching !== true){
            if( !userRegisterReduser.fetching &&
                ( Object.keys(userRegisterReduser.registerData).length > 0) ){

                userLogout()
                const token = userRegisterReduser.registerData.token
                const expireTime = userRegisterReduser.registerData.expire_time
                const propertyList = userRegisterReduser.registerData.property_list.filter( property => typeof property.id !== undefined).shift();
                const user = userRegisterReduser.registerData.user

                if( (token !== null && token !== '' && token !== undefined) &&
                    (expireTime !== null && expireTime !== '' && expireTime !== undefined) &&
                    (Object.keys(propertyList).length > 0 ) &&
                    (Object.keys(user).length > 0 )
                ){
                    userLogin(token);
                    setExpireTime(expireTime)
                    setPropertyId(propertyList.id)
                    setPropertyName(propertyList.name)
                    setUserName(user.name+" "+ user.surname);
                    history.push(CONTENT_INFORMATION);

                }else{
                    setErrorMessage('An unknown error has occurred!')
                    setErrorCode(200)
                    setErrorStatus(true);
                }
            }

            if( userRegisterReduser.error.response &&
                userRegisterReduser.error.response.data.error
            ){
                let message = userRegisterReduser.error.response.data.message;
                setErrorMessage(message)
                setErrorCode(100)
                setErrorStatus(true);
            }
        }

        return () => {
            setErrorStatus(false)
        }
    },[userRegisterReduser, errorStatus, tabIndex,])

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChange} aria-label="login register tabs">
                    <Tab label={ t('btn-sign_in') } {...a11yProps(0)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
                <LoginForm onClickShowForgotPassword={props.onClickShowForgotPassword} oneSignalId={props.oneSignalId} />
            </TabPanel>
            {
                errorStatus && <ErrorModalRegister status={errorStatus}
                                                   message={errorMessage}
                                                   errorCode={errorCode}
                                                   handleChangeTabIndex = {handleChange}
                                                   onClickShowForgotPassword={props.onClickShowForgotPassword}
                />
            }

        </div>
    );
}
export default  LoginRegisterTab
//export default compose(withTranslation('common')) (LoginRegisterTab);
