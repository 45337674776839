import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Language from "../../atomic/PageHeader/Language";
import UserMenu from "../../atomic/PageHeader/UserMenu";
import LabelAtm from "../../atomic/Label/Label";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {
    E404,
    LOGIN,
    MOBILE_LOGIN_SHORT,
    USER_EDIT_PROFILE,
    USER_UPDATE_PASSWORD
} from "../../../constants/appUrls.constants";
import ButtonAtm from "../../atomic/Button/Button";
import {logout, clearState} from "../../../redux/actions/userActions";
import {isDefined, userLogout} from "../../../utils";
import {history} from "../../../utils/history";
import {propertyProducts} from "../../../redux/actions/propertyProductsActions";
import Charts from "../../templates/Global/Charts";

const useStyles = makeStyles(theme => ({
    list: {
        width: 330,
        paddingRight: 40,
        paddingLeft: 40,
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            width: 280,
        },
    },
    fullList: {
        width: 'auto',
    },
    drawerPadding: {
        padding: "22px 8px",
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },

}));

export default function TemporaryDrawer() {
    const classes = useStyles();

    const { t } = useTranslation();
    const userReducer = useSelector(props => props.userReducer)
    const propertyProductsReducer = useSelector(props => props.propertyProductsReducer)
    const getProducts = isDefined(propertyProductsReducer.get_products) && propertyProductsReducer.get_products.data
    const dispatch = useDispatch();
    const splitUrl = window.location.pathname.split('/');

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    useEffect(() => {
        if (splitUrl.length > 3) {
            dispatch(propertyProducts())
        }
    }, []);

    const allowedPage = isDefined(getProducts) && Object.values(getProducts).filter(item => splitUrl[2] === item.route)

    useEffect( () => {

        if (splitUrl.length > 3) {
            if (isDefined(getProducts)) {
                if (isDefined(allowedPage[0]) && allowedPage[0].property_has_product === false){
                    history.push(E404)
                }
            }
        }
    },[getProducts, propertyProductsReducer])

    useEffect( () => {
        if(userReducer && !userReducer.fetching && userReducer.userLogoutStatus === 200){
            dispatch(clearState());
            userLogout();
            if (isDefined(localStorage.getItem("mobile_login"))) {
                history.push(MOBILE_LOGIN_SHORT + localStorage.getItem("mobile_login"));
            } else {
                history.push(LOGIN);
            }
        }
    },[userReducer])


    const onClickLogout = () => {
        dispatch(logout());
    }

    const sideList = side => (
        <div className={classes.list} role="presentation">
            <Hidden lgUp>
                <Charts />
            </Hidden>
            <UserMenu />
            <Link to={USER_EDIT_PROFILE}>
                <LabelAtm labelClass="user-menu-title mb-12" text={ t('btn-edit_profile') } />
            </Link>
            <Link to={USER_UPDATE_PASSWORD}>
                <LabelAtm labelClass="user-menu-title mb-12" text={ t('btn-update_password') } />
            </Link>
            <LabelAtm labelClass="user-menu-language" text={ t('enw-input-language') } />
            <Language />
            <ButtonAtm
                className="drawer-logout"
                buttontext={ t('btn-logout') }
                onClick={onClickLogout}
            />
        </div>
    );

    return (
        <div className={classes.drawerPadding} >
            <Button onClick={toggleDrawer('right', true)}>
                <Hidden xsDown>
                    <i className="fas fa-bars" style={{fontSize: 24}}/>
                </Hidden>
                <Hidden smUp>
                    <i className="fas fa-bars gray"/>
                </Hidden>
            </Button>
            <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>
        </div>
    );
}
