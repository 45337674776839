import React from "react";
import CoverPhoto from "../CoverPhoto/CoverPhoto";

const PhotoList = (props) => {

    const {photo, onClickPhoto, checked, onClickCoverPhoto,coverPhotoChecked} = props;

    return(
        <CoverPhoto
            checkboxId={photo.id+""+photo.photo_name}
            className="channel-box"
            colClassname="room-photo-grid"
            blockClass="photo-select-border-box channel-border-box"
            src={ photo.photo_url_thump}
            value={photo.id}
            checked={checked}
            labelClass="fact-name"
            htmlFor={photo.id+""+photo.photo_name}
            onClick={onClickPhoto}
            cover_id={photo.id}
            cover_checked={coverPhotoChecked}
            cover_value={photo.id}
            cover_onClick={onClickCoverPhoto}
        />
    )
}

export default PhotoList;
