import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Divider, Grid, MenuItem, TextField} from "@material-ui/core";
import ColAtm from "../../../../atomic/Col/Col";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import {getAppLang, getProperty, getUserToken, isDefined} from "../../../../../utils";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch} from "react-redux";
import {
    connectRateGetClear,
    connectRateUpdate,
    PropertyRoomAndRateList,
    PropertyRoomRates,
    PropertyRoomTypes
} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {Alert, Skeleton} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonAtm from "../../../../atomic/Button/Button";
import BlockAtm from "../../../../atomic/Block/Block";
import axios from "axios";
import {SYNC_RATE} from "../../../../../constants/api.constants";
import LabelAtm from "../../../../atomic/Label/Label";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const RateConnectForm = (props) =>   {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const {roomRates, connectRateData, open, connectRateFetching, handleClose} = props;

    const [resData, setResData] = useState({});
    const [roomRateId, setRoomRateId] = useState("");
    const [connectRates, setConnectRates] = useState(connectRateData);

    const selectedRate = (rate, data, key, affectStatus, affect_price_action_type, affect_price_type, affect_price_value ) => {
        setConnectRates({...connectRates,
            [key]:
                {
                    room_id: rate ? data.room.room_id : null,
                    room_rate_id: rate ? rate.room_rate_id : null,
                    is_affected_price: rate && affectStatus ? affectStatus : 0,
                    affect_price_action_type:  rate && affect_price_action_type ? affect_price_action_type : null,
                    affect_price_type: rate && affect_price_type ? affect_price_type : null,
                    affect_price_value: rate && affect_price_value ? affect_price_value : null,
                }
        });
    };

    const selectedRateType = (id) => {
        setRoomRateId(id)
        dispatch(connectRateUpdate(id));
    };

    const onRoomRateSave = () => {
        const onlySelectedRates = Object.values(connectRates).filter(rate => rate.room_rate_id !== null)
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
            body: {
                params: {
                    property_id : getProperty(),
                    room_rate_id: roomRateId,
                    connected_room_rate: onlySelectedRates,
                }
            }
        };

        axios.post(SYNC_RATE, requestOptions.body, {headers: requestOptions.headers})
            .then(response => setResData(response))
            .catch(error => {
                setResData(error)
            });
    }

    useEffect(() => {
        if (!open) {
            dispatch(connectRateGetClear());
        }
    },[open] )

    useEffect(() => {
        if (resData.status === 200) {
            dispatch(PropertyRoomAndRateList());
            dispatch(PropertyRoomTypes());
            dispatch(PropertyRoomRates());
            handleClose();
        }
    },[resData])

    useEffect(() => {
        if ( !!connectRateData && connectRateData.length > 0) {
            let fulfilledRateMapping = isDefined(connectRateData) &&
                connectRateData?.map(fulfilledRateData => fulfilledRateData.rates.filter(rate => rate.connected_rate_id !== null) );
            let fulfilledRoomMapping = isDefined(connectRateData) &&
                connectRateData?.map(fulfilledRateData => fulfilledRateData.room);

            let newArr = [];
            fulfilledRateMapping.forEach((item, key) => {
                if(item.length > 0) {
                    newArr[key] = {
                        room_id: fulfilledRoomMapping[key].room_id,
                        room_rate_id: fulfilledRateMapping[0].length !== 0 ? item[0].room_rate_id : null,
                        is_affected_price: fulfilledRateMapping[0].length !== 0 ? item[0].is_affected_price : 0,
                        affect_price_action_type: fulfilledRateMapping[0].length !== 0 ? item[0].affect_price_action_type : null,
                        affect_price_type: fulfilledRateMapping[0].length !== 0 ? item[0].affect_price_type : null,
                        affect_price_value: fulfilledRateMapping[0].length !== 0 ? item[0].affect_price_value : null
                    };
                }

            });

            setConnectRates(newArr)

        }
    },[connectRateData])

    return (
        <ColAtm className={classes.root}>
            {isDefined(resData) && resData?.status === 200 && <Alert className="w-100" severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
            {isDefined(resData?.response) && <Alert severity="error" className="w-100">{resData?.response?.data?.message}</Alert>}
            <ValidatorForm onSubmit={onRoomRateSave} onError={errors => console.log(errors)} className="w-100 pb-12">
                <ColAtm md={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="roomRates"
                                options={roomRates}
                                getOptionLabel={option => option.name}
                                filterSelectedOptions
                                onChange={ (options, value) => {selectedRateType(value?.id)} }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={ t('enw-room_and_rate-add_rate_rate-options-title') }
                                        placeholder={ t('enw-room_and_rate-add_rate_rate-options-title') }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {
                        Object.values(connectRateData).map((data, index) => {
                            const fulfilledData = data.rates.filter(rate => rate.connected_rate_id !== null).map(fulfilledRateData => fulfilledRateData );
                            return (
                                <Grid className="w-100 mt-22 mb-0">
                                    {
                                        index !== 0 &&
                                        <Divider className="mb-12" />
                                    }
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <LabelAtm labelClass="fw-600 fs-18" variant="h6" component="h3" text={data.room.room_name} />
                                            <Autocomplete
                                                id="roomRates"
                                                options={data.rates}
                                                getOptionLabel={option => option.room_rate_name}
                                                filterSelectedOptions
                                                defaultValue={fulfilledData.length > 0 && fulfilledData[0]}
                                                onChange={ (options, value) => {selectedRate(value, data, index)} }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={ t('enw-room_and_rate-add_rate_rate-options-title') }
                                                        placeholder={ t('enw-room_and_rate-add_rate_rate-options-title') }
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    {
                                        isDefined(connectRateData) && connectRateData.length > -1 && connectRateFetching &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Skeleton width="100%" height={80} />
                                            </Grid>
                                        </Grid>

                                    }
                                    {
                                        (connectRates[index]?.room_rate_id) &&
                                        <>
                                            <Grid container spacing={1} className="change-price-room-connect mt-12">
                                                <Grid item>
                                                    <ColAtm>
                                                        <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_affected-title') } />
                                                    </ColAtm>
                                                </Grid>
                                                <Grid item>
                                                    <input type={"radio"} name={`paymentValue${index}`}
                                                           id={`paymentValue2${index}`}
                                                           value={"false"}
                                                           className="d-none"
                                                           checked={ isDefined(connectRates) && !connectRates[index]?.is_affected_price }
                                                           onClick={() => selectedRate(connectRates[index], data, index, 0)}
                                                    />
                                                    <label className="show-channel-detail"
                                                           htmlFor={`paymentValue2${index}`}>
                                                        { t('btn-not_affected') }
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <input type={"radio"} name={`paymentValue${index}`}
                                                           id={`paymentValue${index}`}
                                                           value={"true"}
                                                           className="d-none"
                                                           onClick={() => selectedRate(connectRates[index], data, index, 1)}
                                                           checked={ isDefined(connectRates) && connectRates[index]?.is_affected_price }
                                                    />
                                                    <label className="show-channel-detail" htmlFor={`paymentValue${index}`}>
                                                        { t('btn-affected') }
                                                    </label>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} className="mobile-no-spacing">
                                                {
                                                    (isDefined(connectRates) && connectRates[index]?.is_affected_price && connectRates[index]?.is_affected_price === 1) ?
                                                    <Grid item xs={12}>
                                                        <Grid container="mobile-no-spacing" spacing={3}>
                                                            <Grid item xs={12} sm={4}>
                                                                <SelectValidator
                                                                    name="affect_price_action_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={isDefined(connectRates) && connectRates[index]?.affect_price_action_type ? connectRates[index]?.affect_price_action_type : ""}
                                                                    label={ t('enw-input-setup_type') }
                                                                    onChange={(e) => selectedRate(connectRates[index], data, index, connectRates[index]?.is_affected_price, e.target.value)}
                                                                    validators={['required']}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={"INC"}>{ t('enw-input-increase') }</MenuItem>
                                                                    <MenuItem value={"DEC"}>{ t('enw-input-decrease') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <SelectValidator
                                                                    name="affect_price_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={ t('enw-input-value_type') }
                                                                    value={isDefined(connectRates) && connectRates[index]?.affect_price_type ? connectRates[index]?.affect_price_type : ""}
                                                                    onChange={(e) => selectedRate(connectRates[index], data, index, connectRates[index]?.is_affected_price, connectRates[index]?.affect_price_action_type, e.target.value)}
                                                                    validators={['required']}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={"PER"}>{ t('enw-input-percent') }</MenuItem>
                                                                    <MenuItem value={"FIX"}>{ t('enw-input-fixed') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextValidator
                                                                    name="affect_price_value"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    label={ t('enw-input-value') }
                                                                    autoComplete="off"
                                                                    value={isDefined(connectRates) && connectRates[index]?.affect_price_value ? connectRates[index]?.affect_price_value : ""}
                                                                    onChange={(e) => selectedRate(connectRates[index], data, index, connectRates[index].is_affected_price, connectRates[index].affect_price_action_type,connectRates[index].affect_price_type, e.target.value)}
                                                                    labelWidth={70}
                                                                    validators={['required']}
                                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: <>
                                                                            {
                                                                                isDefined(connectRates[index]?.affect_price_type) && connectRates[index]?.affect_price_type === "PER" ?
                                                                                    <InputAdornment position="start">
                                                                                        %
                                                                                    </InputAdornment>
                                                                                    :
                                                                                    isDefined(connectRates[index]?.affect_price_type) && connectRates[index]?.affect_price_type === "FIX" ?
                                                                                        <InputAdornment position="start">
                                                                                            {localStorage.getItem("currency")}
                                                                                        </InputAdornment>
                                                                                        :
                                                                                        <InputAdornment position="start">

                                                                                        </InputAdornment>
                                                                            }
                                                                        </>
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                        :
                                                    <></>
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>

                            )
                        })
                    }

                    <BlockAtm md={12} className="t-center mt-22">
                        <ButtonAtm
                            className="room-rate-modal-save room-rate-save bg-red m-auto"
                            buttontext={ t('btn-save') }
                            autoFocus
                            color="primary"
                            type="submit"
                        />
                    </BlockAtm>
                </ColAtm>
            </ValidatorForm>
        </ColAtm>
    );
}

export default RateConnectForm;
