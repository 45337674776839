import {getAppLang, getProperty, getUserToken, isDefined} from "../../../../utils";
import axios from "axios";
import {CREATE_POPUP, CREATE_IMAGE_POPUP, UPDATE_POPUP, POPUP_LIST} from "../../../../constants/api.constants";

export function getPopupList (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
            }
        }
    };

    if (params && (isDefined(params.title) || isDefined(params.language_code))) {
        requestOptions.body.params = {
            property_id : getProperty(),
            filter : {
                title : params.title,
                content_category_id : "1",
                language_code : params.language_code,
                status : "1"
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'POPUP_LIST',
            payload: axios.post(POPUP_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function uploadPopupImage(params) {
    const formData = new FormData();

    formData.append( 'property_id', getProperty());
    if (isDefined(params.file)) {
        formData.append('image', params.file, params.file.name);
    }

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data',  'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
        property_id : getProperty()
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_IMAGE_POPUP',
            payload: axios.post(CREATE_IMAGE_POPUP, requestOptions.data, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createPopup(params) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                title: params.title,
                start_date : params.start_date,
                end_date : params.end_date,
                language_code : params.language_code,
                image : params.image
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_POPUP',
            payload: axios.post(CREATE_POPUP, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updatePopup(params) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                title: params.title,
                start_date : params.start_date,
                end_date : params.end_date,
                language_code : params.language_code,
                image : params.image
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_POPUP',
            payload: axios.post(UPDATE_POPUP + '/' + params.id, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createPopupClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_POPUP_CLEAR',
        })
    }
}

export function updatePopupClearState() {
    return dispatch => {
        dispatch({
            type: 'UPDATE_POPUP_CLEAR',
        })
    }
}

export function uploadPopupImageClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_IMAGE_POPUP_CLEAR',
        })
    }
}