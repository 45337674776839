import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getProperty, isDefined} from "../../../../../../utils";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function AdultPaxDecreaseModal(props) {
    const { t } = useTranslation();
    const default_currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.default_currency ? props.channelInformationData.default_currency : '';
    const currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.currency ? props.channelInformationData.currency : default_currency ;

    const [name, setName] = useState("");


    const [discountType, setDiscountType] = useState(null);
    const [discountValue, setDiscountValue] = useState(null);
    const [adult, setAdult] = useState("");
    const [adultActionType, setAdultActionType ] = useState("");

    const changeDiscountVal = ({target}) => {
        const validated = (target.value).match(/^(\d*\.{0,1}\d{0,2}$)/)
        if (validated) {
            setDiscountValue(target.value)
        }
    }
    const handleDiscountTypeChange = ({target}) => {
        setDiscountType(target.value);
    };
    const handleAdultChange = ({target}) => {
        setAdult(target.value);
    };
    const handleAdultActionTypeChange = ({target}) => {
        setAdultActionType(target.value);
    };

    const { handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            adult: adult,
            adult_action_type: "DEC",
            name: name,
            action_type: adultActionType,
            type: discountType,
            value: discountValue
        }
        props.addChannelAdultPricing(params)
    };

    useEffect( () => {

        if (props.updateStatus === 200) {
            props.handleClose();
            setName("")
            setDiscountValue(null)
            setDiscountType(null)
            setAdult(null)
            setAdultActionType(null)
            props.getChannelPaxList({ channel_id : props.channelInformationData.id } );
        }

        return () => {

            if(props.updateError){
                setName("")
                setDiscountValue(null)
                setDiscountType(null)
            }

            if(props.open){
                setName("")
                setDiscountValue(null)
                setDiscountType(null)
                setAdult(null)
                setAdultActionType(null)
            }
        }

    },[props.updateStatus, props.updateError, props.open] )

    return (
        <>
            <ButtonAtm
                buttontext={ t('btn-create_new_adult_decrease_policy') }
                fullwidth={true}
                startIcon={<i className="fas fa-plus-circle" />}
                endIcon={<i className="fal fa-angle-right ml-9" />}
                onClick={props.handleOpen}
            />
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={props.handleClose}>
                    { t('btn-create_new_adult_decrease_policy') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {props.updateStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                            {isDefined(props.updateError) && <Alert severity="error">{props.updateError.response.data.message}</Alert>}
                            {/* <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            label={ t('enw-input-room_name') }
                                            type="text"
                                            fullWidth
                                            name="name"
                                            autoComplete="off"
                                            variant="outlined"
                                            value={name} onChange={changeName}
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                        />
                                    </Grid>
                                </Grid>*/}

                            <Grid container spacing={3}>
                                {/*<Grid item xs={12} sm={3} className="d-inherit">
                                        <i className="fas fa-user-minus align-self-center" />
                                        <LabelAtm labelClass="pax-price-title ml-5 align-self-center" component="h6" text={ t('enw-input-title-for_an_adult')} />
                                    </Grid>*/}
                                <Grid item xs={12}>
                                    <Grid container spacing={3} className="mobile-no-spacing">
                                        <Grid item xs={12} sm={3}>
                                            <SelectValidator
                                                name="adult"
                                                onChange={handleAdultChange}
                                                value={adult}
                                                className="form-control"
                                                variant="outlined"
                                                fullWidth
                                                label={ t('enw-channels-pricing-number_of_adults') }
                                                validators={['required']}
                                                errorMessages={[ t("enw-notification_this_is_required") ]}
                                            >
                                                {(() => {
                                                    const rows = [];
                                                    for (let i = 1; i < 11; i++) {
                                                        rows.push(<MenuItem value={i}>
                                                            {i} { t('enw-channels-adult') }
                                                        </MenuItem>);
                                                    }
                                                    return rows;
                                                })()}
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <SelectValidator
                                                name="action_type"
                                                onChange={handleAdultActionTypeChange}
                                                value={adultActionType}
                                                className="form-control"
                                                variant="outlined"
                                                fullWidth
                                                label={ t('enw-input-setup_type') }
                                                validators={['required']}
                                                errorMessages={[ t("enw-notification_this_is_required") ]}
                                            >
                                                <MenuItem value={"INC"}>
                                                    { t('enw-input-increase') }
                                                </MenuItem>
                                                <MenuItem value={"DEC"}>
                                                    { t('enw-input-decrease') }
                                                </MenuItem>
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <SelectValidator
                                                name="discountType"
                                                onChange={handleDiscountTypeChange}
                                                value={discountType}
                                                className="form-control"
                                                variant="outlined"
                                                fullWidth
                                                label={ t('enw-input-value_type') }
                                                validators={['required']}
                                                errorMessages={[ t("enw-notification_this_is_required") ]}
                                            >
                                                <MenuItem value={"PER"}>
                                                    { t('enw-input-percent') }
                                                </MenuItem>
                                                <MenuItem value={"FIX"}>
                                                    { t('enw-input-fixed') }
                                                </MenuItem>
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextValidator
                                                label={ t('enw-input-value') }
                                                name="discountValue"
                                                fullWidth
                                                autoComplete="off"
                                                value={discountValue}
                                                onChange={changeDiscountVal}
                                                variant="outlined"
                                                validators={['required']}
                                                errorMessages={[ t("enw-notification_this_is_required") ]}
                                                InputProps={{
                                                    endAdornment: <>
                                                        {
                                                            discountType === "PER" ?
                                                                <InputAdornment position="start">
                                                                    %
                                                                </InputAdornment>
                                                                :
                                                                discountType === "FIX" ?
                                                                    <InputAdornment position="start">
                                                                        {currency}
                                                                    </InputAdornment>
                                                                    :
                                                                    <InputAdornment position="start">

                                                                    </InputAdornment>
                                                        }
                                                    </>
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
