import React, {Component} from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import Map from "./Map";
import PlacesList from "./PlacesList";
import SavedAddress from "./SavedAddress";
import ManualAddressSave from "./ManualAddressSave";
import SelectedPlace from "./SelectedPlace";
import EditingSavedAddress from "./EditingSavedAddress";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import MobileMap from "./MobileMap";
import {Hidden} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

let t =null;
class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            google : window.google,
            map: null,
            marker:null,
            places: [],
            selectedPlace: null,
            propertyName: props.propertyName,
            address : props.propertyAddress,
            propertyHasAnAdress : !(props.propertyAddress === null || props.propertyAddress === undefined),
            editingSavedAddress : false,
            backToSearch : false,
            location:{
                lat: props.propertyLatitude !== null ? props.propertyLatitude : parseFloat(52.52000659999999),
                long: props.propertyLongitude !== null ? props.propertyLongitude : parseFloat(13.404954)
            },
            zoomIn:18,
            notFoundAddress : false,
        };
        t = props.t;
    }

    /**
     * Property Contant Update
     * @param {Object} param
     * @param {string} param.address
     * @param {string} param.latitude
     * @param {string} param.longitude
     */
    updatePropertyContant = (param) => {
        this.props.updatePropertyContant(param)
        let place = {
            address : param.address,
            location:{
                lat: param.latitude,
                long:param.longitude
            }
        }
        this.updateLocationAddress(place)
        if(this.state.notFoundAddress){
            this.setState({
                notFoundAddress:false
            })
        }
    }

    setStateUpdateAddress = (address) => {
        this.setState({
            address : address
        })
    }

    setStateBackToSearch = (status) => {
        this.setState({
            backToSearch : status
        })
    }

    setStateUpdateEditingSavedAddress = (status) => {
        this.setState({
            editingSavedAddress : status
        })
    }

    zoomLocation = (marker) => {
        let { map,zoomIn } = this.state;
        map.setZoom(zoomIn);
        map.setCenter(marker.getPosition());
    }

    handleSelectedPlaceReset = () => {
        this.setState({
            selectedPlace: null,
            propertyHasAnAdress:true,
            editingSavedAddress:false
        } );
    }

    handlePlaceSelect = (selectedPlace) =>  {
        let { marker } = this.state;
        this.setState({
            selectedPlace: selectedPlace,
            places: [],
        });
        this.zoomLocation(marker);
    }

    updatePlaces = (places) => {
        this.setState({
            places: places,
            selectedPlace: null,
            notFoundAddress : false,
        });
    }

    /**
     * Seacrh işleminden sonra Response İçinde Property Kendisine ait adress bulunmadığında yapılan işlem
     */
    addManuelAddress = () =>  {
        this.setState({
            notFoundAddress:true,
            places: [],
            selectedPlace: null,
        })
    }

    getLatLngAdress = (lat,lng) => {
        const {editingSavedAddress} = this.state

        if(editingSavedAddress){
            this.setState({
                location:{
                    lat: lat,
                    long:lng
                }
            })
        }
    }

    updateLocationAddress = (place) =>  {
        this.setState({
            address: place.address,
            location: place.location
        },() => {
            this.handleFoundLocation(document.getElementById('map'));
        });

    }

    handleFoundLocation = (mapElement) => {
        let { google, map, location, marker } = this.state;
        let currentLocation = new google.maps.LatLng(location.lat, location.long);

        if (!map && !marker) {
            let map = new google.maps.Map(mapElement, {
                center: currentLocation,
                zoom: this.state.zoomIn,
                fullscreenControl:false,
                streetViewControl:false,
                mapTypeControl:false
            })

            let marker = new google.maps.Marker({
                position: currentLocation,
                map: mapElement,
                draggable: true
            });

            marker.addListener('dragend', () => {
                var point = marker.getPosition();
                this.getLatLngAdress(marker.position.lat(),marker.position.lng() )
                this.state.location.lat= point.lat();
                this.state.location.long = point.lng();
            });

            this.setState({
                map: map,
                marker:marker
            },() => {
                map.setCenter(currentLocation);
                map.setZoom(18);
                marker.setMap(map);
            } );
        } else {
            map.setCenter(currentLocation);
            map.setZoom(18);
            marker.setPosition(currentLocation)
        }
    }

    getPlaces = (request) => {
        let { google, map } = this.state;

        return new Promise(function (resolve, reject) {
                let service = new google.maps.places.PlacesService(map);
                let callback = (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(results)
                    } else {
                        reject(new Error('Couldnt\'t find address'));
                    }
                };
                service.textSearch(request, callback);
        });
    }

    getPlacesFromAPI = (query) => {
        let { google, map, location } = this.state;
        let request = {//location: currentLocation,//radius: '500',
            query: query
        };

        if(!map){

            let map = new google.maps.Map(document.getElementById('map'), {
                zoom: this.state.zoomIn,
                fullscreenControl:false,
                streetViewControl:false,
                mapTypeControl:false
            })
            let marker = new google.maps.Marker({
                position: location,
                map: map,
                draggable: true
            });

            this.setState({
                map: map,
                marker:marker
            },() => {
                this.getPlaces(request)
                    .then(results => {
                        this.updatePlaces(results);
                    })
                    .catch(error => {
                        console.log('error => ',error)
                        this.setState({
                            notFoundAddress:true,
                            places: [],
                            selectedPlace: null,
                        })
                    })
            })
        }else{
            this.getPlaces(request)
                .then(results => {
                    this.updatePlaces(results);
                })
                .catch(error => {
                    console.log('error => ',error);
                    this.setState({
                        notFoundAddress:true,
                        places: [],
                        selectedPlace: null,
                    })
                })
        }
    }

    // Browser üzerinden komuma göre adres istenirse bu fonksiyon kullanılması gerek
    findLatLang = (latLng, geocoder) => {
        return new Promise(function (resolve, reject) {
            geocoder.geocode({ 'latLng': latLng }, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    resolve(results[0].formatted_address);
                } else {
                    reject(new Error('Couldnt\'t find address'));
                }
            })
        });
    }

    backSeacrh = () => {
        this.setState({
            propertyHasAnAdress : false,
            selectedPlace : null,
            editingSavedAddress : false,
            backToSearch:true,
            address:null
        }, () => {
            this.getPlacesFromAPI(this.props.propertyName);
        })
    }

    componentDidMount() {
        /**
         * Property'e ait kayıtlı bir adres yoksa otomatik search yapılıyor.
         */
        if(!this.state.propertyHasAnAdress){
             this.getPlacesFromAPI(this.props.propertyName);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if( prevProps.updatePropertyContantStatus !== this.props.updatePropertyContantStatus){

            if( this.props.updatePropertyContantStatus === 200 ){
                this.handleSelectedPlaceReset();
            }
        }

    }

    render() {
        const {updatePropertyContantStatus} = this.props
        return (
            <div>
                <div className="container-width" style={{padding: 0}}>
                    <ColAtm>
                        <ColAtm md={12} className="mb-64">
                            <Hidden lgUp>
                                <ExpansionPanel className="location-map-accordion">
                                    <ExpansionPanelSummary
                                        expandIcon={<i className="fas fa-arrow-down mobile-map-arrow" />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header">
                                        <ColAtm>
                                            <ColAtm lg={11}>
                                                <i className="fas fa-map mobile-map-icon" />
                                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-location-map_view') } />
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                        <BlockAtm lg={6} xs={12} className="fullWidthRow">
                                            <MobileMap
                                                map={this.state.map}
                                                google={this.state.google}
                                                location={this.state.location}
                                                address={this.state.address}
                                                findLatLang={this.findLatLang}
                                                updateLocationAddress={this.updateLocationAddress}
                                            />
                                        </BlockAtm>
                                    </ExpansionPanelDetails>

                                </ExpansionPanel>
                            </Hidden>

                            <BlockAtm lg={6} xs={12} className="location-search-list" >
                                <SavedAddress
                                    propertyName={this.state.propertyName}
                                    location={this.state.location}
                                    address={this.state.address}
                                    propertyHasAnAdress = {this.state.propertyHasAnAdress}
                                    updatePropertyContant={this.updatePropertyContant}
                                    editingSavedAddress={this.state.editingSavedAddress}
                                    setStateUpdateEditingSavedAddress={this.setStateUpdateEditingSavedAddress}
                                />
                                <SelectedPlace
                                    selectedPlace={this.state.selectedPlace}
                                    propertyName={this.state.propertyName}
                                    location={this.state.location}
                                    address={this.state.address}
                                    updatePropertyContant={this.updatePropertyContant}
                                    handleSelectedPlaceReset={this.handleSelectedPlaceReset}
                                    setStateUpdateAddress={this.setStateUpdateAddress}
                                    backSeacrh={this.backSeacrh}
                                    backToSearch={this.state.backToSearch}
                                    setStateBackToSearch={this.setStateBackToSearch}
                                />
                                <EditingSavedAddress
                                    location={this.state.location}
                                    propertyName={this.state.propertyName}
                                    address={this.state.address}
                                    editingSavedAddress={this.state.editingSavedAddress}
                                    backSeacrh={this.backSeacrh}
                                    updatePropertyContant={this.updatePropertyContant}
                                    setStateUpdateEditingSavedAddress={this.setStateUpdateEditingSavedAddress}
                                />
                                <ManualAddressSave
                                    map={this.state.map}
                                    marker={this.state.marker}
                                    google={this.state.google}
                                    location={this.state.location}
                                    zoomLocation={this.zoomLocation}
                                    findLatLang={this.findLatLang}
                                    notFoundAddress={this.state.notFoundAddress}
                                    propertyName={this.state.propertyName}
                                    updatePropertyContant={this.updatePropertyContant}
                                    updateLocationAddress={this.updateLocationAddress}
                                    updatePropertyContantStatus={updatePropertyContantStatus}
                                />
                                <PlacesList
                                    places={this.state.places}
                                    handlePlaceSelect={this.handlePlaceSelect}
                                    selectedPlace={this.state.selectedPlace}
                                    updatePropertyContant={this.updatePropertyContant}
                                    updateLocationAddress={this.updateLocationAddress}
                                    addManuelAddress={this.addManuelAddress}
                                    propertyName={this.state.propertyName}
                                />
                            </BlockAtm>
                            <BlockAtm lg={6} xs={12} className="fullWidthRow mobile-d-none">

                                <Map
                                    map={this.state.map}
                                    google={this.state.google}
                                    location={this.state.location}
                                    address={this.state.address}
                                    findLatLang={this.findLatLang}
                                    updateLocationAddress={this.updateLocationAddress}
                                />
                            </BlockAtm>
                        </ColAtm>
                    </ColAtm>
                </div>
            </div>
        );
    }
}

export default compose(withTranslation('common')) (Location);
