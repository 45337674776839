import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import {useDispatch,useSelector} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import {RoomFactRecommendedFeatureUpdate, RoomFactRecommendedUpdate} from "../../../../../../redux/actions/Content/Room/roomFactAction";
import FactCheckBox from "../../../../../molecules/ContentRoomFactContent/ContentRoomFactContent";
import {cloneDeep} from 'lodash'
import {Hidden} from "@material-ui/core";

let lastFactIds = [];
let lastFactFeatureIds = [];
const FactRecommended = ( { roomFactList, roomId } ) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const roomFactRecommendedStatus = useSelector( props => props.roomFactUpdateReducer?.roomFactRecommended );
    const roomFactRecommendedFeatureStatus = useSelector( props => props.roomFactUpdateReducer?.roomFactRecommendedFeature );
    const roomFactError = useSelector( props => props.roomFactUpdateReducer?.roomFactUpdateError?.response?.status  );
    const [allChecked, setAllChecked] = useState(false);
    const [selectedFact, setSelectedFact] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState([]);
    const [totalFactCount, setTotalFactCount] = useState(0);


    const updateRoomFactRecommend = (e) => {
        if (e.target.value === undefined) return 0;
        let tempSelectedFact = cloneDeep(selectedFact);
        let tempSelectedFeature =  cloneDeep(selectedFeature);
        let param = {};

        if( tempSelectedFact.includes(parseInt(e.target.value) ) ){

            let index = tempSelectedFact.indexOf( parseInt(e.target.value) )
            if ( index !== -1) {
                tempSelectedFact.splice(index, 1);
                lastFactIds = tempSelectedFact

                param = {
                    room_id: roomId,
                    property_room_fact: [{
                        id : e.target.value,
                        is_selected: false
                    }]
                }


                if( selectedFeature.includes(parseInt(e.target.value) ) ){
                    let index = tempSelectedFeature.indexOf( parseInt(e.target.value) )
                    if (index !== -1) {
                        tempSelectedFeature.splice(index, 1);

                    }
                }
                lastFactFeatureIds = tempSelectedFeature;
            }



        }else {
            tempSelectedFact.push(parseInt(e.target.value));
            lastFactIds = tempSelectedFact;
            param = {
                room_id: roomId,
                property_room_fact: [{
                    id : e.target.value,
                    is_selected: true
                }]
            }

            lastFactFeatureIds = tempSelectedFeature;
        }

        let totalCountStatus = totalFactCount > 0 ? totalFactCount === tempSelectedFact.length : false
        if(totalCountStatus){
            setSelectedFeature([]);
        }
        dispatch(RoomFactRecommendedUpdate(param));
        //setAllChecked(totalCountStatus);
    }

    const handleAllCheck = e => {

        if(e.target.checked === undefined) return 0;

        if(e.target.checked){
            const factId = roomFactList.get_room_facts.filter( (fact) => {
                if( fact.is_recommended === true && !selectedFact.includes(fact.id) ) {
                    return fact
                }
            }).map((fact) => fact.id);

            let tempFactIds = [...selectedFact, ...factId];
            setTotalFactCount(tempFactIds.length);

            let param = {
                room_id: roomId,
                property_room_fact: tempFactIds.map( (factId) => ({
                    id : factId,
                    is_selected: true
                }))
            };

            dispatch(RoomFactRecommendedUpdate(param));
            lastFactIds = tempFactIds
            //setAllChecked(true);

        }else{
            let tempSelectedFact = cloneDeep(selectedFact);

            let param = {
                room_id: roomId,
                property_room_fact: tempSelectedFact.map( (factId) => ({
                    id :+factId,
                    is_selected: false
                }))
            };

            roomFactList.get_room_facts.map( (fact) => {
                if( fact.is_recommended === true  && tempSelectedFact.includes(fact.id) ) {
                    let index = tempSelectedFact.indexOf(fact.id)
                    if (index !== -1) {
                        tempSelectedFact.splice(index, 1);
                    }
                }
            })
            setTotalFactCount(tempSelectedFact.length);
            dispatch(RoomFactRecommendedUpdate(param));

            lastFactIds = tempSelectedFact
            //setAllChecked(false)
            setSelectedFeature([]);
        }

    };

    const updateRoomFactFeature = (e,factId) => {

        let tempSelectedFeature =  cloneDeep(selectedFeature);
        let param = {
            room_id:roomId,
            is_feature:true,
            fact_id:factId
        }

        if( selectedFeature.includes(factId ) ){
            let index = tempSelectedFeature.indexOf( factId )
            if (index !== -1) {
                tempSelectedFeature.splice(index, 1);
                param.is_feature = false;
            }
        }else {
            tempSelectedFeature.push(factId);
        }

        dispatch( RoomFactRecommendedFeatureUpdate(param) );
        lastFactFeatureIds = tempSelectedFeature
    }


    useEffect( () => {

        if (roomFactRecommendedStatus === 200) {

            setSelectedFact(lastFactIds);
            setSelectedFeature(lastFactFeatureIds);
            let totalCountStatus = totalFactCount > 0 ? totalFactCount === lastFactIds.length : false
            setAllChecked(totalCountStatus)
            lastFactIds = [];
        }
        if (roomFactRecommendedFeatureStatus === 200){
            setSelectedFeature(lastFactFeatureIds);
            lastFactFeatureIds=[];
        }
        if(roomFactError === 400){
            lastFactFeatureIds=[];
        }
    },[roomFactRecommendedStatus,roomFactRecommendedFeatureStatus])

    return(
        <ColAtm className="recommended-box">
            <ColAtm xs={5} sm={4} md={2} lg={2}>
                <LabelAtm text={ t('enw-room-recommended') } />
            </ColAtm>
            <Hidden xsDown>
                <ColAtm lg={8} sm={2}></ColAtm>
            </Hidden>
            <ColAtm xs={7} sm={6} md={8} lg={2} className="select-all-room-btns">
                <div>
                    <input
                        id="checkAll"
                        className="room-fact-check-all"
                        name="checkall"
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleAllCheck}
                    />
                        <label htmlFor="checkAll" className="select-all-room-fact">
                            { t('btn-deselect_all') }
                        </label>
                        <label htmlFor="checkAll" className="deselect-all-room-fact">
                            { t('btn-select_all') }
                        </label>
                </div>
            </ColAtm>
            {

                roomFactList.get_room_facts.map((fact) => (
                    fact.is_recommended === true &&
                    <FactCheckBox
                        checkboxId={fact.id+"room"+roomId+"aaa"}
                        type="checkbox"
                        className="fact-box"
                        colClassname="offer-fact-col tablet-pb-20"
                        blockClass="offer-fact-border-box fact-border-box featured-fact"
                        labelIconClass="fact-icon-label"
                        sm={4} md={2}
                        iconClass={`offer-fact-icon fact-icon ${fact.icon}`}
                        text={ t(fact.language_key) }
                        name={fact.name}
                        value={fact.id}
                        htmlFor={fact.id+"room"+roomId+"aaa"}
                        checked={selectedFact.includes(fact.id)}
                        onChange={ updateRoomFactRecommend}
                        handlerIsfeature={ (e) => updateRoomFactFeature(e,fact.id)}
                        isFeature={selectedFeature.includes(fact.id)}
                    />
                ))
            }
        </ColAtm>
    )
}

export default FactRecommended;
