import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {getProperty, isDefined} from "../../../../../../utils";
import Button from "@material-ui/core/Button";
import Slide from '@material-ui/core/Slide';
import {Grid} from "@material-ui/core";
import RoomRateChannels from "../../../../../molecules/RoomRateChannels/RoomRateChannels";
import {
    addChannelGroupChannels, addChannelGroupClear, channelGroupCreateData,
    getChannelGroupChannels
} from "../../../../../../redux/actions/Network/Channel/channelGroup.actions";
import {useDispatch} from "react-redux";
import {listChannels} from "../../../../../../redux/actions/channelListActions";
import {Alert} from "@material-ui/lab";
import LabelAtm from "../../../../../atomic/Label/Label";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateChannelGroupModal(props) {

    const {channelGroupList} = props;

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [tempChannelMapping, setTempChannelMapping] = useState([])
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
        setName("")
        setTempChannelMapping([])
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(addChannelGroupClear());
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            name: name,
            channels: tempChannelMapping,
        }
        dispatch(addChannelGroupChannels(params))

    };

    const handleChangeName = ({target}) => {
        setName(target.value);
    };

    const handleChange = e => {

        if (e.target.checked === true) {
            setTempChannelMapping([...tempChannelMapping, Number(e.target.value)]);
        } else {
            const selectedAcc = tempChannelMapping.filter(a => {
                if (a === Number(e.target.value)) return false;
                return true;
            });
            setTempChannelMapping([...selectedAcc]);
        }
    };

    useEffect( () => {
        if(channelGroupList.add_mapping.status === 200)
        {
            if(open){
                handleClose();
                dispatch(listChannels("null"));
                dispatch(channelGroupCreateData());
                dispatch(getChannelGroupChannels());
                dispatch(addChannelGroupClear());
            }
        }
    },[channelGroupList, open, dispatch] )

    return (
        <div className="w-100">
            <Button className="filter-action-button float-right" aria-controls="filter-action-menu" aria-haspopup="true" onClick={handleClickOpen}>
                <i className="fas fa-plus-circle" /> { t('enw-channels-group_new-btn') }
            </Button>
            <Dialog
                className="group-channel-modal"
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                key={3}
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogTitle className="red-bottom-border" onClose={handleClose}>
                    { t('enw-channels-group_title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {isDefined(channelGroupList.add_error)
                            && isDefined(channelGroupList.add_error.response)
                            && <Alert severity="error">{channelGroupList.add_error.response.data.message}</Alert>}
                            <Grid container className="channel-new-group-title pt-20">
                                <Grid xs={12}>
                                    <LabelAtm variant="h2" text={ t('enw-channels-group_name') } />
                                    <LabelAtm variant="p" labelClass="f-w-500"
                                              text={ t('enw-channels-group_name-desc') }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextValidator
                                        name="name"
                                        type="text"
                                        className="w-100"
                                        variant="outlined"
                                        label={ "Name" }
                                        value={name}
                                        onChange={handleChangeName}
                                        autoComplete="off"
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="bg-gray p-12 pt-32 radius-8">
                                <Grid xs={12} className="channel-new-group-title">
                                    <LabelAtm variant="h2" text={ t('enw-channels-group_channel-list') } />
                                    <LabelAtm variant="p" labelClass="f-w-500"
                                              text={ t('enw-channels-group_channel-list-desc') }
                                    />
                                </Grid>
                                {Object.values(channelGroupList.get_create_mapping).map((arr) => (
                                        <RoomRateChannels
                                            checkboxId={arr.id}
                                            className="fact-box"
                                            colClassname="offer-fact-col room-rate-channel-list p-0-24 channel-group-box"
                                            blockClass="channel-border-box offer-fact-border-box fact-border-box mb-16"
                                            src={`/assets/img/brands/${arr.id}.png`}
                                            checked={
                                                tempChannelMapping.lastIndexOf(Number(arr.id)) >= 0 ? true : false
                                            }
                                            value={arr.id}
                                            sm={4}
                                            factName={arr.name}
                                            text={arr.name}
                                            labelClass="fact-name"
                                            htmlFor={arr.id}
                                            onChange={e => handleChange(e)}
                                        />
                                    ))}
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
}
