import React        from "react";
import PropTypes    from "prop-types";

const ImageAtm = props => (
    <img alt={props.alt} src={props.src} className={props.className} width={props.width} />
);

ImageAtm.propType = {
    width: PropTypes.any,
    alt: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string
};

export default ImageAtm;