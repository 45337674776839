import {getPlaceCategoryFields, updatePlace} from "../../../../constants/Content/places.constanst";

const initialState = {
    fetching : false,
    updatePlaceStatus:null,
    error : {},
    getCategoryFetching : false,
    placeCategoryFields:[],
    getCategoryError : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case updatePlace.UPDATE_PLACE_PENDING:
            return {
                ...state,
                fetching: true,
                updatePlaceStatus:null,
                error : {},
                getCategoryError : {},
            };
        case updatePlace.UPDATE_PLACE_FULFILLED:
            return {
                ...state,
                fetching: false,
                updatePlaceStatus: action.payload
            };
        case updatePlace.UPDATE_PLACE_REJECTED:
            return {
                ...state,
                fetching: false,
                placeCategoryFields:[],
                error: action.payload,
            };
        case getPlaceCategoryFields.PLACE_CATEGORY_FIELDS_PENDING:
            return {
                ...state,
                getCategoryFetching: true,
                status: false,
                placeCategoryFields:{}
            };
        case getPlaceCategoryFields.PLACE_CATEGORY_FIELDS_FULFILLED:
            return {
                ...state,
                getCategoryFetching: false,
                status: true,
                placeCategoryFields: action.payload
            };
        case getPlaceCategoryFields.PLACE_CATEGORY_FIELDS_REJECTED:
            return {
                ...state,
                getCategoryError: action.payload,
                getCategoryFetching: false,
                status: false
            };
        case getPlaceCategoryFields.PLACE_CATEGORY_FIELDS_CLEAR:
            return {
                ...initialState,
            };
        case updatePlace.UPDATE_PLACE_CLEAR:
            return {
                ...initialState,
            };
        default:
            return state
    }
}
