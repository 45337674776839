import React, {Component}   from 'react';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import PaymentBookingList from "./PaymentBookingList";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {PAYMENT} from "../../../../constants/appUrls.constants";
import {connect} from "react-redux";
import {getPaymentTransactionList} from "../../../../redux/actions/Payment/PaymentTransaction.actions";

let t =null;

class TransactionList extends Component {
    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {

        this.props.getPaymentTransactionList();
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-payment-transaction_list-page_title') } />
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={PAYMENT} text={ t('enw-payment-transaction_list-page_title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width pb-0">
                            <ColAtm className="booking-list transaction-col">
                                {
                                    !this.props.transactionListData.fetching &&
                                    this.props.transactionListData.transactionList &&
                                    <PaymentBookingList reservationList = {this.props.transactionListData.transactionList}/>
                                }
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        transactionListData : state.paymentTransactionReducer
    }
};

const mapDispatchToProps = {
    getPaymentTransactionList
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(TransactionList));
