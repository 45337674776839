import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CheckboxAtm from "../../../../atomic/Checkbox/Checkbox";
import {AwardsCertificatesUpdateStatus} from "../../../../../redux/actions/Content/AwardsCertificates/awardsCertificatesActions";

let lastChangeID = null;
const AwardsCertificatesStatusBox = (props) => {

    const {awardCertificate} = props;
    const awardsCertificatesStatus = useSelector(props => props.awardsCertificatesReducers?.awardsCertificatesStatusUpdate)
    const [isSelected , setIsSelected ] = useState( awardCertificate.status );
    const dispatch = useDispatch();

    const handleUpdateStatusAwards = (e) => {

        let params = {}
        if(isSelected){
            params = {
                award_certificate_id: awardCertificate.id,
                set_status: false
            };
            dispatch( AwardsCertificatesUpdateStatus( params ) )
            lastChangeID = "id-" + awardCertificate.id
        }else{
            params = {
                award_certificate_id: awardCertificate.id,
                set_status: true
            };
            dispatch( AwardsCertificatesUpdateStatus( params ) )
            lastChangeID = "id-" + awardCertificate.id
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {
        if (awardsCertificatesStatus?.status === 200 && lastChangeID === "id-" + awardCertificate.id ){
            setState();
        }

    },[awardsCertificatesStatus])

    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={"id-" + awardCertificate.id}
            value={awardCertificate.id}
            checked={isSelected}
            onChange={handleUpdateStatusAwards}
        />
    )


}
export default AwardsCertificatesStatusBox;
