import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import UpdateAdultPaxModal from "./updateAdultPaxModal";
import LabelAtm from "../../../../../atomic/Label/Label";
import BlockAtm from "../../../../../atomic/Block/Block";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import UpdateAdultPaxDecreaseModal from "./updateAdultPaxDecreaseModal";
import {useTranslation} from "react-i18next";
import AdultCheckBox from "./AdultCheckbox";


const Adult = (props) => {
    const { t } = useTranslation();

    return(
        <>
            <LabelAtm labelClass="pax-price-type-title" component="h6" text={ t('enw-channels-adult') } />
            <Grid container spacing={3}>
                {
                    props.adultPricingPolicy.map( adult => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >

                            { adult.adult_action_type === "INC" ?
                                <UpdateAdultPaxModal
                                adult={adult}
                                updateChannelAdultPricing={props.updateChannelAdultPricing}
                                updateAdultPricingStatus={props.updateAdultPricingStatus}
                                getChannelPaxList={props.getChannelPaxList}
                                channelInformationData={props.channelInformationData}
                                errorUpdateAdult={props.errorUpdateAdult}
                                clearErrorMessage = {props.clearErrorMessage}
                                /> :
                                <UpdateAdultPaxDecreaseModal
                                    adult={adult}
                                    updateChannelAdultPricing={props.updateChannelAdultPricing}
                                    updateAdultPricingStatus={props.updateAdultPricingStatus}
                                    getChannelPaxList={props.getChannelPaxList}
                                    channelInformationData={props.channelInformationData}
                                    errorUpdateAdult={props.errorUpdateAdult}
                                    clearErrorMessage = {props.clearErrorMessage}
                                />
                            }

                            <BlockAtm className="offer-fact-border-box fact-border-box price-adult-box">
                               <AdultCheckBox adultId={adult.id}
                                              roomId={props.roomId}
                                               rateId={props.rateId}
                                               channelId={props.channelId}
                                               rateMappingId={props.rateMappingId}
                                               adultName={adult.name}
                                               adultActionType={adult.action_type}
                                               isSelected={adult.pricing_adult_policy_is_selected}
                               />

                                {adult.adult_action_type === "INC" ?
                                    (<label
                                        htmlFor={"room" + props.roomId  + "rateMapping"+ props.rateMappingId +"rate" +props.rateId+  "adult" + adult.id + adult.name +'type'+ adult.action_type}>
                                        <label style={{position: 'relative'}}
                                            htmlFor={"room" + props.roomId  + "rateMapping"+ props.rateMappingId +"rate" +  +props.rateId+  "adult" + adult.id + adult.name +'type'+ adult.action_type}>
                                            <p className="cancellation-value">
                                                <span>{(adult.action_type === "DEC" ? "- " : "+ ")}</span>
                                                {(adult.value !== null) ? (adult.type === "PER") ? (adult.value === 0) ? t('enw-input-free') :  "%" + adult.value : adult.value + " " + props.currency  : ''      }
                                            </p>
                                            <OverFlowText>
                                                + {[...Array(adult.adult)].map((x, i) =>
                                                <i
                                                    className="fas fa-male"
                                                    key={i} />
                                            )}
                                            </OverFlowText>
                                        </label>
                                    </label>) :
                                    (<label
                                        htmlFor={"room" + props.roomId  + "rateMapping"+ props.rateMappingId +"rate" +  props.rateId+  "adult" + adult.id + adult.name +'type'+ adult.action_type}>
                                        <label style={{position: 'relative'}}
                                            htmlFor={"room" + props.roomId  + "rateMapping"+ props.rateMappingId +"rate" +  +props.rateId+  "adult" + adult.id + adult.name +'type'+ adult.action_type}>
                                            <p className="cancellation-value">
                                                <span>{(adult.action_type === "DEC" ? "- " : "+ ")}</span>
                                                {(adult.value !== null) ? (adult.type === "PER") ? (adult.value === 100) ? t('enw-input-free') :  "%" + adult.value : adult.value + " " + props.currency  : ''      }
                                            </p>
                                            <OverFlowText>
                                                - {[...Array(adult.adult)].map((x, i) =>
                                                <i
                                                    className="fas fa-male"
                                                    key={i} />
                                            )}
                                            </OverFlowText>
                                        </label>
                                    </label>)
                                }

                            </BlockAtm>
                        </ColAtm>
                    ))
                }
            </Grid>
        </>
    )
}
export default Adult;
