import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import DeleteBlog from "./DeleteBlog";
import {WEB_BLOG_CREATE, WEB_BLOG_EDIT_SHORT} from "../../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ColAtm from "../../../../../atomic/Col/Col";
import {MenuItem} from "@material-ui/core";
import BlockAtm from "../../../../../atomic/Block/Block";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getBlogList} from "../../../../../../redux/actions/Web/Blog/blogActions";
import {getLanguages} from "../../../../../../redux/actions/languageActions";
import moment from "moment/moment";

const BlogList = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const languageList = useSelector(props => props.languagesReducer?.languages);
    const error = useSelector(props => props.blogReducers?.listDataError);

    const columns = [
        t('enw-web_blog-title'),
        t('enw-input-date'),
        t('enw-web_blog-language'),
        t('enw-reservation-table-actions'),
    ];

    const {list, fetching} = props;
    const [reservations, setReservations] = useState([]);
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("");
    const [postData, setPostData] = useState(false);

    useEffect(() => {

        if (!fetching && list && list.length > 0) {
            let tempData = [];

            if (error) {
                tempData = [];
            } else {
                list.map((blog, index) => {
                    tempData.push([
                        blog.title,
                        moment.unix(blog.created_at).lang(localStorage.getItem('lng')).format('LLL').toString(),
                        blog.language_code,
                        <div>
                            <Link className="filter-action-button float-right" to={`${WEB_BLOG_EDIT_SHORT}/${blog.id}`}><i
                                className="fas fa-edit"/></Link>
                            <DeleteBlog blog={blog}/></div>,
                    ])
                });
            }

            setReservations(tempData);
        }
    }, [fetching, list, localStorage.getItem('lng')]);

    useEffect(() => {
        dispatch(getLanguages());
    }, [])

    const options = {
        filter: false,
        selectableRowsOnClick: false,
        selectableRows: false,
        searchOpen: true,
        searchPlaceholder: t('btn-search'),
        print: false,
        download: false,
        sortOrder: {
            name: 'name',
            direction: 'desc'
        },
        textLabels: {
            body: {
                noMatch: t('datatable-body-nomatch'),
                toolTip: t('datatable-body-tooltip'),
            },
            pagination: {
                next: t('datatable-pagination-next'),
                previous: t('datatable-pagination-previous'),
                rowsPerPage: t('datatable-pagination-rowsperpage'),
                displayRows: t('datatable-pagination-of'),
            },
            toolbar: {
                search: t('datatable-toolbar-search'),
                downloadCsv: t('datatable-toolbar-download_excel'),
                print: t('datatable-toolbar-print'),
                viewColumns: t('datatable-toolbar-view_columns'),
                filterTable: t('datatable-toolbar-filter_table'),
            },
            filter: {
                all: t('datatable-filter-all'),
                title: t('datatable-filter-title'),
                reset: t('datatable-filter-reset'),
            },
            viewColumns: {
                title: t('datatable-filter-view_columns-title'),
                titleAria: t('datatable-filter-view_columns-title_aria'),
            },
            selectedRows: {
                text: t('datatable-selected_rows-text'),
                delete: t('datatable-selected_rows-delete'),
                deleteAria: t('datatable-selected_rows-delete-aria')
            },
        },
    };

    const onSubmit = () => {
        let filter;

        filter = {
            title : name,
            language_code : language,
        }

        function clean(obj) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }

        setPostData(true)
        dispatch(getBlogList(clean(filter)))
    };

    const changeNameFilter = (e) => {
        setName(e.target.value)
    };

    const changeLanguage = (e) => {
        setLanguage(e.target.value)
    };

    const clearFilter = () => {
        setPostData(false)
        setName("")
        setLanguage("")
        dispatch(getBlogList())
    }

    return (
        <>
            <div className="blog-list-new-blog-btn">
                <Link className="filter-action-button float-right mb-24" to={WEB_BLOG_CREATE}><i
                    className="fas fa-plus-circle" aria-hidden="true"></i> {t('btn-create-blog')}</Link>
            </div>
            <ValidatorForm onSubmit={onSubmit}  className="reservation-filter-container pos-relative w-100 pt-4 p-24">
                <ColAtm spacing={3}>
                    <BlockAtm item xs={12} md={4}>
                        <TextValidator
                            onChange={changeNameFilter}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-web_blog-title') }
                            value={name}
                        />

                    </BlockAtm>

                    <BlockAtm item xs={12} md={2}>
                        <SelectValidator
                            onChange={changeLanguage}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-web_blog-language') }
                            value={language}
                        >
                            {
                                languageList.map(status => (
                                    <MenuItem value={status.code} key={status.id}>
                                        { t(status.language_key) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </BlockAtm>

                    <BlockAtm item xs={12} md={1} className="align-self-center mt-20 blog-filter-btn">
                        <ButtonAtm
                            type="submit"
                            buttontext={ t('enw-table-toolbar-filters') }
                            className="reservation-filter-btn bg-red"
                            disabled={language === "" && name === ""}
                        />
                    </BlockAtm>
                    {
                        postData &&
                        <ButtonAtm className="clear-filter-btn" startIcon={<i className="fas fa-times" />} onClick={clearFilter} />
                    }
                </ColAtm>
            </ValidatorForm>

            {
                !fetching && list &&
                <div className="blog-datatable">
                    <MUIDataTable
                        title={t('enw-blog-title')}
                        data={reservations}
                        columns={columns}
                        options={options}
                    />
                </div>
            }
        </>
    )
}

export default BlogList;
