import {paymentDashboardConstants} from "../../../constants/Payment/paymentDashboard.constants";

const initialState = {
    fetching : false,
    paymentDashboard : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case paymentDashboardConstants.GET_PAYMENT_DASHBOARD_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case paymentDashboardConstants.GET_PAYMENT_DASHBOARD_FULFILLED:
            return {
                ...state,
                fetching: false,
                paymentDashboard: action.payload,
            };
        case paymentDashboardConstants.GET_PAYMENT_DASHBOARD_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
