import {dashboardConstants} from "../../constants/Content/dashboard.constants";

const initialState = {
    property_dashboard   : '',
    left_drawer   : '',
    amenities   : '',
    facility   : '',
    general   : '',
    places   : '',
    photos   : '',
    dashboard: '',
    room   : '',
    isFetching: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.GET_DASHBOARD_REQUEST:
            return {
                ...state,
                payload    : action.response
            };
        case dashboardConstants.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                property_dashboard: action.property_dashboard,
                left_drawer: action.left_drawer,
                amenities: action.amenities,
                facility: action.facility,
                general: action.general,
                photos: action.photos,
                room: action.room,
                places: action.places,
                payload: action.response
            };
        case dashboardConstants.GET_DASHBOARD_FAILURE:
            return {};
        default:
            return state
    }
}
