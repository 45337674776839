import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField} from "@material-ui/core";
import ColAtm from "../../../../atomic/Col/Col";
import InputAtm from "../../../../atomic/Input/Input";
import ButtonAtm from "../../../../atomic/Button/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import {convertAccentedCharacters} from "../../../../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const initialStateError = {
    nameError: "",
    factsError:"",
    accommodationTypeError:""
};

const RatePlanTabs = (props) =>   {

    const { t } = useTranslation();

    const classes = useStyles();

    const factList = props.factSearchList;
    const accommodationTypes = props.accommodationTypes;

    const [state, setState] = React.useState({
        name: props.editRateData.name,
        accommodation_type: props.editRateData.accommodation_type,
        accommodationType: props.editRateData.property_room_rate_accommodation,
        facts: props.editRateData.property_room_rate_inclusion_mapping,
        id: props.editRateData.id,
        status: props.editRateData.status,
        roomViewType: props.editRateData.property_room_rate_inclusion_mapping.length >0 ? [ ...props.editRateData.property_room_rate_inclusion_mapping.map( roomView => roomView.fact_id) ] : [],
    });

    useEffect(() => {
        let tempFacts = [];
        props.editRateData.property_room_rate_inclusion_mapping.map((fact) => {
            tempFacts.push(fact.fact_id ? fact.fact_id : fact.id);
            setState({ ...state, facts: tempFacts } )
        });
    },[]);

    const [errorState,setErrorState] = React.useState(initialStateError);

    const isValidate = () => {

        let nameError, accommodationTypeError = null;

        if (!state.name) {
            nameError = t('enw-notification_this_is_required')
        }

        if (!state.accommodationType) {
            accommodationTypeError = t('enw-notification_this_is_required')
        }
        if (nameError )
        {
            setErrorState({nameError,accommodationTypeError});
            return true
        }
        return false
    };

    const handleInputChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const selectedFacts = (facts) => {
        let tempFacts = [];
        facts.map( (fact) => {
            tempFacts.push(fact.fact_id ? fact.fact_id : fact.id)
        });
        setState({ ...state, facts: tempFacts } );
    };

    const selectedAccommodationType = (accommodation_type) => {
        if(accommodation_type !== null){
            if(accommodation_type.hasOwnProperty('id')){
                setState({ ...state, accommodation_type: accommodation_type.id } );
            }
        }
    };

    const onRoomRateSave = (e) => {
        let isValid = isValidate();
        if(!isValid)
        {
            props.onRatePlanAdd(state)
        }
    };

    const selectedFactList = state.facts;

    const notSelectedFactList = factList.filter( addedItem => {
        return !selectedFactList.find( selectedFactList => selectedFactList.fact_id === addedItem.id );
    });

    return (
        <ColAtm className={classes.root}>
            <ColAtm md={12} className="select-room-types">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="accommodationTypes"
                            options={accommodationTypes.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                            getOptionLabel={option => t(option.language_key)}
                            defaultValue={  state.accommodationType }
                            filterSelectedOptions
                            onChange={ (options, value) => {selectedAccommodationType(value)} }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={ t('enw-input-accommodation_types') }
                                    placeholder={ t('enw-input-accommodation_types') }
                                    fullWidth
                                    helperText={errorState.accommodationTypeError}
                                    error={errorState.accommodationTypeError ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputAtm
                            name="name"
                            type="text"
                            variant="outlined"
                            label={ t('enw-input-new_rate_name')}
                            placeholder={ t('enw-input-new_rate_name')}
                            autoComplete="off"
                            value={state.name}
                            onChange={handleInputChange('name')}
                            required
                            helperText={errorState.nameError}
                            error={errorState.nameError ? true : false}
                        />
                    </Grid>
                </Grid>
            </ColAtm>
           {/* <ColAtm className="m-auto pr-20 pl-20" md={11}>
                <InputAtm
                    name="description"
                    type="text"
                    variant="outlined"
                    label={ t('enw-input-description') }
                    placeholder={ t('enw-input-description') }
                    multiline={true}
                    rows="2"
                    value={state.description}
                    onChange={ handleInputChange('description')  }
                    required
                    helperText={errorState.descriptionError}
                    error={errorState.descriptionError ? true : false}
                />
                <div className={classes.root}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={notSelectedFactList.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                        getOptionLabel={option => t(option.language_key)}
                        defaultValue={  state.facts }
                        filterSelectedOptions
                        onChange={(options, value) => {selectedFacts(value)} }
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={ t('enw-input-inclusions') }
                                placeholder={ t('enw-input-add_a_tag') }
                                fullWidth
                                helperText={errorState.factsError}
                                error={errorState.factsError ? true : false}
                            />
                        )}
                    />
                </div>
            </ColAtm>*/}
            <ColAtm className="mt-13" md={12}>
                <ButtonAtm
                    className="m-auto room-rate-modal-save bg-red"
                    buttontext={ t('btn-save') }
                    autoFocus
                    color="primary"
                    onClick={ onRoomRateSave }
                />
            </ColAtm>
        </ColAtm>
    );
}

export default RatePlanTabs;
