export const getCouponsConstants = {
    NETWORK_COUPONS_GET_PENDING  : 'NETWORK_COUPONS_GET_PENDING',
    NETWORK_COUPONS_GET_FULFILLED: 'NETWORK_COUPONS_GET_FULFILLED',
    NETWORK_COUPONS_GET_REJECTED : 'NETWORK_COUPONS_GET_REJECTED',
};

export const syncCouponsConstants = {
    NETWORK_COUPONS_SYNC_PENDING  : 'NETWORK_COUPONS_SYNC_PENDING',
    NETWORK_COUPONS_SYNC_FULFILLED: 'NETWORK_COUPONS_SYNC_FULFILLED',
    NETWORK_COUPONS_SYNC_REJECTED : 'NETWORK_COUPONS_SYNC_REJECTED',
    NETWORK_COUPONS_CLEAR_STATE   : 'NETWORK_COUPONS_CLEAR_STATE',
};
