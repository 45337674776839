import React from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {FilePond, registerPlugin} from "react-filepond";
import "filepond/dist/filepond.min.css";
import axios from 'axios';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ColAtm from "../../../atomic/Col/Col";
import BlockAtm from "../../../atomic/Block/Block";
import ButtonAtm from "../../../atomic/Button/Button";
import Container from "@material-ui/core/Container";
import FooterAtm from "../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import {getAppLang, getProperty, isDefined} from "../../../../utils";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {
    PHOTO_UPLOAD,
    PHOTO_LIST,
    PHOTO_CATEGORY_LIST,
    SET_PHOTO_CATEGORY,
    DELETE_PHOTOS,
    SET_DEFAULT_PHOTOS,
    PUBLISH_PHOTOS
} from "../../../../constants/api.constants"
import LabelAtm from "../../../atomic/Label/Label";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../utils/history";
import {CONTENT, CONTENT_FACTS_SHORT, CONTENT_AWARDS_AND_CERTIFICATES} from "../../../../constants/appUrls.constants";
import {Skeleton} from "@material-ui/lab";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Menu from "../../Global/Layout/Header/Submenu/General";

let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const Option = ({ value, description }) => (
    <option value={value}>{description}</option>
);

const SelectBox = ({ children, onChange, value ,id}) => (
    <select onChange={onChange} value={value} id={id}>
        {children}
    </select>
);

class HotelGallery extends React.Component {
    queue = [];

    constructor(props) {
        super(props);

        //TODO: locale localStorage üzerinden çekilmesi gerekiyor
        this.state = {
            locale: "tr",
            property_id: getProperty(),
            files: [],
            listPhotos: [],
            categoryList: [],
            photoLength: '',
            showDiv: false,
            showPhotos: true,
            redirect: false,
            showSuccessHeader: false,
            loading: false,
            error: false,
            errorMessage: false,
            open: false,
            selectedPhoto : '',
            openError: false,
            messageInfo: {},
        };

        this.deletePhoto     = this.deletePhoto.bind(this);
        this.setDefaultPhoto = this.setDefaultPhoto.bind(this);
        this.publishPhoto    = this.publishPhoto.bind(this);
        this.optionChanged    = this.optionChanged.bind(this);

        t = props.t;
    }


    handleInit() {
    }

    handleClick = message => () => {
        this.queue.push({
            message,
            key: new Date().getTime(),
        });

        if (this.state.openError) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({ openError: false });
        } else {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                messageInfo: this.queue.shift(),
                openError: true,
            });
        }
    };

    handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openError: false });
    };

    handleExited = () => {
        this.processQueue();
    };

    handleClickOpen = (id) => {
        let selectedPhoto;

        selectedPhoto =  this.state.listPhotos.filter( (executive,key) => {
            return executive.id === id
        })
        this.setState({open:true, selectedPhoto:selectedPhoto});
    };

    handleClose = () => {
        this.setState({open:false})
    };
    getUploadedPhoto(photo) {
        this.setState({
            listPhotos: [...this.state.listPhotos, photo]
        });
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        const url = PHOTO_CATEGORY_LIST;
        const data = ({
            "params": {
                "locale": getAppLang(),
                "property_id" : getProperty()
            }
        });
        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            this.setState({
                categoryList: result.data.data.photo_category_list,
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    toggle = () => this.setState((currentState) => ({showDiv: !currentState.showDiv}));

    optionChanged (e) {
        const photoId = e.target.id;
        const photoCategoryId = e.target.value;
        //const url = "http://api.extranetwork.com/app/v1/property/photo/category/set";
        const url = SET_PHOTO_CATEGORY;
        const data = ({
            "params": {
                "locale":getAppLang(),
                "property_id" : getProperty(),
                "set_photo_category":[
                    {
                        "photo_id" : photoId,
                        "photo_category_id" : photoCategoryId
                    }
                ]
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            const response = result.data;
            if(response.status == 200) {

                const photoList = [...this.state.listPhotos];
                const newList = photoList.filter(function (list) {
                    if(list.id == photoId){
                        list.property_photo_category_id = photoCategoryId
                    }
                    return list
                });
                this.setState({
                    listPhotos: newList
                });
            }

        }).catch(function (error) {
            console.log(error);
        });

    };

    selectChain = () => {
        const chains = this.state.categoryList.map((data, key) => (
                <Option value={data.id} description={data.category_name} key={key} />
            )
        );
        return chains;
    };

    deletePhoto(e) {
        //TODO: multi delete yapıldığında photo_id yapısı değişmesi gerekiyor
        const deleteId = e.target.value;
        //const url = "http://api.extranetwork.com/app/v1/property/photo/delete";
        const url = DELETE_PHOTOS;

        const data = ({
            "params": {
                "property_id" : getProperty(),
                "photos":[
                    {
                        "photo_id": deleteId,
                    }
                ]
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            const response = result.data;
            if(response.status == 200) {
                const photoList = [...this.state.listPhotos];
                const newList = photoList.filter(function (list) {
                    if(list.id != deleteId) return list
                });
                this.setState({
                    listPhotos: newList
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    setDefaultPhoto(e){
        const defaultPhotoId = e.target.value;
        const url = SET_DEFAULT_PHOTOS;
        const is_default = e.target.id === 1 ? 0 : 1;
        const data = ({
            "params": {
                "property_id" : getProperty(),
                "photo_id": defaultPhotoId,
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            const response = result.data;
            if(response.status == 200) {
                const photoList = [...this.state.listPhotos];
                const newList = photoList.filter(function (list) {
                    if(list.id == defaultPhotoId){
                        list.is_default = is_default
                    }else{
                        list.is_default = 0
                    }
                    return list
                });
                this.setState({
                    listPhotos: newList
                });
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    publishPhoto(e){
        const publishPhotoId = e.target.value;
        //const url = "http://api.extranetwork.com/app/v1/property/photo/publish";
        const url = PUBLISH_PHOTOS;
        const is_temp = e.target.id === 1 ? 0 : 1;
        const data = ({
            "params": {
                "property_id" : localStorage.getItem('property'),
                "photos":[
                    {
                        "photo_id": publishPhotoId,
                        "is_temp" : is_temp
                    }
                ]
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            const response = result.data;
            if(response.status == 200) {
                const photoList = [...this.state.listPhotos];
                const newList = photoList.filter(function (list) {
                    if(list.id == publishPhotoId){
                        list.is_temp = is_temp
                    }
                    return list
                });
                this.setState({
                    listPhotos: newList
                });
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    photoZoom = () => {
        if (this.state.selectedPhoto !== undefined && this.state.selectedPhoto !== '' ) {
            return <Dialog onClose={this.handleClose} className="photo-gallery-modal" aria-labelledby="rate-plan" open={this.state.open}>
                <DialogTitle id="rate-plan" onClose={this.handleClose}>
                </DialogTitle>
                <DialogContent>
                    <img className="w-100" src={this.state.selectedPhoto[0].photo_url} />
                </DialogContent>
            </Dialog>

        }
    }

    handleHotelPhoto() {

        if(this.state.listPhotos) {
            const listPhoto = this.state.listPhotos.map((listPhoto, key) =>
                <ColAtm xs={6} md={3} lg={2} className="photo-index">
                    <ColAtm >
                        <BlockAtm style={{backgroundImage: `url(${listPhoto.photo_url_thump})`,backgroundRepeat: 'round'}} key={key}
                                  className="img-div upload-images pos-relative">
                            <div className="ribbon__div">
                                <label><i className={(listPhoto.is_temp ? '' : 'ribbon__green fas fa-check')}/></label>
                            </div>
                            <div dir="ltr" role="group" className="btn-group--icons btn-group photo__btns">
                                <button value={listPhoto.id} onClick={this.deletePhoto} data-toggle="tooltip" title={ t('btn-delete') }
                                        className="photo-btn__bg fas" size="sm">
                                    &#xf1f8;
                                </button>
                                <ButtonAtm
                                    focusRipple
                                    className="zoom-btn"
                                    onClick={() => this.handleClickOpen(listPhoto.id)}
                                    buttontext={<i className="fas fa-search-plus" />}
                                />
                                {
                                    !listPhoto.is_default &&
                                        <button value={listPhoto.id} id={listPhoto.is_default} data-toggle="tooltip" title={ t('btn-set_as_default') }
                                                onClick={this.setDefaultPhoto} className="not-selected-cover">
                                            {t('btn-selected_cover_photo')}
                                        </button>

                                }
                            </div>
                            {
                                listPhoto.is_default ?  <ButtonAtm className="selected-cover" startIcon={<i className="fas fa-check" />} buttontext={ t('btn-selected_cover_photo') } /> : <></>
                            }

                        </BlockAtm>

                        <div className="form__form-group-field photo-type__select" style={{display: 'none'}}>
                            <SelectBox onChange={this.optionChanged}
                                       value={listPhoto.property_photo_category_id}
                                       id={listPhoto.id}
                                       getOptionLabel={option => option}
                                       getOptionValue={option => option}>
                                {this.selectChain ()}
                            </SelectBox>
                        </div>
                    </ColAtm>
                </ColAtm>
            );

            return listPhoto;
        }

    }

    componentWillMount() {
       // const getlist = "http://api.extranetwork.com/app/v1/property/photo/list";
        const getlist = PHOTO_LIST;

        const postData = {
            "params":
                {
                    "locale": "en",
                    "property_id": localStorage.getItem('property')
                }
        };

        axios.post(getlist, postData, {
            headers: {
                'content-type': 'application/json',
                'authToken': localStorage.getItem('auth'),
                "language": getAppLang(),
            }
        })
            .then(res => {
                this.setState({
                    listPhotos: res.data.data
                });
            })
            .catch(err => console.log('Errors =  ' + err.data))
    }

    onLoadPhoto () {
        const { loading, error, messageInfo } = this.state;
        const { classes } = this.props;

        if (this.pond != undefined) {
            if (this.pond.allowFilesSync != undefined) {
                return this.pond.allowFilesSync && this.pond.props.files.length === 0 && this.state.listPhotos.length < 1 ? (
                    <ColAtm md={12} lg={12} xl={12} className="photoContainer first-photo-row mt-56">
                        <Container maxWidth="xl">
                            <ColAtm>
                                <ColAtm xs={12} md={4} lg={4} xl={4} className="photoButtonsRow one-page-content">
                                    <ColAtm>
                                        <FilePond
                                            ref={ref => (this.pond = ref)}
                                            className="first-add-photo"
                                            files={this.state.files}
                                            allowMultiple={true}
                                            maxFiles={200}
                                            maxParallelUploads={10}
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={['image/*']}
                                            labelIdle={'<div class="photo-upload-box"><i class="fas fa-cloud-upload-alt photo-upload-icon" /></div>'}
                                            server={
                                                {
                                                    timeout: 99999,
                                                    process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                        const formData = new FormData();
                                                        formData.append('image', file, file.name);
                                                        formData.append(' property_id', this.state.property_id);

                                                        const url = PHOTO_UPLOAD;

                                                        this.setState({ loading: true }, () => {
                                                            axios({
                                                                method: 'POST',
                                                                url: url,
                                                                data: formData,
                                                                headers: {
                                                                    'content-type': 'multipart/form-data',
                                                                    'authToken': localStorage.getItem('auth'),
                                                                    "language": getAppLang(),
                                                                },
                                                                onUploadProgress: (e) => {
                                                                    // updating progress indicator
                                                                    progress(e.lengthComputable, e.loaded, e.total)
                                                                }
                                                            }).then(response => {
                                                                // passing the file id to FilePond
                                                                load(response.file);
                                                                this.getUploadedPhoto(response.data.data[0]);
                                                                this.pond.removeFile(response.file);
                                                                this.setState({
                                                                    loading: false,
                                                                })
                                                            }).catch((thrown) => {
                                                                if (axios.isCancel(thrown)) {

                                                                } else {
                                                                    // handle error
                                                                }
                                                            });

                                                        })
                                                        // Setup abort interface
                                                        return {
                                                            abort: () => {
                                                                //source.cancel('Operation canceled by the user.');
                                                                abort()
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                            oninit={() => this.handleInit()}
                                            onupdatefiles={fileItems => {
                                                // Set currently active file objects to this.state
                                                this.setState({
                                                    files: fileItems.map(fileItem => fileItem.file)
                                                });
                                            }}
                                        />
                                    </ColAtm>
                                    <BlockAtm className="mobile-w-100">
                                        <LabelAtm labelClass="photo-page-title" text={ t('enw-landing_photo_gallery-subtitle') }/>
                                        <LabelAtm labelClass="photo-page-subtitle"
                                                  text={ t('enw-landing_photo_gallery-desc') } />
                                    </BlockAtm>
                                </ColAtm>
                            </ColAtm>
                        </Container>
                    </ColAtm>
                ) : (
                    <ColAtm md={12} lg={12} xl={12} className="photoContainer pos-relative photo-upload-row">
                        <ColAtm md={12} className="p-absolute">
                            <FilePond
                                ref={ref => (this.pond = ref)}
                                files={this.state.files}
                                allowMultiple={true}
                                allowFileTypeValidation={true}
                                acceptedFileTypes={['image/*']}
                                maxFiles={200}
                                maxParallelUploads={10}
                                labelIdle={' <div> </div> '}
                                server={
                                    {
                                        timeout: 99999,
                                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                                            const formData = new FormData();
                                            formData.append('image', file, file.name);
                                            formData.append(' property_id', this.state.property_id);

                                            const url = PHOTO_UPLOAD;

                                            this.setState({ loading: true }, () => {
                                                axios({
                                                    method: 'POST',
                                                    url: url,
                                                    data: formData,
                                                    headers: {
                                                        'content-type': 'multipart/form-data',
                                                        'authToken': localStorage.getItem('auth'),
                                                        "language": getAppLang(),
                                                    },
                                                    onUploadProgress: (e) => {
                                                        // updating progress indicator
                                                        progress(e.lengthComputable, e.loaded, e.total)
                                                    }
                                                }).then(response => {
                                                    this.setState({
                                                        loading: false,
                                                        error: false,
                                                    })

                                                    if (this.state.error === false) {
                                                        load(response.file);
                                                        this.getUploadedPhoto(response.data.data[0]);
                                                        this.pond.removeFile(response.file);
                                                    }
                                                }).catch((thrown) => {
                                                    if (axios.isCancel(thrown)) {
                                                        this.setState({
                                                            error: true,
                                                            errorMessage: isDefined(thrown) && thrown.response.data.message,
                                                            loading: false,
                                                            openError: true,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            error: true,
                                                            errorMessage: isDefined(thrown) && thrown.response.data.message,
                                                            loading: false,
                                                            openError: true,
                                                        });
                                                    }
                                                });
                                            });

                                            // Setup abort interface
                                            return {
                                                abort: () => {
                                                    //source.cancel('Operation canceled by the user.');
                                                    abort()
                                                }
                                            }
                                        }

                                    }
                                }
                                oninit={() => this.handleInit()}
                                onupdatefiles={fileItems => {
                                    // Set currently active file objects to this.state
                                    this.setState({
                                        files: fileItems.map(fileItem => fileItem.file)
                                    });
                                }}
                            />
                        </ColAtm>

                        <ColAtm spacing={3} className="photo-list-row">
                            {this.handleHotelPhoto()}

                            {this.photoZoom()}
                            <span className="photo-upload-show" style={{width: '232px', height: '232px'}}>
                                <FilePond
                                    ref={ref => (this.pond = ref)}
                                    files={this.state.files}
                                    allowMultiple={true}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={['image/*']}
                                    maxFiles={200}
                                    maxParallelUploads={10}
                                    labelIdle={' <div style="height: 300px"></div>'}
                                    init={() => this.handleInit()}
                                    onupdatefiles={fileItems => {
                                        // Set currently active file objects to this.state
                                        this.setState({
                                            files: fileItems.map(fileItem => fileItem.file)
                                        });
                                    }}
                                />
                            </span>
                            {
                                loading &&
                                <ColAtm xs={6} md={3} lg={2} xl={2} className="photo-index">
                                    <Skeleton variant="rect" width={"100%"} height={"100%"} />
                                </ColAtm>

                            }
                            {
                                error &&
                                <Snackbar
                                    key={messageInfo.key}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                    open={this.state.openError}
                                    autoHideDuration={6000}
                                    onClose={this.handleCloseError}
                                    onExited={this.handleExited}
                                    ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                    message={<span id="message-id">{this.state.errorMessage}</span>}
                                    action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        className={classes.close}
                                        onClick={this.handleCloseError}
                                    >
                                        <CloseIcon />
                                    </IconButton>,
                                ]}
                                />
                            }
                        </ColAtm>

                    </ColAtm>
                )
            }
        }
    }

    onClickNextButton = () => {
        this.setState({
            redirect: true,
            showSuccessHeader:true
        })

    };

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={CONTENT}
                               exitText={ t('btn-dashboard') }
                               text={ t('enw-general-title') }
                               showSuccessHeader={this.state.showSuccessHeader}
        />
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            history.push(CONTENT_FACTS_SHORT+'16')
            /*setTimeout(() => {
                history.push(CONTENT_ROOMS)
            }, 2000);*/
        }
    }


    render() {
        registerPlugin(FilePondPluginImagePreview);
        registerPlugin(FilePondPluginFileValidateType);
        return (
            <>
                <PageTitle title={ t('enw-photos-page_title') } />
                {
                    this.headerStatusShow()
                }
                <Menu photoColClass="active-menu-bg" />

                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width photo-container-width">
                            <ColAtm>
                                <ColAtm className={this.state.listPhotos.length > 0 ? "photoButtons" : "d-none"}>
                                    <ColAtm xs={12} md={3} lg={2} xl={2}  className="photoButtonsRow">
                                        <FilePond
                                            ref={ref => (this.pond = ref)}
                                            className="header-add-photo"
                                            files={this.state.files}
                                            allowMultiple={true}
                                            maxFiles={200}
                                            maxParallelUploads={10}
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={['image/*']}
                                            labelIdle={'<i class="fas fa-plus-circle"></i> <p>' + t("btn-add_new") + ' </p>'}
                                            oninit={() => this.handleInit()}
                                            onupdatefiles={fileItems => {
                                                // Set currently active file objects to this.state
                                                this.setState({
                                                    files: fileItems.map(fileItem => fileItem.file)
                                                });
                                            }}
                                        />

                                    </ColAtm>
                                </ColAtm>
                                {this.onLoadPhoto()}
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backTo={CONTENT_AWARDS_AND_CERTIFICATES}
                    component={Link}
                    nextComponent={Link}
                    buttonNextText={ t('btn-next') }
                    iconLeftClassName="fas fa-arrow-left"
                    backDisabled={this.state.showSuccessHeader}
                    nextDisabled={this.state.showSuccessHeader}
                    onClick={this.onClickNextButton}
                />
                {this.renderRedirect()}
            </>
        )
    }
}
HotelGallery.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withTranslation('common'), withStyles(styles)) (HotelGallery);
