import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../atomic/Button/Button";
import ColAtm from "../../../atomic/Col/Col";
import LabelAtm from "../../../atomic/Label/Label";
import {useTranslation} from "react-i18next";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const ErrorModal  = (props) =>  {

    const [open, setOpen] = useState(props.status)
    const [message, setMessage] = useState(props.message)
    const { t } = useTranslation();

   const handleClose = () => {
       setOpen(false);
    };

    useEffect( () => {
        return () => {
            setOpen(false);
            setMessage('');
        }
    },[])


    return (
        <Dialog onClose={handleClose} className="status-modal" aria-labelledby="status" open={open}>
            <DialogTitle className="red-bottom-border"  id="status" onClose={handleClose}>
                { t('modal-error-title') }
            </DialogTitle>
            <DialogContent>
                <ColAtm>
                    <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow status-modal-content">
                        <ColAtm>
                            <div className="m-auto">
                                <span>
                                    <i
                                        className="fas fa-times"
                                        style={{margin: 'auto', fontSize: '87px', color: '#d71f26', backgroundColor: '#fefafa', borderRadius: '135px', padding: '40px 54px'}}
                                    />
                                </span>
                            </div>
                        </ColAtm>
                        <LabelAtm labelClass="status-modal-subtitle" text={message} />
                        <ButtonAtm
                            className="soft-red-status-button"
                            outline
                            fullwidth={true}
                            buttontext={ t('btn-ok') }
                            onClick={handleClose}
                        />
                    </ColAtm>
                </ColAtm>
            </DialogContent>
        </Dialog>
    )

}

export default ErrorModal;
