import {languagesConstants} from "../../constants/Public/languages.constans";

const initialState = {
    fetching : false,
    status : false,
    languages : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case languagesConstants.LANGUAGES_PENDING:
            return {
                ...state,
                fetching: true,
                status: false,
            };
        case languagesConstants.LANGUAGES_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                languages: action.payload,
            };
        case languagesConstants.LANGUAGES_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
