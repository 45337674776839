import React        from 'react';
import PropTypes    from 'prop-types';

import ButtonAtm    from "../../atomic/Button/Button";
import ColAtm from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";
import Typography from '@material-ui/core/Typography';
import {Tooltip} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);

const ImagedButton = props => (
        <ColAtm style={props.colStyle}>
            <BlockAtm className={props.blockClassName} >
                <LightTooltip title={props.tooltip} placement="top">
                    <Typography noWrap className={props.labelClass} style={{maxWidth: 203}} >
                        {props.text}
                    </Typography>
                </LightTooltip>
                <ButtonAtm
                    style={props.style}
                    className={props.className}
                    onClick={props.onClick}
                    type={props.type}
                    value={props.value}
                    buttontext={props.buttontext}
                    />
            </BlockAtm>
        </ColAtm>
);

ImagedButton.propTypes = {
    colStyle:  PropTypes.string,
    blockClassName:  PropTypes.string,
    className:  PropTypes.string,
    tooltip:  PropTypes.string,
    labelClass: PropTypes.string,
    onClick:    PropTypes.func,
    type:       PropTypes.string,
    buttontext: PropTypes.string,
    text:       PropTypes.any,
    value:      PropTypes.any,
    style:      PropTypes.any,
};

export default ImagedButton;
