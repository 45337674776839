import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Grid} from "@material-ui/core";

import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";
import {getAppLang} from "../../../../../../utils";

const localeMap = {
    es: esLocale,
    de: deLocale,
    tr: trLocale
};

const BulkUpdateDatTime = ({handleChangeDate}) => {

    const { t } = useTranslation();

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState( new Date( new Date().setDate(new Date().getDate()+1)) );

    const handleStartDateChange = date => {
        setStartDate(date);
        handleChangeDate(moment(date).format("YYYY-MM-DD"),null)
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleChangeDate(null,moment(date).format("YYYY-MM-DD"));
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
            <Grid item xs={12} md={6}>
                <DatePicker
                    margin="normal"
                    label={ t('enw-input-from_date')}
                    className="w-100"
                    variant="inline"
                    autoOk
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                    margin="normal"
                    label={ t('enw-input-to_date')}
                    variant="inline"
                    inputVariant="outlined"
                    className="w-100"
                    format="dd.MM.yyyy"
                    autoOk
                    value={endDate}
                    minDate={ moment(startDate)}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
export default BulkUpdateDatTime
