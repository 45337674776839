import React, {Component} from 'react';
import {
    propertyEditRateUpdate,
    PropertyRoomRates,
    getAccommodationTypes,
    PropertyRoomAndRateList, PropertyRoomAndRateListForRateCreate
} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {connect} from "react-redux";
import ButtonAtm from "../../../../atomic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RateConnectForm from "./RateConnectForm";

let t = null

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button"/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class RateConnect extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    state = {
        open: false,
        notificationStatus: false
    };


    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {open} = this.state;

        return (
            <>
                <ButtonAtm
                    buttontext={t('btn-connect_rate')}
                    fullwidth={true}
                    startIcon={<i className="fas fa-plus-circle"/>}
                    endIcon={<i className="fal fa-angle-right ml-29"/>}
                    onClick={this.handleClickOpen}
                />
                <Dialog onClose={this.handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                    <DialogTitle className="red-bottom-border" id="rate-plan" onClose={this.handleClose}>
                        {t('enw-room_and_rate-connect_rate_plan-title')}
                    </DialogTitle>
                    <DialogContent className="p-12">
                        <RateConnectForm
                            //onRateSyncUpdate={this.handlePropertyEditRateUpdate}
                            roomRates={this.props.roomRates}
                            connectRateFetching={this.props.connectRateFetching}
                            connectRateData={this.props.connectRateData}
                            open={this.state.open}
                            handleClose={this.handleClose}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    componentDidMount() {
        this.props.getAccommodationTypes();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.editRateUpdateStatus) {

            this.props.PropertyRoomRates();
            this.props.PropertyRoomAndRateList();
            this.props.PropertyRoomAndRateListForRateCreate();

            if (prevState.open === true) {
                this.setState({open: false})
            }
        }

        if (!this.props.editRateUpdateStatus && this.props.editRateUpdateError) {
            if (prevState.notificationStatus === false) {
                this.setState({notificationStatus: true})
            }
        }

    }
}

const mapStateToProps = (state) => {
    return {
        roomRates: state.propertyRoomAndRateReducers.roomRates,
        editRateData: state.propertyRoomAndRateReducers.getEditRateList,
        editRateFetching: state.propertyRoomAndRateReducers.getEditRateListFetching,
        connectRateData: state.propertyRoomAndRateReducers.connectRateData,
        connectRateFetching: state.propertyRoomAndRateReducers.connectRateFetching
    }
};

const mapDispatchToProps = {
    propertyEditRateUpdate,
    PropertyRoomRates,
    getAccommodationTypes,
    PropertyRoomAndRateList,
    PropertyRoomAndRateListForRateCreate
};

export default compose(withTranslation('common'))(connect(mapStateToProps, mapDispatchToProps)(RateConnect));
