import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CheckboxAtm from "../../../../../atomic/Checkbox/Checkbox";
import {UpdateChannelCancellation} from "../../../../../../redux/actions/Network/Channel/channelCancellation.actions";

let lastChangeID = null;
const CancellationPolicyCheckBox = (props) => {

    const {roomId, rateId, cancellationId, cancellationName, rateMappingId ,channelId} = props;
    const updateCancellationPolicyReducer = useSelector(props => props.updateCancellationPolicyReducer?.updatePolicy)
    const [isSelected , setIsSelected ] = useState( props.isSelected );
    const dispatch = useDispatch();


    const handleUpdateChild = (e) => {

        let params = {}
        if(isSelected){
            params = {
                channel_id: channelId,
                room_rate_channel_mapping_id: rateMappingId,
                cancellation_policy_id: cancellationId,
                is_selected: false
            };
            dispatch( UpdateChannelCancellation( params ) )
            lastChangeID = rateMappingId+""+cancellationId
        }else{
            params = {
                channel_id: channelId,
                room_rate_channel_mapping_id: rateMappingId,
                cancellation_policy_id: cancellationId,
                is_selected: true
            };
            dispatch( UpdateChannelCancellation( params ) )
            lastChangeID = rateMappingId+""+cancellationId
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {
        if (updateCancellationPolicyReducer?.status === 200 && lastChangeID === rateMappingId+""+cancellationId ){
            setState();
        }

    },[updateCancellationPolicyReducer])


    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={"room" + roomId + "rate" + rateId + cancellationId +" "+cancellationName}
            value={cancellationId}
            checked={isSelected}
            onChange={handleUpdateChild}
        />
    )


}
export default CancellationPolicyCheckBox;