import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useTranslation} from "react-i18next";
import {Skeleton} from "@material-ui/lab";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function ContentPdfLang(props) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        props.getLanguages()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectLanguage = (e, languageCode) => {
        props.getPdf(languageCode)
    }

    return (
        <div>
            <button className="download-content-pdf-btn" onClick={handleClickOpen}>
                <i className="fas fa-file-download"/> { t('btn-export_content_pdf') }
            </button>

            <Dialog onClose={handleClose} disableBackdropClick={true} className="content-pdf-modal" aria-labelledby="content-pdf" open={open}>
                <DialogTitle className="red-bottom-border" onClose={handleClose}> { t('enw-dashboard-pdf_export-title') }</DialogTitle>

                <List component="nav" aria-label="pdf language list" className="language-list">
                    {
                        props.languages.length > 0 ?
                            props.languages.map( (language) => (
                                <ListItem key={language.code} onClick={(e) => selectLanguage(e, language.code)} button>
                                    <ListItemIcon style={{minWidth: 40}}>
                                        <img style={{width:"20px"}} src={`/assets/img/language/${language.code}.svg`} alt={language.code} />
                                    </ListItemIcon>
                                    <ListItemText primary={t(language.language_key)} />
                                </ListItem>
                            ))
                            :
                            <div className="loading-pdf-lang">
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </div>
                    }
                </List>
            </Dialog>
        </div>
    );
}

