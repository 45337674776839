import React from "react";
import {NoSsr} from "@material-ui/core";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {convertAccentedCharacters, isDefined} from "../../../../../../utils";
import ReservationTicket from "./Tickets";
import SendBookingMail from "./sendBookingMail";
let t=null;

class Detail extends React.Component {

    constructor(props){
        super(props);
        t = props.t;

        this.state = {
            bookingRooms : Object.keys(this.props.reservation.booking_detail).length > 0 && Object.keys(this.props.reservation.booking_detail.booking_room).length ?
                this.props.reservation.booking_detail.booking_room : [],
            cancellationPolicies : null
        }
    }

    render() {

        const reservation = this.props.reservation &&
        Object.keys(this.props.reservation).length > 0 && Object.keys(this.props.reservation.booking_detail).length > 0 ? this.props.reservation.booking_detail : null;

        return reservation ? (
                <NoSsr>
                    <ColAtm className="offer-info-block">
                        <Grid container className="details-title">
                            <img src={"/assets/img/brands/" + reservation?.booking_channel?.logo} style={{width:"90px", margin: "auto", marginLeft: 20}} alt={reservation?.booking_channel?.name} />
                            <div>                                
                                {
                                    isDefined(reservation.voucher) &&
                                    <a href={reservation.voucher} className="voucher-link" target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link mr-4"/> {t('enw-label-voucher')}</a>
                                }
                                <SendBookingMail reservation={this.props.reservation.booking_detail}></SendBookingMail>
                            </div>
                        </Grid>
                        <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                            <Divider />
                        </Grid>
                        <Grid item container xs={12} className="reservation-info-block">
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-reservation-table-reservation_number') }</h3>
                                <p>{ reservation.booking_code || '-' }</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('env-input-channel') }</h3>
                                <p>
                                    { reservation.booking_channel.name || '-'}
                                </p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-reservation-detail-reservation_owner') }</h3>
                                <p> {reservation.booking_contact.name} {reservation.booking_contact.surname} </p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-time_of_transaction') }</h3>
                                <p>{ moment.unix(reservation.reservation_time).format("DD-MM-YYYY HH:mm:ss") }</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-start') }</h3>
                                <p>{ moment(reservation.checkin_date).format("DD-MM-YYYY") }</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-end') }</h3>
                                <p>{ moment(reservation.checkout_date).format("DD-MM-YYYY") }</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-phone') }</h3>
                                <p>{ reservation.booking_contact.phone_code+' '+reservation.booking_contact.phone_number || '-' }</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-email') }</h3>
                                <p>{ reservation.booking_contact.email || '-'}</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-reservation-table-payment_type') }</h3>
                                <p>
                                    { t(reservation.booking_payment_type.language_key) }
                                    {
                                        reservation.booking_payment.payment_source_code !== null ?
                                            reservation.booking_payment.payment_source_code === "OTA" ? <span> - OTA Credit Card</span> :
                                                reservation.booking_payment.payment_source_code === "GST" && <span> - Guest Credit Card</span> : null
                                    }
                                </p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-reservation-table-price') }</h3>
                                <p>{ reservation.total +" "+ reservation.currency_code || '-'}</p>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-status') }</h3>
                                {
                                    reservation.status === 0 ? <p className="red">{ t('enw-payment_transaction-status-cancel_refund') }  </p> :
                                        reservation.status === 1 ? <p className="green">{ t('enw-channels-channel_setup-booking-title') } </p> :
                                            reservation.status === 2 ? <p className="blue">{ t('enw-reservation-prebooking') } </p> :
                                                reservation.status === 3 && <p className="red">{ t('enw-reservation-no-show') } </p>
                                }
                            </Grid>
                            {
                                reservation.channel_booking_code !== null &&
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-reservation-table-channel_booking_code') }</h3>
                                    <p>{ reservation.channel_booking_code }</p>
                                </Grid>
                            }
                            {
                                isDefined(reservation.booking_contact.note) &&
                                <Grid item xs={12}>
                                    <h3>{ t('be-input-special_note') }</h3>
                                    <p dangerouslySetInnerHTML={{__html: reservation.booking_contact.note}} />
                                </Grid>
                            }
                        </Grid>
                    </ColAtm>
                    {
                        isDefined(reservation.booking_payment_transaction) && reservation.booking_payment_transaction.length > 0 &&
                        <ColAtm className="offer-info-block pb-24">
                            <h2>{ t('enw-reservation-detail-transaction-title') }</h2>
                            <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                <Divider />
                            </Grid>
                            <Grid item container xs={12} className="reservation-info-block pt-0 pb-0">
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-reservation-detail-bank_code') }</h3>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-reservation-detail-transaction_code') }</h3>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-input-fine') }</h3>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-payment-installment') }</h3>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-input-time_of_transaction') }</h3>
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <h3>{ t('enw-offer_list-column-status') }</h3>
                                </Grid>
                                {
                                    isDefined(reservation.booking_payment_transaction) &&
                                    reservation.booking_payment_transaction.map((transaction, transactionKey) => (
                                        <>
                                            {
                                                transactionKey > 0 &&
                                                <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                                    <Divider />
                                                </Grid>
                                            }
                                            <Grid item xs={6} lg={2}>
                                                <p> { transaction.bank_name || '-' }</p>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <p>{ transaction.code || '-' }</p>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                {
                                                    transaction.exchange_rate === 1 ?
                                                        <p>{ transaction.amount } { transaction.currency }</p> :
                                                        <p>{ transaction.amount } { transaction.currency } ({transaction.base_amount} { transaction.base_currency })</p>
                                                }
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <p>{ transaction.installment }</p>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <p>{ moment(transaction.created_at).format("DD-MM-YYYY HH:mm:ss")  || '-'  }</p>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                {
                                                    transaction.status === 0 ? <p className="red">{ t('enw-payment_transaction-status-error') }  </p> :
                                                        transaction.status === 1 ? <p className="green">{ t('enw-payment_transaction-status-success') } </p> :
                                                            transaction.status === 2 ? <p className="blue">{ t('enw-payment_transaction-status-start') } </p>  :
                                                                transaction.status === 3 ? <p className="orange">{ t('enw-payment_transaction-status-pending') } </p>:
                                                                    transaction.status === 4 &&  <p className="purple">{ t('enw-payment_transaction-status-cancel_refund') } </p>
                                                }
                                            </Grid>
                                            {
                                                isDefined(transaction.message) &&
                                                <Grid item xs={12}>
                                                    <h3>{ t('enw-reservation-detail-transaction_message-title') }</h3>
                                                    <p>{ transaction.message }</p>
                                                </Grid>
                                            }
                                        </>
                                    ))
                                }
                            </Grid>

                        </ColAtm>
                    }


                    {
                        reservation.booking_room.map((room) => (
                            <Grid container direction="row" spacing={2} className="mb-24">
                                {

                                    <>
                                        <Grid item xs={12} className="reservation-room-rate-title">
                                            <h6>
                                                <i className="fas fa-bed room-rate-bed" />
                                                {room.room_rate_mapping.property_room.name} ({t(room.room_rate_mapping.property_room.property_room_type.language_key)}) - {room.room_rate_mapping.property_room_rate.name} ({t(room.room_rate_mapping.property_room_rate.property_room_rate_accommodation.language_key)}) {room.room_pax.length > 0 && room.room_pax.length + " "+ t('env-input-guest') }
                                            </h6>
                                            {
                                                isDefined(room.cancellation_policy) &&

                                                <h5>
                                                    {(room.cancellation_policy.isFreeCancellation === 0 && room.cancellation_policy.isNonRefundable === 0 ? t('btn-revocable')+" " :
                                                        room.cancellation_policy.isNonRefundable === 1 ? t('btn-irrevocable') : t('enw-up_to_days', { day:room.cancellation_policy.beforeArrivalDay})+" " +t('btn-free_cancellation')) +
                                                    (room.cancellation_policy.isFreeCancellation !== 1 ? " - " + t('enw-reservation-detail-penalty_amount', { day:room.cancellation_policy.beforeArrivalDay})  + ": " : "") +
                                                    (room.cancellation_policy.value !== 0 ? room.cancellation_policy.value +
                                                        (room.cancellation_policy.type === "PER" ? "% " : " " + reservation.currency_code) : "")}
                                                </h5>
                                            }
                                        </Grid>
                                        {
                                            <Grid item xs={12}>
                                                <ColAtm className="offer-info-block">
                                                    <Grid container className="details-title">
                                                        <h2>{ t('btn-room_details') }</h2>
                                                        {reservation.booking_contact.extra_param && JSON.parse(reservation.booking_contact.extra_param).is_genius &&
                                                        <span className="voucher-link"><i className="fad fa-user-check mr-4"/>Booking.com Genius</span>
                                                        }

                                                    </Grid>
                                                    <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item container xs={12} className="reservation-info-block">
                                                        <Grid container xs={12} lg={6} className="mb-12">
                                                            {
                                                                room.occupancyFormatted &&
                                                                <Grid item xs={12} lg={4}>
                                                                    <h3>{ t('enw_occupancy-title') }</h3>
                                                                    <p>{ room.occupancyFormatted || '-' }</p>
                                                                </Grid>
                                                            }
                                                            {
                                                                room.daily_amount &&
                                                                <Grid item xs={12} lg={8}>
                                                                    <h3 className="ml-28-rez">{ t('enw_daily-amount') }</h3>
                                                                    { room.daily_amount.map((amount, index) => (
                                                                            <div className="txt-al-rez ml-28-rez" key={index} style={{width: 173, textAlign: "left", marginLeft: 28}}>
                                                                                <span style={{fontWeight: 500, color: "#1a1a1a"}}>{moment(amount.date).format('DD.MM.YYYY')}  <span style={{fontWeight: 600, color: "#000", marginLeft: 12}}> {amount.amount} {amount.currency_code}</span></span>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            }
                                                            {
                                                                room?.discount_amount > 0 &&
                                                                <Grid item xs={12} lg={4}>
                                                                </Grid>
                                                            }
                                                            {
                                                                 room?.discount_amount > 0 ?
                                                                <Grid className="mt-10-rez" item xs={12} lg={8}>
                                                                    <h3 className="ml-28-rez" style={{marginLeft: 28}}>{ t('enw_discount-amount') }</h3>
                                                                    <div style={{width: 173, textAlign: "left"}}>
                                                                        <div className="txt-al-rez" style={{width:450, textAlign: "left"}}>
                                                                            <span className="ml-28-rez" style={{fontWeight: 500, color: "#1a1a1a"}}>{ t('enw_discount-amount') }:  <span style={{fontWeight: 600, color: "#000", marginLeft: 12}}> -{room.discount_amount} {room.currency_code}</span></span>
                                                                        </div>
                                                                        <div className="txt-al-rez" style={{width:450, textAlign: "left"}}>
                                                                            <span className="ml-28-rez" style={{fontWeight: 500, color: "#1a1a1a"}}>{ t('enw_discount-price') }:  <span style={{fontWeight: 600, color: "#000", marginLeft: 12}}> {room.total} {room.currency_code}</span></span>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid className="ml-rez" item xs={12} lg={12}>
                                                                    <h3 className="ml-28-rez">{ t('enw-reservation-table-price') }</h3>
                                                                    <div className="txt-al-rez" style={{width: 173, textAlign: "left"}}>
                                                                        <span  className="fw-600 txt-al-rez ml-28-rez" style={{fontWeight: 500, color: "#1a1a1a", marginLeft: 28}}> 
                                                                            {room.total} {room.currency_code}
                                                                        </span>
                                                                    </div>
                                                                </Grid>
                                                                
                                                            }
                                                            
                                                        </Grid>

                                                        <Grid container xs={12} lg={6}>
                                                            {
                                                                room?.extra_param &&
                                                                <>
                                                                    {
                                                                        Object.values(room.extra_param).sort((a, b) => convertAccentedCharacters(a.title) > convertAccentedCharacters(b.title) ? 1:-1).map((data, index) => {
                                                                            return (
                                                                                <Grid key={index} item xs={12} sm={6} className="mb-12 pr-13">
                                                                                    {
                                                                                        data.title === "Cancel Penalties" ?
                                                                                            <>
                                                                                                <h3>{ data.title }</h3>
                                                                                                { data.value.map((cancel_penalties, index) => (
                                                                                                        <div key={index}>
                                                                                                    <span style={{fontWeight: 500, color: "#1a1a1a"}}>
                                                                                                        From {moment(cancel_penalties.from).format('ddd, MM-YYYY  HH:mm:ss')} - {cancel_penalties.amount} {cancel_penalties.currency}
                                                                                                    </span>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <h3>{data.title}</h3>
                                                                                                <p>{data.value || '-' }</p>
                                                                                            </>
                                                                                    }

                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                            {
                                                                isDefined(room.room_pax) && room.room_pax.length > 0 &&
                                                                room.room_pax.map((roomPax, index) => (
                                                                    <Grid item xs={12} lg={12} key={index}>
                                                                        <ColAtm>
                                                                            <Grid item container xs={12}>
                                                                                <Grid item xs={3}>
                                                                                    <h3>{ t('enw-input-name_surname') }</h3>
                                                                                    <p>{ roomPax.name+" "+roomPax.surname || ''}</p>
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <h3>{ t('enw-input-select-type') }</h3>
                                                                                    <p>{ roomPax.type === "ADT" ? t('enw-channels-adult') : t('enw-channels-child') || ''} ({ moment(roomPax.birth_date).format("DD-MM-YYYY") })</p>
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <h3>{ t('input-gender') }</h3>
                                                                                    <p>{ roomPax.gender === "M" ? t('enw-input-male') : t('enw-input-female') || ''}</p>
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <h3>{ t('env-input-citizen') }</h3>
                                                                                    <p>{ t(roomPax.pax_country.language_key ) || ''}</p>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ColAtm>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ColAtm>
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        ))
                    }

                    {
                        isDefined(reservation.booking_addon) && reservation.booking_addon.length > 0 && reservation.booking_addon !== "[]" && reservation.booking_addon !== [] &&
                        <ColAtm className="offer-info-block">
                            <h2>{ t('addons-header-text') }</h2>
                            <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                <Divider />
                            </Grid>

                            {
                                isDefined(reservation.booking_addon) &&
                                <Grid container spacing={4} className="reservation-info-block reservation-addon-block">
                                    {
                                        reservation.booking_addon.map((addon, index) => {
                                            return (
                                                <Grid item xs={12} lg={4} >
                                                    <Grid className="reservation-addon">
                                                        {
                                                            addon?.title &&
                                                            <>
                                                                <h3>{ t('be-label-additional-information') }</h3>
                                                                <p>{ t(addon?.title) || '-' }</p>
                                                                <p className="fw-600">{addon?.total} {addon?.currency_code}</p>
                                                            </>
                                                        }
                                                        {
                                                            isDefined(addon?.attribute) &&
                                                            <Grid container>
                                                                {
                                                                    addon?.attribute.map((attribute) => (
                                                                        <Grid item xs={6}>
                                                                            {
                                                                                attribute?.key &&
                                                                                <>
                                                                                    <h3>{ t(attribute.language_key) }</h3>
                                                                                    <p>{ attribute?.value || '-' }</p>
                                                                                </>
                                                                            }
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }

                                </Grid>
                            }
                        </ColAtm>
                    }
                    <ReservationTicket bookingCode={reservation.booking_code} />
                    {
                    reservation.booking_contact.invoice_request === 1 &&
                        <Grid item xs={12}>
                            <ColAtm className="offer-info-block">
                                <Grid container className="details-title">
                                    <h2>{ t('be-payment-billing_details') }</h2>
                                </Grid>
                                <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                    <Divider />
                                </Grid>
                                <Grid item container xs={12} className="reservation-info-block">
                                    <Grid container xs={12} lg={12} className="mb-12">
                                        <Grid item xs={12} lg={4}>
                                            <h3>{ t('input-name') } { t('input-surname') }</h3>
                                            <p>{reservation.booking_contact?.invoiceArray?.name_surname ? reservation.booking_contact?.invoiceArray?.name_surname : '-'}</p>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <h3>{ t('be-input-passport_or_id') }</h3>
                                            <p>{reservation.booking_contact?.invoiceArray?.citizen_number ? reservation.booking_contact?.invoiceArray?.citizen_number : '-'}</p>                                              
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <h3>{ t('be-input-company_name') }</h3>
                                            <p>{reservation.booking_contact?.invoiceArray?.company ? reservation.booking_contact?.invoiceArray?.company : '-'}</p>                                              
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <h3>{ t('be-input-tax_number') }</h3>
                                            <p>{reservation.booking_contact?.invoiceArray?.tax_number ? reservation.booking_contact?.invoiceArray?.tax_number : '-'}</p>                                              
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <h3>{ t('enw-input-billing_address') }</h3>
                                            <p>{reservation.booking_contact?.invoiceArray?.address ? reservation.booking_contact?.invoiceArray?.address : '-'}</p>                                              
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ColAtm>
                        </Grid>
                    }
                </NoSsr>
            ) :
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center">
                <h2>  { t('enw-notification-no_results') } </h2>
            </Grid>
    }


}

export default compose(withTranslation('common')) (Detail);
