import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import BlockAtm from "../../../../../atomic/Block/Block";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import UpdateChannelGroup from "./updateChannelGroup";

const GroupChannels = (props) => {
    const {channelGroupList} = props;

    return(
        <Grid container spacing={3}>
            {
                Object.values(channelGroupList.get_mapping).map( groupChannel => (
                    <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6}>
                        <UpdateChannelGroup
                            groupChannel={groupChannel}
                        />
                        <BlockAtm className="offer-fact-border-box fact-border-box channel-group-box">
                            <label htmlFor={"id-" + groupChannel.id}>
                                <label style={{position: 'relative'}}
                                       htmlFor={"id-" + groupChannel.id}>
                                    <div style={{backgroundImage: `url(/assets/img/brands/1.png)`}} className={"channel-logo-bg"} ></div>
                                    <OverFlowText>
                                        {groupChannel.name}
                                    </OverFlowText>
                                </label>
                            </label>
                        </BlockAtm>
                    </ColAtm>
                ))
            }
        </Grid>
    )
}
export default GroupChannels;
