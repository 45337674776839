import React from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import LabelAtm from "../../../../atomic/Label/Label";
import ButtonAtm from "../../../../atomic/Button/Button";
import BlockAtm from "../../../../atomic/Block/Block";
import Divider from "@material-ui/core/Divider";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { compose, withProps , lifecycle} from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps";
import Geocode from "react-geocode"
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Scrollchor from 'react-scrollchor';
import {isDefined, toElement as scrollToElement} from "../../../../../utils";
import RcViewer from '@hanyk/rc-viewer'
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ReactHtmlParser from "react-html-parser";
import {withTranslation} from "react-i18next";
import Slider from 'infinite-react-carousel';
import OfferApprove from "./OfferApprove";
import NotFoundOffer from "./NotFoundOffer";
import HeaderOfferLanguage from "../../../../organisms/HeaderLanguage/HeaderOfferLanguage";
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import {Hidden} from "@material-ui/core";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { Splide, SplideSlide } from '@splidejs/react-splide';


let t=null

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const GoogleSearchBox =compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC78drgom35hF5fEcJQciM7BJBFuIRE4Is&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `435px`, display: 'flex', flexDirection: 'column', width: '100%' }} />,
        mapElement: <div style={{ height: `100%`, order: '2', borderRadius: '8px'}} />,
    }),
    lifecycle({
        componentDidMount(){
            const refs = {};
            this.setState({
                places: [],
                name : "",
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();
                    this.setState({
                        places,
                        name: places[0].name
                    });
                    this.props.getPlaces(places)
                }
            });
        }
    }),
    withScriptjs,withGoogleMap
)(props =>(

    <GoogleMap  zoom={props.defaultZoom()} draggable={true} center={props.defaultCenter()}  defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        fullscreenControl: false,
        minZoom: 2,
        maxZoom: 20
    }}>
        {props.isMarkerShown && (
            <Marker draggable={false} position={props.defaultCenter()}/>
        )}
    </GoogleMap>

));

Geocode.setApiKey("AIzaSyC78drgom35hF5fEcJQciM7BJBFuIRE4Is");
Geocode.setLanguage("en");

class LandingOfferData extends React.Component{

    constructor(props) {
        super(props);
        t = props.t;
    }

    state = {
        title : '',
        accept_status : '',
        logo_url : '',
        offer_code	 : '',
        offer_id	 : '',
        expire_date : '',
        currency: '',
        language:'',
        language_key:'',
        code:'',
        name:'',
        official_name:'',
        tax_office:'',
        tax_number:'',
        property_chain : '',
        property_type : '',
        year_construction : '',
        year_renovation : '',
        number_of_rooms : '',
        checkin_time : '',
        checkout_time : '',
        facility_phone : '',
        facility_mobile : '',
        facility_mobile2 : '',
        facility_fax : '',
        facility_email : '',
        facility_web : '',
        facility_address : '',
        facility_facebook : '',
        facility_twitter : '',
        facility_instragram : '',
        facility_latitude : '',
        facility_longitude : '',
        color_code_1 : '',
        color_code_2 : '',
        color_code_3 : '',
        contact_mapping : [],
        important_notes : [{}],
        offerChange : false,
        open : false,
        showDiv: false,
        value : (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0) ? 0 : 1,
        findLocation: false,
        geoDestinations:{
            lat: "",
            lng: ""
        },
        propertyAddress:'',
        total_amount:'',
        zoomIn:18,
        destinationChange : false,
        selectedExecutive : '',
        maxAdults : '',
        maxChildren : '',
        maxRoomOccupancy : '',
        right: false,
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleChange = ( event, newValue ) => {
        this.setState( {value : newValue});
    };

    handleClickOpen = (id) => {
        let selectedExecutive;

        selectedExecutive =  this.props.landingOffer.offerData.offer.offer_contact_mapping.filter( (executive,key) => {
            return executive.id === id
            })
        this.setState({open:true, selectedExecutive:selectedExecutive});

    };

    handleClose = () => {
        this.setState({open:false})
    };

    componentDidMount() {

        let title = '';
        let accept_status = '';
        let logo_url = '';
        let offer_code = '';
        let offer_id = '';
        let currency =  '';
        let language =  '';
        let language_key =  '';
        let code =  '';
        let expire_date =  '';
        let name =  '';
        let official_name =  '';
        let tax_office =  '';
        let tax_number =  '';
        let property_chain = '';
        let property_type = '';
        let year_construction = '';
        let year_renovation = '';
        let number_of_rooms = '';
        let checkin_time = '';
        let checkout_time = '';
        let facility_phone = '';
        let facility_mobile = '';
        let facility_mobile2 = '';
        let facility_fax = '';
        let facility_email = '';
        let facility_web = '';
        let facility_address = '';
        let facility_facebook = '';
        let facility_twitter = '';
        let facility_instragram = '';
        let lat = '';
        let lng = '';
        let total_amount = '';
        let amount = '';
        let color_code_1 = '';
        let color_code_2 = '';
        let color_code_3 = '';
        let contact_mapping = [];

        if(this.props.landingOffer.offerData.length  !== 0 ) {
            const {landingOffer} = this.props;

            if (isDefined(landingOffer.offerData.property_brand.logo_url)) {
                logo_url = landingOffer.offerData.property_brand.logo_url
            }

            if(isDefined(landingOffer.offerData.property_brand.color_codes)){
                Object.values(landingOffer.offerData.property_brand.color_codes).map( key => {

                    switch (key.color_number) {
                        case 1:
                            color_code_1 = key.color_code
                            break;
                        case 2:
                            color_code_2 = key.color_code
                            break;
                        case 3:
                            color_code_3 = key.color_code
                            break;
                    }
                })
            }

            Object.keys(landingOffer.offerData.offer).map( key => {

                switch (key) {
                    case 'title':
                        title = landingOffer.offerData.offer.title
                        break;
                    case 'accept_status':
                        accept_status = landingOffer.offerData.offer.accept_status
                        break;
                    case 'logo_url':
                        logo_url = landingOffer.offerData.offer.logo_url
                        break;
                    case 'offer_code':
                        offer_code = landingOffer.offerData.offer.offer_code
                        break;
                    case 'id':
                        offer_id = landingOffer.offerData.offer.id
                        break;
                    case 'expire_date':
                        expire_date = landingOffer.offerData.offer.expire_date
                        break;
                    case 'currency':
                        currency = landingOffer.offerData.offer.currency
                        break;
                    case 'language':
                        language = landingOffer.offerData.offer.language
                        break;
                }
            })

            Object.keys(landingOffer.offerData.offer.offer_language).map( key => {

                switch (key) {
                    case 'language_key':
                        language_key = landingOffer.offerData.offer.offer_language.language_key
                        break;
                    case 'code':
                        code = landingOffer.offerData.offer.offer_language.code
                        break;
                }
            })

            Object.keys(landingOffer.offerData.property).map( key => {

                switch (key) {
                    case 'name':
                        name = landingOffer.offerData.property[key]
                        break;
                    case 'official_name':
                        official_name = landingOffer.offerData.property[key]
                        break;
                    case 'tax_number':
                        tax_number = landingOffer.offerData.property[key]
                        break;
                    case 'tax_office':
                        tax_office = landingOffer.offerData.property[key]
                        break;
                    case 'property_chain':
                        property_chain = landingOffer.offerData.property.property_chain.name
                        break;
                    case 'property_type':
                        property_type = landingOffer.offerData.property.property_type.name
                        break;
                }
            })

            Object.keys(landingOffer.offerData.property.additional_info).map( key => {

                switch (key) {
                    case 'year_construction':
                        year_construction = landingOffer.offerData.property.additional_info.year_construction
                        break;
                    case 'year_renovation':
                        year_renovation = landingOffer.offerData.property.additional_info.year_renovation
                        break;
                    case 'number_of_rooms':
                        number_of_rooms = landingOffer.offerData.property.additional_info.number_of_rooms
                        break;
                    case 'checkin_time':
                        checkin_time = landingOffer.offerData.property.additional_info.checkin_time
                        break;
                    case 'checkout_time':
                        checkout_time = landingOffer.offerData.property.additional_info.checkout_time
                        break;
                }
            })

            Object.keys(landingOffer.offerData.property.contact).map( key => {

                switch (key) {
                    case 'phone':
                        facility_phone = landingOffer.offerData.property.contact.view_full_phone
                        break;
                    case 'mobile':
                        facility_mobile = landingOffer.offerData.property.contact.view_full_mobile
                        break;
                    case 'mobile2':
                        facility_mobile2 = landingOffer.offerData.property.contact.view_full_mobile2
                        break;
                    case 'fax':
                        facility_fax = landingOffer.offerData.property.contact.view_full_fax
                        break;
                    case 'email':
                        facility_email = landingOffer.offerData.property.contact.email
                        break;
                    case 'web':
                        facility_web = landingOffer.offerData.property.contact.web
                        break;
                    case 'address':
                        facility_address = landingOffer.offerData.property.contact.address
                        break;
                    case 'latitude':
                        lat = landingOffer.offerData.property.contact.latitude
                        break;
                    case 'longitude':
                        lng = landingOffer.offerData.property.contact.longitude
                        break;
                }
            })

            Object.keys(landingOffer.offerData.property.contact.social_media_addresses).map( key => {

                switch (key) {
                    case 'facebook':
                        facility_facebook = landingOffer.offerData.property.contact.social_media_addresses.facebook
                        break;
                    case 'twitter':
                        facility_twitter = landingOffer.offerData.property.contact.social_media_addresses.twitter
                        break;
                    case 'instagram':
                        facility_instragram = landingOffer.offerData.property.contact.social_media_addresses.instagram
                        break;
                }
            })

            Object.keys(landingOffer.offerData.room_price).map( key => {

                switch (key) {
                    case 'total_amount':
                        total_amount = landingOffer.offerData.room_price.total_amount
                        break;
                    case 'amount':
                        amount = landingOffer.offerData.room_price.amount
                        break;
                }
            })
        }

        this.setState({
            title : title,
            accept_status : accept_status,
            logo_url : logo_url,
            offer_code : offer_code,
            offer_id : offer_id,
            currency: currency,
            language: language,
            language_key: language_key,
            code: code,
            expire_date: expire_date,
            name: name,
            official_name: official_name,
            tax_number: tax_number,
            tax_office: tax_office,
            property_chain: property_chain,
            property_type: property_type,
            year_construction: year_construction,
            year_renovation: year_renovation,
            number_of_rooms: number_of_rooms,
            checkin_time: checkin_time,
            checkout_time: checkout_time,
            facility_phone: facility_phone,
            facility_mobile: facility_mobile,
            facility_mobile2: facility_mobile2,
            facility_fax: facility_fax,
            facility_email: facility_email,
            facility_web: facility_web,
            facility_address: facility_address,
            facility_facebook: facility_facebook,
            facility_twitter: facility_twitter,
            facility_instragram: facility_instragram,
            color_code_1: color_code_1,
            color_code_2: color_code_2,
            color_code_3: color_code_3,
            geoDestinations:{
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },


            contact_mapping : contact_mapping,
            total_amount : total_amount,
            amount : amount,
            offerChange:false
        },() => {
        });

        localStorage.setItem("offerLang", language);
    }

    buildDialog = () => {
        if (this.state.selectedExecutive !== undefined && this.state.selectedExecutive !== '' ) {
            return <Dialog onClose={this.handleClose} aria-labelledby="rate-plan" open={this.state.open}>
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.handleClose}>
                   { t('enw-general-tab-employees') }
                </DialogTitle>
                <DialogContent>
                    <ColAtm>
                        <ColAtm className="mb-24 p-8">
                            { isDefined(this.state.selectedExecutive[0].name_surname) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-name_surname') } />
                                <LabelAtm text={this.state.selectedExecutive[0].name_surname}/>
                            </ColAtm>
                            }
                            { isDefined(this.state.selectedExecutive[0].executive_type) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-contact_type') } />
                                <LabelAtm text={ t(this.state.selectedExecutive[0].executive_type) } />
                            </ColAtm>
                            }
                            { isDefined(this.state.selectedExecutive[0].view_full_mobile) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-mobile_phone') } />
                                <LabelAtm text={this.state.selectedExecutive[0].view_full_mobile}/>
                            </ColAtm>
                            }
                            { isDefined(this.state.selectedExecutive[0].view_full_phone) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-phone') } />
                                <LabelAtm text={this.state.selectedExecutive[0].view_full_phone}/>
                            </ColAtm>
                            }
                            { isDefined(this.state.selectedExecutive[0].view_full_fax) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-fax') } />
                                <LabelAtm text={this.state.selectedExecutive[0].view_full_fax}/>
                            </ColAtm>
                            }
                            { isDefined(this.state.selectedExecutive[0].email) &&
                            <ColAtm sm={3} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-email') } />
                                <LabelAtm text={this.state.selectedExecutive[0].email}/>
                            </ColAtm>
                            }
                        </ColAtm>
                    </ColAtm>
                </DialogContent>
            </Dialog>

        }
    }

    contactInformation = () => {
        const firstColor = {
            color: this.state.color_code_1
        };
        let executives = '';

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0 ){
            executives =  this.props.landingOffer.offerData.offer.offer_contact_mapping.map( (executive) => (
                    <ColAtm className="offer-contact-carousel">
                        <ButtonBase
                            focusRipple
                            onClick={(e) => this.handleClickOpen(executive.id)}
                        >
                            <ColAtm className="offer-contact-box">
                                <BlockAtm className="offer-contact-icon">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <i style={firstColor} className="fas fa-address-card m-auto" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LabelAtm labelClass="fs-12" text={executive.name_surname} />
                                        </Grid>
                                    </Grid>
                                </BlockAtm>
                                <BlockAtm className="offer-contact-title">
                                    <LabelAtm text={ t(executive.executive_type)} />
                                </BlockAtm>
                            </ColAtm>
                        </ButtonBase>
                    </ColAtm>
            ))
        }
        return executives;
    }

    photoListCarousel = () => {
        let photoCarousel = '';

        const secondaryColor = {
            color: this.state.color_code_2,
            backgroundColor: '#f4f7fc',
        };
        const { value } = this.state;

        const options={
            movable: false,
        }

        const secondaryOptions = {
            isNavigation: true,
            perPage: 1,
            perMove: 1,
            autoWidth: true,
            pagination: false
        };

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0 ){

            photoCarousel =  <TabPanel value={value} index={0} className="photo-map-tab">
                <RcViewer options={options} ref='viewer'>
                    <Splide
                        options={ secondaryOptions }

                        renderControls={ () => (
                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev" style={secondaryColor}>
                                    <i className="fas fa-caret-left" />
                                </button>
                                <button className="splide__arrow splide__arrow--next" style={secondaryColor}>
                                    <i className="fas fa-caret-right" />
                                </button>
                            </div>
                        ) }
                    >
                        {
                            (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0 ) ?
                            this.props.landingOffer.offerData.offer.offer_photo_mapping.map( (photo,key) => (
                            <SplideSlide key={ photo.medium_image_url }>
                                <img key={key} src={photo.medium_image_url} />
                            </SplideSlide>
                            ))
                                :
                                <></>
                        }
                    </Splide>
                </RcViewer>
            </TabPanel>
        }
        return photoCarousel

    }

    photoListTab = () => {

        let photoTab = "";
        const { value } = this.state;
        const secondaryColor = {
            color: this.state.color_code_2,
            backgroundColor: this.state.color_code_2 + '21',
        };

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0 ){

            photoTab =
                <AppBar position="static" className="photo-map-tab-header">
                    <Tabs value={value} onChange={this.handleChange} aria-label="photos and map tabs">
                        <Tab style={secondaryColor} label={ t('enw-photos-title') } {...a11yProps(0)} />
                        <Tab style={secondaryColor} label={ t('enw-general-tab-location') } {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
        }

        return photoTab

    }

    coverPhoto = () => {
        let photos = '';

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_cover_photos.length !== 0 ){
            photos =  this.props.landingOffer.offerData.offer.offer_cover_photos.map( (cover,key) => (
                <img key={key} src={cover.medium_image_url} />
            ))
        }
        return photos;
    }

    cancellationPolicy = () => {
        let policies = '';

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_cancellation_policy.length !== 0 ){
            policies =  this.props.landingOffer.offerData.offer.offer_cancellation_policy.map( (policy,key) => (
                    <div className="offer-important-note w-100" key={key}>
                        <i className="fas fa-circle" />
                        <p>{policy.days_before} {t("enw-input-days_before")} :
                            {( policy.type === "PER" ?
                                (
                                    <span>{policy.value} %</span>
                                )
                                : (
                                    <span>{policy.value} {this.state.currency}</span>
                                )
                        )} </p>
                    </div>

            ))
        } else {
            policies =
                <div className="offer-important-note">
                    <p>{ t('enw-landing_offer-non_cancelled_offer') }</p>
                </div>
        }
        return policies;
    }

    offerStatus = () => {
        let offerStatus = '';

        if(this.props.landingOffer.offerData.length !== 0){

            if (this.props.landingOffer.offerData.offer.confirm_type === "HTL" &&
                this.props.landingOffer.offerData.offer.accept_status === 3) {
                offerStatus =
                    <ColAtm className="offer-rooms offer-warning">
                        <h2>{ t('enw-offer-approve-status-title') }</h2>
                        <div className="offer-important-note">
                            <p>{ t('enw-landing_offer-status-one') }</p>
                        </div>
                    </ColAtm>

            } else if (this.props.landingOffer.offerData.offer.confirm_type === "HTL" &&
                this.props.landingOffer.offerData.offer.accept_status === 2) {
                offerStatus =
                    <ColAtm className="offer-rooms offer-warning">
                        <h2>{ t('enw-offer-approve-status-title') }</h2>
                        <div className="offer-important-note">
                            <p>{ t('enw-landing_offer-status-two') }</p>
                        </div>
                    </ColAtm>
            } else if (this.props.landingOffer.offerData.offer.accept_status === 1) {
                offerStatus =
                    <ColAtm className="offer-rooms offer-success">
                        <h2>{ t('enw-offer-approve-status-title') }</h2>
                        <div className="offer-important-note">
                            <p>{ t('enw-landing_offer-status-three') }</p>
                        </div>
                    </ColAtm>
            } else if (this.props.landingOffer.offerData.offer.accept_status === 0) {
                offerStatus =
                    <ColAtm className="offer-rooms offer-error">
                        <h2>{ t('enw-offer-approve-status-title') }</h2>
                        <div className="offer-important-note">
                            <p>{ t('enw-landing_offer-status-four') }</p>
                        </div>
                    </ColAtm>
            }
            return offerStatus;
        }
    }

    importantNotes = () => {
        let notes = '';

        if(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_important_notes.length !== 0 ){
            notes =  this.props.landingOffer.offerData.offer.offer_important_notes.map( (note,key) => (
                <div key={key} className="offer-important-note">
                    <i className="fas fa-circle" />
                    <LabelAtm labelClass="text" text={note.note} />
                </div>
            ))
        }
        return notes;
    }

    amenitiesFacts =  () =>{

        const firstColor = {
            color: this.state.color_code_1
        };

        let amentiiesFactList = '';
        let amentiiesFacts = '';
        const {offerData} = this.props.landingOffer;
        if( this.props.landingOffer.offerData.length !== 0 &&
            this.props.landingOffer.offerData.property_fact[1].length !== 0
        ){

            amentiiesFacts = Object.keys(offerData.property_fact[1].fact_subcategory).map( (factSubCatKey, key) => {

                return <Grid item xs={12} className="pt-4 pb-4" >
                    <ExpansionPanel className="features-table offer-table" key={key}>
                        <ExpansionPanelSummary
                            expandIcon={<i className="fas fa-sort-down" />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header">
                            <ColAtm>
                                <ColAtm md={11}>
                                    <i style={firstColor} className={`room-rate-bed m-w-36 ${this.props.landingOffer.offerData.property_fact[1].fact_subcategory[factSubCatKey].icon}`}  />
                                    <LabelAtm labelClass="room-rate-list-title" text={ t(this.props.landingOffer.offerData.property_fact[1].fact_subcategory[factSubCatKey].language_key) } />
                                </ColAtm>
                            </ColAtm>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item" key={key}>
                            {
                                amentiiesFactList = this.props.landingOffer.offerData.property_fact[1].fact_subcategory[factSubCatKey].fact.map( (fact, key) => (

                                    <ColAtm className="mb-24 mt-24" xs={6} sm={4} md={3} key={key}>
                                        <p className="offer-facts"><i className={fact.icon} /> { t(fact.language_key) }</p>
                                    </ColAtm>
                                ))
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

            })
            return amentiiesFacts;
        }
    }

    facilityFacts =  () =>{

        const firstColor = {
            color: this.state.color_code_1
        };

        let facilityFactList = '';
        let facilityFacts = '';

        const {offerData} = this.props.landingOffer;
        if( this.props.landingOffer.offerData.length !== 0 &&
            this.props.landingOffer.offerData.property_fact[44].length !== 0
        ){

            facilityFacts = Object.keys(offerData.property_fact[44].fact_subcategory).map( (factSubCatKey, key) => {

                return <Grid item xs={12} className="pt-4 pb-4" >
                            <ExpansionPanel className="features-table offer-table" key={key}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-sort-down" />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header">
                                    <ColAtm>
                                        <ColAtm md={11}>
                                            <i style={firstColor} className={`room-rate-bed m-w-36 ${this.props.landingOffer.offerData.property_fact[44].fact_subcategory[factSubCatKey].icon} `}  />
                                            <LabelAtm labelClass="room-rate-list-title" text={ t(this.props.landingOffer.offerData.property_fact[44].fact_subcategory[factSubCatKey].language_key) } />
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item" key={key}>

                                {
                                    facilityFactList = this.props.landingOffer.offerData.property_fact[44].fact_subcategory[factSubCatKey].fact.map( (fact, key) => (

                                            <ColAtm className="mb-24 mt-24" xs={6} sm={4} md={3} key={key}>
                                                <p className="offer-facts"><i className={fact.icon} /> { t(fact.language_key) }</p>
                                            </ColAtm>
                                    ))
                                }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                </Grid>

            })
            return facilityFacts;
        }
    }

    roomInfo =  () =>{

        const firstColor = {
            color: this.state.color_code_1
        };
        let facilityFactList = '';
        let facilityFacts = '';
        let appendIcon = '';
        const options={
            movable: false,
            url(image) {
                return image.src.replace('_thumbnail', '').replace('_200x200', '');
            },
        }

        const {offerData} = this.props.landingOffer;
        if( this.props.landingOffer.offerData.length !== 0 &&
            this.props.landingOffer.offerData.room_price.room_list.length !== 0
        ){

            facilityFacts = Object.keys(offerData.room_price.room_list).map( (roomList, key) => {

                return <div key={key}>
                    {
                        facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].accommodation.map( (room, subKey) => (

                            <ColAtm className="offer-rooms" key={subKey}>
                                <ColAtm md={3}>
                                        <Carousel
                                            slidesPerPage={1}
                                            draggable={false}
                                    >
                                            <RcViewer options={options} ref='viewer' className="offer-room-photos">

                                            {
                                                facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].room_photos.map( (photo) => (
                                                        <img src={photo.thumb} style={{cursor: 'zoom-in'}} />
                                                ))
                                            }
                                            </RcViewer>

                                        </Carousel>
                                </ColAtm>

                                <ColAtm md={5} className="d-block offer-room-info">
                                    <ColAtm>
                                        <h3>  {this.props.landingOffer.offerData.room_price.room_list[key].name} </h3>
                                    </ColAtm>
                                    <ColAtm>
                                        { isDefined(room.language_key) &&
                                        <span>&#9679;  { t(room.language_key) }  </span>
                                        }
                                        { isDefined(this.props.landingOffer.offerData.room_price.room_list[key].max_occupancy) &&
                                        <span>&#9679; {this.props.landingOffer.offerData.room_price.room_list[key].max_occupancy} { t('web-offer-person') } </span>
                                        }
                                        { isDefined(this.props.landingOffer.offerData.room_price.room_list[key].room_size) &&
                                        <span>&#9679; {this.props.landingOffer.offerData.room_price.room_list[key].room_size} {this.props.landingOffer.offerData.room_price.room_list[key].room_size_type} </span>
                                        }
                                    </ColAtm>

                                    {
                                        facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].room_views.map( (view) => (
                                            <span>&#9679;  { t(view.language_key) } </span>
                                        ))
                                    }
                                    { isDefined(this.props.landingOffer.offerData.room_price.room_list[key].property_room_type.language_key) &&
                                        <span>&#9679;  { t(this.props.landingOffer.offerData.room_price.room_list[key].property_room_type.language_key) }  </span>
                                    }
                                </ColAtm>
                                <ColAtm md={4} className="d-block">
                                    <h3 className="offer-room-price">{room.price.amount}<span> {this.state.currency}</span></h3>
                                </ColAtm>
                                <ColAtm>
                                    <ColAtm xs={12}>
                                        <ExpansionPanel className="offer-room-table offer-table" key={key}>
                                            <ExpansionPanelSummary
                                                expandIcon={<i className="fas fa-sort-down" />}
                                                aria-label="Expand"
                                                aria-controls="additional-actions1-content"
                                                id="additional-actions1-header">
                                                <ColAtm>
                                                    <ColAtm md={12}>
                                                        <LabelAtm style={firstColor} labelClass="room-rate-list-title" text={ t('btn-room_details') } />
                                                    </ColAtm>
                                                </ColAtm>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item offer-room-facts">
                                                <h2 className="mb-16"> { t('web-occupancy-types-title') }</h2>
                                                <ColAtm className="mb-24 offer-occupancy-box">
                                                    {
                                                        Object.values(this.props.landingOffer.offerData.room_price.room_list[key].include_occupancy).map( (occupancy) => (
                                                            <div className="occupancy-icons">
                                                                {ReactHtmlParser(occupancy
                                                                    .replace(/A/g, '<div><i class="room-type-icon fas fa-male"/></div>')
                                                                    .replace(/C/g, '<div><i class="room-type-icon fas fa-child"/></div>'))}
                                                            </div>
                                                            ))
                                                    }
                                                </ColAtm>

                                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.room_price.room_list[key].room_fact.length > 0 ) &&
                                                <ColAtm>
                                                    <h2 className="mb-16"> { t('enw-room-tab-room_amenities') }</h2>
                                                </ColAtm>
                                                }

                                                {
                                                    facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].room_fact.map( (fact, subKey) => (

                                                        <ColAtm className="room-offer-fact" sm={4} key={subKey}>
                                                            <p className="offer-facts"><i className={fact.icon} /> { t(this.props.landingOffer.offerData.room_price.room_list[key].room_fact[subKey].language_key) } </p>
                                                        </ColAtm>
                                                    ))
                                                }
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                        <ExpansionPanel className="offer-room-table offer-table" key={key}>
                                            <ExpansionPanelSummary
                                                expandIcon={<i className="fas fa-sort-down" />}
                                                aria-label="Expand"
                                                aria-controls="additional-actions1-content"
                                                id="additional-actions1-header">
                                                <ColAtm>
                                                    <ColAtm md={12}>
                                                        <LabelAtm style={firstColor} labelClass="room-rate-list-title" text={ t('btn-price_details') } />
                                                    </ColAtm>
                                                </ColAtm>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item offer-room-facts">

                                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.room_price.room_list[key].accommodation.length > 0 ) &&
                                                <ColAtm>
                                                    <h2 className="mb-16"> { t('enw-room-tab-room_price') }</h2>
                                                </ColAtm>
                                                }

                                                {
                                                    facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].accommodation[subKey].price_daily.map( (price, subKey) => (

                                                        <ColAtm className="room-offer-fact f-w-600 daily-price" sm={12} key={subKey}>
                                                            <span className="f-w-500 mr-4">{price?.date_formatted}: </span> <span>{price?.amount} {this.state.currency} </span>
                                                        </ColAtm>
                                                    ))
                                                }
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </ColAtm>
                                </ColAtm>
                            </ColAtm>
                        ))
                    }
                    {appendIcon = ''}
                </div>
            })
            return facilityFacts;
        }
    }

    roomPrice =  () =>{

        let facilityFactList = '';
        let facilityFacts = '';
        const {offerData} = this.props.landingOffer;
        if( this.props.landingOffer.offerData.length !== 0 &&
            this.props.landingOffer.offerData.room_price.room_list.length !== 0
        ){

            facilityFacts = Object.keys(offerData.room_price.room_list).map( (roomList, key) => {

                return <ColAtm key={key}>
                    {
                        facilityFactList = this.props.landingOffer.offerData.room_price.room_list[key].accommodation.map( (accommodation, subKey) => (

                            <ColAtm xs={12} key={subKey}>
                                <ColAtm xs={9}>
                                    <p className="offer-detail-price"><span className="fs-12">{this.props.landingOffer.offerData.room_price.room_list[key].accommodation[subKey].price.number_of_rooms}</span> x  {this.props.landingOffer.offerData.room_price.room_list[key].name} { t(accommodation.language_key) } { t('web-offer-price') } :</p>
                                </ColAtm>
                                <ColAtm xs={3}>
                                    <span className="ribbon__div"> {this.props.landingOffer.offerData.room_price.room_list[key].accommodation[subKey].price.total_amount}‬ {this.state.currency}</span>
                                </ColAtm>
                            </ColAtm>
                        ))
                    }
                </ColAtm>


            })
            return facilityFacts;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.props.approveOfferSubmit( this.state, this.props.offer_code, this.state.offer_id );
        this.setState({
            offerChange : this.state.offer_code
        })
    };

    scrollToNext() {
        const nextPage = document.querySelector(".scroll");
        scrollToElement(nextPage);
    }

    scrollButton () {
        const thirdColor = {
            color: this.state.color_code_3,
            fill: this.state.color_code_3,
        };

        return  <div className="scroll-to-next p-absolute" onClick={(e) => this.scrollToNext()}>
            <div className="scroll-text" style={thirdColor}>{ t('btn-scroll') } </div>
            <span className="svg svg-icon-arrow-next" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 8" style={thirdColor}>
                <path d="M44 4.5l-3.1 2.8.7.7L46 4l-4.4-4-.7.7L44 3.5"></path>
                <path className="short" d="M44 3.5H-.5v1H44"></path>
                <path className="long" d="M-99 4.5H44v-1H-99"></path>
              </svg>
        </span>
        </div>
    }

    render() {

        const { showDiv, value, accept_status } = this.state;

        const firstColor = {
            backgroundColor: this.state.color_code_1,
        };
        const secondaryColor = {
            color: this.state.color_code_2,
            backgroundColor: this.state.color_code_2 + '21',
        };
        const contactSliderSettings =  {
            dots: true,
            arrows: false,
            arrowsBlock: false,
            slidesPerRow: 4
        };
        const contactSliderMobileSettings =  {
            dots: true,
            arrows: false,
            arrowsBlock: false,
            slidesPerRow: 2
        };
        const { classes } = this.props;

        const sideList = (
            <div className={classes.list}>
                <ListItem className="d-block offer-mobile-menu">
                    <p className="offer-menu-title"> Menü</p>

                    { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0) &&
                    <Scrollchor to="#photos" className="nav-link">
                        <LabelAtm labelClass="user-menu-title mb-20" text={t('enw-photos-title') } />
                    </Scrollchor>
                    }
                    { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.property_fact[44].length !== 0 && this.props.landingOffer.offerData.property_fact[1].length !== 0) &&
                    <Scrollchor to="#amenities" className="nav-link">
                        <LabelAtm labelClass="user-menu-title mb-20" text={t('enw-facilities-title')+ " & " + t('enw-amenities-title') } />
                    </Scrollchor>
                    }
                    { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0 ) &&
                    <Scrollchor to="#contacts" className="nav-link">
                        <LabelAtm onClick={() => this.setState({ showDiv: true })} labelClass="user-menu-title mb-20" text={t('enw-general-tab-communication') } />
                    </Scrollchor>
                    }
                    <Scrollchor to="#facilityInfo" className="nav-link">
                        <LabelAtm labelClass="user-menu-title mb-20" text={ t('enw-general-information-subtitle')} />
                    </Scrollchor>
                    { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_important_notes.length !== 0) &&
                    <Scrollchor to="#notes" className="nav-link">
                        <LabelAtm labelClass="user-menu-title mb-20" text={ t('enw-offer-important_notes-title')} />
                    </Scrollchor>
                    }
                    <Scrollchor to="#rooms" className="nav-link">
                        <LabelAtm labelClass="user-menu-title mb-20" text={ t('btn-total_offer_price')} />
                    </Scrollchor>
                </ListItem>
            </div>
        );
        return (
                <>
                    <ColAtm sm={12}>
                        <ColAtm sm={12}>
                            <div className="flex-grow-1">
                                <Toolbar className="offer-top-menu">
                                    <ColAtm sm={12}>
                                        <ColAtm xs={6} sm={5} className="offer-menu-left">
                                            <img src={this.state.logo_url} />
                                        </ColAtm>
                                        <ColAtm xs={6} sm={7} className="offer-menu-right" style={firstColor}>
                                            <Hidden smDown>
                                                <Scrollchor to="#facilityInfo" className="nav-link">
                                                    <Button color="inherit"> { t('enw-general-information-subtitle') } </Button>
                                                </Scrollchor>
                                            </Hidden>
                                            <Hidden smDown>
                                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0 ) &&
                                                <Scrollchor to="#contacts" className="nav-link">
                                                    <Button color="inherit" onClick={() => this.setState({ showDiv: true })}> { t('enw-general-employees-subtitle') } </Button>
                                                </Scrollchor>
                                                }
                                            </Hidden>
                                            <Hidden smDown>
                                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.property_fact[44].length !== 0 && this.props.landingOffer.offerData.property_fact[1].length !== 0) &&
                                                <Scrollchor to="#facilities" className="nav-link">
                                                    <Button color="inherit"> { t('enw-facilities-title') } & { t('enw-amenities-title') }</Button>
                                                </Scrollchor>
                                                }
                                            </Hidden>
                                            <Hidden smDown>
                                                <Scrollchor to="#footer" className="nav-link">
                                                    <Button color="inherit"> { t('enw-general-tab-communication') }</Button>
                                                </Scrollchor>
                                            </Hidden>

                                            <HeaderOfferLanguage mainLang={this.state.code} keyLang={this.state.language_key} />
                                            <Hidden mdUp>
                                                <div>
                                                    <Button onClick={this.toggleDrawer('right', true)}>
                                                        <i className="far fa-grip-lines" />
                                                    </Button>
                                                    <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                                                        <div
                                                            tabIndex={0}
                                                            role="button"
                                                            onClick={this.toggleDrawer('right', false)}
                                                            onKeyDown={this.toggleDrawer('right', false)}
                                                        >
                                                            {sideList}
                                                        </div>
                                                    </Drawer>
                                                </div>

                                            </Hidden>
                                        </ColAtm>
                                    </ColAtm>
                                </Toolbar>
                            </div>
                        </ColAtm>
                        <ColAtm xs={12} md={5} className="offer-top-left">
                            <ColAtm sm={12} className="offer-carousel-height">
                                {this.coverPhoto()}
                            </ColAtm>
                        </ColAtm>
                        <ColAtm xs={12} md={7} className="offer-top-right" style={firstColor}>
                            <>
                                <ColAtm sm={2}>
                                </ColAtm>
                                <ColAtm sm={6} className="m-auto">
                                    <ColAtm>
                                        <LabelAtm labelClass="offer-welcome-text" text={ t('enw-landing_offer-welcome-slogan', { name:this.state.name}) } />
                                    </ColAtm>
                                    <ColAtm>
                                        <LabelAtm labelClass="offer-slogan-text" text={this.state.title} />
                                    </ColAtm>
                                    <ColAtm>
                                        <p className="offer-end-date-text"> { t('enw-landing_offer-expire_date', { date:this.state.expire_date } ) } </p>
                                    </ColAtm>
                                </ColAtm>
                            </>
                            {this.scrollButton()}
                        </ColAtm>
                    </ColAtm>
                    <Hidden smDown>
                        <ColAtm className="offer-submenu">
                            <ColAtm sm={2}>
                            </ColAtm>
                            <ColAtm sm={8}>
                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_photo_mapping.length !== 0) &&
                                <Scrollchor to="#photos" className="nav-link">
                                    <p>{ t('enw-photos-title') }</p>
                                </Scrollchor>
                                }
                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.property_fact[44].length !== 0 && this.props.landingOffer.offerData.property_fact[1].length !== 0) &&
                                <Scrollchor to="#amenities" className="nav-link">
                                    <p> { t('enw-facilities-title') } & { t('enw-amenities-title') } </p>
                                </Scrollchor>
                                }
                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0 ) &&
                                <Scrollchor to="#contacts" className="nav-link">
                                    <p onClick={() => this.setState({ showDiv: true })}> { t('enw-general-tab-communication') }</p>
                                </Scrollchor>
                                }
                                <Scrollchor to="#facilityInfo" className="nav-link">
                                    <p> { t('enw-general-information-subtitle') } </p>
                                </Scrollchor>
                                { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_important_notes.length !== 0) &&
                                <Scrollchor to="#notes" className="nav-link">
                                    <p> { t('enw-offer-important_notes-title') }</p>
                                </Scrollchor>
                                }
                                <Scrollchor to="#rooms" className="nav-link">
                                    <p>{ t('btn-total_offer_price') }</p>
                                </Scrollchor>
                            </ColAtm>
                            <ColAtm sm={2}>
                            </ColAtm>
                        </ColAtm>
                    </Hidden>
                    <Container maxWidth="xl">
                        <div className="container-width offer-container">
                                <div className="offer-photo-map offer-padding scroll" id="photos">
                                    {this.photoListTab()}

                                    {this.photoListCarousel()}
                                    <TabPanel value={value} index={1}>
                                        <ColAtm className="offer-maps-tab">
                                            <GoogleSearchBox isMarkerShown
                                                             defaultCenter={ () => {
                                                                 return {
                                                                     lat: this.state.geoDestinations.lat,
                                                                     lng: this.state.geoDestinations.lng
                                                                 }}
                                                             }
                                                             defaultZoom ={ () => {return this.state.zoomIn;} }
                                                             getPlaces = {this.getPlaces}/>
                                        </ColAtm>
                                    </TabPanel>
                                </div>
                            {
                                this.props.landingOffer.offerData.length !== 0 &&
                                this.props.landingOffer.offerData.property_fact[44].fact_subcategory.length !== 0 &&

                                <ColAtm className="offer-padding" id="facilities">
                                    <ColAtm>
                                        <Grid className="offer-page-block" item xs={12}>
                                            <h2>{ t('enw-facilities-title') }</h2>
                                            <ColAtm spacing={1}>
                                                <ColAtm xs={12}>
                                                    {this.facilityFacts()}
                                                </ColAtm>
                                            </ColAtm>
                                        </Grid>
                                    </ColAtm>
                                </ColAtm>

                            }
                            {
                                this.props.landingOffer.offerData.length !== 0 &&
                                this.props.landingOffer.offerData.property_fact[1].fact_subcategory.length !== 0 &&

                                <ColAtm className="offer-padding" id="amenities">
                                    <ColAtm>
                                        <Grid className="offer-page-block" item xs={12}>
                                            <h2>{ t('enw-amenities-title') }</h2>
                                            <ColAtm spacing={1}>
                                                <ColAtm xs={12}>
                                                    {this.amenitiesFacts()}
                                                </ColAtm>
                                            </ColAtm>
                                        </Grid>
                                    </ColAtm>
                                </ColAtm>

                            }
                            <ColAtm className="offer-padding">
                                <>
                                    <ColAtm id="rooms">
                                        <Grid className="offer-page-block" item xs={12}>
                                            {this.roomInfo()}
                                            <ColAtm className="offer-total-price offer-rooms" sm={12}>
                                                {this.roomPrice()}
                                                <ColAtm sm={12}>
                                                    <p className="ribbon__div"> { t('web-offer-total') } : <span>{this.state.total_amount}‬ {this.state.currency}</span>
                                                        {
                                                            isDefined(this?.props?.landingOffer?.offerData?.offer_payment?.is_payment_received) && this.props.landingOffer.offerData.offer.accept_status === 1 ?
                                                                <>
                                                                    <br/>
                                                                    <span className={this?.props?.landingOffer?.offerData?.offer_payment?.is_payment_received ?
                                                                        "success-payment-offer" : "waiting-payment-offer"}>
                                                                        {
                                                                            this?.props?.landingOffer?.offerData?.offer_payment?.is_payment_received ?
                                                                                t('web-offer-success-price') :
                                                                                t('web-offer-waiting-price')
                                                                        }
                                                                    </span>
                                                                </> : ""
                                                        }
                                                    </p>
                                                </ColAtm>
                                            </ColAtm>
                                            <ColAtm className="offer-rooms">
                                                <h2>{ t('enw-offer-cancellation_policy-title') }</h2>
                                                {this.cancellationPolicy()}
                                            </ColAtm>
                                            {this.offerStatus()}
                                            <ColAtm>
                                                <ColAtm sm={12} spacing={3} className="m-auto">
                                                    { accept_status === 3 &&
                                                        <ColAtm sm={(this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0) ? 6 : 12}>
                                                            <ButtonAtm
                                                                className="offer-buttons offer-manage-price-button offer-approve-button executive-list"
                                                                outline
                                                                fullwidth={true}
                                                                style={firstColor}
                                                                buttontext={ t('btn-accept_offer') }
                                                                onClick={(e) => this.onSubmit(e) }
                                                            />
                                                        </ColAtm>
                                                    }

                                                    { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_contact_mapping.length !== 0 ) &&
                                                    <ColAtm sm={(accept_status === 3 ? 6 : 12)} id="contacts">
                                                        <ButtonAtm
                                                            className="offer-buttons offer-manage-price-button executive-list mb-16 offer-contact-button"
                                                            style={secondaryColor}
                                                            outline
                                                            buttontext={ t('btn-employees_contact') }
                                                            fullwidth={true}
                                                            onClick={() => this.setState({ showDiv: !showDiv })}
                                                        />
                                                    </ColAtm>
                                                    }

                                                </ColAtm>
                                            </ColAtm>
                                        </Grid>
                                    </ColAtm>
                                    { showDiv && (
                                        <ColAtm className="offer-padding">
                                            <ColAtm>
                                                <Grid className="offer-page-block offer-contacts" item xs={12}>
                                                    <h2> { t('enw-general-employees-subtitle') } </h2>
                                                    <Divider />

                                                    <ColAtm sm={12} className="offer-carousel-height offer-contact">
                                                        <Hidden xsDown>
                                                            <Slider { ...contactSliderSettings }>
                                                                {this.contactInformation()}
                                                            </Slider>
                                                        </Hidden>
                                                        <Hidden smUp>
                                                            <Slider { ...contactSliderMobileSettings }>
                                                                {this.contactInformation()}
                                                            </Slider>
                                                        </Hidden>
                                                        {this.buildDialog()}
                                                    </ColAtm>
                                                </Grid>
                                            </ColAtm>
                                        </ColAtm>
                                    )}
                                </>
                            </ColAtm>
                            <OfferApprove approveOffer = {this.props.approveOffer} />
                            <NotFoundOffer offerStatus={this.props.landingOffer} />
                            <ColAtm className="offer-padding" id="facilityInfo">
                                <ColAtm>
                                    <Grid className="offer-page-block" item xs={12}>
                                        <h2> { t('enw-general-information-subtitle') } </h2>
                                        <Divider />
                                        <ColAtm className="mb-24">
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('input-property_name') } />
                                                <LabelAtm text={this.state.name}/>
                                            </ColAtm>
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('enw-input-registered_tax_office')}/>
                                                <LabelAtm text={this.state.tax_office}/>
                                            </ColAtm>
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('enw-input-license_number')} />
                                                <LabelAtm text={this.state.tax_number}/>
                                            </ColAtm>
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('enw-input-construction_year')} />
                                                <LabelAtm text={this.state.year_construction}/>
                                            </ColAtm>
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('enw-input-last_renovation_year')} />
                                                <LabelAtm text={this.state.year_renovation}/>
                                            </ColAtm>
                                        </ColAtm>
                                        <h2> { t('web-offer-room_and_property_category-title') } </h2>
                                        <Divider />
                                        <ColAtm className="mb-24">
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={t('enw-input-number_of_rooms')} />
                                                <LabelAtm text={this.state.number_of_rooms}/>
                                            </ColAtm>
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text={ t('enw-input-chain_and_group') } />
                                                <LabelAtm text={t(this.state.property_chain)} />
                                            </ColAtm>
                                            {this.state.checkin_time !=  "" &&
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text="Check-in Time"/>
                                                <LabelAtm text={this.state.checkin_time}/>
                                            </ColAtm>
                                            }
                                            {this.state.checkout_time !=  "" &&
                                            <ColAtm xs={6} sm={3} className="offer-facility-information">
                                                <LabelAtm text="Check-out Time"/>
                                                <LabelAtm text={this.state.checkout_time}/>
                                            </ColAtm>
                                            }
                                        </ColAtm>
                                    </Grid>
                                </ColAtm>
                            </ColAtm>
                            { (this.props.landingOffer.offerData.length !== 0 && this.props.landingOffer.offerData.offer.offer_important_notes.length !== 0) &&
                            <ColAtm className="offer-padding" id="notes">
                                <ColAtm>
                                    <Grid className="offer-page-block" item xs={12}>
                                        <h2> { t('enw-offer-important_notes-title') }  </h2>
                                        <Divider />
                                        {this.importantNotes()}
                                    </Grid>
                                </ColAtm>
                            </ColAtm>
                            }
                        </div>
                    </Container>
                    <footer style={firstColor} className="offer-footer" id="footer">
                        <ColAtm className="">
                            <Hidden smDown>
                                <Grid item xs={12} sm={1} container className="footer-menu d-block">
                                </Grid>
                            </Hidden>

                            { (isDefined(this.state.facility_instragram) || isDefined(this.state.facility_facebook) || isDefined(this.state.facility_twitter)) &&
                                <Grid item xs={6} md={2} container className="footer-menu d-block">
                                    <div>
                                        <Typography gutterBottom variant="h3">
                                            { t('enw-landing_offer-footer-social_media') }
                                        </Typography>
                                    </div>
                                    <div className="footer-social-media">
                                        { isDefined(this.state.facility_instragram) &&
                                        <a target="_blank" href={`https://www.instagram.com/${this.state.facility_instragram}`}>
                                            <i className="fab fa-instagram" />
                                        </a>
                                        }
                                        { isDefined(this.state.facility_facebook) &&
                                        <a target="_blank" href={`https://www.facebook.com/${this.state.facility_facebook}`}>
                                            <i className="fab fa-facebook-square" />
                                        </a>
                                        }
                                        { isDefined(this.state.facility_twitter) &&
                                        <a target="_blank" href={`https://www.twitter.com/${this.state.facility_twitter}`}>
                                            <i className="fab fa-twitter" />
                                        </a>
                                        }
                                    </div>
                                </Grid>
                            }

                            { (isDefined(this.state.facility_phone) || isDefined(this.state.facility_mobile) || isDefined(this.state.facility_mobile2) || isDefined(this.state.facility_fax)) &&
                            <Grid item xs={6} md={2} container className="footer-menu d-block">
                                <div>
                                    <Typography gutterBottom variant="h3">
                                        { t('enw-landing_offer-footer-contact_us') }
                                    </Typography>
                                </div>
                                <ColAtm className="offer-footer-contact footer-social-media">
                                    { (isDefined(this.state.facility_phone) && this.state.facility_phone !== "+" && this.state.facility_phone !== " ") &&
                                    <a href={`tel: ${this.state.facility_phone}`}>
                                        <i className="fas fa-phone-alt" />
                                        <LabelAtm text={this.state.facility_phone} />
                                    </a>
                                    }
                                    { (isDefined(this.state.facility_mobile) && this.state.facility_mobile !== "+" && this.state.facility_mobile !== " ") &&
                                    <a href={`tel: ${this.state.facility_mobile}`}>
                                        <i className="fas fa-phone-alt" />
                                        <LabelAtm text={this.state.facility_mobile} />
                                    </a>
                                    }
                                    { (isDefined(this.state.facility_mobile2) && this.state.facility_mobile2 !== "+" && this.state.facility_mobile2 !== " ") &&
                                    <a href={`tel: ${this.state.facility_mobile2}`}>
                                        <i className="fas fa-phone-alt" />
                                        <LabelAtm text={this.state.facility_mobile2} />
                                    </a>
                                    }
                                    { (isDefined(this.state.facility_fax) && this.state.facility_fax !== "+" && this.state.facility_fax !== " ") &&
                                    <a>
                                        <i className="fas fa-fax" />
                                        <LabelAtm text={this.state.facility_fax} />
                                    </a>
                                    }
                                </ColAtm>
                            </Grid>
                            }

                            { isDefined(this.state.facility_email) &&
                            <Grid item xs={12} md={2} container className="footer-menu d-block">
                                <div>
                                    <Typography gutterBottom variant="h3">
                                        { t('enw-landing_offer-footer-email') }
                                    </Typography>
                                </div>
                                <ColAtm className="offer-footer-contact footer-social-media">
                                    <a href={`mailto: ${this.state.facility_email}`}>
                                        <LabelAtm text={this.state.facility_email} />
                                    </a>
                                </ColAtm>
                            </Grid>
                            }

                            { isDefined(this.state.facility_web) &&
                            <Grid item xs={12} md={2} container className="footer-menu d-block">
                                <div>
                                    <Typography gutterBottom variant="h3">
                                        { t('enw-landing_offer-footer-web_site') }
                                    </Typography>
                                </div>
                                <ColAtm className="offer-footer-contact footer-social-media">
                                    <a target="_blank" href={"//" + this.state.facility_web}>
                                        <LabelAtm text={this.state.facility_web} />
                                    </a>
                                </ColAtm>
                            </Grid>
                            }
                            { isDefined(this.state.facility_address) &&
                            <Grid item xs={6} md={2} container className="footer-menu d-block">
                                <Typography gutterBottom variant="h3">
                                    { t('enw-landing_offer-footer-address') }
                                </Typography>
                                <Typography gutterBottom variant="subtitle1">
                                    {this.state.facility_address}
                                </Typography>
                            </Grid>
                            }
                        </ColAtm>
                    </footer>
                </>
        )
    }
}

LandingOfferData.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withTranslation('common'), withStyles(styles))  (LandingOfferData);
