import React, { useEffect, useState} from 'react';
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ColAtm from "../../../atomic/Col/Col";
import {FormControl, Grid, MenuItem, Select, InputLabel, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import ButtonAtm from "../../../atomic/Button/Button";
import {
    createPlace,
    getPlaceList,
    createPlaceClearStatus,
    getPlaceCategoryFields,
    getPlaceCategoryFieldsClearStatus
} from "../../../../redux/actions/Content/Places/placesActions"
import {useTranslation} from "react-i18next";
import {Alert} from "@material-ui/lab";
import {convertAccentedCharacters, isDefined} from "../../../../utils";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import InputAtm from "../../../atomic/Input/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getRoomDescription} from "../../../../redux/actions/propertyRoomAndRateActions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

function a12yProps(index) {
    return {
        id: `place-description-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const findChildData = (data, parentId) => {
    let childData = data.filter( (placesCategories) => {
        return placesCategories.parent_id === parentId
    })
    return childData;
}

const PlacesModal = (props) => {

    const placesCategories  = useSelector(props => props.placesGetCategoriesReducer.placesCategories?.place_categories);
    const placeWorkingHours = useSelector(props => props.placesGetCategoriesReducer.placesCategories?.place_working_hours);
    const placeIncludes = useSelector(props => props.placesGetCategoriesReducer.placesCategories?.place_includes);
    const createPlaceStatus = useSelector(props => props.placeCreateReducer.createPlaceStatus?.status);
    const createPlaceError = useSelector(props => props.placeCreateReducer.error);
    const createGetInputsPlaceError = useSelector(props => props.placeCreateReducer.getCategoryError);
    const placeCategoryFieldsReducer = useSelector(props => props.placeCreateReducer);
    const placeCategoryFields = isDefined(placeCategoryFieldsReducer.placeCategoryFields.data) && placeCategoryFieldsReducer.placeCategoryFields.data.place_category_fields;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedChildId, setSelectedChildId] = useState([]);
    const [lastSelectedId, setLastSelectedId] = useState([]);
    const [workingHour, setWorkingHour] = useState();
    const [include, setInclude] = useState();
    const [placeName, setPlaceName] = useState();
    const [isReservation, setIsReservation] = useState();
    const [childData, setChildData] = useState([]);
    const [render, setRender] = useState(false);
    const [value, setValue] = React.useState(0);
    const [description] = useState(props.descriptionData);
    const [descriptionTabValue, setDescriptionTabValue] = React.useState(0);
    const [descriptionMaxCharCount] = React.useState(2000);
    const [descriptionCharCount, setDescriptionCharCount] = React.useState(isDefined(props.descriptionData) && Object.keys(props.descriptionData).length > 0  ? props.descriptionData.map(value =>{
        return value.description === null ? 0 : value.description.length
    }) : []);
    const [state, setState] = useState({
        fields: {}
    });

    const handleChange = (event, newValue) => {
        dispatch(getPlaceCategoryFieldsClearStatus());

        setValue(newValue);
        setState({ fields: {} })
    };

    const handleChangeDescriptionTab = (event, newValue) => {
        setDescriptionTabValue(newValue);
    };

    const handleChangeCharCount = index => (e) => {

        let tempCount = descriptionCharCount;
        tempCount[index] = e.target.value.length;

        setDescriptionCharCount(tempCount)

        let values = description;
        values[index]["description"] = e.target.value;

    };

    const handleChangeCategory = ({target}) => {
        if(target.value === null) return 0;
        setSelectedCategory(target.value)
        setLastSelectedId(target.value)
        setSelectedChildId([]);
        let childData = findChildData(placesCategories, target.value);
        setChildData( [childData]  )
        setRender(!render)
    }

    const handleChangeChild = (e,index) => {
        if(e.target.value === null) return 0;

        let findedChildData = findChildData(placesCategories,e.target.value)

        if (findedChildData.length === 0 ){
            let temp = childData;
            temp.splice(index+1, temp.length);
            setChildData(temp);
            setLastSelectedId(e.target.value)
            let tempChildIds = selectedChildId;
            tempChildIds.splice(index, tempChildIds.length);
            tempChildIds.push(e.target.value)
            setSelectedChildId(tempChildIds)

            setRender(!render)
            return 0;
        }


        if ((index+1) < childData.length ){
            let temp = childData;
            temp.splice(index+1, temp.length);
            temp.push(findedChildData);
            setLastSelectedId(e.target.value)
            setChildData(temp);

            let tempChildIds = selectedChildId;
            tempChildIds.splice(index+1, tempChildIds.length);


        }else{
            setLastSelectedId(e.target.value);
            setChildData(prevState => [...prevState, findedChildData]);
            setSelectedChildId(prevState => [...prevState, e.target.value]);
        }

        setRender(!render)
    }

    const handleChangeWorkingHour = ({target}) => {
        if(target.value === null) return 0;
        setWorkingHour(target.value);
    }

    const handleChangeInclude = ({target}) => {
        if(target.value === null) return 0;
        setInclude(target.value);
    }
    const handleChangeReservation = ({target}) => {
        if(target.value === null) return 0;
        setIsReservation(target.value);
    }

    const handleChangePlaceName = (e) => {
        setPlaceName(e.target.value)
    }

    useEffect( ()=> {
        if ( (createPlaceStatus === 200)  ){

            // List Yeniden Çekilecek
            dispatch(getPlaceList());
            dispatch(getRoomDescription());

            //Modal Kapatılacak ;
            props.modalClose();

            // Status temizleniyor
            dispatch(createPlaceClearStatus());
        } else {
            setValue(0)
            setState({ fields: {} })
        }
    },[render, createPlaceStatus,createPlaceError] )

    useEffect( ()=> {

        if (placeCategoryFieldsReducer.placeCategoryFields.status === 200) {
            setValue(1)
        }
    },[render, placeCategoryFieldsReducer, createGetInputsPlaceError] )

    const onSubmit = (e) => {
        e.preventDefault();

        let params = {
            place_category_id: lastSelectedId,
            place_working_hour_id: workingHour,
            include : include,
            name : placeName,
            reservation_requirement : isReservation ? 1 : 0,
            field_values: state.fields,
            description: description
        }

        dispatch(createPlace(params));
    };

    const getFields = (e) => {
        e.preventDefault();
        dispatch(getPlaceCategoryFields(lastSelectedId, null));
    };

    const handleChangeFields = (e, key, type, value, name) => {
        e.preventDefault();
        let values = state.fields;

        if (type === "multi") {
            let selectedValues =  value.map((item) => {return item.option_id})

            values[key] = {
                field_id: key,
                field_value: selectedValues,
                field_name: name
            };
        }
        else if (type === "slider") {
            values[key] = {
                field_id: key,
                field_value: value === "minSliderValue" && e.target.value,
                field_name: name,
                field_max_value: value === "maxSliderValue" && e.target.value
            };
        } else if (type === "inputunit") {
            values[key] = {
                field_id: key,
                field_value: value === "unitInputValue" && e.target.value,
                field_name: e.target.name,
                field_unit_value: value === "unitSelectValue" && e.target.value
            };
        } else {
            values[key] = {
                field_id: key,
                field_value: e.target.value,
                field_name: e.target.name
            };
        }
        setState({ ...state, values });
    };

    const descriptionList = isDefined(description) && description.map((title, index)=>{
        return (
            <TabPanel value={descriptionTabValue} index={index} className="w-100">
                <TextField name={title.language_code}
                           type="text"
                           variant="outlined"
                           className="text-capitalize"
                           onChange={handleChangeCharCount(index)}
                           value={title.description}
                           label={ t('enw-input-description') + " - " + title.language_code }
                           multiline={true}
                           fullWidth
                           required={false}
                           rows={2}
                           inputProps={{maxLength : descriptionMaxCharCount}} />
                <div style={{ width: '100%'}}>
                    <p style={{ float : 'right'}} className="mb-0"> { descriptionCharCount[index] }/ {descriptionMaxCharCount}</p>
                </div>
            </TabPanel>
        );
    })

    const descriptionTabs = isDefined(description) && description.map((title,value)=>{
        const flag = <Grid container className="brand-desc-flag" spacing={1}>
            <Grid item sm={3}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} alt={title.language_code} />
            </Grid>
            <Grid item sm={2}>
                {title.language_code}
            </Grid>
        </Grid>
        return (
            <Tab label={ flag } {...a12yProps(value)} />
        );
    })

    return (
        <div>
            <ValidatorForm onSubmit={getFields} onError={errors => console.log(errors)}>
                <AppBar className="room-rate-tab-title" position="static">
                    <Tabs className="room-rate-tabFont" value={value} onChange={handleChange} aria-label="room-rate-tab">
                        <Tab label={ t('enw-content-place_details-title') } {...a11yProps(0)} />
                        <ButtonAtm className={value === 1 ? "bed-type-title" : "passive-tab"} buttontext={ t('enw-content-place_facts-add_place-title') } />
                    </Tabs>
                </AppBar>
                <ColAtm xs={12} className="select-room-types">
                </ColAtm>
                <TabPanel value={value} index={0}>
                    <ColAtm xs={12} className="select-room-types">
                        {isDefined(createPlaceError?.response) && <Alert className="w-100 mt-22" severity="error">{createPlaceError?.response?.data?.message}</Alert>}
                        {isDefined(createGetInputsPlaceError?.response) && <Alert className="w-100 mt-22" severity="error">{createGetInputsPlaceError?.response?.data?.message}</Alert>}
                        <ColAtm xs={12} className="form-group">
                            <SelectValidator
                                onChange={handleChangeCategory}
                                className="form-control"
                                variant="outlined"
                                fullWidth
                                label={ t('enw-content-place_categories') }
                                value={selectedCategory}
                                validators={['required']}
                                errorMessages={[ t("enw-notification_this_is_required") ]}
                            >
                                {
                                    placesCategories.length > 0 && placesCategories.filter( (placesCategories) => {
                                        return placesCategories.parent_id === null
                                    }).map((category) => (
                                        <MenuItem value={category.id} >
                                            { t(category.language_key) }
                                        </MenuItem>
                                    ))
                                }
                            </SelectValidator>
                        </ColAtm>
                        {
                            childData.length > 0 && childData.map( (item,key) => (
                                <ColAtm xs={12} className="form-group">
                                    <SelectValidator
                                        onChange={(e) => handleChangeChild(e,key)}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-category_type') }
                                        value={selectedChildId[key]}
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        {
                                            item.map((category) => (
                                                <MenuItem value={category.id} >
                                                    { t(category.language_key) }
                                                </MenuItem>
                                            ))
                                        }
                                    </SelectValidator>
                                </ColAtm>
                            ))
                        }
                        <ColAtm xs={12} className="form-group">
                            <TextValidator
                                label={ t('enw-input-place_name') }
                                name="place_name"
                                type="text"
                                fullWidth
                                autoComplete="off"
                                value={placeName}
                                onChange={handleChangePlaceName}
                                variant="outlined"
                                validators={['required']}
                                errorMessages={[ t("enw-notification_this_is_required") ]}
                            />
                        </ColAtm>
                        <ColAtm xs={12} className="form-group">
                            <SelectValidator
                                onChange={handleChangeWorkingHour}
                                className="form-control"
                                variant="outlined"
                                fullWidth
                                value={workingHour}
                                label={ t('enw-input-working_hour') }
                                validators={['required']}
                                errorMessages={[ t("enw-notification_this_is_required") ]}
                            >
                                {
                                    placeWorkingHours.length > 0 && placeWorkingHours.map( (placesWorkingHour) => (
                                        <MenuItem value={placesWorkingHour.id} >
                                            { t(placesWorkingHour.language_key) }
                                        </MenuItem>
                                    ))
                                }
                            </SelectValidator>
                        </ColAtm>
                        <ColAtm xs={12} className="form-group">
                            <SelectValidator
                                onChange={handleChangeInclude}
                                className="form-control"
                                variant="outlined"
                                fullWidth
                                value={include}
                                label={ t('enw-input-include') }
                                validators={['required']}
                                errorMessages={[ t("enw-notification_this_is_required") ]}
                            >
                                {
                                    placeIncludes.length > 0 && placeIncludes.map( (placeInclude) => (
                                        <MenuItem value={placeInclude.id} >
                                            { t(placeInclude.language_key) }
                                        </MenuItem>
                                    ))
                                }
                            </SelectValidator>
                        </ColAtm>
                        <ColAtm xs={12} className="form-group">
                            <SelectValidator
                                onChange={handleChangeReservation}
                                className="form-control"
                                variant="outlined"
                                fullWidth
                                value={isReservation}
                                label={ t('enw-content-add_place-is_reservation_required') }
                                validators={['required']}
                                errorMessages={[ t("enw-notification_this_is_required") ]}
                            >
                                <MenuItem value={0} >
                                    { t('enw-input-checkbox-no') }
                                </MenuItem>
                                <MenuItem value={1} >
                                    { t('enw-input-checkbox-yes') }
                                </MenuItem>
                            </SelectValidator>
                        </ColAtm>
                        <ColAtm className="d-block brand-container place-desc">
                            <ColAtm>
                                <AppBar className="room-rate-tab-title" position="static">
                                    <Tabs className="room-rate-tabFont"
                                          value={descriptionTabValue}
                                          onChange={handleChangeDescriptionTab}
                                          aria-label="place-description-tab"
                                          variant="scrollable"
                                          scrollButtons="on"
                                    >
                                        {descriptionTabs}
                                    </Tabs>
                                </AppBar>
                                {descriptionList}
                            </ColAtm>
                        </ColAtm>
                        <ColAtm md={12}>
                            <ButtonAtm
                                type="submit"
                                className="m-auto room-rate-modal-save bg-red"
                                buttontext={ t('btn-next') }
                                color="primary"
                            />
                        </ColAtm>
                    </ColAtm>
                </TabPanel>
                <TabPanel value={value} index={1} className="mt-22">
                    {Object.values(placeCategoryFields).sort((a, b) => a.field_order_number > b.field_order_number ? 1:-1).map((field)=> {
                        return <ColAtm xs={12} className="select-room-types">
                            {
                                field.field_element === "input" ?
                                <ColAtm xs={12} className="form-group">
                                    <InputAtm
                                        name={field.field_name}
                                        type={field.field_type === "text" ? field.field_type : ""}
                                        variant="outlined"
                                        autoComplete="off"
                                        onInput={field.field_type === "number" ? (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') } : ""}
                                        label={ t(field.field_language_key) }
                                        onChange={(e) => handleChangeFields(e, field.field_id, "single")}
                                    />
                                </ColAtm>
                                :
                                field.field_element === "select" ?
                                <ColAtm xs={12} className="form-group">
                                        <FormControl variant="outlined" fullWidth>
                                        <InputLabel>{ t(field.field_language_key) }</InputLabel>
                                        <Select
                                            className="form-control"
                                            name={field.field_name}
                                            variant="outlined"
                                            fullWidth
                                            label={ t(field.field_language_key) }
                                            onChange={(e) => handleChangeFields(e, field.field_id, "single")}
                                        >
                                            {
                                                Object.values(field.options).map( (options,index) => (
                                                    <MenuItem value={options.option_id}>
                                                        { t(options.option_language_key) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    </ColAtm>
                                :
                                field.field_element === "slider" ?
                                <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextValidator
                                                label={ t('enw-input-min_age') }
                                                name="place_name"
                                                type="text"
                                                fullWidth
                                                autoComplete="off"
                                                onChange={(e) => handleChangeFields(e, field.field_id, "slider", "minSliderValue", field.field_name)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextValidator
                                                label={ t('enw-input-max_age') }
                                                name="place_name"
                                                type="text"
                                                fullWidth
                                                autoComplete="off"
                                                onChange={(e) => handleChangeFields(e, field.field_id+"_max", "slider", "maxSliderValue", field.field_name)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                :
                                field.field_element === "multiselect" ?
                                <ColAtm xs={12} className="form-group">
                                    <Autocomplete
                                        id="placesMulti"
                                        options={Object.values(field.options).sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                        getOptionLabel={option => t(option.option_language_key)}
                                        multiple
                                        name={field.field_name}
                                        className="w-100"
                                        filterSelectedOptions
                                        onChange={(e, value) => handleChangeFields(e, field.field_id, "multi", value, field.field_name)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name={params.field_name}
                                                label={ t(field.field_language_key) }
                                                placeholder={ t(field.field_language_key) }
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ColAtm>
                                :
                                field.field_element === "inputunit" ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8}>
                                            <InputAtm
                                                name={field.field_name}
                                                type={field.field_type === "text" ? field.field_type : ""}
                                                variant="outlined"
                                                autoComplete="off"
                                                onInput={field.field_type === "number" ? (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') } : ""}
                                                label={ t(field.field_language_key) }
                                                onChange={(e) => handleChangeFields(e, field.field_id, "inputunit", "unitInputValue", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} className="form-group">
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel>{ t(field.field_attribute.unit_label) }</InputLabel>
                                                <Select
                                                    className="form-control"
                                                    name={field.field_name}
                                                    variant="outlined"
                                                    fullWidth
                                                    label={ t(field.field_attribute.unit_label) }
                                                    onChange={(e) => handleChangeFields(e, field.field_id+"_unit", "inputunit", "unitSelectValue")}
                                                >
                                                    {
                                                        Object.values(field.field_units).map( (options, index) => (
                                                            <MenuItem value={options.id}>
                                                                { t(options.language_key) }
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    :
                                <></>
                            }
                        </ColAtm>
                    })}
                    <ColAtm className="mt-22" md={12}>
                        <ButtonAtm
                            className="m-auto room-rate-modal-save bg-red"
                            type="submit"
                            buttontext={ t('btn-save') }
                            color="primary"
                            onClick={onSubmit}
                        />
                    </ColAtm>
                </TabPanel>
            </ValidatorForm>
        </div>
    );
}

export default PlacesModal;
