import React        from 'react';
import PropTypes    from 'prop-types';

import CheckboxAtm  from "../../atomic/Checkbox/Checkbox";
import ColAtm       from "../../atomic/Col/Col";
import IconAtm      from "../../atomic/Icon/Icon";
import BlockAtm from "../../atomic/Block/Block";
import OverFlowText from "../OverFlowText/OverFlowText";


const FactDefaultBox = props => (
    //  <ColAtm className={props.colClassname} md={2} xs={12} value={props.value}> Yeni Offer da bu şekilde yapmıştık
    <ColAtm className={props.colClassname}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} >
            <CheckboxAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                checkboxId={props.checkboxId}
                value={props.value}
                tooltip={props.tooltip}
                labelPlacement={props.labelPlacement}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
            />
            <label htmlFor={props.htmlFor}>
                <label htmlFor={props.htmlFor}>
                    <IconAtm iconClass={props.iconClass} />
                    <BlockAtm className={props.labelIconClass}>
                        <OverFlowText className={props.labelClass} htmlFor={props.htmlFor}> {props.text} </OverFlowText>
                    </BlockAtm>
                </label>
            </label>
        </BlockAtm>
    </ColAtm>
);

FactDefaultBox.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    tooltip: PropTypes.any,
    labelIconClass: PropTypes.string,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
};

export default FactDefaultBox;
