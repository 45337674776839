import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import axios from "axios";
import {BOOKING_ENGINE_DASHBOARD} from "../../../../constants/api.constants";

export function getBookingEngineDashboard() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                booking_id : 1
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'BOOKING_ENGINE_DASHBOARD',
            payload: axios.post(BOOKING_ENGINE_DASHBOARD, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
