import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import ColAtm from "../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import LabelAtm from "../../../../atomic/Label/Label";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ButtonAtm from "../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {PropertyRoomAndRateList, PropertyRoomTypes} from "../../../../../redux/actions/propertyRoomAndRateActions";
import EditRoom from "../EditRoom/EditRoom";
import {Grid} from "@material-ui/core";
import RoomRateChannel from "./RoomRateChannel";

const RoomAndRateList = props => {

    const {rooms} = props;
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeEditRoom = () => {
        setEditModalStatus(false);
    }

    useEffect( () => {
        if(props.updateStatus === 200)
        {
            if(editModalStatus){
                closeEditRoom();
                dispatch(PropertyRoomAndRateList());
                dispatch(PropertyRoomTypes());
            }

        }
    },[editModalStatus,props.updateStatus] )

    const rateFilteredRooms = () => {
        let rateFilteredRooms = [];
        if( props.selectedRoomTypeId !== 0 && props.selectedRoomRateId === 0 )
        {
            rateFilteredRooms = rooms.filter( (room) => room.room_type_id === props.selectedRoomTypeId )
        }
        else if(props.selectedRoomTypeId !== 0 && props.selectedRoomRateId !== 0){
            let filteredRoomType = [];
            rooms.map( (room) => {
                if(room.room_type_id === props.selectedRoomTypeId){
                    filteredRoomType.push(room)
                }
            });

            // eslint-disable-next-line array-callback-return
            filteredRoomType.map( (room) => {
                let hasRate = room.property_room_rate_mapping.filter( rate => rate.room_rate_id === props.selectedRoomRateId );
                if(hasRate !== undefined && hasRate.length > 0){
                    rateFilteredRooms.push(room)
                }
            } );

        }
        else if( props.selectedRoomTypeId === 0 && props.selectedRoomRateId !== 0 ){
            // eslint-disable-next-line array-callback-return
             rooms.map( (room) => {
               let hasRate = room.property_room_rate_mapping.filter( rate => rate.room_rate_id === props.selectedRoomRateId );
               if(hasRate !== undefined && hasRate.length > 0){
                   rateFilteredRooms.push(room)
               }
            } );

        }
        else{
            // eslint-disable-next-line array-callback-return
            rooms.map((room) => {
                let hasRate = room.property_room_rate_mapping.length > 0
                if(hasRate){
                    rateFilteredRooms.push(room)
                }
            } );
        }

       const filteredData = rateFilteredRooms.map( (room, key) => (
            <ExpansionPanel className="accordion-room-table" key={key} defaultExpanded>
                <ExpansionPanelSummary
                    expandIcon={<i className="fas fa-caret-down" />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header">
                    <Grid container
                          justify="space-between"
                          alignItems="center"
                    >
                        <Grid item xs={12} className="d-inherit">
                            <i className="fas fa-bed room-rate-bed" />
                            <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                        </Grid>
                        {/*<Grid item xs={4} md={2} onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}>
                            <Hidden xsDown>
                                <ButtonAtm className="edit-room-btn"
                                           onClick={ ()=> openEditRoom(room) }
                                           buttontext={ t('btn-edit_room') }/>
                            </Hidden>
                            <Hidden smUp>
                                <ButtonAtm className="edit-room-btn"
                                           onClick={ ()=> openEditRoom(room) }
                                           buttontext={<i className="fas fa-pen" /> }/>
                            </Hidden>
                        </Grid>*/}
                    </Grid>
                </ExpansionPanelSummary>
                {

                    props.selectedRoomRateId !== 0 ?

                    room.property_room_rate_mapping.filter( (rate) => rate.room_rate_id === props.selectedRoomRateId ).map( ( rate, key ) => (
                        <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item" key={key}>
                            <ExpansionPanel className="accordion-rate-table">
                                <ExpansionPanelSummary
                                    expandIcon={<ButtonAtm className="channels-button" buttontext={ t('btn-channels') } endIcon={<ExpandMoreIcon />} />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    className="rate-accordion-mobile"
                                    id="additional-actions1-header">
                                    <ColAtm>
                                        <ColAtm md={10}>
                                            <i className="fas fa-tags rate-tag-icon" />
                                            <LabelAtm labelClass="rate-title" text={rate.name} />
                                            { rate?.connected_rate?.name &&
                                                <>
                                                    <i className="fas fa-link connect-rate-icon" />
                                                    <LabelAtm labelClass="rate-title" text={rate?.connected_rate?.name} />
                                                </>
                                            }
                                        </ColAtm>
                                        <ColAtm className="included-occupancy-div" md={2} >
                                            <LabelAtm labelClass="included-occupancy-text" text={t('enw-input-included_occupancy') + ": " + rate.included_occupancy} />
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelSummary>
                               {/* <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                    <ColAtm>
                                        <ColAtm md={12}>
                                            <ButtonAtm
                                                className="inventory-channels-filter-button"
                                                type="button"
                                                endIcon={<i className="fas fa-archive room-rate-channel-filter" />}
                                            />
                                            <ColAtm md={12} spacing={3}>
                                            {
                                                rate.property_room_rate_channel.map( (rateChannel) => (
                                                    <RoomRateChannel channel={rateChannel} rate={rate} />
                                                ) )
                                            }
                                            </ColAtm>
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelDetails>*/}
                                <img className="flip-transform" src="/assets/img/icons/channel-arrow.png" alt="channel-arrow" />
                            </ExpansionPanel>
                        </ExpansionPanelDetails>
                    )) :
                        room.property_room_rate_mapping.map( ( rate, key ) => (
                            <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item" key={key}>
                                <ExpansionPanel className="accordion-rate-table">
                                    <ExpansionPanelSummary
                                        expandIcon={<ButtonAtm className="channels-button" buttontext={ t('btn-channels') } endIcon={<ExpandMoreIcon />} />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        className="rate-accordion-mobile"
                                        id="additional-actions1-header">
                                        <ColAtm>
                                            <ColAtm md={10}>
                                                <i className="fas fa-tags rate-tag-icon" />
                                                <LabelAtm labelClass="rate-title"  text={rate.name} />
                                                { rate?.connected_rate?.name &&
                                                <>
                                                    <i className="fas fa-link connect-rate-icon" />
                                                    <LabelAtm labelClass="rate-title" text={rate?.connected_rate?.name} />
                                                </>
                                                }
                                            </ColAtm>
                                            <ColAtm className="included-occupancy-div" md={2} >
                                                <LabelAtm labelClass="included-occupancy-text" text={t('enw-input-included_occupancy') + ": " + rate.included_occupancy} />
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                        <ColAtm md={12} spacing={3}>
                                           {/* {
                                                rate.property_room_rate_channel.map( (rateChannel) => (
                                                    <RoomRateChannel channel={rateChannel} rate={rate}/>
                                                ) )
                                            }*/}
                                        </ColAtm>
                                    </ExpansionPanelDetails>
                                    <img className="flip-transform" src="/assets/img/icons/channel-arrow.png" alt="channel-arrow"/>
                                </ExpansionPanel>
                            </ExpansionPanelDetails>
                        ))

                }

            </ExpansionPanel>
        ));
       return filteredData;
    };

    if(rooms && rooms.length === 0 )
    {
        return (<h2>{ t('enw-notification-no_results') }</h2>)
    }else{
        return (
            <>
                {rateFilteredRooms()}
                {
                    editModalStatus &&
                    <EditRoom
                        closeEditRoom={closeEditRoom}
                        allRoomTypes={props.allRoomTypes}
                        allBedType={props.allBedType}
                        roomViewTypes={props.roomViewTypes}
                        roomSizeTypes={props.roomSizeTypes}
                    />
                }
            </>
        );
    }
};

RoomAndRateList.propTypes = {
    rooms: PropTypes.array.isRequired
};
RoomAndRateList.defaultProps = {};

export default RoomAndRateList;
