import React, {useEffect, useState} from 'react';
import FactDefaultBox from "../../../../molecules/FactDefaultBox/FactDefaultBox";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AddonSyncModal from "./AddonSyncModal";
import {AddonDataClear, SyncAddons} from "../../../../../redux/actions/Network/Addons/addonsActions";
let lastFactUpdateId=null;
const AddonBox = (props) => {

    const { t } = useTranslation();
    const { addon } = props
    const [isSelected , setIsSelected ] = useState( props.isSelected );
    const syncStatus = useSelector(props => props.addonsReducers.syncAddonsStatus)

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(AddonDataClear());
    };

    useEffect( () => {

        if (syncStatus === 200 && lastFactUpdateId === addon?.id+"-"+addon?.name+"-"+addon?.channelAddon?.property_addon_id){
            setState();
        }
    },[syncStatus]);

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }
    /*const handleUpdateClick = (e,addon) => {

        let params = {}
        lastFactUpdateId =addon?.id+"-"+addon?.name+"-"+addon?.channelAddon?.property_addon_id

        if(isSelected){
            params = {
                status: false,
                channel_id: props.pageId,
                property_addon_id: addon?.id,
            }
            dispatch(SyncAddons(params))
        }
    }*/

    useEffect( () => {
        if (syncStatus === 200) {
            dispatch(AddonDataClear());
        }
    },[syncStatus])

        return (
            <div>
                {
                    addon.is_selected && isSelected ?
                        <>
                            <AddonSyncModal
                                fact={addon}
                                id={addon.id}
                                pageId={props.pageId}
                                open={open}
                                type="modal"
                                handleClickOpen={handleClickOpen}
                                handleClose={handleClose}
                                descriptionData={props.descriptionData}
                            />
                            <FactDefaultBox
                                checkboxId={addon.id+"-"+addon.name+"-"+addon?.channelAddon?.property_addon_id}
                                name={t(addon.language_key)}
                                className="fact-box"
                                blockClass="fact-border-box"
                                labelIconClass="fact-icon-label addon-checkbox"
                                iconClass={`fact-icon addon-checkbox ${addon.icon}`}
                                key={addon.id+"-"+addon.name+"-"+addon?.channelAddon?.property_addon_id}
                                checked={isSelected}
                                factName={t(addon.language_key)}
                                tooltip={t(addon.language_key)}
                                text={t(addon.language_key)}
                                labelClass="fact-name"
                                htmlFor={addon.id}
                            />
                        </>
                        :
                        <AddonSyncModal
                            fact={addon}
                            id={addon.id}
                            pageId={props.pageId}
                            open={open}
                            type="notSelected"
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            descriptionData={props.descriptionData}
                        />
                }
            </div>
        );

}

export default AddonBox;
