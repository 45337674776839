import React from 'react';
import Container from "@material-ui/core/Container";
import ColAtm from "../../../../atomic/Col/Col";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Hidden, withStyles} from "@material-ui/core";
import FactBoxs from "./FactBoxs";
import FactMenu from "./FactMenu";
import FactSubtitle from "./FactSubtitle";
import FactHeader from "./FactHeader";
import FactFooter from "./FactFooter";

let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    menuLinks: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#eaeaea'
        },
    }
});

class Facts extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            accept_status : '',
            redirect: false,
            showSuccessHeader: false,
            checkedStatus: true
        }
        t = props.t;
    }


    render() {
        const { classes } = this.props;

        return (
            <>
                <FactHeader this_main_category={this.props.this_main_category} />
                <Hidden xsDown>
                    <ColAtm md={12} className={classes.menuLinks}>
                        <FactMenu getMenusItem={this.props.getMenusData} pageId={this.props.this_sub_category.id} />
                    </ColAtm>
                </Hidden>
                <Container maxWidth="xl">
                    <div className="container-width fact-list">
                        <FactSubtitle this_sub_category = {this.props.this_sub_category} />
                        <ColAtm>
                            <FactBoxs
                                get_facts={this.props.FactListData}
                                fetching={this.props.fetching}
                                pageId={this.props.pageId}
                            />
                        </ColAtm>
                    </div>
                </Container>
                <FactFooter getMenus={this.props.getMenusData.prev} nextMenus={this.props.getMenusData.next} />
            </>
        )
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (Facts);
