import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import ColAtm from "../../../../../atomic/Col/Col";
import InputAtm from "../../../../../atomic/Input/Input";
import {FormHelperText, FormControl, Grid} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import BlockAtm from "../../../../../atomic/Block/Block";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {Alert, createFilterOptions} from "@material-ui/lab";
import {isDefined} from "../../../../../../utils";
import {history} from "../../../../../../utils/history";
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});

const  useOnMount = (handler) =>    {
    return useEffect(handler,[]);
}

const WebForm = (props) => {

    const {languages} =  props;

    const defaultValues ={
        domain: isDefined(props.webReducer.domains) ? props.webReducer.domains.domain : '',
        language: isDefined(props.webReducer.domains) ? props.webReducer.domains.default_language : '',
        property_web_id: isDefined(props.webReducer.domains) ? props.webReducer.domains.id : ''
    }

    const { register, handleSubmit,  errors, getValues, setValue, setError, clearError} = useForm({
        mode: 'onBlur',
        reValidateMode: "onBlur",
        defaultValues
    })

    const classes = styles();
    const { t } = useTranslation();

    const showError = (e) => {
        if( props.webReducer && props.webReducer.updateData.status === 200 ){
            history.push(`/app/web/edit/${props.webReducer.updateData.data.id}`)
        }
        else if (isDefined(props.webReducer.submitError.response) ) {
            let message = props.webReducer.submitError.response.data.message
            return  <Alert className="w-100" severity="error">{message}</Alert>

        }
    }

    const onSubmit = (data) => {

        let params = {
            domain: data.domain,
            language: data.language,
            property_web_id: defaultValues.property_web_id
        }

        if(props.webReducer.domains.length < 1){

            props.createWeb(params);

        }else{
            props.updateWeb(params);
        }
    }

    useOnMount(() => {
        register(
            { name: "language", type: "text" },
            {  required: t("enw-notification_this_is_required")  }
        );
    });

    return(
        <ColAtm container className={classes.root} direction="row" md={10}>
            {showError()}

            <form onSubmit={handleSubmit(onSubmit)}  name="general-form" id="general-form" autoComplete="off" style={{width: 606}} >
                <InputAtm
                    name="domain"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={ t('enw-input-domain_name') }
                    autoComplete="off"
                    autoFocus
                    error={!!errors.domain}
                    inputRef={register({
                        required: t("enw-notification_this_is_required"),
                        pattern: {
                            value : /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})\.[a-z]{2,}?$/,
                            message: t('enw-notification_invalid_format')
                        }
                    })}
                />
                <FormHelperText error={!!errors.domain}>
                    {errors.domain && errors.domain.message}
                </FormHelperText>


                <Grid container justify="space-between">

                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                options={languages}
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ languages.find( (language) => {
                                    return language.code === getValues('language')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("language", data.code);
                                        clearError("language")
                                    }else{
                                        setValue("language", '');
                                        setError("language", "notMatch", t("enw-notification_this_is_required"));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={ t('enw-input-language') }
                                            variant="outlined"
                                            error={!!errors.language}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                       <FormHelperText error={!!errors.language}>
                            {errors.language && errors.language.message}
                        </FormHelperText>

                    <Grid item md={12} className="mt-12">
                        <h2> { t('room-rooms-detail-description-title') } </h2>
                        <ColAtm className="mb-24">
                            <ColAtm className="room-detail-all-p">
                                <Typography>
                                    { ReactHtmlParser( t('enw-myw-domain-desc', { domain : 'myweb.extranetwork.com'} ) ) }
                                </Typography>
                            </ColAtm>
                        </ColAtm>
                    </Grid>
                    <ColAtm className="mt-32 assign-rate-next" md={12}>
                        <BlockAtm className="t-center pb-32" md={12}>
                            <ButtonAtm
                                className="room-rate-modal-save room-rate-save bg-red m-auto"
                                buttontext={ t('btn-save') }
                                autoFocus
                                color="primary"
                                type="submit"
                            />
                        </BlockAtm>
                    </ColAtm>
                </Grid>
            </form>
        </ColAtm>
    )

}

export default WebForm
