import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import { NETWORK_DASHBOARD }            from "../../../../constants/api.constants";


export function getNetworkDashboard() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_DASHBOARD',
            payload: axios.post(NETWORK_DASHBOARD, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

