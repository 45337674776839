
export const channelRoomRateConstants = {
    CHANNEL_ROOM_RATE_GET_PENDING:   'CHANNEL_ROOM_RATE_GET_PENDING',
    CHANNEL_ROOM_RATE_GET_FULFILLED:   'CHANNEL_ROOM_RATE_GET_FULFILLED',
    CHANNEL_ROOM_RATE_GET_REJECTED:   'CHANNEL_ROOM_RATE_GET_REJECTED',
};

export const channelRoomRateUpdateConstants = {
    ROOM_RATE_CHANNEL_UPDATE_PENDING:   'ROOM_RATE_CHANNEL_UPDATE_PENDING',
    ROOM_RATE_CHANNEL_UPDATE_FULFILLED:   'ROOM_RATE_CHANNEL_UPDATE_FULFILLED',
    ROOM_RATE_CHANNEL_UPDATE_REJECTED:   'ROOM_RATE_CHANNEL_UPDATE_REJECTED',
    ROOM_RATE_CHANNEL_UPDATE_CLEAR:   'ROOM_RATE_CHANNEL_UPDATE_CLEAR',
};
