import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    CREATE_CHANNEL_CANCELLATION, UPDATE_CANCELLATION_POLICY, GET_CHANNEL_CANCELLATION_LIST, UPDATE_CHANNEL_CANCELLATION_POLICY
} from "../../../../constants/api.constants";


export function getChannelCancellationList(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CHANNEL_CANCELLATION_LIST',
            payload: axios.post(GET_CHANNEL_CANCELLATION_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function addChannelCancellation(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                name: params.name,
                before_arrival : params.before_arrival,
                value : params.value,
                type: params.type,
                is_nonrefundable: params.is_nonrefundable,
                is_free_cancellation: params.is_free_cancellation,
                affects_price: params.is_affect,
                affects_price_action_type: params.affects_price_action_type,
                affects_price_type: params.affects_price_type,
                affects_price_value: params.affects_price_value,
                is_date_range: params.is_date_range,
                start_date: params.start_date,
                finish_date: params.finish_date
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_CHANNEL_CANCELLATION',
            payload: axios.post(CREATE_CHANNEL_CANCELLATION, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdateChannelCancellation(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                room_rate_channel_mapping_id: params.room_rate_channel_mapping_id,
                cancellation_policy_id: params.cancellation_policy_id,
                is_selected: params.is_selected,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_CHANNEL_CANCELLATION_POLICY',
            payload: axios.post(UPDATE_CHANNEL_CANCELLATION_POLICY, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdateCancellationPolicy(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                cancellation_policy_id: params.cancellation_policy_id,
                name: params.name,
                before_arrival: params.before_arrival,
                value: params.value,
                type: params.type,
                is_nonrefundable: params.is_nonrefundable,
                is_free_cancellation: params.is_free_cancellation,
                affects_price: params.is_affect,
                affects_price_action_type: params.affects_price_action_type,
                affects_price_type: params.affects_price_type,
                affects_price_value: params.affects_price_value,
                is_date_range: params.is_date_range,
                start_date: params.start_date,
                finish_date: params.finish_date
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_CHANNEL_CANCELLATION_POLICY',
            payload: axios.post(UPDATE_CANCELLATION_POLICY, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
