import React from "react";
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Hidden} from "@material-ui/core";
import {
    APP,
    CONTENT,
    NETWORK,
    OFFER,
    WEB,
    PAYMENT_LIST,
    BOOKING_ENGINE,
    PAYMENT_TRANSACTION_LIST,
    PAYMENT_CREATE, NOT_ALLOWED_DASHBOARD_SHORT
} from "../../../../constants/appUrls.constants";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {history} from "../../../../utils/history";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getPaymentDashboard} from "../../../../redux/actions/Payment/PaymentDashboard.actions";
import {getPropertyName, isDefined} from "../../../../utils";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import NotAllowedPage from "../../Global/NotAllowedPage/NotAllowedPage";
import {propertyProducts} from "../../../../redux/actions/propertyProductsActions";
import Charts from "../../Global/Charts";

let t=null;

const drawerWidth = 101;
const leftMenuWidth = 252;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '999',
    },
    leftMenu: {
        width: 0,
        flexShrink: 0,
    },
    leftMenuPaper: {
        width: leftMenuWidth,
        marginLeft: 102,
        marginTop: 81
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '81px',
        display: 'inline-grid'
    },
    listItem: {
        display: 'block',
        textAlign: 'center !important',
        margin: 'auto',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftBarShift: {
        width: `calc(100% - ${leftMenuWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        boxShadow: '0 2px 4px 0 #ccced5',
        backgroundColor: '#ffffff',
        padding: '11px 12.14px'
    },
    hide: {
        display: 'none',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 125,
        marginRight: -60,
        paddingRight: 0,
    },
    content: {
        flexGrow: 1,
        padding: '24px 68px',
    },
    toolbar: theme.mixins.toolbar,
}));

class PaymentDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSuccessHeader: false,
            open : false,
            open_preview: false,
            copied: false
        };
        t = props.t;
    }

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClickOpenPreview = () => {
        this.setState({open_preview:true});
    };

    handleClose = () => {
        this.setState({open:false, open_preview:false});
    };

    handlePreviewClose = () => {
        this.setState({open_preview:false});
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getPaymentDashboard();
        this.props.propertyProducts();
    }

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={APP}
                               exitText={ t('btn-properties')}
                               text={ getPropertyName() }
                               showSuccessHeader={this.props.createStatus}
        />
    };

    render() {

        const allowedPage = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageOffer = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        function Sidebar(props) {
            const classes = useStyles();

            const sidebar = (
                <div className={classes.root}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <List className={classes.listItem}>
                            {props.siderbarItems.map((post , key) =>
                                <Link key={key} to={post.link} className="completed-title" variant="body2" color="textSecondary" >
                                    <ListItem className={post.isActive === true ? 'active-sideMenu sidebar-list': 'sidebar-list'} button key={post.id}>
                                        <div className={post.isActive === true ? 'active-sidebar': 'sidebar-items'}>
                                            <i className={post.icon} />
                                        </div>
                                        <ListItemText className="dashboard-menu" primary={post.text} />
                                    </ListItem>
                                </Link>
                            )}
                        </List>
                        <Charts />
                    </Drawer>
                </div>
            );
            return (
                <>
                    {sidebar}
                </>
            );
        }
        const siderbarItems = [
            {id: 1, isActive: false, text:  `${ t('enw-product_one-title') }`, icon: 'fas fa-hotel', link:'content'},
            {id: 2, isActive: false, text: `${t('enw-product_two-title') }`, icon: 'fas fa-chart-network', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? "network" : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#" },
            {id: 3, isActive: false, text: `${t('enw-product_three-title') }`, icon: 'fas fa-paperclip', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? "offer" : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#" },
            {id: 4, isActive: false, text: `${t('enw-product_four-title') }`, icon: 'fas fa-network-wired', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? "web" : NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#" },
            {id: 5, isActive: false, text: `${t('enw-product_six-title')}`, icon: 'fas fa-calendar-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? "booking-engine" : NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#" },
            {id: 6, isActive: true, text: `${t('enw-product_five-title') }`, icon: 'fas fa-money-bill-wave-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? "payment" : NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#" }
        ];

        const { paymentDashboardReducer } = this.props;

        return (
            <>
                <PageTitle title={ t('enw-product_five-title') } />
                {
                    this.headerStatusShow()
                }

                <Grid container className="payment-dashboard payment-main">
                    <Grid item md={1} className="m-none" style={{ height: 'fit-content'}}>
                        <Sidebar siderbarItems={siderbarItems} />
                    </Grid>
                    <Grid item xs={12} className="m-auto web-dashboard">
                        <div>
                            <CssBaseline />
                            {
                                isDefined(allowedPage[0]) && !allowedPage[0].property_has_product ?
                                    <Grid item xs={12} lg={6} className="no-buy-product">
                                        <Card className="p-24">
                                            <NotAllowedPage />
                                        </Card>
                                    </Grid>
                                    :
                                    <Grid container spacing={3} className="mt-44 be-container-width">
                                        <Grid item xs={12} sm={8} lg={7} className="m-auto web-stats be-dashboard-items">
                                            <Grid container spacing={3}>
                                                <Grid item md={7} xs={12} className="m-auto pt-20">
                                                    <Hidden xsDown>
                                                        <Card className="offer-dashboard__box-shadow" item xs={12}>
                                                            <Box className="offer-dashboard bg-soft-green" component="fieldset" mb={3} borderColor="transparent">
                                                                <i className="fas fa-money-bill-wave-alt green" />
                                                            </Box>
                                                            <CardContent className="min-height-153 pt-0 pr-24 pl-24">
                                                                <ColAtm>
                                                                    <ColAtm md={8} xl={9}>
                                                                        <Typography className="green pb-12" gutterBottom variant="h5" component="h2">
                                                                            { t('enw-payment-dashboard-subtitle') }
                                                                        </Typography>
                                                                    </ColAtm>
                                                                </ColAtm>
                                                                <Typography variant="body2" color="textSecondary" component="p">
                                                                    { t('enw-payment-dashboard-subdesc') }
                                                                </Typography>
                                                            </CardContent>
                                                            <ColAtm className="carousel-col general-carousel offer-block-tabs">
                                                                <Link to={PAYMENT_LIST}> { t('btn-payment_management') } </Link>
                                                                <Link to={PAYMENT_TRANSACTION_LIST}> { t('btn-payment_list') } </Link>
                                                                <Link to={PAYMENT_CREATE}> { t('btn-create_payment') } </Link>
                                                            </ColAtm>
                                                        </Card>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <Card className="offer-dashboard__box-shadow mobile-mb-0 mb-24" item xs={12}>
                                                            <Card>
                                                                <Grid container spacing={3}>
                                                                    <ColAtm>
                                                                        <ColAtm className="m-payment-dashboard" item xs={4}>
                                                                            <i className="fas fa-money-bill-wave-alt" />
                                                                        </ColAtm>
                                                                        <ColAtm item xs={8} className="border-shadow">
                                                                            <CardContent className="w-100">
                                                                                <ColAtm>
                                                                                    <ColAtm xs={12}>
                                                                                        <Typography className="green" gutterBottom noWrap>
                                                                                            { t('enw-payment-dashboard-subtitle') }
                                                                                        </Typography>
                                                                                    </ColAtm>
                                                                                </ColAtm>
                                                                                <Typography variant="body2" color="textSecondary" component="p">
                                                                                    { t('enw-payment-dashboard-subdesc') }
                                                                                </Typography>
                                                                            </CardContent>
                                                                        </ColAtm>
                                                                    </ColAtm>
                                                                    <ColAtm className="carousel-col general-carousel offer-block-tabs">
                                                                        <Carousel slidesPerPage={2}
                                                                                  infinite
                                                                                  keepDirectionWhenDragging
                                                                                  arrowLeft={<i className="fas fa-caret-left" />}
                                                                                  arrowRight={<i className="fas fa-caret-right" />}
                                                                                  addArrowClickHandler
                                                                                  arrows
                                                                        >
                                                                            <Link key={0} to={PAYMENT_LIST}> { t('btn-payment_management') } </Link>
                                                                            <Link key={1} to={PAYMENT_TRANSACTION_LIST}> { t('btn-payment_list') } </Link>
                                                                            <Link key={2} to={PAYMENT_CREATE}> { t('btn-create_payment') } </Link>
                                                                        </Carousel>
                                                                    </ColAtm>
                                                                </Grid>
                                                            </Card>
                                                        </Card>
                                                    </Hidden>
                                                </Grid>
                                                <Grid item md={5} xs={12} className="m-auto">
                                                    <Grid container spacing={3} className="mobile-offer-boxes-padding">
                                                        <Grid item xs={6}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="far fa-thumbs-up green fs-22 bg-icon-green m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                            <span className="payment-info-text m-auto f-w-bold">
                                                                {
                                                                    paymentDashboardReducer.fetching ?
                                                                        <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                        (
                                                                            <>{paymentDashboardReducer.paymentDashboard.confirmed_transactions}</>
                                                                        )
                                                                }
                                                            </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-payment-dashboard-successful_transactions') }</span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-ballot-check orange fs-22 bg-icon-orange m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                            <span className="payment-info-text m-auto f-w-bold">
                                                                  {
                                                                      paymentDashboardReducer.fetching ?
                                                                          <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                          (
                                                                              <>{paymentDashboardReducer.paymentDashboard.all_transactions}</>
                                                                          )
                                                                  }
                                                            </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title">  { t('enw-payment-dashboard-all_transactions') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-repeat purple fs-22 bg-icon-purple m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                            <span className="payment-info-text m-auto f-w-bold">
                                                                {
                                                                    paymentDashboardReducer.fetching ?
                                                                        <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                        (
                                                                            <>{paymentDashboardReducer.paymentDashboard.conversion_rate}</>
                                                                        )
                                                                }
                                                            </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-payment-dashboard-conversion_rate') }   </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-boxes-alt blue fs-22 bg-icon-blue m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                            <span className="payment-info-text m-auto f-w-bold">
                                                                 {
                                                                     paymentDashboardReducer.fetching ?
                                                                         <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                         (
                                                                             <>{paymentDashboardReducer.paymentDashboard.active_pos_count}</>
                                                                         )
                                                                 }
                                                            </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-payment-dashboard-active_pos_number') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <BottomNavigation className="m-navigation" value="payment">
                        <BottomNavigationAction value="content" icon={<i className="fas fa-hotel" />} onClick={() => { history.push(CONTENT)}}  />
                        <BottomNavigationAction value="network" icon={<i className="fas fa-chart-network" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#" )}} />
                        <BottomNavigationAction value="offer" icon={<i className="fas fa-paperclip" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? OFFER : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#")}} />
                        <BottomNavigationAction value="web" icon={<i className="fas fa-network-wired" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? WEB :  NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#")}} />
                        <BottomNavigationAction value="booking" icon={<i className="fas fa-calendar-alt" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? BOOKING_ENGINE :  NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#")}} />
                        <BottomNavigationAction value="payment" icon={<i className="fas fa-money-bill-wave-alt" />} />
                    </BottomNavigation>
                </Hidden>
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        paymentDashboardReducer : props.paymentDashboardReducer,
        propertyProductsReducer : props.propertyProductsReducer
    }
};

const mapDispatchToProps = {
    getPaymentDashboard,
    propertyProducts
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PaymentDashboard));
