import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../utils";
import {
    CREATE_WEB, GET_WEB, UPDATE_WEB
} from "../../constants/api.constants";


export function getWeb() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_WEB',
            payload: axios.post(GET_WEB, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createWeb(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                domain : params.domain,
                default_language : params.language,
                template_id : 1
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_WEB',
            payload: axios.post(CREATE_WEB, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data )
        })
    }
}

export function updateWeb(params) {


    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                property_web_id: params.property_web_id,
                domain : params.domain,
                default_language : params.language,
                template_id : 1
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_WEB',
            payload: axios.post(UPDATE_WEB, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data )
        })
    }
}
