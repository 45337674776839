import React, {useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getReservationExcelClear} from "../../../../redux/actions/Network/Reservation/reservationList.action";


const ExportReservationListToExcel = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reservationExcel = useSelector(props => props.reservationListReducer.reservationExcel);


    const submit = () => {
        props.ReservationExcel();
    }

    useEffect(() => {
        if (reservationExcel && reservationExcel.url) {
            window.open(reservationExcel.url, '_blank');
            dispatch(getReservationExcelClear());
        }
    }, [reservationExcel])

    return (
        <Tooltip title={ t('datatable-toolbar-download_excel') }>
            <IconButton onClick={submit}>
                <i className="fas fa-file-excel"/>
            </IconButton>
        </Tooltip>
    );

}
export default ExportReservationListToExcel
