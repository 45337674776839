import {blogConstants} from "../../../../constants/Web/blog.constants";

const initialState = {
    fetching: false,
    listData: [],
    listDataError: false,

    createBlogFetching: false,
    createBlog: [],
    createBlogError: false,

    updateBlogFetching: false,
    updateBlog: [],
    updateBlogError: false,

    createImageFetching: false,
    createImage: [],
    createImageError: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case blogConstants.BLOG_LIST_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case blogConstants.BLOG_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                listDataError: false,
                listData: action.payload,
            };
        case blogConstants.BLOG_LIST_REJECTED:
            return {
                ...state,
                fetching: false,
                listDataError: action.payload
            }

        case blogConstants.CREATE_BLOG_PENDING:
            return {
                ...state,
                createBlogFetching: true,
            };
        case blogConstants.CREATE_BLOG_FULFILLED:
            return {
                ...state,
                createBlogFetching: false,
                createBlogError: false,
                createBlog: action.payload,
            };
        case blogConstants.CREATE_BLOG_REJECTED:
            return {
                ...state,
                createBlogFetching: false,
                createBlogError: action.payload
            };
        case blogConstants.CREATE_BLOG_CLEAR:
            return {
                ...state,
                createBlogFetching: false,
                createBlog: [],
                createBlogError: false,
            };

        case blogConstants.UPDATE_BLOG_PENDING:
            return {
                ...state,
                updateBlogFetching: true,
            };
        case blogConstants.UPDATE_BLOG_FULFILLED:
            return {
                ...state,
                updateBlogFetching: false,
                updateBlogError: false,
                updateBlog: action.payload,
            };
        case blogConstants.UPDATE_BLOG_REJECTED:
            return {
                ...state,
                updateBlogFetching: false,
                updateBlogError: action.payload
            }
        case blogConstants.UPDATE_BLOG_CLEAR:
            return {
                ...state,
                updateBlogFetching: false,
                updateBlog: [],
                updateBlogError: false,
            };

        case blogConstants.CREATE_IMAGE_PENDING:
            return {
                ...state,
                createImageFetching: true,
            };
        case blogConstants.CREATE_IMAGE_FULFILLED:
            return {
                ...state,
                createImageFetching: false,
                createImageError: false,
                createImage: action.payload,
            };
        case blogConstants.CREATE_IMAGE_REJECTED:
            return {
                ...state,
                createImageFetching: false,
                createImageError: action.payload
            }
        case blogConstants.CREATE_IMAGE_CLEAR:
            return {
                ...state,
                createImageFetching: false,
                createImage: [],
                createImageError: false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
