import React, {useEffect, useState} from "react";
import InputAtm from "../../../../../atomic/Input/Input";
import {Grid} from "@material-ui/core";
import moment from "moment";
import {isDefined} from "../../../../../../utils";

export default function QuickPricingPrice(props) {
    useEffect(() => {},[]);

    const {key} = props.value
    const {updatedData} = props

    const [price, setPrice] = useState( "");
    const [hasData, setHasData] = useState(updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);

    const onChange = event => {
        let newPrice = event.target.value;
        let priceKey = event.target.name;
        setPrice( newPrice );
        setHasData (false);
        props.onChangeUpdateData(priceKey, newPrice);
    };

    const quickPriceKey = key.split('_');

    return (
        <Grid className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) ? 'weekend-inputs' : ""} inventory-price t-center`}>
            <InputAtm
                name={quickPriceKey[4]}
                placeholder="-"
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                min={1}
                value={price}
                className={(hasData && isDefined(price)) ? "has-value-inventory type-number-hidden" : "type-number-hidden"}
                onChange={onChange}
            />
            <p className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && (isDefined(props.channelAvailability) && props.channelAvailability.id === 3 ? "guaranteed-weekend-radius" : isDefined(props.channelAvailability) && props.channelAvailability.id === 2 ? "weekend-radius-limited" : 'weekend-radius')} null-inventory`}> &nbsp;</p>
        </Grid>
    );
}
