import { factConstants } from "../../constants/Content/fact.constans"

const initialState = {
    fact_list   : '',
    isFetching: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case factConstants.FACT_REQUEST:
            return {
                ...state,
                payload    : action.response
            };
        case factConstants.FACT_SUCCESS:
            return {
                ...state,
                fact_list: action.fact_list,
                payload: action.response
            };
        case factConstants.LOGIN_FAILURE:
            return {};
        default:
            return state
    }
}
