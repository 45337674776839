import axios from 'axios';
import {getUserToken, getProperty, getAppLang, isDefined} from "../../../../utils";
import {
    CHANNEL_SETUP_GET, CHANNEL_SETUP_ADD
} from "../../../../constants/api.constants";


export function getChannelSetup(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_SETUP_GET',
            payload: axios.post(CHANNEL_SETUP_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function addChannelSetup(params) {
    const formData = new FormData();

    if (isDefined(params.contract_file) && isDefined(params.contract_file.type)) {
        formData.append('contract_file', params.contract_file, params.contract_file.name);
    }
    formData.append( 'property_id', getProperty());
    formData.append( 'channel_id', params.channel_id);
    formData.append( 'booking_type_id', params.booking_type_id);
    formData.append( 'availability_type_id', params.availability_type_id);
    formData.append( 'room_pricing_type_id', params.room_pricing_type_id);
    formData.append( 'currency_code', params.currency_code);
    formData.append( 'connected_channel_id', params.connected_channel_id);
    formData.append( 'connected_channel_action', JSON.stringify(params.connected_channel_action));

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data', 'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_SETUP_ADD',
            payload: axios.post(CHANNEL_SETUP_ADD, requestOptions.data, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
