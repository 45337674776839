import React from 'react';

import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ListItem from './ListItem';
let t=null;



class PlacesList extends React.Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

  render() {
    const places = this.props.places;
    return (
        <div className="location-results">
            <ul className="list-group">
                {
                    places.length > 0 &&
                    <div>
                        <h3 className="location-find-results">{this.props.propertyName}  {places.length} { t('enw-general-location-search_result_list') }  </h3>
                    </div>

                }
              {
                places.map((place, index) => {
                  return (
                    <ListItem
                      key={index.toString()}
                      place={place}
                      handlePlaceSelect={this.props.handlePlaceSelect}
                      updateLocationAddress={this.props.updateLocationAddress}
                    />
                  );
                })
              }
              {
                  places.length > 0  &&
                  <ColAtm>
                      <ButtonAtm className="location-find-no-results orange-button"
                                 buttontext={ t('enw-general-location-not_found_my_facility_message') }
                                 onClick={this.props.addManuelAddress}
                      />
                  </ColAtm>
              }
            </ul>
        </div>
    );
  }
}

export default compose(withTranslation('common'))(PlacesList);
