import React, { useEffect, useState } from "react";
import Accommodation from "./Accommodation";

export default function Accommodations(props) {

    useEffect(() => {});
    const [accommodations, setAccommodations] = useState(props.accommodations);


    const onChange = (accommodationIndex , newAccommodation) => {
        let newValue = newAccommodation;
        setAccommodations(prevState => {
            let newItem = { ...prevState, [accommodationIndex]: newValue };
            props.onChange(props.roomIndex, accommodationIndex, newItem);
            let returnNewData = Object.assign([],newItem);
            return returnNewData;
        });
    };

    return (
        <>
        {
            accommodations.map( (accommodation,accommodationIndex) => (
                <Accommodation accommodation={accommodation}
                               accommodationIndex={accommodationIndex}
                               onChange={onChange}
                               currency={props.currency}
                               dateRangeChange={props.dateRangeChange}
                               allDays={props.allDays}
                />
            ) )
        }
        </>


    );
}
