import React, {Component} from 'react';
import {PageTitle}        from "../../../atomic/PageHeader/PageTitle";
import RowAtm             from "../../../atomic/Row/Row";
import Container          from "@material-ui/core/Container";
import {connect}          from "react-redux";
import {Animated}         from "react-animated-css";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import ProfileForm        from "./components/ProfileForm";
import {getProfile,updateProfile} from "../../../../redux/actions/User/Profile/profileAction"
import SuccessModal from "../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import {setUserName} from "../../../../utils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {CONTENT} from "../../../../constants/appUrls.constants";
let t=null;

class Profile extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        this.props.getProfile();
    }

    showMessage = () => {

        if( this.props.profileReducer.updateProfileData.error === false ){

            let name = this.props.profileReducer.updateProfileData.data.name;
            let surname = this.props.profileReducer.updateProfileData.data.surname;

            setUserName(name+" "+ surname);
            return <SuccessModal status={true} message={"Your profile information has been updated"}/>
        }

        if( this.props.profileReducer.updateProfileData.error === true &&
            this.props.editOfferReducer.error.response
        ){
            return  <ErrorModal status={true}/>
        }

        if( Object.keys(this.props.profileReducer.error).length > 0 && this.props.profileReducer.error.response){
            return  <ErrorModal status={true} message={'An unknown error has occurred'}/>
        }
    }


    render() {
        return (
            <>
                <PageTitle title={ t('enw-edit-profile-page_title') } />
                <PageHeaderSaveAtm
                    saveText="Save"
                    backText={ t('btn-dashboard') }
                    backButton={CONTENT}
                    text={ t('enw-edit-profile-title') }
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" className="border-top-red-border">
                        <div className="container-width">
                            <RowAtm>
                                {
                                    !this.props.profileReducer.fetching &&
                                    <ProfileForm
                                        userProfile={this.props.profileReducer}
                                        languages={this.props.profileReducer.profileData.languages}
                                        updateProfile = {this.props.updateProfile}
                                    />
                                }
                                {
                                    this.showMessage()
                                }

                            </RowAtm>
                        </div>
                    </Container>
                </Animated>
            </>

        );
    }
}

const mapStateToProps = (props) => {
    return {
        profileReducer : props.profileReducer,
    }
};

const mapDispatchToProps = {
    getProfile,
    updateProfile
};

//export default connect(mapStateToProps, mapDispatchToProps )(Profile);
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Profile));
