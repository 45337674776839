import React, { Component } from 'react'
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from 'react-i18next';
import {compose} from "redux";
import {Grid, NoSsr} from "@material-ui/core";
import ChannelListBox from "../../../../molecules/ChannelListBox/ChannelListBox";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {NETWORK} from "../../../../../constants/appUrls.constants";
import {history} from "../../../../../utils/history";
import {Animated} from "react-animated-css";
import CreateChannelGroupModal from "./ChannelGroup/createChannelGroupModal";
import LabelAtm from "../../../../atomic/Label/Label";
import GroupChannels from "./ChannelGroup/GroupChannels";
import ChannelList from "./ChannelList/ChannelList";

let t =null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});



class GetChannels extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_list: this.props.channel_list ? this.props.channel_list : '',
            isVisible: true,
            isShowInformation: false,
            isShowList: true,
            fadeOut: false,
            fadeIn: true,
        };
        t = props.t;
    }

    handleClose = () => {
        this.setState({open:false})
    };

    ChannelList = () => {
        return (
            <NoSsr>
                {Object.values(this.props.channel_list).map((channel) => (
                    <ChannelListBox
                        checkboxId={channel.id}
                        className="fact-box"
                        colClassname="offer-fact-col mb-12 tablet-pb-20"
                        blockClass="offer-fact-border-box fact-border-box"
                        channelLabelClass={channel.is_selected ? "assign-channel-label" : "de-select-channel-name"}
                        channelBlockClass={channel.is_selected ? "selected-channel-block" : "de-select-channel"}
                        src={{backgroundImage: `url(/assets/img/brands/${channel.id}.png)`}}
                        activeIcon={channel.is_selected ? "channel-active-icon fad fa-dot-circle green" : channel.is_pending && "channel-active-icon fad fa-dot-circle blue"}
                        value={channel.id}
                        defaultChecked={channel.mapped}
                        factName={channel.name}
                        onClick={(e) => this.handleClickOpen(e, channel.id)}
                        text={channel.name}
                        labelClass="fact-name"
                        channelImage={channel.is_selected ? "channel-logo-bg": channel.is_pending ? "channel-logo-bg channel-grayscale-pending-box" : "channel-logo-bg channel-grayscale-box"}
                        htmlFor={channel.id}
                    />
                    ))}
            </NoSsr>
        );
    };

    handleClickOpen (e, id) {

        setTimeout(() => {
            this.setState({
                isShowInformation: true,
                isShowList: false
            })
        }, 300)

        this.setState({
            fadeIn: true,
            fadeOut: true,
        })

        history.push(`/app/network/channel-information/${id}`)

    };

    render() {

        return (
            <>
                <PageHeaderAtm backButton={NETWORK} exitText={ t('btn-dashboard') } text={ t('enw-channels-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" >
                        <div className="container-width channel-list-main">
                            <Grid container>
                                <Grid item xs={12} className="mb-16">
                                    <Grid item className="photoButtonsRow new-channel-group-btn">
                                        <CreateChannelGroupModal
                                            channelList={this.props.channel_list}
                                            channelGroupList={this.props.channelGroupList}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    !this.props.channelGroupList.get_fetching && !this.props.channel_list_reducers.fetching &&
                                    <Grid item xs={12} className="mt-22">
                                        {
                                            this.props.channelGroupList.get_mapping.length > 0 &&
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <LabelAtm variant="h2" text={ t('enw-channels_group-title') } labelClass="channel-list-title" />
                                                </Grid>
                                                <GroupChannels
                                                    channelGroupList={this.props.channelGroupList}
                                                />
                                            </Grid>
                                        }
                                        <Grid container>
                                            <Grid xs={12}>
                                                <LabelAtm variant="h2" text={ t('enw-channels_all-title') } labelClass="channel-list-title mt-20" />
                                            </Grid>
                                            <ChannelList
                                                channelList={this.props.channel_list}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (GetChannels);
