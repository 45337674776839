import { getUserToken,getProperty ,getAppLang}                 from "../../utils/index";
import { GET_OFFER_LIST }                 from "../../constants/api.constants";
import axios                            from 'axios';

export function getOfferList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'OFFER_LIST',
            payload: axios.post(GET_OFFER_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}