import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CheckboxAtm from "../../../../atomic/Checkbox/Checkbox";
import {UpdateRoomRatePromotion} from "../../../../../redux/actions/Network/Promotion/Promotion.actions";

let lastChangeID = null;
const PromotionCheckBox = (props) => {

    const {roomId, rateId, promotionId, rateMappingId, channelId} = props;

    const updateRoomRatePromotionReducer = useSelector(props => props.promotionReducers?.updateRoomRatePromotion)
    const [isSelected , setIsSelected ] = useState( props.isSelected );
    const dispatch = useDispatch();

    const handleUpdateChild = () => {

        let params = {}
        if(isSelected){
            params = {
                room_rate_channel_mapping_id: rateMappingId,
                property_promotion_id: promotionId,
                is_selected: false
            };
            dispatch( UpdateRoomRatePromotion( params ) )
            lastChangeID = "room" + roomId + "rate" + rateId + "id" + promotionId +"channel" + channelId
        }else{
            params = {
                room_rate_channel_mapping_id: rateMappingId,
                property_promotion_id: promotionId,
                is_selected: true
            };
            dispatch( UpdateRoomRatePromotion( params ) )
            lastChangeID = "room" + roomId + "rate" + rateId + "id" + promotionId +"channel" + channelId
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {
        if (updateRoomRatePromotionReducer?.status === 200 && lastChangeID === "room" + roomId + "rate" + rateId + "id" + promotionId +"channel" + channelId){
            setState();
        }

    },[updateRoomRatePromotionReducer])


    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={"room" + roomId + "rate" + rateId + "id" + promotionId +"channel" + channelId}
            value={promotionId}
            checked={isSelected}
            onChange={handleUpdateChild}
        />
    )


}
export default PromotionCheckBox;
