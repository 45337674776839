import {
    reservationExcelConstants,
    reservationListConstants,
    reservationListFilterConstants
} from "../../../../../constants/Network/reservation.constants";

const initialState = {
    fetching :false,
    reservationList: [],
    reservationExcel: [],
    error    :{},
    fetchingListFilter :false,
    reservationListFilter: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case reservationListConstants.RESERVATION_LIST_PENDING:
            return {
                fetching:true,
                error: {},
            };
        case reservationListConstants.RESERVATION_LIST_FULFILLED:
            return {
                ...state,
                fetching : false,
                reservationList: action.payload,
                error: {},
            };
        case reservationListConstants.RESERVATION_LIST_REJECTED:
            return {
                ...state,
                reservationList: [],
                error: action.payload,
                fetching : false,
            };

        case reservationExcelConstants.RESERVATION_EXCEL_FULFILLED:
            return {
                ...state,
                fetching : false,
                reservationExcel: action.payload,
                error: {},
            };
        case reservationExcelConstants.RESERVATION_EXCEL_CLEAR_STATE:
            return {
                ...state,
                reservationExcel: [],
                error: {},
            };

        case reservationListFilterConstants.LIST_FILTER_PENDING:
            return {
                fetchingListFilter: true,
            };
        case reservationListFilterConstants.LIST_FILTER_FULFILLED:
            return {
                ...state,
                fetchingListFilter : false,
                reservationListFilter: action.payload,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
