import React, {Component}   from 'react';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {PAYMENT} from "../../../../constants/appUrls.constants";
import {connect} from "react-redux";
import {getCreateManualPaymentData, createManualPayment} from "../../../../redux/actions/Payment/PaymentCreate.actions";
import CreateManualPaymentForm from "./createManualPaymentForm";

let t =null;

class CreateManualPayment extends Component {
    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        this.props.getCreateManualPaymentData();
    }

    render() {

        const {paymentManualCreateReducers} = this.props;

        return (
            <>
                <PageTitle title={ t('enw-payment-create_manual_payment-page_title') } />
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={PAYMENT} text={ t('enw-payment-create_manual_payment-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" className="border-top-red-border">
                        <div className="container-width">
                            <CreateManualPaymentForm getCreateData={paymentManualCreateReducers.getCreateData}
                                                     createManualPayment={this.props.createManualPayment}
                                                     createError={paymentManualCreateReducers.createError}
                                                     createFormData={paymentManualCreateReducers.createData}
                                                     createFetching={paymentManualCreateReducers.createFetching}
                            />
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return{
        paymentManualCreateReducers: props.paymentManualCreateReducers
    }
};

const mapDispatchToProps = {
    getCreateManualPaymentData,
    createManualPayment
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps)(CreateManualPayment));
