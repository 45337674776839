import {propertyProductsConstants} from "../../constants/Public/propertyProducts.constans";

const initialState = {
    fetching : true,
    error : '',
    get_products: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case propertyProductsConstants.PROPERTY_PRODUCTS_PENDING:
            return {
                fetching: true,
                error: false,
                get_products: {},
            };
        case propertyProductsConstants.PROPERTY_PRODUCTS_FULFILLED:
            return {
                fetching: false,
                get_products: action.payload,
            };
        case propertyProductsConstants.PROPERTY_PRODUCTS_REJECTED:
            return {
                error: action.payload,
                fetching: false
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
