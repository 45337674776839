import React, {useEffect, useState} from "react";
import InputAtm from "../../../../atomic/Input/Input";
import {Grid} from "@material-ui/core";
import moment from "moment";
import {isDefined} from "../../../../../utils";
import {useTranslation} from "react-i18next";

export default function MinStay(props) {
    useEffect(() => {},[]);

    const { t } = useTranslation();
    const {key, value, stop_sell} = props.value
    const {updatedData} = props

    const [minStay, setMinStay] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? updatedData[key] : value);
    const [hasData, setHasData] = useState(updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);
    const [disabled, setDisabled] = useState(stop_sell);

    const onChange = event => {
        let newPrice = event.target.value;
        let priceKey = event.target.name;
        setMinStay( newPrice );
        setHasData (false);
        props.onChangeUpdateData(priceKey, newPrice);
    };

    const disabledKey = key.split('_');
    const disabledKeyMerge = "STS_" + disabledKey[1] + "_" + disabledKey[2] + "_" + disabledKey[3] + "_" + disabledKey[4];

    useEffect( () => {
        if (Object.keys(updatedData).some(item => disabledKeyMerge === item)) {

            if (updatedData[disabledKeyMerge] === 1) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }

    },[updatedData])

    return (
        <Grid className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) ? 'weekend-inputs' : ""} inventory-price t-center`}>
            <InputAtm
                name={key}
                placeholder="-"
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                min={1}
                value={minStay}
                className={(hasData && isDefined(minStay)) ? "has-value-inventory type-number-hidden" : "type-number-hidden"}
                onChange={onChange}
                title={disabled ? t('btn-stop_sell') : ""}
                disabled={disabled}
            />
            <p className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && 'weekend-radius'} null-inventory`}> &nbsp;</p>
        </Grid>
    );
}
