import React, { Component } from 'react'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {withTranslation} from 'react-i18next';
import ColAtm from "../../../../../atomic/Col/Col";
import {compose} from "redux";
import 'react-phone-input-2/lib/material.css'
import {Grid, NoSsr} from "@material-ui/core";
import {history} from "../../../../../../utils/history";
import ChannelListBox from "../../../../../molecules/ChannelListBox/ChannelListBox";
import PageHeaderAtm from "../../../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {NETWORK} from "../../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";
import LabelAtm from "../../../../../atomic/Label/Label";
let t =null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    div: {
        position: 'absolute',
        top: 28,
        right: 0,
        left: 0,
        width: '120px',
        zIndex: '9',
        borderRadius: '4px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.11)',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));


class InventoryList extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_list: this.props.channel_list ? this.props.channel_list : '',
            isVisible: true,
            isShowInformation: false,
            isShowList: true,
            fadeOut: false,
            fadeIn: true,
        };
        t = props.t;
    }

    handleClose = () => {
        this.setState({open:false})
    };

    ChannelList = () => {
        return (
            <NoSsr>
                {Object.values(this.props.channel_list).map((channel) => (
                    channel.is_selected && channel.connected_channel_id === null && (channel.parent_id === null || channel.channel_category_id === 3) &&
                        <ChannelListBox
                            checkboxId={channel.id}
                            className="fact-box"
                            colClassname="offer-fact-col mb-12 tablet-pb-20"
                            blockClass="offer-fact-border-box fact-border-box"
                            channelLabelClass={"assign-channel-label"}
                            channelBlockClass={"selected-channel-block"}
                            src={{backgroundImage: `url(${channel.logoUrl})`}}
                            activeIcon="channel-active-icon fad fa-dot-circle green"
                            value={channel.id}
                            defaultChecked={channel.mapped}
                            factName={channel.name}
                            onClick={(e) => this.handleClickOpen(e, channel.id)}
                            text={channel.name}
                            labelClass="fact-name"
                            channelImage={"channel-logo-bg"}
                            htmlFor={channel.id}
                        />
                    ))}
            </NoSsr>
        );
    };

    handleClickOpen (e, id) {
        history.push(`/app/network/inventory/${id}`)
    };

    handleClickGroupOpen (e, id) {
        history.push(`/app/network/group-inventory/${id}`)
    };

    render() {
        const {channelGroupList} = this.props;
        return (
            <>
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={NETWORK} text={ t('enw-network-inventory_list-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            {
                                !this.props.channelGroupList.get_fetching && !this.props.channel_list_reducers.fetching &&
                                <ColAtm>
                                    {
                                        this.props.channelGroupList.get_mapping.length > 0 &&
                                        <Grid container>
                                            <Grid xs={12}>
                                                <LabelAtm variant="h2" text={ t('enw-channels_group-title') } labelClass="channel-list-title" />
                                            </Grid>
                                            <Grid container spacing={3}>
                                                {
                                                    Object.values(channelGroupList.get_mapping).map( groupChannel => (
                                                        <ChannelListBox
                                                            checkboxId={groupChannel.id}
                                                            className="fact-box"
                                                            colClassname="offer-fact-col mb-12 tablet-pb-20"
                                                            blockClass="offer-fact-border-box fact-border-box"
                                                            channelLabelClass={"assign-channel-label"}
                                                            channelBlockClass={"selected-channel-block"}
                                                            src={{backgroundImage: `url(/assets/img/brands/1.png)`}}
                                                            activeIcon="channel-active-icon fad fa-dot-circle green"
                                                            value={groupChannel.id}
                                                            defaultChecked={groupChannel.mapped}
                                                            factName={groupChannel.name}
                                                            onClick={(e) => this.handleClickGroupOpen(e, groupChannel.id)}
                                                            text={groupChannel.name}
                                                            labelClass="fact-name"
                                                            channelImage={"channel-logo-bg"}
                                                            htmlFor={groupChannel.id}
                                                        />
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container>
                                        <Grid xs={12}>
                                            <LabelAtm variant="h2" text={ t('enw-channels_all-title') } labelClass="channel-list-title mt-20" />
                                        </Grid>
                                        <Grid container spacing={3}>
                                            {this.ChannelList()}
                                        </Grid>
                                    </Grid>
                                </ColAtm>
                            }
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (InventoryList);
