import React, {useEffect,useMemo} from "react";
import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";
import { withStyles } from '@material-ui/core/styles';
import Accommodations from "./Accommodations";
import Availabilities from "./Availabilities";
import Tooltip from '@material-ui/core/Tooltip';
import {Grid, Typography} from "@material-ui/core";
import InventorySkeleton from "../../../../molecules/InventorySkeleton/InventorySkeleton";
import RemainingAvailabilities from "./RemainingAvailabilities";
import {useTranslation} from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import {isDefined} from "../../../../../utils";

let updatedData = [];

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const Rooms =  (props) => {
    const state = useMemo( () => props.roomPrice,[props.roomPrice] )
    const { t } = useTranslation();
    useEffect(() => {
        if(props.inventoryUpdate === 200){
            updatedData = [];
            props.changePriceStatus(false);
        }
        return () => {
            updatedData = [];
        }
    },[props.inventoryUpdate]);


    /*const onInputChange = (roomIndex,accommodationIndex, item) => {
        state[roomIndex]['accommodation'][accommodationIndex] = item;
    };*/

    const onChangeUpdateData = (key, value) => {
        /*let tempData = updatedData;
        let newObj = { ...tempData, [key]: value }
        //setUpdatedData(newObj)
        updatedData = newObj;*/
        updatedData[key] = value
        props.isChangePriceStatus();
    }

    const postData = (e) => {
        e.preventDefault();
        props.updatePropertyInvertory(updatedData)
    };

    return (
        <>
            {
                props.fetching ?
                    <InventorySkeleton/>
                    :
                    <form onSubmit={postData}
                          className={props.type === "quickPrice" ? "quick-price-inventory" : ""}
                          name="general-form"
                          id="general-form"
                          autoComplete="off"
                          style={{width: '100%'}}
                    >
                        {
                            state.map( (room) => (

                                <ColAtm className="inventory-box">
                                    <ColAtm className="inventory-room-box">
                                        <ColAtm xs={3} lg={4} className="align-self-center">
                                            <BlockAtm md={1} className="m-h-auto">
                                                <i className="fas fa-bed inventory-icon" />
                                            </BlockAtm>
                                            <BlockAtm xs={6}>
                                                <LabelAtm labelClass="inventory-room-name" text={room.name} />
                                            </BlockAtm>
                                            <Grid item xs={3}>
                                                <Grid container>
                                                    <Grid item xs={11}>
                                                        <LabelAtm labelClass="inventory-room-avail" text={ t('enw-room_and_rates-assign_rate-checkbox_title-availability') } />
                                                    </Grid>
                                                    <Grid item xs={1} className="align-self-center m-display-none">
                                                        <HtmlTooltip
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit"> { t('enw-network-inventory-toolbar_title') } </Typography>
                                                                    { t('enw-network-inventory-toolbar_desc') }
                                                                    <b><u>{ t('btn-all_channels') }</u></b>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <i className="fas fa-question-circle" />
                                                        </HtmlTooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ColAtm>
                                        <ColAtm xs={8} className="inventory-date-padding">
                                            <Availabilities onChangeUpdateData={onChangeUpdateData}
                                                            availabilities={room.room_availability}
                                                            stopSellAvailabilities={room.room_stop_sell}
                                                            updatedData={updatedData}
                                                            showStopSell={props.showStopSell}
                                            />
                                        </ColAtm>
                                    </ColAtm>
                                    {
                                        isDefined(props.channelAvailability) && props.channelAvailability.id === 3 &&
                                        <Grid container md={12}>
                                            <Grid item md={2} className="align-self-center">
                                            </Grid>
                                            <Grid item xs={10} className="inventory-room-box">
                                                <Grid container>
                                                    <Grid item md={12} className="align-self-center">
                                                        <Grid container className="mt-6">
                                                            <Grid item xs={2}>
                                                                <Typography className="inventory-room-avail">
                                                                    { ReactHtmlParser(t('enw-network-inventory-guaranteed_availability_left') ) }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1} className="align-self-center">
                                                                <HtmlTooltip
                                                                    arrow
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Typography color="inherit">{ t('enw-network-inventory-toolbar_title') } </Typography>
                                                                            { t('enw-network-inventory-toolbar_desc') }
                                                                            <b><u>{ t('btn-all_channels') }</u></b>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle" />
                                                                </HtmlTooltip>
                                                            </Grid>
                                                            <Grid item xs={9} className="remaining-availability">
                                                               <RemainingAvailabilities
                                                                   remainingAvailability={room.remaining_availability}
                                                               />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Accommodations onChangeUpdateData={onChangeUpdateData}
                                                    roomRates={room.property_room_rate_mapping}
                                                    updatedData={updatedData}
                                                    channelAvailability={props.channelAvailability}
                                                    showStopSell={props.showStopSell}
                                                    showMinStay={props.showMinStay}
                                    />
                                </ColAtm>

                            ))
                        }
                    </form>
            }
        </>
    )
}

export default Rooms;
