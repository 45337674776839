import React, {useEffect, useState} from 'react';
import FactDefaultBox from "../../../../molecules/FactDefaultBox/FactDefaultBox";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {updateFactMapping} from "../../../../../redux/actions/Content/FactList/factListActions";
import FactDescriptionModal from "./FactDescriptionModal";
let lastFactUpdateId=null;
const FactBox = (props) => {

    const { fact, getFactList } = props
    const [isSelected , setIsSelected ] = useState( props.isSelected );
    const updateFactMappingStatus = useSelector(props => props.updateFactMappingReducer?.updateFactMappingStatus)
    const dispatch = useDispatch();

    useEffect( () => {

        if (updateFactMappingStatus === 200 && lastFactUpdateId === fact.id+"-"+fact.name+"-"+fact.mapping_id){
            setState();
        }
    },[updateFactMappingStatus]);

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }
    const handleUpdateClick = (e,fact) => {

        let params = {}
        lastFactUpdateId =fact.id+"-"+fact.name+"-"+fact.mapping_id

        if(isSelected){
            params = {
                    property_fact: [{
                        id: fact.id,
                        is_selected: false,
                    }]
            }
            dispatch( updateFactMapping( params ) )

        }else{
            params = {
                property_fact: [{
                    id: fact.id,
                    is_selected: true,
                }]
            }
            dispatch( updateFactMapping( params ) )
        }
    }

    const { t } = useTranslation();

        return (
            <div>
                {
                    (fact.is_selected || isSelected) &&
                    <FactDescriptionModal
                        description={fact.description}
                        id={fact.id}
                        pageId={props.pageId}
                        type="modal"
                    />
                }
                <FactDefaultBox
                    checkboxId={fact.id+"-"+fact.name+"-"+fact.mapping_id}
                    name={t(fact.language_key)}
                    className="fact-box"
                    blockClass="fact-border-box"
                    labelIconClass="fact-icon-label"
                    iconClass={`fact-icon ${fact.icon}`}
                    key={fact.id+"-"+fact.name+"-"+fact.mapping_id}
                    checked={isSelected}
                    factName={t(fact.language_key)}
                    tooltip={t(fact.language_key)}
                    text={t(fact.language_key)}
                    labelClass="fact-name"
                    htmlFor={fact.id}
                    onClick={ (e) => { handleUpdateClick(e, fact)} }
                />
            </div>
        );

}

export default FactBox;
