import {downloadContentPhotosConstants} from "../../../../constants/Content/downloadContentPhotos.constans";

const initialState = {
    fetching : false,
    status : false,
    contentPhotosData : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case downloadContentPhotosConstants.DOWNLOAD_CONTENT_PHOTOS_PENDING:
            return {
                ...state,
                fetching: true,
                contentPhotosData : [],
                status: false,
            };
        case downloadContentPhotosConstants.DOWNLOAD_CONTENT_PHOTOS_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                contentPhotosData: action.payload,
            };
        case downloadContentPhotosConstants.DOWNLOAD_CONTENT_PHOTOS_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
