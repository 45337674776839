import React        from 'react';
import PropTypes    from 'prop-types';

import CheckboxAtm  from "../../atomic/Checkbox/Checkbox";
import ColAtm       from "../../atomic/Col/Col";
import IconAtm      from "../../atomic/Icon/Icon";
import BlockAtm from "../../atomic/Block/Block";
import OverFlowText from "../OverFlowText/OverFlowText";
import {isDefined} from "../../../utils";


const FactCheckBox = props => (
    <ColAtm className={props.colClassname} md={props.md} xs={6} sm={props.sm} lg={props.lg}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} >
            <CheckboxAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                checkboxId={props.checkboxId}
                value={props.value}
                tooltip={props.tooltip}
                labelPlacement={props.labelPlacement}
                checked={props.checked}
                disabled={props.disabled}
                defaultChecked={props.defaultChecked}
            />
            <label htmlFor={props.htmlFor}>
                <label htmlFor={props.htmlFor}>
                    {
                        isDefined(props.iconClass) &&
                        <IconAtm iconClass={props.iconClass} />
                    }
                    {
                        isDefined(props.src) &&
                        <img className={props.imgClass} src={props.src} alt={props.name} />
                    }
                    <BlockAtm className={props.labelIconClass}>
                        <OverFlowText className={props.labelClass} htmlFor={props.htmlFor}> {props.text} </OverFlowText>
                    </BlockAtm>
                </label>
            </label>
        </BlockAtm>
    </ColAtm>
);

FactCheckBox.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    tooltip: PropTypes.any,
    labelIconClass: PropTypes.string,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    sm: PropTypes.any,
    md: PropTypes.any,
    lg: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
    isFeature: PropTypes.any,
    disabled: PropTypes.any,
    handlerIsfeature: PropTypes.any,
    src: PropTypes.any,
    imgClass: PropTypes.any,
};

export default FactCheckBox;
