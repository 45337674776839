import React from "react";
import {connect} from "react-redux";
import { Redirect } from 'react-router-dom';
import Facts from "./components/Addons";
import {GetAddons} from "../../../../redux/actions/Network/Addons/addonsActions";
import {getRoomDescription} from "../../../../redux/actions/propertyRoomAndRateActions";

class Addons extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            property_brand : null,
            sub_category_id : '',
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getRoomDescription();
        if ( this.props.match.params.id ) {
            this.props.GetAddons( this.props.match.params.id );

            this.setState({
                sub_category_id: this.props.match.params.id
            })
        }
        else {
            return <Redirect to="/404" />
        }

    }

    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id) {
            this.props.GetAddons(nextProps.match.params.id);
        }
    }

    render() {
        return (
            <Facts
                AddonsListData = {this.props.addonsReducers.getAddonsList}
                fetching = {this.props.addonsReducers.fetching}
                descriptionData = {this.props.descriptionData}
                pageId={this.props.match.params.id}
            />
        )
    }
}


const mapStateToProps = (props) => {
    return {
        addonsReducers : props.addonsReducers,
        descriptionData : props.propertyRoomAndRateReducers.descriptionData.data
    }
};

const mapDispatchToProps ={
    GetAddons,
    getRoomDescription,
};


export default connect(mapStateToProps, mapDispatchToProps )(Addons);
