import React, {useEffect} from "react";
import FactList from "./FactList";
import {useDispatch} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {RoomFactListGet,roomFactUpdateClear} from "../../../../../../redux/actions/Content/Room/roomFactAction"

const Room = (props) => {

    const { rooms } = props;
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();

    const handleChange = (roomId) => (event, isExpanded) => {
        setExpanded(isExpanded ? roomId : false);

        if(isExpanded !== false){
            dispatch( roomFactUpdateClear() );
            dispatch( RoomFactListGet(roomId) );
        }
    };

    useEffect( () => {},[])

    return(
        <>
            {
                props.selectedRoomTypeId  === 0 ?
                rooms.map( (room) => (
                    <ExpansionPanel className="accordion-room-table" key={room.id} expanded={expanded === room.id} onChange={handleChange(room.id)}>
                        <ExpansionPanelSummary
                            expandIcon={<i className="fas fa-caret-down" />}
                            aria-label="Expand">
                            <ColAtm>
                                <ColAtm md={10} xl={11}>
                                    <i className="fas fa-bed room-rate-bed" />
                                    <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                </ColAtm>
                            </ColAtm>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                            <FactList roomId={room.id} selectedRoom={expanded} />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )) :
                    props.rooms.filter( (room) => room.room_type_id === props.selectedRoomTypeId )
                        .map( (room) =>(
                            <ExpansionPanel className="accordion-room-table" key={room.id} expanded={expanded === room.id} onChange={handleChange(room.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-caret-down" />}
                                    aria-label="Expand">
                                    <ColAtm>
                                        <ColAtm md={10} xl={11}>
                                            <i className="fas fa-bed room-rate-bed" />
                                            <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                        </ColAtm>
                                    </ColAtm>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                    <FactList roomId={room.id} selectedRoom={expanded} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))
            }
        </>

    )

}
export default Room;
