import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../utils";
import {
    CREATE_MANUAL_PAYMENT,
    UPDATE_MANUAL_PAYMENT,
    GET_CREATE_MANUAL_PAYMENT_DATA,
    GET_UPDATE_MANUAL_PAYMENT_DATA
} from "../../../constants/api.constants";


export function getCreateManualPaymentData() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CREATE_MANUAL_PAYMENT_DATA',
            payload: axios.post(GET_CREATE_MANUAL_PAYMENT_DATA, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createManualPayment(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                title: params.title,
                description: params.description,
                email: params.email,
                currency : params.currency,
                base_amount: params.base_amount,
                commission: params.commission,
                payment_method : params.payment_method,
                payment_type_mapping_id : params.payment_type_mapping_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_MANUAL_PAYMENT',
            payload: axios.post(CREATE_MANUAL_PAYMENT, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}


export function getUpdateManualPaymentData(paymentId) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                payment_transaction_id: paymentId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_UPDATE_MANUAL_PAYMENT_DATA',
            payload: axios.post(GET_UPDATE_MANUAL_PAYMENT_DATA, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateManualPayment(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                order_id : params.order_id,
                title: params.title,
                description: params.description,
                email: params.email,
                currency : params.currency,
                base_amount: params.base_amount,
                commission: params.commission,
                payment_method : params.payment_method,
                payment_type_mapping_id : params.payment_type_mapping_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_MANUAL_PAYMENT',
            payload: axios.post(UPDATE_MANUAL_PAYMENT, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
