import React, {Component} from 'react';

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {listChannelsInModal} from "../../../../../../redux/actions/channelListActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
import ChannelListBox from "../../../../../molecules/ChannelListBox/ChannelListBox";
import {Grid} from "@material-ui/core";
import {history} from "../../../../../../utils/history";
import LabelAtm from "../../../../../atomic/Label/Label";

let t =null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ChannelListModal extends Component {

    constructor(props) {
        super(props);
        t = props.t;

        this.state = {
            open: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
    }

    handleClickOpen = () => {
        this.setState({open:true});

        this.props.listChannelsInModal(this.props.channel.id);
    };

    handleClose = () => {
        this.setState({open:false});
    };

    handleRedirectChannel (e, id) {

        setTimeout(() => {
            this.setState({
                isShowInformation: true,
                isShowList: false
            })
        }, 300)

        this.setState({
            fadeIn: true,
            fadeOut: true,
        })

        history.push(`/app/network/channel-information/${id}`)
    };

    render() {
        const {open} = this.state;
        const {channelList, fetching} = this.props;

        return (
            <div className="w-100">
                <Button
                    className="edit-channel-price-btn"
                    endIcon={<i className="fas fa-layer-group" />}
                    onClick={this.handleClickOpen}
                    title={t('btn-channel_list')}
                />
                <Dialog
                    className="group-channel-modal"
                    open={open}
                    onClose={this.handleClose}
                    disableBackdropClick={true}
                    key={3}
                    fullScreen
                    TransitionComponent={Transition}
                >
                    <DialogTitle className="red-bottom-border" onClose={this.handleClose}>
                        { !fetching && this.props.channel.name} {" " + t('enw-channels_sub-channel-title') }
                    </DialogTitle>
                    <DialogContent  className="bg-gray">
                        <Grid container spacing={3}>
                            <Grid xs={12} className="channel-new-group-title pt-20">
                                <LabelAtm variant="h2" text= {t('btn-channel_list')} />
                                <LabelAtm variant="p" labelClass="f-w-500"
                                          text={ !fetching && this.props.channel.name + " " + t('enw-channels_sub-desc') }
                                />
                            </Grid>
                            {
                                !fetching &&
                                <>
                                    {Object.values(channelList).map((arr)=>{

                                        return <ChannelListBox
                                            checkboxId={arr.id}
                                            className="fact-box"
                                            colClassname="offer-fact-col mb-12 tablet-pb-20"
                                            blockClass="offer-fact-border-box fact-border-box"
                                            channelLabelClass={arr.is_selected ? "assign-channel-label" : "de-select-channel-name"}
                                            channelBlockClass={arr.is_selected ? "selected-channel-block" : "de-select-channel"}
                                            src={{backgroundImage: `url(/assets/img/brands/${arr.id}.png)`}}
                                            activeIcon={arr.is_selected ? "channel-active-icon fad fa-dot-circle green" : arr.is_pending && "channel-active-icon fad fa-dot-circle blue"}
                                            value={arr.id}
                                            defaultChecked={arr.mapped}
                                            factName={arr.name}
                                            onClick={(e) => this.handleRedirectChannel(e, arr.id)}
                                            text={arr.name}
                                            labelClass="fact-name"
                                            channelImage={arr.is_selected ? "channel-logo-bg": arr.is_pending ? "channel-logo-bg channel-grayscale-pending-box" : "channel-logo-bg channel-grayscale-box"}
                                            htmlFor={arr.id}
                                        />
                                    })}
                                </>
                            }
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        channelList : props.channelListReducers.channel_list_modal,
        fetching : props.channelListReducers.fetching_modal,
    }
};

const mapDispatchToProps ={
    listChannelsInModal
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelListModal));
