import React, { Component } from 'react'
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from 'react-i18next';
import ColAtm from "../../../../atomic/Col/Col";
import {compose} from "redux";
import 'react-phone-input-2/lib/material.css'
import { Grid, NoSsr } from "@material-ui/core";
import {history} from "../../../../../utils/history";

import ChannelListBox from "../../../../molecules/ChannelListBox/ChannelListBox";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {NETWORK, NETWORK_ADDONS_CHANNEL_LIST} from "../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";

let t =null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


class PromotionChannelList extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_list: this.props.channel_list ? this.props.channel_list : '',
            isVisible: true,
            isShowInformation: false,
            isShowList: true,
            fadeOut: false,
            fadeIn: true,
        };
        t = props.t;
    }

    handleClose = () => {
        this.setState({open:false})
    };
    ChannelList = () => {
        return (
            <NoSsr>
                {Object.values(this.props.channel_list).map((channel) => (
                    channel.is_selected && (channel.parent_id == null || channel.channel_category_id === 3)
                    && (channel.channel_category_id !== 4) &&
                        <ChannelListBox
                            checkboxId={channel.id}
                            className="fact-box"
                            colClassname="offer-fact-col mb-12 tablet-pb-20"
                            blockClass="offer-fact-border-box fact-border-box"
                            channelLabelClass={"assign-channel-label"}
                            channelBlockClass={"selected-channel-block"}
                            src={{backgroundImage: `url(/assets/img/brands/${channel.id}.png)`}}
                            activeIcon="channel-active-icon fad fa-dot-circle green"
                            value={channel.id}
                            defaultChecked={channel.mapped}
                            factName={channel.name}
                            onClick={(e) => this.handleClickOpen(e, channel.id)}
                            text={channel.name}
                            labelClass="fact-name"
                            channelImage={"channel-logo-bg"}
                            htmlFor={channel.id}
                        />
                    ))}
            </NoSsr>
        );
    };

    handleClickOpen (e, id) {
        history.push(NETWORK_ADDONS_CHANNEL_LIST + `/${id}`)
    };

    render() {
        return (
            <>
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={NETWORK} text={ t('enw-channels-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <ColAtm>
                                <ColAtm>
                                    <Grid container>
                                        <Grid container spacing={3}>
                                            {this.ChannelList()}
                                        </Grid>
                                    </Grid>
                                </ColAtm>
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (PromotionChannelList);
