/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import ColAtm from "../../../../../atomic/Col/Col";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {
    CONTENT_BRAND,
    CONTENT_COMMUNICATION,
    CONTENT_EMPLOYEES, CONTENT_INFORMATION,
    CONTENT_LOCATION, CONTENT_PHOTOS,
    CONTENT_AWARDS_AND_CERTIFICATES
} from "../../../../../../constants/appUrls.constants";

const useStyles = makeStyles(theme => ({
    root: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#e46a6b',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#e46a6b'
        },
    }
}));

export default function Menu(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Hidden xsDown>
            <ColAtm md={12} className="content-menu">
                <ColAtm className={props.infoColClass}>
                    <Link to={CONTENT_INFORMATION} className={classes.aLink} disabled={props.infoDisabled}>
                        { t('enw-general-tab-information') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.facilityColClass}>
                    <Link to={CONTENT_COMMUNICATION} className={classes.aLink} disabled={props.facilityDisabled}>
                        { t('enw-general-tab-communication') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.contactColClass}>
                    <Link to={CONTENT_EMPLOYEES} className={classes.aLink} disabled={props.contactDisabled}>
                        { t('enw-general-tab-employees') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.locationColClass}>
                    <Link to={CONTENT_LOCATION} className={classes.aLink} disabled={props.locationDisabled}>
                        { t('enw-general-tab-location') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.brandColClass}>
                    <Link to={CONTENT_BRAND} className={classes.aLink} disabled={props.brandDisabled}>
                        { t('enw-general-tab-brand') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.awardsCertificates}>
                    <Link to={CONTENT_AWARDS_AND_CERTIFICATES} className={classes.aLink} disabled={props.awardsCertificatesDisabled}>
                        { t('enw-general-tab-award-and-certificates') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.photoColClass}>
                    <Link to={CONTENT_PHOTOS} className={classes.aLink} disabled={props.photoDisabled}>
                        { t('enw-photos-title') }
                    </Link>
                </ColAtm>
            </ColAtm>
        </Hidden>
    );
}
Menu.propTypes = {
    text: PropTypes.string,
    infoColClass: PropTypes.string,
    contactColClass: PropTypes.string,
    facilityColClass: PropTypes.string,
    locationColClass: PropTypes.string,
    awardsCertificates: PropTypes.string,
    brandColClass: PropTypes.string,
    photoColClass: PropTypes.string,
    infoDisabled: PropTypes.bool,
    contactDisabled: PropTypes.bool,
    facilityDisabled: PropTypes.bool,
    locationDisabled: PropTypes.bool,
    awardsCertificatesDisabled: PropTypes.bool,
    brandDisabled: PropTypes.bool,
    photoDisabled: PropTypes.bool,
};
