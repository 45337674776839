import React from "react";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import FactCheckBox from "../../../../../molecules/FactContent/FactContent";
import {compose} from "redux";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import ChannelPaymentCancellationModal from "./ChannelPaymentCancellationModal";
import BlockAtm from "../../../../../atomic/Block/Block";
import IconAtm from "../../../../../atomic/Icon/Icon";
import Typography from "@material-ui/core/Typography";


let t =null;
class ChannelPaymentForm extends React.Component {
    constructor(props){
        super(props);
        t = props.t;
    }
    state = {
        payment: [{}],
        tax: [{}],
        is_selected: "",
        channel_id: this.props.channelPayment ? this.props.channelPayment.channel_id : '',
        currency_code: this.props.channelPayment ? this.props.channelPayment.currency_code : ''
    };

    componentDidMount() {
        let payment = [];
        let tax = [];

        if(this.props.channelPayment.payments.length !== 0 ) {

            Object.keys(this.props.channelPayment).map( key => {
                switch (key) {
                    case 'payments':
                        this.props.channelPayment.payments.length !== 0 ?
                            payment = this.props.channelPayment.payments.map(pay => {
                                return{
                                    language_key: pay.language_key,
                                    payment_type_id: pay.payment_type_id,
                                    action_type: pay.action_type,
                                    value_type: pay.value_type,
                                    value: pay.value,
                                    is_selected: pay.is_selected,
                                    cancellation_policy: pay.cancellation_policy,
                                    is_get_payment_data: pay.is_get_payment_data,
                                    code: pay.code,
                                    is_affected_price: pay.is_affected_price !== null &&  pay.is_affected_price === 1 ? 1 : 0
                                }
                            })
                            :
                            payment = [{
                                language_key: '',
                                payment_type_id: '',
                                action_type: '',
                                value_type: '',
                                value: '',
                                is_selected: '',
                                cancellation_policy: '',
                                is_get_payment_data: '',
                                code: '',
                                is_affected_price: 0
                            }]
                        break;
                }
            })

        }

        this.setState({
            payment: payment.length > 0 ? payment : {
                language_key: "",
                payment_type_id: "",
                action_type: "",
                value_type: "",
                is_get_payment_data: '',
                code: '',
                value: "",
                is_selected: false,
                cancellation_policy: null,
                is_affected_price: 0
            },
        })


        if(this.props.channelPayment.channelTax.length !== 0 ) {
            const tempTax = this.props.channelPayment.channelTax
            const selectedTax = tempTax ? tempTax.filter(tax => tax.is_selected) : null;
            localStorage.setItem("lastTaxId", selectedTax.length > 0 ? selectedTax[0].id : null)

            Object.keys(this.props.channelPayment).map( key => {

                switch (key) {
                    case 'channelTax':
                        this.props.channelPayment.channelTax.length !== 0 ?
                            tax = this.props.channelPayment.channelTax.map((pay, index) => {
                                return{
                                    language_key: pay.language_key,
                                    payment_type_id: pay.id,
                                    is_selected: pay.is_selected,
                                    code: pay.code,
                                }
                            })
                            :
                            tax = [{
                                language_key: '',
                                payment_type_id: '',
                                is_selected: '',
                                code: '',
                            }]
                        break;
                }
            })

        }

        this.setState({
            tax: tax.length > 0 ? tax : {
                language_key: "",
                payment_type_id: "",
                code: '',
                is_selected: false,
            },
        })
    }

    handlePaymentChange = (e, index) => {
        const {name, value } = e.target;
        const list = [...this.state.payment];
        list[index][name] = value;
        this.setState({
            payment : list,
        })
    };

    onPaymentSelected = (e, i, taxId) => {
        let tempPayment = this.state.payment

        if (taxId) {
            if (taxId == localStorage.getItem("lastTaxId")) {
                e.target.checked = false;
                localStorage.setItem("lastTaxId", null);
            } else if (taxId) {
                localStorage.setItem("lastTaxId", taxId);
            } else {
                e.target.checked = true;
            }
        }

        let param = {
            payment: tempPayment,
            channel_id: this.state.channel_id,
            channel_tax: parseInt(localStorage.getItem("lastTaxId"))
        }


        if(e.target.checked === true) {
            let temp_payment_mapping = this.state.payment;
            if (!taxId) {
                temp_payment_mapping[i]['is_selected'] = true;
                temp_payment_mapping[i]['value_type'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['value_type'] : '';
                temp_payment_mapping[i]['value'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['value'] : '';
                temp_payment_mapping[i]['action_type'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['action_type'] : '';
            }
            this.setState({ ...this.state, payment: temp_payment_mapping } );
            this.props.addChannelPaymentPolicy(param);

        } else if (e.target.checked === false){
            let temp_payment_mapping = this.state.payment;
            if (!taxId) {
                temp_payment_mapping[i]['is_selected'] = false;
                temp_payment_mapping[i]['value_type'] =  '';
                temp_payment_mapping[i]['value'] =  '';
                temp_payment_mapping[i]['action_type'] =  '';
                temp_payment_mapping[i]['is_affected_price'] = 0;
                temp_payment_mapping[i]['is_get_payment_data'] = 0;
                temp_payment_mapping[i]['cancellation_policy'] = [];
            }
            this.setState({ ...this.state, payment: temp_payment_mapping } );
            this.props.addChannelPaymentPolicy(param);
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let tempPayment = this.state.payment

        const param = {
            payment: tempPayment,
            channel_id: this.state.channel_id,
            channel_tax: parseInt(localStorage.getItem("lastTaxId"))
        }

        if(tempPayment[0]['is_selected'] || tempPayment[1]['is_selected']){
            this.props.addChannelPaymentPolicy(param);
        }

    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

    };

    handleChangePaymentValue = (e, i) => {

        if(e.target.value === "true") {
            let temp_payment_mapping = this.state.payment;
            temp_payment_mapping[i]['is_affected_price'] = 1;
            temp_payment_mapping[i]['value_type'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['value_type'] : '';
            temp_payment_mapping[i]['value'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['value'] : '';
            temp_payment_mapping[i]['action_type'] = Object.keys(this.props.channelPayment.payments) !== 0 && this.props.channelPayment.payments[i] ? this.props.channelPayment.payments[i]['action_type'] : '';
            this.setState({ ...this.state, payment: temp_payment_mapping } );
        } else if (e.target.value === "false"){
            let temp_payment_mapping = this.state.payment;
            temp_payment_mapping[i]['is_affected_price'] = 0;
            temp_payment_mapping[i]['value_type'] =  '';
            temp_payment_mapping[i]['value'] =  '';
            temp_payment_mapping[i]['action_type'] =  '';
            this.setState({ ...this.state, payment: temp_payment_mapping } );
        }
    }

    handleChangePaymentCreditCard = (e, i) => {
        if(e.target.value === "true") {
            let temp_payment_mapping = this.state.payment;
            temp_payment_mapping[i]['is_get_payment_data'] = 1;
            this.setState({ ...this.state, payment: temp_payment_mapping } );
        } else if (e.target.value === "false"){
            let temp_payment_mapping = this.state.payment;
            temp_payment_mapping[i]['is_get_payment_data'] = 0;
            this.setState({ ...this.state, payment: temp_payment_mapping } );
        }
    }

    showMessages = () => {
        if (this.props.channelPayment && Object.keys(this.props.channelPayment.submitError) !== 0 && this.props.channelPayment.submitError.response !== undefined )  {
            let message = this.props.channelPayment.submitError.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    handleClickCancellationPolicy = (e, i ,id) => {
        let temp_payment_mapping = this.state.payment;

        if(!temp_payment_mapping[i]['cancellation_policy'].includes(id)){
            temp_payment_mapping[i]['cancellation_policy'].push(id);
            this.setState({ ...this.state, payment: temp_payment_mapping });
        }else {
            temp_payment_mapping[i]['cancellation_policy'].splice(temp_payment_mapping[i]['cancellation_policy'].indexOf(id), 1);
            this.setState({ ...this.state, payment: temp_payment_mapping });
        }
    };

    render() {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <div className="m-5">
                        <Grid container sm={12}>
                            <Card className="w-100 channel-border">
                                <CardContent>
                                    <ColAtm>
                                        <LabelAtm variant="h6" component="h2" text={ t('property_content_category-payment') } />
                                    </ColAtm>
                                    <Grid container sm={12} spacing={6}>
                                        <Grid item sm={12}>
                                            <div className="channel-form-box">
                                                <Grid container spacing={3}>
                                                    {this.state.payment.map((pay, i) => {
                                                    return (
                                                        <Grid item className="pos-relative">
                                                                <FactCheckBox
                                                                    checkboxId={pay.payment_type_id+"payment"}
                                                                    className="fact-box"
                                                                    md={12}
                                                                    blockClass="offer-fact-border-box fact-border-box gray-border"
                                                                    labelIconClass="fact-icon-label"
                                                                    iconClass={`offer-fact-icon fact-icon fas fa-credit-card-front`}
                                                                    key={pay.payment_type_id}
                                                                    value={pay.payment_type_id+"payment"}
                                                                    text={ t(pay.language_key) }
                                                                    tooltip={ t(pay.language_key) }
                                                                    labelClass="fact-name"
                                                                    htmlFor={pay.payment_type_id+"payment"}
                                                                    defaultChecked={ pay.is_selected }
                                                                    onChange={(e) => { this.onPaymentSelected(e, i, false)}}
                                                                />
                                                                {pay.is_selected &&
                                                                <ChannelPaymentCancellationModal
                                                                    closeModal={this.handleClose}
                                                                    selectedCancellation={pay.cancellation_policy.length !== 0 || pay.is_affected_price || pay.is_get_payment_data}
                                                                    index={i}
                                                                    handleClickCancellationPolicy={this.handleClickCancellationPolicy}
                                                                    cancellation_list={pay.cancellation_policy}
                                                                    pay={pay}
                                                                    handleChangePaymentValue={this.handleChangePaymentValue}
                                                                    handlePaymentChange={this.handlePaymentChange}
                                                                    handleChangePaymentCreditCard={this.handleChangePaymentCreditCard}
                                                                    onSubmit={this.onSubmit}
                                                                    currency_code={this.state.currency_code}
                                                                    status={this.props.channelPayment && this.props.channelPayment.status}
                                                                />
                                                                }
                                                            </Grid>
                                                    );
                                                })}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                            <Card className="w-100 channel-border">
                                <CardContent>
                                    <ColAtm>
                                        <LabelAtm variant="h6" component="h2" text={ t('property_content_category-tax') } />
                                    </ColAtm>
                                    <Grid container sm={12} spacing={6}>
                                        <Grid item sm={12}>
                                            <div className="channel-form-box tax-box">
                                                <Grid container spacing={3}>
                                                    {this.state.tax.map((pay, i) => {
                                                        return (
                                                            <Grid item className="pos-relative">
                                                                <Grid item xs={6} sm={3} md={4}>
                                                                    <ColAtm>
                                                                        <BlockAtm
                                                                            className={"offer-fact-border-box fact-border-box gray-border"}>
                                                                            <input type={"radio"} name="room_price_type"
                                                                                   className="channel-radio"
                                                                                   id={pay.payment_type_id + "tax"}
                                                                                   defaultChecked={pay.is_selected}
                                                                                   onClick={(e) => {
                                                                                       this.onPaymentSelected(e, i, pay.payment_type_id)
                                                                                   }}
                                                                            />
                                                                            <label htmlFor={pay.payment_type_id + "tax"}>
                                                                                <label
                                                                                    htmlFor={pay.payment_type_id + "tax"}>
                                                                                    <IconAtm
                                                                                        iconClass={`offer-fact-icon fact-icon fas fa-money-check`}/>
                                                                                    <BlockAtm className={"fact-icon-label"}>
                                                                                        <Typography noWrap
                                                                                                    className={"fact-name"}
                                                                                                    htmlFor={pay.payment_type_id + "tax"}>
                                                                                            <i className="fas fa-check-circle blue"/> {t(pay.language_key)}
                                                                                        </Typography>
                                                                                    </BlockAtm>
                                                                                </label>
                                                                            </label>
                                                                        </BlockAtm>
                                                                    </ColAtm>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                        { this.showMessages() }
                </div>
            </div>
        );
    }
}

ChannelPaymentForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(withTranslation('common')) (ChannelPaymentForm);
