import React from "react";
import ReactExport from "react-export-excel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExportTransactionListToExcel = (props) => {

    const { t } = useTranslation();

    const DownloadButton = () => {
        return (
            <Tooltip title={ t('datatable-toolbar-download_excel') }>
                <IconButton>
                    <i className="fas fa-file-excel"/>
                </IconButton>
            </Tooltip>
        )
    }


    return (
        <ExcelFile element={DownloadButton()}>
            <ExcelSheet data={props.data} name="Employees">
                <ExcelColumn label={ t('enw-input-day_of_transaction') } value="transactionDate"/>
                <ExcelColumn label={ "Transaction Type" } value="transactionType"/>
                <ExcelColumn label={ t('enw-reservation-detail-order_id')} value="orderId"/>
                <ExcelColumn label={ t('enw-reservation-detail-transaction_code') } value="transactionCode"/>
                <ExcelColumn label={ t('enw-reservation-detail-bank_name') } value="bankName"/>
                <ExcelColumn label={ t('enw-payment-installment') } value="installment"/>
                <ExcelColumn label={ t('be-search-card_number-title') } value="creditCardNumber"/>
                <ExcelColumn label={ t('enw-input-fine') } value="amount"/>
                <ExcelColumn label={ t('enw-offer_list-column-status') } value="status"/>
            </ExcelSheet>
        </ExcelFile>
    );

}
export default ExportTransactionListToExcel
