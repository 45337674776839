import {
    getChannelContact,
    createChannelContact,
    updateChannelContact,
    deleteChannelContact,
    clearChannelContactError
} from "../../../../constants/Network/Channels/channelContact.constants";

const initialState = {
    channelContactListFetching : false,
    channelContactListError : {},
    channelContactList: [],

    channelContactCreateFetching : false,
    channelContactCreateError : {},
    channelContactCreate: [],

    channelContactUpdateFetching : false,
    channelContactUpdateError : {},
    channelContactUpdate: [],

    channelContactDeleteFetching : false,
    channelContactDeleteError : {},
    channelContactDelete: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        //GET LIST
        case getChannelContact.NETWORK_CHANNEL_CONTACT_GET_PENDING:
            return {
                ...state,
                channelContactListFetching: true,
                channelContactListError: {},
                channelContactDelete: {},
                channelContactDeleteError: {},
            };
        case getChannelContact.NETWORK_CHANNEL_CONTACT_GET_FULFILLED:
            return {
                ...state,
                channelContactListFetching: false,
                channelContactList: action.payload,
            };
        case getChannelContact.NETWORK_CHANNEL_CONTACT_GET_REJECTED:
            return {
                ...state,
                channelContactListError: action.payload,
                channelContactListFetching: false
            };

            //Create
        case createChannelContact.NETWORK_CHANNEL_CONTACT_CREATE_PENDING:
            return {
                ...state,
                channelContactCreateFetching: true,
                channelContactCreateError: {}
            };
        case createChannelContact.NETWORK_CHANNEL_CONTACT_CREATE_FULFILLED:
            return {
                ...state,
                channelContactCreateFetching: false,
                channelContactCreate: action.payload,
            };
        case createChannelContact.NETWORK_CHANNEL_CONTACT_CREATE_REJECTED:
            return {
                ...state,
                channelContactCreateError: action.payload,
                channelContactCreateFetching: false
            };

            //UPDATE
        case updateChannelContact.NETWORK_CHANNEL_CONTACT_UPDATE_PENDING:
            return {
                ...state,
                channelContactUpdateFetching: true,
                channelContactUpdateError: {}
            };
        case updateChannelContact.NETWORK_CHANNEL_CONTACT_UPDATE_FULFILLED:
            return {
                ...state,
                channelContactUpdateFetching: false,
                channelContactUpdate: action.payload,
            };
        case updateChannelContact.NETWORK_CHANNEL_CONTACT_UPDATE_REJECTED:
            return {
                ...state,
                channelContactUpdateError: action.payload,
                channelContactUpdateFetching: false
            };

            //Delete
        case deleteChannelContact.NETWORK_CHANNEL_CONTACT_DELETE_PENDING:
            return {
                ...state,
                channelContactDeleteFetching: true,
                channelContactDeleteError: {}
            };
        case deleteChannelContact.NETWORK_CHANNEL_CONTACT_DELETE_FULFILLED:
            return {
                ...state,
                channelContactDeleteFetching: false,
                channelContactDelete: action.payload,
            };
        case deleteChannelContact.NETWORK_CHANNEL_CONTACT_DELETE_REJECTED:
            return {
                ...state,
                channelContactDeleteError: action.payload,
                channelContactDeleteFetching: false
            };
        case clearChannelContactError.NETWORK_CHANNEL_CONTACT_CLEAR_ERROR_STATE:
            return {
                ...state,
                channelContactCreate: [],
                channelContactUpdate: [],
                channelContactDelete: [],
                channelContactDeletePhotoError: {},
                channelContactDeleteError: {},
                channelContactCreateError: {},
                channelContactUpdateError: {},
            };
        default:
            return state
    }
}
