import React from 'react';
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";

let t=null;


class FactFooter extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            showSuccessHeader: false,
        }
        t = props.t;
    }


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.props.nextMenus} />
        }
    }

    render() {
        return (
            <>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backTo={this.props.getMenus}
                    nextTo={this.props.nextMenus}
                    component={Link}
                    nextComponent={Link}
                    buttonNextText={ t('btn-next') }
                    iconLeftClassName="fas fa-arrow-left"
                    backDisabled={this.state.showSuccessHeader}
                    nextDisabled={this.state.showSuccessHeader}
                />
            </>
        )
    }
}

export default compose(withTranslation('common')) (FactFooter);
