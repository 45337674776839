export const groupChannelGetConstants = {
    NETWORK_CHANNEL_GROUP_GET_PENDING  : 'NETWORK_CHANNEL_GROUP_GET_PENDING',
    NETWORK_CHANNEL_GROUP_GET_FULFILLED: 'NETWORK_CHANNEL_GROUP_GET_FULFILLED',
    NETWORK_CHANNEL_GROUP_GET_REJECTED : 'NETWORK_CHANNEL_GROUP_GET_REJECTED',
};

export const singleGroupChannelGetConstants = {
    NETWORK_SINGLE_CHANNEL_GROUP_GET_PENDING  : 'NETWORK_SINGLE_CHANNEL_GROUP_GET_PENDING',
    NETWORK_SINGLE_CHANNEL_GROUP_GET_FULFILLED: 'NETWORK_SINGLE_CHANNEL_GROUP_GET_FULFILLED',
    NETWORK_SINGLE_CHANNEL_GROUP_GET_REJECTED : 'NETWORK_SINGLE_CHANNEL_GROUP_GET_REJECTED',
};

export const groupChannelAddConstants = {
    NETWORK_CHANNEL_GROUP_ADD_PENDING  : 'NETWORK_CHANNEL_GROUP_ADD_PENDING',
    NETWORK_CHANNEL_GROUP_ADD_FULFILLED: 'NETWORK_CHANNEL_GROUP_ADD_FULFILLED',
    NETWORK_CHANNEL_GROUP_ADD_REJECTED : 'NETWORK_CHANNEL_GROUP_ADD_REJECTED',
    NETWORK_CHANNEL_GROUP_ADD_CLEAR_STATE : 'NETWORK_CHANNEL_GROUP_ADD_CLEAR_STATE',
};

export const groupChannelUpdateConstants = {
    NETWORK_CHANNEL_GROUP_UPDATE_PENDING  : 'NETWORK_CHANNEL_GROUP_UPDATE_PENDING',
    NETWORK_CHANNEL_GROUP_UPDATE_FULFILLED: 'NETWORK_CHANNEL_GROUP_UPDATE_FULFILLED',
    NETWORK_CHANNEL_GROUP_UPDATE_REJECTED : 'NETWORK_CHANNEL_GROUP_UPDATE_REJECTED',
    NETWORK_CHANNEL_GROUP_UPDATE_CLEAR_STATE : 'NETWORK_CHANNEL_GROUP_UPDATE_CLEAR_STATE',
};

export const groupChannelCreateDataConstants = {
    NETWORK_CHANNEL_GROUP_CREATE_DATA_PENDING  : 'NETWORK_CHANNEL_GROUP_CREATE_DATA_PENDING',
    NETWORK_CHANNEL_GROUP_CREATE_DATA_FULFILLED: 'NETWORK_CHANNEL_GROUP_CREATE_DATA_FULFILLED',
    NETWORK_CHANNEL_GROUP_CREATE_DATA_REJECTED : 'NETWORK_CHANNEL_GROUP_CREATE_DATA_REJECTED'
};
