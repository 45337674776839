import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {
    NETWORK_CHANNEL_CONTACT_GET,
    NETWORK_CHANNEL_CONTACT_CREATE,
    NETWORK_CHANNEL_CONTACT_UPDATE,
    NETWORK_CHANNEL_CONTACT_DELETE
} from "../../../../constants/api.constants";
import axios from 'axios';

export function getChannelContact(channel_id) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                channel_id: channel_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_CONTACT_GET',
            payload: axios.post(NETWORK_CHANNEL_CONTACT_GET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}


export function addChannelContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                name : params.name,
                email : params.email
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_CONTACT_CREATE',
            payload: axios.post(NETWORK_CHANNEL_CONTACT_CREATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                id: params.id,
                property_channel_mapping_id: params.property_channel_mapping_id,
                name : params.name,
                email : params.email
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_CONTACT_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_CONTACT_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function deleteChannelContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                id: params.id,
                property_channel_mapping_id: params.property_channel_mapping_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_CONTACT_DELETE',
            payload: axios.post(NETWORK_CHANNEL_CONTACT_DELETE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createOrUpdateChannelContactErrorClear() {

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_CONTACT_CLEAR_ERROR_STATE',
        })
    }
}
