import axios                                   from 'axios';
import {getUserToken, getAppLang, getProperty} from "../../../../utils";
import {CREATE_TICKET, GET_TICKET, CREATE_PAYMENT_CODE, UPDATE_TICKET} from "../../../../constants/api.constants";


export function getTicket(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                user_id: params.user_id,
                booking_code: params.booking_code
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_TICKET',
            payload: axios.post(GET_TICKET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createTicket(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                user_id: params.user_id,
                booking_code: params.booking_code,
                message: params.message,
                is_note: params.is_note,
                ip_address: params.ip_address
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_TICKET',
            payload: axios.post(CREATE_TICKET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateTicket(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                booking_id: params.booking_id,
                total: params.total,
                currency_code: params.currency_code,
                status: params.status
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_TICKET',
            payload: axios.post(UPDATE_TICKET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createPaymentCode(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                booking_id: params.booking_id,
                confirmCode: params.confirmCode,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_PAYMENT_CODE',
            payload: axios.post(CREATE_PAYMENT_CODE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateTicketClearState() {
    return dispatch => {
        dispatch({
            type: 'UPDATE_TICKET_CLEAR',
        })
    }
}

export function createTicketClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_TICKET_CLEAR',
        })
    }
}

export function createPaymentCodeClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_PAYMENT_CODE_CLEAR',
        })
    }
}