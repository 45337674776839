import React from "react";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import ColAtm from "../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import RoomList from "./List/RoomList";
import RateList from "./List/RateList";
import RoomAndRateList from "./List/RoomAndRateList";
import {connect} from "react-redux";
import {
    PropertyRoomAndRateList,
    PropertyRoomTypes,
    PropertyRoomRates,
    propertyAssignRate,
    propertyFactSearchList, PropertyRoomAndRateListForRateCreate
} from "../../../../redux/actions/propertyRoomAndRateActions";
import {updateChannelRoomRateClear} from "../../../../redux/actions/Network/Channel/channelRoomRateActions"

import AllRoomTypes from "./Filters/AllRoomTypes";
import AllRatePlans from "./Filters/AllRatePlans";
import RoomAndRateFilter from "./Filters/RoomAndRateFilter";

import NewRatePlan from "./NewRatePlan/NewRatePlan";
import RoomRateButtonGroups from "../../../organisms/RoomRateButtons/RoomRateButtonGroups";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Loading from "../../../molecules/Loading/Loading";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {NETWORK} from "../../../../constants/appUrls.constants";
import {Grid} from "@material-ui/core";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import EditRatePlan from "./EditRatePlan/EditRatePlan";
import RateConnect from "./RateConnect/RateConnect";

class Index extends React.Component {

    state = {
        selectedRoomRateFilterId: 0,
        selectedRoomTypeId: 0,
        selectedRoomRateId: 0,
        roomAddModalStatus: false,
        urlParam: null
    };

    onChangeRoomAndRateFilter = (selectedIndex) => {
        this.setState({
            selectedRoomRateFilterId: selectedIndex,
        });
    };

    onChangeRoomFilter = (selectedIndex) => {
        this.setState({
            selectedRoomTypeId: selectedIndex,
        });
    };

    onChangeRoomRateFilter = (selectedIndex) => {
        this.setState({
            selectedRoomRateId: selectedIndex,
        });
    };

    selectRoomPageList = () => {
        switch (this.state.selectedRoomRateFilterId) {
            case 0:
                return (<RoomAndRateList rooms={this.props.propertyRoomRateList}
                                         selectedRoomTypeId={this.state.selectedRoomTypeId}
                                         selectedRoomRateId={this.state.selectedRoomRateId}
                                         updateStatus={this.props.updateStatus}
                                         allRoomTypes={this.props.allRoomTypes}
                                         allBedType={this.props.allBedType}
                                         roomViewTypes={this.props.roomViewTypes}
                                         roomSizeTypes={this.props.roomSizeTypes}
                />);
            case 1:
                return (<RoomList rooms={this.props.propertyRoomRateList}
                                  selectedRoomTypeId={this.state.selectedRoomTypeId}
                                  updateStatus={this.props.updateStatus}
                                  allRoomTypes={this.props.allRoomTypes}
                                  allBedType={this.props.allBedType}
                                  roomViewTypes={this.props.roomViewTypes}
                                  roomSizeTypes={this.props.roomSizeTypes}
                />);
            case 2:
                return (<RateList rates={this.props.roomRates}
                                  ratePlanUpdateStatus={this.props.ratePlanUpdateStatus}
                                  ratePlanUpdateError={this.props.ratePlanUpdateError}
                />);
            default:
                return (<RoomAndRateList rooms={this.props.propertyRoomRateList}
                                         selectedRoomTypeId={this.state.selectedRoomTypeId}
                                         updateStatus={this.props.updateStatus}
                                         allRoomTypes={this.props.allRoomTypes}
                                         allBedType={this.props.allBedType}
                                         roomViewTypes={this.props.roomViewTypes}
                                         roomSizeTypes={this.props.roomSizeTypes}
                />);
        }
    };

    onAddAssignRate = (param) => (e) => {
        this.props.propertyAssignRate(param);
    };

    componentDidMount() {
        this.props.PropertyRoomAndRateList();
        this.props.PropertyRoomAndRateListForRateCreate();
        this.props.PropertyRoomTypes();
        this.props.PropertyRoomRates();
        this.props.propertyFactSearchList();
        this.props.updateChannelRoomRateClear();

        if (this.props.match.params.id) {
            this.setState({
                urlParam: this.props.match.params.id
            });
        }
    }

    showMessages = () => {

        if (this.props.updateChannelRoomRateError?.response?.status === 400) {
            let message = this.props.updateChannelRoomRateError?.response.data.message
            return <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <PageTitle title={t('enw-room_and_rates-page_title')}/>
                <PageHeaderAtm backButton={NETWORK} exitText={t('btn-dashboard')} text={t('enw-room_and_rate-title')}/>
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <Grid container spacing={2} className="photoButtons room-rate-filter-group">
                                <Grid item className="type-plan-buttons">
                                    <RoomAndRateFilter onChangeRoomAndRateFilter={this.onChangeRoomAndRateFilter}
                                                       selectedRoomRateFilterId={this.state.selectedRoomRateFilterId}
                                    />
                                </Grid>
                                <Grid item className="type-plan-buttons">
                                    {this.props.propertyRoomTypesFetching ? (

                                        <Loading/>

                                    ) : (
                                        <AllRoomTypes propertyRoomTypes={this.props.propertyRoomTypes}
                                                      onChangeRoomFilter={this.onChangeRoomFilter}
                                        />
                                    )}

                                </Grid>
                                {
                                    <Grid item className="type-plan-buttons room-rate-filter-col">
                                        {this.props.roomRatesFetching ? (

                                            <Loading/>

                                        ) : (

                                            this.state.selectedRoomRateFilterId !== 1 ? (

                                                <AllRatePlans roomRates={this.props.roomRates}
                                                              onChangeRoomRateFilter={this.onChangeRoomRateFilter}/>

                                            ) : null
                                        )}

                                    </Grid>
                                }

                                <Grid item className="photoButtonsRow">
                                    <RoomRateButtonGroups
                                        rateMenuItem={
                                            <NewRatePlan
                                                urlParam={this.state.urlParam}
                                                factSearchList={this.props.factSearchList}
                                                rooms={this.props.propertyRoomRateListForRate}
                                            />
                                        }
                                        editRateMenuItem={
                                            <EditRatePlan
                                                urlParam={this.state.urlParam}
                                                factSearchList={this.props.factSearchList}
                                                roomRates={this.props.roomRates}
                                            />}
                                        rateConnect={
                                            <RateConnect
                                                factSearchList={this.props.factSearchList}
                                                roomRates={this.props.roomRates}
                                            />}
                                    />
                                </Grid>
                            </Grid>
                            <ColAtm className="room-rate-list-col">
                                {!this.props.propertyRoomRateListFetching &&
                                this.selectRoomPageList()
                                }
                            </ColAtm>
                        </div>
                    </Container>
                    {
                        this.showMessages()
                    }

                </Animated>
            </>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        propertyRoomRateListFetching: state.propertyRoomAndRateReducers.propertyRoomRateListFetching,
        propertyRoomRateList: state.propertyRoomAndRateReducers.propertyRoomRateList,

        propertyRoomRateListForRateFetching: state.propertyRoomAndRateReducers.propertyRoomRateListForRateFetching,
        propertyRoomRateListForRate: state.propertyRoomAndRateReducers.propertyRoomRateListForRate,

        propertyRoomTypesFetching: state.propertyRoomAndRateReducers.propertyRoomTypesFetching,
        propertyRoomTypes: state.propertyRoomAndRateReducers.propertyRoomTypes,

        roomRatesFetching: state.propertyRoomAndRateReducers.roomRatesFetching,
        roomRates: state.propertyRoomAndRateReducers.roomRates,

        roomAndBedAddStatus: state.propertyRoomAndRateReducers.roomAndBedAddStatus,

        updateStatus: state.propertyRoomAndRateReducers.updateStatus,

        ratePlanUpdateStatus: state.propertyRoomAndRateReducers.ratePlanUpdateStatus,
        ratePlanUpdateError: state.propertyRoomAndRateReducers.ratePlanUpdateError,

        allRoomTypes: state.roomTypesReducer.allRoomTypes,
        allBedType: state.bedTypesReducer.allBedType,
        roomViewTypes: state.RoomViewTypesReducer.roomViewTypes,
        roomSizeTypes: state.RoomSizeTypeReducer.roomSizeTypes,
        factSearchList: state.propertyRoomAndRateReducers.factSearchList,

        updateChannelRoomRateError: state.channelRoomRateUpdateReducer?.error

    }
};

const mapDispatchToProps = {
    PropertyRoomAndRateList,
    PropertyRoomAndRateListForRateCreate,
    PropertyRoomTypes,
    PropertyRoomRates,
    propertyAssignRate,
    propertyFactSearchList,
    updateChannelRoomRateClear
};

export default compose(withTranslation('common'))(connect(mapStateToProps, mapDispatchToProps)(Index));
