import { getUserToken,getProperty ,getAppLang}                 from "../../utils/index";
import { CHANNEL_LIST} from "../../constants/api.constants";
import axios from 'axios';

export function listChannels(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                parent_id : param
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_LIST',
            payload: axios.post(CHANNEL_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function listChannelsInModal(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                parent_id : param
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_LIST_MODAL',
            payload: axios.post(CHANNEL_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
