export const channelConstants = {
    CHANNEL_LIST_PENDING  : 'CHANNEL_LIST_PENDING',
    CHANNEL_LIST_FULFILLED: 'CHANNEL_LIST_FULFILLED',
    CHANNEL_LIST_REJECTED : 'CHANNEL_LIST_REJECTED',
};

export const channelModalConstants = {
    CHANNEL_LIST_MODAL_PENDING  : 'CHANNEL_LIST_MODAL_PENDING',
    CHANNEL_LIST_MODAL_FULFILLED: 'CHANNEL_LIST_MODAL_FULFILLED',
    CHANNEL_LIST_MODAL_REJECTED : 'CHANNEL_LIST_MODAL_REJECTED',
};
