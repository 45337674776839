import React,{Component} from "react";
import {connect} from "react-redux";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {
    getEditOffer,
    offerAcceptStatus,
    updateOffer,
    updateOfferStatus
} from "../../../../redux/actions/Offer/EditOffer/editOfferAction";
import SuccessModal from "../../../organisms/Modal/SuccessModal/SuccessModal"
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal"
import OfferForm from "./components/OfferForm"

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {OFFER_LIST} from "../../../../constants/appUrls.constants";
let t=null;

class CreateOffer extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    state = {
        offer_id : ''
    }

    componentDidMount() {
        if ( this.props.match.params.id ) {

             this.props.getEditOffer(this.props.match.params.id)

             this.setState({
                offer_id:this.props.match.params.id
             })
         }
        else{
            this.props.history.replace(OFFER_LIST);
        }

    }

    showMessage = () => {

        if( this.props.editOfferReducer.offerUpdateStatus === true ){
            return <SuccessModal status={true} message={ t("enw-modal-update_success_message") }/>
        }

        if( this.props.editOfferReducer.offerUpdateStatus === false &&
            this.props.editOfferReducer.error.response
        ){
            let message = this.props.editOfferReducer.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-edit_offer-page_title') } />
                <PageHeaderAtm backButton={OFFER_LIST} exitText={ t('btn-offer_list')} text={ t('enw-edit_offer-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">

                            {
                                this.props.editOfferReducer.fetching && this.props.editOfferReducer.offerData.length === 0 ?
                                    <></> :
                                    (
                                        <OfferForm
                                            offerId = {this.state.offer_id}
                                            offerReducer = {this.props.editOfferReducer.offerData}
                                            offerUpdateStatus = {this.props.editOfferReducer.offerUpdateStatus}
                                            updateOffer = {this.props.updateOffer}
                                            updateOfferStatus = {this.props.updateOfferStatus}
                                            offerAcceptStatus = {this.props.offerAcceptStatus}
                                        />
                                    )
                            }
                            {
                                this.showMessage()
                            }
                        </div>
                    </Container>
                </Animated>
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return{
        editOfferReducer : props.editOfferReducer,
        updateOfferReducer : props.updateOfferReducer //TODO : Yeni offer form'u eklenince silinecektir
    }
}

const mapDispatchToProps = {
    getEditOffer, updateOffer, updateOfferStatus, offerAcceptStatus
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(CreateOffer));
