import React from 'react';
import axios from "axios";
import PropTypes from 'prop-types';

import ColAtm from "../../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {ROOM_PHOTO_UPDATE} from "../../../../../../constants/api.constants";
import {getAppLang, getProperty, getUserToken} from "../../../../../../utils";
import PhotoSelect from "../../../../../molecules/PhotoSelect/PhotoSelect";
import {useTranslation} from "react-i18next";
import {AllCheckerCheckbox, CheckboxGroup} from "@createnl/grouped-checkboxes";
import MultiPhotoSelect from "../../../../../molecules/MultiPhotoSelect/MultiPhotoSelect";


const RoomList = props => {
    const {rooms, roomPhotoList, getRoomPhotoList, roomPhotoListFetching} = props;
    const [expanded, setExpanded] = React.useState(false);
    const { t } = useTranslation();

    const handleChange = (roomId) => (event, isExpanded) => {
        setExpanded(isExpanded ? roomId : false);
        if(isExpanded !== false){
            getRoomPhotoList(roomId);
        }
    };

    const RoomPhotoList = ( roomId ) => {

        if(roomPhotoList.length === 0 ){
            return;
        }
        let roomPhotoListBox = '';
        if( expanded !== false && roomPhotoListFetching === false && roomPhotoList.show_recommended === true){

            roomPhotoListBox = roomPhotoList.get_room_photos.map( (photo, key) => (
                photo.is_recommended === false && (
                    <PhotoSelect
                        checkboxId={photo.id}
                        className="channel-box"
                        colClassname="room-photo-grid"
                        blockClass="photo-select-border-box channel-border-box"
                        src={ photo.photo_url_thump}
                        value={photo.id}
                        defaultChecked={photo.is_selected}
                        onClick={ (e) => updateRoomPhoto(e,roomId)}
                        labelClass="fact-name"
                        htmlFor={photo.id}
                    />
                    )
                )
            );

            return roomPhotoListBox;

        } else if (expanded !== false && roomPhotoListFetching === false) {
            roomPhotoListBox = roomPhotoList.get_room_photos.map( (photo, key) => (
                    <PhotoSelect
                        checkboxId={photo.id}
                        className="channel-box"
                        colClassname="room-photo-grid"
                        blockClass="photo-select-border-box channel-border-box"
                        src={ photo.photo_url_thump}
                        value={photo.id}
                        defaultChecked={photo.is_selected}
                        onClick={ (e) => updateRoomPhoto(e,roomId)}
                        labelClass="fact-name"
                        htmlFor={photo.id}
                    />
                )
            );

            return roomPhotoListBox;
        }

    };

    const RoomRecommendedPhotoList = ( roomId ) => {

        if(roomPhotoList.length === 0 ){
            return;
        }
        let roomPhotoListBox = '';
        if( expanded !== false && roomPhotoListFetching === false  && roomPhotoList.show_recommended === true){

            roomPhotoListBox = roomPhotoList.get_room_photos.map( (photo, key) => (

                photo.is_recommended === true ? (
                    <MultiPhotoSelect
                        checkboxId={photo.id}
                        className="channel-box"
                        colClassname="room-photo-grid"
                        blockClass="photo-select-border-box channel-border-box"
                        src={ photo.photo_url_thump}
                        value={photo.id}
                        defaultChecked={photo.is_selected}
                        onClick={ (e) => updateRoomPhoto(e,roomId)}
                        labelClass="fact-name"
                        htmlFor={photo.id}
                    />
                ) : (
                        <></>
                    )
                )
            );

            return roomPhotoListBox;
        }

    };

    const updateRoomPhoto = (e,roomId) => {
        const photoId      = e.target.value
        const photoChecked = e.target.checked

        if( photoId === undefined && photoChecked === undefined ) {
            return;
        }

        const url = ROOM_PHOTO_UPDATE;

        const data = ({
            params: {
                property_id: getProperty(),
                room_id: roomId,
                property_room_photo: photoId.split(',').map( (x) => (
                    { id :+x,
                        is_selected:  e.target.checked}
                ))
            }
        });

        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: getUserToken(),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    if(rooms && rooms.length === 0 )
    {
        return (
            <h2>{ t('enw-notification-no_results') }</h2>
        )
    }else{
        return (

            <>
                {
                    props.selectedRoomTypeId  === 0 ? (
                            props.rooms.map( (room, key) => (

                                    <ExpansionPanel className="accordion-room-table" key={room.id} expanded={expanded === room.id} onChange={handleChange(room.id)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<i className="fas fa-caret-down" />}
                                            aria-label="Expand">
                                            <ColAtm>
                                                <ColAtm md={10} xl={11}>
                                                    <i className="fas fa-bed room-rate-bed" />
                                                    <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                                </ColAtm>
                                            </ColAtm>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                            <ColAtm>
                                                {
                                                    roomPhotoList.show_recommended === true && <ColAtm className="recommended-box">
                                                        <ColAtm xs={6} md={2}>
                                                            <LabelAtm text={ t('enw-room-recommended') } />
                                                        </ColAtm>
                                                        <CheckboxGroup>
                                                            <ColAtm xs={6} md={10} className="select-all">
                                                                <label className="select-all-chck">
                                                                    <AllCheckerCheckbox
                                                                        value={[
                                                                            roomPhotoList.get_room_photos.map( (photo, key) => (
                                                                                photo.is_recommended === true && photo.is_recommended != "false"  && photo.is_recommended != false ?
                                                                                    [photo.id + (",")]
                                                                                    :
                                                                                    null
                                                                            )).join('').slice(0, -1)
                                                                        ]}
                                                                        onChange={ (e) => updateRoomPhoto(e, room.id)}
                                                                    />
                                                                    <span className="select-all-text">
                                                                        { t('btn-deselect_all') }
                                                                    </span>
                                                                    <span className="deselect-all-text">
                                                                        { t('btn-select_all') }
                                                                    </span>
                                                                </label>
                                                            </ColAtm>
                                                            { expanded === room.id ? RoomRecommendedPhotoList(room.id) : '' }
                                                        </CheckboxGroup>
                                                    </ColAtm>
                                                }
                                                <ColAtm>
                                                    { expanded === room.id ? RoomPhotoList(room.id) : '' }
                                                </ColAtm>
                                            </ColAtm>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ))
                    ) : (
                        props.rooms.filter( (room) => room.room_type_id === props.selectedRoomTypeId )
                            .map( (room, key) => (

                                <ExpansionPanel className="accordion-room-table" key={room.id} expanded={expanded === room.id} onChange={handleChange(room.id)}>
                                    <ExpansionPanelSummary
                                        expandIcon={<i className="fas fa-caret-down" />}
                                        aria-label="Expand">
                                        <ColAtm>
                                            <ColAtm md={10} xl={11}>
                                                <i className="fas fa-bed room-rate-bed" />
                                                <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                        <ColAtm>
                                            {
                                                roomPhotoList.show_recommended === true && <ColAtm className="recommended-box">
                                                    <ColAtm xs={6} md={2}>
                                                        <LabelAtm text={ t('enw-room-recommended') } />
                                                    </ColAtm>
                                                    <CheckboxGroup>
                                                        <ColAtm xs={6} md={10} className="select-all">
                                                            <label className="select-all-chck">
                                                                <AllCheckerCheckbox
                                                                    value={[
                                                                        roomPhotoList.get_room_photos.map( (photo, key) => (
                                                                            photo.is_recommended === true && photo.is_recommended != "false"  && photo.is_recommended != false ?
                                                                                [photo.id + (",")]
                                                                                :
                                                                                null
                                                                        )).join('').slice(0, -1)
                                                                    ]}
                                                                    onChange={ (e) => updateRoomPhoto(e, room.id)}
                                                                />
                                                                <span className="select-all-text">
                                                                        { t('btn-select_all') }
                                                                    </span>
                                                                <span className="deselect-all-text">
                                                                        { t('btn-deselect_all') }
                                                                    </span>
                                                            </label>
                                                        </ColAtm>
                                                        { expanded === room.id ? RoomRecommendedPhotoList(room.id) : '' }
                                                    </CheckboxGroup>
                                                </ColAtm>
                                            }
                                            <ColAtm>
                                                { expanded === room.id ? RoomPhotoList(room.id) : '' }
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                        ))
                    )
                }

            </>
        );
    }


};

RoomList.propTypes = {
    rooms: PropTypes.array.isRequired
};
RoomList.defaultProps = {};

export default RoomList;
