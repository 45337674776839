import React, {useEffect} from 'react';
import {useDispatch,useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {setExpireTime, userLogin, setUserName, mobileLogin} from "../../../../../utils";
import {history} from "../../../../../utils/history";
import {APP} from '../../../../../constants/appUrls.constants'

import { login,userClearState } from "../../../../../redux/actions/userActions";
import { getLanguages } from "../../../../../redux/actions/languageActions";

import ButtonAtm from "../../../../atomic/Button/Button";
import InputAtm from "../../../../atomic/Input/Input";
import {FormHelperText, Hidden} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const LoginForm = (props) => {

    const userReduser = useSelector(props => props.userReducer)
    const languages = useSelector(props => props.languagesReducer.languages)
    const [errorMessage, setErrorMessage] = React.useState(null);
    const { t,i18n } = useTranslation();
    const { register, handleSubmit,  errors  } = useForm({
        mode: "onBlur",
        reValidateMode: 'onBlur',
    });
    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(login(data.email, data.password, true, props.oneSignalId))
    };

    useEffect( () => {

        if( Object.keys(languages).length === 0 ) {
            dispatch( getLanguages() )
        }

        if(userReduser && !userReduser.fetching && Object.keys(userReduser.error).length>0){
            const newState = userReduser.error.response.data.message;
            setErrorMessage(newState);
        }
        mobileLogin(props.oneSignalId)

        if(userReduser && !userReduser.fetching && Object.keys(userReduser.userLoginData).length>0){

            const expireTime = userReduser.userLoginData.expire_time;
            const token = userReduser.userLoginData.token;
            const user = userReduser.userLoginData.user;
            const userLang = userReduser.userLoginData.user.language
            if (userLang !== null || userLang !== undefined ){
                i18n.changeLanguage(userLang);
            }

            userLogin(token);
            setExpireTime(expireTime);
            setUserName(user.name+" "+user.surname);
            dispatch( userClearState() )
            history.push(APP);
        }

    },[userReduser])

    return(
        <form onSubmit={handleSubmit(onSubmit)}  style={{textAlign: 'center'}} className="register-form" autoComplete="off" >
            <InputAtm
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={ t('enw-input-email') }
                name="email"
                autoComplete="off"
                autoFocus
                error={!!errors.email}
                inputRef={register({
                    required: true,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("enw-notification_invalid_email")
                    },
                    validate: value => value !== "" || t("enw-notification_this_is_required"),
                })}
            />
            <FormHelperText error={!!errors.email}>
                {errors.email && errors.email.message}
            </FormHelperText>

            <InputAtm
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={t('enw-input-login-password')}
                type="password"
                id="password"
                autoComplete="off"
                error={!!errors.password}
                inputRef={register({ required:  t("enw-notification_this_is_required") })}
            />
            <FormHelperText error={!!errors.password}>
                {errors.password && errors.password.message}
            </FormHelperText>
            <p className="error-login">{errorMessage}</p>
            <ButtonAtm
                className="login-button"
                type="submit"
                fullwidth={true}
                variant="contained"
                color="primary"
                buttontext={t('btn-login')}
                style={{textTransform: 'none' }}
            />

            <Grid container style={{width: '100%'}}>
                <Hidden smDown>
                    <Grid item>
                        <FormControlLabel
                            label={t('input-remember_me')}
                            className="remember-me-label"
                            name="remember_me"
                            inputRef={register}
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                />
                            }
                        />
                    </Grid>
                </Hidden>
                <Grid className="forgot-password-row" item xs>
                    <Button onClick={props.onClickShowForgotPassword} variant="body2" disabled={userReduser.fetching} style={{marginTop: -8}}>
                        {t('btn-forgot_password')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default LoginForm;
