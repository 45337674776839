import React, {Component} from 'react';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import {TextField} from "@material-ui/core";
import ButtonAtm from "../../../../atomic/Button/Button";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
let t=null;

class ManualAddressSave extends Component {

    constructor(props) {
        super(props);
        this.state =  {
            activeStep: 0,
            destination: '',
            address : '',
            location:{
                lat: null,
                long: null
            },
            hasAdress : null,
        }
        t = props.t;
    }

    handleChange = (event) => {

        this.setState({ [event.target.name] : event.target.value }, () => {
            this.state.address.length > 0 ?
                 this.setState({hasAdress: true})
                 :
                this.setState({hasAdress: false})
        });
    }

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }),() => {
            if( this.state.activeStep === 2 ){
                this.updatePropertyContant();
            }
        });
    };

    updatePropertyContant = () =>{
        if(this.state.hasAdress){
            this.props.updatePropertyContant({
                "address"  : this.state.address,
                "latitude" : parseFloat(this.state.location.lat),
                "longitude": parseFloat(this.state.location.long),
            })
        }
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    searchLocation = () => {
        this.getPlacesFromAPI(this.state.destination);
    }

    getLatLngAdress = (lat,lng) => {

        let {google} = this.props;
        let geocoder = new google.maps.Geocoder();

        let latlng = new google.maps.LatLng(lat, lng);

        this.props.findLatLang(latlng, geocoder)
            .then(result => {
                let address = result;
                this.setState({
                    address,
                    location:{
                        lat: lat,
                        long: lng
                    },
                })
            })
            .catch(error => {
                console.error("Not Found Address: ",error);
            });
    }

    getPlacesFromAPI = (query) => {
        let { google, map, marker } = this.props;
        //let currentLocation = new google.maps.LatLng(location.lat, location.long);
        let request = {//location: currentLocation,//radius: '500',
            query: query
        };

        let service = new google.maps.places.PlacesService(map);

        let callback = (results, status) => {

            if ( status === google.maps.places.PlacesServiceStatus.OK ) {
                marker.setPosition(results[0].geometry.location)

                this.props.zoomLocation(marker);

                marker.addListener('dragend', () => {
                    this.getLatLngAdress(marker.position.lat(),marker.position.lng() )
                });

                this.setState({
                    hasAdress:true,
                    address : results[0].formatted_address,
                    location:{
                        lat: marker.position.lat(),
                        long: marker.position.lng()
                    },
                })

            } else {
                //console.log('Google Places API Error: ' + status);
                this.setState({
                    hasAdress : false,
                })
            }
      };

      service.textSearch(request, callback);
    }

    render() {
        const { activeStep } = this.state;
        const { notFoundAddress,propertyName } = this.props;
        return (
            <div className={ notFoundAddress ? "location-stepper-root" : "d-none" }>
                <div>
                    <h3 className="location-find-results"> { t('enw-general-location-add_your_address_manually') } </h3>
                </div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={0}>
                        <StepLabel> { t('enw-general-location-your_address-title') } </StepLabel>
                        <StepContent>
                            <ColAtm className="location-card">
                                <LabelAtm color="textSecondary" component="h4" text={propertyName} />
                                <ColAtm>
                                    <ColAtm md={10}>
                                        <TextField
                                            name="destination"
                                            label={ t('enw-input-find_your_location') }
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.handleChange}
                                            value={this.state.destination}
                                        />
                                    </ColAtm>
                                    <ColAtm md={2}>
                                        <ButtonAtm
                                            className={"location-manual-search"}
                                            buttontext={ t('btn-search') }
                                            endIcon={<i className="fas fa-location-arrow" aria-hidden="true" />}
                                            onClick={this.searchLocation}
                                        />
                                    </ColAtm>
                                </ColAtm>
                            </ColAtm>
                            <Typography className="pin-text">
                                { t('enw-general-location-map_pin_warning_message') }
                            </Typography>
                            <div className="mt-22">
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                        startIcon={<i className="fas fa-arrow-left" />}
                                    >
                                        { t('btn-back') }
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        endIcon={<i className="fas fa-arrow-right" />}
                                    >
                                        {activeStep === 2 - 1 ? t('btn-finish') : t('btn-next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key={1}>
                        <StepLabel> { t('enw-general-location-verify_your_address') }</StepLabel>
                        <StepContent>
                            <Typography>
                                <ColAtm className="location-card">
                                    <TextField
                                        multiline={true}
                                        type="text"
                                        name="address"
                                        label={ t('enw-input-address') }
                                        variant="outlined"
                                        rows={2}
                                        fullWidth
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        error={!this.state.hasAdress}
                                    />
                                </ColAtm>
                            </Typography>
                            <div className="mt-22">
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                        startIcon={<i className="fas fa-arrow-left" />}
                                    >
                                        { t('btn-back') }
                                    </Button>
                                    <Button
                                        disabled={ activeStep === 2 - 1 ? !this.state.hasAdress : false}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        startIcon={<i className="fas fa-check" />}
                                    >
                                        {activeStep === 2 - 1 ? t('btn-finish') : t('btn-next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 2 && (
                    <Paper square elevation={0} className="reset-location">
                        <Typography>{ t('enw-general-location-verify_your_address') }</Typography>
                        <Typography>{ t('enw-general-location-mistake_message') }</Typography>
                        <Button onClick={this.handleReset} className={"classes.button"}>
                            { t('btn-reset') }
                        </Button>
                    </Paper>
                )}
            </div>
        );
    }
}

//export default ManualAddressSave;
export default compose(withTranslation('common'))(ManualAddressSave);
