import React from "react";
import ReactExport from "react-export-excel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExportOfferListToExcel = (props) => {

    const { t } = useTranslation();

    const DownloadButton = () => {
        return (
            <Tooltip title={ t('datatable-toolbar-download_excel') }>
                <IconButton>
                    <i className="fas fa-file-excel"/>
                </IconButton>
            </Tooltip>
        )
    }


    return (
        <ExcelFile element={DownloadButton()}>
            <ExcelSheet data={props.data} name="Employees">
                <ExcelColumn label={ t('enw-offer_list-column-ticket_code') } value="ticket_code"/>
                <ExcelColumn label={ t('enw-offer_list-column-offer_title')} value="title"/>
                <ExcelColumn label={ t('enw-offer_list-column-price') } value="total"/>
                <ExcelColumn label={ t('enw-offer_list-column-effective_date') }
                             value={ (col) => moment.unix( col.created_at ).format("DD-MM-YYYY")  }/>
                <ExcelColumn label={ t('enw-offer_list-column-expire_date') }
                             value={ (col) => moment( col.expire_date ).format("DD-MM-YYYY")  }/>
                <ExcelColumn label={ t('enw-offer_list-column-approved') }
                             value={ (col) => col.accept_status === 1 ?  t('enw-offer-status-approved') :
                                 col.accept_status  === 2 ?  t('enw-offer-status-pending') : t('enw-offer-status-unapproved') }/>
                <ExcelColumn label={ t('enw-offer_list-column-status') }
                             value={ (col) => col.status ? t('enw-input-active') :  t('enw-input-inactive') }/>
            </ExcelSheet>
        </ExcelFile>
    );

}
export default ExportOfferListToExcel
