import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import CheckboxAtm from "../../../../atomic/Checkbox/Checkbox";
import {NoSsr} from "@material-ui/core";

let lastChangeID = null;
const PaymentCheckBox = (props) => {

    const {paymentMappingListData, UpdatePaymentStatusMapping} = props;

    const paymentReducers = useSelector(props => props.paymentReducers?.updatePaymentMappingBoxStatusData)
    const [isSelected , setIsSelected ] = useState( props.paymentMappingListData.status );

    const handleUpdatePayment = (e) => {

        let params = {}
        if(isSelected){
            params = {
                property_payment_mapping_id: paymentMappingListData.id,
                set_status : 0
            };
            UpdatePaymentStatusMapping( params )
            lastChangeID = paymentMappingListData.id
        }else{
            params = {
                property_payment_mapping_id: paymentMappingListData.id,
                set_status : 1
            };
            UpdatePaymentStatusMapping( params )
            lastChangeID = paymentMappingListData.id
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }
    useEffect( () => {
        if (paymentReducers?.status === 200 && lastChangeID === paymentMappingListData.id){
            setState();
        }
    },[paymentReducers])



    return(
        <NoSsr>
            <CheckboxAtm
                className="fact-box"
                checkboxId={paymentMappingListData.id}
                value={paymentMappingListData.code}
                checked={isSelected}
                onChange={handleUpdatePayment}
            />
        </NoSsr>
    )


}
export default PaymentCheckBox;
