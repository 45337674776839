import React, {Component}   from 'react';
import {PageTitle}          from "../../../atomic/PageHeader/PageTitle";
import RowAtm               from "../../../atomic/Row/Row";
import Container            from "@material-ui/core/Container";
import PageHeaderSaveAtm    from "../../../atomic/PageHeader/PageHeaderSave";
import FooterAtm            from "../../../atomic/Footer/Footer";
import Menu                 from "../../Global/Layout/Header/Submenu/General";
import {Link} from "react-router-dom";
import {connect}            from "react-redux";
import {Animated}           from "react-animated-css";
import {getContactList, onFacilityContactSubmit} from "../../../../redux/actions/facilityContactActions";
import FacilityContactForm from "./components/FacilityContactForm";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {history} from "../../../../utils/history";
import {CONTENT, CONTENT_EMPLOYEES, CONTENT_INFORMATION} from "../../../../constants/appUrls.constants";
let t=null;

class Index extends Component {

    constructor(props){
        super(props);
        this.headerStatusShow = this.headerStatusShow.bind(this)
        this.state = {
            statusHeader: false,
            redirect: false,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        this.props.getContactList();
    }

    headerStatusShow = () =>{
        return  <PageHeaderSaveAtm
            saveText="Save"
            backText={ t('btn-dashboard') }
            backButton={CONTENT}
            text={t("enw-general-title")}
            saveButtonDisabled={this.props.buttonPassiveStatus}
            processStatus={this.props.updateFetching}
            processResponse={this.props.updateData}
            errorStatus={Object.keys(this.props.error).length > 0 ? this.props.error : false}
            successHeaderTime={true}
        />
    };

    saveNextButton = () => {
        /*setTimeout(() => {
            this.setState({
                redirect: true,
            })
        }, 2000)*/
        this.setState({
            redirect: true,
        })
    };

    renderRedirect = () => {
        if (this.props.facilityContactReducer.updateData === 200) {
            if (this.state.redirect) {
                history.push(CONTENT_EMPLOYEES);
            }
        }
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-general-communication-pagetitle') } />
                {
                    this.headerStatusShow()
                }
                <Menu facilityColClass="active-menu-bg"
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <RowAtm>
                                { !this.props.fetching &&
                                <FacilityContactForm property_contact={this.props.property_contact}
                                                     social_media_addresses={this.props.social_media_addresses}
                                                     country_code={this.props.country_code}
                                                     get_property={this.props.get_property}
                                                     onFacilityContactSubmit={this.props.onFacilityContactSubmit}/>
                                }

                            </RowAtm>
                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextText={ t('btn-next') }
                    nextDisabled={this.props.buttonPassiveStatus}
                    backDisabled={this.props.buttonPassiveStatus}
                    iconLeftClassName="fas fa-arrow-left"
                    backTo={CONTENT_INFORMATION}
                    component={Link}
                    form='general-form'
                    onClick={this.saveNextButton}
                />
                {this.renderRedirect()}

            </>

        );
    }
}

const mapStateToProps = (props) => {

    return{
        fetching : props.facilityContactReducer.fetching,
        buttonPassiveStatus : props.facilityContactReducer.buttonPassiveStatus,
        updateFetching : props.facilityContactReducer.updateFetching,
        updateData : props.facilityContactReducer.updateData,
        error : props.facilityContactReducer.error,

        property_contact : props.facilityContactReducer.property_contact,
        get_property : props.facilityContactReducer.get_property,
        social_media_addresses : props.facilityContactReducer.social_media_addresses,
        country_code : props.facilityContactReducer.country_code,
        facilityContactReducer : props.facilityContactReducer,
    }
};

const mapDispatchToProps = {
    getContactList,
    onFacilityContactSubmit
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Index));
