import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    NETWORK_CHANNEL_PRICING_ADULT_CREATE,
    NETWORK_CHANNEL_PRICING_ADULT_UPDATE,
    NETWORK_CHANNEL_PRICING_CHILD_CREATE,
    NETWORK_CHANNEL_PRICING_CHILD_UPDATE,
    NETWORK_CHANNEL_PRICING_GET,
    NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE,
    NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE
} from "../../../../constants/api.constants";


export function getChannelPaxList(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_GET',
            payload: axios.post(NETWORK_CHANNEL_PRICING_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}


export function addChannelAdultPricing(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_ADULT_CREATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_ADULT_CREATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function addChannelChildPricing(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_CHILD_CREATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_CHILD_CREATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}


export function updateChannelAdultPricing(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_ADULT_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_ADULT_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelChildPricing(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_CHILD_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_CHILD_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}


export function updateChannelAdultPricingMapping(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelChildPricingMapping(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelPricingClearState() {


    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_POLICY_CLEAR_STATE_UPDATE',
        })
    }
}

export function updateChannelPricingErrorClear() {


    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_PRICING_CLEAR_ERROR_STATE',
        })
    }
}
