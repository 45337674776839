import React, {Component}   from 'react';

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear
} from "../../../../redux/actions/Payment/Payment.actions";

import {Grid} from "@material-ui/core";
import PaymentList from "./components/PaymentList";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {PAYMENT} from "../../../../constants/appUrls.constants";

let t =null;

class PaymentListIndex extends Component {

    constructor(props){
        super(props);
        this.state = {
            open : false,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getPaymentMappingList();
        this.props.getPaymentTypeList();
    }

    handleOpen = () => {
        const {paymentMapping} = this.props;
        this.props.getPaymentTypeList();

        if (!paymentMapping.paymentTypeListFetching) {
            this.setState({open:true});
        }
    };

    handleClose = (e) => {
        this.setState({open:false});
    };

    render() {
        return (
            <>
                <PageTitle title={  t('enw-payment-virtual_pos-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-dashboard') }
                    backButton={PAYMENT}
                    text={ t('enw-payment-virtual_pos-title') }
                />
                <Grid container className="container-width cancellation-policy-list payment-setup">
                    {
                        (!this.props.paymentMapping.paymentTypeListFetching) &&
                        <PaymentList
                            paymentMappingList={this.props.paymentMapping.paymentMappingList}
                            paymentMapping={this.props.paymentMapping}
                            UpdatePaymentMapping={this.props.UpdatePaymentMapping}
                            UpdatePaymentStatusMapping={this.props.UpdatePaymentStatusMapping}
                            PaymentStatusSetDefault={this.props.PaymentStatusSetDefault}
                            PaymentInstallmentUpdate={this.props.PaymentInstallmentUpdate}
                            getPaymentMappingList={this.props.getPaymentMappingList}
                            updatePaymentErrorClear={this.props.updatePaymentErrorClear}
                            successPaymentClear={this.props.successPaymentClear}
                            paymentTypeList={this.props.paymentMapping.paymentTypeList}
                        />
                    }
                </Grid>
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        paymentMapping: props.paymentReducers
    }
};

const mapDispatchToProps ={
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PaymentListIndex));
