import axios from 'axios';
import {getAppLang, getProperty, getUserToken, isDefined} from "../../utils";
import {
    PROPERTY_ROOM_RATE_LIST,
    PROPERTY_ROOM_RATE_LIST_BEDS,
    PROPERTY_ROOM_TYPE,
    PROPERTY_ROOM_RATE,
    PROPERTY_ROOM_AND_BED_ADD,
    PROPERTY_RATE_PLAN_ADD,
    PROPERTY_FACT_SEARCH_LIST,
    PROPERTY_ASSIGN_RATE,
    UPDATE_ROOM,
    GET_ACCOMMODATION_TYPES,
    GET_ROOM_DESCRIPTION,
    PROPERTY_ROOM_AND_BED_UPDATE,
    PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE,
    GET_EDIT_RATE,
    PROPERTY_EDIT_RATE_UPDATE,
    CONNECT_RATE
} from "../../constants/api.constants";

export function PropertyRoomAndRateList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_RATE_LIST',
            payload: axios.post(PROPERTY_ROOM_RATE_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}
export function PropertyRoomAndRateListForRateCreate() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE',
            payload: axios.post(PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function PropertyRoomAndRateListWithBeds() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_RATE_LIST',
            payload: axios.post(PROPERTY_ROOM_RATE_LIST_BEDS, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data )
        })
    }
}

export function PropertyRoomTypes() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_TYPE',
            payload: axios.post(PROPERTY_ROOM_TYPE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function PropertyRoomRates() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_RATE',
            payload: axios.post(PROPERTY_ROOM_RATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function propertyRoomAdd(param) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                name: param.roomName,
                room_type_id: param.roomTypeValue,
                max_occupancy: param.maxRoomOccupancy,
                max_adult: param.maxAdults,
                max_child: param.maxChildren,
                room_size: param.room_size,
                room_count : param.room_count,
                room_type_count : param.room_type_count,
                bathroom_count : param.bathroom_count,
                toilet_count : param.toilet_count,
                lounge_count : param.lounge_count,
                max_child_number : param.max_child_number,
                room_bed_group: param.options,
                exclude_occupancy : param.exclude_occupancy,
                room_view_type : param.roomViewType,
                room_size_type : param.roomSizeType,
                description : param.description,
                connected_rooms : param.connected_rooms,
                is_connected_room : param.is_connected_room,
                is_connected_room_price : param.is_connected_room_price,
                is_connected_room_availability : param.is_connected_room_availability,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_BED_ADD',
            payload: axios.post(PROPERTY_ROOM_AND_BED_ADD, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function propertyRatePlanAdd(param) {


    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                name: param.name,
                description: param.description,
                accommodation_type: param.accommodationType,
                facts: param.facts,
                rooms: param.rooms,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_RATE_PLAN_ADD',
            payload: axios.post(PROPERTY_RATE_PLAN_ADD, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    };
}

export function propertyFactSearchList() {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "search_term" : null,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_FACT_SEARCH_LIST',
            payload: axios.post(PROPERTY_FACT_SEARCH_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function propertyAssignRate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id": param.rooms,
                "room_rate_id": param.roomRateId,
                "description": param.description,
              //  "rack_rate": param.rackRate,
                "included_occupancy": param.includedOccupancy,
               /* "ext_adult_type": param.extAdultType,
                "ext_adult_rate": param.extAdultRate,
                "ext_child_type": param.extChildType,
                "ext_child_rate": param.extChildRate,
                "dis_guest_type": param.disGuestType,
                "dis_guest_rate": param.disGuestRate*/
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ASSIGN_RATE',
            payload: axios.post(PROPERTY_ASSIGN_RATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateRoom(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                room_id : param.roomId,
                name: param.roomName,
                room_type_id: param.roomTypeValue,
                max_occupancy: param.maxRoomOccupancy,
                max_adult: param.maxAdults,
                max_child: param.maxChildren,
                room_size: param.room_size,
                room_size_type : param.room_size_type,
                room_count : param.room_count,
                room_type_count : param.room_type_count,
                bathroom_count : param.bathroom_count,
                toilet_count : param.toilet_count,
                lounge_count : param.lounge_count,
                max_child_number : isDefined(param.max_child_number) ? param.max_child_number : null,
                room_bed_group : param.options,
                room_view_type : param.roomViewType,
                exclude_occupancy : param.exclude_occupancy,
                description : param.description,
                connected_rooms : param.connected_rooms,
                is_connected_room : param.is_connected_room,
                is_connected_room_price : param.is_connected_room_price,
                is_connected_room_availability : param.is_connected_room_availability,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_ROOM',
            payload: axios.post(UPDATE_ROOM, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getAccommodationTypes(){

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_ACCOMMODATION_TYPES',
            payload: axios.post(GET_ACCOMMODATION_TYPES, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getRoomDescription(){

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()}
    };

    return dispatch => {
        dispatch({
            type: 'GET_ROOM_DESCRIPTION',
            payload: axios.get(GET_ROOM_DESCRIPTION,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function propertyRatePlanUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                name: param.name,
                id: param.id,
                description: param.description,
                accommodation_type: param.accommodation_type,
                status: 1,
                min_stay: param.min_stay,
                facts: param.facts,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_BED_UPDATE',
            payload: axios.post(PROPERTY_ROOM_AND_BED_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    };
}

export function getEditRatePlan(room_rate_id) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                room_rate_id: room_rate_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_EDIT_RATE',
            payload: axios.post(GET_EDIT_RATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    };
}

export function propertyEditRateUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                name: param.name,
                id: param.id,
                accommodation_type: param.accommodationType,
                rooms: param.rooms,
                status: 1,
                facts: []
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_EDIT_RATE_UPDATE',
            payload: axios.post(PROPERTY_EDIT_RATE_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    };
}

export function connectRateUpdate(room_rate_id) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language': getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                room_rate_id: room_rate_id,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CONNECT_RATE_UPDATE',
            payload: axios.post(CONNECT_RATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    };
}

export function updateRoomAndRateClear(){
    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_BED_UPDATE_CLEAR_DATA',
        })
    }
}
export function addRoomAndRateClear(){
    return dispatch => {
        dispatch({
            type: 'PROPERTY_ROOM_AND_BED_ADD_CLEAR_DATA',
        })
    }
}
export function editRateGetClear(){
    return dispatch => {
        dispatch({
            type: 'GET_EDIT_RATE_CLEAR_DATA',
        })
    }
}
export function connectRateGetClear(){
    return dispatch => {
        dispatch({
            type: 'CONNECT_RATE_UPDATE_CLEAR_DATA',
        })
    }
}
