import React        from 'react';
import PropTypes    from 'prop-types';
import { Container } from "@material-ui/core";
import Button       from "@material-ui/core/Button";
import ColAtm       from "../Col/Col";

const FooterAtm = props => (
    <Container fixed className="footer">
        <ColAtm md={12} style={{
            display: 'block',
            textAlign: 'center',
            marginTop: '12px'
        }}>
            <Button
                onClick={props.onClickBack}
                to={props.backTo}
                component={props.component}
                variant={props.variant}
                color={props.color}
                value={props.value}
                style={props.buttonBackStyle}
                disabled={props.backDisabled}
            >
                <i className={props.iconLeftClassName} style={{ marginRight: '5px'}} /> {props.buttonBackText}
            </Button>
            <Button
                onClick={props.onClick}
                to={props.nextTo}
                component={props.nextComponent}
                variant={props.variant}
                color={props.color}
                value={props.value}
                style={props.buttonNextStyle}
                disabled={props.nextDisabled}
                htmlFor="general-form"
                form={props.form}
                type="submit"
            >
               {props.buttonNextText} <i className={props.iconRightClassName}  style={{ marginLeft: '5px'}} />
            </Button>
        </ColAtm>
    </Container>
);

FooterAtm.propTypes = {
    className: PropTypes.string,
    form: PropTypes.string,
    iconRightClassName: PropTypes.string,
    iconLeftClassName: PropTypes.string,
    component: PropTypes.any,
    nextComponent: PropTypes.any,
    width: PropTypes.any,
    nextTo: PropTypes.any,
    backTo: PropTypes.any,
    nextDisabled: PropTypes.bool,
    backDisabled: PropTypes.bool,
    alt: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.any,
    buttonBackStyle: PropTypes.any,
    buttonNextStyle: PropTypes.any,
    onClick: PropTypes.func,
    onClickBack: PropTypes.func,
    buttonBackText: PropTypes.string,
    buttonNextText: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
};

export default FooterAtm;
