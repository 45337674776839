import React        from 'react';
import PropTypes    from 'prop-types';

import ColAtm       from "../../atomic/Col/Col";
import IconAtm      from "../../atomic/Icon/Icon";
import BlockAtm from "../../atomic/Block/Block";
import Typography from "@material-ui/core/Typography";
import {Tooltip, withStyles} from "@material-ui/core";
import MultiCheckboxAtm from "../../atomic/MultiCheckbox/MultiCheckbox";
import {isDefined} from "../../../utils";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);

const MultiFactCheckBox = props => (
    //  <ColAtm className={props.colClassname} md={2} xs={12} value={props.value}> Yeni Offer da bu şekilde yapmıştık
    <ColAtm className={props.colClassname} md={2} xs={6} sm={props.sm}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} >
            { props.isFeature ? (
                <div style={{ 'float' : 'right'}}>
                    <Tooltip title={props.isFeature === true ? "Öne Çıkan Özellik Kapat" : "Öne Çıkan Özellik Aç" }>
                        <i  onClick={props.handlerIsfeature} className={props.isFeature === true ?
                            'channel-active-icon fas fa-star green bg-icon-green' : 'channel-active-icon fas fa-star gray'}/>
                    </Tooltip>

                </div>
            ) : (<></>)}
            <MultiCheckboxAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                checkboxId={props.checkboxId}
                value={props.value}
                tooltip={props.tooltip}
                labelPlacement={props.labelPlacement}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                disabled={props.disabled}
            />
            <label htmlFor={props.htmlFor}>
                <label htmlFor={props.htmlFor} className={props.labelClassName}>
                    <IconAtm iconClass={props.iconClass} />
                    {
                        isDefined(props.menuCount) &&
                        <Typography className={props.menuCountClass}>{props.menuCount}</Typography>
                    }
                    <BlockAtm className={props.labelIconClass}>
                        {
                            isDefined(props.tooltip) ?
                                <LightTooltip title={props.tooltip} placement="top">
                                    <Typography noWrap className={props.labelClass} htmlFor={props.htmlFor}> <i className="fas fa-check-circle blue" /> {props.text} </Typography>
                                </LightTooltip>
                                :
                                <Typography noWrap className={props.labelClass} htmlFor={props.htmlFor}> <i className="fas fa-check-circle blue" /> {props.text} </Typography>
                        }
                    </BlockAtm>
                </label>
            </label>
        </BlockAtm>
    </ColAtm>
);

MultiFactCheckBox.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    tooltip: PropTypes.any,
    labelIconClass: PropTypes.string,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    sm: PropTypes.any,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    menuCount: PropTypes.any,
    labelClassName: PropTypes.any,
    menuCountClass: PropTypes.any,
    disabled: PropTypes.any,
    text: PropTypes.any,
};

export default MultiFactCheckBox;
