import React, {Component}   from 'react';
import PropertyButtons      from "./components/SelectProperty";
import {Grid} from "@material-ui/core";

class SelectProperty extends Component {
    render() {
        return (
            <Grid className="pos-relative h-100 property-page">
                <PropertyButtons />
                <Grid className="mini-logo-bottom">
                    <img src="/mini-logo.svg" alt="mini-logo" />
                </Grid>
            </Grid>
        );
    }
}
export default SelectProperty;
