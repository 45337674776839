import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {getLanguages} from "../../../redux/actions/languageActions";
import {compose} from "redux";
import {connect} from "react-redux";
import {getAppLang} from "../../../utils";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const arrow = `${process.env.PUBLIC_URL}/assets/img/language/sort-down-solid.svg`;
let t=null;

class HeaderLandingLanguage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            mainButtonContent: "",
            anchorEl: null
        };
        t = props.t;
    }

    componentDidMount() {
        this.props.getLanguages();
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    changeLanguage = (lng, name) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lng);

            const LngList = () => (
                <div>
                    <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${lng}.svg`} alt={lng} />
                    <span> {name} </span>
                </div>
            );

        this.setState({ mainButtonContent: <LngList />, anchorEl: null});
    };

    systemSelectedLang = (languages) => {
        let selectedLangFlag = "";
        let lng = getAppLang();
        const defaultLang = (
            <div>
              <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/en.svg`} alt="en" />
              <span className="uppercase"> en</span>
            </div>
        )

        if( languages.length > 0){
            selectedLangFlag = languages.filter( (lang) => lang.code == lng).map((lang, key) => {
                return  <div key={key}>
                    <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${lang.code}.svg`} alt={getAppLang()} />
                    <span className="uppercase"> {getAppLang()}</span>
                </div>
            })
        }else{
            selectedLangFlag = (
                <div>
                    <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${getAppLang()}.svg`} alt={getAppLang()} />
                    <span className="uppercase"> {getAppLang()}</span>
                </div>
            )
        }

        selectedLangFlag = Object.keys(selectedLangFlag).length > 0 ? selectedLangFlag : defaultLang ;

        return selectedLangFlag
    }

    render() {
        const { anchorEl } = this.state;

        const menu = (
            <Menu id="login-dropdown"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
            >
                {
                    this.props.languages.map( (language, key) => (
                         <MenuItem onClick={() => this.changeLanguage(language.code, language.language_key)} key={key}>
                            <div>
                                <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${language.code}.svg`} alt={language.code} />
                                <span> {t(language.language_key)} </span>
                            </div>
                        </MenuItem>
                    ))
                }
            </Menu>

        );
        return (
            <>
                <Button className="login-language" aria-controls="login-dropdown" aria-haspopup="true" onClick={this.handleClick}>
                    {this.systemSelectedLang(this.props.languages)}
                    <img src={arrow} alt="arrow" />
                </Button>
                {menu}
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        languages : props.languagesReducer.languages,
    }
};

const mapDispatchToProps ={
    getLanguages
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(HeaderLandingLanguage));
