import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import PlaceListInfo from "./placeListInfo";
import {Grid, Hidden} from "@material-ui/core";
import ButtonAtm from "../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import EditPlace from "../EditPlace";



const PlaceList = (props) => {

    const [expanded, setExpanded] = React.useState(false);
    const placeList  = useSelector(props => props.getPlaceListReducer.placeList);
    const [editPlaceData, setEditPlaceData] = React.useState([]);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const { t } = useTranslation();

    const handleChange = (roomId) => (event, isExpanded) => {
        setExpanded(isExpanded ? roomId : false);

        if(isExpanded !== false){
            //dispatch( roomFactUpdateClear() );
            //dispatch( RoomFactListGet(roomId) );
        }
    };

    const openEditPlace = (place) => {
        setEditPlaceData(place);
        setEditModalStatus(true);
    }
    const closeEditPlace = () => {
        setEditModalStatus(false);
    }

    useEffect( () => {},[])


    return(
        <>

            {
                props.selectedPlaceCategoryId === 0 ?
                    placeList && placeList.length > 0 && placeList.map( (place) => (
                        <ExpansionPanel  className={`${place.status === 0 ? ' accordion-room-table inactive-plcs' : 'accordion-room-table'}`} key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-caret-down" />}
                                aria-label="Expand">
                                <Grid container
                                      justify="space-between"
                                      alignItems="center">
                                    <Grid xs={7} item className="d-inherit">
                                        <i className="far fa-home-alt room-rate-bed" />
                                        <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                    </Grid>
                                    <Grid xs={5} item onClick={(event) => event.stopPropagation()}
                                          onFocus={(event) => event.stopPropagation()}>
                                        <Hidden xsDown>
                                            <ButtonAtm className="edit-room-btn"
                                                       onClick={ ()=> openEditPlace(place) }
                                                       buttontext={ t('btn-edit_place') }/>
                                        </Hidden>
                                        <Hidden smUp>
                                            <ButtonAtm className="edit-room-btn"
                                                       onClick={ ()=> openEditPlace(place) }
                                                       buttontext={<i className="fas fa-pen" /> }/>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                <PlaceListInfo place={place}/>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )) :
                    placeList && placeList.length > 0 && placeList.filter( (place) => place.category_filters.includes(props.selectedPlaceCategoryId)  )
                        .map( (place) =>(
                            <ExpansionPanel className="accordion-room-table" key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-caret-down" />}
                                    aria-label="Expand">
                                    <Grid container
                                          justify="space-between"
                                          alignItems="center">
                                        <Grid xs={7} item className="d-inherit">
                                            <i className="far fa-home-alt room-rate-bed" />
                                            <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                        </Grid>
                                        <Grid xs={5} item onClick={(event) => event.stopPropagation()}
                                              onFocus={(event) => event.stopPropagation()}>
                                            <Hidden xsDown>
                                                <ButtonAtm className="edit-room-btn"
                                                           onClick={ ()=> openEditPlace(place) }
                                                           buttontext={ t('btn-edit_place') }/>
                                            </Hidden>
                                            <Hidden smUp>
                                                <ButtonAtm className="edit-room-btn"
                                                           onClick={ ()=> openEditPlace(place) }
                                                           buttontext={<i className="fas fa-pen" /> }/>
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                    <PlaceListInfo place={place}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))
            }
            {
                editModalStatus &&
                <EditPlace place={editPlaceData}
                           closePlace={closeEditPlace}
                />
            }

        </>

    )

}
export default PlaceList;
