import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import { makeStyles } from '@material-ui/core/styles';
import {Animated} from "react-animated-css";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import InputAtm from "../../../../atomic/Input/Input";
import {FormHelperText, FormControl, Grid} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {convertAccentedCharacters, getChildAges, getDayHours, getYears} from '../../../../../utils/index';
import {createFilterOptions} from "@material-ui/lab";

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});

const  useOnMount = (handler) =>    {
    return useEffect(handler,[]);
}
const startYear = new Date().getFullYear();
const endYear = 1700;

const times = getDayHours();
const years = getYears(startYear, endYear);
const ages = getChildAges();

const GenerelInformationForm = (props) => {


    const defaultValues ={
        name       : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.name : '',
        chain_type : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.chain_id : '',
        country    : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.country : '',
        official_name : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.official_name : '',
        property_type_id : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.property_type_id : '',
        tax_office : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.tax_office : '',
        tax_number : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.tax_number : '',
        has_locale_name : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.has_locale_name : '',
        number_of_rooms : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.number_of_rooms : '',
        year_construction : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.year_construction : '',
        year_renovation : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.year_renovation : '',
        checkin_time : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.checkin_time : '',
        checkout_time : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.checkout_time : '',
        locale_name_language  :  props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.locale_name_language : '',
        locale_name     :  props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.locale_name : '',
        pet_allowed : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.pet_allowed : '',
        disability_friendly : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.disability_friendly : '',
        minimum_age_policy : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.minimum_age_policy : '',
        /*min_check_in_age_with_elder_person : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.min_check_in_age_with_elder_person : '',*/
        min_self_check_in_age : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.min_self_check_in_age : '',
        property_timezone : props.additional_info && Object.keys(props.additional_info).length > 0 ? props.additional_info.property_timezone : '',
        general_timezone    : props.property_info && Object.keys(props.property_info).length > 0 ? props.property_info.general_timezone : '',
        property_language_spoken    : props.property_language_spoken && Object.keys(props.property_language_spoken).length > 0 ? props.property_language_spoken : '',
    }

    const { register, handleSubmit,  errors ,  watch, getValues,setValue, setError, clearError} = useForm({
        mode: 'onChange',
        reValidateMode: "onChange",
        defaultValues,
    })

    const watch_has_locale_name = watch("has_locale_name");
    const classes = styles();
    const { t } = useTranslation();

    const [languageSpoken, setLanguageSpoken] = React.useState(
        props.property_language_spoken.filter(item => item.is_selected === true).map((items) => {return items.code})
        );

    const onSubmit = (data) => {
        let params = {
            name                : data.name,
            property_type_id    : data.property_type_id,
            chain_id            : data.chain_type,
            country             : data.country,
            number_of_rooms     : data.number_of_rooms,
            checkin_time        : data.checkin_time,
            checkout_time       : data.checkout_time,
            year_construction   : data.year_construction,
            year_renovation     : data.year_renovation,
            minimum_age_policy  : data.minimum_age_policy,
            pet_allowed         : data.pet_allowed,
            disability_friendly : data.disability_friendly,
            /*min_check_in_age_with_elder_person: data.min_check_in_age_with_elder_person,*/
            min_self_check_in_age: data.min_self_check_in_age,
            property_timezone   : data.property_timezone,
            general_timezone    : data.general_timezone,
            property_language_spoken: languageSpoken,
        }

        if(data.has_locale_name)
        {
            params = Object.assign({},params,{locale_name:data.locale_name, locale_name_language:data.locale_name_language, has_locale_name : data.has_locale_name});
        }
        else{
            params = Object.assign({}, params, {has_locale_name : data.has_locale_name})
        }
       props.updateInformation(params);
    }



    useOnMount(() => {
        register(
            { name: "chain_type", type: "text" },
            {  required: t('enw-notification_this_is_required')  }
        );
        register(
            { name: "country", type: "text" },
            {  required: t('enw-notification_this_is_required')  }
        );
        register(
            { name: "property_timezone", type: "text" },
            {  required: t('enw-notification_this_is_required')  }
        );
        register(
            { name: "property_type_id", type: "text" },
            {  required: t('enw-notification_this_is_required')  }
        );
        register(
            { name: "year_construction", type: "text" }
        );
        register(
            { name: "year_renovation", type: "text" }
        );
        register(
            { name: "checkin_time", type: "text" }
        );
        register(
            { name: "checkout_time", type: "text" }
        )
        register(
            { name: "minimum_age_policy", type: "text" }
        )
        register(
            { name: "pet_allowed", type: "text" }
        )
        register(
            { name: "disability_friendly", type: "text" }
        )
        /*register(
            { name: "min_check_in_age_with_elder_person", type: "text" }
        )*/
        register(
            { name: "min_self_check_in_age", type: "text" }
        )
        register(
            { name: "property_language_spoken", type: "text" }
        )
        register(
            { name: "locale_name_language", type: "text" },
            {
                validate: (value) => {
                    if( ( value !== '' && value !== null )
                        &&
                        (watch("locale_name") === 0 || watch("locale_name") === null || watch("locale_name") === undefined || watch("locale_name") === '' ) ){
                        return t('enw-notification_this_is_required')
                    }
                }
        });
    });

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option,
    });

   useEffect( () => {

       if(watch_has_locale_name === false){
           setValue("locale_name", '');
           setValue("locale_name_language", '');

           clearError("locale_name_language");
           clearError("locale_name");
       }
   },[watch_has_locale_name])

    const handleChangeFields = (e, value) => {
        let selectedValues =  value.map((item) => {return item.code})
        setLanguageSpoken(selectedValues)
    };

    const trueFalseOption = [
            {
                id: "1",
                language_key: t('enw-input-checkbox-yes')
            },
            {
                id: "0",
                language_key: t('enw-input-checkbox-no')
            }
        ]

    return(
        <ColAtm container className={classes.root} direction="row" md={6}>
            <form onSubmit={handleSubmit(onSubmit)}  name="general-form" id="general-form" autoComplete="off" style={{width: 606}} >
                <LabelAtm variant="h6" component="h2" text={t('enw-general-information-subtitle')} />
                <InputAtm
                    name="name"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="property_name"
                    label={t('input-property_name')}
                    autoComplete="off"
                    autoFocus
                    error={!!errors.name}
                    inputRef={register({
                        required: t('enw-notification_this_is_required'),
                    })}
                />
                <FormHelperText error={!!errors.name}>
                    {errors.name && errors.name.message}
                </FormHelperText>
                <ColAtm>
                    <input type="checkbox" className="d-none" id="haveANonEnglishName" name="has_locale_name" ref={register} />
                    <label className={"traditional-name " + ( watch_has_locale_name ? 'active-button' : '')} htmlFor="haveANonEnglishName">
                        <i className={ watch_has_locale_name ? 'fa fa-check' : ''} aria-hidden="true" />
                        { t('enw-btn-facility_have_non_eng_name')}
                    </label>
                </ColAtm>
                {
                    watch_has_locale_name &&
                    <Animated animationIn="fadeIn" animationInDuration={1000} isVisible={true}>
                        <Grid container justify="space-between">
                            <div className="w-49">
                                <FormControl variant="outlined" fullWidth>
                                    <Autocomplete
                                        id="local_language"
                                        options={props.languages.languages.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                        getOptionLabel={option => t(option.language_key)}
                                        defaultValue={ props.languages.languages.find( (country) => {
                                            return country.code === getValues('locale_name_language')
                                        }) }
                                        filterOptions={createFilterOptions({
                                            matchFrom: 'start',
                                            stringify: option => t(option.language_key),
                                        })}
                                        onChange={(e, data) => {

                                            if(data !== null){
                                                setValue("locale_name_language", data.code);
                                                if(watch("locale_name") === ''){
                                                    setError("locale_name_language", "notMatch", t('enw-notification_this_is_required'));
                                                }else{
                                                    clearError("locale_name")
                                                }
                                            }else{
                                                if(watch("locale_name") === ''){
                                                    clearError("locale_name_language")
                                                }else{
                                                    setError("locale_name", "notMatch", t('enw-notification_this_is_required'));
                                                }
                                                setValue("locale_name_language", '');
                                            }
                                        }}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    className={"auto-complete-select"}
                                                    fullWidth
                                                    {...params}
                                                    label={t('enw-input-local_language')}
                                                    variant="outlined"
                                                    error={!!errors.locale_name}
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormHelperText error={!!errors.locale_name}>
                                    {errors.locale_name && errors.locale_name.message}
                                </FormHelperText>
                            </div>
                            <div className="w-49">
                                <InputAtm
                                    name="locale_name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={t('enw-input-property_name_local_language')}
                                    id="property_name_local_language"
                                    autoComplete="off"
                                    inputRef={register({
                                        validate: (value) => {
                                            if( ( value !== '' )
                                                &&
                                                (watch("locale_name_language") === 0 || watch("locale_name_language") === null || watch("locale_name_language") === undefined || watch("locale_name_language") === '' ) ){
                                                return t('enw-notification_this_is_required')
                                            }else{
                                                if(value !== ''){
                                                    clearError("locale_name_language")
                                                }
                                                if(value === ''){
                                                    setError("locale_name_language", "notMatch", t('enw-notification_this_is_required'));
                                                }
                                                if(value === '' && watch("locale_name") === ''){
                                                    clearError("locale_name_language")
                                                }
                                                if(watch("locale_name_language") !== '' && value === ''){
                                                    setError("locale_name_language", "notMatch", t('enw-notification_this_is_required'));
                                                }
                                            }
                                        }
                                    })}
                                    error={!!errors.locale_name_language}
                                />
                                <FormHelperText error={!!errors.locale_name_language}>
                                    {errors.locale_name_language && errors.locale_name_language.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                    </Animated>
                }
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="chain_type"
                                options={props.property_chains}
                                getOptionLabel={option => t(option.name)}
                                defaultValue={ props.property_chains.find( (chains) => {
                                    return chains.id === getValues('chain_type')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.name),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("chain_type", data.id);
                                        clearError("chain_type")
                                    }else{
                                        setValue("chain_type", '');
                                        setError("chain_type", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-chain_type')}
                                            variant="outlined"
                                            error={!!errors.chain_type}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        <FormHelperText error={!!errors.chain_type}>
                            {errors.chain_type && errors.chain_type.message}
                        </FormHelperText>
                    </div>

                    <div className="w-49">
                        <InputAtm
                            className="type-number-hidden"
                            name="number_of_rooms"
                            variant="outlined"
                            fullWidth
                            label={t('enw-input-number_of_rooms')}
                            id="number_of_rooms"
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                            autoComplete="off"
                            type="number"
                            inputRef={register({ max: {value: 9999, message: 'Number of Rooms max value 9999'}, })}
                            error={!!errors.number_of_rooms}
                        />
                        <FormHelperText error={!!errors.number_of_rooms}>
                            {errors.number_of_rooms && errors.number_of_rooms.type == 'max' && errors.number_of_rooms.message}
                        </FormHelperText>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="country"
                                options={props.countries.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                name="country"
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ props.countries.find( (country) => {
                                    return country.country_code === getValues('country')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("country", data.country_code);
                                        clearError("country")
                                    }else{
                                        setValue("country", '');
                                        setError("country", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-country')}
                                            variant="outlined"
                                            error={!!errors.country}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        <FormHelperText error={!!errors.country}>
                            {errors.country && errors.country.message}
                        </FormHelperText>
                    </div>

                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="property_type"
                                name="property_type_id"
                                options={props.property_type.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ props.property_type.find( (type) => {
                                    return type.id === getValues('property_type_id')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("property_type_id", data.id);
                                        clearError("property_type_id")
                                    }else{
                                        setValue("property_type_id", '');
                                        setError("property_type_id", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-property_type')}
                                            variant="outlined"
                                            error={!!errors.property_type_id}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        <FormHelperText error={!!errors.property_type_id}>
                            {errors.property_type_id && errors.property_type_id.message}
                        </FormHelperText>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                            id="property_timezone"
                            options={props.general_timezone}
                            name="property_timezone"
                            getOptionLabel={option => option.location + " - " + (option.description)}
                            defaultValue={ props.general_timezone.find( (timezone) => {
                                return timezone.id === parseInt(getValues('property_timezone'))
                            }) }

                            onChange={(e, data) => {
                                if(data !== null){
                                    setValue("property_timezone", data.id);
                                    clearError("property_timezone")
                                }else{
                                    setValue("property_timezone", '');
                                    setError("property_timezone", "notMatch", t('enw-notification_this_is_required'));
                                }
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        className={"auto-complete-select"}
                                        fullWidth
                                        {...params}
                                        label={ t('enw-input-timezone') }
                                        variant="outlined"
                                        error={!!errors.property_timezone}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormHelperText error={!!errors.property_timezone}>
                        {errors.property_timezone && errors.property_timezone.message}
                    </FormHelperText>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="construction_year"
                                options={years}
                                getOptionLabel={option => String(option)}
                                defaultValue={getValues('year_construction')}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("year_construction", String(data));
                                    }else{
                                        setValue("year_construction", '');
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-construction_year')}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="last_renovation_year"
                                options={years}
                                getOptionLabel={option => String(option)}
                                defaultValue={ getValues('year_renovation')}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("year_renovation", String(data));
                                    }else{
                                        setValue("year_renovation", '');
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-last_renovation_year')}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="check_in_time"
                                options={times}
                                filterOptions={filterOptions}
                                getOptionLabel={option => option}
                                defaultValue={ getValues('checkin_time')}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("checkin_time", String(data));
                                    }else{
                                        setValue("checkin_time", '');
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-check_in_time')}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="check_out_time"
                                options={times}
                                getOptionLabel={option => option}
                                defaultValue={ getValues('checkout_time')}
                                filterOptions={filterOptions}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("checkout_time", String(data));
                                    }else{
                                        setValue("checkout_time", '');
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={t('enw-input-check_out_time')}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="pet_allowed"
                                options={trueFalseOption}
                                name="pet_allowed"
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ trueFalseOption.find( (pet) => {
                                    return pet.id === getValues('pet_allowed')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("pet_allowed", data.id);
                                        clearError("pet_allowed")
                                    }else{
                                        setValue("pet_allowed", '');
                                        setError("pet_allowed", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={ t('enw-input-pet_allowed') }
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="disability_friendly"
                                options={trueFalseOption}
                                name="disability_friendly"
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ trueFalseOption.find( (disabledFriendly) => {
                                    return disabledFriendly.id === getValues('disability_friendly')
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("disability_friendly", data.id);
                                        clearError("disability_friendly")
                                    }else{
                                        setValue("disability_friendly", '');
                                        setError("disability_friendly", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={ t('enw-input-disabled_friendly') }
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="min_self_check_in_age"
                                options={ages}
                                getOptionLabel={option => option}
                                defaultValue={ getValues('min_self_check_in_age')}
                                filterOptions={filterOptions}
                                onChange={(e, data) => {
                                    if(data !== null){
                                        setValue("min_self_check_in_age", String(data));
                                    }else{
                                        setValue("min_self_check_in_age", '');
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={ t('enw-input-min_self_check_in_age') }
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="w-49">
                        <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                                id="minimum_age_policy"
                                name="minimum_age_policy"
                                options={props.minimum_age_policy}
                                getOptionLabel={option => t(option.language_key)}
                                defaultValue={ props.minimum_age_policy.find( (type) => {
                                    return type.value === parseInt(getValues('minimum_age_policy'))
                                }) }
                                filterOptions={createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: option => t(option.language_key),
                                })}
                                onChange={(e, data) => {

                                    if(data !== null){
                                        setValue("minimum_age_policy", data.value.toString());
                                        clearError("minimum_age_policy")
                                    }else{
                                        setValue("minimum_age_policy", '');
                                        setError("minimum_age_policy", "notMatch", t('enw-notification_this_is_required'));
                                    }
                                }}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            className={"auto-complete-select"}
                                            fullWidth
                                            {...params}
                                            label={ t('enw-input-minimum_child_age') }
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                            id="property_language_spoken"
                            options={props.property_language_spoken}
                            name="property_language_spoken"
                            multiple
                            getOptionLabel={option => t(option.language_key)}
                            defaultValue={props.property_language_spoken.filter(spokenLanguages => spokenLanguages.is_selected === true)}
                            filterOptions={createFilterOptions({
                                matchFrom: 'start',
                                stringify: option => t(option.language_key),
                            })}
                            onChange={(e, value) => handleChangeFields(e, value)}
                            renderInput={params => {
                                return (
                                    <TextField
                                        className={"auto-complete-select"}
                                        fullWidth
                                        {...params}
                                        label={ t('enw-input-language_spoken') }
                                        variant="outlined"
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            </form>
        </ColAtm>
    )

}

export default GenerelInformationForm
