import {dashboardConstants} from "../../constants/Content/dashboard.constants";
import { getUserToken,getProperty,getAppLang }     from "../../utils/index";
import {GET_PROPERTY_DASHBOARD} from "../../constants/api.constants";
import axios                from 'axios';

export const dashboardPropertyActions = () => {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };
    return (dispatch) => {
        return axios.post(GET_PROPERTY_DASHBOARD, requestOptions.body, {
            headers: requestOptions.headers
        })
            .then(response => {
                dispatch(dashboardPropertySuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const dashboardPropertySuccess =  (data) => {
    return {
        type: dashboardConstants.GET_DASHBOARD_SUCCESS,
        left_drawer: data.data,
        property_dashboard: data.data.dashboard.general,
        general: data.data.dashboard.general,
        photos: data.data.dashboard.photos,
        places: data.data.dashboard.places,
        amenities: data.data.dashboard.amenities,
        facility: data.data.dashboard.facility,
        room: data.data.dashboard.room,
    }
};
