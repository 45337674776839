import React, { useRef} from 'react'

import ColAtm from "../../../../atomic/Col/Col";

import LabelAtm from "../../../../atomic/Label/Label";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import { withStyles} from "@material-ui/core/styles";
import {useForm} from "react-hook-form";
import InputAtm from "../../../../atomic/Input/Input";
import {FormHelperText} from "@material-ui/core";

const styles = theme => ({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    },
    eye: {
        cursor: 'pointer',
    },
});

const ResetPasswordForm = (props) => {

    const { register, handleSubmit, errors ,watch} = useForm({
        mode: "onChange",
        reValidateMode: 'onChange',
    });

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = (data) => {

        const params = {
            email : props.email,
            key : props.hash,
            password : data.password,
            password_confirmation :data.password_confirmation
        }

        props.resetPassword(params)
    }


    return (
        <>
            <ColAtm container className={props.classes.root}  direction="row" md={6}>
                <form onSubmit={handleSubmit(onSubmit)} className="edit-profile-form" name="general-form" id="general-form" >
                <LabelAtm variant="h6" component="h2" text="Reset Password" />

                    <InputAtm
                        name="password"
                        type="password"
                        variant="outlined"
                        label="Yeni Şifre"
                        fullWidth
                        error={!!errors.password}
                        inputRef={register({
                            required: "You must specify a password",
                            minLength: {
                                value: 8,
                                message: "Password must have at least 8 characters"
                            }
                        })}
                    />
                    <FormHelperText error={!!errors.password}>
                        {errors.password && errors.password.message}
                    </FormHelperText>
                    <InputAtm
                        name="password_confirmation"
                        type="password"
                        variant="outlined"
                        label="Confirm Password"
                        fullWidth
                        error={!!errors.password_confirmation}
                        inputRef={register({
                            validate: value =>
                                value === password.current || "The passwords do not match"
                        })}
                    />
                    <FormHelperText error={!!errors.password_confirmation}>
                        {errors.password_confirmation && errors.password_confirmation.message}
                    </FormHelperText>
            </form>
        </ColAtm>
        </>
    );

}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (ResetPasswordForm);
