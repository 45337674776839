import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import {Grid, MenuItem, Backdrop, CircularProgress} from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import InputAdornment from "@material-ui/core/InputAdornment";
import {sendReservationMail, reservationClearState} from "../../../../../../redux/actions/Network/Reservation/sendBookingMail.actions";
import {getReservationDetail} from "../../../../../../redux/actions/Network/Reservation/reservationDetail.actions";
import {useDispatch, useSelector} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import {updateTicket, updateTicketClearState} from "../../../../../../redux/actions/Network/Reservation/ticket.actions";
import {getProperty, isDefined} from "../../../../../../utils";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import createOrUpdateTicketReducer
    from "../../../../../../redux/reducers/Network/Reservation/ReservationDetail/createOrUpdateTicketReducer";
import sendBookingMailReducer from '../../../../../../redux/reducers/Network/Reservation/ReservationDetail/sendBookingMailReducer';
import SuccessGroupInventoryModal from '../../../../../organisms/Modal/SuccessModal/SuccessGroupInventoryModal';
import ErrorModal from '../../../../../organisms/Modal/ErrorModal/ErrorModal';
import SuccessModal from '../../../../../organisms/Modal/SuccessModal/SuccessModal';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#d71f26',
        //height: '100vh'
    },
}))

const SendBookingMail = (props) =>  {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateReservationDataMail = useSelector(props => props.sendBookingMailReducer.sendBookingMail)
    const errorUpdateReservationData = useSelector(props => props.sendBookingMailReducer.sendBookingMail_error)
    const updateReservationDataMailFetching = useSelector(props => props.sendBookingMailReducer.sendBookingMail_fetching)

    const {reservation} = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true); 
    };
    const handleClose = () => {
        setOpen(false);
       // dispatch(updateTicketClearState());
    };

    useEffect( () => {
        dispatch(reservationClearState());
    },[] )
    
    const onClick = () => {
        setOpen(true);

        const params = {
            property_id: getProperty(),
            booking_id: reservation.id
        }
        dispatch(sendReservationMail(params))  
    };

    return (
        <>
            <ButtonAtm className="filter-action-button float-right rez-mail"
                       startIcon={<i className="fa fa-envelope-o mr-0" />} buttontext={t('enw-reservation_update-mail')} fullwidth={false} onClick={onClick} />

                {
                    updateReservationDataMailFetching && isDefined(updateReservationDataMailFetching) &&
                    <Backdrop className={classes.backdrop} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                {
                    
                   isDefined(updateReservationDataMail) && (updateReservationDataMail.status === 200) &&
                    <SuccessModal status={true}
                    message={t('enw-notification_success')} />
                }
                {   isDefined(errorUpdateReservationData) &&
                    isDefined(errorUpdateReservationData.response) &&
                        <ErrorModal status={true} message={errorUpdateReservationData.response.data.message}/>
                } 
        </>
    );
};


export default SendBookingMail;
