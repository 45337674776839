import React, {useEffect, useMemo, useState} from "react";
import Price from "./Price"
import {Grid} from "@material-ui/core";
import BlockAtm from "../../../../../atomic/Block/Block";
import {isDefined} from "../../../../../../utils";

export default function QuickPricingPrices(props) {

    useEffect(() => {},[props.prices]);

    const [prices] =  useState( useMemo(() => props.prices , [props.prices] ) );

    return (
        <Grid container className={props.isAvail ? "inventory-rate-detail" : "inventory-rate-price"}>
            <Grid item xs={2}>
                <BlockAtm md={12} />
            </Grid>
            <Grid item xs={10} className="m-overflow-inventory-price">
                <Grid container className="inventory-price-padding">
                    {
                        Object.entries(prices).map( ( [date, value] ) => (

                            <Price date={date}
                                   value={value}
                                   onChangeUpdateData={props.onChangeUpdateData}
                                   updatedData={ props.updatedData }
                                   channelAvailability={ props.channelAvailability }
                                   showStopSell={ props.showStopSell }
                            />

                        ))

                    }
                    {
                        isDefined(props.currency_code) && !props.showStopSell && !props.showMinStay &&
                        <p className="inventory-currency">
                            {props.currency_code}
                        </p>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
