import React, {useEffect,useMemo, useState} from "react";
import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";
import Accommodations from "./Accommodations";
import {useTranslation} from "react-i18next";


const buildingTextFieldNames = (roomPrice, allDays) => {

    let data = [];
    if(roomPrice !== undefined) {
        data = roomPrice;
        // Roomlar

        Object.keys(data).forEach(function(key) {

            if( !roomPrice[key].hasOwnProperty('accommodation') )  return;

            //Roomlara ait  accommodation lar
            Object.keys( roomPrice[key].accommodation ).forEach( function ( accommodationKey ) {

                if( ! roomPrice[key].accommodation[accommodationKey].hasOwnProperty('price') ) return;

                // Accommodationlara ait pricelar
                let newPrice = {}
                let accomodationPrice = roomPrice[key].accommodation[accommodationKey].price;

                allDays.map( (day) => {
                     if(accomodationPrice.hasOwnProperty(day)){
                         Object.assign(newPrice,{[day]: accomodationPrice[day] })
                     }else{
                         Object.assign(newPrice,{[day]: null })
                     }
                })

                roomPrice[key].accommodation[accommodationKey].price = newPrice;
            })
        });
    }

    if (data.length > 0){
        return data;
    }else{
        return [];
    }

}

const Rooms =  (props) => {
    const { t } = useTranslation();
    const [state] = useState( useMemo(() => buildingTextFieldNames(props.roomPrice, props.allDays ) ), [props.roomPrice,props.allDays,] );
    useEffect(() => {},[state]);
    const onInputChange = (roomIndex,accommodationIndex, item) => {
        state[roomIndex]['accommodation'] = item;
    };

    const postData = (e) => {
        e.preventDefault();
        const { storePriceOffer, startDate, endDate, offerId } = props;
        let params = {
            offer_id : offerId,
            start_date : startDate,
            end_date : endDate,
            room_price : state
        };
        storePriceOffer(params);
    };

    return (
        <form onSubmit={postData} name="general-form" id="general-form" autoComplete="off" style={{width: '100%'}}>
            {
                state.map( (room, roomIndex) => (
                        <ColAtm className="inventory-box">
                            <ColAtm className="inventory-room-box">
                                <ColAtm xs={5}>
                                    <BlockAtm xs={1} className="m-h-auto">
                                        <i className="fas fa-bed inventory-icon" />
                                    </BlockAtm>
                                    <BlockAtm xs={6} className="m-h-auto">
                                        <LabelAtm labelClass="inventory-room-name" text={room.room_name} />
                                    </BlockAtm>
                                    <BlockAtm xs={2} className="m-h-auto">
                                        <LabelAtm labelClass="offer-price-room-rates" text={ t('enw-input-number_of_rooms')} />
                                    </BlockAtm>
                                </ColAtm>
                            </ColAtm>

                            <Accommodations onChange={onInputChange}
                                            accommodations={room.accommodation}
                                            roomIndex={roomIndex}
                                            allDays={props.allDays}
                                            dateRangeChange={props.dateRangeChange}
                                            currency={props.currency}
                            />

                        </ColAtm>
                ))
            }
        </form>
    )
}

export default Rooms;
