import React, {useState} from 'react';
import ColAtm from "../../../../../atomic/Col/Col";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {cloneDeep} from 'lodash'
import InputAtm from "../../../../../atomic/Input/Input";
import {Grid, MenuItem} from "@material-ui/core";
import {isDefined} from "../../../../../../utils";
import {useTranslation} from "react-i18next";
import {SelectValidator} from "react-material-ui-form-validator";

const Rates = (props) => {

    const { t } = useTranslation();
    const {setChangeRoomRate, updateType, availabilityTypeId} = props;
    const [cloneRoomRate] = useState( cloneDeep(props.roomRate) );
    const [priceFields, setPriceFields] = useState([]);

    const onChangePrice = (e, roomId, rateId, currency, type) => {
        if (e.target.value === undefined) return 0;

        const newValues = {...priceFields};

        let dayArr = [...priceFields];
        const value = e.target.value
        let index = dayArr.findIndex(day => day === value);


        if(index > -1) {
            dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
        } else {
            type === "stop_sell" ?
                dayArr.push(newValues[roomId] = {
                    value: value,
                    room_id: roomId,
                    room_rate_mapping_id: rateId,
                    name: e.target.name
                })
                :
                type === "min_stay" ?
                    dayArr.push(newValues[roomId] = {
                        value: value,
                        room_id: roomId,
                        room_rate_mapping_id: rateId,
                        name: e.target.name
                    })
                :
                dayArr.push(newValues[roomId] = {
                    value: value,
                    room_id: roomId,
                    room_rate_mapping_id: rateId,
                    currency: currency,
                    name: e.target.name
                });
        }

        /*const outputClearData = [...new Map(dayArr.map(o => [o.name, o])).values()]
        const clearData = outputClearData.filter((item) => item.value);*/

        const clearData = [...new Map(dayArr.map(o => [o.name, o])).values()];

        setPriceFields(clearData);
        setChangeRoomRate(clearData);
    }

    return(
        <ColAtm className="bulk-update-rates">
            {
                Object.entries(cloneRoomRate).map( ( [index, room] ) => {
                    return(
                        <>
                            <ColAtm md={12} className="mt-22 p-6-0 bulk-update-room-name">
                                <label className="MuiFormControlLabel-root desc-text bulk-update-room-icon">
                                        <span>
                                            <span className="MuiIconButton-label"></span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </span>
                                    <span
                                        className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                                            {room.name}
                                        </span>
                                </label>
                            </ColAtm>
                            {
                                Object.entries(room.rates).map( ( [index, rates] ) => (
                                    <>
                                        {
                                            updateType === "rate" ?

                                                Object.values(rates).map( ( rate, key ) => {
                                                    return (
                                                        <ColAtm>
                                                            <ColAtm md={1} className="bulk-update-null-col" />
                                                            <ColAtm md={11} className="mt-8 d-block bulk-update-room-name group-inventory-input">
                                                                <Grid container>
                                                                    <Grid item xs={6} className="rate-name">
                                                                        <p>{rate.name} ({rate.currency})</p>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <InputAtm
                                                                            name={"rate"+rate.id+"room"+room.id+"room.name"+availabilityTypeId+updateType+rate.currency}
                                                                            label={updateType === "rate" ? t('enw-offer_list-column-price') : t('enw-input-min_stay') }
                                                                            onChange={ (e) => onChangePrice(e,room.id, rate.room_rate_mapping_id, rate.currency, "rate")}
                                                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                                                            autoComplete="off"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ColAtm>
                                                        </ColAtm>
                                                    )

                                                } )
                                                :
                                               updateType === "min_stay" ?

                                               <ColAtm>
                                                       <ColAtm md={1} className="bulk-update-null-col" />
                                                       <ColAtm md={11} className="mt-8 d-block bulk-update-room-name group-inventory-input">
                                                           <Grid container>
                                                               <Grid item xs={6} className="rate-name">
                                                                   <p>{isDefined(Object.values(rates)[0]) && Object.values(rates)[0].name}</p>
                                                               </Grid>
                                                               <Grid item xs={6}>
                                                                   <InputAtm
                                                                       name={"rate"+Object.values(rates)[0].id+"room"+room.id+"room.name"+availabilityTypeId+updateType}
                                                                       label={updateType === "rate" ? t('enw-offer_list-column-price') : t('enw-input-min_stay') }
                                                                       onChange={ (e) => onChangePrice(e,room.id, Object.values(rates)[0].room_rate_mapping_id, "EUR", "min_stay")}
                                                                       onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                                                       autoComplete="off"
                                                                   />
                                                               </Grid>
                                                           </Grid>
                                                       </ColAtm>
                                                   </ColAtm>
                                                :

                                                   <ColAtm>
                                                       <ColAtm md={1} className="bulk-update-null-col" />
                                                       <ColAtm md={11} className="mt-8 d-block bulk-update-room-name group-inventory-input">
                                                           <Grid container>
                                                               <Grid item xs={6} className="rate-name">
                                                                   <p>{isDefined(Object.values(rates)[0]) && Object.values(rates)[0].name}</p>
                                                               </Grid>
                                                               <Grid item xs={6}>
                                                                   <SelectValidator
                                                                       onChange={ (e) => onChangePrice(e,room.id, Object.values(rates)[0].room_rate_mapping_id, "EUR", "stop_sell")}
                                                                       name={"rate"+Object.values(rates)[0].id+"room"+room.id+"room.name"+availabilityTypeId+updateType}
                                                                       className="form-control"
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       label={ t('enw-input-select') }
                                                                   >
                                                                       <MenuItem value={0}>
                                                                           {t('enw-select-inventory_stop-sell-passive')}
                                                                       </MenuItem>
                                                                       <MenuItem value={1}>
                                                                           {t('enw-select-inventory_stop-sell-active')}
                                                                       </MenuItem>
                                                                   </SelectValidator>
                                                               </Grid>
                                                           </Grid>
                                                       </ColAtm>
                                                   </ColAtm>
                                        }
                                    </>
                                ))
                            }
                        </>
                    )
                })
            }
        </ColAtm>
    )
}

export default Rates;
