import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import {Divider} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function ChannelPaxPolicyButtons(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="w-100">
            <Button className="filter-action-button float-right" aria-controls="filter-action-menu" aria-haspopup="true" onClick={handleClick}>
                <i className="fas fa-plus-circle" /> { t('btn-new_action') }
            </Button>
            <Menu
                id="room-rate-button-menu"
                className="room-rate-button-list"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>{props.AdultPax}</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>{props.AdultPaxDecrease}</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>{props.ChildPax}</MenuItem>
            </Menu>
        </div>
    );
}

ChannelPaxPolicyButtons.propType = {
    AdultPax: PropTypes.string,
    ChildPax: PropTypes.string,
};

