export const contactConstants = {
    GET_PROPERTY_CONTACT_PENDING  : 'GET_PROPERTY_CONTACT_PENDING',
    GET_PROPERTY_CONTACT_FULFILLED: 'GET_PROPERTY_CONTACT_FULFILLED',
    GET_PROPERTY_CONTACT_REJECTED : 'GET_PROPERTY_CONTACT_REJECTED',

    //UPDATE_PROPERTY_CONTACT
    UPDATE_PROPERTY_CONTACT_PENDING  : 'UPDATE_PROPERTY_CONTACT_PENDING',
    UPDATE_PROPERTY_CONTACT_FULFILLED: 'UPDATE_PROPERTY_CONTACT_FULFILLED',
    UPDATE_PROPERTY_CONTACT_REJECTED : 'UPDATE_PROPERTY_CONTACT_REJECTED',
};
