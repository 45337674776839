import axios from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {
    GET_PLACE_CATEGORIES,
    CREATE_PLACE,
    GET_PLACE_LIST,
    GET_PLACE_PHOTO_LIST,
    UPDATE_PLACE,
    PLACE_CATEGORY_FIELDS
} from "../../../../constants/api.constants";


export function getPlacesCategories() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PLACE_CATEGORIES',
            payload: axios.post(GET_PLACE_CATEGORIES, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createPlace(params){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                place_category_id: params.place_category_id,
                place_working_hour_id : params.place_working_hour_id,
                include: params.include ,
                name : params.name,
                reservation_requirement : params.reservation_requirement,
                field_values : params.field_values,
                description: params.description
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_PLACE',
            payload: axios.post(CREATE_PLACE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createPlaceClearStatus(){
    return dispatch => {
        dispatch({
            type: 'CREATE_PLACE_CLEAR',
        })
    }
}

export function getPlaceList(){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PLACE_LIST',
            payload: axios.post(GET_PLACE_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getPlacePhoto(placeId){

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                place_id : placeId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PLACE_PHOTO_LIST',
            payload: axios.post(GET_PLACE_PHOTO_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updatePlace(params){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_place_id: params.property_place_id,
                place_category_id: params.place_category_id,
                place_working_hour_id : params.place_working_hour_id,
                include : params.include,
                name : params.name,
                reservation_requirement : params.reservation_requirement,
                field_values : params.field_values,
                description: params.description,
                status: params.status
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_PLACE',
            payload: axios.post(UPDATE_PLACE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getPlaceCategoryFields(placeCategoryId, placeId){

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                place_category_id : placeCategoryId,
                place_id : placeId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PLACE_CATEGORY_FIELDS',
            payload: axios.post(PLACE_CATEGORY_FIELDS, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getPlaceCategoryFieldsUpdateClearStatus(){
    return dispatch => {
        dispatch({
            type: 'PLACE_CATEGORY_FIELDS_CLEAR',
        })
    }
}
export function getPlaceCategoryFieldsClearStatus(){
    return dispatch => {
        dispatch({
            type: 'CREATE_PLACE_CLEAR',
        })
    }
}

export function updatePlaceClearStatus(){
    return dispatch => {
        dispatch({
            type: 'UPDATE_PLACE_CLEAR',
        })
    }
}


export function updateFactClearState() {

    return dispatch => {
        dispatch({
            type: 'UPDATE_FACT_MAPPING_CLEAR',
        })
    }
}
