import React from "react";
import PropTypes from "prop-types";

import { Grid } from '@material-ui/core';

const ColAtm = props => (
    <Grid
        item
        container
        style={props.style}
        className={props.className}
        onClick={props.onClick}
        span={props.span}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        justify={props.justify}
        spacing={props.spacing}
        direction={props.direction}
        id={props.id}
        alignItems={props.alignItems}
        order={props.order}>
        {props.children}
    </Grid>
);
ColAtm.propType = {
    style: PropTypes.any,
    span: PropTypes.number,
    spacing: PropTypes.number,
    className: PropTypes.any,
    id: PropTypes.string,
    onClick: PropTypes.func,
    xs: PropTypes.any,
    sm: PropTypes.any,
    md: PropTypes.any,
    lg: PropTypes.any,
    xl: PropTypes.any,
    justify: PropTypes.any,
    direction: PropTypes.any,
    alignItems: PropTypes.string,
    order: PropTypes.number,
    children: PropTypes.any
};

export default ColAtm;
