export const roomPhoto = {
    ROOM_PHOTO_LIST_PENDING  : 'ROOM_PHOTO_LIST_PENDING',
    ROOM_PHOTO_LIST_FULFILLED: 'ROOM_PHOTO_LIST_FULFILLED',
    ROOM_PHOTO_LIST_REJECTED : 'ROOM_PHOTO_LIST_REJECTED',

    ROOM_PHOTO_UPDATE_PENDING  : 'ROOM_PHOTO_UPDATE_PENDING',
    ROOM_PHOTO_UPDATE_FULFILLED: 'ROOM_PHOTO_UPDATE_FULFILLED',
    ROOM_PHOTO_UPDATE_REJECTED : 'ROOM_PHOTO_UPDATE_REJECTED',


}