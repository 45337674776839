import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import {PROPERTY_GROUP_INVENTORY_GET, PROPERTY_INVENTORY_UPDATE_OR_CREATE} from "../../../../constants/api.constants"


export function getPropertyGroupInventory(startDate, endDate, channelId) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                channel_group_id  : channelId,
                start_date  : startDate,
                end_date    : endDate
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_GROUP_INVENTORY_GET',
            payload: axios.post(PROPERTY_GROUP_INVENTORY_GET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }

}

export function updateOrCreateGroupInventory(channel_id,data) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },

        body: {
            params: {
                property_id : getProperty(),
                channel_group_id  : channel_id,
                data       : {...data}
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'PROPERTY_INVENTORY_UPDATE_OR_CREATE',
            payload: axios.post(PROPERTY_INVENTORY_UPDATE_OR_CREATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
