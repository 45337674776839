import axios          from 'axios';
import {getAppLang, getUserToken} from "../../../../utils";
import { FORGOT_PASSWORD }   from "../../../../constants/api.constants";


export function forgotPassword(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'language' : getAppLang(),
            'authToken': getUserToken(),
        },
        body: {
            "params": {
                email : params.email
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: axios.post(FORGOT_PASSWORD, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

 export  function clearStateForgotPassword() {
    return  dispatch => {
         dispatch({
            type : 'CLEAR_STATE'
        })
    }
}