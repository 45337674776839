export const getPlacesCategories = {
    GET_PLACE_CATEGORIES_PENDING  : 'GET_PLACE_CATEGORIES_PENDING',
    GET_PLACE_CATEGORIES_FULFILLED: 'GET_PLACE_CATEGORIES_FULFILLED',
    GET_PLACE_CATEGORIES_REJECTED : 'GET_PLACE_CATEGORIES_REJECTED',
};

export const createPlace = {
    CREATE_PLACE_PENDING  : 'CREATE_PLACE_PENDING',
    CREATE_PLACE_FULFILLED: 'CREATE_PLACE_FULFILLED',
    CREATE_PLACE_REJECTED : 'CREATE_PLACE_REJECTED',
    CREATE_PLACE_CLEAR : 'CREATE_PLACE_CLEAR',
};

export const getPlaceList = {
    GET_PLACE_LIST_PENDING  : 'GET_PLACE_LIST_PENDING',
    GET_PLACE_LIST_FULFILLED: 'GET_PLACE_LIST_FULFILLED',
    GET_PLACE_LIST_REJECTED : 'GET_PLACE_LIST_REJECTED',
};

export const getPlacePhotoList = {
    GET_PLACE_PHOTO_LIST_PENDING  : 'GET_PLACE_PHOTO_LIST_PENDING',
    GET_PLACE_PHOTO_LIST_FULFILLED: 'GET_PLACE_PHOTO_LIST_FULFILLED',
    GET_PLACE_PHOTO_LIST_REJECTED : 'GET_PLACE_PHOTO_LIST_REJECTED',
};

export const getPlaceCategoryFields = {
    PLACE_CATEGORY_FIELDS_PENDING  : 'PLACE_CATEGORY_FIELDS_PENDING',
    PLACE_CATEGORY_FIELDS_FULFILLED: 'PLACE_CATEGORY_FIELDS_FULFILLED',
    PLACE_CATEGORY_FIELDS_REJECTED : 'PLACE_CATEGORY_FIELDS_REJECTED',
    PLACE_CATEGORY_FIELDS_CLEAR : 'PLACE_CATEGORY_FIELDS_CLEAR',
};

export const updatePlace = {
    UPDATE_PLACE_PENDING  : 'UPDATE_PLACE_PENDING',
    UPDATE_PLACE_FULFILLED: 'UPDATE_PLACE_FULFILLED',
    UPDATE_PLACE_REJECTED : 'UPDATE_PLACE_REJECTED',
    UPDATE_PLACE_CLEAR    : 'UPDATE_PLACE_CLEAR',
};
