import React from 'react';


export default function RegisterConditionsText() {

    return (
        <div>
            <h1 className="t-center">Extranetwork <br/> Terms & Conditions</h1>

                These Terms & Conditions is stated to you as a user of the Services (“you”, “your”) by AYT Technologies GmbH, (‘Extranetwork’, ‘we’, ‘us’, ‘our’) sets out the basis on which Extranetwork will provide accommodation and hotel facilities channel management and booking engine services (“the Services”). We may amend the terms of this Agreement at any time by giving you not less than 30 days written notice. By using the Services you confirm your acceptance of the terms of this Agreement, as amended from time to time.

            <h3 className="mt-12">1. Services </h3>

                SAAS (software as a service) modules and any related units located and served online at https://extranetwork.com website and/or mobile apps and other digital services/applications named under “Extranetwork” (“the Services”) are fully stated as “the Services” throughout this document.
                <br/>
                We will provide the Services as described in the “Extranetwork Software As A Service (SAAS) Subscription And Licensing Agreement” (“the Main Agreement”) signed between you and us prior to your use of the Services.
                <br/>
                We reserve the right to upgrade, modify, alter, limit or terminate this document at any time. We will endeavor to notify you of all significant changes to the Services or this document. Additional services that we may provide will then be provided as part of the Services. You agree that the provision of additional services will be governed as stated by these terms and conditions document.

            <h3 className="mt-12">2. Account</h3>

                You shall ensure that all information you provide is accurate, current and complete and you will advise us of any changes so that such information remains accurate, current and complete.
                <br/>
                To enable you to access the Services, we may provide you with a username and password. Your username and password are for your use only. You must at all times keep your username and password secure and confidential and not disclose them to any third party.
                <br/>
                You are solely responsible for any use of the Services by any person using your username or password and you agree to indemnify us against any and all claims arising out of your failure to keep your username or password secure and confidential.
                <br/>
                You must notify us immediately of any unauthorized use of your member ID, password or any other breach of security.

            <h3 className="mt-12">3. Charges</h3>

                You agree to pay all charges for the Services as set out in the Main Agreement and its schedules. <br />
                All charges are exclusive of any taxes, duties or other governmental levies or other charges. You are responsible for payment of all such other charges, including bank transfer fees.
                <br/>
                If you fail to pay the charges by the due date for payment, then if upon written notice informing you of your failure to pay such charges and requiring you to remedy such failure, you still have not made full payment of such charges, we may, without further notice suspend the Services.
                <br/>
                We reserve the right to impose late interest charges on the amounts remaining unpaid. In addition, we reserve the right to recover costs associated with the collection of unpaid charges, including any legal fees and charges relating to debt collection services.
                <br/>
                We reserve the right to modify our pricing structure and payment conditions from time to time at our sole discretion. Such notification shall be given via the email address provided by you.

            <h3 className="mt-12">4. Your Responsibilities</h3>

                We shall not be responsible if we cannot obtain access to any third party portal or website that is required in order to provide the Services in the event that any link fails to connect due to changes in the third-party systems. However, we will make every effort to re-instate the connection.
                <br/>
                It is your responsibility to carefully manage your bookings and inventory. We shall not be responsible for over bookings.
                <br/>
                You shall indemnify us and hold us harmless against all claims made by third parties that arise from your failure to perform your obligations under this Agreement.

            <h3 className="mt-12">5. Warranties</h3>

                Extranetwork gives no warranties and makes no representations of any kind relating to the Services including, without limitation, any implied warranties of non-infringement, fitness for a particular purpose, or merchantability, and we exclude all warranties to the maximum extent permitted by law. The Services is made available to you strictly on an ‘as is’ basis.
                <br/>
                You acknowledge and agree that the Services are acquired for the purposes of a business and that the guarantees provided under any Customer Related Acts shall not apply.

            <h3 className="mt-12">6. Limitation of Liability</h3>
                Except to the extent that it is unlawful to exclude such liability: <br/>
                We will not in any circumstances (including, without limitation, negligence or breach of statutory duty) be liable for any loss of profits, loss of revenue, loss of savings, loss of data, or for any indirect, consequential, special, exemplary, punitive, incidental, or other loss or damages regardless of the form of action or legal basis of liability even if we are advised of the possibility of such loss or damages.

                <br/>
                Our liability to you arising out of any and all claims whatsoever (irrespective of the cause of action) will not in any circumstances exceed in aggregate the total amount of the sums paid by you to us for the Services in respect of which we are in default (which shall not exceed the charges paid by you for those Services during the one month period preceding the date of your claim).
                <br/>
                You acknowledge that the access to and use of the Services may be impaired or prevented by a variety of factors beyond our control, for instance defects in your computer system and problems with Internet connectivity, and that we are not responsible for any such factors or their effects.
                <br/>
                We shall not be liable for any failure to back up or restore any information or for interruptions, delay or suspension of access to or unavailability of the Services, or any loss of such information, data or transmissions. It is your responsibility to ensure that your data is backed up effectively. <br />
                No action arising out of or in connection with this Agreement, regardless of form, may be brought by either party more than one year after the cause of action arose.

            <h3 className="mt-12">7. Intellectual Property</h3>

                You acknowledge that intellectual property rights that may subsist in content of the Services and the processes we use to deliver the Services belong to Extranetwork or its suppliers. You agree not to challenge such ownership or to act inconsistently with such ownership.

            <h3 className="mt-12">8. Confidential information</h3>

                You agree to keep secret and confidential at all times, all Confidential Information of us and will not use, communicate, cause to be communicated, copy, make available or otherwise resupply any such Confidential Information to any person except as is reasonably necessary for the purposes of this Agreement, or as otherwise required by law

            <h3 className="mt-12">9. Promotion</h3>

                You agree that we may identify you as one of our clients in our promotional media and literature and disclose to current and prospective clients and investors that you are a client and user of our products and services.

            <h3 className="mt-12">10. Third Party Systems & Websites</h3>

                This Services interface with Internet sites and systems maintained by third parties. Such systems and websites are not under our control and we are not responsible for their operation or availability. We will not be liable for any damages or loss arising in any way out of or in connection with or incidental to any information or services provided by any third party.

            <h3 className="mt-12">11. Personal Information</h3>

                Extranetwork may collect personal information from your customers or receive personal information about your customers from other booking or accommodation management services and/or websites. Such information includes credit card details that we may collect in providing the Services. You are responsible for complying with the requirements of the related laws in your resident country in relation to the personal information we collect on your behalf.
                <br/>
                You must ensure that all credit card information that is made available to you by Extranetwork is held securely at all times.

            <h3 className="mt-12">12. Termination</h3>

                We may, in our sole discretion, terminate your use of the Services immediately if you fail to comply the terms of these Terms & Conditions or the main Agreement and/or schedules of it.
                <br/>
                If your use of the Services is terminated for any reason:
                <ul>
                        <li>your right to access or use the Services is immediately revoked and you shall cease use of the Services;</li>
                        <li>you shall not attempt to access or use the Services without our prior written consent; and any charges paid by you will be forfeited.</li>
                </ul>

                Termination shall not affect: <br />
                The rights of a party which accrued prior to and upon termination; or the provisions of this Agreement which by their nature, survive termination namely the sections (Warranties), (Limitation of liability), (Intellectual property), (Confidential Information), (Personal information), and this section (Termination).


                <h3 className="mt-12">13. General Provisions</h3>
                Assignment and subcontracting: We may subcontract, assign or novate any of our rights, duties or obligations under this Agreement. You cannot transfer, assign, novate or sublicense any or all of your rights, duties or obligations in this Agreement to, or share them with, a third party, without our prior written approval. <br />
                Governing law: The laws of Germany and Belin State govern this Agreement and the courts of Berlin City shall have jurisdiction to resolve any dispute arising between the parties. <br />
                Waiver: No delay in enforcement or extension of time or failure to exercise any right under this Terms and Conditions will be deemed to be a waiver of any right by us. No waiver of an earlier breach of this Agreement will be construed as a waiver of a later breach.

        </div>
    );
}
