import React, { PureComponent } from 'react';
import {compose}                from "redux";
import {withTranslation}        from "react-i18next";

import ColAtm                   from "../../../../atomic/Col/Col";
import InputAtm                 from "../../../../atomic/Input/Input";
import LabelAtm                 from "../../../../atomic/Label/Label";
import ButtonAtm                from "../../../../atomic/Button/Button";
import MenuItem                 from '@material-ui/core/MenuItem';
import PhoneInput               from 'react-phone-input-2'
import BlockAtm from "../../../../atomic/Block/Block";
import {Grid, Hidden} from "@material-ui/core";
import styled from "styled-components";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {convertAccentedCharacters} from "../../../../../utils";

let t=null;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const PhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 80px !important;
                  }`;

const MobilePhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                  }`;
const FaxCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                  }`;

class Executive extends PureComponent {

    constructor(props){
        super(props);
        this.state = {
            name_surname : '',
            phone     : '',
            extension : '',
            mobile    : '',
            fax       : '',
            email     :  '',
            id        :  '',
            country_code : this.props.country_code ? this.props.country_code : '',
            executive_type_id: '',
            user_executive_type_id: '',
            executives  : this.props.executives ? this.props.executives : '',
            executive_list  : this.props.executive_type ? this.props.executive_type : '',
            executivesFirst  : this.props.executivesFirst ? this.props.executivesFirst : '',
            selectedOption :'',
            activeIndex: null,
            maxListHeight: false,
            tabValue : 0,
        };
        this.onChangeExecutive = this.onChangeExecutive.bind(this);
        this.addNewExecutiveContact = this.addNewExecutiveContact.bind(this);
        this.updateExecutive = this.updateExecutive.bind(this);
        this.deleteExecutive = this.deleteExecutive.bind(this);

        t = props.t;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if( nextProps.executive_type !== prevState.executive_list ||
            nextProps.executivesFirst !== prevState.executivesFirst
        ){
            return nextProps
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {executivesFirst} = this.props
        if(prevProps.executivesFirst !== executivesFirst){
            if(executivesFirst !== undefined && Object.keys(executivesFirst).length !== 0 )
            {
                this.setState({
                    selectedOption : 1,
                    id  : executivesFirst ? executivesFirst.id : '',
                    name_surname: executivesFirst ? executivesFirst.name_surname : '',
                    user_executive_type_id: executivesFirst ? executivesFirst.executive_type_id : '',
                    extension: executivesFirst ? executivesFirst.extension : '',
                    executive_type_id: executivesFirst ? executivesFirst.executive_type_id : '',
                    email: executivesFirst ? executivesFirst.email : '',
                    fax: executivesFirst ? executivesFirst.fax : '',
                    phone: executivesFirst && executivesFirst.phone !== null ? executivesFirst.phone : '',
                    mobile: executivesFirst && executivesFirst.mobile !== null ? executivesFirst.mobile : '',
                    buttonStatus: true,
                    activeIndex: 0
                })
            }else{
                this.setState({
                    buttonStatus:false
                })
            }

        }
    }

    addExecutive  = (e) => {
        e.preventDefault();
        this.props.addExecutiveContact(this.state);

        this.setState({
            activeIndex: 0
        })

        this.setState(prevState => ({
            executives: [ {
                selectedOption:this.state.selectedOption,
                id: this.state.name_surname+this.state.phone,
                name_surname: this.state.name_surname,
                extension: this.state.extension,
                executive_type_id: this.state.user_executive_type_id,
                email: this.state.email,
                phone: this.state.phone,
                mobile: this.state.mobile,
                fax: this.state.fax,
            }, ...prevState.executives]
        }))
    };



    selectExecutiveType = () => {

        return (
            <SelectValidator
                id="contact_type"
                value={this.state.user_executive_type_id}
                onChange={this.handleChangeSelectExecutiveType}
                className="form-control"
                label={ t('enw-input-contact_type') }
                variant="outlined"
                validators={['required']}
                fullWidth
                errorMessages={[t("enw-notification_this_is_required")]}
            >
                {Object.entries(this.props.executive_type).sort((a, b) => convertAccentedCharacters(t(a[1].language_key)) > convertAccentedCharacters(t(b[1].language_key)) ? 1:-1).map((arr)=>{

                    return <MenuItem value={arr[1].executive_type_id} key={arr}>
                        { t(arr[1].language_key) }
                    </MenuItem>
                })}
            </SelectValidator>
        );
    };

    executiveList = () => {
        const {executives} = this.state;
        if (Object.keys(executives).length > 8) {
            this.setState({
                maxListHeight: true,
            });
        }
        if (executives === '' || executives === undefined || executives === null || Object.keys(executives).length === 0  ) {
            return <Grid>
                <ColAtm  container
                         className="no-contact-result"
                         direction="row"
                         justify="center"
                         alignItems="center">
                    <i className="fas fa-address-card" />
                    <p> { t('enw-general-employees-null_list_message') } </p>
                </ColAtm>
            </Grid>
        } else {
            return (
                <>
                    {Object.entries(executives).map((executive, key)=>{

                        return  <ColAtm className="executive-list" key={key} onClick={e => this.setState({activeIndex: key})}>
                            <ButtonAtm
                                className={key === this.state.activeIndex
                                    ? 'blue-button executive-list' : 'executive-list grayButton'}
                                id={executive[1].name_surname}
                                outline
                                fullwidth={true}
                                buttontext={executive[1].name_surname}
                                value={executive[1].id}
                                onClick={this.onChangeExecutive}
                                disabled={false} // TODO : liste üzerinde butonlar disable olmuyor!!
                            />
                        </ColAtm>
                    })}
                </>
            );
        }
    };

    onChangeExecutive(e) {
        let tmpExecutiveId = e.target.value;
        let executiveId = '';
        if(tmpExecutiveId){
            executiveId = tmpExecutiveId;
        }else{
            executiveId = e.currentTarget.value;
        }
        this.setState({
            buttonStatus: true,
            selectedOption:executiveId,
            isActive: !this.state.isActive,
            tabValue : 0
        });

        const getExecutive = this.state.executives.filter( (executive) => {
            return executive.id == executiveId
        });


        if (getExecutive != ''){
            getExecutive.filter( (executive) => {
                let data  = executive;
                if(data !== '') {
                    this.setState({
                        user_executive_type_id: data.executive_type_id  !== null ? data.executive_type_id :"",
                        id: data.id  !== null ? data.id : "",
                        name_surname: data.name_surname  !== null ? data.name_surname : "",
                        extension: data.extension  !== null ? data.extension : "",
                        email: data.email !== null ? data.email : "",
                        phone: data.phone !== null? data.phone : "",
                        mobile: data.mobile !== null ? data.mobile : "",
                        fax: data.fax !== null ? data.fax : "",
                    })
                }else{
                    this.setState({
                        user_executive_type_id: "",
                        id: "",
                        name_surname: "",
                        extension: "",
                        email: "",
                        phone: "",
                        mobile: "",
                        fax: "",
                    })
                }
            });
        }else{
            this.setState({
                user_executive_type_id: "",
                id: "",
                name_surname: "",
                extension: "",
                email: "",
                phone: "",
                mobile: "",
                fax: "",
            })
        }
    }

    addNewExecutiveContact(){
        this.setState({
            selectedOption:"",
            user_executive_type_id: "",
            id: "",
            name_surname: "",
            extension: "",
            email: "",
            phone: "",
            mobile: "",
            fax: "",
            buttonStatus: false,
            activeIndex: false,
            tabValue : 0
        })
    };

    handleChangeSelectExecutiveType = (e) => {
        e.preventDefault();
        this.setState({user_executive_type_id: e.target.value});
    };

    updateExecutive  = (e) => {
        e.preventDefault();
        this.props.updateExecutiveContact(this.state);
    };

    deleteExecutive  = (e) => {
        e.preventDefault();
        this.props.deleteExecutiveContact(this.state);

        let array = this.state.executives;
        let selected = this.state.activeIndex;
        array.splice(selected,1);

        this.setState({
            selectedOption:"",
            user_executive_type_id: "",
            id: "",
            name_surname: "",
            extension: "",
            email: "",
            phone: "",
            mobile: "",
            fax: "",
            buttonStatus: false,
            activeIndex: false
        })
    };

    handleTabChange = ( event, newValue ) => {
        this.setState( {tabValue : newValue});
    };

    render() {
        const { email, name_surname, maxListHeight, tabValue } = this.state;
        const { buttonPassiveStatus } = this.props;

        return (
            <ColAtm md={12} lg={12} xl={12} direction="row" justify="center">
                <Hidden smDown>
                    <ColAtm className="d-block" container direction="row" md={8} lg={5} >
                        <LabelAtm style={{paddingBottom: '24px'}} variant="h6" component="h2" text={ t('enw-general-employees-subtitle') } />
                        <ValidatorForm className="executive-form" autoComplete="off" onSubmit={this.state.buttonStatus ? this.updateExecutive : this.addExecutive} ref="form">
                            <div>
                                <TextValidator
                                    label={ t('enw-input-name_surname') }
                                    variant="outlined"
                                    name="name_surname"
                                    type="text"
                                    fullWidth
                                    value={name_surname}
                                    onChange={e => this.setState({name_surname: e.target.value})}
                                    validators={['required']}
                                    errorMessages={[t("enw-notification_this_is_required")]}
                                />
                                <ColAtm md={12} className="form-group">
                                    {this.selectExecutiveType()}
                                </ColAtm>
                                <div className="employees-phone" style={{width: '80%', display: 'inline-block'}}>
                                    <PhoneCard lang={t('enw-input-phone')}>
                                        <PhoneInput
                                            style={{width: '98%', display: 'inline-block'}}
                                            country={this.state.country_code}
                                            enableSearch={true}
                                            required
                                            label={ t('enw-input-phone') }
                                            variant="outlined"
                                            name="phone"
                                            value={this.state.phone}
                                            placeholder={"Phone"}
                                            prefix={""}
                                            onChange={phone => this.setState({ phone })}
                                        />
                                    </PhoneCard>
                                </div>
                                <InputAtm
                                    style={{width: '20%', display: 'inline-block', float: 'right'}}
                                    label={ t('enw-input-extension') }
                                    placeholder={ t('enw-input-extension') }
                                    variant="outlined"
                                    name="extension"
                                    type="text"
                                    value={this.state.extension}
                                    onChange={e => this.setState({extension: e.target.value})}
                                />
                                <div className="employees-mobile-phone w-49" style={{display: 'inline-block'}}>
                                    <MobilePhoneCard lang={t('enw-input-mobile_phone')}>
                                        <PhoneInput
                                            country={this.state.country_code}
                                            enableSearch={true}
                                            required
                                            label={ t('enw-input-mobile_phone') }
                                            placeholder={ t('enw-input-mobile_phone') }
                                            variant="outlined"
                                            name="mobile"
                                            prefix={""}
                                            value={this.state.mobile}
                                            onChange={mobile => this.setState({ mobile })} />
                                    </MobilePhoneCard>
                                </div>
                                <div style={{width: '2%', display: 'inline-block'}}></div>
                                <div className="employees-fax w-49" style={{display: 'inline-block'}}>
                                    <FaxCard lang={t('enw-input-fax')}>
                                        <PhoneInput
                                            name="fax"
                                            country={this.state.country_code}
                                            enableSearch={true}
                                            variant="outlined"
                                            label={ t('enw-input-fax') }
                                            prefix={""}
                                            value={this.state.fax}
                                            placeholder={ t('enw-input-fax') }
                                            onChange={fax => this.setState({ fax })}
                                            required
                                        />
                                    </FaxCard>
                                </div>
                                <InputAtm
                                    label={ t('enw-input-email') }
                                    placeholder={ t('enw-input-email') }
                                    variant="outlined"
                                    name="email"
                                    type="text"
                                    value={email}
                                    onChange={e => this.setState({email: e.target.value})}
                                />
                            </div>
                            <ColAtm className="executiveBottomButtons">
                                <ColAtm className={this.state.buttonStatus ? "d-none" : "pr-8"} md={12} >
                                    <ButtonAtm
                                        type="submit"
                                        className={ buttonPassiveStatus ? "executiveContactButton grayButton" : "red-button"}
                                        fullwidth={true}
                                        buttontext={ t('btn-add_new_contact') }
                                        disabled={buttonPassiveStatus}
                                    />
                                </ColAtm>
                                <ColAtm className={this.state.buttonStatus ? "pr-8" : "d-none"} md={6} >
                                    <ButtonAtm
                                        type="submit"
                                        className={buttonPassiveStatus ? "executiveContactButton grayButton" : "orange-button" }
                                        fullwidth={true}
                                        buttontext={ t('btn-update') }
                                        disabled={buttonPassiveStatus}
                                    />
                                </ColAtm>
                                <ColAtm className="pl-8" md={6}>
                                    <ButtonAtm
                                        className={ this.state.buttonStatus ? "executiveContactButton grayButton" : "d-none"}
                                        onClick={this.deleteExecutive}
                                        fullwidth={true}
                                        buttontext={ t('btn-delete') }
                                        disabled={buttonPassiveStatus}
                                    />
                                </ColAtm>
                            </ColAtm>
                        </ValidatorForm>
                    </ColAtm>
                    <BlockAtm md={2}>

                        <ColAtm className="new-executiveButton">
                            {
                                this.state.executives == '' ? <></> : <ButtonAtm
                                    className={"soft-blue-button new-executive-btn"  }
                                    type="button"
                                    buttontext={ t('btn-add_new') }
                                    startIcon={<i className="fas fa-plus-circle" />}
                                    fullwidth={true}
                                    onClick={this.addNewExecutiveContact}
                                    disabled={buttonPassiveStatus}
                                />
                            }

                        </ColAtm>

                        <ColAtm container
                                className={`bg-white d-block executiveRightButtons ${maxListHeight ? "executive-list-max-h" : ""}`}
                                direction="row"
                                justify="center"
                                alignItems="center"
                        >

                            {
                                this.executiveList()
                            }
                        </ColAtm>
                    </BlockAtm>
                </Hidden>
                <Hidden mdUp>
                    <Grid container >
                        <Grid item xs={7}>
                            <LabelAtm style={{paddingBottom: '24px'}} variant="h6" component="h2" text={ t('enw-general-employees-subtitle') } />
                        </Grid>
                        <Grid item xs={5}>
                            <ColAtm className="new-executiveButton">
                                {
                                    this.state.executives == '' ? <></> : <ButtonAtm
                                        className={"soft-blue-button new-executive-btn"  }
                                        type="button"
                                        buttontext={ t('btn-add_new') }
                                        startIcon={<i className="fas fa-plus-circle" />}
                                        fullwidth={true}
                                        onClick={this.addNewExecutiveContact}
                                        disabled={buttonPassiveStatus}
                                    />
                                }

                            </ColAtm>
                        </Grid>
                    </Grid>
                    <AppBar className="room-rate-tab-title" position="static">
                        <Tabs className="room-rate-tabFont" value={tabValue} onChange={this.handleTabChange} aria-label="room-rate-tab">
                            <Tab label={ "Yeni Ekle" } {...a11yProps(0)} />
                            <Tab label={ "Eklenenler" } {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                        <ColAtm className="d-block" container direction="row" md={5} lg={5} xl={5}>
                            <ValidatorForm className="executive-form" autoComplete="off" onSubmit={this.state.buttonStatus ? this.updateExecutive : this.addExecutive} ref="form">
                                <div>
                                    <TextValidator
                                        label={ t('enw-input-name_surname') }
                                        variant="outlined"
                                        name="name_surname"
                                        type="text"
                                        fullWidth
                                        value={name_surname}
                                        onChange={e => this.setState({name_surname: e.target.value})}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                    <ColAtm md={12} className="form-group">
                                        {this.selectExecutiveType()}
                                    </ColAtm>
                                    <div className="employees-phone executive-phone-input">
                                        <PhoneCard lang={t('enw-input-phone')}>
                                            <PhoneInput
                                                style={{width: '98%', display: 'inline-block'}}
                                                country={this.state.country_code}
                                                enableSearch={true}
                                                required
                                                label={ t('enw-input-phone') }
                                                variant="outlined"
                                                name="phone"
                                                value={this.state.phone}
                                                placeholder={"Phone"}
                                                prefix={""}
                                                onChange={phone => this.setState({ phone })}
                                            />
                                        </PhoneCard>
                                    </div>
                                    <InputAtm
                                        className="internal-phone"
                                        label={ t('enw-input-extension') }
                                        placeholder={ t('enw-input-extension') }
                                        variant="outlined"
                                        name="extension"
                                        type="text"
                                        value={this.state.extension}
                                        onChange={e => this.setState({extension: e.target.value})}
                                    />
                                    <div className="employees-mobile-phone w-49" style={{display: 'inline-block'}}>
                                        <MobilePhoneCard lang={t('enw-input-mobile_phone')}>
                                            <PhoneInput
                                                country={this.state.country_code}
                                                enableSearch={true}
                                                required
                                                label={ t('enw-input-mobile_phone') }
                                                placeholder={ t('enw-input-mobile_phone') }
                                                variant="outlined"
                                                name="mobile"
                                                prefix={""}
                                                value={this.state.mobile}
                                                onChange={mobile => this.setState({ mobile })} />
                                        </MobilePhoneCard>
                                    </div>
                                    <div className="employees-fax w-49" style={{display: 'inline-block'}}>
                                        <FaxCard lang={t('enw-input-fax')}>
                                            <PhoneInput
                                                name="fax"
                                                country={this.state.country_code}
                                                enableSearch={true}
                                                variant="outlined"
                                                label={ t('enw-input-fax') }
                                                prefix={""}
                                                value={this.state.fax}
                                                placeholder={ t('enw-input-fax') }
                                                onChange={fax => this.setState({ fax })}
                                                required
                                            />
                                        </FaxCard>
                                    </div>
                                    <InputAtm
                                        label={ t('enw-input-email') }
                                        placeholder={ t('enw-input-email') }
                                        variant="outlined"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={e => this.setState({email: e.target.value})}
                                    />
                                </div>
                                <ColAtm className="executiveBottomButtons">
                                    <ColAtm className={this.state.buttonStatus ? "d-none" : "pr-8"} md={12} >
                                        <ButtonAtm
                                            type="submit"
                                            className={ buttonPassiveStatus ? "executiveContactButton grayButton" : "red-button"}
                                            fullwidth={true}
                                            buttontext={ t('btn-add_new_contact') }
                                            disabled={buttonPassiveStatus}
                                        />
                                    </ColAtm>
                                    <ColAtm className={this.state.buttonStatus ? "pr-8" : "d-none"} md={6} >
                                        <ButtonAtm
                                            type="submit"
                                            className={buttonPassiveStatus ? "executiveContactButton grayButton" : "orange-button" }
                                            fullwidth={true}
                                            buttontext={ t('btn-update') }
                                            disabled={buttonPassiveStatus}
                                        />
                                    </ColAtm>
                                    <ColAtm className="pl-8" md={6}>
                                        <ButtonAtm
                                            className={ this.state.buttonStatus ? "executiveContactButton grayButton" : "d-none"}
                                            onClick={this.deleteExecutive}
                                            fullwidth={true}
                                            buttontext={ t('btn-delete') }
                                            disabled={buttonPassiveStatus}
                                        />
                                    </ColAtm>
                                </ColAtm>
                            </ValidatorForm>
                        </ColAtm>

                    </TabPanel>
                    <TabPanel value={tabValue} index={1} className="mt-22 w-100">
                        <BlockAtm md={2} lg={2} xl={2}>

                            <ColAtm container
                                    className={`bg-white d-block executiveRightButtons ${maxListHeight ? "executive-list-max-h" : ""}`}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                            >

                                {
                                    this.executiveList()
                                }
                            </ColAtm>
                        </BlockAtm>
                    </TabPanel>
                </Hidden>
            </ColAtm>
        );
    }
}


export default compose(withTranslation('common')) (Executive);
