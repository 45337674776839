import React, {useEffect, useState} from "react";
import Availability from "./Availability";
import StopSellAvailability from "./StopSellAvailability";


export default function Availabilities(props) {

    useEffect(() => {},[props.availabilities, props.stopSellAvailabilities]);

   // const [availabilities, setAvailabilities] =  useState( useMemo(() => props.availabilities , [] ) );
    const [availabilities] =  useState(  props.availabilities   );
    const [stopSellAvailabilities] =  useState(  props.stopSellAvailabilities   );

    return (
        <>
            {
                props.showStopSell ?

                    Object.entries(stopSellAvailabilities).map( ( [date, value] ) => (

                        <StopSellAvailability date={date}
                                       value={value}
                                       onChangeUpdateData={props.onChangeUpdateData}
                                       updatedData={ props.updatedData }
                                       channelAvailability={ props.channelAvailability }
                                       showStopSell={ props.showStopSell }
                        />

                    ))
                    :
                    Object.entries(availabilities).map( ( [date, value] ) => (

                        <Availability date={ date }
                                      value={ value }
                                      onChangeUpdateData={ props.onChangeUpdateData }
                                      updatedData={ props.updatedData }
                                      showStopSell={props.showStopSell}
                        />

                    ))
            }
        </>
    )
}

