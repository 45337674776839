import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import OfferInfo from "./OfferInfo";
import CancellationPolicy from "../../../../molecules/Offer/CancellationPolicy";
import ImportantNotes from "../../../../molecules/Offer/ImportantNotes";
import Rooms from "../../../../molecules/Offer/Rooms";
import Employees from "../../../../molecules/Offer/Employees";
import Accommodation from "../../../../molecules/Offer/Accommodation";

import Amenities from "./Amenities";
import Facilities from "./Facilities";
import Photos from "./Photos";

import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {dateGlobal} from "../../../../../utils";
import {history} from "../../../../../utils/history";
import {OFFER_PRICE_SHORT} from "../../../../../constants/appUrls.constants";

const getFactByCategory = ( data , factId,factSubId = 'all' ) => {
    let tempData = [];
    if(data !== undefined && data !== null){
        for (const dataKey of Object.keys(data)) {
            if( dataKey === 'get_facts'){
                for(const facId of Object.keys( data[dataKey] ) ){
                    if(facId === factId || facId === factId){
                        for(const facSubId of Object.keys  (data[dataKey][facId].fact_subcategory) ){
                            if (facSubId == factSubId){
                                data[dataKey][facId].fact_subcategory[facSubId].fact.map(fact => tempData.push(fact.id) )
                            }else if(factSubId === 'all'){
                                data[dataKey][facId].fact_subcategory[facSubId].fact.map(fact => tempData.push(fact.id) )
                            }
                        }
                    }
                }
            }
        }
    }
    return tempData;
}

const OfferForm  = (props) => {

    const { t } = useTranslation();
    const {offerReducer, offerStoreData, offerStoreStatus} = props
    const [state, setState] = useState({
        accommodation_mapping :[],
        room_mapping :[],
        contact_mapping :[],
        hotel_features_mapping: getFactByCategory(props.offerReducer, '1'),
        fact_mapping : getFactByCategory(props.offerReducer, '44'),
        photo_mapping : [],
        cover_photos :[],
        start_date:  dateGlobal(new Date()),
        end_date: dateGlobal(new Date( new Date().setDate(new Date().getDate()+13))),
        redirect : false,
    });

    const defaultValues = {
        title: "",
        email: "",
        language: "",
        currency: "",
        confirm_type: "",
        expire_date : null,
        status: "",
        important_notes: [""],
        has_cancellation_policy: "false",
        cancellation_policy: [{"days_before": null, "type": null, "value": null}],
    }

    const { register, handleSubmit,  errors , control, watch, setValue, getValues, triggerValidation,clearError} = useForm({
        mode: 'onChange',
        reValidateMode: "onChange",
        defaultValues,
        shouldFocusError: true,
    })


    const onClickAccommodationType = (e) => {

        if(e.target.checked === true){
            setState({ ...state, accommodation_mapping: [...state.accommodation_mapping, e.target.value] } );

        }else if (e.target.checked === false){
            let temp_accommodation_mapping = state.accommodation_mapping;
            let index = temp_accommodation_mapping.indexOf(e.target.value)
            if (index !== -1) {
                temp_accommodation_mapping.splice(index, 1);
                setState({...state, accommodation_mapping: temp_accommodation_mapping});
            }
        }
    }

    const  onClickSelectRoom = (e) => {
        if(e.target.checked === true) {
            setState({ ...state, room_mapping: [...state.room_mapping, e.target.value] } );
        }else if (e.target.checked === false){
            let temp_room_mapping = state.room_mapping;
            let index = temp_room_mapping.indexOf(e.target.value)
            if (index !== -1) {
                temp_room_mapping.splice(index, 1);
                setState({...state, room_mapping: temp_room_mapping});
            }
        }
    }

    const  onClickEmployess = (e) => {
        if(e.target.checked === true) {
            setState({ ...state, contact_mapping: [...state.contact_mapping, e.target.value] } );
        }else if (e.target.checked === false){
            let temp_contact_mapping = state.contact_mapping;
            let index = temp_contact_mapping.indexOf(e.target.value)
            if (index !== -1) {
                temp_contact_mapping.splice(index, 1);
                setState({...state, contact_mapping: temp_contact_mapping});
            }
        }
    }

    const  onClickHotelFeatures = (factIds) => {
        setState({ ...state, hotel_features_mapping: factIds } );
    }

    const  onClickFacility = (factIds) => {
        /*if(e.target.checked === true) {
            setState({ ...state, fact_mapping: [...state.fact_mapping, e.target.value] } );
        }else if (e.target.checked === false){
            let temp_fact_mapping = state.fact_mapping;
            let index = temp_fact_mapping.indexOf(e.target.value)
            if (index !== -1) {
                temp_fact_mapping.splice(index, 1);
                setState({...state, fact_mapping: temp_fact_mapping});
            }
        }*/

        setState({ ...state, fact_mapping: factIds } );
    }

    const onClickPhoto = (photoIds) => {

        if (photoIds.length === 0) {
            setState({...state, cover_photos: [] });
        }else{
            setState({...state, photo_mapping: photoIds});
        }
    }

    const onClickCoverPhoto = (coverPhoto) => {
        setState({...state, cover_photos: coverPhoto});
    }

    const redirectToPrice = () => {

        if(offerStoreStatus && state.redirect){
            history.push(`${OFFER_PRICE_SHORT}?id=${offerStoreData.id}&start_date=${state.start_date}&end_date=${state.end_date}`)
        }
    }

    return (
        <div>
            <OfferInfo offerReducer={offerReducer}
                       register={register}
                       errors={errors}
                       control={control}
                       watch={watch}
                       setValue={setValue}
                       getValues={getValues}
                       triggerValidation={triggerValidation}
                       clearError={clearError}
            />

            <ColAtm className="offer-list-col">
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-building room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-facilities-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Facilities offerReducer={offerReducer}
                                    onClickFacility={onClickFacility}
                                    fact_mapping={state.fact_mapping}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-hotel room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-amenities-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Amenities offerReducer={offerReducer}
                                   onClickHotelFeatures={onClickHotelFeatures}
                                   fact_mapping={state.hotel_features_mapping}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-id-card-alt room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-general-tab-employees')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Employees offerReducer={offerReducer}
                                   onClickEmployess={onClickEmployess}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-bed room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-room-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Rooms offerReducer={offerReducer}
                               onClickSelectRoom={onClickSelectRoom}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-concierge-bell room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-amenities-tab-accommodation')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Accommodation offerReducer={offerReducer}
                                       onClickAccommodationType={onClickAccommodationType}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-image room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-photos-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="offer-photos-detail room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Photos offerReducer={offerReducer}
                                onClickPhoto={onClickPhoto}
                                onClickCoverPhoto={onClickCoverPhoto}
                                photo_mapping={state.photo_mapping}
                                cover_photos={state.cover_photos}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-pen room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-offer-important_notes-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ImportantNotes control={control}
                                        name="important_notes"
                                        register={register}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-calendar-minus room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-offer-cancellation_policy-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="cancellation-policy-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <CancellationPolicy register={register}
                                            errors={errors}
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                            clearError={clearError}
                                            name="cancellation_policy"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </ColAtm>

            <ColAtm md={2} className="m-auto p-12">
                <ButtonAtm
                    className="offer-manage-price-button blue-button executive-list mb-16 text"
                    outline
                    fullwidth={true}
                    buttontext={t('btn-add_prices')}
                    onClick={handleSubmit(data => {

                        let params= {
                            "title"                  : data.title,
                            "email"                  : data.email,
                            "confirm_type"           : data.confirm_type,
                            "payment_list_type"      : data.payment_type_mapping_id,
                            "currency"               : data.currency,
                            "expire_date"            : data.expire_date,
                            "language"               : data.language,
                            "fact_mapping"           : state.fact_mapping,
                            "hotel_features_mapping" : state.hotel_features_mapping,
                            "contact_mapping"        : state.contact_mapping,
                            "room_mapping"           : state.room_mapping,
                            "accommodation_mapping"  : state.accommodation_mapping,
                            "photo_mapping"          : state.photo_mapping,
                            "cover_photos"           : state.cover_photos,
                            "important_notes"        : data.important_notes,
                            "has_cancellation_policy": data.has_cancellation_policy == 'true' ? true : false,
                            "cancellation_policy"    : data.cancellation_policy,
                        }

                        props.offerStore(params)
                        setState({ ...state, redirect: true } );
                    })}
                />
            </ColAtm>
            {
                redirectToPrice()
            }
        </div>
    );

}

export default OfferForm;
