import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import FooterAtm from "../../../atomic/Footer/Footer";


import {
    CONTENT,
    CONTENT_ROOM_PHOTOS,
    CONTENT_PLACES_FACTS
} from "../../../../constants/appUrls.constants";
import NewPlaces from "./NewPlaces";

import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import Loading from "../../../molecules/Loading/Loading";
import PlacesTypeFilter from "./PlacesTypeFilter";
import AddPlaceButton from "../../../organisms/AddPlaceButton/AddPlaceButton";
import ColAtm from "../../../atomic/Col/Col";
import {Animated} from "react-animated-css";
import PlacesMenu from "../../Global/Layout/Header/Submenu/Places";
import {getPlacesCategories,getPlaceList} from "../../../../redux/actions/Content/Places/placesActions"
import PlaceList from "./List";
import AddRoomButton from "../../../organisms/AddRoomButton/AddRoomButton";
import LabelAtm from "../../../atomic/Label/Label";
import {getRoomDescription} from "../../../../redux/actions/propertyRoomAndRateActions";

let t=null;

class Places extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlaceCategoryId : 0,
            selectedRoomRateId : 0,
            roomAddModalStatus : false,
            redirect: false,
            showSuccessHeader: false,
        };
        t = props.t;
    }

    onChangePlaceFilter = (selectedIndex) => {
        this.setState({
            selectedPlaceCategoryId : selectedIndex,
        });
    };

    componentDidMount() {
        this.props.getPlaceList();
        this.props.getPlacesCategories();
        this.props.getRoomDescription();
    }

    onClickNextButton = () => {
        this.setState({
            redirect: true,
            showSuccessHeader:true
        })

    };

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={CONTENT}
                               exitText={ t('btn-dashboard')}
                               text= { t('enw-content-places-page_title') }
                               showSuccessHeader={this.state.showSuccessHeader}
        />
    };

    render() {

        return (
            <>
                <PageTitle title={ t('enw-content-places-page_title') } />
                {
                    this.headerStatusShow()
                }

                { this.props.placeListFetching ? (

                    <Loading />

                ) : (
                    <>
                        { this.props.placeList && this.props.placeList.length < 1 ? (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width">
                                        <ColAtm>
                                            <Container maxWidth="xl">
                                                <div className="offer-container-width">
                                                    <ColAtm>
                                                        <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                            <ColAtm>
                                                                <div className="m-auto first-create-room first-places-btn">
                                                                <span>
                                                                    <AddRoomButton
                                                                        roomMenuItem={
                                                                            <NewPlaces
                                                                                roomAddModalStatus={this.state.roomAddModalStatus}
                                                                                descriptionData={this.props.descriptionData}
                                                                            />

                                                                        }
                                                                    />
                                                                </span>
                                                                </div>
                                                            </ColAtm>
                                                            <LabelAtm labelClass="one-page-title" text={ t('enw-landing_create_place-subtitle') } />
                                                            <LabelAtm labelClass="one-page-subtitle" text={ t('enw-landing_create_place-desc') } />
                                                        </ColAtm>
                                                    </ColAtm>
                                                </div>
                                            </Container>
                                        </ColAtm>
                                    </div>
                                </Container>
                            </Animated>
                        ) : (
                            <>
                                <PlacesMenu
                                    createPlacesColClass="active-menu-bg"
                                />
                                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                    <Container maxWidth="xl">
                                        <div className="container-width">
                                            <Grid container spacing={2} className="photoButtons room-rate-filter-group">
                                                <Grid item className="type-plan-buttons">
                                                    { this.props.propertyRoomTypesFetching ? (
                                                        <Loading />
                                                    ) : (
                                                        <PlacesTypeFilter onChangePlaceFilter={this.onChangePlaceFilter}/>
                                                    )}
                                                </Grid>
                                                <Grid item className="photoButtonsRow">
                                                    <AddPlaceButton
                                                        placeMenuItem={
                                                            <NewPlaces
                                                                roomAddModalStatus={this.state.roomAddModalStatus}
                                                                descriptionData={this.props.descriptionData}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <ColAtm className="room-rate-list-col">
                                                { !this.props.placeListFetching &&
                                                <PlaceList selectedPlaceCategoryId={this.state.selectedPlaceCategoryId} />
                                                }
                                            </ColAtm>
                                        </div>
                                    </Container>
                                </Animated>
                                <FooterAtm
                                    buttonBackText={ t('btn-back') }
                                    iconRightClassName="fas fa-arrow-right"
                                    buttonBackStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                    buttonNextStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                    nextTo={CONTENT_PLACES_FACTS}
                                    component={Link}
                                    nextComponent={Link}
                                    buttonNextText={ t('btn-next') }
                                    iconLeftClassName="fas fa-arrow-left"
                                    backTo={CONTENT_ROOM_PHOTOS}
                                    nextDisabled={this.state.showSuccessHeader}
                                    onClick={this.onClickNextButton}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        )
    }
}
const mapStateToProps = (props) => {
    return{
        placeList : props.getPlaceListReducer.placeList,
        placeListFetching : props.getPlaceListReducer.fetching,
        descriptionData : props.propertyRoomAndRateReducers.descriptionData.data,
    }
};

const mapDispatchToProps = {
    getPlacesCategories,
    getPlaceList,
    getRoomDescription
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Places));
