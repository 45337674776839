import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";

import Rates from "./Rates";
import {withTranslation} from "react-i18next";
import ColAtm from "../../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../../atomic/Label/Label";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import {updateChannelPricingClearState} from "../../../../../../redux/actions/Network/Channel/channelPaxPricing"
let t=null

class ChannelPaxList extends Component {

    constructor(props){
        super(props);
        t = props.t;
        this.state = {
            pax_pricing_policy: [{}],
            currency: this.props.channelInformationData.currency,
            channel_id: this.props.channelInformationData.id,
        };
    }

    componentWillUnmount() {
        this.props.updateChannelPricingClearState();
    }

    showMessage = () => {

        if(this.props.updateChildPricingPolicyError?.response?.status === 400)
        {
            let message = this.props.updateChildPricingPolicyError?.response.data.message
            return  <ErrorModal status={true} message={t(message)}/>
        }

        if(this.props.updateAdultPricingPolicyError?.response?.status === 400)
        {
            let message = this.props.updateAdultPricingPolicyError?.response.data.message
            return  <ErrorModal status={true} message={t(message)}/>
        }
    }


    render() {
        return (
            <div>
                {
                    this.props.channelPaxPricingList.map( (room, key) => (
                        <ExpansionPanel className="accordion-room-table" key={key} defaultExpanded>
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-caret-down" />}
                                aria-label="Expand">
                                <ColAtm>
                                    <ColAtm md={10} xl={11}>
                                        <i className="fas fa-bed room-rate-bed ml-16" />
                                        <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelSummary>
                            <Rates roomRates={room.property_room_rate_mapping}
                                   roomId={room.id}
                                   channelId={this.props.channelId}
                                   channelInformationData={this.props.channelInformationData}
                                   updateChannelAdultPricing={this.props.updateChannelAdultPricing}
                                   updateChannelChildPricing={this.props.updateChannelChildPricing}
                                   updateAdultPricingStatus={this.props.updateAdultPricingStatus}
                                   updateChildPricingStatus={this.props.updateChildPricingStatus}
                                   errorUpdateChild={this.props.errorUpdateChild}
                                   errorUpdateAdult={this.props.errorUpdateAdult}
                                   getChannelPaxList={this.props.getChannelPaxList}
                                   clearErrorMessage = {this.props.clearErrorMessage}
                                   currency={this.state.currency}

                            />
                        </ExpansionPanel>
                    ))
                }
                {
                    this.showMessage()
                }
            </div>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        updateChildPricingPolicyError : props.updatePersonPricingReducer?.error,
        updateAdultPricingPolicyError : props.updatePersonPricingReducer?.error,
    }
};

export default compose(withTranslation('common'))(connect(mapStateToProps, {updateChannelPricingClearState } )(ChannelPaxList));
