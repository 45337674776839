import React        from 'react';
import PropTypes    from 'prop-types';

import ColAtm       from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";
import MultiCheckboxAtm from "../../atomic/MultiCheckbox/MultiCheckbox";


const MultiPhotoSelect = props => (
    <div className={props.colClassname} htmlFor={props.htmlFor}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} htmlFor={props.htmlFor}>
            <MultiCheckboxAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                checkboxId={props.checkboxId}
                value={props.value}
                labelPlacement={props.labelPlacement}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
            />
            <ColAtm htmlFor={props.htmlFor}>
                <ColAtm htmlFor={props.htmlFor}>
                    <ColAtm className="channel-logo" htmlFor={props.htmlFor}>
                        <label htmlFor={props.htmlFor}>
                            <i className="fas fa-check" />
                            <img src={props.src} htmlFor={props.htmlFor} alt={props.name} />
                        </label>
                    </ColAtm>
                </ColAtm>
            </ColAtm>
        </BlockAtm>
    </div>
);

MultiPhotoSelect.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    src: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
};

export default MultiPhotoSelect;
