export const reservationListConstants = {
    RESERVATION_LIST_PENDING:   'RESERVATION_LIST_PENDING',
    RESERVATION_LIST_FULFILLED:   'RESERVATION_LIST_FULFILLED',
    RESERVATION_LIST_REJECTED:   'RESERVATION_LIST_REJECTED',
};
export const reservationExcelConstants = {
    RESERVATION_EXCEL_PENDING:   'RESERVATION_EXCEL_PENDING',
    RESERVATION_EXCEL_FULFILLED:   'RESERVATION_EXCEL_FULFILLED',
    RESERVATION_EXCEL_REJECTED:   'RESERVATION_EXCEL_REJECTED',
    RESERVATION_EXCEL_CLEAR_STATE:   'RESERVATION_EXCEL_CLEAR_STATE',
};

export const reservationListFilterConstants = {
    LIST_FILTER_PENDING: 'LIST_FILTER_PENDING',
    LIST_FILTER_FULFILLED: 'LIST_FILTER_FULFILLED',
};

export const reservationDetailConstants = {
    RESERVATION_DETAIL_PENDING:   'RESERVATION_DETAIL_PENDING',
    RESERVATION_DETAIL_FULFILLED:   'RESERVATION_DETAIL_FULFILLED',
    RESERVATION_DETAIL_REJECTED:   'RESERVATION_DETAIL_REJECTED',
};


export const getTicketConstants = {
    GET_TICKET_PENDING: 'GET_TICKET_PENDING',
    GET_TICKET_FULFILLED: 'GET_TICKET_FULFILLED',
    GET_TICKET_REJECTED: 'GET_TICKET_REJECTED',
};

export const createTicketConstants = {
    CREATE_TICKET_PENDING: 'CREATE_TICKET_PENDING',
    CREATE_TICKET_FULFILLED: 'CREATE_TICKET_FULFILLED',
    CREATE_TICKET_REJECTED: 'CREATE_TICKET_REJECTED',
    CREATE_TICKET_CLEAR : 'CREATE_TICKET_CLEAR'
};


export const updateTicketConstants = {
    UPDATE_TICKET_PENDING: 'UPDATE_TICKET_PENDING',
    UPDATE_TICKET_FULFILLED: 'UPDATE_TICKET_FULFILLED',
    UPDATE_TICKET_REJECTED: 'UPDATE_TICKET_REJECTED',
    UPDATE_TICKET_CLEAR : 'UPDATE_TICKET_CLEAR'
};


export const createPaymentCodeConstants = {
    CREATE_PAYMENT_CODE_PENDING: 'CREATE_PAYMENT_CODE_PENDING',
    CREATE_PAYMENT_CODE_FULFILLED: 'CREATE_PAYMENT_CODE_FULFILLED',
    CREATE_PAYMENT_CODE_REJECTED: 'CREATE_PAYMENT_CODE_REJECTED',
    CREATE_PAYMENT_CODE_CLEAR : 'CREATE_PAYMENT_CODE_CLEAR'
};

export const sendBookingMailConstants = {
    RESERVATION_UPDATE_MAIL_PENDING:  'RESERVATION_UPDATE_MAIL_PENDING',
    RESERVATION_UPDATE_MAIL_FULFILLED:  'RESERVATION_UPDATE_MAIL_FULFILLED',
    RESERVATION_UPDATE_MAIL_REJECTED:  'RESERVATION_UPDATE_MAIL_REJECTED',
    RESERVATION_UPDATE_MAIL_CLEAR : 'RESERVATION_UPDATE_MAIL_CLEAR'
};