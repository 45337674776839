import {reservationDetailConstants } from "../../../../../constants/Network/reservation.constants";

const initialState = {
    fetching :false,
    reservationList: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case reservationDetailConstants.RESERVATION_DETAIL_PENDING:
            return {
                fetching:true,
                error: {},
            };
        case reservationDetailConstants.RESERVATION_DETAIL_FULFILLED:
            return {
                ...state,
                fetching : false,
                reservation: action.payload,
                error: {},
            };
        case reservationDetailConstants.RESERVATION_DETAIL_REJECTED:
            return {
                ...state,
                reservation: [],
                error: action.payload,
                fetching : false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
