import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {DOWNLOAD_CONTENT_PHOTOS} from "../../../../constants/api.constants";
import axios                            from 'axios';

export function downloadContentPhotos(photos) {

    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
            authToken: getUserToken(),
            language : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                custom : photos
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'DOWNLOAD_CONTENT_PHOTOS',
            payload: axios.post(DOWNLOAD_CONTENT_PHOTOS, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

