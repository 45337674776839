import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import { PLACE_FACT_LIST, PLACE_FACT_UPDATE} from "../../../../constants/api.constants";

export function PlacesFactsListGet(placeId) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                place_id : placeId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PLACE_FACT_LIST',
            payload: axios.post(PLACE_FACT_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function PlaceFactUpdate(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                place_id : params.place_id,
                place_fact_title_fact_mapping_id : params.place_fact_title_fact_mapping_id,
                is_selected : params.is_selected
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PLACE_FACT_UPDATE',
            payload: axios.post(PLACE_FACT_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data?.status )
        })
    }
}

export function placeFactUpdateClear() {
    return dispatch => {
        dispatch({
            type: 'PLACE_FACT_UPDATE_CLEAR'
        })
    }
}
