import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    UPDATE_EDIT_CONTENT_WEB
} from "../../../../constants/api.constants";


export function updatePreviewEditContentWeb(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                property_web_id : params.property_web_id,
                action :  "preview",
                photo_mapping : params.photo_mapping,
                cover_photos : params.cover_photos,
                languages : params.languages,
                menus: params.menuPostData,
                colors: params.colors,
                rooms: params.rooms,
                about_us: params.aboutUs,
                weather_active: params.weather_active
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_EDIT_CONTENT_WEB',
            payload: axios.post(UPDATE_EDIT_CONTENT_WEB, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateEditContentWeb(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                property_web_id : params.property_web_id,
                action :  "publish",
                photo_mapping : params.photo_mapping,
                cover_photos : params.cover_photos,
                languages : params.languages,
                menus: params.menuPostData,
                colors: params.colors,
                rooms: params.rooms,
                about_us: params.aboutUs,
                additional_info: {
                    myweb_contact_email: params.myweb_contact_email,
                    kvkk_mersis_no: params.kvkk_mersis_no,
                    kvkk_data_controller: params.kvkk_data_controller,
                    kvkk_contact_person: params.kvkk_contact_person,
                    kvkk_address: params.kvkk_address,
                    kvkk_email: params.kvkk_email,
                    kvkk_phone: params.kvkk_phone,
                    kvkk_tax_number: params.kvkk_tax_number,
                    kvkk_tax_office: params.kvkk_tax_office,
                    kvkk_web_site: params.kvkk_web_site,
                    kvkk_contact_email: params.kvkk_contact_email,
                    kvkk_contact_address: params.kvkk_contact_address,
                },
                weather_active: params.weather_active
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_EDIT_CONTENT_WEB',
            payload: axios.post(UPDATE_EDIT_CONTENT_WEB, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
