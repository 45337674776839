import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    NETWORK_CHANNEL_GROUP_GET, NETWORK_CHANNEL_GROUP_ADD, NETWORK_CHANNEL_GROUP_UPDATE, NETWORK_CHANNEL_GROUP_CREATE_DATA
} from "../../../../constants/api.constants";


export function getChannelGroupChannels() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_GET',
            payload: axios.post(NETWORK_CHANNEL_GROUP_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getChannelGroupChannelsSingle(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_SINGLE_CHANNEL_GROUP_GET',
            payload: axios.post(NETWORK_CHANNEL_GROUP_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function addChannelGroupChannels(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                name: params.name,
                channels: params.channels

            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_ADD',
            payload: axios.post(NETWORK_CHANNEL_GROUP_ADD, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelGroupChannels(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                name: params.name,
                group_id: params.group_id,
                channels: params.channels

            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_UPDATE',
            payload: axios.post(NETWORK_CHANNEL_GROUP_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function channelGroupCreateData() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_CREATE_DATA',
            payload: axios.post(NETWORK_CHANNEL_GROUP_CREATE_DATA, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function addChannelGroupClear() {
    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_ADD_CLEAR_STATE',
        })
    }
}


export function updateChannelGroupClear() {
    return dispatch => {
        dispatch({
            type: 'NETWORK_CHANNEL_GROUP_UPDATE_CLEAR_STATE',
        })
    }
}
