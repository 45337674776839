import React, {Component}   from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import OfferListTable from "./components/OfferListTable";
import {getOfferList} from "../../../../redux/actions/offerListActions";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import ButtonAtm from "../../../atomic/Button/Button";
import {OFFER, OFFER_CREATE} from "../../../../constants/appUrls.constants";
let t=null

class OfferList extends Component {

    constructor(props){
        super(props);
        this.state = {
            offerList : null
        };
        t = props.t;
    }

    componentDidMount() {
         this.props.getOfferList();
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-offer_list-page_title') } />
                <PageHeaderAtm exitText={ t('btn-dashboard')} backButton={OFFER} text={ t('enw-offer_list-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width pb-0">
                            <Box component="span" mb={3}  className="float-right create-offer">
                                <Link to={OFFER_CREATE} style={{ textDecoration: 'none' }}>
                                    <ButtonAtm
                                        buttontext={ t('btn-create_offer') }
                                        className="filter-action-button"
                                        startIcon={<i className="fas fa-plus" />}
                                    />
                                </Link>
                            </Box>
                            <ColAtm className="booking-list">
                                {
                                    !this.props.fetching &&
                                        this.props.get_offer_list && <OfferListTable offerList = {this.props.get_offer_list}/>
                                }
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return{

        fetching : state.offerListReducer.fetching,
        updateStatus : state.offerListReducer.updateStatus,
        errorUpdateStatus : state.offerListReducer.errorUpdateStatus,
        errorUpdateError : state.offerListReducer.errorUpdateError,
        get_offer_list : state.offerListReducer.get_offer_list,
    }
};

const mapDispatchToProps = {
    getOfferList
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(OfferList));
