import {roomPhoto} from "../../../../constants/Network/RoomAndRates/roomPhoto.constants";

const initialState = {
    fetching : false,
    status : false,
    roomPhotoList : [],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case roomPhoto.ROOM_PHOTO_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case roomPhoto.ROOM_PHOTO_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                roomPhotoList: action.payload
            };
        case roomPhoto.ROOM_PHOTO_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                status: false
            };
        case roomPhoto.ROOM_PHOTO_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case roomPhoto.ROOM_PHOTO_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
            };
        default:
            return state
    }
}
