import React from "react";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";

import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";

import InventorySkeleton from "../../../../molecules/InventorySkeleton/InventorySkeleton";
import {useTranslation} from "react-i18next";
import QuickPricingModal from "./QuickPricingModal";
import QuickPricingRooms from "./QuickPricing/Rooms";

const QuickPricing =  (props) => {
    const { t } = useTranslation();

    const {
        allDays,
        channelId,
        roomPrice,
        getQuickPricing,
        getPropertyInventory,
        fetching,
        channelAvailability,
        isChangePriceStatus,
        changePriceStatus,
        updatePropertyInvertory,
    } = props;

    return (
        <>
            {
                getQuickPricing?.fetching ?
                    <InventorySkeleton/>
                    :
                    <>
                        {
                            getQuickPricing?.getQuickPricing?.rooms?.length > 0 ?
                                <ColAtm className="inventory-box">
                                    <ColAtm className="inventory-room-box">
                                        <ColAtm xs={3} lg={4} className="align-self-center">
                                            <BlockAtm md={1} className="m-h-auto">
                                                <QuickPricingModal
                                                    type="edit"
                                                    roomPrice={roomPrice}
                                                    channelId={channelId}
                                                    getData={getQuickPricing?.getQuickPricing?.rooms}
                                                />
                                            </BlockAtm>
                                            <BlockAtm xs={6}>
                                                <LabelAtm labelClass="inventory-room-name" text={ t('enw-quick-price_price-title')} />
                                            </BlockAtm>
                                            <Grid item xs={4} />
                                        </ColAtm>
                                        <ColAtm xs={8} className="inventory-date-padding pos-relative" />
                                    </ColAtm>
                                    <QuickPricingRooms
                                        roomPrice={roomPrice}
                                        allDays={allDays}
                                        getPropertyInventory={getPropertyInventory}
                                        updatePropertyInvertory={updatePropertyInvertory}
                                        changePriceStatus={changePriceStatus}
                                        isChangePriceStatus={isChangePriceStatus}
                                        channelAvailability={channelAvailability}
                                        fetching={fetching}
                                        channelId={channelId}
                                    />

                                </ColAtm>
                                :
                                <Grid container spacing={3} className="mt-60 web-dashboard centered-position">
                                    <Container maxWidth="xl">
                                        <div className="container-width">
                                            <ColAtm xs={12} className="photoButtonsRow one-page-content">
                                                <ColAtm>
                                                    <div className="m-auto first-create-room">
                                                        <QuickPricingModal
                                                            roomPrice={roomPrice}
                                                            channelId={channelId}
                                                            getData={getQuickPricing?.getQuickPricing?.rooms}
                                                        />
                                                    </div>
                                                </ColAtm>
                                                <LabelAtm labelClass="one-page-title" text={ t('btn-create_quick-price-first-title') } />
                                                <LabelAtm labelClass="one-page-subtitle p-0-100" text={ t('btn-create_quick-price-first-desc') } />
                                            </ColAtm>
                                        </div>
                                    </Container>
                                </Grid>
                        }
                    </>
            }
        </>
    )
}

export default QuickPricing;
