import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../atomic/Button/Button";
import PlacesModal from "./PlacesModal";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {getPlaceCategoryFieldsUpdateClearStatus} from "../../../../redux/actions/Content/Places/placesActions";

let t=null

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class NewPlaces extends React.Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    state = {
        open : false
    };

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClose = () => {
        this.setState({open:false});
        this.props.getPlaceCategoryFieldsUpdateClearStatus()
    };

    render() {
        return (
            <>
                <ButtonAtm
                    buttontext={ t('btn-new_place') }
                    className="filter-action-button float-right"
                    startIcon={<i className="fas fa-plus-circle" />}
                    onClick={this.handleClickOpen}
                />
                <Dialog onClose={this.handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={this.handleClose}>
                        { t('enw-content-place-add_place-title') }
                    </DialogTitle>
                    <DialogContent>
                        <PlacesModal
                            modalClose={this.handleClose}
                            descriptionData={this.props.descriptionData}
                        />
                    </DialogContent>
                </Dialog>
            </>
        )
    }

}

const mapStateToProps = (props) => {

    return {

    }
};

const mapDispatchToProps = {
    getPlaceCategoryFieldsUpdateClearStatus
};
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(NewPlaces));
