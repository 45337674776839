export const paymentTransactionListConstants = {
    PAYMENT_TRANSACTION_LIST_PENDING  : 'PAYMENT_TRANSACTION_LIST_PENDING',
    PAYMENT_TRANSACTION_LIST_FULFILLED: 'PAYMENT_TRANSACTION_LIST_FULFILLED',
    PAYMENT_TRANSACTION_LIST_REJECTED : 'PAYMENT_TRANSACTION_LIST_REJECTED',
};

export const paymentManualTransactionListConstants = {
    PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_PENDING  : 'PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_PENDING',
    PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_FULFILLED: 'PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_FULFILLED',
    PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_REJECTED : 'PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_REJECTED',
};
