import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {fillOnUndefinedObject, getFactByCategoryName} from "../../../utils";
import {Button} from "@material-ui/core";
import ColAtm from "../../atomic/Col/Col";
import FactCheckBox from "../../molecules/FactContent/FactContent";

const Fact = (props) => {

    const { t } = useTranslation();
    const {fact, onClickFacility, checked} = props;

    return(
        <FactCheckBox
            checkboxId={fact.id+""+fact.mapping_id}
            className="fact-box"
            colClassname="offer-fact-col"
            blockClass="offer-fact-border-box fact-border-box"
            labelIconClass="fact-icon-label"
            iconClass={`offer-fact-icon fact-icon ${fact.icon}`}
            value={fact.id}
            md={2}
            key={fact.id+""+fact.mapping_id}
            data-sort={ t(fact.language_key) }
            text={ t(fact.language_key) }
            tooltip={ t(fact.language_key) }
            labelClass="fact-name"
            htmlFor={fact.id+""+fact.mapping_id}
            checked={ checked }
            onClick={onClickFacility}
        />
    )
}

const Amenities = ( props ) => {
    const { t } = useTranslation();

    const {offerReducer, fact_mapping } = props;
    const tmpFact = fillOnUndefinedObject(offerReducer,'get_facts');
    const factNames = getFactByCategoryName(tmpFact,'44');
    const [selectedFactCategory, setSelectedFactCategory] = useState('all');
    const getFacts = getFactByCategory(offerReducer, '44',selectedFactCategory);

    const [tempFactMapping, setTempFactMapping] = useState(fact_mapping)
    const [showSelectAll, setShowSelectAll] = useState(false)

    const onClickSelectAll = () => {
        let factId = [];

        if( selectedFactCategory === 'all' )
        {
            factId = getFacts.map( (fact) => fact.id )
        }
        else {
            factId = getFacts.filter( fact => fact.parent_id == selectedFactCategory).map( factMap => factMap.id );
        }
        setShowSelectAll(false);
        addAllCheckboxState(factId)
    }


    const onClickDeSelectAll = () => {

        let factId = [];
        if( selectedFactCategory === 'all' ) {
            factId = [];
            deleteAllCheckboxState(factId)
        }
        else {
            factId = getFacts.filter( fact => fact.parent_id == selectedFactCategory).map( factMap => factMap.id );
            deleteAllCheckboxState(factId)
        }
        setShowSelectAll(true);
    }

    const onClickFacility = (e) => {
        if(e.target.checked === true)
        {
            addCheckboxState(e.target.value)
        }
        else if (e.target.checked === false){
            deleteCheckboxState(e.target.value)
            setShowSelectAll(true);
        }
    }

    const deleteCheckboxState = (factId) => {

        let temp = tempFactMapping;
        let index = temp.indexOf(parseInt(factId));

        if ( index > -1 ) {
            temp.splice(index, 1);
            setTempFactMapping(temp);
            props.onClickFacility(temp);
        }
    }

    const deleteAllCheckboxState = (factIds) => {
        if(factIds.length > 0 ){

            let temp = tempFactMapping;

            factIds.map( item => {
                let index = temp.indexOf(parseInt(item));
                if ( index > -1 ) {
                    temp.splice(index, 1);
                }
            });
            setTempFactMapping(temp);
            props.onClickFacility(temp);
        }else{
            setTempFactMapping([]);
            props.onClickFacility([]);
        }


    }

    const addAllCheckboxState = (factIds) => {

        if(factIds.length > 0 )
        {
            let temp = tempFactMapping;

            factIds.map( item => {
                let index = temp.indexOf(parseInt(item));
                if ( index === -1 ) {
                    temp.push(item);
                }
            });

            setTempFactMapping(temp);
            props.onClickFacility(temp);
        }
    }

    const addCheckboxState = (factId) => {

        let temp = [...tempFactMapping, parseInt(factId)];

        setTempFactMapping(temp);
        props.onClickFacility(temp);
    }

    useEffect( () => {

    },[])

    return(
        <>
            <ColAtm>
                <ColAtm className="fact-categories">
                    <ColAtm md={11}>
                        <Button className={(selectedFactCategory === "all" ? 'active-fact-button' : '')} onClick={() => setSelectedFactCategory('all')}> { t('enw-tab-all') } </Button>
                        {
                            Object.keys(factNames).length > 0 ?
                                factNames.sort((a, b) => t(a.priority) > t(b.priority ) ? 1:-1).map( (factName) => (
                                    <Button className={(selectedFactCategory === factName.id ? 'active-fact-button' : '')}
                                            key={factName.id}
                                            onClick={ () => setSelectedFactCategory(factName.id)}
                                    >
                                        {t(factName.name)}
                                    </Button>
                                ))
                                :
                                <h2>Data not Found!</h2>
                        }
                    </ColAtm>
                    <ColAtm className="select-all-fact-offer" md={1}>
                        <Button className={showSelectAll ? "select-all-fact" : "d-none"}  onClick={ onClickSelectAll }> { t('btn-deselect_all') } </Button>
                        <Button className={!showSelectAll ? "de-select-all-fact" : "d-none"}  onClick={ onClickDeSelectAll } > { t('btn-select_all') } </Button>
                    </ColAtm>

                </ColAtm>
                <>
                    {
                        getFacts.filter(  (filterFact) =>  ( selectedFactCategory !== 'all' ? filterFact.parent_id == selectedFactCategory : true ) ).sort((a, b) => t(a.name) > t(b.name ) ? 1:-1).map( fact => (

                            <Fact  fact={fact}
                                   onClickFacility={onClickFacility}
                                   checked={tempFactMapping.indexOf(fact.id) > -1  ? true : false}
                            />
                        ))
                    }
                </>
            </ColAtm>

        </>
    )
}

export default Amenities;

const getFactByCategory = ( data , factId,factSubId = 'all' ) => {
    let tempData = [];
    if(data !== undefined && data !== null){
        for (const dataKey of Object.keys(data)) {
            if( dataKey === 'get_facts'){
                for(const facId of Object.keys( data[dataKey] ) ){
                    if(facId === factId || facId === factId){
                        for(const facSubId of Object.keys  (data[dataKey][facId].fact_subcategory) ){
                            if (facSubId == factSubId){
                                data[dataKey][facId].fact_subcategory[facSubId].fact.map(fact => tempData.push(fact) )
                            }else if(factSubId === 'all'){
                                data[dataKey][facId].fact_subcategory[facSubId].fact.map(fact => tempData.push(fact) )
                            }
                        }
                    }
                }
            }
        }
    }
    return tempData;
}
