import { getUserToken,getProperty ,getAppLang}                 from "../../utils/index";
import { GET_PROPERTY,UPDATE_PROPERTY }                 from "../../constants/api.constants";
import axios                            from 'axios';

export function getPropertyInfo() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "locale": getAppLang(),
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GENERAL_INFORMATION',
            payload: axios.post(GET_PROPERTY, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updatePropertyGeneralInformation(params) {

    //TODO : General Information'a api güncellendikten sonra eklenmesi gereken 'additional_info' lar var.
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                property_info:
                    {
                        name: params.name,
                        property_type_id:params.property_type_id,
                        chain_id:  params.chain_id,
                        country: params.country
                    },
                has_locale_name: params.has_locale_name,
                property_language_spoken: params.property_language_spoken,
                additional_info:
                    {
                        year_construction: params.year_construction,
                        year_renovation: params.year_renovation,
                        number_of_rooms: params.number_of_rooms,
                        checkin_time: params.checkin_time,
                        checkout_time: params.checkout_time,
                        locale_name_language : params.locale_name_language,
                        locale_name: params.locale_name,
                        pet_allowed: params.pet_allowed,
                        disability_friendly: params.disability_friendly,
                        minimum_age_policy: params.minimum_age_policy,
                        min_self_check_in_age: params.min_self_check_in_age,
                        property_timezone: params.property_timezone
                    },
                property_rate_for : "Content.General.Information"
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_GENERAL_INFORMATION',
            payload: axios.post(UPDATE_PROPERTY, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data )
        })
    }
}
