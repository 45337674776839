export const inventoryConstants = {
    PROPERTY_GROUP_INVENTORY_GET_PENDING  : 'PROPERTY_GROUP_INVENTORY_GET_PENDING',
    PROPERTY_GROUP_INVENTORY_GET_FULFILLED: 'PROPERTY_GROUP_INVENTORY_GET_FULFILLED',
    PROPERTY_GROUP_INVENTORY_GET_REJECTED : 'PROPERTY_GROUP_INVENTORY_GET_REJECTED',

    PROPERTY_INVENTORY_UPDATE_OR_CREATE_PENDING  : 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_PENDING',
    PROPERTY_INVENTORY_UPDATE_OR_CREATE_FULFILLED: 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_FULFILLED',
    PROPERTY_INVENTORY_UPDATE_OR_CREATE_REJECTED : 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_REJECTED',

    PROPERTY_BULK_UPDATE_PENDING    : 'PROPERTY_BULK_UPDATE_PENDING',
    PROPERTY_BULK_UPDATE_FULFILLED  : 'PROPERTY_BULK_UPDATE_FULFILLED',
    PROPERTY_BULK_UPDATE_REJECTED   : 'PROPERTY_BULK_UPDATE_REJECTED',

    GET_PROPERTY_BULK_UPDATE_PENDING    : 'GET_PROPERTY_BULK_UPDATE_PENDING',
    GET_PROPERTY_BULK_UPDATE_FULFILLED  : 'GET_PROPERTY_BULK_UPDATE_FULFILLED',
    GET_PROPERTY_BULK_UPDATE_REJECTED   : 'GET_PROPERTY_BULK_UPDATE_REJECTED',

    PROPERTY_BULK_UPDATE_CLEAR : 'PROPERTY_BULK_UPDATE_CLEAR'
};
