import { getUserToken, getAppLang}                 from "../../utils/index";
import {GET_ALL_LANGUAGES} from "../../constants/api.constants";
import axios                            from 'axios';

export function getLanguages() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), language : getAppLang()}
    };

    return dispatch => {
        dispatch({
            type: 'ALL_LANGUAGES',
            payload: axios.get(GET_ALL_LANGUAGES, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

