import React, {Component}   from 'react';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {PAYMENT} from "../../../../constants/appUrls.constants";
import {connect} from "react-redux";
import {getUpdateManualPaymentData, updateManualPayment} from "../../../../redux/actions/Payment/PaymentCreate.actions";
import UpdateManualPaymentForm from "./updateManualPaymentForm";

let t =null;

class UpdateManualPayment extends Component {
    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";

        if ( this.props.match.params.id )
        {
            this.props.getUpdateManualPaymentData( this.props.match.params.id );
            this.setState({
                paymentId: this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/payment');
        }
    }

    render() {

        const {paymentManualUpdateReducers} = this.props;

        return (
            <>
                <PageTitle title={ t('enw-payment-update_manual_payment-page_title') }  />
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={PAYMENT} text={ t('enw-payment-update_manual_payment-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" className="border-top-red-border">
                        <div className="container-width">
                            {
                                !paymentManualUpdateReducers.getUpdateFetching &&
                                <UpdateManualPaymentForm getUpdateData={paymentManualUpdateReducers.getUpdateData}
                                                         updateManualPayment={this.props.updateManualPayment}
                                                         updateError={paymentManualUpdateReducers.updateError}
                                                         updateFormData={paymentManualUpdateReducers.updateData}
                                                         updateFetching={paymentManualUpdateReducers.updateFetching}
                                />
                            }

                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return{
        paymentManualUpdateReducers: props.paymentManualUpdateReducers
    }
};

const mapDispatchToProps = {
    getUpdateManualPaymentData,
    updateManualPayment
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps)(UpdateManualPayment));
