import React, {useEffect} from 'react';
import {Controller} from "react-hook-form";

import InputAtm from "../../../../atomic/Input/Input";
import ColAtm from "../../../../atomic/Col/Col";
import Grid from "@material-ui/core/Grid";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useTranslation} from "react-i18next";
import {formattedMonth, getAppLang, isDefined} from "../../../../../utils";

import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";

const localeMap = {
    es: esLocale,
    de: deLocale,
    tr: trLocale
};

const dateFormaded = (date) => {
    let tempDate = date.getFullYear() + "-" + ( formattedMonth(date.getMonth()+1) ) + "-" +formattedMonth( date.getDate());
    return tempDate
}

const  useOnMount = (handler) =>    {
    return useEffect(handler,[]);
}

const OfferInfo = (props) => {
    const { t } = useTranslation();
    const {register, errors, control, offerReducer, watch, setValue,triggerValidation, clearError} = props;
    const languages = offerReducer.hasOwnProperty("languages") &&
    Object.keys(offerReducer.languages).length > 0 ? offerReducer.languages :[];
    const currencies = offerReducer.hasOwnProperty("currencies") &&
    Object.keys(offerReducer.currencies).length > 0 ? offerReducer.currencies : [];
    const confirmType = offerReducer.hasOwnProperty("offer_confirm_type") &&
    Object.keys(offerReducer.offer_confirm_type).length > 0 ? offerReducer.offer_confirm_type : [];
    const paymentListType = offerReducer?.hasOwnProperty("payment_list_type") &&
    Object.keys(offerReducer.payment_list_type).length > 0 ? offerReducer.payment_list_type : [];

    const expire_date = watch("expire_date");

    useOnMount(() => {
        register(
            { name: "expire_date", type: "text" },
            {  required: t("enw-notification_this_is_required")  }
        );
    });


    return(
        <div>
            <form>
                <ColAtm className="offer-info-block">
                    <Grid item xs={12} container spacing={3}>
                        <ColAtm xs={12} md={6}>
                            <InputAtm
                                name="title"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="property_name"
                                label={t('enw-input-title')}
                                autoComplete="off"
                                autoFocus
                                error={!!errors.title}
                                inputRef={register({
                                    required: t("enw-notification_this_is_required"),
                                })}
                            />
                            <FormHelperText error={!!errors.title}>
                                {errors.title && errors.title.message}
                            </FormHelperText>
                        </ColAtm>
                        <ColAtm xs={12} md={3}>
                            <InputAtm
                                name="email"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="property_name"
                                label={t('enw-input-email')}
                                autoComplete="off"
                                autoFocus
                                error={!!errors.email}
                                inputRef={register({
                                    required: t("enw-notification_this_is_required"),
                                })}
                            />
                            <FormHelperText error={!!errors.email}>
                                {errors.email && errors.email.message}
                            </FormHelperText>
                        </ColAtm>
                        <ColAtm md={3} className="form-group general-select">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t('enw-input-language')}</InputLabel>
                                <Controller
                                    as={
                                        <Select
                                            className="form-control"
                                            label={t('enw-input-language')}
                                        >
                                            {
                                                languages.map( language => (
                                                    <MenuItem value={language.code} key={language.id}>
                                                        { t(language.language_key) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    }
                                    name="language"
                                    rules={{ required: t("enw-notification_this_is_required") }}
                                    control={control}
                                    defaultValue=""
                                    error={!!errors.language}
                                />
                            </FormControl>
                            <FormHelperText error={!!errors.language}>
                                {errors.language && errors.language.message}
                            </FormHelperText>
                        </ColAtm>
                        <ColAtm md={3} className="form-group general-select">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t('enw-input-currency')}</InputLabel>
                                <Controller
                                    as={
                                        <Select
                                            className="form-control"
                                            label={t('enw-input-currency')}
                                        >
                                            {
                                                currencies.map(currency => (
                                                    <MenuItem value={currency.code} key={currency.id}>
                                                        { t(currency.language_key) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    }
                                    name="currency"
                                    rules={{ required: t("enw-notification_this_is_required") }}
                                    control={control}
                                    error={!!errors.currency}
                                    defaultValue=""
                                />
                            </FormControl>
                            <FormHelperText error={!!errors.currency}>
                                {errors.currency && errors.currency.message}
                            </FormHelperText>
                        </ColAtm>
                        <ColAtm md={3} className="form-group general-select">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t('enw-input-confirm-type')}</InputLabel>
                                <Controller
                                    as={
                                        <Select
                                            className="form-control"
                                            label={t('enw-input-confirm-type')}
                                        >
                                            {
                                                confirmType.map(type => (
                                                    <MenuItem value={type.code} key={type.id}>
                                                        { t(type.language_key) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    }
                                    name="confirm_type"
                                    rules={{ required: t("enw-notification_this_is_required") }}
                                    control={control}
                                    error={!!errors.confirm_type}
                                    defaultValue=""
                                />
                            </FormControl>
                            <FormHelperText error={!!errors.confirm_type}>
                                {errors.confirm_type && errors.confirm_type.message}
                            </FormHelperText>
                        </ColAtm>
                        <ColAtm md={3} className="form-group general-select">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t('enw-input-payment_list_type')}</InputLabel>
                                <Controller
                                    as={
                                        <Select
                                            className="form-control"
                                            label={t('enw-input-payment_list_type')}
                                        >
                                            <MenuItem  value={null}>
                                                {t('enw-input-payment_list_default')}
                                            </MenuItem>
                                            {
                                                paymentListType?.map(type => (
                                                    <MenuItem value={type?.id} key={type?.id}>
                                                        { type?.title }
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    }
                                    name="payment_type_mapping_id"
                                    control={control}
                                />
                            </FormControl>
                        </ColAtm>
                        <ColAtm xs={12} md={3} className="form-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                <DatePicker
                                    autoOk
                                    disableToolbar
                                    id="expire_date"
                                    name="expire_date"
                                    className="MuiGrid-grid-xs-12"
                                    inputVariant="outlined"
                                    minDate={new Date()}
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    label={t('enw-input-offer_deadline')}
                                    variant="inline"
                                    value={ expire_date === null ?  null  : new Date(expire_date)}
                                    onChange={   (date) => {

                                        setValue("expire_date", dateFormaded(date) );
                                        const result = triggerValidation("expire_date");
                                        if(!result){
                                            clearError(["expire_date"]);
                                        }

                                    }}
                                    error={!!errors.expire_date}
                                />
                                <FormHelperText error={!!errors.expire_date}>
                                    {errors.expire_date && errors.expire_date.message}
                                </FormHelperText>
                            </MuiPickersUtilsProvider>
                        </ColAtm>
                    </Grid>
                </ColAtm>
            </form>
        </div>
    )
}

export default OfferInfo;
