import React from "react";
import {useDispatch, useSelector} from "react-redux";
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {Grid} from "@material-ui/core";
import FactList from "./FactList";
import {PlacesFactsListGet, placeFactUpdateClear} from "../../../../../../redux/actions/Content/Places/placesFactAction";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import {isDefined} from "../../../../../../utils";

const Places = (props) => {

    const [expanded, setExpanded] = React.useState(false);
    const placeList  = useSelector(props => props.getPlaceListReducer.placeList);
    const dispatch = useDispatch();
    const placeFactUpdateErrorStatus = useSelector( props => props.getPlaceListReducer?.updateFactError )
    const placeFactList = useSelector(props => props.placeFactReducer?.placeFacts)

    const handleChange = (placeId) => (event, isExpanded) => {
        setExpanded(isExpanded ? placeId : false);

        if(isExpanded !== false){
            dispatch( placeFactUpdateClear() );
            dispatch( PlacesFactsListGet(placeId) );
        }
    };

    const showMessage = () => {
        const errorStatus = placeFactUpdateErrorStatus?.response?.status
        if(errorStatus === 500 || errorStatus === 400 || errorStatus === 401)
        {
            let message = placeFactUpdateErrorStatus?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    return(
        <>
            {
                props.selectedPlaceCategoryId === 0 ?
                    placeList && placeList.length > 0 && placeList.map( (place) => (
                        <ExpansionPanel className="accordion-room-table" key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-caret-down" />}
                                aria-label="Expand">
                                <Grid container
                                      justify="space-between"
                                      alignItems="center">
                                    <Grid xs={12} item className="d-inherit">
                                        <i className="far fa-home-alt room-rate-bed" />
                                        <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                {
                                    expanded === place.id &&
                                    <FactList placeId={place.id} selectedPlace={expanded} factMapping={getSelectedData(placeFactList)} />
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )) :
                    placeList && placeList.length > 0 && placeList.filter( (place) => place.category_filters.includes(props.selectedPlaceCategoryId)  )
                        .map( (place) =>(
                            <ExpansionPanel className="accordion-room-table" key={place.id} expanded={expanded === place.id} onChange={handleChange(place.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-caret-down" />}
                                    aria-label="Expand">
                                    <Grid container
                                          justify="space-between"
                                          alignItems="center">
                                        <Grid xs={12} item className="d-inherit">
                                            <i className="far fa-home-alt room-rate-bed" />
                                            <LabelAtm labelClass="room-rate-list-title" text={place.name} />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                    {
                                        expanded === place.id &&
                                        <FactList placeId={place.id} selectedPlace={expanded} factMapping={getSelectedData(placeFactList)} />
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))
            }
            {
                showMessage()
            }
        </>

    )

}
const getSelectedData = (reducer) => {
    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];
    isDefined(reducer.place_facilities) && reducer.place_facilities.map( (value, factsKey) => {
        reducer.place_facilities[factsKey].facts.filter( (fact) => fact.is_selected === true ).map(fact => tempData = [...tempData,fact.id] )
    })
    return tempData
}

export default Places;
