import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import UpdateChildPaxModal from "../components/updateChildPaxModal";
import LabelAtm from "../../../../../atomic/Label/Label";
import BlockAtm from "../../../../../atomic/Block/Block";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import ChildCheckboxAtm from "./ChildCheckboxAtm"
import {useTranslation} from "react-i18next";


const Child = (props) => {

    useEffect( () => {},[])
    const { t } = useTranslation();
    return(
        <>
            <LabelAtm labelClass="pax-price-type-title" component="h6" text={ t('enw-channels-child') } />
            <Grid container spacing={3}>
                {
                    props.childPricingPolicy.map( child => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                            <UpdateChildPaxModal
                                child={child}
                                updateChannelChildPricing={props.updateChannelChildPricing}
                                updateChildPricingStatus={props.updateChildPricingStatus}
                                getChannelPaxList={props.getChannelPaxList}
                                channelInformationData={props.channelInformationData}
                                errorUpdateChild={props.errorUpdateChild}
                                clearErrorMessage = {props.clearErrorMessage}
                                rateMappingId={props.rateMappingId}
                            />
                            <BlockAtm className="offer-fact-border-box fact-border-box price-child-list">
                                <ChildCheckboxAtm roomId={props.roomId}
                                                  childId={child.id}
                                                  channelId={props.channelId}
                                                  childName={child.name}
                                                  rateMappingId={props.rateMappingId}
                                                  isSelected={child.pricing_child_policy_is_selected}
                                />
                                <label htmlFor={"room" + props.roomId + "rate" + props.rateMappingId + child.id+ "adult" +child.name}>
                                    <label htmlFor={"room" + props.roomId + "rate" + props.rateMappingId + child.id+ "adult" +child.name}>
                                        <Grid item xs={12} className="cancellation-info-col">
                                            <LabelAtm labelClass="cancellation-value" text={ child.value !== null ? child.value === 0 ? t("enw-input-free") : child.type === "PER" ? "%" + child.value : child.value + " " + props.currency : ""} />
                                        </Grid>
                                        <OverFlowText>
                                            {[...Array(child.adult)].map((x, i) =>
                                                <i
                                                    className="fas fa-male"
                                                    key={i} />
                                            )}
                                            {[...Array(child.child_order)].map((x, i) =>
                                                <i
                                                    className={`fas fa-child ${child.child_order === i+1 ? "selected-pax-child" : ""}`}
                                                    key={i} />
                                            )}
                                            {child.child_age_start + " - " + child.child_age_end + " " + t('enw-channels-pricing-age_range')}
                                        </OverFlowText>
                                    </label>
                                </label>
                            </BlockAtm>
                        </ColAtm>
                    ))
                }
            </Grid>
        </>
    )
}

export default Child;
