import {getCreateManualPayment, createManualPayment} from "../../../constants/Payment/createPayment.constants";

const initialState = {
    getCreateFetching : false,
    getCreateData : [],
    getCreateError : {},

    createFetching : false,
    createData : [],
    createError : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getCreateManualPayment.GET_CREATE_MANUAL_PAYMENT_DATA_PENDING:
            return {
                ...state,
                getCreateFetching: true,
                createData : [],
                getCreateData : [],
                getCreateError : {}
            };
        case getCreateManualPayment.GET_CREATE_MANUAL_PAYMENT_DATA_FULFILLED:
            return {
                ...state,
                getCreateFetching: false,
                getCreateData: action.payload,
            };
        case getCreateManualPayment.GET_CREATE_MANUAL_PAYMENT_DATA_REJECTED:
            return {
                ...state,
                getCreateError: action.payload,
                getCreateFetching: false
            };
        case createManualPayment.CREATE_MANUAL_PAYMENT_PENDING:
            return {
                ...state,
                createFetching: true,
                createData : [],
                getCreateData : [],
                createError : {}
            };
        case createManualPayment.CREATE_MANUAL_PAYMENT_FULFILLED:
            return {
                ...state,
                createFetching: false,
                createData: action.payload,
            };
        case createManualPayment.CREATE_MANUAL_PAYMENT_REJECTED:
            return {
                ...state,
                createError: action.payload,
                createFetching: false
            };
        default:
            return state
    }
}
