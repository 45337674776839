import React from "react";
import PaymentInstallments from "./PaymentInstallments";
import {isDefined} from "../../../../../../utils";
import CreateInstallmentModal from "./CreateInstallmentModal";

const InstallmentBox = (props) => {
    const {
        paymentMappingListData,
        paymentMapping,
        PaymentInstallmentUpdate,
        updatePaymentErrorClear,
        successPaymentClear,
        GetPaymentType,
        paymentTypeId,
        UpdatePaymentInstallmentStatus
    } = props;

    return(
        <>
            {
                isDefined(paymentMappingListData.installments) && paymentMappingListData.installments.map( (installmentData, key) => (
                    <>
                        {
                            installmentData.has_installments ?
                                <PaymentInstallments
                                    installmentData={installmentData}
                                    paymentMappingListData={paymentMappingListData}
                                    PaymentInstallmentUpdate={PaymentInstallmentUpdate}
                                    paymentMapping={paymentMapping}
                                    updatePaymentErrorClear={updatePaymentErrorClear}
                                    successPaymentClear={successPaymentClear}
                                    paymentTypeId={paymentTypeId}
                                    GetPaymentType={GetPaymentType}
                                    UpdatePaymentInstallmentStatus={UpdatePaymentInstallmentStatus}
                                />
                                :
                                <CreateInstallmentModal
                                    paymentMappingListData={paymentMappingListData}
                                    PaymentInstallmentUpdate={PaymentInstallmentUpdate}
                                    paymentMapping={paymentMapping}
                                    updatePaymentErrorClear={updatePaymentErrorClear}
                                    successPaymentClear={successPaymentClear}
                                    GetPaymentType={GetPaymentType}
                                    installmentData={installmentData}
                                    paymentTypeId={paymentTypeId}
                                />
                        }
                    </>
                ))
            }
        </>
    )
}

export default InstallmentBox;
