export const promotionRoomRateListConstants = {
    PROMOTION_ROOM_RATE_LIST_PENDING  : 'PROMOTION_ROOM_RATE_LIST_PENDING',
    PROMOTION_ROOM_RATE_LIST_FULFILLED: 'PROMOTION_ROOM_RATE_LIST_FULFILLED',
    PROMOTION_ROOM_RATE_LIST_REJECTED : 'PROMOTION_ROOM_RATE_LIST_REJECTED',
};

export const promotionListConstants = {
    PROMOTION_LIST_PENDING  : 'PROMOTION_LIST_PENDING',
    PROMOTION_LIST_FULFILLED: 'PROMOTION_LIST_FULFILLED',
    PROMOTION_LIST_REJECTED : 'PROMOTION_LIST_REJECTED',
};

export const createPromotionConstants = {
    PROMOTION_CREATE_PENDING  : 'PROMOTION_CREATE_PENDING',
    PROMOTION_CREATE_FULFILLED: 'PROMOTION_CREATE_FULFILLED',
    PROMOTION_CREATE_REJECTED : 'PROMOTION_CREATE_REJECTED',
};

export const updatePromotionConstants = {
    PROMOTION_UPDATE_PENDING  : 'PROMOTION_UPDATE_PENDING',
    PROMOTION_UPDATE_FULFILLED: 'PROMOTION_UPDATE_FULFILLED',
    PROMOTION_UPDATE_REJECTED : 'PROMOTION_UPDATE_REJECTED',
};

export const getPromotionTypes = {
    PROMOTION_TYPES_GET_PENDING  : 'PROMOTION_TYPES_GET_PENDING',
    PROMOTION_TYPES_GET_FULFILLED: 'PROMOTION_TYPES_GET_FULFILLED',
    PROMOTION_TYPES_GET_REJECTED : 'PROMOTION_TYPES_GET_REJECTED',
};

export const updateRoomRatePromotionConstants = {
    PROMOTION_ROOM_RATE_UPDATE_PENDING  : 'PROMOTION_ROOM_RATE_UPDATE_PENDING',
    PROMOTION_ROOM_RATE_UPDATE_FULFILLED: 'PROMOTION_ROOM_RATE_UPDATE_FULFILLED',
    PROMOTION_ROOM_RATE_UPDATE_REJECTED : 'PROMOTION_ROOM_RATE_UPDATE_REJECTED',
};

export const clearPromotionError = {
    PROMOTION_CLEAR_ERROR_STATE : 'PROMOTION_CLEAR_ERROR_STATE',
};
