import {paymentTransactionListConstants} from "../../../constants/Payment/paymentTransaction.constants";

const initialState = {
    fetching : false,
    status : false,
    transactionList : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case paymentTransactionListConstants.PAYMENT_TRANSACTION_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                status: false,
            };
        case paymentTransactionListConstants.PAYMENT_TRANSACTION_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                transactionList: action.payload,
            };
        case paymentTransactionListConstants.PAYMENT_TRANSACTION_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
