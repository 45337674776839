import React, {useEffect, useState} from "react";
import BlockAtm from "../../../../atomic/Block/Block";
import InputAtm from "../../../../atomic/Input/Input";
import moment from "moment";
import {useTranslation} from "react-i18next";



export default function Availability(props) {
    useEffect(() => {},[]);

    const { t } = useTranslation();
    const {key, value, stop_sell} = props.value
    const {updatedData} = props

    const [availability, setAvailability] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? updatedData[key] : value);
    const [hasData] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);
    const [disabled, setDisabled] = useState(stop_sell);

    const onChange = event => {
        let newAvailability = event.target.value;
        let availabilityKey = event.target.name;
        setAvailability( newAvailability );
        props.onChangeUpdateData(availabilityKey,newAvailability);
    };

    const disabledKey = key.split('_');
    const disabledKeyMerge = "STS_" + disabledKey[1] + "_" + disabledKey[2] + "_" + disabledKey[3] + "_" + disabledKey[4];

    useEffect( () => {
        if (Object.keys(updatedData).some(item => disabledKeyMerge === item)) {

            if (updatedData[disabledKeyMerge] === 1) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }

    },[updatedData])


    return (
        <BlockAtm className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && 'weekend-avail-inputs'} inventory-input t-center`}
                  style={{ borderColor: hasData ? "red" : "none" }}>
            <InputAtm
                name={key}
                placeholder="-"
                value={ availability }
                onChange={onChange}
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                title={disabled ? t('btn-stop_sell') : ""}
                disabled={disabled}
            />
        </BlockAtm>
    );
}
