import {factConstants} from "../../../../constants/Content/fact.constans";

const initialState = {
    fetching : false,
    updateFactMappingStatus: null,
    error:{}
};


export default function (state = initialState, action) {
    switch (action.type) {
        case factConstants.UPDATE_FACT_MAPPING_PENDING:
            return {
                ...state,
                fetching :true,
                updateFactMappingStatus:null,
                error:{}
            }
        case factConstants.UPDATE_FACT_MAPPING_FULFILLED:
            return {
                ...state,
                fetching :false,
                updateFactMappingStatus:action.payload?.status
            }
        case factConstants.UPDATE_FACT_MAPPING_REJECTED:
            return {
                ...state,
                fetching :false,
                updateFactMappingStatus:null,
                error: action.payload
            }
        case factConstants.UPDATE_FACT_MAPPING_CLEAR:
            return {...initialState}
        default:
            return state
    }
}
