import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FormHelperText, Grid} from "@material-ui/core";
import ButtonAtm from "../../../atomic/Button/Button";
import ColAtm from "../../../atomic/Col/Col";
import {Alert} from "@material-ui/lab";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../utils";
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {history} from "../../../../utils/history";
import {PAYMENT_TRANSACTION_DETAIL_SHORT} from "../../../../constants/appUrls.constants";
import LabelAtm from "../../../atomic/Label/Label";

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});

export default function UpdateManualPaymentForm(props) {

    const { t } = useTranslation();
    const classes = styles();

    const {getUpdateData, updateManualPayment, updateError, updateFormData, updateFetching} = props;

    const updateData = isDefined(getUpdateData.manual_payment_data) && getUpdateData.manual_payment_data;

    const updateParamsData = (
        isDefined(getUpdateData.manual_payment_data) &&
        isDefined(getUpdateData.manual_payment_data.paramsArray)) &&
        getUpdateData.manual_payment_data.paramsArray;

    const [title, setTitle] = useState(updateParamsData.title);
    const [description, setDescription] = useState(updateParamsData.description);
    const [email, setEmail] = useState(updateParamsData.email);
    const [amount, setAmount] = useState(updateParamsData.base_amount);
    const [commission, setCommission] = useState(updateParamsData.commission);
    const [currency, setCurrency] = useState(updateData.base_currency);
    const [paymentTypeId, setPaymentTypeId] = useState(updateData.payment_type_mapping_id);
    const [paymentMethod, setPaymentMethod] = useState(updateData.payment_type_mapping_id === null ? "offline" : "online");
    const [isMail, setIsMail] = useState(isDefined(updateParamsData.email) ? true : false);
    const [saveData, setSaveData] = useState(false);

    const changeTitle = ({target}) => {
        setTitle(target.value)
    };

    const changeDescription = ({target}) => {
        setDescription(target.value)
    };

    const changeCurrency = ({target}) => {
        setCurrency(target.value)
    };

    const changeEmail = ({target}) => {
        setEmail(target.value)
    };

    const changeAmount = ({target}) => {
        setAmount(target.value)
    };

    const changeCommission = ({target}) => {
        setCommission(target.value)
    };

    const changePaymentTypeId = ({target}) => {
        setPaymentTypeId(target.value)
    };

    const changePaymentMethod = ({target}) => {
        setPaymentMethod(target.value)
    };

   // const { handleSubmit } = useForm();
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            order_id : getUpdateData.manual_payment_data.order_id,
            title: title,
            description: description,
            email: isMail ? email : null,
            currency : currency,
            base_amount: amount,
            commission: commission,
            payment_method : paymentMethod,
            payment_type_mapping_id: paymentMethod === "offline" ? null : paymentTypeId
        }
        setSaveData(true)
        updateManualPayment(params)
    };

    useEffect( () => {

        if (updateFormData.status === 200 && saveData) {
            history.push(PAYMENT_TRANSACTION_DETAIL_SHORT + updateData.id);
        }

    }, [updateFormData]);

    const percentage = amount * (commission / 100)
    const totalAmount = Number(amount) + percentage

    return (
        <Grid container className={classes.root} direction="row" md={6}>
            <ValidatorForm onSubmit={handleSubmit(onSubmit)} className="m-auto" style={{width: 606}}>
                {
                     updateFetching &&
                    <Alert severity="info"> { t('enw-payment-manual_payment-loading-message') }</Alert>
                }
                {
                    (isDefined(updateError) &&
                        isDefined(updateError.response)
                    ) &&
                    <Alert severity="error"> { t( updateError.response.data.message ) }</Alert>
                }
                <Grid item xs={12}>
                    <TextValidator
                        label={t('enw-input-title')}
                        name="title"
                        fullWidth
                        autoComplete="off"
                        value={title}
                        onChange={changeTitle}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[t("enw-notification_this_is_required")]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label={t('enw-input-description')}
                        name="description"
                        fullWidth
                        autoComplete="off"
                        multiline={true}
                        rows={2}
                        value={description}
                        onChange={changeDescription}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[t("enw-notification_this_is_required")]}
                    />
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextValidator
                            label={t('enw-input-fine')}
                            name="amount"
                            fullWidth
                            autoComplete="off"
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                            value={amount}
                            onChange={changeAmount}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={[t("enw-notification_this_is_required")]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectValidator
                            name="currency"
                            onChange={changeCurrency}
                            value={currency}
                            className="form-control"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-input-currency') }
                            validators={['required']}
                            errorMessages={[ t("enw-notification_this_is_required") ]}
                        >
                            {
                                isDefined(getUpdateData.currency_list) &&
                                getUpdateData.currency_list.map( (currency) => (
                                    <MenuItem value={currency.code} >
                                        { t(currency.language_key) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label={t('enw-input-commission')}
                        name="commission"
                        fullWidth
                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                        autoComplete="off"
                        value={commission}
                        onChange={changeCommission}
                        variant="outlined"
                        validators={['required', 'minNumber:0', 'maxNumber:100']}
                        errorMessages={[t("enw-notification_this_is_required"), 'minNumber 0', 'maxNumber 100']}
                    />
                </Grid>
                {
                    isDefined(currency) && isDefined(commission) &&
                    <Grid item xs={12}>
                        <ColAtm className="mt-12 payment-create-total-price">
                            <LabelAtm variant="p" component="p" text={t('enw-payment-total_amount')+": " + totalAmount.toFixed(2) + " " + currency }  />
                        </ColAtm>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SelectValidator
                        name="paymentMethod"
                        onChange={changePaymentMethod}
                        value={paymentMethod}
                        className="form-control"
                        variant="outlined"
                        fullWidth
                        label={t('enw-input-payment_method')}
                        validators={['required']}
                        errorMessages={[ t("enw-notification_this_is_required") ]}
                    >
                        <MenuItem value={"online"} >
                            { t('enw-input-online') }
                        </MenuItem>
                        <MenuItem value={"offline"} >
                            { t('enw-input-offline') }
                        </MenuItem>
                    </SelectValidator>
                </Grid>
                {
                    paymentMethod === "online" &&
                    <Grid item xs={12}>
                        <SelectValidator
                            name="paymentType"
                            onChange={changePaymentTypeId}
                            value={paymentTypeId}
                            className="form-control"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-input-payment') }
                            validators={['required']}
                            errorMessages={[ t("enw-notification_this_is_required") ]}
                        >
                            {
                                isDefined(getUpdateData.payment_mapping_list) &&
                                getUpdateData.payment_mapping_list.map( (payment) => (
                                    <MenuItem value={payment.id} >
                                        { payment.name } - { payment.currency_code }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </Grid>
                }

                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <ColAtm className="mt-12">
                            <LabelAtm variant="h6" component="h3" text={ t('enw-payment-after_payment_notification') }  />
                        </ColAtm>
                    </Grid>
                    <Grid item >
                        <input type={"radio"}
                               name={"paymentValue"}
                               id={"paymentValue"}
                               value={"true"}
                               className="d-none"
                               onClick={(e) => { setIsMail(true) }}
                               checked={ isMail }
                        />
                        <label className="show-channel-detail" htmlFor={"paymentValue"}>
                            { t('enw-input-checkbox-yes') }
                        </label>

                    </Grid>
                    <Grid item>
                        <input type={"radio"} name={"paymentValue"}
                               id={"paymentValue2"}
                               value={"false"}
                               className="d-none"
                               checked={ !isMail }
                               onClick={(e) => { setIsMail(false) }}
                        />
                        <label className="show-channel-detail"
                               htmlFor={"paymentValue2"}>
                            { t('enw-input-checkbox-no') }
                        </label>
                    </Grid>
                </Grid>

                {
                    isMail &&
                    <Grid item xs={12} className="mt-12">
                        <TextValidator
                            label={t('enw-input-email')}
                            name="email"
                            fullWidth
                            autoComplete="off"
                            value={email}
                            onChange={changeEmail}
                            variant="outlined"
                            inputRef={register({
                                required: t("enw-notification_this_is_required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t("enw-notification_invalid_email")
                                }
                            })}
                        />
                        <FormHelperText error={!!errors.email}>
                            {errors.email && errors.email.message}
                        </FormHelperText>
                    </Grid>
                }

                <ColAtm className="mt-22" xs={12}>
                    <ButtonAtm
                        className="m-auto room-rate-modal-save bg-red"
                        type="submit"
                        buttontext={ t('btn-save') }
                        color="primary"
                    />
                </ColAtm>
            </ValidatorForm>
        </Grid>
    );
}
