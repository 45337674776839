import React from 'react';

const SelectLng = (props) => (
    <div>
        <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${props.mainLang}.svg`} alt={props.mainLang} />
        <span> {props.keyLang}</span>
    </div>
);

export default SelectLng;
