import React from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {CONTENT} from "../../../../../constants/appUrls.constants";

let t=null;


class FactHeader extends React.Component{
    constructor(props) {
        super(props);
        t = props.t;
    }

    render() {
        return (
            <>
                <ColAtm>
                    <PageTitle title={ t(this.props.this_main_category.language_key)  + " - ExtraNetWork"} />
                    <PageHeaderAtm backButton={CONTENT}
                                   exitText= { t('btn-dashboard') }
                                   text={t(this.props.this_main_category.language_key)}
                    />
                </ColAtm>
            </>
        )
    }
}

export default compose(withTranslation('common')) (FactHeader);
