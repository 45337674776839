import React from 'react';
import PropTypes from "prop-types";

export default function AddRoomButton(props) {
    return (
        <div className="w-100">
            {props.roomMenuItem}
        </div>
    );
}

AddRoomButton.propType = {
    roomMenuItem: PropTypes.string,
};

