import React from "react";
import {useTranslation} from "react-i18next";
import FactCheckBox from "../../molecules/FactContent/FactContent";


const Accommodation = (props) => {
    const { t } = useTranslation();
    const {offerReducer} = props

    const accommodations = offerReducer.hasOwnProperty("accommodation_types") &&
    Object.keys(offerReducer.accommodation_types).length > 0 ? offerReducer.accommodation_types : [];

    return(
        <>
            {
                accommodations.map( (accommodation) => (
                    <FactCheckBox
                        name="accommodation_mapping"
                        checkboxId={"atyp"+accommodation.id+""+accommodation.mapping_id}
                        className="fact-box"
                        colClassname="offer-fact-col"
                        blockClass="offer-fact-border-box fact-border-box"
                        labelIconClass="fact-icon-label"
                        iconClass={`offer-fact-icon fact-icon ${accommodation.icon}`}
                        value={accommodation.id}
                        defaultChecked={accommodation.is_selected}
                        key={"atyp"+accommodation.id+""+accommodation.mapping_id}
                        factName="name"
                        md={2}
                        text={ t(accommodation.language_key) }
                        tooltip={ t(accommodation.language_key) }
                        labelClass="fact-name"
                        htmlFor={"atyp"+accommodation.id+""+accommodation.mapping_id}
                        onClick={props.onClickAccommodationType}
                    />
                ))
            }
        </>
    )
}

export default Accommodation;
