import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ColAtm from "../../atomic/Col/Col";
import {Button} from "@material-ui/core";
import PhotoList from "./PhotoList";

const Photos = ( props ) => {
    const { t } = useTranslation();

    const {offerReducer, photo_mapping, cover_photos} = props;

    const getFacts = offerReducer.photos;

    const [tempPhotoMapping, setTempPhotoMapping] = useState(photo_mapping)
    const [tempCoverPhoto, setCoverPhoto] = useState(cover_photos)
    const [showSelectAll, setShowSelectAll] = useState(false)

    const onClickSelectAll = () => {
        let photoId = [];

        photoId = getFacts.map( (photo) => photo.id )
        setShowSelectAll(true);
        addAllCheckboxState(photoId)
    }

    const onClickDeSelectAll = () => {

        let photoId = [];
        photoId = getFacts.map( factMap => factMap.id );
        deleteAllCheckboxState(photoId)
        setShowSelectAll(false);
    }

    const onClickPhoto = (e) => {

        if(e.target.checked === undefined) return 0;

        if(e.target.checked === true)
        {
            addCheckboxState(e.target.value)
        }
        else if (e.target.checked === false){
            deleteCheckboxState(e.target.value)
            setShowSelectAll(true);
        }
    }

    const onClickCoverPhoto = (e) => {

        e.stopPropagation()

        if(e.target.checked === true)
        {
            let temp = [];
            if( !tempCoverPhoto.includes( parseInt(e.target.value) ) ){
                temp = [ parseInt(e.target.value) ];
            }
            props.onClickCoverPhoto(temp)
            setCoverPhoto(temp);
        }
    }

    const deleteCheckboxState = (photoId) => {

        let temp = tempPhotoMapping;
        let index = temp.indexOf(parseInt(photoId));

        if ( index > -1 ) {
            temp.splice(index, 1);
            setTempPhotoMapping(temp);
            props.onClickPhoto(temp);
        }
    }

    const deleteAllCheckboxState = (photoIds) => {

        if(photoIds.length > 0 ){

            let temp = tempPhotoMapping;

            photoIds.map( item => {
                let index = temp.indexOf(parseInt(item));
                if ( index > -1 ) {
                    temp.splice(index, 1);
                }
            });
            setTempPhotoMapping(temp);
            props.onClickPhoto(temp);
        }else{
            setTempPhotoMapping([]);
            props.onClickPhoto([]);
        }


    }

    const addAllCheckboxState = (photoIds) => {
        if(photoIds.length > 0 )
        {
            let temp = tempPhotoMapping;

            photoIds.map( item => {
                let index = temp.indexOf(parseInt(item));
                if ( index === -1 ) {
                    temp.push(item);
                }
            });

            setTempPhotoMapping(temp);
            props.onClickPhoto(temp);
        }
    }

    const addCheckboxState = (photoId) => {

        let temp = [...tempPhotoMapping, parseInt(photoId)];

        setTempPhotoMapping(temp);
        props.onClickPhoto(temp);
    }


    useEffect( () => {
        if (cover_photos.length > 0) {
            setCoverPhoto(cover_photos)
        }
    },[cover_photos])

    return(
        <>
            <ColAtm>
                <ColAtm className="fact-categories">
                    <ColAtm className="select-all-photos-offer" xs={12}>
                        <Button className={!showSelectAll ? "select-all-photos" : "d-none"}  onClick={ onClickSelectAll }> { t('btn-deselect_all') } </Button>
                        <Button className={showSelectAll ? "de-select-all-photos" : "d-none"}  onClick={ onClickDeSelectAll } > { t('btn-select_all') } </Button>
                    </ColAtm>
                </ColAtm>
                <>
                    {
                        offerReducer.hasOwnProperty("photos") &&
                        Object.keys(offerReducer.photos).length > 0 && offerReducer.photos.map( (photo) => (
                            <PhotoList  photo={photo}
                                        onClickPhoto={onClickPhoto}
                                        onClickCoverPhoto={onClickCoverPhoto}
                                        checked={tempPhotoMapping.indexOf(photo.id) > -1  ? true : false}
                                        coverPhotoChecked={ tempCoverPhoto.length > 0 && tempCoverPhoto[0] === photo.id  ? true : false}
                            />
                        ))
                    }
                </>
            </ColAtm>

        </>
    )
}

export default Photos;
