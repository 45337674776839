import { factListConstants } from "../../../../constants/Content/factList.constants";

const initialState = {
    fetching :false,
    factData: [],
    getMenus: [],
    this_main_category: [],
    this_sub_category: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case factListConstants.GET_FACT_LIST_PENDING:
            return {
                ...state,
                fetching:true
            };
        case factListConstants.GET_FACT_LIST_FULFILLED:
            return {
                ...state,
                fetching : false,
                factData: action.payload.data.get_facts,
                getMenus: action.payload.data.get_menus,
                this_main_category: action.payload.data.this_main_category,
                this_sub_category: action.payload.data.this_sub_category,
            };
        case factListConstants.GET_FACT_LIST_REJECTED:
            return {
                ...state,
                fetching : false,
            }
        default:
            return state
    }
}
