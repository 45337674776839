import React, {useEffect, useState} from "react";
import BlockAtm from "../../../../atomic/Block/Block";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {isDefined} from "../../../../../utils";

export default function StopSellAvailability(props) {
    useEffect(() => {},[]);
    const {key, value} = props.value
    const {updatedData} = props

    const [availability, setAvailability] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? updatedData[key] : value);
    const [hasData, setHasData] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);

    const onChange = event => {
        let newAvailability = event.target.checked === true ? 1 : 0;
        let availabilityKey = event.target.name;
        setAvailability( newAvailability );
        setHasData (false);
        props.onChangeUpdateData(availabilityKey, newAvailability);
    };

    return (
        <BlockAtm className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && 'weekend-avail-inputs'} inventory-input t-center`}
                  style={{ borderColor: hasData ? "red" : "none" }}>
            <FormControlLabel
                control={<Checkbox
                    icon={<i className="far fa-square" />}
                    checkedIcon={<i className="far fa-times-square" />}
                    className={(hasData && isDefined(availability)) ? "default-border" : ""}
                    name={key}
                    checked={availability}
                    type="checkbox"
                    onChange={onChange}
                />
                }
            />
        </BlockAtm>
    );
}
