import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import moment from 'moment';
import 'moment/min/locales.min';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
let t=null;

const dateFormat = 'YYYY-MM-DD';

class PriceDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: '',
            startDate: moment(props.startDate, dateFormat),
            endDate: moment(props.endDate, dateFormat)
        };
        this.onDatesChange = this.onDatesChange.bind(this)
        this.onFocusChange = this.onFocusChange.bind(this)
        t = props.t;
    }

    onDatesChange({startDate, endDate}) {

        if (endDate != null || startDate != null) {

            if (this.state.focusedInput == "startDate") {
                let endDate = null;
                this.setState({startDate,endDate})
                return
            }

            if (this.state.focusedInput == "endDate") {
                if( (endDate != null && startDate != null) ){
                    this.setState({startDate, endDate},
                        () => this.props.dateRangeChange(moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'))
                    );
                }
            }



        }
    }

    onFocusChange(focusedInput) {
        this.setState({focusedInput});
    }
    isOutsideRange=(day) => {
        if (this.state.focusedInput == "endDate") {
            return day.isAfter(moment(this.state.startDate, dateFormat).add(14, 'days')) || day.isBefore(this.state.startDate);
        }
        return false;
    }


    render() {
        const {focusedInput, startDate, endDate} = this.state;
        const appLanguage = localStorage.getItem("lng");
        moment.locale(appLanguage)

        return (
                <Grid container>
                    <Grid item sm={4} className="pos-relative">
                        <Grid className="offer-date-icon offer-start-icon">
                            <p>{ t('enw-input-from_date') }</p>
                            <i className="far fa-calendar-alt" />
                        </Grid>
                        <DateRangePicker
                            onDatesChange={this.onDatesChange}
                            onFocusChange={this.onFocusChange}
                            focusedInput={focusedInput}
                            startDate={moment(startDate).locale(appLanguage)}
                            endDate={moment(endDate).locale(appLanguage)}
                            displayFormat="MMM DD YYYY"
                            isOutsideRange={this.isOutsideRange}
                            minimumNights={0}
                        />
                        <Grid className="offer-date-icon offer-end-icon">
                            <p>{ t('enw-input-to_date') }</p>
                            <i className="far fa-calendar-alt" />
                        </Grid>
                    </Grid>
                    <Grid item sm={8} className="offer-price-dates">
                        <Grid container className="offer-mobile-dates">
                            {Object.entries(this.props.allDays).map(([arr, val])=>{
                                return <Grid item className="offer-price-day">
                                    <Grid className={`${(moment(val).locale('de').weekday() == 5 || moment(val).locale('de').weekday() == 6 ) ? 'offer-price-weekend' : 'weekdays'}`}>
                                        <p>
                                            {moment(val).locale(appLanguage).format('ddd')}
                                        </p>
                                        <p>
                                            {moment(val).locale(appLanguage).format('D')}
                                        </p>
                                        <p>
                                            {moment(val).locale(appLanguage).format('MMM')}
                                        </p>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid>
        );
    }
}

export default compose(withTranslation('common'))(PriceDateRange);
