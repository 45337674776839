import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {fillOnUndefinedObject, isDefined} from "../../../../../../utils";
import {Button} from "@material-ui/core";
import ColAtm from "../../../../../atomic/Col/Col";
import {useSelector} from "react-redux";
import PlacesFactCheckBox from "./PlacesFactCheckBox";
import Loading from "../../../../../molecules/Loading/Loading";

const FactList = ( props ) => {
    const { t } = useTranslation();
    const placeFactList = useSelector(props => props.placeFactReducer?.placeFacts)

    const tmpFact = fillOnUndefinedObject(placeFactList,'place_facilities');
    const [selectedFactCategory, setSelectedFactCategory] = useState('all');

    return(
        <ColAtm>
            <ColAtm className="fact-categories">
                <ColAtm md={11}>
                    <Button className={(selectedFactCategory === "all" ? 'active-fact-button' : '')} onClick={() => setSelectedFactCategory('all')}> { t('enw-tab-all') } </Button>
                    {
                        isDefined(tmpFact) ?
                            tmpFact.map( (factName) => (
                                <Button className={(selectedFactCategory === factName.id ? 'active-fact-button' : '')}
                                        key={factName.id}
                                        onClick={ () => setSelectedFactCategory(factName.id)}
                                >
                                    {t(factName.language_key)}
                                </Button>
                            ))
                            :
                            <Loading />
                    }
                </ColAtm>
            </ColAtm>
            <>
                {
                    isDefined(tmpFact) ?
                        tmpFact.map( (factList) => (
                            <>
                                {
                                    factList.facts.map((fact) => (
                                        <PlacesFactCheckBox
                                            fact={fact}
                                            placeId={props.placeId}
                                            selectedFactCategory={selectedFactCategory}
                                            factListId={factList.id}
                                        />
                                    ))
                                }
                            </>

                        ))
                        :
                        <Loading />
                }
            </>
        </ColAtm>
    )
}


export default FactList;

