import {
    getChannelPaxPricingListConstants,
    addChannelAdultPaxPricingListConstants,
    addChannelChildPaxPricingListConstants,
    updateChannelAdultPaxPricingListConstants, updateChannelChildPaxPricingListConstants,
    updateChannelAdultPaxPricingChildConstants, updateChannelChildPaxPricingChildConstants
} from "../../../../constants/Network/Channels/channelPaxPricing.constans";

const initialState = {
    fetching : false,
    errorPricingList : {},
    getPricingList: [],
    addAdultPricing: [],
    addAdultPricingStatus: null,
    errorAddAdult: {},
    addChildPricing: [],
    addChildPricingStatus: null,
    errorAddChild: {},
    updateAdultPricing: [],
    updateAdultPricingStatus: null,
    errorUpdateAdult: {},
    updateChildPricing: [],
    updateChildPricingStatus: null,
    errorUpdateChild: {},
    errorUpdateAdultMapping: {},
    UpdateChildMapping: {},
    errorUpdateChildMapping: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getChannelPaxPricingListConstants.NETWORK_CHANNEL_PRICING_GET_PENDING:
            return {
                ...state,
                getPricingList: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                updateChildPricing: [],
                updateChildPricingStatus: null,
                fetching: true,
            };
        case getChannelPaxPricingListConstants.NETWORK_CHANNEL_PRICING_GET_FULFILLED:
            return {
                ...state,
                fetching: false,
                errorPricingList: {},
                getPricingList: action.payload.data.rooms,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                errorAddAdult: null,
                errorAddChild: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case getChannelPaxPricingListConstants.NETWORK_CHANNEL_PRICING_GET_REJECTED:
            return {
                ...state,
                errorPricingList: action.payload,
                errorAddAdult: null,
                errorAddChild: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: false
            };
        case addChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_CREATE_PENDING:
            return {
                ...state,
                addAdultPricing: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                updateChildPricing: [],
                updateChildPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: true,
            };
        case addChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_CREATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                errorPricingList: null,
                errorAddAdult: null,
                addAdultPricing: action.payload,
                addAdultPricingStatus: action.payload.status,
                errorAddChild: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case addChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_CREATE_REJECTED:
            return {
                ...state,
                errorAddAdult: action.payload,
                errorAddChild: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: false
            };
        case addChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_CREATE_PENDING:
            return {
                ...state,
                addAdultPricing: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                updateChildPricing: [],
                updateChildPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: true,
            };
        case addChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_CREATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                errorPricingList: null,
                errorAddAdult: null,
                addChildPricing: action.payload,
                addChildPricingStatus: action.payload.status,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case addChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_CREATE_REJECTED:
            return {
                ...state,
                errorAddChild: action.payload,
                errorAddAdult: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: false
            };

        case updateChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_UPDATE_PENDING:
            return {
                ...state,
                addAdultPricing: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                fetching: false,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                updateChildPricing: [],
                updateChildPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case updateChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                errorPricingList: null,
                errorAddAdult: null,
                addAdultPricing: [],
                addAdultPricingStatus: [],
                errorAddChild: null,
                updateAdultPricing: action.payload,
                updateAdultPricingStatus: action.payload.status,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case updateChannelAdultPaxPricingListConstants.NETWORK_CHANNEL_PRICING_ADULT_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdateAdult: action.payload,
                errorAddAdult: null,
                errorAddChild: null,
                errorUpdateChild: null,
                fetching: false
            };

        case updateChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_UPDATE_PENDING:
            return {
                ...state,
                addAdultPricing: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                fetching: false,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                updateChildPricing: [],
                updateChildPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
            };
        case updateChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                addAdultPricing: [],
                errorPricingList: {},
                errorAddAdult: null,
                errorAddChild: null,
                addAdultPricingStatus: null,
                addChildPricingStatus: null,
                updateAdultPricing: [],
                updateAdultPricingStatus: null,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                updateChildPricing: action.payload,
                updateChildPricingStatus: action.payload.status,
            };
        case updateChannelChildPaxPricingListConstants.NETWORK_CHANNEL_PRICING_CHILD_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdateChild: action.payload,
                errorUpdateAdult: null,
                errorAddAdult: null,
                errorAddChild: null,
                fetching: false
            };

        case updateChannelAdultPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_PENDING:
            return {
                ...state,
                fetching : false,
            };
        case updateChannelAdultPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
            };
        case updateChannelAdultPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdateAdultMapping: action.payload,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: false
            };
        case updateChannelChildPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_PENDING:
            return {
                ...state,
                fetching : false,
            };
        case updateChannelChildPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_FULFILLED:
            return {
                ...state,
                UpdateChildMapping: action.payload,
                fetching: false,
            };
        case updateChannelChildPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdateChildMapping: action.payload,
                errorUpdateAdult: null,
                errorUpdateChild: null,
                fetching: false
            };
        case updateChannelChildPaxPricingChildConstants.NETWORK_CHANNEL_PRICING_CLEAR_ERROR_STATE:
            return {
                ...state,
                errorAddAdult: null,
                errorAddChild: null,
                errorUpdateAdult : null,
                errorUpdateChild : null
            };
        default:
            return state
    }
}
