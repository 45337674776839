import React from 'react'
import { withStyles }           from "@material-ui/core/styles";
import {useTranslation, withTranslation} from 'react-i18next';
import InputAtm from "../../../../atomic/Input/Input";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";

const PhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 80px !important;
                  }`;

const styles = theme => ({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});

const ProfileForm = (props) =>  {

    const { t } = useTranslation();


    let defaultValues = {
        name:  props.userProfile &&  Object.keys(props.userProfile.profileData).length > 0 ? props.userProfile.profileData.profile.name : '',
        surname:  props.userProfile &&  Object.keys(props.userProfile.profileData).length > 0 ? props.userProfile.profileData.profile.surname : '',
        language: props.userProfile &&  Object.keys(props.userProfile.profileData).length > 0 ? props.userProfile.profileData.profile.language : '',
        phone:  props.userProfile && Object.keys(props.userProfile.profileData).length > 0 && props.userProfile.profileData.profile.phone !== null ? props.userProfile.profileData.profile.phone : '',
        gender :  props.userProfile &&  Object.keys(props.userProfile.profileData).length > 0 ? props.userProfile.profileData.profile.gender : '',
        id :  props.userProfile &&  Object.keys(props.userProfile.profileData).length > 0 ? props.userProfile.profileData.profile.id : '',
    }

    const { register, handleSubmit,  errors , control } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange',
        defaultValues
    });

    const onSubmit = (data) => {
        props.updateProfile(data)
    }

    return (
        <ColAtm container className={props.classes.root} direction="row" md={6}>
            <form onSubmit={handleSubmit(onSubmit)} className="edit-profile-form" name="general-form" id="general-form" >
                <LabelAtm variant="h6" component="h2" text={ t('enw-edit-profile-subtitle') } />
                <Grid container justify="space-between">
                    <div style={{width: '49%'}}>
                        <InputAtm
                            name="name"
                            type="text"
                            variant="outlined"
                            label={ t('input-name') }
                            fullWidth

                            error={!!errors.name}
                            inputRef={register({ required:  t('enw-notification_this_is_required') })}
                        />
                        <FormHelperText error={!!errors.name}>
                            {errors.name && errors.name.message}
                        </FormHelperText>
                    </div>
                    <div style={{width: '49%'}}>
                        <InputAtm
                            name="surname"
                            type="text"
                            variant="outlined"
                            label={ t('input-surname') }
                            fullWidth
                            error={!!errors.surname}
                            inputRef={register({ required:  t('enw-notification_this_is_required') })}
                        />
                        <FormHelperText error={!!errors.surname}>
                            {errors.surname && errors.surname.message}
                        </FormHelperText>
                    </div>
                </Grid>
                <ColAtm md={12} className="form-group">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{ t('input-gender') }</InputLabel>
                        <Controller
                            as={
                                <Select
                                    className="form-control"
                                    label={ t('input-gender') }
                                >
                                    <MenuItem key={0}>{ t('enw-input-select') }</MenuItem>
                                    <MenuItem value="X"> { t('enw-input-not_indicate') }  </MenuItem>
                                    <MenuItem value="F"> { t('enw-input-female') }</MenuItem>
                                    <MenuItem value="M"> { t('enw-input-male') }</MenuItem>
                                </Select>

                            }
                            name="gender"
                            control={control}
                            error={!!errors.gender}
                        />
                    </FormControl>
                    <FormHelperText error={!!errors.gender}>
                        {errors.gender && errors.gender.message}
                    </FormHelperText>
                </ColAtm>
                <PhoneCard lang={t('enw-input-main_phone')}>
                    <FormControl variant="outlined" fullWidth className="mt-0">
                        <Controller
                            as={
                                <PhoneInput
                                    name="phone"
                                    country={'de'}
                                    enableSearch={true}
                                    prefix={""}
                                    label={ t('enw-input-main_phone')}
                                    placeholder={ t('enw-input-main_phone')}
                                />

                            }
                            name="phone"
                            control={control}
                            error={!!errors.phone}
                        />
                    </FormControl>
                    <FormHelperText error={!!errors.phone}>
                        {errors.phone && errors.phone.message}
                    </FormHelperText>
                </PhoneCard>
                <ColAtm md={12} className="form-group">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel> { t('enw-input-language') } </InputLabel>
                        <Controller
                            as={
                                <Select
                                    className="form-control"
                                    label={ t('enw-input-language') }
                                >
                                    <MenuItem key={0}>{ t('enw-input-select') }</MenuItem>
                                    {
                                        props.languages && props.languages.map(lang => (
                                            <MenuItem value={lang.code} key={lang.id}>
                                                { t(lang.language_key) }
                                            </MenuItem>
                                        ))
                                    }
                                </Select>

                            }
                            name="language"
                            rules={{ required: t("enw-notification_this_is_required") }}
                            control={control}
                            error={!!errors.language}
                        />
                    </FormControl>
                    <FormHelperText error={!!errors.language}>
                        {errors.language && errors.language.message}
                    </FormHelperText>
                </ColAtm>

            </form>
        </ColAtm>
        );

}

export default withTranslation('common')( withStyles(styles, { withTheme: true }) (ProfileForm));
