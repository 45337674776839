import React, {useEffect,useMemo} from "react";
import InventorySkeleton from "../../../../../molecules/InventorySkeleton/InventorySkeleton";
import {useTranslation} from "react-i18next";
import QuickPricingAccommodations from "./Accommodations";
import {
    updateQuickPricing, updateQuickPricingClear
} from "../../../../../../redux/actions/Network/Inventory/inventoryAction";
import {useDispatch, useSelector} from "react-redux";
import SuccessInventoryModal from "../../../../../organisms/Modal/SuccessModal/SuccessInventoryModal";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import {isDefined} from "../../../../../../utils";

let updatedData = [];

const QuickPricingRooms =  (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const state = useMemo( () => props.roomPrice,[props.roomPrice] )
    const quickPricingData  = useSelector(props => props?.quickPricingReducer.quickPricing);
    const quickPricingDataError  = useSelector(props => props?.quickPricingReducer.error);

    useEffect(() => {
        if(quickPricingData.status === 200){
            updatedData = [];
            props.changePriceStatus(false);
            dispatch(updateQuickPricingClear())
        }
        return () => {
            updatedData = [];
        }
    },[])


    const onChangeUpdateData = (key, value) => {
        updatedData[key] = value
        props.isChangePriceStatus();
    }

    const postData = (e) => {
        e.preventDefault();

        dispatch(updateQuickPricing(props.channelId, updatedData));
    };

    const showMessage = () => {
        const {channelId} = props.channelId;

        if( quickPricingData.status === 200 ){

            return <SuccessInventoryModal
                status={true}
                message={t('enw-notification_success')}
                channelId={channelId}
            />
        }

        if( quickPricingDataError !== {}  &&
            quickPricingDataError?.response
        ){
            let message = quickPricingDataError.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    return (
        <>
            {
                props.fetching ?
                    <InventorySkeleton/>
                    :
                    <form onSubmit={postData} name="general-form" id="general-form" autoComplete="off" style={{width: '100%'}}>

                        <QuickPricingAccommodations
                            onChangeUpdateData={onChangeUpdateData}
                            roomRates={isDefined(state) && isDefined(state[0]) && state[0]?.property_room_rate_mapping}
                            updatedData={updatedData}
                            channelAvailability={props.channelAvailability}
                            showStopSell={props.showStopSell}
                            showMinStay={props.showMinStay}
                        />
                    </form>
            }
            {showMessage()}
        </>
    )
}

export default QuickPricingRooms;
