import React from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {NETWORK_COUPONS_CHANNEL_LIST} from "../../../../../constants/appUrls.constants";

let t=null;


class CouponHeader extends React.Component{
    constructor(props) {
        super(props);
        t = props.t;
    }

    render() {
        return (
            <>
                <ColAtm>
                    <PageTitle title={ t('title-coupons-header-text') } />
                    <PageHeaderAtm backButton={NETWORK_COUPONS_CHANNEL_LIST}
                                   exitText= { t('btn-dashboard') }
                                   text={ t('title-coupons-header-text') }
                    />
                </ColAtm>
            </>
        )
    }
}

export default compose(withTranslation('common')) (CouponHeader);
