import React, {useEffect, useState, Fragment, useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getAppLang, isDefined} from "../../../../../utils";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import moment from "moment";
import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";
import { IconButton, InputAdornment } from "@material-ui/core";
import TodayIcon from '@material-ui/icons/Today';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAtm from "../../../../atomic/Input/Input";
import { el } from 'date-fns/locale';
import {AppBar, Tabs, Tab, Box} from '@material-ui/core';
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    exclWidth: {
        width: "700px",
    },
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
       <Box>{children}</Box>
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        // overflowY: "clip"
    },
}))(MuiDialogContent);

export default function CreatePromotionModal(props) {
    const { t } = useTranslation();

    const localeMap = {
        es: esLocale,
        de: deLocale,
        tr: trLocale
    };

    const {promotionReducers, createPromotion, getPromotionRoomRateList, createOrUpdatePromotionErrorClear, getPromotionList} = props;

    const [open, setOpen] = React.useState(false);
    const [promotionType, setPromotionType] = useState(null);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"));
    const [reservationStartDate, setReservationStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [reservationEndDate, setReservationEndDate] = useState(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"));
    const [dayBefore, setDayBefore] = useState("");
    const [lastMinute, setLastMinute] = useState("");
    const [amount, setAmount] = useState("");
    const [minStay, setMinStay] = useState("");
    const [isMobile, setIsMobile] = useState(null);
    const [isTime, setIsTime] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [days, setDays] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [title, setTitle] = useState(null);
    const [isSameDayChecked, setIsSameDayChecked] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [excludeDaysData, setExcludeDaysData] = useState({exclude_dates: []});
    const [excludeDaysDate, setExcludeDaysDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [excDaysButton, setExcDaysButton] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        
        setTitle(null);
        setStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setEndDate(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"))
        setReservationStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setReservationEndDate(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"))
        setDayBefore("")
        setLastMinute("")
        setAmount("")
        setMinStay("")
        setIsMobile(null)
        setDays([1, 2, 3, 4, 5, 6, 7])
        setIsTime(null);
        setStartTime(null);
        setEndTime(null);
        setExcDaysButton(0)
        setTabValue(0)
        setExcludeDaysData({exclude_dates: []})
        createOrUpdatePromotionErrorClear();
    };


    const changePromotionType = ({target}) => {
        setPromotionType(target.value)

        setTitle(null);
        setStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setEndDate(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"))
        setReservationStartDate(moment(new Date()).format("YYYY-MM-DD"))
        setReservationEndDate(moment(new Date()).add(1, 'days').format("YYYY-MM-DD"))
        setDayBefore("")
        setLastMinute("")
        setAmount("")
        setMinStay("")
        setIsTime(null);
        setStartTime(null);
        setEndTime(null);
        setDays([1, 2, 3, 4, 5, 6, 7])
        setExcDaysButton(0)
        setTabValue(0)
        setExcludeDaysData({exclude_dates: []})
    }

    const changeStartDate = date => {
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeEndDate = date => {
        setEndDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeReservationStartDate = date => {
        setReservationStartDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeReservationEndDate = date => {
        setReservationEndDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeDayBefore = ({target}) => {
        setDayBefore(target.value)
    }

    const changeAmount = ({target}) => {
        setAmount(target.value)
    }

    const changeMinStay = ({target}) => {
        setMinStay(target.value)
    }

    const changeIsMobile = ({target}) => {
        setIsMobile(target.checked ? 1 : null)
    }

    const changeTime = ({target}) => {
        setIsTime(target.checked ? 1 : null)
        if(target.checked) {
            setStartTime("00:00")
            setEndTime("00:00")
        } else {
            setStartTime(null)
            setEndTime(null)
        }
    }

    const changeStartTime = ({target}) => {
        setStartTime(target.value)
    }

    const changeEndTime = ({target}) => {
        setEndTime(target.value)
    }

    const changeTitle = ({target}) => {
        setTitle(target.value)
    }

    const changeSameDay = ({target}) => {
        if (target.checked) {
            setIsSameDayChecked(true)
            setLastMinute(0)
        }
        else {
            setIsSameDayChecked(false)
            setLastMinute("")
        }
    }


    const handleCheckboxChange = event => {
        let newArray = [...days.filter(Number), parseInt(event.target.id)];
        if (days.includes(parseInt(event.target.id))) {
            newArray = newArray.filter(day => day !== parseInt(event.target.id)).filter(Number);
        }

        setDays(newArray)


    };

    const changeLastMinute = ({target}) => {
        setLastMinute(target.value)
    }

    const handleTabChange = ( event, newValue ) => {
        setTabValue(newValue)
    };

    const handleExcludeValue = ({target}) => {
        // setTabValue(1)
        if (target.checked) {
            setExcDaysButton(1)
        }
        else {
            setExcDaysButton(0)
        }
        
    }

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            promotion_type_id: promotionType,
            title: title ? title : null,
            start_date: promotionType === 2 ? startDate : null,
            end_date: promotionType === 2 ? endDate : null,
            reservation_start_date: promotionType === 2 ? reservationStartDate : null,
            reservation_end_date: promotionType === 2 ? reservationEndDate : null,
            day_before: promotionType === 3 ? dayBefore : null,
            lastMinute: promotionType === 4 ? lastMinute : null,
            amount: amount,
            min_stay: minStay,
            is_mobile: isMobile,
            days: days,
            start_time: startTime,
            end_time: endTime,
            is_time: isTime,
            exclude_dates: excDaysButton === 1 ? excludeDaysData.exclude_dates : null
        }

        createPromotion(params)
    };

    useEffect( () => {

        if (promotionReducers.createPromotion.status === 200 && open) {
            getPromotionRoomRateList(props.channelInformationData.id);
            getPromotionList();
            handleClose();
        }
        return () => {
            setPromotionType(null);
        }
    },[promotionReducers.createPromotion, props.open] )

    useEffect( () => {
        setExcludeDaysData(excludeDaysData);
    },[excludeDaysData])

    const addItem = useCallback((_event, itemType, itemValue) => {
        setExcludeDaysData((currentSort) => {
          const key = itemType.toLowerCase(),
          existing = new Set(currentSort[key] || []);
          existing.add(itemValue);
          return { ...currentSort, [key]: [...existing] };
        });
      }, []);

      const removeItem = useCallback((_event, itemType, itemValue) => {
        setExcludeDaysData((currentSort) => {
           const key = itemType.toLowerCase(),
           existing = new Set(currentSort[key] || []);
           existing.delete(itemValue);
           return { ...currentSort, [key]: [...existing] };
         });
       }, []);

    const enterExDaysDate = (date, id, e) => {
        setExcludeDaysDate(moment(date).format("YYYY-MM-DD"))
    }

    return (
        <>
            <ButtonAtm
                buttontext={ t('btn-create_promotion') }
                className="filter-action-button float-right"
                startIcon={<i className="fas fa-plus-circle" />}
                onClick={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="create-promotion-title"
                className="promotion-modal"
                disableBackdropClick={true}
            >
                <DialogTitle id="create-promotion-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-create_promotion-title')}
                </DialogTitle>
                <DialogContent className={tabValue === 1 ? "exclWidth" : "w-100"}>
                    <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                    {
                        excDaysButton === 1 ?
                        <AppBar className="room-rate-tab-title" position="static">
                            <Tabs className="room-rate-tabFont" value={tabValue} onChange={handleTabChange} aria-label="room-rate-tab">
                                <Tab label={ t('enw-promotions-tab_title') } {...a11yProps(0)} />
                                {/* <ButtonAtm className={tabValue === 1 ? "bed-type-title" : "passive-tab"} buttontext={ t('enw-promotion-exclude_dates') } /> */}
                                <Tab className={tabValue === 1 ? "bed-type-title" : "passive-tab"} label={ t('enw-promotion-exclude_dates') } {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        : <div className='d-none'></div>
                    }
                    {
                        promotionType === 3 || promotionType === 5 ? 
                            <CustomTabPanel value={tabValue} index={1}>
                                <Grid container className='m-w-auto' spacing={2} xs={12} md={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                        <Grid item xs={12} md={10}>
                                            <DatePicker
                                                margin="normal"
                                                label={ t('enw-input-promotion_start_date')}
                                                className="w-98"
                                                variant="inline"
                                                autoOk
                                                inputVariant="outlined"
                                                format="dd.MM.yyyy"
                                                value={excludeDaysDate}
                                                onChange={enterExDaysDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton>
                                                                <TodayIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2} className='pl-0-m'>
                                            <ButtonAtm className="new-bed-button mt-24 w-70-btn prom-ex-day-add-btn" fullwidth={true}
                                                startIcon={<i className="fas fa-plus-circle mt-10 h-30" />}
                                                onClick={(e) => addItem(e, "exclude_dates", excludeDaysDate)}
                                            />
                                        </Grid>
                                        {
                                            excludeDaysData?.exclude_dates?.length ? excludeDaysData?.exclude_dates?.map((excludeDays, index) => (
                                                <div className='w-100-m'>
                                                    <div key={index} className='prom-ex-day-dates mt-5'>{moment(excludeDays).format("DD.MM.YYYY")}</div>
                                                        <div className='prom-ex-day-delete-btn'>
                                                            <ButtonAtm className="delete-bed-button mt-24 w-40-btn" fullwidth={true}
                                                                startIcon={<i className="fas fa-minus-circle mt-10 h-30" />}
                                                                onClick={(e) => removeItem(e, "exclude_dates", excludeDays)}
                                                            />
                                                        </div>
                                                </div>
                                                )) : ""
                                        }
                                        <ColAtm className="mt-32" md={12}>
                                            <ButtonAtm
                                                className="m-auto room-rate-modal-save bg-red"
                                                type="submit"
                                                buttontext={ t('btn-save') }
                                                color="primary"
                                            />
                                        </ColAtm>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </CustomTabPanel>
                        : <div className='d-none'></div>
                    }
                    <CustomTabPanel value={tabValue} index={0}>

                        {promotionReducers.createPromotion.status === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}

                        {isDefined(promotionReducers) &&
                        isDefined(promotionReducers.createPromotionError) &&
                        isDefined(promotionReducers.createPromotionError.response) &&
                        <Alert severity="error">{promotionReducers.createPromotionError.response.data.message}</Alert>}

                        {
                            isDefined(promotionType) &&
                        <>
                            <Grid item xs={12} md={12}>
                                <TextValidator
                                    label={ t('enw-input-coupon-title') }
                                    name="title"
                                    fullWidth
                                    value={title}
                                    autoComplete="off"
                                    onChange={changeTitle}
                                    // validators={['minNumber:1']}
                                    // errorMessages={['minNumber 1']}
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                        }

                        <Grid container>
                            <Grid item xs={12}>
                                <SelectValidator
                                    onChange={changePromotionType}
                                    className="form-control"
                                    variant="outlined"
                                    fullWidth
                                    label={ t('enw-input-promotion_type') }
                                    value={promotionType}
                                    validators={['required']}
                                    errorMessages={[ t("enw-notification_this_is_required") ]}
                                >
                                    {
                                        isDefined(promotionReducers.getPromotionTypes) && isDefined(promotionReducers.getPromotionTypes.promotion_type) &&
                                        promotionReducers.getPromotionTypes.promotion_type.map((type) => (
                                            <MenuItem value={type.id} >
                                                { t(type.language_key) }
                                            </MenuItem>
                                        ))
                                    }
                                </SelectValidator>
                            </Grid>
                        </Grid>

                        {
                            isDefined(promotionType) &&
                            <>
                                
                                {/* <Grid item xs={12} md={12}>
                                    <TextValidator
                                        label={ t('enw-input-promotion_title') }
                                        name="title"
                                        fullWidth
                                        value={title}
                                        autoComplete="off"
                                        onChange={changeTitle}
                                        // validators={['minNumber:1']}
                                        // errorMessages={['minNumber 1']}
                                        variant="outlined"
                                    />
                                </Grid> */}
                                <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                    {
                                        promotionType === 3 &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextValidator
                                                    label={ t('enw-input-day_before') }
                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                    name="dayBefore"
                                                    fullWidth
                                                    value={dayBefore}
                                                    autoComplete="off"
                                                    onChange={changeDayBefore}
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={[ t("enw-notification_this_is_required") ]}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        promotionType === 4 &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextValidator
                                                    label={ t('enw-input-last_minute') }
                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                    name="lastMinute"
                                                    fullWidth
                                                    value={lastMinute}
                                                    autoComplete="off"
                                                    onChange={changeLastMinute}
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={[ t("enw-notification_this_is_required") ]}
                                                    disabled={isSameDayChecked ? true : false}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} md={promotionType === 3 || promotionType === 4  ? 6 : 12}>
                                        <TextValidator
                                            label={ t('enw-input-discount_rate') }
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                            name="amount"
                                            fullWidth
                                            value={amount}
                                            onChange={changeAmount}
                                            autoComplete="off"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>
                                            }}
                                            validators={['required', 'minNumber:0', 'maxNumber:100']}
                                            errorMessages={[t("enw-notification_this_is_required"), 'minNumber 0', 'maxNumber 100']}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    promotionType === 2 &&
                                    <>
                                        <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={ t('enw-input-promotion_start_date')}
                                                        className="w-100"
                                                        variant="inline"
                                                        autoOk
                                                        inputVariant="outlined"
                                                        format="dd.MM.yyyy"
                                                        value={startDate}
                                                        onChange={changeStartDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <TodayIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={ t('enw-input-promotion_end_date')}
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        className="w-100"
                                                        format="dd.MM.yyyy"
                                                        autoOk
                                                        value={endDate}
                                                        minDate={ moment(startDate).add(1,'days') }
                                                        onChange={changeEndDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <InsertInvitationIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={ t('enw-input-reservation_start_date') }
                                                        className="w-100"
                                                        variant="inline"
                                                        autoOk
                                                        inputVariant="outlined"
                                                        format="dd.MM.yyyy"
                                                        value={reservationStartDate}
                                                        onChange={changeReservationStartDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <TodayIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={t('enw-input-reservation_end_date')}
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        className="w-100"
                                                        format="dd.MM.yyyy"
                                                        autoOk
                                                        value={reservationEndDate}
                                                        minDate={ moment(reservationStartDate).add(1,'days') }
                                                        onChange={changeReservationEndDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <InsertInvitationIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </>
                                }
                                <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} md={12} className='txt-al-center'>
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Mon"
                                            control={<Checkbox color="primary" value="1" defaultChecked={true} id="1" />}
                                            label={ t('day-short-monday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Tue"
                                            control={<Checkbox color="primary" id="2" value="2" defaultChecked={true} />}
                                            label={ t('day-short-tuesday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Wed"
                                            control={<Checkbox color="primary" id="3" value="3" defaultChecked={true} />}
                                            label={ t('day-short-wednesday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Tue"
                                            control={<Checkbox color="primary" id="4" value="4" defaultChecked={true} />}
                                            label={ t('day-short-thursday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Fri"
                                            control={<Checkbox color="primary" id="5" value="5" defaultChecked={true} />}
                                            label={ t('day-short-friday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Sat"
                                            control={<Checkbox color="primary" id="6" value="6" defaultChecked={true} />}
                                            label={ t('day-short-saturday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                        <FormControlLabel
                                            className="desc-text pr-10 pl-10-mobile"
                                            name="Sun"
                                            control={<Checkbox color="primary" id="7" value="7" defaultChecked={true} />}
                                            label={ t('day-short-sunday') }
                                            labelPlacement="left"
                                            onClick={handleCheckboxChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    {
                                        promotionType === 5 || promotionType === 4 ?
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                className="desc-text"
                                                name="is_mobile"
                                                control={<Checkbox color="primary" value="1" />}
                                                label={ t('enw-promotion-hours') }
                                                labelPlacement="left"
                                                onClick={changeTime}
                                            />
                                        </Grid>
                                        : <div className='d-none'></div>
                                    }
                                    

                                    {
                                        isTime &&
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={3} className="mobile-no-spacing" >
                                                <Grid item xs={12} md={6}>
                                                    <InputAtm
                                                        id="time"
                                                        label={ t('enw-promotion-start-time') }
                                                        type="time"
                                                        defaultValue="00:00"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        onChange={changeStartTime}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <InputAtm
                                                        id="time"
                                                        label={ t('enw-promotion-end-time') }
                                                        type="time"
                                                        defaultValue="00:00"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        onChange={changeEndTime}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>


                                <Grid container justify="space-between" spacing={3} className={promotionType === 2 || promotionType === 3 ? "mobile-no-spacing mt-18" : "mobile-no-spacing"}>
                                    <Grid item xs={12} md={6} className={promotionType === 5 && promotionType === 4 ? "mt-0" : "mt-0"}>
                                        <FormControlLabel
                                            className={promotionType === 2 || promotionType === 3 ? "desc-text mt-6" : "desc-text"}
                                            name="is_mobile"
                                            control={<Checkbox color="primary" value="1" />}
                                            label={ t('enw-promotion-mobile') }
                                            labelPlacement="left"
                                            onClick={changeIsMobile}
                                        />

                                        {
                                            promotionType === 3  ?
                                                <FormControlLabel
                                                className="desc-text mt-14 mt-0-prom"
                                                name="is_mobile"
                                                control={<Checkbox color="primary" value="1" />}
                                                label={ t('enw-promotion-exclude_dates') }
                                                labelPlacement="left"
                                                onClick={handleExcludeValue}
                                            /> : <div className='d-none'></div>
                                        }
                                        {
                                            promotionType === 5  ?
                                                <FormControlLabel
                                                className="desc-text mt-14 mt-0-prom"
                                                name="is_mobile"
                                                control={<Checkbox color="primary" value="1" />}
                                                label={ t('enw-promotion-exclude_dates') }
                                                labelPlacement="left"
                                                onClick={handleExcludeValue}
                                            /> : <div className='d-none'></div>
                                        }

                                        {
                                        promotionType === 4 &&
                                       
                                            <FormControlLabel
                                                className="desc-text mt-14 mt-0-prom"
                                                name="is_mobile"
                                                control={<Checkbox color="primary" value="1" />}
                                                label={ t('enw-promotion-same_day_checkin') }
                                                labelPlacement="left"
                                                onClick={changeSameDay}
                                            />
                                       
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6} className={promotionType === 5 && promotionType === 4 ? "mt-50-m mt-0" : "mt-12"}>
                                        <TextValidator
                                            label={ t('enw-input-min_stay') }
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                            name="minStay"
                                            fullWidth
                                            value={minStay}
                                            autoComplete="off"
                                            onChange={changeMinStay}
                                            validators={['minNumber:1']}
                                            errorMessages={['minNumber 1']}
                                            variant="outlined"
                                            className='mt-0'
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </>
                        }
                            <ColAtm className="mt-32" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </CustomTabPanel>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </>
    );
}
