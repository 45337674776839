import React        from 'react';
import PropTypes    from 'prop-types';

import CheckboxAtm  from "../../atomic/Checkbox/Checkbox";
import ColAtm       from "../../atomic/Col/Col";
import IconAtm      from "../../atomic/Icon/Icon";
import BlockAtm from "../../atomic/Block/Block";
import {Tooltip} from "@material-ui/core";
import OverFlowText from "../OverFlowText/OverFlowText";
import {useTranslation} from "react-i18next";


const FactCheckBox = props => {
    const { t } = useTranslation();

    return(
        //  <ColAtm className={props.colClassname} md={2} xs={12} value={props.value}> Yeni Offer da bu şekilde yapmıştık
        <ColAtm className={props.colClassname} md={props.md} xs={6} sm={props.sm} lg={props.lg}>
            <BlockAtm className={props.blockClass} onClick={props.onClick}>
                {props.checked ? (
                    <div style={{'float': 'right'}}>
                        <Tooltip title={props.isFeature === true ? t('enw-close-featured-feature') : t('enw-open-featured-feature')}>
                            <i onClick={props.handlerIsfeature} className={props.isFeature === true ?
                                'channel-active-icon fas fa-star green bg-icon-green' : 'channel-active-icon fas fa-star gray'}/>
                        </Tooltip>

                    </div>
                ) : (<></>)}

                <CheckboxAtm
                    name={props.name}
                    id={props.id}
                    className={props.className}
                    onChange={props.onChange}
                    checkboxId={props.checkboxId}
                    value={props.value}
                    tooltip={props.tooltip}
                    labelPlacement={props.labelPlacement}
                    checked={props.checked}
                    defaultChecked={props.defaultChecked}
                />
                <label htmlFor={props.htmlFor}>
                    <label htmlFor={props.htmlFor}>
                        <IconAtm iconClass={props.iconClass}/>
                        <BlockAtm className={props.labelIconClass}>
                            <OverFlowText className={props.labelClass} htmlFor={props.htmlFor}> {props.text} </OverFlowText>
                        </BlockAtm>
                    </label>
                </label>
            </BlockAtm>
        </ColAtm>
    )
};

FactCheckBox.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    tooltip: PropTypes.any,
    labelIconClass: PropTypes.string,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    md: PropTypes.any,
    lg: PropTypes.any,
    sm: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
    isFeature: PropTypes.any,
    handlerIsfeature: PropTypes.any,
};

export default FactCheckBox;
