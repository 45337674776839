import {getUpdateManualPayment, updateManualPayment} from "../../../constants/Payment/createPayment.constants";

const initialState = {
    getUpdateFetching : false,
    getUpdateData : [],
    getUpdateError : {},

    updateFetching : false,
    updateData : [],
    updateError : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getUpdateManualPayment.GET_UPDATE_MANUAL_PAYMENT_DATA_PENDING:
            return {
                ...state,
                getUpdateFetching: true,
                getUpdateError : {}
            };
        case getUpdateManualPayment.GET_UPDATE_MANUAL_PAYMENT_DATA_FULFILLED:
            return {
                ...state,
                getUpdateFetching: false,
                getUpdateData: action.payload,
                updateError : {}
            };
        case getUpdateManualPayment.GET_UPDATE_MANUAL_PAYMENT_DATA_REJECTED:
            return {
                ...state,
                getUpdateError: action.payload,
                getUpdateFetching: false
            };
        case updateManualPayment.UPDATE_MANUAL_PAYMENT_PENDING:
            return {
                ...state,
                updateFetching: true,
                updateError : {}
            };
        case updateManualPayment.UPDATE_MANUAL_PAYMENT_FULFILLED:
            return {
                ...state,
                updateFetching: false,
                updateData: action.payload,
            };
        case updateManualPayment.UPDATE_MANUAL_PAYMENT_REJECTED:
            return {
                ...state,
                updateError: action.payload,
                updateFetching: false
            };
        default:
            return state
    }
}
