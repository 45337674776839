import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import axios from "axios";
import {CHANNEL_ROOM_RATE_GET, ROOM_RATE_CHANNEL_UPDATE} from "../../../../constants/api.constants";

export function getChannelRoomRate(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id : params.channel_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_ROOM_RATE_GET',
            payload: axios.post(CHANNEL_ROOM_RATE_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
export function updateChannelRoomRate(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                room_rate_channel_mapping: params.room_rate_channel_mapping
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_RATE_CHANNEL_UPDATE',
            payload: axios.post(ROOM_RATE_CHANNEL_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateChannelRoomRateClear() {

    return dispatch => {
        dispatch({
            type: 'ROOM_RATE_CHANNEL_UPDATE_CLEAR',
        })
    }

}
