import {paymentManualTransactionListConstants} from "../../../constants/Payment/paymentTransaction.constants";

const initialState = {
    fetching : false,
    manualTransactionList : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case paymentManualTransactionListConstants.PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case paymentManualTransactionListConstants.PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                manualTransactionList: action.payload,
            };
        case paymentManualTransactionListConstants.PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
