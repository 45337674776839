import {combineReducers}            from "redux"
import userReducer                  from "./userReducer"
import propertyReducer              from "./propertyReducer";
import factReducer                  from "./factReducer";
import generalInformationReducer    from "./Content/generalInformationReducer";
import ExecutiveContactReducers     from "./Content/ExecutiveContactReducers";
import dashboardReducers            from "./dashboardReducers";
import menuReducers                 from "./menuReducers";
import contactReducers              from "./Content/contactReducers";
import propertyRoomAndRateReducers  from "./propertyRoomAndRateReducers";
import roomTypesReducer             from "./roomTypesReducer";
import bedTypesReducer              from "./bedTypesReducer";
import inventoryReducer             from "./Network/Invertory/inventoryReducer";
import channelListReducers          from "./channelListReducers";
import facilityContactReducer       from "./facilityContactReducer";
import roomFactReducer              from "./Content/Room/RoomFactReducer";
import roomPhotoReducer             from "./Content/Room/roomPhotoReducer";
import brandReducer                 from "./Content/brandReducers";
import createOfferReducer           from "./Offer/CreateOffer/createOfferReducer";
import editOfferReducer             from "./Offer/EditOffer/editOfferReducer";
import priceOfferReducer            from "./Offer/PriceOffer/priceOfferReducer";
import offerListReducer             from "./Offer/offerListReducer";
import landingOfferReducer          from "./Offer/LandingOffer/landingOfferReducer";
import dashboardOfferReducer        from "./Offer/DashboardOffer/dashboardOfferReducer";
import approveOfferReducer          from "./Offer/ApproveOffer/approveOfferReducer";
import userRegisterReduser          from "./User/Register/userRegisterReduser";
import profileReducer               from "./User/Profile/profileReducer";
import updatePasswordReducer        from "./User/UpdatePassword/updatePasswordReducer";
import userForgotPasswordReducer    from "./User/ForgotPassword/userForgotPasswordReducer";
import resetPasswordReducer         from "./User/ResetPassword/resetPasswordReducer";
import languagesReducer             from "./languagesReducer";
import createPropertyReducer        from "./createPropertyReducer";
import RoomViewTypesReducer         from "./Content/Room/RoomViewTypesReducer";
import FactListReducer              from "./Content/FactList/FactListReducer";
import roomEditReducer              from "./Content/Room/roomEditReducer";
import webReducer                   from "./webReducer";
import getEditContentWebReducer     from "./Web/EditWeb/getEditContentWebReducer";
import updateEditContentWebReducer  from "./Web/EditWeb/updateEditContentWebReducer";
import RoomSizeTypeReducer          from "./Content/Room/RoomSizeTypeReducer";
import channelSetupReducers         from "./Network/Channel/channelSetupReducers";
import allLanguagesReducer          from "./allLanguagesReducer";
import channelConnectReducer        from "./Network/Channel/channelConnectReducer";
import channelRoomRateReducer       from "./Network/Channel/channelRoomRateReducer";
import channelCancellationReducers  from "./Network/Channel/channelCancellationReducers";
import channelPaymentReducer        from "./Network/Channel/channelPaymentReducer";
import channelPaxPricingReducers    from "./Network/Channel/channelPaxPricingReducers";
import updateCancellationPolicyReducer from "./Network/Channel/CancellationPolicy/updateCancellationPolicyReducer";
import updatePersonPricingReducer from "./Network/Channel/PersonPricing/updatePersonPricingReducer";
import reservationListReducer from "./Network/Reservation/ReservationList/reservationListReducer";
import reservationDetailReducer from "./Network/Reservation/ReservationDetail/reservationDetailReducer";
import roomFactUpdateReducer from "./Content/Room/roomFactUpdateReducer";
import channelRoomRateUpdateReducer from "./Network/Channel/channelRoomRateUpdateReducer";
import bulkUpdateReducer from "./Network/Invertory/bulkUpdateReducer";
import bulkUpdateOptionsReducer from "./Network/Invertory/bulkUpdateOptionsReducer";
import contentPdfReducer from "./Content/ContentPDF/contentPdfReducer";
import downloadContentPhotosReducers from "./Content/DownloadContentPhotos/downloadContentPhotosReducers";
import paymentReducers from "./Payment/paymentReducers";
import BookingEngineDashboardReducers from "./BookingEngine/BookingEngineDashboard/BookingEngineDashboardReducers";
import updateFactMappingReducer from "./Content/FactList/updateFactMappingReducer";
import placesGetCategoriesReducer from "./Content/Places/placesGetCategoriesReducer";
import placeCreateReducer from "./Content/Places/placeCreateReducer";
import getPlaceListReducer from "./Content/Places/getPlaceListReducer";
import getPlacePhotoListReducer from "./Content/Places/getPlacePhotoListReducer";
import updatePlaceReducer from "./Content/Places/updatePlaceReducer";
import paymentTransactionReducer from "./Payment/paymentTransactionReducer";
import placeFactReducer from "./Content/Places/placeFactReducer";
import paymentDashboardReducer from "./Payment/paymentDashboardReducer";
import awardsCertificatesReducers from "./Content/AwardsCertificates/awardsCertificatesReducers";
import paymentManualCreateReducers from "./Payment/paymentManualCreateReducers";
import paymentManualUpdateReducers from "./Payment/paymentManualUpdateReducers";
import paymentManualTransactionListReducer from "./Payment/paymentManualTransactionListReducer";
import promotionReducers from "./Network/Promotion/promotionReducers";
import landingContactFormReducer from "./User/LandingContactForm/landingContactFormReducer";
import networkDashboardReducer from "./Network/Dashboard/networkDashboardReducer";
import propertyProductsReducer from "./propertyProductsReducer";
import channelGroupReducers from "./Network/Channel/channelGroupReducers";
import groupInventoryReducer from "./Network/GroupInvertory/groupInventoryReducer";
import channelContactReducers from "./Network/Channel/channelContactReducers";
import inventoryLinkReducer from "./Network/Invertory/inventoryLinkReducer";
import ticketReducer from "./Network/Reservation/ReservationDetail/ticketReducer";
import createOrUpdateTicketReducer from "./Network/Reservation/ReservationDetail/createOrUpdateTicketReducer";
import cpaReducer from "./Network/Invertory/cpaReducer";
import quickPricingReducer from "./Network/Invertory/quickPricingReducer";
import addonsReducers from "./Network/Addons/addonsReducers";
import couponsReducers from "./Network/Coupons/couponsReducers";
import blogReducers from "./Web/Blog/blog.reducer";
import popupReducers from "./Web/Popup/popup.reducer";
import sendBookingMailReducer from "./Network/Reservation/ReservationDetail/sendBookingMailReducer";

const appReducer = combineReducers({
    userReducer,
    propertyReducer,
    factReducer,
    generalInformationReducer,
    facilityContactReducer,
    channelListReducers,
    ExecutiveContactReducers,
    dashboardReducers,
    menuReducers,
    contactReducers,
    propertyRoomAndRateReducers,
    roomTypesReducer,
    bedTypesReducer,
    inventoryReducer,
    roomFactReducer,
    roomPhotoReducer,
    brandReducer,
    createOfferReducer,
    offerListReducer,
    priceOfferReducer,
    editOfferReducer,
    landingOfferReducer,
    dashboardOfferReducer,
    approveOfferReducer,
    userRegisterReduser,
    profileReducer,
    updatePasswordReducer,
    allLanguagesReducer,
    userForgotPasswordReducer,
    resetPasswordReducer,
    languagesReducer,
    createPropertyReducer,
    RoomViewTypesReducer,
    FactListReducer,
    roomEditReducer,
    webReducer,
    getEditContentWebReducer,
    updateEditContentWebReducer,
    RoomSizeTypeReducer,
    channelSetupReducers,
    channelConnectReducer,
    channelRoomRateReducer,
    channelCancellationReducers,
    channelPaymentReducer,
    channelPaxPricingReducers,
    updatePersonPricingReducer,
    updateCancellationPolicyReducer,
    reservationListReducer,
    reservationDetailReducer,
    roomFactUpdateReducer,
    channelRoomRateUpdateReducer,
    bulkUpdateReducer,
    bulkUpdateOptionsReducer,
    contentPdfReducer,
    downloadContentPhotosReducers,
    paymentReducers,
    BookingEngineDashboardReducers,
    updateFactMappingReducer,
    placesGetCategoriesReducer,
    placeCreateReducer,
    getPlaceListReducer,
    getPlacePhotoListReducer,
    updatePlaceReducer,
    paymentTransactionReducer,
    placeFactReducer,
    paymentDashboardReducer,
    awardsCertificatesReducers,
    paymentManualCreateReducers,
    paymentManualUpdateReducers,
    paymentManualTransactionListReducer,
    promotionReducers,
    landingContactFormReducer,
    networkDashboardReducer,
    propertyProductsReducer,
    channelGroupReducers,
    groupInventoryReducer,
    channelContactReducers,
    inventoryLinkReducer,
    ticketReducer,
    createOrUpdateTicketReducer,
    cpaReducer,
    quickPricingReducer,
    addonsReducers,
    couponsReducers,
    blogReducers,
    popupReducers,
    sendBookingMailReducer
});


//const rootReducers = combineReducers();
const rootReducers = ( props, action) => {
    if (action.type === 'CLEAR_STATE') {
        props = '';
    }

    return appReducer(props, action);
};

export default rootReducers;
