import React from "react";
import {connect} from "react-redux";
import ColAtm from "../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {Link} from "react-router-dom";
import LabelAtm from "../../../atomic/Label/Label";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ButtonAtm from "../../../atomic/Button/Button";
import {getProfile} from "../../../../redux/actions/User/Profile/profileAction";
import {createWeb, getWeb, updateWeb} from "../../../../redux/actions/webActions";
import {publishWebSubmit} from "../../../../redux/actions/Web/PublishWeb/publishWebActions";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import WebDialog from './components/NewWeb/WebDialog';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import WebPreviewDialog from "./components/PreviewWeb/WebPreviewDialog";
import PublishWeb from "./components/PublishWeb/PublishWeb";
import Alert from '@material-ui/lab/Alert';
import {CircularProgress, Hidden} from "@material-ui/core";
import {
    APP,
    BOOKING_ENGINE,
    CONTENT,
    NETWORK, NOT_ALLOWED_DASHBOARD_SHORT,
    OFFER,
    PAYMENT,
    WEB_EDIT_SHORT
} from "../../../../constants/appUrls.constants";
import {BASE_LIVE_WEB} from "../../../../constants/api.constants";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {history} from "../../../../utils/history";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {getPropertyName, isDefined} from "../../../../utils";
import {propertyProducts} from "../../../../redux/actions/propertyProductsActions";
import NotAllowedPage from "../../Global/NotAllowedPage/NotAllowedPage";
import {Skeleton} from "@material-ui/lab";
import {Animated} from "react-animated-css";
import { WorldMap } from "react-svg-worldmap"
import Charts from "../../Global/Charts";

let t=null;
const drawerWidth = 101;
const leftMenuWidth = 252;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '999',
    },
    leftMenu: {
        width: 0,
        flexShrink: 0,
    },
    leftMenuPaper: {
        width: leftMenuWidth,
        marginLeft: 102,
        marginTop: 81
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '81px',
        display: 'inline-grid'
    },
    listItem: {
        display: 'block',
        textAlign: 'center !important',
        margin: 'auto',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftBarShift: {
        width: `calc(100% - ${leftMenuWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        boxShadow: '0 2px 4px 0 #ccced5',
        backgroundColor: '#ffffff',
        padding: '11px 12.14px'
    },
    hide: {
        display: 'none',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 125,
        marginRight: -60,
        paddingRight: 0,
    },
    content: {
        flexGrow: 1,
        padding: '24px 68px',
    },
    toolbar: theme.mixins.toolbar,
}));

class CreateWeb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSuccessHeader: false,
            open : false,
            open_preview: false,
            property_web_id: "",
            is_publish: 1
        };
        t = props.t;
    }


    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClickOpenPreview = () => {
        this.setState({open_preview:true});
    };

    handleClose = () => {
        this.setState({open:false, open_preview:false});
    };


    handleSubmitClose = () => {
        this.setState({open:false, open_preview:false});
    };

    handlePreviewClose = () => {
        this.setState({open_preview:false});
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getProfile();
        this.props.getWeb();
        this.props.propertyProducts();
    }


    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={APP}
                               exitText={ t('btn-properties')}
                               text={ getPropertyName() }
                               showSuccessHeader={this.props.createStatus}
        />
    };


    handlePublish = (id) => (e) => {
        e.preventDefault();

        this.props.publishWebSubmit( this.state, id, this.state.is_publish );
    };

    render() {

        const url = BASE_LIVE_WEB;
        const domain = isDefined(this.props.webReducer) && isDefined(this.props.webReducer.domains) && this.props.webReducer.domains

        const allowedPage = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageOffer = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        function Sidebar(props) {
            const classes = useStyles();

            const sidebar = (
                <div className={classes.root}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <List className={classes.listItem}>
                            {props.siderbarItems.map((post, key) =>
                                <Link key={key} to={post.link} className="completed-title" variant="body2" color="textSecondary" >
                                    <ListItem className={post.isActive === true ? 'active-sideMenu sidebar-list': 'sidebar-list'} button>
                                        <div className={post.isActive === true ? 'active-sidebar': 'sidebar-items'}>
                                            <i className={post.icon} />
                                        </div>
                                        <ListItemText className="dashboard-menu" primary={post.text} />
                                    </ListItem>
                                </Link>
                            )}
                        </List>
                        <Charts />
                    </Drawer>
                </div>
            );
            return (
                <>
                    {sidebar}
                </>
            );
        }
        const siderbarItems = [
            {id: 1, isActive: false, text: `${ t('enw-product_one-title') }`, icon: 'fas fa-hotel', link:"content"},
            {id: 2, isActive: false, text: `${t('enw-product_two-title') }`, icon: 'fas fa-chart-network', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? "network" : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#"},
            {id: 3, isActive: false, text: `${t('enw-product_three-title') }`, icon: 'fas fa-paperclip', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? "offer" : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#"},
            {id: 4, isActive: true, text: `${t('enw-product_four-title') }`, icon: 'fas fa-network-wired', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? "web" : NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#"},
            {id: 5, isActive: false, text: `${t('enw-product_six-title')}`, icon: 'fas fa-calendar-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? "booking-engine" : NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#"},
            {id: 6, isActive: false, text: `${t('enw-product_five-title') }`, icon: 'fas fa-money-bill-wave-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? "payment" : NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#"}
        ];

        return (
            <>
                <PageTitle title={ t('enw-myw-title') } />
                {
                    this.headerStatusShow()
                }

                <Grid container className="web-dashboard">
                    <Grid item md={1} className="m-none" style={{ height: 'fit-content'}}>
                        <Sidebar siderbarItems={siderbarItems} />
                    </Grid>
                    <Grid item xs={12} className="m-auto">
                        <div>
                            <CssBaseline />
                            {
                                isDefined(allowedPage[0]) && !allowedPage[0].property_has_product ?
                                    <Grid item xs={12} lg={6} className="no-buy-product no-buy-product-web">
                                        <Card className="p-24">
                                            <NotAllowedPage />
                                        </Card>
                                    </Grid>
                                    :

                                    <Grid container spacing={3} className="mt-60 web-dashboard centered-position">
                                        {
                                            isDefined(this.props.webReducer) && !this.props.webReducer.fetching && this.props.webReducer.domains.length === 0 ?
                                                <Grid item md={6} xs={12} className="m-auto">
                                                    <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                                        <Container maxWidth="xl">
                                                            <div className="container-width">
                                                                <ColAtm xs={12} className="photoButtonsRow one-page-content">
                                                                    <ColAtm>
                                                                        <div className="m-auto first-create-room">
                                                                    <span>
                                                                       <ButtonAtm
                                                                           buttontext={ t('btn-new_room') }
                                                                           className="filter-action-button"
                                                                           startIcon={<i className="fas fa-laptop" />}
                                                                           onClick={this.handleClickOpen}
                                                                       />
                                                                        {<WebDialog
                                                                            languages={this.props.profileReducer.profileData.languages}
                                                                            createWeb={this.props.createWeb}
                                                                            updateWeb={this.props.updateWeb}
                                                                            webReducer={this.props.webReducer}
                                                                            handleClickOpen = {this.handleClickOpen}
                                                                            handleSubmitClose = {this.handleSubmitClose}
                                                                            handleClose = {this.handleClose}
                                                                            open ={this.state.open} // "Create your Website" "Kendi internet siteni oluşturabilir kampanya ekleyebilir, düzenleyebilir, ziyaretçilerini kontrol edebilirsiniz."
                                                                        />}
                                                                    </span>
                                                                        </div>
                                                                    </ColAtm>
                                                                    <LabelAtm labelClass="one-page-title" text={ t('btn-create_your_website') } />
                                                                    <LabelAtm labelClass="one-page-subtitle p-0-100" text={ t('enw-myw-create_your_website-desc') } />
                                                                </ColAtm>
                                                            </div>
                                                        </Container>
                                                    </Animated>
                                                </Grid>
                                                :
                                                <Grid item sm={8} lg={6} className="m-auto web-stats">

                                                    {
                                                        (domain.is_published === 1 && domain.is_dns_checked === 0) &&

                                                        <Alert className="mb-16 wait-web-site" severity="info"  iconMapping={{ info: <i className="fas fa-clock"/> }}>
                                                            {t('btn-wait-publish')}
                                                        </Alert>
                                                    }
                                                    <Grid container spacing={3}>
                                                        <Grid item md={8} xs={12} className="m-auto m-pt-20">
                                                            <Card className="offer-dashboard__box-shadow" item xs={12}>
                                                                <>
                                                                    <Button
                                                                        className="p-0"
                                                                        onClick={this.handleClickOpenPreview}
                                                                        disabled={this.props.webReducer.fetching}
                                                                        fullWidth
                                                                    >
                                                                        <Box className="general_dashboard w-100 bg-blue" component="fieldset" mb={3} borderColor="transparent">
                                                                            <p className="preview-web-text blue">
                                                                                <i className="fas fa-search" />
                                                                                { t('btn-site_preview')}
                                                                            </p>
                                                                            <iframe className="site-frame" src={url +domain.token} scrolling="no" title="create-web-frame"/>
                                                                        </Box>
                                                                    </Button>

                                                                    <CardContent className="min-height-156 pt-20 pr-24 pl-24">
                                                                        <ColAtm>
                                                                            <ColAtm md={8} xl={9}>
                                                                                <Typography className="blue pb-12" gutterBottom variant="h5" component="h2">
                                                                                    { t('enw-myw-site_information-title')}
                                                                                </Typography>
                                                                            </ColAtm>
                                                                        </ColAtm>
                                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                                            { t('enw-myw-domain_title') } : <b><Link to={{pathname: url +domain.token}}  target="_blank"> {domain.domain} </Link></b>

                                                                            {
                                                                                (domain.is_published === 1 && domain.is_dns_checked === 1) &&
                                                                                <i className="fas fa-check-circle web-check-icon" />
                                                                            }
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary" component="p">

                                                                            { t('enw-web-default_language_title') } : <b> {
                                                                            domain.property_web_language ? t(domain.property_web_language.language_key) : '' }
                                                                        </b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                                            { t('enw-myw-token') } :  <b> { domain.token} </b>
                                                                        </Typography>
                                                                        <WebDialog
                                                                            languages={this.props.profileReducer.profileData.languages}
                                                                            createWeb={this.props.createWeb}
                                                                            updateWeb={this.props.updateWeb}
                                                                            webReducer={this.props.webReducer}
                                                                            handleClickOpen = {this.handleClickOpen}
                                                                            handleSubmitClose = {this.handleSubmitClose}
                                                                            handleClose = {this.handleClose}
                                                                            open ={this.state.open}
                                                                        />

                                                                        <WebPreviewDialog handleClickOpenPreview = {this.handleClickOpenPreview}
                                                                                          handlePreviewClose = {this.handlePreviewClose}
                                                                                          open_preview ={this.state.open_preview}
                                                                                          token={domain.token}
                                                                                          webId={domain.id}
                                                                                          webDefaultLang={ domain.property_web_language ? domain.property_web_language.code : domain.default_language }
                                                                                          openSetup={this.handleClickOpen}
                                                                                          closeSetup = {this.handleClose}
                                                                        />

                                                                        <Divider className="mt-22" />

                                                                        <div className="mt-22">
                                                                            <Grid container justify="center" spacing={3}>
                                                                                <Grid item xs={6}>
                                                                                    <div className="d-grid">
                                                                                        <Link
                                                                                            className={`${this.props.webReducer.fetching && "disabled-link"} site-edit-btn`}
                                                                                            to={WEB_EDIT_SHORT + domain.id}
                                                                                        >
                                                                                            {t('btn-edit')}
                                                                                        </Link>
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={6}>
                                                                                    <ButtonAtm
                                                                                        className={`${this.props.webReducer.fetching && "disabled-link"} site-setup-btn`}
                                                                                        fullwidth={true}
                                                                                        buttontext={ t('btn-setup') }
                                                                                        onClick={this.handleClickOpen}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            {
                                                                                (domain.is_published === 0 && domain.is_dns_checked === 0) &&
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={12}>
                                                                                        <ButtonAtm
                                                                                            className={(this.props.webReducer.publishWebData && this.props.webReducer.publishWebData.status === 200) ? "d-none" : "site-preview-btn"}
                                                                                            fullwidth={true}
                                                                                            buttontext={t('btn-publish')}
                                                                                            value={domain.id}
                                                                                            onClick={this.handlePublish(domain.id)}
                                                                                        />
                                                                                        <PublishWeb publishWeb={this.props.webReducer} />
                                                                                    </Grid>
                                                                                </Grid>

                                                                            }
                                                                        </div>
                                                                    </CardContent>
                                                                </>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item md={4} xs={12} className="m-auto">
                                                            <Card className="offer-dashboard__box-shadow min-height-389" item xs={12}>
                                                                <CardContent className="min-height-156 p-24">
                                                                    <h3 className="green country-text">{ t('enw-myw-dashboard-country-title') }</h3>
                                                                    <Grid container>
                                                                        <Grid item lg={12} className="m-auto">
                                                                            {
                                                                                !this.props.webReducer.fetching && isDefined(domain.log) ?
                                                                                    <WorldMap color="green" value-suffix="people" size="sm" data={domain.log.country_count} />
                                                                                    :
                                                                                    <div className="w-100">
                                                                                        <Skeleton className="mb-12 radius-8" animation="wave" height={160} />
                                                                                    </div>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container className="web-countries">
                                                                        {
                                                                            !this.props.webReducer.fetching && isDefined(domain.log) ?

                                                                                <Grid item xs={12} className={domain.log.country_count.length > 0 ? "" : "t-center"}>
                                                                                    { domain.log.country_count.length > 0 ? domain.log.country_count.map( (value, key) => {
                                                                                        return (<p key={key}>{ value.country_name } <span>{ value.value }</span></p>)
                                                                                    }) : <span className="fs-56 m-auto f-w-bold">0</span>}
                                                                                </Grid>
                                                                                :
                                                                                <div className="w-100">
                                                                                    <Skeleton className="mb-12" animation="wave" />
                                                                                    <Skeleton className="mb-12" animation="wave" />
                                                                                    <Skeleton className="mb-12" animation="wave" />
                                                                                    <Skeleton className="mb-12" animation="wave" />
                                                                                </div>
                                                                        }
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} className="mobile-offer-boxes-padding">
                                                        <Grid item xs={6} md={3}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-check green fs-22 bg-icon-green m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                <span className="fs-36 pt-18 m-auto f-w-bold">
                                                                    {
                                                                        !this.props.webReducer.fetching && isDefined(domain.log) ?
                                                                            <>{domain.log.booking_count}</>
                                                                             :
                                                                            <div className="d-none"></div>
                                                                    }
                                                                </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-myw-dashboard-site_reservation-title') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-users orange fs-22 bg-icon-orange m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="fs-36 pt-18 m-auto f-w-bold">
                                                                        {
                                                                            !this.props.webReducer.fetching && isDefined(domain.log) ?
                                                                                <>{domain.log.active_user_count}</>
                                                                                :
                                                                                <CircularProgress  color="secondary"/>
                                                                        }
                                                                    </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-myw-dashboard-active_user-title') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-mouse-pointer purple fs-22 bg-icon-purple m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="fs-36 pt-18 m-auto f-w-bold">
                                                                        {
                                                                            !this.props.webReducer.fetching && isDefined(domain.log) ?
                                                                                <>{domain.log.web_count}</>
                                                                                :
                                                                                <div className="d-none"></div>
                                                                        }
                                                                    </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-myw-dashboard-web_login-title') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <Paper className="offer-boxes">
                                                                <ColAtm sm={12} className="pt-26">
                                                                    <i className="fas fa-mobile-android blue fs-22 bg-icon-blue m-auto b-r-50" />
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                <span className="fs-36 pt-18 m-auto f-w-bold">
                                                                     {
                                                                         !this.props.webReducer.fetching && isDefined(domain.log) ?
                                                                             <>{domain.log.mobile_count}</>
                                                                             :
                                                                             <div className="d-none"></div>
                                                                     }
                                                                </span>
                                                                </ColAtm>
                                                                <ColAtm sm={12}>
                                                                    <span className="offer-total-title"> { t('enw-myw-dashboard-mobile_login-title') } </span>
                                                                </ColAtm>
                                                            </Paper>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                        }
                                    </Grid>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <BottomNavigation className="m-navigation" value="web">
                        <BottomNavigationAction value="content" icon={<i className="fas fa-hotel" />} onClick={() => { history.push(CONTENT)}}  />
                        <BottomNavigationAction value="network" icon={<i className="fas fa-chart-network" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#" )}} />
                        <BottomNavigationAction value="offer" icon={<i className="fas fa-paperclip" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? OFFER : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#")}} />
                        <BottomNavigationAction value="web" icon={<i className="fas fa-network-wired" />}/>
                        <BottomNavigationAction value="booking" icon={<i className="fas fa-calendar-alt" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? BOOKING_ENGINE :  NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#")}} />
                        <BottomNavigationAction value="payment" icon={<i className="fas fa-money-bill-wave-alt" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? PAYMENT :  NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#")}} />
                    </BottomNavigation>
                </Hidden>
            </>
        )
    }
}
const mapStateToProps = (props) => {
    return {
        profileReducer : props.profileReducer,
        webReducer : props.webReducer,
        createStatus : props.webReducer.createStatus,
        propertyProductsReducer : props.propertyProductsReducer
    }
};

const mapDispatchToProps = {
    getProfile,
    createWeb,
    getWeb,
    updateWeb,
    publishWebSubmit,
    propertyProducts
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(CreateWeb));
