import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ColAtm from "../../../../../atomic/Col/Col";
import {BASE_LIVE_WEB} from "../../../../../../constants/api.constants";

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
        height: "100%"
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});


const WebForm = (props) => {

    const classes = styles();
    const url = BASE_LIVE_WEB+props.token+'/'+props.webDefaultLang;

    return(
        <ColAtm container className={classes.root} direction="row" md={12}>
            <iframe id="previewFrame" name="previewFrame" className="site-preview-frame" src={url} title="preview-frame" />
        </ColAtm>
    )

}

export default WebForm
