import React from "react";
import {useForm,Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import InputAtm from "../../../../atomic/Input/Input";
import {FormHelperText, FormControl, Grid} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
    },
    restrictInfo: {
        paddingTop: '24px'
    }
});


const PhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 80px !important;
                  }`;

const SecondaryCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                  }`;

const MobilePhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 40px !important;
                  }`;

const FaxCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 40px !important;
                  }`;

const FacilityContactForm = (props) => {

    const defaultValues = {
        phone     : props.property_contact && props.property_contact.phone !== null ? props.property_contact.phone : '',
        mobile    : props.property_contact && props.property_contact.mobile !== null ? props.property_contact.mobile : '',
        mobile2   : props.property_contact && props.property_contact.mobile2 !== null ? props.property_contact.mobile2 : '',
        fax       : props.property_contact && props.property_contact.fax !== null ? props.property_contact.fax : '',
        email     : props.property_contact ? props.property_contact.email : '',
        web       : props.property_contact ? props.property_contact.web : '',
        country_code : props.country_code ? props.country_code : '',
        facebook  : props.social_media_addresses.facebook ? props.social_media_addresses.facebook : '',
        twitter   : props.social_media_addresses.twitter ? props.social_media_addresses.twitter : '',
        instagram : props.social_media_addresses.instagram ? props.social_media_addresses.instagram : '',
        official_name   : props.get_property && props.get_property.official_name !== null ? props.get_property.official_name : '',
        tax_office   : props.get_property && props.get_property.tax_office !== null ? props.get_property.tax_office : '',
        tax_number   : props.get_property && props.get_property.tax_number !== null ? props.get_property.tax_number : '',
    }

    const { register, handleSubmit,  errors , control, getValues, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: "onBlur",
        defaultValues
    })

    const classes = styles();
    const { t } = useTranslation();
    const phoneMinCharacterSize = 6;


    const onSubmit = (data) => {
        const mobile = document.getElementById("main-mobile-phone").value;
        const phone = document.getElementById("main-phone").value;
        const mobile2 = document.getElementById("secondary-phone").value;
        const fax = document.getElementById("fax-phone").value;
        const mobileCleaned = mobile.replace(/\s/g, ' ').replace(/-/g, '').replace(/[\(\)]/g, '')
        const phoneCleaned = phone.replace(/\s/g, ' ').replace(/-/g, '').replace(/[\(\)]/g, '')
        const mobile2Cleaned = mobile2.replace(/\s/g, ' ').replace(/-/g, '').replace(/[\(\)]/g, '')
        const faxCleaned = fax.replace(/\s/g, ' ').replace(/-/g, '').replace(/[\(\)]/g, '')
        data.mobile = mobileCleaned;
        data.phone = phoneCleaned;
        data.mobile2 = mobile2Cleaned;
        data.fax = faxCleaned;
        props.onFacilityContactSubmit(data);
        // console.log(data)
    }

    const values = getValues();
    return(
        <ColAtm container className={classes.root} direction="row" md={6}>
            <form onSubmit={handleSubmit(onSubmit)}  name="general-form" id="general-form" autoComplete="off" style={{width: 606}} >
                <LabelAtm variant="h6" component="h2" text={ t('enw-general-communication-subtitle') } />
                <InputAtm
                    name="official_name"
                    type="text"
                    label={t('enw-input-official_name')}
                    id="official_name"
                    variant="outlined"
                    fullWidth
                    inputRef={register}
                />
                <Grid container justify="space-between">
                    <div className="w-49">
                        <InputAtm
                            name="tax_office"
                            type="text"
                            label={ t('enw-input-registered_tax_office')}
                            id="tax_office"
                            variant="outlined"
                            className="w-49"
                            fullWidth
                            inputRef={register}
                        />
                    </div>
                    <div className="w-49">
                        <InputAtm
                            name="tax_number"
                            type="text"
                            label={ t('enw-input-license_number')}
                            id="tax_number"
                            variant="outlined"
                            className="w-49"
                            fullWidth
                            inputRef={register}
                        />
                    </div>

                </Grid>
                <InputAtm
                    name="web"
                    type="text"
                    variant="outlined"
                    label={ t('enw-input-web_site') }
                    fullWidth
                    autoComplete="off"
                    autoFocus
                    inputRef={register}
                />
                <InputAtm
                    name="email"
                    type="text"
                    variant="outlined"
                    label={ t('enw-input-main_email')}
                    fullWidth
                    inputRef={register({
                        required: t("enw-notification_this_is_required"),
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("enw-notification_invalid_email")
                        }
                    })}
                    error={!!errors.email}
                />
                <FormHelperText error={!!errors.email}>
                    {errors.email && errors.email.message}
                </FormHelperText>

                <Grid container justify="space-between" className="facility-phone2">
                    <div className="w-49">
                        <PhoneCard lang={t('enw-input-main_phone')}>
                            <FormControl variant="outlined"  className="mt-0" fullWidth>
                                <Controller
                                    as={
                                        <PhoneInput
                                        inputProps={{
                                            id: 'main-phone'
                                        }}
                                            country={values.country_code}
                                            enableSearch={true}
                                            placeholder={ t('enw-input-main_phone')}
                                            prefix={""}
                                        />

                                    }
                                    name="phone"
                                    control={control}
                                    rules={{
                                        validate : (value) => {
                                            if( ( watch("mobile") === 0 || watch("mobile") === null || watch("mobile") === undefined ||
                                                watch("mobile") === ''  || watch("mobile") === ' ' || watch("mobile") === '+' || watch("mobile").length < phoneMinCharacterSize )
                                                &&
                                                ( value === 0 || value === null || value === undefined || value === '' || value === ' ' || value === '+' || value.length < phoneMinCharacterSize) ){
                                                return t('enw-input-must_one_of_phone_notification')
                                            }
                                        }

                                    }}
                                    error={!!errors.phone}
                                />
                            </FormControl>
                            <FormHelperText error={!!errors.phone}>
                                {errors.phone && errors.phone.message}
                            </FormHelperText>
                        </PhoneCard>
                    </div>
                    <div className="w-49">
                        <SecondaryCard lang={t('enw-input-secondary_phone')}>
                            <FormControl variant="outlined" className="property-contact-secondary-phone mt-0" fullWidth>
                                <Controller
                                    as={
                                        <PhoneInput
                                        inputProps={{
                                            id: 'secondary-phone'
                                        }}
                                            country={values.country_code}
                                            enableSearch={true}
                                            placeholder={ t('enw-input-secondary_phone')}
                                            prefix={""}
                                        />

                                    }
                                    name="mobile2"
                                    control={control}
                                />
                            </FormControl>
                        </SecondaryCard>
                    </div>
                </Grid>
                <Grid container justify="space-between">
                    <div className="w-49" >
                        <MobilePhoneCard lang={t('enw-input-main_mobile_phone')}>
                            <FormControl variant="outlined"  className="property-contact-main-mobile-phone mt-0" fullWidth>
                                <Controller
                                    as={
                                        <PhoneInput
                                        inputProps={{
                                            id: 'main-mobile-phone'
                                        }}
                                            className="facility-mobile-phone"
                                            country={values.country_code}
                                            enableSearch={true}
                                            placeholder={ t('enw-input-phone') }
                                            prefix={""}
                                        />
                                    }
                                    name="mobile"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                            if( (value === 0 || value === null || value === undefined || value === '' || value === ' ' || value === '+' || value.length < phoneMinCharacterSize)
                                                &&
                                                (watch("phone") === 0 || watch("phone") === null || watch("phone") === undefined || watch("phone") === ''  ||
                                                    watch("phone") === ' ' || watch("phone") === '+' || watch("phone").length < phoneMinCharacterSize  ) ){
                                                return t('enw-input-must_one_of_phone_notification')
                                            }
                                        }
                                    }}
                                    error={!!errors.mobile}
                                />
                            </FormControl>
                            <FormHelperText error={!!errors.mobile}>
                                {errors.mobile && errors.mobile.message}
                            </FormHelperText>
                        </MobilePhoneCard>
                    </div>
                    <div className="w-49" >
                        <FaxCard lang={t('enw-input-fax')}>
                            <FormControl variant="outlined" className="mt-0 facility-fax-phone" fullWidth>
                                <Controller
                                    as={
                                        <PhoneInput
                                            country={values.country_code}
                                            enableSearch={true}
                                            placeholder={ t('enw-input-fax') }
                                            inputProps={{
                                                id: "fax-phone",
                                                autoFocus: false
                                            }}
                                            prefix={""}
                                        />

                                    }
                                    name="fax"
                                    control={control}
                                />
                            </FormControl>
                        </FaxCard>
                    </div>
                </Grid>
                <InputAtm
                    name="facebook"
                    type="text"
                    variant="outlined"
                    label={"Facebook"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <i className="fab fa-facebook-f" />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={register}
                />
                <InputAtm
                    name="instagram"
                    type="text"
                    variant="outlined"
                    label={"Instagram"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <i className="fab fa-instagram" />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={register}
                />
                <InputAtm
                    name="twitter"
                    type="text"
                    variant="outlined"
                    label={"Twitter"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <i className="fab fa-twitter" />
                            </InputAdornment>
                        ),
                    }}
                    inputRef={register}
                />
            </form>
        </ColAtm>
    )

}
export default FacilityContactForm
