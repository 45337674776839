import React, {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Backdrop, CircularProgress, Grid, MenuItem} from "@material-ui/core";
import {SelectValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import InputAtm from "../../../../../atomic/Input/Input";
import ColAtm from "../../../../../atomic/Col/Col";
import ButtonAtm from "../../../../../atomic/Button/Button";
import Editor from 'react-simple-wysiwyg';
import {getAppLang, getProperty, isDefined} from "../../../../../../utils";
import {
    updateBlog,
    updateBlogClearState,
    uploadBlogImage, uploadBlogImageClearState
} from "../../../../../../redux/actions/Web/Blog/blogActions";
import {getLanguages} from "../../../../../../redux/actions/languageActions";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import {BLOG_GET_CONTENT} from "../../../../../../constants/api.constants";
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../../atomic/PageHeader/PageHeader";
import {WEB_BLOG_LIST} from "../../../../../../constants/appUrls.constants";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#d71f26',
    },
}))


export default function EditBlogModal() {
    const classes = useStyles();
    const {t} = useTranslation();
    const {handleSubmit} = useForm();
    const dispatch = useDispatch();
    const location = useLocation();

    const createImage = useSelector(props => props.blogReducers?.createImage);
    const createImageError = useSelector(props => props.blogReducers?.createImageError);
    const updateBlogData = useSelector(props => props.blogReducers?.updateBlog);
    const updateBlogError = useSelector(props => props.blogReducers?.updateBlogError);
    const languageList = useSelector(props => props.languagesReducer?.languages);
    const createImageFetching = useSelector(props => props.blogReducers?.createImageFetching);

    const blogId = location.pathname.split('/').pop()

    const [html, setHtml] = useState("");
    const [filePath, setFilePath] = useState("");
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [defaultImage, setDefaultImage] = useState("");


    useEffect(() => {
        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(BLOG_GET_CONTENT + "/" + blogId, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            setLanguage(result.data.data.language_code);
            setTitle(result.data.data.title);
            setHtml(result.data.data.content);
            setDefaultImage(result.data.data.image);
            setImageUrl(result.data.data?.imageUrl?.thumb);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        dispatch(getLanguages());
    }, [dispatch]);


    useEffect(() => {
        if (updateBlogData.status === 200) {
            //dispatch(updateBlogClearState());
            //dispatch(uploadBlogImageClearState());
            //history.push(WEB_BLOG_LIST)
        }
    }, [updateBlogData])

    useEffect(() => {
        setHtml("")
        setFilePath("")
        setFile("")
        setTitle("")
        setLanguage("")
        setDefaultImage("")
        dispatch(updateBlogClearState());
        dispatch(uploadBlogImageClearState());
    }, [dispatch]);

    useEffect(() => {
        if (createImageError) {
            setFilePath("")
            setFile("")
        }
    }, [createImageError]);

    const handleChangeTitle = ({target}) => {
        setTitle(target.value);
    };

    const selectLang = ({target}) => {
        setLanguage(target.value)
    };

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            title: title,
            language_code: language,
            content: html,
            image: createImage?.image ? createImage.image : defaultImage,
            id: blogId,
        }

        dispatch(updateBlog(params));
    };

    function onChange(e) {
        setHtml(e.target.value);
    }

    const onFileChange = event => {
        event.preventDefault();
        const file = event.target.files[0];
        if (isDefined(file)) {
            setFilePath(URL.createObjectURL(file))
            setFile(event.target.files[0])
        }
        const params = {
            property_id: getProperty(),
            file: file,
        }
        dispatch(uploadBlogImage(params));
    };

    return (
        <div className="mb-64">
            <PageTitle title={t('enw-web_blog-edit')}/>
            <PageHeaderAtm backButton={WEB_BLOG_LIST} exitText={t('enw-blog-title')} text={t('enw-web_blog-edit')}/>
            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                <Container maxWidth="xl">
                    <div className="container-width">
                        <div className="blog-list">
                            <Fragment>
                                <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container className="blog-edit-and-create" spacing={3} md={8}>
                                        {updateBlogData?.status === 200 &&
                                            <Alert className="w-100" severity="success">{t('enw-success-message')}</Alert>}
                                        {updateBlogError &&
                                            <Alert className="w-100" severity="error">{updateBlogError?.response?.data?.message}</Alert>}
                                        {createImageError &&
                                            <Alert className="w-100" severity="error">{createImageError?.response?.data?.message}</Alert>}
                                        {
                                            createImageFetching &&
                                            <Backdrop className={classes.backdrop} open>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>
                                        }
                                        <Grid item xs={12} md={8}>
                                            <InputAtm
                                                label={t('enw-web_blog-title')}
                                                placeholder={t('enw-web_blog-title')}
                                                variant="outlined"
                                                autoComplete="off"
                                                onChange={handleChangeTitle}
                                                validators={['required']}
                                                errorMessages={[t("enw-notification_this_is_required")]}
                                                value={title}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} className="mb-24">
                                            <SelectValidator
                                                name="category"
                                                onChange={selectLang}
                                                value={language}
                                                className="form-control"
                                                variant="outlined"
                                                fullWidth
                                                label={t('enw-input-language')}
                                                validators={['required']}
                                                errorMessages={[t("enw-notification_this_is_required")]}
                                            >
                                                {
                                                    languageList?.map((lang) => (
                                                        <MenuItem value={lang.code}>
                                                            <img src={`/assets/img/language/${lang.code}.svg`}
                                                                 alt="en"
                                                                 style={{
                                                                     width: 20,
                                                                     marginRight: 8
                                                                 }}/> {t(lang.language_key)}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item xs={12} className="mb-24">
                                            <Editor value={html} onChange={onChange}/>
                                        </Grid>
                                        <Grid item xs={12} className="mb-24 d-flex">
                                            <Grid item xs={12} md={4} className="blog-img-area">
                                                <input
                                                    className="d-none"
                                                    accept="image/png, image/jpeg"
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={onFileChange}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        className="room-rate-add soft-blue-button m-auto radius-8"
                                                        component="span"
                                                        startIcon={<i className="fas fa-cloud-upload-alt"/>}>
                                                        {t('btn-upload_image')}
                                                    </Button>
                                                </label>
                                            </Grid>
                                            <div>
                                                <div className="blog-preview-img">
                                                    <img src={filePath ? filePath : imageUrl ? imageUrl : ""}/>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <ColAtm className="mt-32" md={12}>
                                        <ButtonAtm
                                            className="m-auto room-rate-modal-save bg-red"
                                            buttontext={t('btn-save')}
                                            color="primary"
                                            type="submit"
                                            disabled={!isDefined(html)}
                                        />
                                    </ColAtm>
                                </ValidatorForm>
                            </Fragment>
                        </div>
                    </div>
                </Container>
            </Animated>
        </div>
    );
}
