import { userConstants } from "../../../../constants/Public/user.constants";

const initialState = {
    fetching :false,
    forgotUpdate: [],
    forgotUpdateStatus:false,
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case userConstants.FORGOT_PASSWORD_PENDING:
            return {
                fetching : true,
                forgotUpdateStatus:false,
                error:{}
            };
        case userConstants.FORGOT_PASSWORD_FULFILLED:
            return {
                ...state,
                fetching : false,
                forgotUpdate: action.payload,
                forgotUpdateStatus:true,
            };
        case userConstants.FORGOT_PASSWORD_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.payload,
                forgotUpdateStatus:false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
