import React, {Component}   from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";

import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {getChannelSetup} from "../../../../redux/actions/Network/Channel/channelSetup.actions";
import {Grid} from "@material-ui/core";
import CreatePromotionModal from "./components/createPromotion";
import ColAtm from "../../../atomic/Col/Col";
import AddRoomButton from "../../../organisms/AddRoomButton/AddRoomButton";
import LabelAtm from "../../../atomic/Label/Label";
import Rooms from "./components/Rooms";

import {
    getPromotionRoomRateList,
    getPromotionList,
    UpdatePromotion,
    CreatePromotion,
    GetPromotionTypes,
    createOrUpdatePromotionErrorClear
} from "../../../../redux/actions/Network/Promotion/Promotion.actions";

import {NETWORK_PROMOTION_CHANNEL_LIST} from "../../../../constants/appUrls.constants";
import {isDefined} from "../../../../utils";
import { getProperty } from '../../../../utils';
import axios from 'axios';
import { USER_MENU } from '../../../../constants/api.constants';
import { getAppLang } from '../../../../utils';

let t =null;

class PromotionIndex extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true,
            open : false,
            propertyName : '',
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getChannelSetup( { channel_id: this.props.match.params.id } );

        this.props.getPromotionRoomRateList( this.props.match.params.id  );
        this.props.getPromotionList();
        this.props.GetPromotionTypes();

        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(USER_MENU, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            //propertyName = result.data.data.property_list[0].name + " - ";
            this.setState({propertyName: result.data.data.property_list[0].name + " - "});
        }).catch(function (error) {
            console.log(error);
        });
    }

    handleClose = () => {
        this.setState({open:false});
    };
    handleOpen = () => {
        this.setState({open:true});
    };

    render() {

        return (
            <>
                <PageTitle title={ t('enw-promotions-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={NETWORK_PROMOTION_CHANNEL_LIST}
                    text={this.state.propertyName + t('enw-promotions-title')}
                />

                {
                    !this.props.promotionReducers.promotionFetching && !this.props.promotionReducers.promotionListFetching ?
                    isDefined(this.props.promotionReducers.promotionList.property_promotions) &&
                    Object.keys(this.props.promotionReducers.promotionList.property_promotions).length > 0  ? (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width cancellation-policy-list">
                                        <Grid container>
                                            <Grid item xs={12} className="mb-16">
                                                <Grid item  className="photoButtonsRow">
                                                    <CreatePromotionModal
                                                        channelInformationData={this.props.channelInformationData}
                                                        promotionReducers={this.props.promotionReducers}
                                                        createPromotion={this.props.CreatePromotion}
                                                        getPromotionList={this.props.getPromotionList}
                                                        getPromotionRoomRateList={this.props.getPromotionRoomRateList}
                                                        createOrUpdatePromotionErrorClear={this.props.createOrUpdatePromotionErrorClear}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    (!this.props.promotionReducers.promotionFetching && !this.props.promotionReducers.promotionListFetching) &&
                                                    <Rooms
                                                        channelId = {this.props.match.params.id}
                                                        channelInformationData={this.props.channelInformationData}
                                                        promotionReducers={this.props.promotionReducers}
                                                        updatePromotion={this.props.UpdatePromotion}
                                                        getPromotionList={this.props.getPromotionList}
                                                        getPromotionRoomRateList={this.props.getPromotionRoomRateList}
                                                        createOrUpdatePromotionErrorClear={this.props.createOrUpdatePromotionErrorClear}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Container>
                            </Animated>
                        )
                        :
                        (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width">
                                        <ColAtm>
                                            <Container maxWidth="xl">
                                                <div className="offer-container-width">
                                                    <ColAtm>
                                                        <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                            <ColAtm>
                                                                <div className="m-auto first-create-room first-create-promotion">
                                                                    <span>
                                                                        <AddRoomButton
                                                                            roomMenuItem={
                                                                                <CreatePromotionModal
                                                                                    channelInformationData={this.props.channelInformationData}
                                                                                    promotionReducers={this.props.promotionReducers}
                                                                                    createPromotion={this.props.CreatePromotion}
                                                                                    getPromotionList={this.props.getPromotionList}
                                                                                    getPromotionRoomRateList={this.props.getPromotionRoomRateList}
                                                                                    createOrUpdatePromotionErrorClear={this.props.createOrUpdatePromotionErrorClear}
                                                                                />
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </ColAtm>
                                                            <LabelAtm labelClass="one-page-title" text={ t('enw-landing_create_promotion-subtitle') } />
                                                            <LabelAtm labelClass="one-page-subtitle" text={ t('enw-landing_create_promotion-desc') } />
                                                        </ColAtm>
                                                    </ColAtm>
                                                </div>
                                            </Container>
                                        </ColAtm>
                                    </div>
                                </Container>
                            </Animated>
                        )
                        :
                        <></>
                }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        channelCancellationListData : props.channelCancellationReducers.channelCancellationListData,
        setupFetching : props.channelSetupReducers.fetching,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        updateStatus : props.channelCancellationReducers.updateStatus,
        channelCancellationReducers : props.channelCancellationReducers,
        updateError : props.channelCancellationReducers.updateError,
        cancellationFetching : props.channelCancellationReducers.fetching,
        channelSetupReducers : props.channelSetupReducers,


        promotionReducers: props.promotionReducers
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    getPromotionRoomRateList,
    getPromotionList,
    CreatePromotion,
    UpdatePromotion,
    GetPromotionTypes,
    createOrUpdatePromotionErrorClear

};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PromotionIndex));
