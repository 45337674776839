import React, { Component } from 'react'
import 'react-circular-progressbar/dist/styles.css';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle"
import {getDashboardOffer} from "../../../../redux/actions/Offer/OfferDashboard/dasboardActions";
import {connect} from "react-redux";
import OfferDashboardData from "./components/OfferDashboardData";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {
    APP,
    BOOKING_ENGINE,
    CONTENT,
    NETWORK,
    NOT_ALLOWED_DASHBOARD_SHORT,
    PAYMENT,
    WEB
} from "../../../../constants/appUrls.constants";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {history} from "../../../../utils/history";
import {Hidden} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {getPropertyName, isDefined} from "../../../../utils";
import {propertyProducts} from "../../../../redux/actions/propertyProductsActions";

let t=null

class OfferDashboard extends Component{

    constructor(props) {
        super(props);
        t = props.t;
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getDashboardOffer();
        this.props.propertyProducts();
    }

    render(){
        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)
        return(
            <>
                <PageTitle title={ t('enw-offer-page_title') } />
                <PageHeaderAtm exitText={ t('btn-properties') } backButton={APP} text={ getPropertyName() } />
                <Grid container className="offer-main-page">
                    <OfferDashboardData
                        dashBoardOffer={this.props.dashboardOfferReducer}
                        fetching={this.props.dashboardOfferReducer.fetching}
                        propertyProductsReducer={this.props.propertyProductsReducer}
                    />
                </Grid>
                <Hidden mdUp>
                    <BottomNavigation className="m-navigation" value="offer">
                        <BottomNavigationAction value="content" icon={<i className="fas fa-hotel" />} onClick={() => { history.push(CONTENT)}}  />
                        <BottomNavigationAction value="network" icon={<i className="fas fa-chart-network" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#")}} />
                        <BottomNavigationAction value="offer" icon={<i className="fas fa-paperclip" />} />
                        <BottomNavigationAction value="web" icon={<i className="fas fa-network-wired" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? WEB :  NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#")}} />
                        <BottomNavigationAction value="booking" icon={<i className="fas fa-calendar-alt" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? BOOKING_ENGINE :  NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#")}} />
                        <BottomNavigationAction value="payment" icon={<i className="fas fa-money-bill-wave-alt" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? PAYMENT :  NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#")}} />
                    </BottomNavigation>
                </Hidden>
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        dashboardOfferReducer : props.dashboardOfferReducer,
        propertyProductsReducer : props.propertyProductsReducer
    }
};

const mapDispatchToProps ={
    getDashboardOffer,
    propertyProducts
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(OfferDashboard));
