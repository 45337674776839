import React,{Component} from "react";
import {connect} from "react-redux";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import OfferForm from "./components/OfferForm";
import {createOffer,offerStore } from "../../../../redux/actions/Offer/CreateOffer/createOfferAction";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import {OFFER_LIST} from "../../../../constants/appUrls.constants";
let t=null

class CreateOffer extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    componentDidMount() {
        this.props.createOffer();
    }

    showMessage = () => {

        if( this.props.offerStoreStatus === false &&
            this.props.createOfferReducer.error.response
        ){
            let message = this.props.createOfferReducer.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-create_offer-page_title') } />
                <PageHeaderAtm backButton={OFFER_LIST} exitText={ t('btn-offer_list')} text={ t('enw-create_offer-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width create-offer-container">
                            {
                                !this.props.createOfferReducer.fetching &&
                                    <OfferForm
                                        offerReducer = {this.props.createOfferReducer.offerData}
                                        offerStore = { this.props.offerStore }
                                        offerStoreData = {this.props.offerStoreData}
                                        offerStoreStatus = {this.props.offerStoreStatus}
                                    />
                            }
                            {

                                    this.showMessage()

                            }
                        </div>
                    </Container>
                </Animated>
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return{
        createOfferReducer : props.createOfferReducer,
        offerStoreData  : props.createOfferReducer.offerStoreData,
        offerStoreStatus  : props.createOfferReducer.offerStoreStatus,
    }
}

const mapDispatchToProps = {
    createOffer,
    offerStore
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(CreateOffer));
