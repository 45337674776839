import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import {CircularProgress, Grid} from "@material-ui/core";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import {
    createPaymentCode, createPaymentCodeClearState,
} from "../../../../../../redux/actions/Network/Reservation/ticket.actions";
import {isDefined} from "../../../../../../utils";
import {Alert} from "@material-ui/lab";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const CreatePaymentCodeModal = (props) =>  {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const createPaymentCodeData = useSelector(props => props?.createOrUpdateTicketReducer?.create_payment_code)
    const errorCreatePaymentCodeData = useSelector(props => props?.createOrUpdateTicketReducer?.create_payment_code_error)
    const fetchCreatePaymentCodeData = useSelector(props => props?.createOrUpdateTicketReducer?.create_payment_code_fetching)

    const {reservation} = props;

    const [open, setOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [price, setPrice] = useState("");

    const handleClickOpen = () => {
        setOpen(true);

        const params = {
            booking_id: reservation.id
        }

        dispatch(createPaymentCode(params))
    };
    const handleClose = () => {
        setOpen(false);
        setIsConfirm(false);
        dispatch(createPaymentCodeClearState());
    };

    const handleChangePrice = ({target}) => {
        setPrice(target.value);
    };

    useEffect( () => {

        if (createPaymentCodeData?.data?.cc) {
            setIsConfirm(true)
        }

    },[createPaymentCodeData] )

    const onSubmit = () => {

        const params = {
            booking_id: reservation.id,
            confirmCode: price,
        }

        dispatch(createPaymentCode(params))
    };

    return (
        <>
            <ButtonAtm className="green-button payment-code-modal-btn mt-0 float-right" startIcon={<i className="fas fa-credit-card-front" />} buttontext={t('enw-reservation_create_payment_code-title')} fullwidth={true} onClick={handleClickOpen} />
            <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                <DialogTitle className="red-bottom-border" id="rate-plan" onClose={handleClose}>
                    {t('enw-reservation_create_payment_code-title')}
                </DialogTitle>
                <DialogContent>
                    {isDefined(errorCreatePaymentCodeData) &&
                    isDefined(errorCreatePaymentCodeData.response) &&
                        <Alert severity="error">{errorCreatePaymentCodeData.response.data.message}</Alert>
                    }
                    <ValidatorForm onSubmit={onSubmit}>
                            {
                                fetchCreatePaymentCodeData ?
                                    <Grid item className="t-center">
                                        <CircularProgress color="secondary"/>
                                    </Grid>
                                    :
                                    <>
                                        {
                                            createPaymentCodeData?.data?.type === "redirect" ?
                                                <>
                                                    {
                                                        createPaymentCodeData?.data?.channel_manager_id === 1 &&
                                                            <Alert severity="info" className="mb-24 w-100 card-code-alert">{t('enw-reservation_create_payment_code-alert-redirect-text')}</Alert>
                                                    }
                                                    <Grid item className="redirect-url-payment">
                                                        <iframe id="previewFrame" name="previewFrame" className="site-preview-frame" height="650" src={createPaymentCodeData?.data?.redirectUrl} title="be-preview-frame" />
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    {
                                                        !isConfirm &&
                                                        <Alert severity="info" className="mb-24 w-100 card-code-alert">{t('enw-reservation_create_payment_code-alert-text')}</Alert>
                                                    }
                                                    <Grid container spacing={3}>
                                                        {
                                                            isConfirm ?

                                                                <div className="m-auto mt-14">
                                                                    <div className="card card--front">
                                                                    {  isDefined(createPaymentCodeData.data.ch) &&
                                                                    <div className="card__placeholder">{createPaymentCodeData?.data?.ch}</div>
                                                                    }
                                                                        <div className="card__number">{createPaymentCodeData?.data?.cc}</div>
                                                                        <div className="card__bottom">
                                                                            <div className="card__expiry-date">{createPaymentCodeData?.data?.cm}/{createPaymentCodeData?.data?.cy}</div>
                                                                            <div className="card__expiry-date">{createPaymentCodeData?.data?.cv}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <Grid item xs={12} md={6} className="m-auto">
                                                                        <TextValidator
                                                                            label={ t('enw-input-confirm-code') }
                                                                            name="price"
                                                                            fullWidth
                                                                            autoComplete="off"
                                                                            value={price}
                                                                            onChange={handleChangePrice}
                                                                            variant="outlined"
                                                                            validators={['required']}
                                                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                                                        />
                                                                    </Grid>
                                                                    <ColAtm className="mt-13" md={12}>
                                                                        <ButtonAtm
                                                                            className="m-auto room-rate-modal-save bg-red"
                                                                            type="submit"
                                                                            buttontext={ t('btn-send') }
                                                                            color="primary"
                                                                        />
                                                                    </ColAtm>
                                                                </>
                                                        }
                                                    </Grid>
                                                </>


                                        }
                                    </>
                            }
                        </ValidatorForm>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default CreatePaymentCodeModal;
