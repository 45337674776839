import {getPlacesCategories } from "../../../../constants/Content/places.constanst";

const initialState = {
    fetching : false,
    placesCategories:[],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getPlacesCategories.GET_PLACE_CATEGORIES_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case getPlacesCategories.GET_PLACE_CATEGORIES_FULFILLED:
            return {
                ...state,
                fetching: false,
                placesCategories: action.payload?.data
            };
        case getPlacesCategories.GET_PLACE_CATEGORIES_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        default:
            return state
    }
}
