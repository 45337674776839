import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Backdrop, CircularProgress, Grid, MenuItem} from "@material-ui/core";
import {SelectValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import InputAtm from "../../../../../atomic/Input/Input";
import ColAtm from "../../../../../atomic/Col/Col";
import ButtonAtm from "../../../../../atomic/Button/Button";
import Editor from 'react-simple-wysiwyg';
import {getProperty, isDefined} from "../../../../../../utils";
import {
    createBlog,
    createBlogClearState, getBlogList,
    uploadBlogImage,
    uploadBlogImageClearState
} from "../../../../../../redux/actions/Web/Blog/blogActions";
import {getLanguages} from "../../../../../../redux/actions/languageActions";
import Alert from "@material-ui/lab/Alert";
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../../atomic/PageHeader/PageHeader";
import {WEB_BLOG_LIST} from "../../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {history} from "../../../../../../utils/history";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#d71f26',
    },
}))

export default function CreateBlogModal() {
    const classes = useStyles();
    const {t} = useTranslation();
    const {handleSubmit} = useForm();

    const createImage = useSelector(props => props.blogReducers?.createImage);
    const blogData = useSelector(props => props.blogReducers?.createBlog);
    const createBlogError = useSelector(props => props.blogReducers?.createBlogError);
    const languageList = useSelector(props => props.languagesReducer?.languages);
    const createImageError = useSelector(props => props.blogReducers?.createImageError);
    const createImageFetching = useSelector(props => props.blogReducers?.createImageFetching);

    const dispatch = useDispatch();

    const [html, setHtml] = useState("");
    const [filePath, setFilePath] = useState("");
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");

    useEffect(() => {
        dispatch(getLanguages());
    }, [dispatch]);

    useEffect(() => {
        if (blogData.status === 200) {
            dispatch(getBlogList());
            dispatch(createBlogClearState());
            dispatch(uploadBlogImageClearState());
            history.push(WEB_BLOG_LIST)
        }
    }, [blogData]);

    useEffect(() => {
        setHtml("")
        setFilePath("")
        setFile("")
        setTitle("")
        setLanguage("")
        dispatch(createBlogClearState());
        dispatch(uploadBlogImageClearState());
    }, [dispatch]);

    useEffect(() => {
        if (createImageError) {
            setFilePath("")
            setFile("")
        }
    }, [createImageError]);

    const handleChangeTitle = ({target}) => {
        setTitle(target.value);
    };

    const selectLang = ({target}) => {
        setLanguage(target.value)
    };

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            title: title,
            language_code: language,
            content: html,
            image: createImage?.image ? createImage.image : null,
        }

        dispatch(createBlog(params));
    };

    function onChange(e) {
        setHtml(e.target.value);
    }

    const onFileChange = event => {
        event.preventDefault();
        const file = event.target.files[0];
        if (isDefined(file)) {
            setFilePath(URL.createObjectURL(file))
            setFile(event.target.files[0])
        }
        const params = {
            property_id: getProperty(),
            file: file,
        }
        dispatch(uploadBlogImage(params));
    };


    return (
        <div className="mb-64">
            <PageTitle title={t('btn-create-blog')}/>
            <PageHeaderAtm backButton={WEB_BLOG_LIST} exitText={t('enw-blog-title')} text={t('btn-create-blog')}/>
            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                <Container maxWidth="xl">
                    <div className="container-width">
                        <Fragment>
                            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                <Grid container className="blog-edit-and-create" spacing={3} md={8}>
                                    {blogData?.status === 200 &&
                                        <Alert className="w-100" severity="success">{t('enw-redirect_to_save-message')}</Alert>}
                                    {createBlogError &&
                                        <Alert className="w-100" severity="error">{createBlogError?.response?.data?.message}</Alert>}
                                    {createImageError &&
                                        <Alert className="w-100" severity="error">{createImageError?.response?.data?.message}</Alert>}
                                    {
                                        createImageFetching &&
                                        <Backdrop className={classes.backdrop} open>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                    <Grid item xs={12} md={8}>
                                        <InputAtm
                                            label={t('enw-web_blog-title')}
                                            placeholder={t('enw-web_blog-title')}
                                            variant="outlined"
                                            autoComplete="off"
                                            onChange={handleChangeTitle}
                                            validators={['required']}
                                            errorMessages={[t("enw-notification_this_is_required")]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} className="mb-24">
                                        <SelectValidator
                                            name="category"
                                            onChange={selectLang}
                                            value={language}
                                            className="form-control"
                                            variant="outlined"
                                            fullWidth
                                            label={t('enw-input-language')}
                                            validators={['required']}
                                            errorMessages={[t("enw-notification_this_is_required")]}
                                        >
                                            {
                                                languageList?.map((lang) => (
                                                    <MenuItem value={lang.code}>
                                                        <img src={`/assets/img/language/${lang.code}.svg`} alt="en"
                                                             style={{width: 20, marginRight: 8}}/> {t(lang.language_key)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </SelectValidator>
                                    </Grid>
                                    <Grid item xs={12} className="mb-24">
                                        <Editor value={html} onChange={onChange}/>
                                    </Grid>
                                    <Grid item xs={12} className="mb-24 d-flex">
                                        <Grid item xs={12} md={4} className="blog-img-area">
                                            <input
                                                className="d-none"
                                                accept="image/png, image/jpeg, .pdf"
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={onFileChange}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button
                                                    className="room-rate-add soft-blue-button m-auto radius-8"
                                                    component="span"
                                                    startIcon={<i className="fas fa-cloud-upload-alt"/>}>
                                                    {t('btn-upload_image')}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <div>
                                            {
                                                !isDefined(file) ?
                                                    <label htmlFor="contained-button-file"
                                                           className="cursor-pointer"></label>
                                                    :
                                                    <div className="blog-preview-img">
                                                        <img src={filePath}/>
                                                    </div>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                                <ColAtm className="mt-32" md={12}>
                                    <ButtonAtm
                                        className="m-auto room-rate-modal-save bg-red"
                                        buttontext={t('btn-save')}
                                        color="primary"
                                        type="submit"
                                        disabled={!isDefined(html)}
                                    />
                                </ColAtm>
                            </ValidatorForm>
                        </Fragment>
                    </div>
                </Container>
            </Animated>
        </div>
    );
}
