export const paymentMappingListConstants = {
    PAYMENT_MAPPING_LIST_PENDING  : 'PAYMENT_MAPPING_LIST_PENDING',
    PAYMENT_MAPPING_LIST_FULFILLED: 'PAYMENT_MAPPING_LIST_FULFILLED',
    PAYMENT_MAPPING_LIST_REJECTED : 'PAYMENT_MAPPING_LIST_REJECTED',
};

export const getPaymentTypeListConstants = {
    PAYMENT_TYPE_LIST_PENDING  : 'PAYMENT_TYPE_LIST_PENDING',
    PAYMENT_TYPE_LIST_FULFILLED: 'PAYMENT_TYPE_LIST_FULFILLED',
    PAYMENT_TYPE_LIST_REJECTED : 'PAYMENT_TYPE_LIST_REJECTED',
};

export const getPaymentTypeConstants = {
    PAYMENT_TYPE_GET_PENDING  : 'PAYMENT_TYPE_GET_PENDING',
    PAYMENT_TYPE_GET_FULFILLED: 'PAYMENT_TYPE_GET_FULFILLED',
    PAYMENT_TYPE_GET_REJECTED : 'PAYMENT_TYPE_GET_REJECTED',
};

export const createPaymentMappingConstants = {
    PAYMENT_CREATE_MAPPING_PENDING  : 'PAYMENT_CREATE_MAPPING_PENDING',
    PAYMENT_CREATE_MAPPING_FULFILLED: 'PAYMENT_CREATE_MAPPING_FULFILLED',
    PAYMENT_CREATE_MAPPING_REJECTED : 'PAYMENT_CREATE_MAPPING_REJECTED',
    PAYMENT_CREATE_MAPPING_CLEAR_ERROR_STATE  : 'PAYMENT_CREATE_MAPPING_CLEAR_ERROR_STATE',
    PAYMENT_CREATE_MAPPING_CLEAR_SUCCESS_STATE  : 'PAYMENT_CREATE_MAPPING_CLEAR_SUCCESS_STATE',
};

export const updatePaymentMappingConstants = {
    PAYMENT_MAPPING_UPDATE_PENDING  : 'PAYMENT_MAPPING_UPDATE_PENDING',
    PAYMENT_MAPPING_UPDATE_FULFILLED: 'PAYMENT_MAPPING_UPDATE_FULFILLED',
    PAYMENT_MAPPING_UPDATE_REJECTED : 'PAYMENT_MAPPING_UPDATE_REJECTED',
    PAYMENT_MAPPING_UPDATE_CLEAR_ERROR_STATE  : 'PAYMENT_MAPPING_UPDATE_CLEAR_ERROR_STATE',
};

export const updatePaymentSetDefaultConstants = {
    PAYMENT_SET_DEFAULT_PENDING  : 'PAYMENT_SET_DEFAULT_PENDING',
    PAYMENT_SET_DEFAULT_FULFILLED: 'PAYMENT_SET_DEFAULT_FULFILLED',
    PAYMENT_SET_DEFAULT_REJECTED : 'PAYMENT_SET_DEFAULT_REJECTED',
};

export const updatePaymentMappingBoxStatusConstants = {
    PAYMENT_MAPPING_UPDATE_STATUS_PENDING  : 'PAYMENT_MAPPING_UPDATE_STATUS_PENDING',
    PAYMENT_MAPPING_UPDATE_STATUS_FULFILLED: 'PAYMENT_MAPPING_UPDATE_STATUS_FULFILLED',
    PAYMENT_MAPPING_UPDATE_STATUS_REJECTED : 'PAYMENT_MAPPING_UPDATE_STATUS_REJECTED',
};

export const paymentInstallmentUpdateConstants = {
    PAYMENT_INSTALLMENTS_UPDATE_PENDING  : 'PAYMENT_INSTALLMENTS_UPDATE_PENDING',
    PAYMENT_INSTALLMENTS_UPDATE_FULFILLED: 'PAYMENT_INSTALLMENTS_UPDATE_FULFILLED',
    PAYMENT_INSTALLMENTS_UPDATE_REJECTED : 'PAYMENT_INSTALLMENTS_UPDATE_REJECTED',
};

export const paymentInstallmentUpdateStatusConstants = {
    PAYMENT_INSTALLMENTS_UPDATE_STATUS_PENDING  : 'PAYMENT_INSTALLMENTS_UPDATE_STATUS_PENDING',
    PAYMENT_INSTALLMENTS_UPDATE_STATUS_FULFILLED: 'PAYMENT_INSTALLMENTS_UPDATE_STATUS_FULFILLED',
    PAYMENT_INSTALLMENTS_UPDATE_STATUS_REJECTED : 'PAYMENT_INSTALLMENTS_UPDATE_STATUS_REJECTED',
};

