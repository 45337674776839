import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createTicket,
    getTicket
} from "../../../../../../redux/actions/Network/Reservation/ticket.actions";
import jwt_decode from "jwt-decode";
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import {useTranslation} from "react-i18next";
import {Button, Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Checkbox from '@material-ui/core/Checkbox';
import {isDefined} from "../../../../../../utils";
import ColAtm from "../../../../../atomic/Col/Col";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useForm} from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Skeleton} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import createOrUpdateTicketReducer
    from "../../../../../../redux/reducers/Network/Reservation/ReservationDetail/createOrUpdateTicketReducer";

const ReservationTicket = (props) => {

    const useStyles = makeStyles({
        root: {
            maxWidth: 345,
            margin: "auto"
        },
    });

    const { t } = useTranslation();
    const classes = useStyles();

    const token = localStorage.getItem('auth');
    const decoded = jwt_decode(token);

    const {bookingCode} = props;

    const ticketFullData = useSelector(props => props.ticketReducer)
    const ticketCreateFullData = useSelector(props => props.createOrUpdateTicketReducer)
    const ticketData = useSelector(props => props.ticketReducer.get_ticket)
    const createTicketData = useSelector(props => props.createOrUpdateTicketReducer.create_ticket)

    const [message, setMessage] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [isNote, setIsNote] = React.useState(false);

    const params = {
        user_id : decoded.user_id,
        booking_code : bookingCode
    }

    const { handleSubmit } = useForm();
    const dispatch = useDispatch();

    const changeMessage = ({target}) => {
        setMessage(target.value)
    };

    const handleChangeNote = (event) => {
        setIsNote(event.target.checked);
    };

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            user_id: decoded.user_id,
            booking_code: bookingCode,
            message: message,
            is_note: isNote,
            ip_address: ipAddress
        }

        dispatch(createTicket(params));
    };

    useEffect( () => {
        fetch(`https://jsonip.com/`)
            .then(res => res.json())
            .then(res => setIpAddress(res.ip));
    },[])

    useEffect( () => {

        dispatch(getTicket(params))

    },[bookingCode, dispatch, decoded.user_id])

    useEffect( () => {

        if (isDefined(createTicketData) && createTicketData.status === 200) {
            dispatch(getTicket(params))
            setMessage("")
            setIsNote(false)
        }
    },[createTicketData, dispatch])

    const showMessage = () => {

        if(ticketFullData.get_ticket_error?.response?.status === 400 || ticketFullData.get_ticket_error?.response?.status === 500) {
            const message = ticketFullData.get_ticket_error?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

        if(ticketCreateFullData.create_ticket_error?.response?.status === 400 || ticketCreateFullData.create_ticket_error?.response?.status === 500) {
            let message = ticketCreateFullData.create_ticket_error?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    const updateTicket = () => {
        dispatch(getTicket(params))
    }

    return(
        <Grid container className="offer-info-block pb-24">
            <h2>{t('enw-reservation_ticket-title')}</h2>
            <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                <Divider />
            </Grid>
            {
                !ticketFullData.get_ticket_fetching ?
                    <Grid item xs={12} md={8} className="support-message m-order-1">
                        {
                            isDefined(ticketData) && ticketData.map( (tickets) => (
                                <p className={tickets.user_id === null ? "from-them" : tickets.is_note === 1 ? "from-me is-note-ticket" : "from-me"}>
                                    {tickets.message}
                                    <span className={tickets.user_id !== null ? "from-me-time" : "from-them-time"}>
                                        {tickets.createdTime}
                                    </span>
                                </p>
                            ))
                        }
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)} onError={errors => console.log(errors)} className="w-100">
                            <TextValidator
                                label={t('enw-reservation_ticket-answer')}
                                name="description"
                                fullWidth
                                autoComplete="off"
                                multiline={true}
                                value={message}
                                onChange={changeMessage}
                                rows={3}
                                variant="outlined"
                                validators={['required']}
                                errorMessages={ [ t("enw-notification_this_is_required") ] }
                                inputProps={{
                                    maxLength: 2000,
                                }}
                            />
                            <Grid container>
                                <Grid item xs={6}>
                                    <p>
                                        {message.length} / 2000
                                    </p>
                                </Grid>
                                <Grid item xs={6} className="t-right d-grid">
                                    <FormControlLabel
                                        className="float-right d-block"
                                        control={<Checkbox color="primary" checked={isNote} onClick={handleChangeNote} />}
                                        label={ t('be-input-special_note') }
                                        labelPlacement="left"
                                    />
                                    <span className="rate-setup">{t('enw-reservation_ticket-special-note-desc')}</span>
                                </Grid>
                            </Grid>
                            <ColAtm className="mt-22" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-send') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Grid>
                    :
                    <Grid item xs={12} md={8} className="m-auto">
                        <Skeleton height={250} className="m-0-40" />
                        <Skeleton height={40} width={140} className="m-auto" />
                    </Grid>

            }
            <Grid item xs={12} md={4} className="support-message support-message-info m-order-0">
                <Card className={classes.root}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            <i className="fas fa-square gray mr-9" />
                            {t('enw-reservation_ticket-from-customer')}
                            <span className="d-block">{t('enw-reservation_ticket-from-customer-desc')}</span>
                        </Typography>
                        <Typography gutterBottom variant="h5" component="h2">
                            <i className="fas fa-square red mr-9" />
                            {t('enw-reservation_ticket-from-property')}
                            <span className="d-block">{t('enw-reservation_ticket-from-property-desc')}</span>
                        </Typography>
                        <Typography gutterBottom variant="h5" component="h2">
                            <i className="fas fa-square orange mr-9" />
                            { t('be-input-special_note') }
                            <span className="d-block">{t('enw-reservation_ticket-special-note-desc')}</span>
                        </Typography>
                    </CardContent>
                    <Grid item xs={12} className="t-center">
                        <Button className="soft-blue-button new-executive-btn" onClick={updateTicket}>
                            {t('btn-update-conversation')}
                        </Button>
                    </Grid>
                </Card>
            </Grid>
            {showMessage()}
        </Grid>
    )
}
export default ReservationTicket;
