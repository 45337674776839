import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export function TotalForecast({forecastTotal}) {

    const { t } = useTranslation();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: t("enw-chart-forecast_title"),
            },
        },
    };
    const labels = forecastTotal && Object.keys(forecastTotal);

    const data = {
        labels,
        datasets: [
            {
                label: t("enw-chart-forecast_label"),
                data: forecastTotal && Object.values(forecastTotal),
                borderColor: 'rgb(215, 35, 31)',
                backgroundColor: 'rgb(215, 35, 31)',
            }
        ],
    };
    return <Line options={options} data={data} type={"line"} />;
}
