import {getAppLang, getProperty, getUserToken, isDefined} from "../../../../utils";
import axios from "axios";
import {BLOG_LIST, CREATE_BLOG, CREATE_IMAGE, UPDATE_BLOG} from "../../../../constants/api.constants";

export function getBlogList (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
            }
        }
    };

    if (params && (isDefined(params.title) || isDefined(params.language_code))) {
        requestOptions.body.params = {
            property_id : getProperty(),
            filter : {
                title : params.title,
                content_category_id : "1",
                language_code : params.language_code,
                status : "1"
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'BLOG_LIST',
            payload: axios.post(BLOG_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function uploadBlogImage(params) {
    const formData = new FormData();

    formData.append( 'property_id', getProperty());
    if (isDefined(params.file)) {
        formData.append('image', params.file, params.file.name);
    }

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data',  'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_IMAGE',
            payload: axios.post(CREATE_IMAGE, requestOptions.data, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createBlog(params) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                content_category_id: 1,
                title: params.title,
                language_code : params.language_code,
                image : params.image,
                content : params.content
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_BLOG',
            payload: axios.post(CREATE_BLOG, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateBlog(params) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                content_category_id: 1,
                title: params.title,
                language_code : params.language_code,
                image : params.image,
                content : params.content
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_BLOG',
            payload: axios.post(UPDATE_BLOG + '/' + params.id, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createBlogClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_BLOG_CLEAR',
        })
    }
}

export function updateBlogClearState() {
    return dispatch => {
        dispatch({
            type: 'UPDATE_BLOG_CLEAR',
        })
    }
}

export function uploadBlogImageClearState() {
    return dispatch => {
        dispatch({
            type: 'CREATE_IMAGE_CLEAR',
        })
    }
}