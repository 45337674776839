import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ColAtm from "../../../atomic/Col/Col";
import LabelAtm from "../../../atomic/Label/Label";
import ButtonAtm from "../../../atomic/Button/Button";
import { history }    from "../../../../utils/history";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class SuccessModal extends React.Component {

    constructor(props) {
        super(props);
        t = props.t;
    }
    state = {
        open : this.props.status,
        message: this.props.message,
        redirectUrl: this.props.redirectUrl
    };

    handleClose = () => {
        this.setState({
            open:false
        })
        if( this.state.redirectUrl !== null && this.state.redirectUrl !== undefined ){
            history.push(this.state.redirectUrl);
        }else if(this.props.handleFonction !== undefined){
            this.props.toggle()
            this.props.handleFonction(1);
        }
    };

    render() {
        return (
            <Dialog onClose={this.handleClose} className="status-modal" aria-labelledby="status" open={this.state.open}>
                <DialogTitle className="red-bottom-border"  id="status" onClose={this.handleClose}>
                    { t('modal-success-title') }
                </DialogTitle>
                <DialogContent>
                    <ColAtm>
                        <ColAtm xs={12} md={4} className="photoButtonsRow status-modal-content">
                            <ColAtm>
                                <div className="m-auto">
                                    <span>
                                        <i
                                            className="fas fa-check"
                                            style={{margin: 'auto', fontSize: '70px', color: '#38c537', backgroundColor: '#def6de', borderRadius: '135px', padding: '49px'}}
                                        />
                                    </span>
                                </div>
                            </ColAtm>
                            <LabelAtm labelClass="status-modal-subtitle" text={this.state.message} />
                            <ButtonAtm
                                className="soft-green-button"
                                outline
                                fullwidth={true}
                                buttontext={ t('btn-ok') }
                                onClick={this.handleClose}
                            />
                        </ColAtm>
                    </ColAtm>
                </DialogContent>
            </Dialog>
        )
    }

}

export default compose(withTranslation('common')) (SuccessModal);
