import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../atomic/Label/Label";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {PropertyRoomAndRateList, PropertyRoomTypes, PropertyRoomRates} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {useDispatch} from "react-redux";
import UpdateRatePlanModal from "../UpdateRatePlan/UpdateRatePlan";

const RateList = props => {
    const {rates} = props;
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [editRate, setEditRate] = React.useState([]);
    const dispatch = useDispatch();

    const closeEditRoom = () => {
        setEditModalStatus(false);
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect( () => {
        if(props.ratePlanUpdateStatus === 200)
        {
            if(editModalStatus){
                closeEditRoom();
                dispatch(PropertyRoomAndRateList());
                dispatch(PropertyRoomTypes());
                dispatch(PropertyRoomRates());
            }

        }
    },[editModalStatus,props.ratePlanUpdateStatus] )


    if(rates && rates.length === 0 )
    {
        return (
            <h2>{ t('enw-notification-no_results') }</h2>
        )
    }else{
        return (

            <>
                {
                    props.rates.map( (rate, key) => (

                        <ExpansionPanel className="accordion-room-table rate-list-accordion" key={key} expanded={expanded === key} onChange={handleChange(key)}>
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-caret-down" />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header">
                                <Grid container
                                      justify="space-between"
                                      alignItems="center"
                                >
                                    <Grid item xs={12} className="d-inherit">
                                        <i className="fas fa-tags room-rate-bed" />
                                        <LabelAtm labelClass="room-rate-list-title" text={rate.name} />
                                    </Grid>
                                    {/*<Grid xs={4} md={2} item onClick={(event) => event.stopPropagation()}
                                          onFocus={(event) => event.stopPropagation()}>
                                        <Hidden xsDown>
                                            <ButtonAtm className="edit-room-btn"
                                                       onClick={ ()=> openEditRoom(rate) }
                                                       buttontext={ t('btn-edit_rate') }/>
                                        </Hidden>
                                        <Hidden smUp>
                                            <ButtonAtm className="edit-room-btn"
                                                       onClick={ ()=> openEditRoom(rate) }
                                                       buttontext={<i className="fas fa-pen" /> }/>
                                        </Hidden>
                                    </Grid>*/}
                                </Grid>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    ))
                }
                {
                    editModalStatus &&
                    <UpdateRatePlanModal
                        editRateData={editRate}
                        open={editModalStatus}
                        closeEditRoom={closeEditRoom}
                        allRoomTypes={props.allRoomTypes}
                        allBedType={props.allBedType}
                        roomViewTypes={props.roomViewTypes}
                        roomSizeTypes={props.roomSizeTypes}
                    />
                }
            </>
        );
    }


};

RateList.propTypes = {
    rooms: PropTypes.array.isRequired
};
RateList.defaultProps = {};

export default RateList;
