export const executiveConstants = {
    EXECUTIVE_CONTACT_PENDING  : 'EXECUTIVE_CONTACT_PENDING',
    EXECUTIVE_CONTACT_FULFILLED: 'EXECUTIVE_CONTACT_FULFILLED',
    EXECUTIVE_CONTACT_REJECTED : 'EXECUTIVE_CONTACT_REJECTED',

    GET_EXECUTIVE_CONTACT_PENDING  : 'GET_EXECUTIVE_CONTACT_PENDING',
    GET_EXECUTIVE_CONTACT_FULFILLED: 'GET_EXECUTIVE_CONTACT_FULFILLED',
    GET_EXECUTIVE_CONTACT_REJECTED : 'GET_EXECUTIVE_CONTACT_REJECTED',

    UPDATE_EXECUTIVE_CONTACT_PENDING  : 'UPDATE_EXECUTIVE_CONTACT_PENDING',
    UPDATE_EXECUTIVE_CONTACT_FULFILLED: 'UPDATE_EXECUTIVE_CONTACT_FULFILLED',
    UPDATE_EXECUTIVE_CONTACT_REJECTED : 'UPDATE_EXECUTIVE_CONTACT_REJECTED',

    ADD_EXECUTIVE_CONTACT_PENDING  : 'ADD_EXECUTIVE_CONTACT_PENDING',
    ADD_EXECUTIVE_CONTACT_FULFILLED: 'ADD_EXECUTIVE_CONTACT_FULFILLED',
    ADD_EXECUTIVE_CONTACT_REJECTED : 'ADD_EXECUTIVE_CONTACT_REJECTED',

    DELETE_EXECUTIVE_CONTACT_PENDING  : 'DELETE_EXECUTIVE_CONTACT_PENDING',
    DELETE_EXECUTIVE_CONTACT_FULFILLED: 'DELETE_EXECUTIVE_CONTACT_FULFILLED',
    DELETE_EXECUTIVE_CONTACT_REJECTED : 'DELETE_EXECUTIVE_CONTACT_REJECTED',

    CLEAR_EXECUTIVE_CONTACT: 'CLEAR_EXECUTIVE_CONTACT'
};
