export const getChannelPaxPricingListConstants = {
    NETWORK_CHANNEL_PRICING_GET_PENDING  : 'NETWORK_CHANNEL_PRICING_GET_PENDING',
    NETWORK_CHANNEL_PRICING_GET_FULFILLED: 'NETWORK_CHANNEL_PRICING_GET_FULFILLED',
    NETWORK_CHANNEL_PRICING_GET_REJECTED : 'NETWORK_CHANNEL_PRICING_GET_REJECTED',
};
export const addChannelAdultPaxPricingListConstants = {
   NETWORK_CHANNEL_PRICING_ADULT_CREATE_PENDING  : 'NETWORK_CHANNEL_PRICING_ADULT_CREATE_PENDING',
   NETWORK_CHANNEL_PRICING_ADULT_CREATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_ADULT_CREATE_FULFILLED',
   NETWORK_CHANNEL_PRICING_ADULT_CREATE_REJECTED : 'NETWORK_CHANNEL_PRICING_ADULT_CREATE_REJECTED',
};
export const addChannelChildPaxPricingListConstants = {
    NETWORK_CHANNEL_PRICING_CHILD_CREATE_PENDING  : 'NETWORK_CHANNEL_PRICING_CHILD_CREATE_PENDING',
    NETWORK_CHANNEL_PRICING_CHILD_CREATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_CHILD_CREATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_CHILD_CREATE_REJECTED : 'NETWORK_CHANNEL_PRICING_CHILD_CREATE_REJECTED',
};

export const updateChannelAdultPaxPricingListConstants = {
   NETWORK_CHANNEL_PRICING_ADULT_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_ADULT_UPDATE_PENDING',
   NETWORK_CHANNEL_PRICING_ADULT_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_ADULT_UPDATE_FULFILLED',
   NETWORK_CHANNEL_PRICING_ADULT_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_ADULT_UPDATE_REJECTED',
};
export const updateChannelChildPaxPricingListConstants = {
    NETWORK_CHANNEL_PRICING_CHILD_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_CHILD_UPDATE_PENDING',
    NETWORK_CHANNEL_PRICING_CHILD_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_CHILD_UPDATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_CHILD_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_CHILD_UPDATE_REJECTED',
};

export const updateChannelAdultPaxPricingChildConstants = {
    NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_PENDING',
    NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE_REJECTED',
};


export const updateChannelChildPaxPricingChildConstants = {
    NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_PENDING',
    NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_CHILD_MAPPING_UPDATE_REJECTED',
    NETWORK_CHANNEL_PRICING_CLEAR_ERROR_STATE : 'NETWORK_CHANNEL_PRICING_CLEAR_ERROR_STATE',
};

export const networkChannelPriceingChildPolicyUpdate = {
    NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_PENDING',
    NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_REJECTED',
};

export const networkChannelPriceingAdultPolicyUpdate = {
    NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_PENDING  : 'NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_PENDING',
    NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_FULFILLED: 'NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_FULFILLED',
    NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_REJECTED : 'NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_REJECTED',
    NETWORK_CHANNEL_PRICING_POLICY_CLEAR_STATE_UPDATE : 'NETWORK_CHANNEL_PRICING_POLICY_CLEAR_STATE_UPDATE',
};