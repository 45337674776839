import React, {useEffect, useMemo, useState} from "react";
import InputAtm from "../../../../atomic/Input/Input";
import {Grid} from "@material-ui/core";
import moment from "moment";

function Child(props) {

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if(value != props.value ){
            (props.value  === undefined || props.value  === null)
                ? setValue('') : setValue(props.value)
        }
        if ( (value == null || value == '') && props.value  === null  ){
            setValue('')
        }
    },[props.value]);



    const onChange = event => {
        let newValue = event.target.value;
        let dateValue = event.target.name;

        props.onChange(newValue,dateValue);
        setValue( newValue );
    };


    return (
        <Grid className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) ? 'weekend-inputs' : ""} offer-input`}>
            <InputAtm
                name={props.date}
                placeholder="-"
                value={value}
                onChange={onChange}
                className={"type-number-hidden"}
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                min={1}
            />
            <p className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && 'weekend-radius'} null-inventory`}> &nbsp;</p>
        </Grid>
    );
}

export default function Price(props) {

    const [prices, setPrices] =  useState( useMemo(() => props.prices , [props.prices,props.allDays] ) );

    useEffect(() => {
        setPrices(props.prices)

    },[props.prices]);


    const { accommodationIndex } = props;

    const onChangeRate = (value, date) => {

        let priceValue = value;
        let priceDate = date;
        let newPrice = {};
        setPrices( prevState => {

            newPrice = { ...prevState, [priceDate]:priceValue};
            props.onChangeRate(newPrice,accommodationIndex);
            return newPrice;
        })
    }

    return (
        <>
            {
                 Object.entries(props.allDays).map( ( [index, date] ) => (

                     <Child date={date}
                            value={ prices[date]  }
                            accommodationIndex={accommodationIndex}
                            onChange={onChangeRate}
                     />

                ))
            }
        </>
    )
}
