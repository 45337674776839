import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const PlacesTypeFilter = (props) => {

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedRoomType, setSelectedRoomType] = React.useState(t('enw-content-place_categories'));

    const placesCategories  = useSelector(props => props.placesGetCategoriesReducer.placesCategories?.place_categories);

    const handleMenuItemClick = (event, index) => {
        //debugger
        setSelectedIndex(index);

        if(index !== 0){
            placesCategories && placesCategories.length > 0 && placesCategories.filter( (placesCategories) => {
                //debugger
                return placesCategories.parent_id == null
            }).map((category) => {
                //debugger
                if( category.id === index ) {
                    props.onChangePlaceFilter(category.id);
                    setSelectedRoomType(t(category.language_key));
                }
            })
        }else{
            props.onChangePlaceFilter(index);
            setSelectedRoomType(t('enw-content-place_categories'));
        }

        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    useEffect(() => {
        setSelectedRoomType(t('enw-content-place_categories'));
    });

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <ButtonGroup ref={anchorRef} aria-label="split button">
                    <Button
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        className="type-plan-filter" buttontext={ "Places Types" } fullWidth={true} startIcon={<i className="far fa-home-alt" />}
                    >
                        {selectedRoomType}
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        <MenuItem
                                            selected={ selectedIndex === 0 }
                                            onClick={event => handleMenuItemClick(event, 0)}>
                                            { t('enw-content-place_categories') }
                                        </MenuItem>
                                        {
                                            placesCategories.length > 0 && placesCategories.filter( (placesCategories) => {
                                                return placesCategories.parent_id === null
                                            }).map((category,key) => (
                                                <MenuItem
                                                    key={key}
                                                    selected={category.id === selectedIndex}
                                                    onClick={event => handleMenuItemClick(event, category.id)}
                                                >
                                                    { t(category.language_key) }
                                                </MenuItem>
                                            ))
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default PlacesTypeFilter;
