import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Button, InputAdornment, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import BlockAtm from "../../../../atomic/Block/Block";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import InputAtm from "../../../../atomic/Input/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ButtonAtm from "../../../../atomic/Button/Button";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactHtmlParser from 'react-html-parser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {convertAccentedCharacters, getRoomChildAges, isDefined, viewTypeReturn} from "../../../../../utils";
import {bedGroupCoundCreateData} from "../../../../../utils";
import {Alert, createFilterOptions} from "@material-ui/lab";
import {roomOccupancyCodeCreate} from "../../../../../utils";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";

let t=null

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}
function a12yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const ages = getRoomChildAges();


class EditRoomForm extends Component {

    constructor(props){
        super(props);
        this.handleAddField = this.handleAddField.bind(this);
        this.roomOccupancyAdult = this.roomOccupancyAdult.bind(this);
        this.handleDeleteFields = this.handleDeleteFields.bind(this);
        t = props.t;
        if(this.props.roomInfo){

            this.state = {
                value : 0,
                roomId : this.props.roomInfo.id,
                roomTypeValue: this.props.roomInfo.room_type_id,
                roomName: this.props.roomInfo.name,
                maxRoomOccupancy : this.props.roomInfo.max_occupancy,
                maxAdults : this.props.roomInfo.max_adult,
                maxChildren : this.props.roomInfo.max_child,
                room_size : this.props.roomInfo.room_size,
                room_size_type : this.props.roomInfo.room_size_type,
                room_count : this.props.roomInfo?.room_count,
                room_type_count : this.props.roomInfo?.room_type_count,
                bathroom_count : this.props.roomInfo?.bathroom_count,
                toilet_count : this.props.roomInfo?.toilet_count,
                lounge_count : this.props.roomInfo?.lounge_count,
                max_child_number : String(this.props.roomInfo?.max_child_number),
                roomViewType: this.props.roomInfo.property_room_view_mapping.length >0 ? [ ...this.props.roomInfo.property_room_view_mapping.map( roomView => roomView.id) ] : [],
                options : bedGroupCoundCreateData(this.props.roomInfo.property_room_bed_group),
                exclude_occupancy : this.props.roomInfo.exclude_occupancy !== null ? this.props.roomInfo.exclude_occupancy : [] ,
                occupancyCode : [],
                description : this.props.roomInfo.description ? this.props.roomInfo.description : {},
                descriptionCharCount : Object.keys(this.props.roomInfo.description).length > 0  ? this.props.roomInfo.description.map(value =>{
                    return value.description === null ? 0 : value.description.length
                }) : [],
                descriptionMaxCharCount : 200,
                tabValue : 0,
            }
        }
    }

    handleTabChange = ( event, newValue ) => {
        this.setState( {tabValue : newValue});
    };

    handleRoomAdd = (event) => {
        const param = this.state;
        this.props.updateRoom(param);
    };

    handleChange = ( event, newValue ) => {
        this.setState( {value : newValue});
    };

    handleChangeFields = (e) => {
        this.setState({
            [e.target.name]: isDefined(e.target.value) ? e.target.value : null
        });
    };

    handleChangeRoomType = (e) => {
        this.setState({roomTypeValue:e.target.value});
    };

    handleChangeBedType = (id,subId) =>(e) => {
        let tempOptions = this.state.options;
        tempOptions[id][subId].bed_type_id = e.target.value;
        tempOptions[id][subId].bed_type_count = 1;
        this.setState({
            options: tempOptions,
        });
    };

    handleAddOptions = () => {
        this.setState({
            options: this.state.options.concat(
                [
                    [
                        {
                            bed_type_count:"",
                            bed_type_id: "",
                        }
                    ]
                ]
            )
        });
    };

    handleAddField = id => evt => {

        let tempOptions = this.state.options;
        tempOptions[id] = this.state.options[id].concat(
            {
                bed_type_count:"",
                bed_type_id: "",
            }
        );

        this.setState({
            options: tempOptions,
        });

    };

    handleDeleteOptionField = id => (e) => {
        let tempOptions = this.state.options;
        if(id > 0){
            tempOptions.splice(id,1);
            this.setState({
                options: tempOptions,
            });
        }
    }

    componentDidMount() {
        if(this.state.occupancyCode.length === 0 )
        {
            let occupancyCode = roomOccupancyCodeCreate(this.state.maxAdults,this.state.maxChildren,this.state.maxRoomOccupancy )
            this.setState({
                occupancyCode
            })
        }
    }

    roomOccupancyAdult() {

        /**
         *  A = adult
         *  C = child
         */
        let adultCode = 'A';
        let childCode = 'C';

        let adultIcons = '<i class="room-type-icon fas fa-male" />';
        let childIcons = '<i class="room-type-icon fas fa-child" />';


        let allCode = [];
        for (let i = 1; i <= this.state.maxAdults; i++) {

            for (let j = 0; j <= this.state.maxChildren; j++) {
                let code = '';
                let appendIcon = '';
                if ((i + j) > this.state.maxRoomOccupancy) continue;

                for (let k = 0; k < i; k++) code +=  adultCode;
                for (let l = 0; l < j; l++) code +=  childCode;

                for (let k = 0; k < i; k++) appendIcon += '<div>'+ adultIcons+ '</div>';
                for (let l = 0; l < j; l++) appendIcon += '<div>'+ childIcons +'</div>';

                allCode.push({code:code,icon:appendIcon})
            }
        }

        this.setState({
            occupancyCode : allCode
        })
    }

    handleChangeOccupancy = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        }, () => this.roomOccupancyAdult() )
    }

    handleChangeExcludeOccupancy = (selectedOccupancy) => {

        if ( selectedOccupancy !== undefined && selectedOccupancy !== null ) {

            let list = [...this.state.exclude_occupancy];
            let index = list.findIndex(value => value === selectedOccupancy );

            if(index !== -1 )
            {
                list.splice(index,1);
                this.setState({
                    exclude_occupancy : list
                } )
            }else{
                list.push(selectedOccupancy);
                this.setState({
                    exclude_occupancy : list
                })
            }
        }
    }

    handleDeleteFields (subId, id) {

        let tempOptions = this.state.options;

        let index =  subId
        if (index !== -1) {
            tempOptions[id].splice(subId,1);
            this.setState({
                options: tempOptions,
            });
        }
    };

    handleChangeRoomViewType=(e,value) => {
        let selectedValues =  value.map((item) => {
            return item.id
        })
        this.setState({
            roomViewType: selectedValues
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState( {value : 1});
    };

    handleChangeCharCount = index => (e) => {

        let tempCount = this.state.descriptionCharCount;
        tempCount[index] = e.target.value.length;

        this.setState({
            descriptionCharCount:tempCount
        });

        let values = this.state.description;
        values[index]["description"] = e.target.value;

    };

    handleChangeMaxChildFields = (e, value) => {
        this.setState({max_child_number: value})
    };

    render() {
        const {value,roomTypeValue, occupancyCode} = this.state;
        const {allRoomTypes} = this.props;
        const HtmlTooltip = (Tooltip);

        const descriptionList = this.state.description.map((title, index)=>{

            return (
                <TabPanel value={this.state.tabValue} index={index} className="w-100">
                    <TextField name={title.language_code}
                               type="text"
                               variant="outlined"
                               className="text-capitalize"
                               value={title.description}
                               /*onChange={ (e) => {
                                   let values = this.state.description;
                                   values[index]["description"] = e.target.value;
                                   this.setState({ values });
                               } }*/
                               onChange={this.handleChangeCharCount(index)}
                               label={ t('enw-input-description') + " - " + title.language_code }
                               multiline={true}
                               fullWidth
                               required={false}
                               rows={2}
                               inputProps={{maxLength : this.state.descriptionMaxCharCount}} />
                    <div style={{ width: '100%'}}>
                        <p style={{ float : 'right'}}> { this.state.descriptionCharCount[index] }/ {this.state.descriptionMaxCharCount}</p>
                    </div>
                </TabPanel>
            );
        })

        const descriptionTabs = this.state.description.map((title,value)=>{
            const flag = <Grid container className="brand-desc-flag" spacing={1}>
                <Grid item sm={3}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} alt={title.language_code} />
                </Grid>
                <Grid item sm={2}>
                    {title.language_code}
                </Grid>
            </Grid>
            return (
                <Tab label={ flag } {...a12yProps(value)} />
            );
        })

        return (
            <div>
                <ValidatorForm onSubmit={this.onSubmit} ref={"form"} onError={errors => console.log(errors)}>
                    <AppBar className="room-rate-tab-title" position="static">
                        <Tabs className="room-rate-tabFont" value={value} onChange={this.handleChange} aria-label="room-rate-tab">
                            <Tab label={ t('enw-room-rooms-modal-tab-room_type') } {...a11yProps(0)} />
                            <ButtonAtm type='submit' className={value === 1 ? "bed-type-title" : "passive-tab"} buttontext={ t('enw-room-rooms-modal-tab-bed_type') } {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <ColAtm xs={12} className="select-room-types">
                            <Grid container spacing={3}>
                                <ColAtm xs={12} md={6} className="form-group">
                                    <SelectValidator
                                        value={roomTypeValue}
                                        onChange={this.handleChangeRoomType}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-room-type') }
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    >
                                        {
                                            allRoomTypes.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1).map( (roomType) => (
                                                <MenuItem  value={roomType.id} description="Example Room">
                                                    { t(roomType.language_key) }
                                                </MenuItem>
                                            ))
                                        }
                                    </SelectValidator>
                                </ColAtm>
                                <ColAtm xs={12} md={6}>
                                    <TextValidator
                                        id="roomTypeCount"
                                        name="room_type_count"
                                        value={this.state.room_type_count}
                                        placeholder={ t('enw-input-room_type_count') }
                                        label={ t('enw-input-room_type_count') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['minNumber:1', 'maxNumber:999','required']}
                                        errorMessages={[ 'minNumber 1', 'maxNumber 999',t("enw-notification_this_is_required")]}
                                    />
                                </ColAtm>
                            </Grid>
                            <Grid container spacing={3}>
                                <ColAtm xs={12} md={6}>
                                    <InputAtm
                                        name="roomName"
                                        variant="outlined"
                                        placeholder={ t('enw-input-room_name') }
                                        label={ t('enw-input-room_name') }
                                        fullWidth
                                        value={this.state.roomName}
                                        onChange={this.handleChangeFields}
                                    />
                                </ColAtm>
                                <ColAtm md={3}>
                                    <TextValidator
                                        id="roomSize"
                                        name="room_size"
                                        value={this.state.room_size}
                                        placeholder={ t('enw-input-room_size') }
                                        label={ t('enw-input-room_size') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9-]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </ColAtm>
                                <ColAtm className="select-width" xs={12} md={3}>
                                    <SelectValidator
                                        name="room_size_type"
                                        value={this.state.room_size_type}
                                        onChange={this.handleChangeFields}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-room_size_type') }
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    >
                                        {
                                            Object.keys(this.props.roomSizeTypes).length > 0 ? this.props.roomSizeTypes.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1).map(
                                                (roomSizeType) => (
                                                    <MenuItem key={roomSizeType.id}  value={roomSizeType.code}>
                                                        { t(roomSizeType.language_key) }
                                                    </MenuItem>
                                                )
                                                ) :
                                                (
                                                    <MenuItem>
                                                        { "Data not fount" }
                                                    </MenuItem>
                                                )
                                        }
                                    </SelectValidator>
                                </ColAtm>
                            </Grid>
                            <Grid container spacing={3}>
                                <ColAtm xs={12} md={3}>
                                    <TextValidator
                                        id="roomCount"
                                        name="room_count"
                                        value={this.state.room_count}
                                        placeholder={ t('enw-input-number_of_rooms') }
                                        label={ t('enw-input-number_of_rooms') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['minNumber:1', 'maxNumber:999']}
                                        errorMessages={[ 'minNumber 1', 'maxNumber 999']}
                                    />
                                </ColAtm>
                                <ColAtm xs={12} md={3}>
                                    <TextValidator
                                        id="loungeCount"
                                        name="lounge_count"
                                        value={this.state.lounge_count}
                                        placeholder={ t('enw-input-number_of_lounge') }
                                        label={ t('enw-input-number_of_lounge') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['minNumber:1', 'maxNumber:99', 'matchRegexp:^[1-99][0-99]?$|^99$']}
                                        errorMessages={[ 'minNumber 1', 'maxNumber 99', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                                <ColAtm xs={12} md={3}>
                                    <TextValidator
                                        id="bathroomCount"
                                        name="bathroom_count"
                                        value={this.state.bathroom_count}
                                        placeholder={ t('enw-input-number_of_bathrooms') }
                                        label={ t('enw-input-number_of_bathrooms') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['minNumber:1', 'maxNumber:99', 'matchRegexp:^[1-99][0-99]?$|^99$']}
                                        errorMessages={[ 'minNumber 1', 'maxNumber 99', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                                <ColAtm xs={12} md={3}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            id="max_child_number"
                                            options={ages}
                                            getOptionLabel={option => String(option)}
                                            defaultValue={ isDefined(this.state.max_child_number) ? this.state.max_child_number : ""}
                                            onChange={(e, value) => this.handleChangeMaxChildFields(e, String(value))}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        className={"auto-complete-select"}
                                                        fullWidth
                                                        {...params}
                                                        label={ t('enw-input-max_child_number') }
                                                        variant="outlined"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </ColAtm>
                            </Grid>
                            {/*<Grid container spacing={3}>
                                <ColAtm xs={12} md={4}>
                                    <TextValidator
                                        id="toiletCount"
                                        name="toilet_count"
                                        value={this.state.toilet_count}
                                        placeholder={ t('enw-input-number_of_toilets') }
                                        label={ t('enw-input-number_of_toilets') }
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        fullWidth
                                        autoComplete="off"
                                        onChange={this.handleChangeFields}
                                        validators={['minNumber:1', 'maxNumber:99', 'matchRegexp:^[1-99][0-99]?$|^99$']}
                                        errorMessages={[ 'minNumber 1', 'maxNumber 99', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                            </Grid>*/}
                            <ColAtm xs={12}>
                                <Autocomplete
                                    className="auto-complete-select"
                                    onChange={this.handleChangeRoomViewType}
                                    multiple
                                    fullWidth
                                    options={this.props.roomViewTypes.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                    defaultValue={  viewTypeReturn(this.props.roomViewTypes, this.state.roomViewType)  }
                                    getOptionLabel={ (option) => t(option.language_key)}
                                    filterSelectedOptions
                                    filterOptions={createFilterOptions({
                                        matchFrom: 'start',
                                        stringify: option => t(option.language_key),
                                    })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            label={ t('enw-input-room_view') }
                                            placeholder={ t('enw-input-room_view') }
                                        />
                                    )}
                                />
                            </ColAtm>
                        </ColAtm>
                        <ColAtm className="d-block brand-container room-desc">
                            <ColAtm>
                                <AppBar className="room-rate-tab-title" position="static">
                                    <Tabs className="room-rate-tabFont"
                                          value={this.state.tabValue}
                                          onChange={this.handleTabChange}
                                          aria-label="room-rate-tab"
                                          variant="scrollable"
                                          scrollButtons="on"
                                    >
                                        {descriptionTabs}
                                    </Tabs>
                                </AppBar>
                                {descriptionList}
                            </ColAtm>
                        </ColAtm>
                        <ColAtm className="mt-13 assign-rate-next" xs={12}>
                            <BlockAtm className="t-center pb-32" xs={12}>
                                <Tabs className="room-rate-tabFont" value={value} onChange={this.handleChange} aria-label="room-rate-tab">
                                    <Tab label="Rate Plan Detail" {...a11yProps(0)} />
                                    <ButtonAtm type='submit'  buttontext={ t('btn-next') } {...a11yProps(1)} />
                                </Tabs>
                            </BlockAtm>
                        </ColAtm>
                    </TabPanel>
                    <TabPanel value={value} index={1} className="mt-22">
                        {
                            this.props.updateError && isDefined(this.props.updateError) && isDefined(this.props.updateError.response)  ?
                                <ColAtm className="t-center pb-14 update-room-error" md={12}>
                                    <Alert severity="error">{ this.props.updateError.response.data.message }</Alert>
                                </ColAtm>
                                :
                                ""
                        }
                        <ColAtm className="max-room-info" container justify="space-between">
                            <Grid container spacing={3} className="occupancy-box mobile-no-spacing">
                                <ColAtm xs={12} md={4}>
                                    <TextValidator
                                        id="adults"
                                        name="maxAdults"
                                        value={this.state.maxAdults}
                                        placeholder={ t('enw-input-max_adults') }
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={ (e) => this.handleChangeOccupancy(e) }
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <LabelAtm labelClass="occupancy-desc" text={ t('enw-input-max_adults-description') } />
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button><i className="fas fa-male" /></Button>
                                                    </HtmlTooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        validators={['required','minNumber:0', 'maxNumber:30', 'matchRegexp:^[0-9][0-9]?$|^30$']}
                                        errorMessages={[t("enw-notification_this_is_required"),'minNumber 0', 'maxNumber 30', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                                <ColAtm xs={12} md={4}>
                                    <TextValidator
                                        id="children"
                                        name="maxChildren"
                                        placeholder={ t('enw-input-max_children') }
                                        variant="outlined"
                                        autoComplete="off"
                                        value={this.state.maxChildren}
                                        onChange={(e) => this.handleChangeOccupancy(e)}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <LabelAtm labelClass="occupancy-desc" text={ t('enw-input-max_children-description') } />
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button><i className="fas fa-child" /></Button>
                                                    </HtmlTooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        validators={['required','minNumber:0', 'maxNumber:30', 'matchRegexp:^[0-9][0-9]?$|^30$']}
                                        errorMessages={[ t("enw-notification_this_is_required"),'minNumber 0', 'maxNumber 30', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                                <ColAtm xs={12} md={4}>
                                    <TextValidator
                                        id="occupancy"
                                        name="maxRoomOccupancy"
                                        variant="outlined"
                                        autoComplete="off"
                                        placeholder={ t('enw-input-max_room_occupancy') }
                                        onChange={(e) => this.handleChangeOccupancy(e)}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <LabelAtm labelClass="occupancy-desc" text={ t('enw-input-max_room_occupancy-description') } />
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button><i className="fas fa-users" /></Button>
                                                    </HtmlTooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.maxRoomOccupancy}
                                        validators={['required','minNumber:0', 'maxNumber:30', 'matchRegexp:^[1-9][0-9]?$|^30$']}
                                        errorMessages={[ t("enw-notification_this_is_required"),'minNumber 0', 'maxNumber 30', 'yalnızca sayı']}
                                    />
                                </ColAtm>
                            </Grid>
                            <ColAtm style={{display: 'inherit'}} className="mt-32 occupancy-height">
                                {
                                    Object.keys(occupancyCode).length > 0 ?
                                        occupancyCode.map( (occupancy) => (
                                            <Button
                                                className={this.state.exclude_occupancy.includes(occupancy.code) ? "select-occupancy" : "deselect-occupancy"  }
                                                type="button"
                                                onClick={ () => this.handleChangeExcludeOccupancy(occupancy.code) }
                                            >
                                                {ReactHtmlParser(occupancy.icon)}
                                            </Button>
                                        )) : ''
                                }
                            </ColAtm>
                            <LabelAtm
                                labelClass="select-room-type-text"
                                text={ t('enw-room-rooms-modal-room_type-sub_description') }
                            />
                        </ColAtm>
                        {
                            this.state.options.map( (option,id) => (
                                <BlockAtm>

                                    <ColAtm>
                                        <LabelAtm labelClass="bed-type-title ml-8" text={ t('enw-room-rooms-modal-bed_type-title') + ' ' + (id + 1) } />
                                        { id > 0 &&
                                        <ColAtm xs={1} className="form-group mr-9" style={{ paddingTop:10}}>
                                            <ButtonAtm className="delete-bed-button" fullwidth={true}
                                                       startIcon={<i className="fas fa-minus-circle" />}
                                                       onClick={this.handleDeleteOptionField(id)}
                                            />
                                        </ColAtm>
                                        }
                                        {
                                            option.map( (el,subId) => (
                                                <ColAtm xs={11} className="select-room-types" key={subId} id={subId}>
                                                    <ColAtm md={12} className="form-group general-select">
                                                        <FormControl variant="outlined" fullWidth className="MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-md-11">
                                                            <InputLabel>{ t('enw-input-bed_type') }</InputLabel>
                                                            <Select value={el.bed_type_id} className="form-control" label={ t('enw-input-bed_type') } onChange={this.handleChangeBedType(id,subId)}>
                                                                {
                                                                    this.props.allBedType.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1).map( (bedType) => (
                                                                        <MenuItem value={ bedType.id } description="Beds">
                                                                            <img className="bed-type-icons" src={"/assets/img/icons/bed-icons/" + (bedType.icon)} /> { t(bedType.language_key) }
                                                                        </MenuItem>
                                                                    ))
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                        <ColAtm xs={1} className="form-group pt-24">
                                                            <ButtonAtm className={option.length > 1 ? "delete-bed-button" : "d-none"}  fullwidth={true}
                                                                       startIcon={<i className="fas fa-minus-circle" />}
                                                                       id={subId}
                                                                       onClick={() => this.handleDeleteFields(subId, id) }
                                                            />

                                                        </ColAtm>
                                                    </ColAtm>
                                                </ColAtm>
                                            ))
                                        }
                                        <ColAtm md={1} className="form-group pt-24 new-bed-type">
                                            <ButtonAtm className="new-bed-button" fullwidth={true}
                                                       startIcon={<i className="fas fa-plus-circle" />}
                                                       onClick={this.handleAddField(id)}
                                            />
                                        </ColAtm>
                                    </ColAtm>

                                    <ColAtm className="bed-type-info" container>
                                    </ColAtm>
                                </BlockAtm>
                            ) )
                        }
                        {
                            this.props.roomAddError && Object.keys(this.props.roomAddError).length > 0 ?  <ColAtm className="t-center pb-14 update-room-error" md={12}>
                                <Alert severity="error">{ this.props.roomAddError.response.data.message }</Alert>
                            </ColAtm> : ""
                        }
                        <ColAtm>
                            <BlockAtm className="t-center pb-32" xs={12}>
                                <ButtonAtm
                                    className="room-rate-add soft-blue-button m-auto radius-8"
                                    type="button"
                                    buttontext={ t('btn-add_bedding_option') }
                                    startIcon={<i className="fas fa-plus-circle" />}
                                    onClick={this.handleAddOptions}
                                />
                            </BlockAtm>
                            <BlockAtm xs={12} className="t-center">
                                <ButtonAtm
                                    className="room-rate-modal-save room-rate-save bg-red m-auto"
                                    buttontext={ t('btn-save') }
                                    autoFocus
                                    color="primary"
                                    type="submit"
                                    onClick={this.handleRoomAdd}
                                />
                            </BlockAtm>
                        </ColAtm>
                    </TabPanel>
                </ValidatorForm>
            </div>
        );
    }
}

EditRoomForm.propTypes = {};

const mapStateToProps = (props) => {
    return{
        updateError : props.propertyRoomAndRateReducers.updateError,
    }
}

export default compose(withTranslation('common')) ( connect(mapStateToProps, {} )(EditRoomForm));
