import React, {Component}   from 'react';
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import {NETWORK_RESERVATIONS} from "../../../../../constants/appUrls.constants";
import {getReservationDetail} from "../../../../../redux/actions/Network/Reservation/reservationDetail.actions";
import Detail from "./components/Detail";
import ReservationUpdateModal from "./components/ReservationUpdateModal";
import {Grid} from "@material-ui/core";
import {
    createTicketClearState
} from "../../../../../redux/actions/Network/Reservation/ticket.actions";
import CreatePaymentCodeModal from "./components/CreatePaymentCodeModal";

let t =null;

class ReservationDetail extends Component {
    constructor(props) {
        super(props);
        t = props.t;
        this.state = {
            booking_id : null
        };
    }

    componentDidMount() {

        if (this.props.location.search.indexOf("propertyid") > -1) {
           const propertyId = this.props.location.search.split('=');
           localStorage.setItem("property", propertyId[1])
        }

        if(this.props.match.params.id ){
            this.props.getReservationDetail({ booking_id : this.props.match.params.id });
            this.setState({
                booking_id : this.props.match.params.id
            })
        }else{
            this.props.history.replace(NETWORK_RESERVATIONS);
        }

        this.props.createTicketClearState();

    }


    render() {
        return (
            <>
                <PageTitle title={ t('enw-reservation_detail-page_title') } />
                <PageHeaderAtm exitText={ t('btn-reservation_list') } backButton={NETWORK_RESERVATIONS} text={ t('enw-reservation_detail-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        {
                            !this.props.fetching && this.props.reservation &&
                            Object.keys(this.props.reservation).length > 0 &&
                            Object.keys(this.props.reservation.booking_detail.length > 0) &&
                            <div className="container-width reservation-detail">
                                <Grid container>
                                    <Grid item xs={12} className="mb-16">
                                        <Grid item className="photoButtonsRow">
                                            <ReservationUpdateModal
                                                reservation={this.props.reservation.booking_detail}
                                            />
                                        </Grid>
                                        {
                                            this.props.reservation?.booking_detail?.isThereBookingPaymentData &&
                                            <Grid item className="photoButtonsRow">
                                                <CreatePaymentCodeModal
                                                    reservation={this.props.reservation.booking_detail}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Detail reservation={this.props.reservation} />
                            </div>
                        }
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        fetching : state.reservationDetailReducer.fetching,
        reservation : state.reservationDetailReducer.reservation,
        error : state.reservationDetailReducer.error
    }
};

const mapDispatchToProps = {
    getReservationDetail,
    createTicketClearState,
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ReservationDetail));
