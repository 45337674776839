import React from "react";
import Dialog from "@material-ui/core/Dialog";
import BookingForm from "./BookingEnginePreviewDetail";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from '@material-ui/core/Slide';

let t=null;


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BookingEnginePreviewDialog extends React.Component{

    constructor(props) {
        super(props);
        t = props.t;
    }

    render(){
        return (
            <Dialog className="web-preview-dialog"
                    TransitionComponent={Transition}
                    onClose={this.props.handlePreviewClose}
                    fullScreen
                    aria-labelledby="customized-dialog-title"
                    open={this.props.open_preview}
            >
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.props.handlePreviewClose}>
                    { t('enw-be-preview-title') }
                </DialogTitle>
                <DialogContent>
                    <BookingForm
                        handleClickOpen = {this.props.handleClickOpenPreview}
                        handlePreviewClose = {this.props.handlePreviewClose}
                        url = {this.props.url}
                        webDefaultLang={this.props.webDefaultLang}
                        frameReload={this.props.frameReload}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(withTranslation('common'))(BookingEnginePreviewDialog);
