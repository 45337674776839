import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Checkbox, FormControlLabel, Grid, MenuItem} from "@material-ui/core";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import {getAppLang, getCurrency, isDefined} from "../../../../../utils";
import {Alert} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CouponDataClear, GetCoupons, SyncCoupons} from "../../../../../redux/actions/Network/Coupons/couponsActions";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TodayIcon from "@material-ui/icons/Today";
import moment from "moment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";
import ruLocale from "date-fns/locale/ru";
import frLocale from "date-fns/locale/fr";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const localeMap = {
    es: esLocale,
    de: deLocale,
    tr: trLocale,
    ru: ruLocale,
    fr: frLocale,
};

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function CouponNewModal({id, pageId}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleSubmit } = useForm();

    const couponsReducers = useSelector(props => props.couponsReducers)


    const [couponType, setCouponType] = useState("");
    const [title, setTitle] = useState("");
    const [code, setCode] = useState(Math.floor(Math.random() * 100000000));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setTitle("");
        setCode(Math.floor(Math.random() * 100000000));
        setCouponType("");
        setStartDate(null);
        setEndDate(null);
        setValue("");
        setIsChecked(false);
        dispatch(CouponDataClear());
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeTitle = ({target}) => {
        setTitle(target.value)
    };
    const changeCode = ({target}) => {
        setCode(target.value)
    };

    const changeType = ({target}) => {
        setCouponType(target.value)
    };

    const changeAmount = ({target}) => {
        setValue(target.value)
    };

    const changeStartDate = date => {
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeEndDate = date => {
        setEndDate(moment(date).format("YYYY-MM-DD"))
    }

    const handleIsNotify = (event) => {
        setIsChecked(event.target.checked);
    };

    const onSubmit = () => {
        let params;

        params = {
            id: id,
            title: title,
            channel_id: pageId,
            code: code,
            start_date: startDate,
            end_date: endDate,
            type: couponType,
            is_notify: isChecked ? isChecked : null,
            value: value
        }

        dispatch(SyncCoupons(params))
    };

    useEffect( () => {

        if (couponsReducers.syncCouponsStatus === 200 && open) {
            handleClose();
            dispatch(GetCoupons(pageId));
        }

        if (couponsReducers.syncCouponsStatus === 200) {
            setCouponType("");
            setTitle("");
        }

    },[couponsReducers])

    return (
        <>
            <Button className="filter-action-button float-right mb-24" aria-controls="filter-action-menu" aria-haspopup="true" onClick={handleClickOpen}>
                <i className="fas fa-plus-circle" /> { t('btn-new_coupon') }
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-fact_description_title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {isDefined(couponsReducers) && couponsReducers?.syncCouponsStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                            {isDefined(couponsReducers?.syncCouponsError?.response) && <Alert severity="error">{couponsReducers?.syncCouponsError?.response?.data?.message}</Alert>}

                            <Grid container spacing={3} className="mobile-no-spacing">
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="title"
                                        type="text"
                                        variant="outlined"
                                        label={ t('enw-input-coupon-title') }
                                        autoComplete="off"
                                        value={title}
                                        onChange={changeTitle}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="code"
                                        type="text"
                                        variant="outlined"
                                        label={ t('enw-input-coupon-code') }
                                        autoComplete="off"
                                        value={code}
                                        onChange={changeCode}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="mobile-no-spacing">
                                <Grid item xs={12} sm={6}>
                                    <SelectValidator
                                        name="addon_type"
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-value_type') }
                                        value={couponType}
                                        onChange={changeType}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    >
                                        <MenuItem value="PER">
                                            { t('enw-input-percent') }
                                        </MenuItem>
                                        <MenuItem value="FIX">
                                            { t('enw-input-fixed') }
                                        </MenuItem>
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="value"
                                        type="text"
                                        variant="outlined"
                                        label={ t('enw-input-value') }
                                        autoComplete="off"
                                        value={value}
                                        onChange={changeAmount}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <>
                                                {
                                                    couponType === "PER" ?
                                                        <InputAdornment position="start">
                                                            %
                                                        </InputAdornment>
                                                        :
                                                        couponType === "FIX" ?
                                                            <InputAdornment position="start">
                                                                { getCurrency() }
                                                            </InputAdornment>
                                                            :
                                                            <InputAdornment position="start">

                                                            </InputAdornment>
                                                }
                                            </>
                                        }}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            margin="normal"
                                            label={ t('enw-input-from_date')}
                                            className="w-100"
                                            variant="inline"
                                            autoOk
                                            inputVariant="outlined"
                                            format="dd.MM.yyyy"
                                            value={startDate}
                                            onChange={changeStartDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <TodayIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            margin="normal"
                                            label={ t('enw-input-to_date')}
                                            variant="inline"
                                            inputVariant="outlined"
                                            className="w-100"
                                            format="dd.MM.yyyy"
                                            autoOk
                                            value={endDate}
                                            minDate={ moment(startDate).add(1,'days') }
                                            onChange={changeEndDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <InsertInvitationIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} className='mw-100'>
                            <FormControlLabel
                                        className="mt-10"
                                        control={<Checkbox color="primary" checked={isChecked}  onChange={handleIsNotify}/>}
                                        label={ t('enw-input-coupon_notify') }
                                        labelPlacement="left"
                                    />

                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
