import React, {Component}   from 'react';
import {Grid} from "@material-ui/core";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../../../utils/history";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {isDefined} from "../../../../../../utils";
import {CircularProgressbar} from "react-circular-progressbar";
import ChannelDisconnect from "./ChannelDisconnect";
import BlockAtm from "../../../../../atomic/Block/Block";
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

let t =null;

class ChannelInformationData extends Component {

    constructor(props){
        super(props);
        t = props.t;

        this.state = {
            channel_id : '',
            is_connected : false,
            isVisible: true,
            fadeOut: false,
            fadeIn: true,
            isConnected: this.props.channelSetupReducers ? this.props.channelSetupReducers.is_connected : '',
            errorMessageOpen: true,
        };
    }

    setVisible = (e, id) => {

        setTimeout(() => {
            this.setState({
                isVisible: false
            })
        }, 300)

        this.setState({
            fadeIn: false,
            fadeOut: true,
        })
        setTimeout(() => {
            history.push(`${"/app/network/channel-setup/" + id}`)
        }, 800);

    }

    setDisconnect = (e, id) => {
        e.preventDefault();

        let params = {
            id: id,
            is_selected: false,
        }

        this.props.updateChannelConnect( params );
    }

    onSubmit = (e) => {
        e.preventDefault();

        const param = {
            channel_id: this.props.channelId,
            booking_type_id: "",
            availability_type_id: "",
            room_pricing_type_id: "",
            currency_code: ""
        }
        this.props.addChannelSetup(param);
    };

    handleCopy = (e, copyText) =>  {
        e.preventDefault();
        navigator.clipboard.writeText(copyText)
        this.setState({copied:true});

        setTimeout(() => {
            this.setState({copied: false});
        }, 1000)

    }

    render() {
        let InformationData = this.props.channelSetupReducers

        const {errorMessageOpen} = this.state;
        const {updateChannelData, channelInformationDataCategory} = this.props;

        return (
            <ColAtm xs={11} lg={8} className={ this.state.fadeOut ? "slide-bottom-list m-auto channel-information" : "slide-top-list m-auto channel-information" }>
                {
                    InformationData.channel_category_id === 4 && InformationData.parent_id === 5 ?
                        <Grid container>
                            {
                                isDefined(updateChannelData.channelSetupSubmitData.data) && updateChannelData.channelSetupSubmitData.data.status === 2 &&
                                <Alert severity="success" className="w-100">
                                    <AlertTitle>{ t('enw-channel-success_message-title') }</AlertTitle>
                                        <p className="fs-16 mb-0">{ t('enw-channel-success_message-desc') }</p>
                                </Alert>
                            }
                            {
                                isDefined(updateChannelData.submitError.response) &&
                                <Collapse in={errorMessageOpen} className="w-100 mb-24">
                                    <Alert
                                        severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({errorMessageOpen: false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        <p className="fs-16 mb-0">{updateChannelData.submitError.response.data.message} </p>
                                    </Alert>
                                </Collapse>
                            }

                            {
                                !isDefined(updateChannelData.channelSetupSubmitData.data) &&

                                <Alert severity={InformationData.is_connected && !InformationData.is_pending ? "success" : "info"} className="w-100" variant="outlined">
                                    <AlertTitle>{ t('enw-channel-info_message-title') }</AlertTitle>
                                    {
                                        InformationData.is_connected && InformationData.is_pending ?
                                            <p className="fs-16">{ t('enw-channel-success_message-desc') }</p>
                                            :
                                            InformationData.is_connected && !InformationData.is_pending ?
                                                <p className="fs-16">{ t('enw-channel-warning_message-desc') }</p>
                                                :
                                                <p className="fs-16">{ t('enw-channel-info_message-desc') }</p>
                                    }
                                </Alert>
                            }

                            <BlockAtm xs={12} className="t-center mt-22">
                                {
                                    InformationData.is_pending || InformationData.is_connected || (isDefined(updateChannelData.channelSetupSubmitData.data) && updateChannelData.channelSetupSubmitData.data.status === 2) ?
                                        <ButtonAtm
                                            buttontext={ t('btn-disconnect') }
                                            className="m-auto room-rate-modal-save bg-red m-width-100"
                                            startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                            onClick={(e) => this.setDisconnect(e, InformationData.id)}
                                        />
                                        :
                                        <ButtonAtm
                                            buttontext={ t('btn-connect_now') }
                                            className="bg-green m-auto m-width-100 room-rate-modal-save"
                                            startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                            type="submit"
                                            onClick={this.onSubmit}
                                        />
                                }

                            </BlockAtm>
                        </Grid>
                    :
                        <>
                            <ColAtm sm={12} md={8} className="offer-top-left">
                                <ColAtm sm={12} className="offer-carousel-height">
                                    <Grid item xs={12} sm={9} className="m-auto m-order-1">
                                        <Grid container className="channel-informations">
                                            <Grid item xs={12} className="mb-16">
                                                <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_info-title') } />
                                            </Grid>
                                            {
                                                isDefined(InformationData.official_name) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-channels-channel_official_name-title') } : <span>{ InformationData.official_name }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.name) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-channels-channel_name-title') } :  <span>{ InformationData.name }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.address) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-input-address') }:  <span>{ InformationData.address }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.email) &&
                                                <Grid item xs={12}>
                                                    <p>{ t('enw-input-email') }: <span>{ InformationData.email }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.phone) &&
                                                <Grid item xs={12}>
                                                    <p>{ t('enw-input-phone') }: <span>{ InformationData.phone }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.phone2) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-input-secondary_phone') }: <span>{ InformationData.phone2 }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.fax) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-input-fax') }: <span>{ InformationData.fax }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(channelInformationDataCategory.language_key) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-channels-channel_type-title') } : <span>{ t(channelInformationDataCategory.language_key) }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.country_code) &&
                                                <Grid item xs={12}>
                                                    <p> { t('enw-input-country') }: <span className="uppercase">{ InformationData.country_code }</span></p>
                                                </Grid>
                                            }
                                            {
                                                isDefined(InformationData.bookingEngineUrl) &&
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <p> { t('enw-be-search-booking_engine-title') }:
                                                                <span className="d-flex">
                                                                    <Link className="black" to={{pathname: InformationData.bookingEngineUrl}} target="_blank">
                                                                        {InformationData.bookingEngineToken}
                                                                    </Link>
                                                                      <Grid className="be-copy-icon ml-5">
                                                                        <Tooltip title={this.state.copied ? t('btn-copied') : t('btn-copy')}>
                                                                            <i className="fas fa-copy"
                                                                               onClick={(e) => this.handleCopy(e, InformationData.bookingEngineUrl)} />
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </span>
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} className="m-order-0">
                                        <Grid className="channel-information-logo">
                                            <Grid>
                                                <img src={`${InformationData.logoUrl}`} alt={InformationData.country_code} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ColAtm>
                            </ColAtm>
                            <ColAtm sm={12} md={4} className="offer-top-right" style={{backgroundColor: "#d71f26"}}>
                                <ColAtm sm={12} className="m-auto">
                                    <ColAtm>
                                        {
                                            isDefined(InformationData.score) &&
                                            <Grid container>
                                                <Grid item xs={12} className="brand-logo-upload">
                                                    <p className={"channel-score-text"}>{ t('enw-channel-score-title') }</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CircularProgressbar
                                                        className="channel-rate"
                                                        value={InformationData.score}
                                                        text={InformationData.score}
                                                        counterClockwise
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </ColAtm>
                                    <Grid container className={isDefined(InformationData.score) ? "mt-60 channel-actions" : "channel-actions"}>
                                        <Grid item xs={12}>
                                            <p>{ t('enw-channels-connected_hotels-title') } : <span className="uppercase">10</span></p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p> { t('enw-channels-reservation_count-title') } : <span className="uppercase">120</span></p>
                                        </Grid>
                                    </Grid>
                                </ColAtm>
                            </ColAtm>
                            { !this.props.channelSetup.fetching &&
                            this.state.isConnected ?
                                this.props.updateChannelData.channelUpdate.status !== 200 ?
                                    <Grid container className="pt-22 justify-center m-channel-info-btn mobile-no-spacing" spacing={3}>
                                        <Grid item xs={12} md={3}>
                                            <ButtonAtm
                                                buttontext={ t('btn-setup') }
                                                className="channel-setup-btn"
                                                startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                                onClick={(e) => this.setVisible(e, InformationData.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <ButtonAtm
                                                buttontext={ t('btn-disconnect') }
                                                className="m-auto room-rate-modal-save bg-red m-width-100"
                                                startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                                onClick={(e) => this.setDisconnect(e, InformationData.id)}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    <ColAtm>
                                        <ButtonAtm
                                            buttontext={ t('btn-connect_now') }
                                            className="green-button m-auto"
                                            startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                            onClick={(e) => this.setVisible(e, InformationData.id)}
                                        />
                                    </ColAtm>
                                :
                                <ColAtm xs={12}>
                                    <ButtonAtm
                                        buttontext={ t('btn-connect_now') }
                                        className="green-button m-auto"
                                        startIcon={<i className="channel-zoom-btn fas fa-link" />}
                                        onClick={(e) => this.setVisible(e, InformationData.id)}
                                    />
                                </ColAtm>
                            }
                        </>

                }

                <ChannelDisconnect updateChannelData={this.props.updateChannelData} channelName={InformationData.name} isConnect={this.state.isConnected} />
            </ColAtm>
        );
    }
}

export default compose(withTranslation('common')) (ChannelInformationData);
