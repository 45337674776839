import React, {Component}   from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";

import Container from "@material-ui/core/Container";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    CONTENT, CONTENT_PHOTOS, CONTENT_BRAND
} from "../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";

import FooterAtm from "../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {Grid} from "@material-ui/core";

import {
    getAwardsCertificates,
    createAwardsCertificates,
    updateAwardsCertificates,
    getAwardsCertificatesCategories,
    deleteAwardsCertificatesPhoto,
    createOrUpdateAwardsCertificatesErrorClear
} from "../../../../redux/actions/Content/AwardsCertificates/awardsCertificatesActions";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Menu from "../../Global/Layout/Header/Submenu/General";
import AwardsCertificatesList from "./components/AwardsCertificatesList";
import CreateAwardsCertificatesModal from "./components/createAwardsCertificatesModal";
import ColAtm from "../../../atomic/Col/Col";
import LabelAtm from "../../../atomic/Label/Label";

let t =null;

class AwardsCertificates extends Component {

    constructor(props){
        super(props);
        this.state = {
            isVisible: true,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        this.props.getAwardsCertificates();
        this.props.getAwardsCertificatesCategories();
    };

    render() {

        const {awardsCertificatesReducers} = this.props;

        return (
            <>
                <PageTitle title={ t('enw-general-awards_and_certificates-page_title') } />
                <PageHeaderAtm backButton={CONTENT}
                               exitText= { t('btn-dashboard') }
                               text={ t('enw-general-title') }
                />
                <Menu awardsCertificates="active-menu-bg" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        {
                            !awardsCertificatesReducers.awardsCertificatesListFetching &&
                            <div className="container-width">
                                { awardsCertificatesReducers.awardsCertificatesList && awardsCertificatesReducers.awardsCertificatesList.length < 1 ? (
                                    <ColAtm>
                                        <Container maxWidth="xl">
                                            <div className="offer-container-width">
                                                <ColAtm>
                                                    <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                        <ColAtm>
                                                            <div className="m-auto first-create-room first-awards-btn">
                                                                <span>
                                                                   <CreateAwardsCertificatesModal
                                                                       clearErrorMessage = {this.props.createOrUpdateAwardsCertificatesErrorClear}
                                                                       createAwardsCertificates = {this.props.createAwardsCertificates}
                                                                       awardsCertificatesReducers = {this.props.awardsCertificatesReducers}
                                                                       getAwardsCertificates = {this.props.getAwardsCertificates}
                                                                       deleteAwardsCertificates = {this.props.deleteAwardsCertificatesPhoto}
                                                                   />
                                                                </span>
                                                            </div>
                                                        </ColAtm>
                                                        <LabelAtm labelClass="one-page-title" text={ t('enw-general-awards_and_certificates_create-title') } />
                                                        <LabelAtm labelClass="one-page-subtitle" text={ t('enw-general-awards_and_certificates_create-desc') } />
                                                    </ColAtm>
                                                </ColAtm>
                                            </div>
                                        </Container>
                                    </ColAtm>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12} className="mb-16">
                                            <Grid item  className="photoButtonsRow">
                                                <CreateAwardsCertificatesModal
                                                    clearErrorMessage = {this.props.createOrUpdateAwardsCertificatesErrorClear}
                                                    createAwardsCertificates = {this.props.createAwardsCertificates}
                                                    awardsCertificatesReducers = {this.props.awardsCertificatesReducers}
                                                    getAwardsCertificates = {this.props.getAwardsCertificates}
                                                    deleteAwardsCertificates = {this.props.deleteAwardsCertificatesPhoto}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="mt-22">
                                            <AwardsCertificatesList
                                                clearErrorMessage = {this.props.createOrUpdateAwardsCertificatesErrorClear}
                                                updateAwardsCertificates = {this.props.updateAwardsCertificates}
                                                awardsCertificatesReducers = {this.props.awardsCertificatesReducers}
                                                getAwardsCertificates = {this.props.getAwardsCertificates}
                                                deleteAwardsCertificates = {this.props.deleteAwardsCertificatesPhoto}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        }

                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    iconLeftClassName="fas fa-arrow-left"
                    buttonNextText={ t('btn-next') }
                    backTo={CONTENT_BRAND}
                    component={Link}
                    nextTo={CONTENT_PHOTOS}
                    nextComponent={Link}
                />
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        awardsCertificatesReducers: props.awardsCertificatesReducers,
    }
};

const mapDispatchToProps ={
    getAwardsCertificates,
    getAwardsCertificatesCategories,
    createAwardsCertificates,
    updateAwardsCertificates,
    deleteAwardsCertificatesPhoto,
    createOrUpdateAwardsCertificatesErrorClear
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(AwardsCertificates));
