import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import DeletePopup from "./DeletePopup";
import {WEB_POPUP_CREATE, WEB_POPUP_EDIT_SHORT} from "../../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ColAtm from "../../../../../atomic/Col/Col";
import {MenuItem} from "@material-ui/core";
import BlockAtm from "../../../../../atomic/Block/Block";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getPopupList} from "../../../../../../redux/actions/Web/Popup/PopupActions";
import {getLanguages} from "../../../../../../redux/actions/languageActions";
import moment from "moment/moment";
const PopupList = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const languageList = useSelector(props => props.languagesReducer?.languages);
    const error = useSelector(props => props.popupReducers?.listDataError);

    const columns = [
        t('enw-input-title'),
        t('enw-input-language'),
        t('enw-input-start_date'),
        t('enw-input-end_date'),
        t('enw-reservation-table-actions'),
    ];

    const {list, fetching} = props;
    const [reservations, setReservations] = useState([]);
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("");
    const [postData, setPostData] = useState(false);

    useEffect(() => {

        if (!fetching && list && list.length > 0) {
            let tempData = [];

            if (error) {
                tempData = [];
            } else {
                list.map((popup, index) => {//tablo içeriği buradan ayarlanıyor
                    tempData.push([
                        popup.title,
                        popup.language_code,
                        moment(popup.start_date).format("DD.MM.yyyy").toString(), //popup başlangıç tarihi
                        moment(popup.end_date).format("DD.MM.yyyy").toString(), //popup bitiş tarihi
                        <div> 
                            <Link className="filter-action-button float-right" to={`${WEB_POPUP_EDIT_SHORT}/${popup.id}`}><i
                                className="fas fa-edit"/></Link>
                            <DeletePopup popup={popup}/></div>,
                    ])
                });
            }

            setReservations(tempData);
        }
    }, [fetching, list, localStorage.getItem('lng')]);

    useEffect(() => {
        dispatch(getLanguages());
    }, [])

    const options = {
        filter: false,
        selectableRowsOnClick: false,
        selectableRows: false,
        searchOpen: true,
        searchPlaceholder: t('btn-search'),
        print: false,
        download: false,
        sortOrder: {
            name: 'name',
            direction: 'desc'
        },
        textLabels: {
            body: {
                noMatch: t('datatable-body-nomatch'),
                toolTip: t('datatable-body-tooltip'),
            },
            pagination: {
                next: t('datatable-pagination-next'),
                previous: t('datatable-pagination-previous'),
                rowsPerPage: t('datatable-pagination-rowsperpage'),
                displayRows: t('datatable-pagination-of'),
            },
            toolbar: {
                search: t('datatable-toolbar-search'),
                downloadCsv: t('datatable-toolbar-download_excel'),
                print: t('datatable-toolbar-print'),
                viewColumns: t('datatable-toolbar-view_columns'),
                filterTable: t('datatable-toolbar-filter_table'),
            },
            filter: {
                all: t('datatable-filter-all'),
                title: t('datatable-filter-title'),
                reset: t('datatable-filter-reset'),
            },
            viewColumns: {
                title: t('datatable-filter-view_columns-title'),
                titleAria: t('datatable-filter-view_columns-title_aria'),
            },
            selectedRows: {
                text: t('datatable-selected_rows-text'),
                delete: t('datatable-selected_rows-delete'),
                deleteAria: t('datatable-selected_rows-delete-aria')
            },
        },
    };

    const onSubmit = () => {
        let filter;

        filter = {
            title : name,
            language_code : language,
        }

        function clean(obj) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }

        setPostData(true)
        dispatch(getPopupList(clean(filter)))
    };

    const changeNameFilter = (e) => {
        setName(e.target.value)
    };

    const changeLanguage = (e) => {
        setLanguage(e.target.value)
    };

    const clearFilter = () => {
        setPostData(false)
        setName("")
        setLanguage("")
        dispatch(getPopupList())
    }

    return (
        <>
            <div className="blog-list-new-blog-btn">
                <Link className="filter-action-button float-right mb-24" to={WEB_POPUP_CREATE}><i
                    className="fas fa-plus-circle" aria-hidden="true"></i> {t('btn-create-popup')}</Link>
            </div>
            

            {
                !fetching && list &&
                <div className="blog-datatable">
                    <MUIDataTable
                        title={t('enw-popup-title')}
                        data={reservations} //not: tablo verileri apidan çekilecek
                        columns={columns}
                        options={options}
                    />
                </div>
            }
        </>
    )
}

export default PopupList;
