import React, {useEffect,useMemo} from "react";
import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";
import Accommodations from "./Accommodations";
import InventorySkeleton from "../../../../molecules/InventorySkeleton/InventorySkeleton";
import {isDefined} from "../../../../../utils";

let updatedData = [];

const Rooms =  (props) => {
    const state = useMemo( () => props.roomPrice,[props.roomPrice] )
    useEffect(() => {
        if(props.inventoryUpdate === 200){
            updatedData = [];
            props.changePriceStatus(false);
        }
        return () => {
            updatedData = [];
        }
    },[props.inventoryUpdate]);


    /*const onInputChange = (roomIndex,accommodationIndex, item) => {
        state[roomIndex]['accommodation'][accommodationIndex] = item;
    };*/

    const onChangeUpdateData = (key, value) => {
        /*let tempData = updatedData;
        let newObj = { ...tempData, [key]: value }
        //setUpdatedData(newObj)
        updatedData = newObj;*/
        updatedData[key] = value
        props.isChangePriceStatus();
    }

    const postData = (e) => {
        e.preventDefault();
        props.updatePropertyInventory(updatedData)
    };

    return (
        <>
            {
                props.fetching ?
                    <InventorySkeleton/>
                    :
                    <form onSubmit={postData} name="general-form" id="general-form" autoComplete="off" style={{width: '100%'}}>
                        {
                            isDefined(state) && isDefined(state.rooms) && Object.values(state.rooms).map( (room) => (

                                <ColAtm className="inventory-box">
                                    <ColAtm className="inventory-room-box">
                                        <ColAtm xs={3} lg={4} className="align-self-center">
                                            <BlockAtm md={1} className="m-h-auto">
                                                <i className="fas fa-bed inventory-icon" />
                                            </BlockAtm>
                                            <BlockAtm md={9}>
                                                <LabelAtm labelClass="inventory-room-name" text={room.name} />
                                            </BlockAtm>
                                        </ColAtm>
                                    </ColAtm>
                                    <Accommodations onChangeUpdateData={onChangeUpdateData}
                                                    roomRates={room.rates}
                                                    updatedData={updatedData}
                                                    showStopSell={props.showStopSell}
                                                    showMinStay={props.showMinStay}
                                    />
                                </ColAtm>

                            ))
                        }
                    </form>
            }
        </>
    )
}

export default Rooms;
