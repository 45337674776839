import React, {useEffect, useState} from "react";
import BlockAtm from "../../../../atomic/Block/Block";
import moment from "moment";
import LabelAtm from "../../../../atomic/Label/Label";

export default function RemainingAvailability(props) {
    useEffect(() => {},[]);
    const {key, value} = props.value
    const {updatedData} = props

    const [availability] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? updatedData[key] : value);
    const [hasData] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);

    return (
        <BlockAtm className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && 'weekend-avail-inputs'} inventory-input`}
                  style={{ borderColor: hasData ? "red" : "none" }}>
            <LabelAtm
                text={availability}
            />
        </BlockAtm>
    );
}
