import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import {
    GET_QUICK_PRICING,
    PROPERTY_INVENTORY_GET,
    PROPERTY_INVENTORY_UPDATE_OR_CREATE,
    UPDATE_QUICK_PRICING
} from "../../../../constants/api.constants"


export function getPropertyInventory(startDate, endDate, channelId) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                channel_id  : channelId,
                start_date  : startDate,
                end_date    : endDate
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_INVENTORY_GET',
            payload: axios.post(PROPERTY_INVENTORY_GET, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }

}

export function updateOrCreateInventory(channel_id,data) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },

        body: {
            params: {
                "property_id" : getProperty(),
                "channel_id"  : channel_id,
                "data"       : {...data}
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'PROPERTY_INVENTORY_UPDATE_OR_CREATE',
            payload: axios.post(PROPERTY_INVENTORY_UPDATE_OR_CREATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateQuickPricing (channel_id, data) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },

        body: {
            params: {
                property_id : getProperty(),
                channel_id  : channel_id,
                data       : {...data}
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'UPDATE_QUICK_PRICING',
            payload: axios.post(UPDATE_QUICK_PRICING, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateQuickPricingClear(){

    return dispatch => {
        dispatch({
            type: 'UPDATE_QUICK_PRICING_CLEAR',
        })
    }
}


export function getQuickPricing (channel_id) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },

        body: {
            params: {
                property_id : getProperty(),
                channel_id  : channel_id,
            }
        }
    }

    return dispatch => {
        dispatch({
            type: 'GET_QUICK_PRICING',
            payload: axios.post(GET_QUICK_PRICING, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}
