import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../atomic/Col/Col";
import OverFlowText from "../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../atomic/Block/Block";
import {Link} from "react-router-dom";
import {PAYMENT_SETUP_SHORT} from "../../../../../constants/appUrls.constants";

const PaymentsBox = (props) => {
    const {
        paymentTypeList,
    } = props;

    return(
            <Grid item className="offer-fact-col pos-relative" xs={6} sm={4} md={2}>
                <Link to={PAYMENT_SETUP_SHORT+paymentTypeList.id}>
                    <BlockAtm className="offer-fact-border-box fact-border-box">
                    <label htmlFor={paymentTypeList.id} className={paymentTypeList.has_setup ? "active-pos channel-logo-bg" : "passive-pos"}>
                        <label htmlFor={paymentTypeList.id}>
                            <Grid item xs={12} className="cancellation-info-col">
                                <ColAtm className="channel-logo pos-relative">
                                    <label>
                                        {
                                            paymentTypeList.has_setup &&
                                            <i className="channel-active-icon fad fa-dot-circle green" />
                                        }
                                        <img src={'/assets/img/logo/banks-logo/' +paymentTypeList.bank_code + '.jpg'} alt={paymentTypeList.bank_code} />
                                    </label>
                                </ColAtm>
                            </Grid>
                            <OverFlowText>{paymentTypeList.name}</OverFlowText>
                        </label>
                    </label>
                </BlockAtm>
                </Link>
            </Grid>

    )
}

export default PaymentsBox;
