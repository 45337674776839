import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import { GET_OFFER_PRICE ,STORE_OFFER_PRICE}   from "../../../../constants/api.constants";


export function getPriceOffer(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "locale" : getAppLang(),
                "property_id" : getProperty(),
                "offer_id" : param.offer_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_OFFER_PRICE',
            payload: axios.post(GET_OFFER_PRICE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function storePriceOffer(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "locale" : getAppLang(),
                "property_id" : getProperty(),
                "offer_id" : params.offer_id,
                "start_date": params.start_date,
                "end_date": params.end_date,
                "room_price" : params.room_price
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'STORE_OFFER_PRICE',
            payload: axios.post(STORE_OFFER_PRICE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
