import moment from "moment";

const TOKEN_KEY = "auth";
const PROPERTY  = "property";
const EXP_TIME  = "exp_time";
const APP_LANG  = "lng";
const USER_NAME = "name"
const PROPERTY_NAME = "property_name"
const MOBILE_LOGIN = "mobile_login"
const INVENTORY_START_DATE = "inventory_start_date"
const INVENTORY_END_DATE = "inventory_end_date"
const CURRENCY = "currency"
const LAST_TAX_ID = "lastTaxId"

export const userLogin = (token) => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY,token);
};

export const mobileLogin = (oneSignalKey) => {
    localStorage.removeItem(MOBILE_LOGIN);
    localStorage.setItem(MOBILE_LOGIN, oneSignalKey);
};

export const userLogout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXP_TIME);
    localStorage.removeItem(PROPERTY);
    localStorage.removeItem(USER_NAME);
    localStorage.removeItem(PROPERTY_NAME);
    localStorage.removeItem(INVENTORY_START_DATE);
    localStorage.removeItem(INVENTORY_END_DATE);
    localStorage.removeItem(LAST_TAX_ID);
};

export const isLogged = () => {
    return !!localStorage.getItem(TOKEN_KEY);
};

export const setPropertyId = (propertyId) => {
    localStorage.setItem(PROPERTY,propertyId);
};

export const setLocalStorage = (keyName, keyValue) => {
    localStorage.setItem(keyName,keyValue);
};

export const destroyLocalStorage = (keyName) => {
    localStorage.removeItem(keyName)
};

export function isDefined(obj) {
    return obj !== undefined && obj !== null && obj !== "null" && obj !== ""
}

export function timeToTimestamp(strDate){
    return Date.parse(strDate);
}

export function getExpireTime() {
    return localStorage.getItem(EXP_TIME);
}

export function setExpireTime(strDate) {
    localStorage.removeItem(EXP_TIME);
    let expTimestamp = timeToTimestamp(strDate.replace(/-/g, "/"));
    setLocalStorage(EXP_TIME,expTimestamp);
}

export function setUserName(userName){
    setLocalStorage(USER_NAME,userName)
}

export function getUserName(){
    return localStorage.getItem(USER_NAME);
}

export function setCurrency(currency_code){
    setLocalStorage(CURRENCY, currency_code)
}

export function getCurrency(){
    return localStorage.getItem(CURRENCY);
}

export function checkExpireTime() {
    let expTime = getExpireTime();
    let date = new Date();
    let currentTime = date.getTime();

    return expTime >= currentTime;
}

export function getMinutesBetweenTwoTimestamp(startTime, endTime) {
    let difference = endTime - startTime;
    return  Math.round(difference / 60000);
}

export function getExpireMinutes() {
    let date = new Date();
    let currentTime = date.getTime();
    let expireTime = getExpireTime();
    return getMinutesBetweenTwoTimestamp(currentTime, expireTime);
}

export function setPropertyList(properties) {
    setLocalStorage(PROPERTY, JSON.stringify(properties) );
}

export function getPropertyList() {
    let propertyList =  localStorage.getItem(PROPERTY);
    return JSON.parse(propertyList);
}

export const isProperty = () => {
    return !!localStorage.getItem(PROPERTY);
};

export const getProperty = () => {
    return isProperty() ? localStorage.getItem(PROPERTY) : null;
};

export function getUserToken() {
    return localStorage.getItem(TOKEN_KEY)
}

export function getAppLang() {
    let lng = localStorage.getItem(APP_LANG);
    lng = (lng) ? lng : null

    if( lng !== null && lng.length > 2 )
    {
        lng = lng.substr(0,2);
    }
    return lng
}

export function getBetweenDate(startDate, endDate) {

    let dates = [],
        currentDate = startDate,
        addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}

export function formattedMonth (mount){
    return mount<10 ? '0'+mount : mount
}

export function getBetweenDateGlobal(startDate, endDate) {
    let dates = getBetweenDate(startDate,endDate);
    let formattedDate = [];

    dates.map( (date) => {
        let temp = date.getFullYear() + "-" + ( formattedMonth(date.getMonth()+1) ) + "-" + date.getDate();
        formattedDate.push(temp)
    });
    return formattedDate;
}

export function getBetweenDateGlobalTest(dates) {
    let formattedDate = [];
    dates.map( date => {
        let temp = date.getFullYear() + "-" + ( formattedMonth(date.getMonth()+1) ) + "-" +formattedMonth( date.getDate());
        formattedDate.push(temp);
    });

    return formattedDate;
}

export function dateGlobal(date) {


    let temp = date.getFullYear() + "-" + ( formattedMonth(date.getMonth()+1) ) + "-" +formattedMonth( date.getDate());


    return temp;
}

export function dateGlobalObjet(date) {


    let temp = date[0].getFullYear() + "-" + ( formattedMonth(date[0].getMonth()+1) ) + "-" +formattedMonth( date[0].getDate());


    return temp;
}


const isSmoothScrollSupported = ((document || {}).documentElement || {}).style
    ? 'scrollBehavior' in document.documentElement.style
    : false;

export const toTop = () => {
    if (isSmoothScrollSupported) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, 0);
    }
};

export const to = (ycoordinate) => {
    if (isSmoothScrollSupported) {
        window.scroll({
            top: ycoordinate,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, ycoordinate);
    }
};

export const toElement = (element) => {
    if (element) {
        if (isSmoothScrollSupported) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        } else {
            element.scrollIntoView();
        }
    }
};

export default {
    toTop,
    to,
    toElement
};

export function fillOnUndefinedObject(data, key) {

    if(!isObject(data)){
        return null
    }

    let tmpData = [];
    for (const dataKey of Object.keys(data)) {
        if(dataKey === key){
            tmpData = data[dataKey]
            return tmpData;
        }
    }
}

export function getFactByCategoryName(data, factId) {

    let tmp = [];

    if(!isObject(data)){
        return [];
    }

    for (const dataKey of Object.keys(data)) {

        if(dataKey !== factId){continue}

        for( const dataSubKey of Object.keys( data[dataKey].fact_subcategory ) ){
            tmp.push( { id: dataSubKey, name: data[dataKey].fact_subcategory[dataSubKey].language_key, priority: data[dataKey].fact_subcategory[dataSubKey].priority } )
        }
    }

    return tmp;
}

export function getSelectedItem( data , factId ){

    let tmp = [];

    if(!isObject(data)){
        return [];
    }

    for (const dataKey of Object.keys(data)) {

        if(dataKey !== factId){continue}

        for( const dataSubKey of Object.keys( data[dataKey].fact_subcategory ) ){

            data[dataKey].fact_subcategory[dataSubKey].fact.filter( (fact) => fact.is_selected === true  ).map(fact => tmp = [...tmp,fact.id] )
        }
    }

    return tmp;
}

const isObject = (val) =>  {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

export function viewTypeReturn(data,selectedId) {
    let values = [];
    if(selectedId.length >0 ){
        values = data.filter(item => {
            if(selectedId.includes(item.id) ){
                return item
            }
        })
    }

    return values;
}

export function getDayHours(){
    let quarterHours = ["00", "15", "30", "45"];
    let times = [];
    for(let i = 0; i < 24; i++){
        for(let j = 0; j < 4; j++){
            let time = i + ":" + quarterHours[j];
            if(i < 10){
                time = "0" + time;
            }
            times.push(time);
        }
    }
    return times;
}

export function getYears ( startYear, endYear  ){

    let sYear = startYear;
    let eYear = endYear;
    let allYear = [];

    for(let i=sYear; i >= eYear; i--){
        allYear.push(i);
    }

    return allYear;

}

export function bedGroupCoundCreateData(roomBedGroupData) {

    let defaultData = [ [{bed_type_id: ""}] ];

    let tmpBedGroup = [];

    if( Array.isArray(roomBedGroupData) && roomBedGroupData.length > 0 )
    {
        for(let i = 0; i < roomBedGroupData.length; i++){
            let last = [];
            for(let j = 0; j < roomBedGroupData[i].length; j++){

                let count = roomBedGroupData[i][j]['count'];

                for (let k = 0; k < count; k++){
                    last.push( { bed_type_id: roomBedGroupData[i][j].bed_type_id}  )
                }
            }
            tmpBedGroup.push(last);
        }
    }else{
        tmpBedGroup = defaultData;
    }

    return tmpBedGroup
}

export function roomOccupancyCodeCreate(maxAdults,maxChildren,maxRoomOccupancy) {
    if( ( maxAdults === null || maxAdults === 0 ) ){
        return [];
    }

    /**
     *  A = adult
     *  C = child
     */
    let adultCode = 'A';
    let childCode = 'C';

    let adultIcons = '<i class="room-type-icon fas fa-male" />';
    let childIcons = '<i class="room-type-icon fas fa-child" />';


    let allCode = [];
    for (let i = 1; i <= maxAdults; i++) {

        for (let j = 0; j <= maxChildren; j++) {
            let code = '';
            let appendIcon = '';
            if ((i + j) > maxRoomOccupancy) continue;

            for (let k = 0; k < i; k++) code +=  adultCode;
            for (let l = 0; l < j; l++) code +=  childCode;

            for (let k = 0; k < i; k++) appendIcon += '<div>'+ adultIcons+ '</div>';
            for (let l = 0; l < j; l++) appendIcon += '<div>'+ childIcons +'</div>';

            allCode.push({code:code,icon:appendIcon})
        }
    }

    return allCode
}

export function constructListOfIntervals(start, end, interval) {
    let intervals = [];

    //TODO : Tarih kontrol etme fonskiyonu yeniden düzenlecektir.
    /*let startDateStatus = dateIsValidCheck(start)
    let endDateStatus = dateIsValidCheck(end);*/
    let startDateStatus = true;
    let endDateStatus = true;


    try {
        //if( !moment(startDate).isValid() || !moment(endDate).isValid() ) throw "invalid date";
        if( !startDateStatus || !endDateStatus ) throw "invalid date";
        let intervals = [];
        const diffUnitOfTime =  `${interval}s`;
        while (moment(end).diff(start, diffUnitOfTime) >= 0) {
            intervals.push(start);
            const currentEnd = moment(moment(start).add(1, diffUnitOfTime)).format('YYYY-MM-DD');
            //Object.assign(intervals, { [start]: currentEnd });
            start = currentEnd;
        }
        return intervals;
    }
    catch(err) {
        intervals = err
    }
    return intervals;
}
export function addDateAndReturnRange(start, numberOfDays, interval) {
    const diffUnitOfTime =  `${interval}s`;
    const currentEnd = moment( moment(start).add( parseInt(numberOfDays), diffUnitOfTime) ).format('YYYY-MM-DD');
    let intervals = constructListOfIntervals(start, currentEnd, interval);
    return intervals;
}

export function subtractDateAndReturnRange(endDate, numberOfDays, interval) {

    const diffUnitOfTime =  `${interval}s`;
    const currenStart = moment( moment(endDate).subtract( parseInt(numberOfDays), diffUnitOfTime) ).format('YYYY-MM-DD');

    let intervals = constructListOfIntervals(currenStart, endDate, interval);
    return intervals;
}

export function dateIsValidCheck(date) {
    if(moment(date, ["YYYY-MM-DD"], true).isValid())
    {
        if(moment(date, ["YYYY-MM-DD"]).isSameOrAfter(moment("1-1-0000"), ["YYYY-MM-DD"]) )
            return true ;
        else
            return false;
    }else
    {
        return false;
    }
}
export function getNextDates(start, numberOfDays, allDays, interval) {
    const diffUnitOfTime = `${interval}s`;
    let tempDays = allDays;
    let combineDate = [];

    const currentEnd = moment(
        moment(start).add(parseInt(numberOfDays), diffUnitOfTime)
    ).format("YYYY-MM-DD");

    let intervals = constructListOfIntervals(start, currentEnd, interval);
    tempDays = tempDays.slice(7, tempDays.length - 1);

    combineDate = [...tempDays, ...intervals];
    return combineDate;
}

export function getPreviousDate(start, numberOfDays, allDays, interval) {
    const diffUnitOfTime = `${interval}s`;
    let tempDays = allDays;
    let combineDate = [];

    const currenStart = moment(
        moment(start).subtract(parseInt(numberOfDays), diffUnitOfTime)
    ).format("YYYY-MM-DD");

    let intervals = constructListOfIntervals(currenStart, start, interval);
    tempDays = tempDays.slice(1, numberOfDays + 1);

    combineDate = [...intervals, ...tempDays];
    return combineDate;
}

export function convertAccentedCharacters(str){
    var conversions = new Object();
    conversions['ae'] = 'ä|æ|ǽ';
    conversions['oe'] = 'ö|œ';
    conversions['ue'] = 'ü';
    conversions['Ae'] = 'Ä';
    conversions['Ue'] = 'Ü';
    conversions['Oe'] = 'Ö';
    conversions['A'] = 'À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ';
    conversions['a'] = 'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª';
    conversions['C'] = 'Ç|Ć|Ĉ|Ċ|Č';
    conversions['c'] = 'ç|ć|ĉ|ċ|č';
    conversions['D'] = 'Ð|Ď|Đ';
    conversions['d'] = 'ð|ď|đ';
    conversions['E'] = 'È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě';
    conversions['e'] = 'è|é|ê|ë|ē|ĕ|ė|ę|ě';
    conversions['G'] = 'Ĝ|Ğ|Ġ|Ģ';
    conversions['g'] = 'ĝ|ğ|ġ|ģ';
    conversions['H'] = 'Ĥ|Ħ';
    conversions['h'] = 'ĥ|ħ';
    conversions['I'] = 'Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ';
    conversions['i'] = 'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı';
    conversions['J'] = 'Ĵ';
    conversions['j'] = 'ĵ';
    conversions['K'] = 'Ķ';
    conversions['k'] = 'ķ';
    conversions['L'] = 'Ĺ|Ļ|Ľ|Ŀ|Ł';
    conversions['l'] = 'ĺ|ļ|ľ|ŀ|ł';
    conversions['N'] = 'Ñ|Ń|Ņ|Ň';
    conversions['n'] = 'ñ|ń|ņ|ň|ŉ';
    conversions['O'] = 'Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ';
    conversions['o'] = 'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º';
    conversions['R'] = 'Ŕ|Ŗ|Ř';
    conversions['r'] = 'ŕ|ŗ|ř';
    conversions['S'] = 'Ś|Ŝ|Ş|Š';
    conversions['s'] = 'ś|ŝ|ş|š|ſ';
    conversions['T'] = 'Ţ|Ť|Ŧ';
    conversions['t'] = 'ţ|ť|ŧ';
    conversions['U'] = 'Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ';
    conversions['u'] = 'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ';
    conversions['Y'] = 'Ý|Ÿ|Ŷ';
    conversions['y'] = 'ý|ÿ|ŷ';
    conversions['W'] = 'Ŵ';
    conversions['w'] = 'ŵ';
    conversions['Z'] = 'Ź|Ż|Ž';
    conversions['z'] = 'ź|ż|ž';
    conversions['AE'] = 'Æ|Ǽ';
    conversions['ss'] = 'ß';
    conversions['IJ'] = 'Ĳ';
    conversions['ij'] = 'ĳ';
    conversions['OE'] = 'Œ';
    conversions['f'] = 'ƒ';
    for(var i in conversions){
        var re = new RegExp(conversions[i],"g");
        str = str.replace(re,i);
    }
    return str;
}

export function getChildAges(){
    let ages = [];
    for(let i = 1; i < 22; i++){
        let time = i + "+";
        ages.push(time);
    }
    return ages;
}
export function getRoomChildAges(){
    let ages = [];
    for(let i = 1; i < 19; i++){
        let age = i;
        ages.push(String(age));
    }
    return (ages);
}

export function setPropertyName(propertyName){
    setLocalStorage(PROPERTY_NAME, propertyName)
}

export function getPropertyName(){
    return localStorage.getItem(PROPERTY_NAME);
}

export function setInventoryDate(startDate, endDate){
    setLocalStorage(INVENTORY_START_DATE, startDate)
    setLocalStorage(INVENTORY_END_DATE, endDate)
}
