import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Hidden, MenuItem} from "@material-ui/core";
import {ValidatorForm, SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {useDispatch} from "react-redux";
import {
    getReservationExcel, getReservationExcelClear,
    getReservationList,
    getReservationListFilter
} from "../../../../redux/actions/Network/Reservation/reservationList.action";
import ColAtm from "../../../atomic/Col/Col";
import BlockAtm from "../../../atomic/Block/Block";
import ButtonAtm from "../../../atomic/Button/Button";
import {
    DateRangePicker,
} from "react-dates";
import ReservationListDatatable from "./ReservationListDatatable";

export default function ReservationList(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appLanguage = localStorage.getItem("lng");
    moment.locale(appLanguage)
    let fromRange = '29/03/2020';

    const {reservationList, reservationListFilter, fetching} = props;
    const [channelId , setChannelId] = useState("");
    const [bookingCode , setBookingCode] = useState("");
    const [paymentType , setPaymentType] = useState("");
    const [status , setStatus] = useState("");
    const [dateType , setDateType] = useState("");
    const [quickDateType , setQuickDateType] = useState("");
    const [dateRange , setDateRange] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [postData, setPostData] = useState(false);

    const changeChannel = (e) => {
        setChannelId(e.target.value)
    };

    const changeBookingCode = (e) => {
        setBookingCode(e.target.value)
    };

    const changePaymentType = (e) => {
        setPaymentType(e.target.value)
    };

    const changeStatus = (e) => {
        setStatus(e.target.value)
    };

    const changeDateType = (e) => {
        setDateType(e.target.value)
    };

    const onSubmit = () => {
        let filter;

        filter = {
            channel_id: channelId,
            booking_code: bookingCode,
            payment_type_code: paymentType,
            status: status,
            date_type: dateType,
            date_range: dateRange,
        }


        function clean(obj) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }


        setPostData(true)

        dispatch(getReservationListFilter())
        dispatch(getReservationList(clean(filter)))
    };

    const ReservationExcel = () => {
        let filter;

        filter = {
            channel_id: channelId,
            booking_code: bookingCode,
            payment_type_code: paymentType,
            status: status,
            date_type: dateType,
            date_range: dateRange,
        }


        function clean(obj) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }


        setPostData(true)

        dispatch(getReservationExcel(clean(filter)))
        dispatch(getReservationExcelClear());
    };

    const quickBookings = (e) => {

        setQuickDateType(e.target.value);

        let filter;

        setStartDate(moment());
        setEndDate(moment());
        setDateRange(moment(moment()).format('YY-MM-DD') + " " + moment(moment()).format('YY-MM-DD'))

        if(e.target.value === "todayReservation" ) {
            setDateType("reservation_time");
            filter = {
                date_type: "reservation_time",
                date_range: moment().format('YY-MM-DD') + " " + moment().format('YY-MM-DD'),
            }
        }

        if(e.target.value === "todayCheckin" ) {
            setDateType("checkin_date");
            filter = {
                date_type: "checkin_date",
                date_range: moment().format('YY-MM-DD') + " " + moment().format('YY-MM-DD'),
            }
        }

        if(e.target.value === "todayCheckout" ) {
            setDateType("checkout_date");
            filter = {
                date_type: "checkout_date",
                date_range: moment().format('YY-MM-DD') + " " + moment().format('YY-MM-DD'),
            }
        }

        if(e.target.value === "yesterdayReservation" ) {
            setDateType("reservation_time");
            filter = {
                date_type: "reservation_time",
                date_range: moment().subtract(1, 'd').format('YY-MM-DD') + " " + moment().subtract(1, 'd').format('YY-MM-DD'),
            }
        }

        if(e.target.value === "tomorrowCheckin" ) {
            setDateType("checkin_date");
            filter = {
                date_type: "checkin_date",
                date_range: moment().add(1, 'd').format('YY-MM-DD') + " " + moment().add(1, 'd').format('YY-MM-DD'),
            }
        }

        if(e.target.value === "tomorrowCheckout" ) {
            setDateType("checkout_date");
            filter = {
                date_type: "checkout_date",
                date_range: moment().add(1, 'd').format('YY-MM-DD') + " .add(1, 'd')" + moment().format('YY-MM-DD'),
            }
        }

        function clean(obj) {
            for (const propName in obj) {
                if (obj[propName] === null || obj[propName] === "") {
                    delete obj[propName];
                }
            }
            return obj
        }


        setPostData(true)

        dispatch(getReservationListFilter())
        dispatch(getReservationList(clean(filter)))
    };

    const isOutsideRange = (day => {
        let dayIsBlocked = false;
        if(day > moment(fromRange)) {
            dayIsBlocked = true;
        }
        return dayIsBlocked;
    })

    const clearFilter = () => {
        setPostData(false)
        setChannelId("")
        setBookingCode("")
        setPaymentType("")
        setStatus("")
        setDateType("")
        setQuickDateType("")
        setDateRange("")
        setStartDate(null)
        setEndDate(null)
        setFocusedInput(null)
        dispatch(getReservationListFilter())
        dispatch(getReservationList())
    }

    return (
        <>
            <ValidatorForm onSubmit={onSubmit}  className="reservation-filter-container pos-relative w-100 pt-4 p-24">
                <ColAtm className="justify-end">
                    <ColAtm item xs={12} md={2}  className="justify-end d-flex quick-reservation-select">
                        <SelectValidator
                            onChange={quickBookings}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-reservation-quick-filter') }
                            value={quickDateType}
                        >
                            <MenuItem value="todayReservation">
                                { t('enw-table-toolbar-quick-filter-booking') }
                            </MenuItem>
                            <MenuItem value="todayCheckin">
                                { t('enw-table-toolbar-quick-filter-check-in') }
                            </MenuItem>
                            <MenuItem value="todayCheckout">
                                { t('enw-table-toolbar-quick-filter-check-out') }
                            </MenuItem>
                        </SelectValidator>
                    </ColAtm>
                </ColAtm>
                <ColAtm spacing={3}>

                    <BlockAtm item xs={6} md={2}>
                        <SelectValidator
                            onChange={changeChannel}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-reservation-table-channels') }
                            value={channelId}
                        >
                            <MenuItem value="">{ t('enw-input-select') }</MenuItem>
                            {
                                reservationListFilter?.channel?.map(channel => (
                                    <MenuItem value={channel.id} key={channel.id}>
                                        { t(channel.name) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </BlockAtm>

                    <BlockAtm item xs={6} md={2}>
                        <TextValidator
                            onChange={changeBookingCode}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-reservation-table-reservation_number') }
                            value={bookingCode}
                        />

                    </BlockAtm>

                    <BlockAtm item xs={4} md={2}>
                        <SelectValidator
                            onChange={changeStatus}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-reservation-table-booking_type') }
                            value={status}
                        >
                            <MenuItem value="">{ t('enw-input-select') }</MenuItem>
                            {
                                reservationListFilter?.status?.map(status => (
                                    <MenuItem value={status.id} key={status.id}>
                                        { t(status.language_key) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </BlockAtm>

                    <BlockAtm item xs={4} md={1}>
                        <SelectValidator
                            onChange={changePaymentType}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-reservation-table-payment_type') }
                            value={paymentType}
                        >
                            <MenuItem value="">{ t('enw-input-select') }</MenuItem>
                            {
                                reservationListFilter?.payment_type?.map(payment_type => (
                                    <MenuItem value={payment_type.code} key={payment_type.code}>
                                        { t(payment_type.language_key) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </BlockAtm>

                    <BlockAtm item xs={4} md={2}>
                        <SelectValidator
                            onChange={changeDateType}
                            className="form-control w-100"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-input-reservation_date-type') }
                            value={dateType}
                        >
                            <MenuItem value="">{ t('enw-input-select') }</MenuItem>
                            {
                                reservationListFilter?.date_type?.map(date_type => (
                                    <MenuItem value={date_type.code} key={date_type.code}>
                                        { t(date_type.language_key) }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </BlockAtm>

                    <BlockAtm item xs={12} md={2} className="align-self-end">
                        <Hidden smUp>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    setStartDate(startDate);
                                    setEndDate(endDate);
                                    setDateRange(moment(startDate).format('YY-MM-DD') + " " + moment(endDate).format('YY-MM-DD'))
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={setFocusedInput}
                                isOutsideRange={isOutsideRange}
                                initialVisibleMonth={() => moment().subtract(1, "month")}
                                orientation={"horizontal"}
                                disabled={dateType === ""}
                                numberOfMonths={1}
                                minimumNights={0}
                                startDatePlaceholderText={ t('enw-input-start_date') }
                                endDatePlaceholderText={ t('enw-input-end_date') }
                            />
                        </Hidden>
                        <Hidden xsDown>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    setStartDate(startDate);
                                    setEndDate(endDate);
                                    setDateRange(moment(startDate).format('YY-MM-DD') + " " + moment(endDate).format('YY-MM-DD'))
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={setFocusedInput}
                                isOutsideRange={isOutsideRange}
                                initialVisibleMonth={() => moment().subtract(1, "month")}
                                orientation={"horizontal"}
                                disabled={dateType === ""}
                                minimumNights={0}
                                startDatePlaceholderText={ t('enw-input-start_date') }
                                endDatePlaceholderText={ t('enw-input-end_date') }
                            />
                        </Hidden>
                    </BlockAtm>
                    <BlockAtm item xs={12} md={1} className="align-self-center mt-20">
                        <ButtonAtm
                            type="submit"
                            buttontext={ t('enw-table-toolbar-filters') }
                            className="reservation-filter-btn bg-red"
                            disabled={channelId === "" && bookingCode === "" && paymentType === "" && status === "" && dateType === ""}
                        />
                    </BlockAtm>
                    {
                        postData &&
                        <ButtonAtm className="clear-filter-btn" startIcon={<i className="fas fa-times" />} onClick={clearFilter} />
                    }
                </ColAtm>
            </ValidatorForm>
            {
                !fetching && reservationList &&
                <ReservationListDatatable
                    fetching={fetching}
                    reservationList={reservationList}
                    ReservationExcel={ReservationExcel}
                />
            }
        </>
    )
}

