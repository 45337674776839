import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ColAtm from "../../../../atomic/Col/Col";

const styles = makeStyles({
    body: {
        background: '#ffffff'
    },
    root: {
        margin: 'auto',
        height: "100%"
    },
    restrictInfo: {
        paddingTop: '24px'
    },
});

const BookingForm = (props) => {
    const classes = styles();
    return(
        <ColAtm container className={classes.root} direction="row" md={12}>
            <iframe id="previewFrame" name="previewFrame" className="site-preview-frame" src={props.url} title="be-preview-frame" />
        </ColAtm>
    )
}

export default BookingForm
