import {inventoryConstants} from "../../../../constants/Network/inventory.constants";

const initialState = {
    fetching: false,
    bulkUpdateData:{},
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryConstants.GET_PROPERTY_BULK_UPDATE_PENDING:
            return {
                ...state,
                fetching:true,
                bulkUpdate:{},
                error:{},
            };
        case inventoryConstants.GET_PROPERTY_BULK_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                bulkUpdateData : action.payload
            };
        case inventoryConstants.GET_PROPERTY_BULK_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return {
                ...state
            };
    }
}
