import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import FactCheckBox from "../../../../../molecules/ContentRoomFactContent/ContentRoomFactContent";
import {RoomFactUpdate,RoomFactFeatureUpdate } from "../../../../../../redux/actions/Content/Room/roomFactAction";
import {useDispatch,useSelector} from "react-redux";


let lastSelectFactId = null;
const FactCheckBoxA = ( { fact,roomId } ) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const roomFactUpdateStatus = useSelector( props => props.roomFactUpdateReducer?.roomFactStatus )
    const roomFactFeatureUpdateStatus = useSelector( props => props.roomFactUpdateReducer?.roomFactFeature )
    const [ isSelected , setIsSelected ] = useState(fact.is_selected);
    const [ isFeature , setIsFeature ] = useState(fact.is_feature  === 0 ? false : true  );

    const updateRoomFact = (e) => {
        if (e.target.value === undefined) return 0;
        let param = {};

        if (isSelected){
            param = {
                room_id:roomId,
                property_room_fact: [{
                    id : e.target.value,
                    is_selected: false
                }]
            }
        }else{
            param = {
                room_id:roomId,
                property_room_fact: [{
                    id : e.target.value,
                    is_selected: true
                }]
            }
        }

        dispatch(RoomFactUpdate(param));
        lastSelectFactId = fact.id+"Fact"+roomId;
    }

    const updateRoomFactFeature = (e) => {

        let param = {};

        if (isFeature){
            param = {
                room_id:roomId,
                is_feature:false,
                fact_id:fact.id
            }
        }else{
            param = {
                room_id:roomId,
                is_feature:true,
                fact_id:fact.id
            }
        }

        dispatch( RoomFactFeatureUpdate(param) );
        lastSelectFactId = fact.id+"Feature"+roomId;
    }

    const setStateRoomFactFeature =  () => {
        if (isFeature){
            setIsFeature(false)
        }else{
            setIsFeature(true)
        }
    }

    useEffect( () => {

        if (roomFactUpdateStatus === 200 && lastSelectFactId === fact.id+"Fact"+roomId){
            if (isSelected){
                setIsSelected(false)
                setIsFeature(false)
            }else{
                setIsSelected(true)
            }
        }
        if (roomFactFeatureUpdateStatus === 200 && lastSelectFactId === fact.id+"Feature"+roomId) setStateRoomFactFeature();

    },[roomFactUpdateStatus,roomFactFeatureUpdateStatus])

    return(
        <FactCheckBox
            checkboxId={fact.id+"room"+roomId}
            className="fact-box"
            colClassname="offer-fact-col tablet-pb-20"
            blockClass="offer-fact-border-box fact-border-box featured-fact"
            labelIconClass="fact-icon-label"
            iconClass={`offer-fact-icon fact-icon ${fact.icon}`}
            key={fact.id+"room"+roomId}
            sm={4} md={2}
            value={fact.id}
            tooltip={ t(fact.language_key) }
            checked={isSelected}
            factName={ t(fact.language_key) }
            text={ t(fact.language_key) }
            labelClass="fact-name"
            htmlFor={fact.id+"room"+roomId}
            onClick={ (e) => updateRoomFact(e) }
            isFeature={isFeature}
            handlerIsfeature={ (e) => updateRoomFactFeature(e)}
        />
    )
}

export default FactCheckBoxA;
