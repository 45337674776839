import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    GET_EDIT_CONTENT_WEB
} from "../../../../constants/api.constants";


export function getEditContentWeb(property_web_id) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                property_web_id : property_web_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_EDIT_CONTENT_WEB',
            payload: axios.post(GET_EDIT_CONTENT_WEB, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}
