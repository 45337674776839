import React from 'react';

import ColAtm from "../../../../atomic/Col/Col";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Animated} from "react-animated-css";
import CouponBox from "./CouponBox"
import {connect} from "react-redux";
import {updateFactClearState} from "../../../../../redux/actions/Content/FactList/factListActions";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";



class CouponBoxes extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            checkedStatus: true
        }
    }

    showMessage = () => {

        if(this.props.updateFactMappingReducerError?.response?.status === 400)
        {
            let message = this.props.updateFactMappingReducerError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

    }

    componentWillUnmount() {
        this.props.updateFactClearState();
    }


    render() {
        return (
            <ColAtm>
                {
                    this.props.get_coupons.map( (coupon) => (

                        !this.props.fetching &&
                        <Animated className="fact-col" animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                            <CouponBox
                                coupon={coupon}
                                isSelected={coupon.status}
                                pageId={this.props.pageId}
                            />
                        </Animated>
                    ))
                }
                {
                    this.showMessage()
                }
            </ColAtm>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        updateFactMappingReducerError : props.updateFactMappingReducer?.error,
    }
};

export default compose(withTranslation('common'))(connect(mapStateToProps, { updateFactClearState } )(CouponBoxes));
