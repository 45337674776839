export const placeFact = {
    PLACE_FACT_LIST_PENDING  : 'PLACE_FACT_LIST_PENDING',
    PLACE_FACT_LIST_FULFILLED: 'PLACE_FACT_LIST_FULFILLED',
    PLACE_FACT_LIST_REJECTED : 'PLACE_FACT_LIST_REJECTED',

    PLACE_FACT_UPDATE_PENDING  : 'PLACE_FACT_UPDATE_PENDING',
    PLACE_FACT_UPDATE_FULFILLED: 'PLACE_FACT_UPDATE_FULFILLED',
    PLACE_FACT_UPDATE_REJECTED : 'PLACE_FACT_UPDATE_REJECTED',

    PLACE_FACT_UPDATE_CLEAR : 'PLACE_FACT_UPDATE_CLEAR',
}
