import React from "react";
import PropTypes from "prop-types";

import TextField from '@material-ui/core/TextField';

const InputAtm = props => (
    <TextField
           className={props.className}
           placeholder={props.placeholder}
           onChange={props.onChange}
           onKeyDown={props.onKeyDown}
           onKeyPress={props.onKeyPress}
           onInput={props.onInput}
           min={props.min}
           autoComplete={props.autoComplete}
           id={props.id}
           defaultValue={props.defaultValue}
           name={props.name}
           style={props.style}
           label={props.label}
           type={props.type}
           variant={props.variant}
           rows={props.rows}
           rowsMax={props.rowsMax}
           margin={props.margin}
           title={props.title}
           multiline={props.multiline}
           InputProps={props.InputProps}
           value={props.value}
           InputLabelProps={props.InputLabelProps}
           disabled={props.disabled}
           helperText={props.helperText}
           fullWidth
           required={props.required}
           error={props.error}
           inputRef={props.inputRef}
    />
);
InputAtm.propType = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onInput: PropTypes.func,
    children: PropTypes.string,
    autoComplete: PropTypes.string,
    id: PropTypes.string,
    InputLabelProps: PropTypes.any,
    InputProps: PropTypes.any,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    type: PropTypes.string,
    margin: PropTypes.string,
    variant: PropTypes.string,
    helperText:PropTypes.string,
    error:PropTypes.boolean,
    inputRef:PropTypes.any,
    title:PropTypes.any,
    min:PropTypes.any
};

export default InputAtm;
