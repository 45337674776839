import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";

import {Grid, MenuItem} from "@material-ui/core";
import {getAppLang, getCurrency, getProperty, isDefined} from "../../../../../utils";
import {Alert} from "@material-ui/lab";
import {ValidatorForm, SelectValidator, TextValidator} from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import ColAtm from "../../../../atomic/Col/Col";
import axios from "axios";
import {SYNC_QUICK_PRICING} from "../../../../../constants/api.constants";
import {getQuickPricing} from "../../../../../redux/actions/Network/Inventory/inventoryAction";
import {useDispatch} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CancellationPolicy from "../../Channels/ChanelCancellation/components/CancellationPolicy";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const QuickPricingModal = ({ type, channelId, getData }) =>  {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false); // Open modal
    const [selectedRate, setSelectedRate] = useState(getData);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    const handleClickOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
        setSuccess("");
        setError("");
    };

    const handleChange = (e, index, key) => {
        const {name, value} = e.target;
        const list = getData;

        list[index]["property_room_rate_mapping"][key][name] = value;
        setSelectedRate({list})

    };


    const onSubmit = () => {

        let postRatesMap = selectedRate.list.map(rate => rate.property_room_rate_mapping);

        const postRatesData = postRatesMap.flat();
        const filteredData = postRatesData.filter(item => item.action_type !== null).filter(item => item.price_type !== null).filter(item => item.price_value !== null)


        const data = ({
            params: {
                property_id : getProperty(),
                channel_id : channelId,
                room_rates : filteredData,
            }
        });

        axios.post(SYNC_QUICK_PRICING, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            setSuccess(result.data)
            setIsOpen(false);
            dispatch(getQuickPricing(channelId))
        }).catch(function (error) {
            setError(error.response.data.message)
        });
    };

    return (
        <>
            <ButtonAtm
                className={type !== "edit" ? "filter-action-button" : "cogs-inventory-btn"}
                startIcon={type !== "edit" && <i className="fas fa-tags" />}
                buttontext={type !== "edit" ? t('enw-quick-pricing_modal-title') : <i className="fas fa-cog gray fs-22" /> }
                onClick={handleClickOpen}
            />
            <Dialog onClose={handleClose}
                    fullWidth
                    disableBackdropClick={true}
                    aria-labelledby="rate-plan"
                    open={isOpen}
                    className="quick-price-dialog"
            >
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={handleClose}>
                    {t('enw-quick-pricing_add-modal-title')}
                </DialogTitle>
                <DialogContent>
                    {success && <Alert severity="success">{t('enw-quick-pricing_modal-success-message')}</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}

                    <ValidatorForm onSubmit={onSubmit}>
                        <>
                            {getData.map((room, index) =>

                                <ExpansionPanel className="accordion-room-table" key={room.id} >
                                    <ExpansionPanelSummary
                                        expandIcon={<i className="fas fa-caret-down" />}
                                        aria-label="Expand">
                                        <ColAtm>
                                            <ColAtm md={10} xl={11}>
                                                <i className="fas fa-bed room-rate-bed ml-16" />
                                                <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                            </ColAtm>
                                        </ColAtm>
                                    </ExpansionPanelSummary>
                                    {
                                        room.property_room_rate_mapping.map( ( rate, key ) => (

                                            <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item" key={rate.id}>
                                                <ExpansionPanel className="accordion-rate-table">
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header">
                                                        <ColAtm>
                                                            <ColAtm md={10} xl={11}>
                                                                <i className="fas fa-tags rate-tag-icon" />
                                                                <LabelAtm labelClass="rate-title"  text={rate.name} />
                                                            </ColAtm>
                                                        </ColAtm>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                                        <Grid container spacing={3} className="mobile-no-spacing">
                                                            <Grid item xs={12} md={4}>
                                                                <SelectValidator
                                                                    name="action_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={ t('enw-input-setup_type') }
                                                                    onChange={e => handleChange(e, index, key)}
                                                                    value={rate.action_type}
                                                                    validators={
                                                                        isDefined(selectedRate.list)
                                                                        && (selectedRate.list[index]["property_room_rate_mapping"][key]["action_type"] === null
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["action_type"] === "")
                                                                        && (
                                                                            (selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] !== null
                                                                                && selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] !== '')
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["price_type"] !== null
                                                                        )
                                                                        && ['required']
                                                                    }
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={null}>{ t('enw-input-select') }</MenuItem>
                                                                    <MenuItem value="INC">{ t('enw-input-increase') }</MenuItem>
                                                                    <MenuItem value="DEC">{ t('enw-input-decrease') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <SelectValidator
                                                                    name="price_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={ t('enw-input-value_type') }
                                                                    onChange={e => handleChange(e, index, key)}
                                                                    value={rate.price_type}
                                                                    validators={
                                                                        isDefined(selectedRate.list)
                                                                        && (selectedRate.list[index]["property_room_rate_mapping"][key]["price_type"] === null
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["price_type"] === "")
                                                                        && (
                                                                            (selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] !== null
                                                                                && selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] !== '')
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["action_type"] !== null
                                                                        )
                                                                        && ['required']
                                                                    }
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={null}>{ t('enw-input-select') }</MenuItem>
                                                                    <MenuItem value="PER">{ t('enw-input-percent') }</MenuItem>
                                                                    <MenuItem value="FIX">{ t('enw-input-fixed') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <TextValidator
                                                                    name="price_value"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    label={ t('enw-input-value') }
                                                                    autoComplete="off"
                                                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                                    onChange={e => handleChange(e, index, key)}
                                                                    labelWidth={70}
                                                                    fullWidth
                                                                    value={rate.price_value}
                                                                    validators={
                                                                        isDefined(selectedRate.list)
                                                                        && (selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] === null
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] === "")
                                                                        && (
                                                                            selectedRate.list[index]["property_room_rate_mapping"][key]["price_type"] !== null
                                                                            || selectedRate.list[index]["property_room_rate_mapping"][key]["action_type"] !== null
                                                                        )
                                                                        && ['required']
                                                                    }
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                    InputProps={{
                                                                        endAdornment: <>
                                                                            {
                                                                                isDefined(selectedRate.list) && selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] === "PER" ?
                                                                                    <InputAdornment position="start">
                                                                                        %
                                                                                    </InputAdornment>
                                                                                    :
                                                                                    isDefined(selectedRate.list) && selectedRate.list[index]["property_room_rate_mapping"][key]["price_value"] === "FIX" ?
                                                                                        <InputAdornment position="start">
                                                                                            { getCurrency() }
                                                                                        </InputAdornment>
                                                                                        :
                                                                                        <InputAdornment position="start">

                                                                                        </InputAdornment>
                                                                            }
                                                                        </>
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                    <img className="flip-transform" src="/assets/img/icons/channel-arrow.png" alt="channel-arrow"/>
                                                </ExpansionPanel>
                                            </ExpansionPanelDetails>

                                        ))
                                    }
                                </ExpansionPanel>

                            )}

                            <Grid item xs={12} className="t-center mt-14">
                                <ButtonAtm
                                    type="submit"
                                    className="room-rate-modal-save room-rate-save bg-red m-auto"
                                    buttontext={ t('btn-save') }
                                    disabled={!isDefined(selectedRate.list)}
                                />
                            </Grid>
                        </>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default QuickPricingModal;
