import React, { useEffect, useState } from "react";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";
import InputAtm from "../../../../atomic/Input/Input";
import ColAtm from "../../../../atomic/Col/Col";
import Price from "./Price";
import {useTranslation} from "react-i18next";

export default function Accommodation(props) {
    const { t } = useTranslation();
    const [accommodation, setAccommodation] = useState(props.accommodation);

    const {accommodationIndex} = props;

    useEffect(() => {
        setAccommodation(props.accommodation)
    },[props.accommodation]);

    const onChange = (e,accommodationIndex) => {
        let newValue = e.target.value;
        setAccommodation(prevState => {
            let newAccommodation = { ...prevState, number_of_rooms: newValue };
            props.onChange( accommodationIndex, newAccommodation );
            return newAccommodation;
        });
    };

    const onChangeRate = (item, accommodationIndex) => {

        setAccommodation(prevState => {
            let newItem = { ...prevState, price: item };
            let returNewData = Object.assign([],newItem);
            props.onChange(accommodationIndex, newItem);
            return returNewData;
        });
    }

    return (
        <>
        {
                <ColAtm className="inventory-avail-box">
                        <ColAtm xs={4} >
                            <BlockAtm xs={1}></BlockAtm>
                            <BlockAtm xs={5} className="m-h-auto offer-price-rates">
                                <LabelAtm labelClass="inventory-room-name ml-16" text={t(accommodation.language_key)} />
                            </BlockAtm>
                            <BlockAtm className="channels-box" xs={3}>
                            </BlockAtm>
                            <BlockAtm xs={1}>
                                <BlockAtm className="offer-rooms-input offer-price-border">
                                    <InputAtm
                                        name={"number_of_rooms"}
                                        type="text"
                                        placeholder="-"
                                        value={accommodation.number_of_rooms}
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                        onChange={ (e) => onChange(e, accommodationIndex)}
                                    />
                                </BlockAtm>
                            </BlockAtm>
                        </ColAtm>
                        <ColAtm xs={8} className="pos-relative">
                            <Price  prices={accommodation.price}
                                    onChangeRate={onChangeRate}
                                    allDays={props.allDays}
                                    currency={props.currency}
                                    dateRangeChange={props.dateRangeChange}
                                    accommodationIndex={accommodationIndex}
                            />
                            <p className="offer-price-currency">{props.currency}</p>
                        </ColAtm>
                </ColAtm>

        }
        </>


    );
}
