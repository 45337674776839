import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../atomic/Col/Col";
import UpdateAwardsCertificates from "./updateAwardsCertificates";
import BlockAtm from "../../../../atomic/Block/Block";
import OverFlowText from "../../../../molecules/OverFlowText/OverFlowText";
import {isDefined} from "../../../../../utils";
import AwardsCertificatesStatusBox from "./AwardsCertificatesStatusBox";
import {useTranslation} from "react-i18next";

const AwardsCertificates = (props) => {
    const {
        awardsCertificatesReducers,
        updateAwardsCertificates,
        deleteAwardsCertificates,
        getAwardsCertificates,
        clearErrorMessage} = props;

    const {t} = useTranslation();

    return(
        <Grid>
            <Grid container spacing={3} className="mb-24">
                <Grid item xs={12}>
                    <h2 className="mb-0">{t('enw-awards-title')}</h2>
                </Grid>
                {
                    isDefined(awardsCertificatesReducers) && isDefined(awardsCertificatesReducers.awardsCertificatesList) &&
                    awardsCertificatesReducers.awardsCertificatesList.filter(item => "AWD" === item.category_type).map(awardCertificate => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                            <UpdateAwardsCertificates
                                awardCertificate={awardCertificate}
                                getAwardsCertificates={getAwardsCertificates}
                                updateAwardsCertificates={updateAwardsCertificates}
                                awardsCertificatesReducers={awardsCertificatesReducers}
                                deleteAwardsCertificates={deleteAwardsCertificates}
                                clearErrorMessage={clearErrorMessage}

                            />
                            <BlockAtm className="offer-fact-border-box fact-border-box">
                                <AwardsCertificatesStatusBox
                                    awardCertificate={awardCertificate}
                                    awardsCertificatesReducers={awardsCertificatesReducers}
                                />

                                <label htmlFor={"id-" + awardCertificate.id}>
                                    <label style={{position: 'relative'}}
                                           htmlFor={"id-" + awardCertificate.id}>
                                        <i className={"offer-fact-icon fact-icon fas fa-file-certificate"} />
                                        <OverFlowText>
                                            {awardCertificate.category_name}
                                        </OverFlowText>
                                    </label>
                                </label>
                            </BlockAtm>
                        </ColAtm>
                    ))
                }
            </Grid>
            <Grid container spacing={3} className="mb-24">
                <Grid item xs={12}>
                    <h2 className="mb-0">{t('enw-certificates-title')}</h2>
                </Grid>
                {
                    isDefined(awardsCertificatesReducers) && isDefined(awardsCertificatesReducers.awardsCertificatesList) &&
                    awardsCertificatesReducers.awardsCertificatesList.filter(item => "CRT" === item.category_type).map(awardCertificate => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6}>
                            <UpdateAwardsCertificates
                                awardCertificate={awardCertificate}
                                getAwardsCertificates={getAwardsCertificates}
                                updateAwardsCertificates={updateAwardsCertificates}
                                awardsCertificatesReducers={awardsCertificatesReducers}
                                deleteAwardsCertificates={deleteAwardsCertificates}
                                clearErrorMessage={clearErrorMessage}

                            />
                            <BlockAtm className="offer-fact-border-box fact-border-box">
                                <AwardsCertificatesStatusBox
                                    awardCertificate={awardCertificate}
                                    awardsCertificatesReducers={awardsCertificatesReducers}
                                />

                                <label htmlFor={"id-" + awardCertificate.id}>
                                    <label style={{position: 'relative'}}
                                           htmlFor={"id-" + awardCertificate.id}>
                                        <i className={"offer-fact-icon fact-icon fas fa-file-certificate"}/>
                                        <OverFlowText>
                                            {awardCertificate.category_name}
                                        </OverFlowText>
                                    </label>
                                </label>
                            </BlockAtm>
                        </ColAtm>
                    ))
                }
            </Grid>
            <Grid container spacing={3} className="mb-24">
                <Grid item xs={12}>
                    <h2 className="mb-0">{t('enw-policy-title')}</h2>
                </Grid>
                {
                    isDefined(awardsCertificatesReducers) && isDefined(awardsCertificatesReducers.awardsCertificatesList) &&
                    awardsCertificatesReducers.awardsCertificatesList.filter(item => "PLC" === item.category_type).map(awardCertificate => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6}>
                            <UpdateAwardsCertificates
                                awardCertificate={awardCertificate}
                                getAwardsCertificates={getAwardsCertificates}
                                updateAwardsCertificates={updateAwardsCertificates}
                                awardsCertificatesReducers={awardsCertificatesReducers}
                                deleteAwardsCertificates={deleteAwardsCertificates}
                                clearErrorMessage={clearErrorMessage}

                            />
                            <BlockAtm className="offer-fact-border-box fact-border-box">
                                <AwardsCertificatesStatusBox
                                    awardCertificate={awardCertificate}
                                    awardsCertificatesReducers={awardsCertificatesReducers}
                                />

                                <label htmlFor={"id-" + awardCertificate.id}>
                                    <label style={{position: 'relative'}}
                                           htmlFor={"id-" + awardCertificate.id}>
                                        <i className={"offer-fact-icon fact-icon fas fa-file-certificate"}/>
                                        <OverFlowText>
                                            {awardCertificate.category_name}
                                        </OverFlowText>
                                    </label>
                                </label>
                            </BlockAtm>
                        </ColAtm>
                    ))
                }
            </Grid>
        </Grid>
    )
}
export default AwardsCertificates;
