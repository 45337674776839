import {executiveConstants} from "../../../constants/Content/executive.constants";

const initialState = {
    fetching : false,
    buttonPassiveStatus:false,
    error : {},
    executive_type: [],
    executives: [],
    executivesFirst: [],
    country_code: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case executiveConstants.GET_EXECUTIVE_CONTACT_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus:true,
                error: {},
                executives:[],
                executivesFirst:[],
                country_code:''
            };
        case executiveConstants.GET_EXECUTIVE_CONTACT_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                executive_type: action.payload.executive_type,
                executives: action.payload.executives,
                executivesFirst: action.payload.executives[0],
                country_code: action.payload.country_code,
            };
        case executiveConstants.GET_EXECUTIVE_CONTACT_REJECTED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                error: action.payload
            };

        // UPDATE Contact
        case executiveConstants.UPDATE_EXECUTIVE_CONTACT_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus: true,
                error: {}
            };
        case executiveConstants.UPDATE_EXECUTIVE_CONTACT_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                executive_type: action.payload.executive_type,
                executives: action.payload.executives,
                executivesFirst: action.payload.executives[0],
            };
        case executiveConstants.UPDATE_EXECUTIVE_CONTACT_REJECTED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                error: action.payload,
            };

        // Add Contact
        case executiveConstants.ADD_EXECUTIVE_CONTACT_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus: true,
                error: {}
            };
        case executiveConstants.ADD_EXECUTIVE_CONTACT_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                executive_type: action.payload.executive_type,
                executives: action.payload.executives,
                executivesFirst: action.payload.executives[0],
            };
        case executiveConstants.ADD_EXECUTIVE_CONTACT_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                buttonPassiveStatus: false,
            };

        // DELETE Caontact
        case executiveConstants.DELETE_EXECUTIVE_CONTACT_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus: true,
                error: {}
            };
        case executiveConstants.DELETE_EXECUTIVE_CONTACT_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                executive_type: action.payload.executive_type,
                executives: action.payload.executives,
                executivesFirst: action.payload.executives[0],
            };
        case executiveConstants.DELETE_EXECUTIVE_CONTACT_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                buttonPassiveStatus: false,
            };
            //Clear
        case executiveConstants.CLEAR_EXECUTIVE_CONTACT:
                return {
                    ...initialState
                }
        default:
            return state
    }
}
