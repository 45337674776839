export const getChannelCancellationListConstants = {
    GET_CHANNEL_CANCELLATION_LIST_PENDING  : 'GET_CHANNEL_CANCELLATION_LIST_PENDING',
    GET_CHANNEL_CANCELLATION_LIST_FULFILLED: 'GET_CHANNEL_CANCELLATION_LIST_FULFILLED',
    GET_CHANNEL_CANCELLATION_LIST_REJECTED : 'GET_CHANNEL_CANCELLATION_LIST_REJECTED',
};
export const getChannelCancellationConstants = {
    GET_CHANNEL_CANCELLATION_PENDING  : 'GET_CHANNEL_CANCELLATION_PENDING',
    GET_CHANNEL_CANCELLATION_FULFILLED: 'GET_CHANNEL_CANCELLATION_FULFILLED',
    GET_CHANNEL_CANCELLATION_REJECTED : 'GET_CHANNEL_CANCELLATION_REJECTED',
};
export const createChannelCancellationConstants = {
    CREATE_CHANNEL_CANCELLATION_PENDING  : 'CREATE_CHANNEL_CANCELLATION_PENDING',
    CREATE_CHANNEL_CANCELLATION_FULFILLED: 'CREATE_CHANNEL_CANCELLATION_FULFILLED',
    CREATE_CHANNEL_CANCELLATION_REJECTED : 'CREATE_CHANNEL_CANCELLATION_REJECTED',
};

export const updateChannelCancellationConstants = {
    UPDATE_CHANNEL_CANCELLATION_POLICY_PENDING  : 'UPDATE_CHANNEL_CANCELLATION_POLICY_PENDING',
    UPDATE_CHANNEL_CANCELLATION_POLICY_FULFILLED: 'UPDATE_CHANNEL_CANCELLATION_POLICY_FULFILLED',
    UPDATE_CHANNEL_CANCELLATION_POLICY_REJECTED : 'UPDATE_CHANNEL_CANCELLATION_POLICY_REJECTED',
};

export const getChannelPaymentConstants = {
    GET_CHANNEL_PAYMENT_PENDING  : 'GET_CHANNEL_PAYMENT_PENDING',
    GET_CHANNEL_PAYMENT_FULFILLED: 'GET_CHANNEL_PAYMENT_FULFILLED',
    GET_CHANNEL_PAYMENT_REJECTED : 'GET_CHANNEL_PAYMENT_REJECTED',
};

export const getChannelPaymentCancellationConstants = {
    GET_CHANNEL_CANCELLATION_PENDING  : 'GET_CHANNEL_CANCELLATION_PENDING',
    GET_CHANNEL_CANCELLATION_FULFILLED: 'GET_CHANNEL_CANCELLATION_FULFILLED',
};

export const addChannelPaymentConstants = {
    ADD_CHANNEL_PAYMENT_PENDING  : 'ADD_CHANNEL_PAYMENT_PENDING',
    ADD_CHANNEL_PAYMENT_FULFILLED: 'ADD_CHANNEL_PAYMENT_FULFILLED',
    ADD_CHANNEL_PAYMENT_REJECTED : 'ADD_CHANNEL_PAYMENT_REJECTED',
};

export const updateChannelPolicyConstants = {
    UPDATE_CANCELLATION_POLICY_PENDING  : 'UPDATE_CANCELLATION_POLICY_PENDING',
    UPDATE_CANCELLATION_POLICY_FULFILLED: 'UPDATE_CANCELLATION_POLICY_FULFILLED',
    UPDATE_CANCELLATION_POLICY_REJECTED : 'UPDATE_CANCELLATION_POLICY_REJECTED',
};
