import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import {  ROOM_PHOTO_LIST, ROOM_PHOTO_UPDATE} from "../../../../constants/api.constants";

export function RoomPhotoListGet(roomId) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : roomId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_PHOTO_LIST',
            payload: axios.post(ROOM_PHOTO_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function RoomPhotoUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : param.room_id,
                "property_room_fact": param.property_room_fact
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_PHOTO_UPDATE',
            payload: axios.post(ROOM_PHOTO_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

