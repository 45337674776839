export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_PENDING  : 'LOGIN_PENDING',
    LOGIN_FULFILLED: 'LOGIN_FULFILLED',
    LOGIN_REJECTED : 'LOGIN_REJECTED',

    LOGOUT_PENDING  : 'LOGOUT_PENDING',
    LOGOUT_FULFILLED: 'LOGOUT_FULFILLED',
    LOGOUT_REJECTED : 'LOGOUT_REJECTED',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    REGISTER_PENDING  : 'REGISTER_PENDING',
    REGISTER_FULFILLED: 'REGISTER_FULFILLED',
    REGISTER_REJECTED : 'REGISTER_REJECTED',

    GET_PROFILE_PENDING  : 'GET_PROFILE_PENDING',
    GET_PROFILE_FULFILLED: 'GET_PROFILE_FULFILLED',
    GET_PROFILE_REJECTED : 'GET_PROFILE_REJECTED',

    UPDATE_PROFILE_PENDING  : 'UPDATE_PROFILE_PENDING',
    UPDATE_PROFILE_FULFILLED: 'UPDATE_PROFILE_FULFILLED',
    UPDATE_PROFILE_REJECTED : 'UPDATE_PROFILE_REJECTED',

    UPDATE_PASSWORD_PENDING  : 'UPDATE_PASSWORD_PENDING',
    UPDATE_PASSWORD_FULFILLED: 'UPDATE_PASSWORD_FULFILLED',
    UPDATE_PASSWORD_REJECTED : 'UPDATE_PASSWORD_REJECTED',

    FORGOT_PASSWORD_PENDING  : 'FORGOT_PASSWORD_PENDING',
    FORGOT_PASSWORD_FULFILLED: 'FORGOT_PASSWORD_FULFILLED',
    FORGOT_PASSWORD_REJECTED : 'FORGOT_PASSWORD_REJECTED',

    RESET_PASSWORD_PENDING  : 'RESET_PASSWORD_PENDING',
    RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',
    RESET_PASSWORD_REJECTED : 'RESET_PASSWORD_REJECTED',
};