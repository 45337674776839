import {getAppLang, getCurrency, getProperty, getUserToken} from "../../../../utils";
import axios from "axios";
import {CPA_DAILY_PRICE, GET_CPA_COMPETITOR, SYNC_CPA_COMPETITOR} from "../../../../constants/api.constants";

export function getPropertyCpa(){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CPA_COMPETITOR',
            payload: axios.post(GET_CPA_COMPETITOR, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getCpaDailyPrice(date, key){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            date: date,
            competitor_property_key: key,
            currency: getCurrency()
        }
    };

    return dispatch => {
        dispatch({
            type: 'CPA_DAILY_PRICE',
            payload: axios.post(CPA_DAILY_PRICE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function setSyncCpaCompetitor (data){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                competitor: data,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'SYNC_CPA_COMPETITOR',
            payload: axios.post(SYNC_CPA_COMPETITOR, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function setSyncCpaCompetitorClearState() {
    return dispatch => {
        dispatch({
            type: 'SYNC_CPA_COMPETITOR_CLEAR_DATA',
        })
    }
}

export function getCpaClearState() {
    return dispatch => {
        dispatch({
            type: 'GET_CPA_COMPETITOR_CLEAR_DATA',
        })
    }
}