import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField} from "@material-ui/core";
import ColAtm from "../../../../atomic/Col/Col";
import ButtonAtm from "../../../../atomic/Button/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import {convertAccentedCharacters, isDefined} from "../../../../../utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useDispatch} from "react-redux";
import {editRateGetClear, getEditRatePlan} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {Alert, Skeleton} from "@material-ui/lab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const EditRatePlanTabs = (props) =>   {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const {accommodationTypes, roomRates, editRateData, open, editRateFetching} = props;
    const [selectedRooms, setSelectedRooms] = useState();

    const [value, setValue] = React.useState('one');
    const [isDisabled, setIsDisabled] = React.useState(true);


    const [state, setState] = React.useState({
        name: "",
        rateType:"",
        rateTypeValue:"",
        defaultRateValue: "",
        accommodationType:"",
        accommodationTypeValue:"",
        facts:[],
        rooms: [],
        id: ""
    });

    const handleInputChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const selectedAccommodationType = (accommodationType) => {
        if(accommodationType !== null){
            if(accommodationType.hasOwnProperty('id')){
                setState({ ...state, accommodationType: accommodationType.id } );
            }
        } else {
            setState({...state, accommodationType: ""})
        }
    };

    const selectedRateType = (rateType) => {
        if(rateType !== null){
            if(rateType.hasOwnProperty('id')){
                setState({ ...state, rateType: rateType.id,  rateTypeValue: rateType } );
                dispatch(getEditRatePlan(rateType.id));
            }
        } else {
            setState({...state, rateType: "", rateTypeValue: ""})
        }
    };

    const onRoomRateSave = (e) => {
        props.onRatePlanUpdate(state)
    };

    const handleChangeRoomValue = (e, i) => {

        if(e.target.checked) {
            let temp_room_mapping = editRateData.rooms;
            temp_room_mapping[i]['room_id'] = Object.keys(editRateData.rooms) !== 0 && editRateData.rooms[i] ? editRateData.rooms[i]['id'] : '';
            temp_room_mapping[i]['is_selected'] = Object.keys(editRateData.rooms) !== 0 && editRateData.rooms[i] ? true : false;
            setSelectedRooms({temp_room_mapping})

        } else {
            let temp_room_mapping = editRateData.rooms;
            temp_room_mapping[i]['is_selected'] = false;
            temp_room_mapping[i]['value'] = "";
            setSelectedRooms({temp_room_mapping})
        }
    }

    const handleOccupancyChange = (e, index) => {
        const {name, value} = e.target;
        const validated = (value).match(/^(\d*\.{0,1}\d{0,2}$)/)
        const list = editRateData.rooms;
        if (validated) {
            list[index][name] = value;
            setSelectedRooms({list})
        }
    };

    useEffect( () => {

        let tempData = [];

        if (isDefined(editRateData) && isDefined(editRateData.rooms) && editRateData.rooms.length > 0) {
            editRateData.rooms.filter( (room) => room.is_selected === true ).map(room_rate => tempData = [...tempData, {room_id: room_rate.id, value: room_rate.included_occupancy}] )
            setState({ ...state, rooms: tempData } );
        }

        if (isDefined(state.rateTypeValue) && isDefined(state.accommodationType) && isDefined(state.defaultRateValue[0]) && isDefined(state.name)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }

    },[editRateData.rooms, selectedRooms, state.rateTypeValue, state.name, state.defaultRateValue, state.accommodationType] )

    useEffect(() => {

        let selectedAccommodation = '';

        if (isDefined(editRateData)) {
            selectedAccommodation = accommodationTypes.filter( type => type.id === editRateData.accommodation_type);
            setState({ ...state, defaultRateValue: selectedAccommodation, name: editRateData.name, id: editRateData.id, accommodationType: editRateData.accommodation_type } );
        }

    },[editRateData, accommodationTypes] )


    useEffect(() => {
        if (!open) {
            dispatch(editRateGetClear());
        }
    },[open] )


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ColAtm className={classes.root}>
            <ValidatorForm onSubmit={onRoomRateSave} onError={errors => console.log(errors)} className="w-100">
                <div className={classes.root}>
                    <AppBar className="room-rate-tab-title" position="static">
                        <Tabs className="room-rate-tabFont" value={value} onChange={handleChange} aria-label="rate-edit-tabs">
                            <Tab
                                value="one"
                                label={ t('enw-room_and_rate-add_rate_rate-options-title') }
                                wrapped
                                {...a11yProps('one')}
                            />
                            <Tab value="two" label={ t('enw-room_and_rate-add_rate_room-options-title') } {...a11yProps('two')} disabled={isDisabled} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index="one">
                        <ColAtm md={12}>
                            { props.showNotification() }
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="roomRate"
                                        options={roomRates}
                                        getOptionLabel={option => option.name}
                                        defaultValue={state.rateTypeValue}
                                        filterSelectedOptions
                                        onChange={ (options, value) => {selectedRateType(value)} }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={ t('enw-input-accommodation_types') }
                                                placeholder={ t('enw-input-accommodation_types') }
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            {
                                editRateFetching &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Skeleton width="100%" height={80} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Skeleton width="100%" height={80} />
                                    </Grid>
                                </Grid>

                            }
                            {
                                isDefined(editRateData) && state.defaultRateValue.length > 0 &&
                                <Grid container spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            id="accommodationTypes"
                                            options={accommodationTypes.sort((a, b) => convertAccentedCharacters(t(a.language_key)) > convertAccentedCharacters(t(b.language_key)) ? 1:-1)}
                                            getOptionLabel={option => t(option.language_key)}
                                            defaultValue={state.defaultRateValue[0]}
                                            filterSelectedOptions
                                            onChange={ (options, value) => {selectedAccommodationType(value)} }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label={ t('enw-input-accommodation_types') }
                                                    placeholder={ t('enw-input-accommodation_types') }
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="name"
                                            type="text"
                                            variant="outlined"
                                            label={t('enw-input-new_rate_name')}
                                            placeholder={ t('enw-input-new_rate_name')}
                                            autoComplete="off"
                                            fullWidth
                                            value={state.name}
                                            onChange={handleInputChange('name')}
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Tabs className="rate-tab-button" value={value} onChange={handleChange} aria-label="rate-edit-tabs">
                                <Tab className="m-auto room-rate-modal-save bg-red rate-tab-next-btn" value="two" label={ t('btn-next') } {...a11yProps('two')} disabled={isDisabled} />
                            </Tabs>
                        </ColAtm>
                    </TabPanel>
                    <TabPanel value={value} index="two">
                        <Grid container className="bulk-update-rates" spacing={3}>
                            { props.showNotification() }

                            {
                                isDefined(editRateData) && isDefined(editRateData.rooms) && editRateData.rooms.length > 0 &&
                                editRateData.rooms.some( (room) => room.is_selected_lock === true ) &&
                                <Alert className="w-100" severity="info">{ t('enw-edit-rate_disabled-rate-info') }</Alert>
                            }

                            {
                                isDefined(editRateData) && isDefined(editRateData.rooms) && editRateData.rooms.length > 0 &&
                                editRateData.rooms.map( (room, key) => (
                                    <Grid container className="bulk-update-room-name" style={{padding: "6px 12px 6px 0px", marginTop: 12}}>
                                        <Grid item xs={8} className="align-self-center">
                                            {
                                                room.is_selected_lock ?
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            color="primary"
                                                            value={room.id}
                                                            checkedIcon={<i className="fas fa-lock-alt mr-4 fs-20" />}
                                                            name="roomName"
                                                            checked={room.is_selected}
                                                            disabled={true}
                                                        />
                                                        }
                                                        label={ room.name }
                                                        labelPlacement="left"
                                                        className="desc-text bulk-update-room-icon"
                                                    />
                                                    :
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                value={room.id}
                                                                name="roomName"
                                                                checked={room.is_selected}
                                                            />
                                                        }
                                                        label={ room.name }
                                                        labelPlacement="left"
                                                        className="desc-text bulk-update-room-icon"
                                                        onClick={(e) => { handleChangeRoomValue(e, key) }}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                room.is_selected ?
                                                    <TextValidator
                                                        label={t('enw-input-included_occupancy')}
                                                        name="included_occupancy"
                                                        type="text"
                                                        fullWidth
                                                        className="mt-0"
                                                        autoComplete="off"
                                                        size="small"
                                                        onChange={e => handleOccupancyChange(e, key)}
                                                        value={room.included_occupancy}
                                                        variant="outlined"
                                                        validators={['required']}
                                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                                    />
                                                    :
                                                    <TextField
                                                        label={t('enw-input-included_occupancy')}
                                                        fullWidth
                                                        className="mt-0"
                                                        size="small"
                                                        variant="outlined"
                                                        disabled={!room.is_selected}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {
                                                                        !room.is_selected &&
                                                                        <i className="fas fa-lock-alt" />
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                            }

                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <ColAtm className="mt-32" md={12}>
                            <ButtonAtm
                                className="m-auto room-rate-modal-save bg-red"
                                buttontext={ t('btn-save') }
                                type="submit"
                                autoFocus
                                color="primary"
                            />
                        </ColAtm>
                    </TabPanel>
                </div>


            </ValidatorForm>
        </ColAtm>
    );
}

export default EditRatePlanTabs;
