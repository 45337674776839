import React, {useEffect} from 'react';
import {getUserName} from "../../../utils";
import {getProperty} from "../../../utils";
import {useTranslation} from "react-i18next";

 const PrimarySearchAppBar = () =>  {

     const userName = getUserName();
     const propertyId = getProperty();
     const { t } = useTranslation();

    useEffect( () => {
    },[])

    return (
        <>
            <p className="user-menu-name"><span>{ t('enw-drawer_menu-title') }</span> {userName}</p>
            <p className="user-menu-name"><span>{ t('enw-drawer_menu-property_id') }</span> {propertyId}</p>
        </>

    );
}

export default PrimarySearchAppBar
