import React from "react";
import PropTypes from "prop-types";
import {Grid} from "@material-ui/core";


const BlockAtm = props => (
    <Grid className={props.className}
          item
         onClick={props.onClick}
         style={props.style}
         span={props.span}
         xs={props.xs}
         sm={props.sm}
         md={props.md}
         lg={props.lg}
         xl={props.xl}
         justify={props.justify}
         direction={props.direction}
         alignItems={props.alignItems}
         order={props.order}
    >
        {props.children}
    </Grid>
);

BlockAtm.propType = {
    className: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.string,
    onClick: PropTypes.func,
    xs: PropTypes.any,
    sm: PropTypes.any,
    md: PropTypes.any,
    lg: PropTypes.any,
    xl: PropTypes.any,
    justify: PropTypes.any,
    direction: PropTypes.any,
    alignItems: PropTypes.string,
    order: PropTypes.number,
};

export default BlockAtm;
