import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {Grid, MenuItem} from "@material-ui/core";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import ButtonAtm from "../../../../../atomic/Button/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BulkUpdateDatTime from "./BulkUpdateDateTime";
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from "react-redux";
import Rates from "./Rates";
import moment from "moment";
import {SelectValidator, ValidatorForm} from "react-material-ui-form-validator";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import SuccessBulkUpdateModal from "../../../../../organisms/Modal/SuccessModal/SuccessBulkUpdateModal";
import {
    propertyGroupBulkUpdate,
    propertyGroupBulkUpdateClear
} from "../../../../../../redux/actions/Network/GroupInventory/BulkUpdateAction";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


const BulkUpdateTabs = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const bulkUpdateRoomRateReducer = useSelector(props => props.groupInventoryReducer?.inventory?.rooms);
    const bulkUpdateRoomRate = Object.values(bulkUpdateRoomRateReducer);
    const bulkUpdateData = useSelector(props => props.bulkUpdateOptionsReducer?.bulkUpdate);
    const bulkUpdateError = useSelector(props => props.bulkUpdateOptionsReducer?.error)
    const bulkUpdateOptions = [
        {
            "name": "Room Availability",
            "language_key": "property_availability_type-room_availability",
            "type": "rate",
            "availability_type_id": 1
        },
        {
            "name": "Room Availability",
            "language_key": "property_availability_type-room_availability",
            "type": "rate_stop_sell",
            "availability_type_id": 1
        },
        {
            "name": "Room Availability",
            "language_key": "property_availability_type-room_availability",
            "type": "min_stay",
            "availability_type_id": 1
        }
    ];

    const dispatch = useDispatch();

    const [state, setState] = React.useState({
        update_type: "",
        availability_type_id: "",
        value : "",
        start_date:moment(new Date()).format("YYYY-MM-DD"),
        end_date:moment(new Date()). add(1,'days').format("YYYY-MM-DD")
    });
    const [roomRates, setRoomRates] = React.useState([]);
    const [options, setOptions] = React.useState('');
    const [includeDays, setIncludeDays] = useState([]);

    const handleChangeOptions = (e)=> {
        if ( e.target.value === undefined ) return 0
        let selectedOption = bulkUpdateOptions.find( (options, index) => index === e.target.value )
        let update_type = selectedOption?.type;
        let availability_type_id = selectedOption?.availability_type_id;

        setOptions(e.target.value)
        setState({ ...state, update_type, availability_type_id, value:""});
        setRoomRates([]);
    };

    const handleChangeDate = (  startDate, endDate  ) => {
        if(endDate == null){
            setState({ ...state, start_date:startDate});
        }else{
            setState({ ...state, end_date:endDate});
        }

    }

    const setChangeRoomRate = (param) => {
        setRoomRates(param)
    }

    const onClickIncludeDay = (e) => {
        if (e.target.value === undefined) return 0;

        if( includeDays.includes(e.target.value) ){
            let temp = includeDays;
            let index = temp.indexOf(e.target.value);
            temp.splice(index, 1);
            setIncludeDays(temp);
        }else{
            setIncludeDays([ ...includeDays, e.target.value])
        }
    }

    const onSubmit = () => {

        /*let resultData = roomRates.map(function(item) {
            delete item.name;
            return item;
        });*/

        let params = {
            update_type: state.update_type,
            start_date: state.start_date,
            end_date: state.end_date,
            availability_type_id : state.availability_type_id,
            channel_group_id : props.channelId,
            room_rates: roomRates,
            include_days: includeDays
        }

        dispatch(propertyGroupBulkUpdate(params))
    }

    const showMessage = () => {

        if( bulkUpdateData && bulkUpdateData.status === 200 ){
            return <SuccessBulkUpdateModal status={true} message={ t('enw-notification_saved_successfully') } runFunction={props.handleClose} />
        }

        if(bulkUpdateError?.response?.status === 400 || bulkUpdateError?.response?.status === 500) {
            let message = bulkUpdateError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    useEffect( () => {},[bulkUpdateData,bulkUpdateError]);

    useEffect(() => {
        setIncludeDays(["Mon","Tue","Wed","Thu","Fri","Sat","Sun"])

        return () => {
            dispatch(propertyGroupBulkUpdateClear());

        };
    }, []);

    return (
        <ColAtm className={classes.root}>
            <ValidatorForm onSubmit={onSubmit} onError={errors => console.log(errors)}>
                <Grid container className="select-room-types" spacing={1}>
                    <AppBar className="room-rate-tab-title" position="static">
                        <ColAtm className="room-rate-tabFont" value="0">
                            <LabelAtm labelClass="rate-plan-title" text={ t('inventory-bulk_update_details') } />
                        </ColAtm>
                    </AppBar>
                    <Grid item xs={12} md={6} className="form-group">
                        <SelectValidator
                            value={options}
                            onChange={handleChangeOptions}
                            className="form-control"
                            variant="outlined"
                            fullWidth
                            label={ t('enw-input-select') }
                            validators={['required']}
                            errorMessages={[ t("enw-notification_this_is_required") ]}
                        >
                            {
                                bulkUpdateOptions.map( (options,index) => (
                                    <MenuItem value={index}>
                                        { t(options.language_key) }
                                        { options.type === "rate" && " "+t('web-offer-price') }
                                        { options.type === "room_stop_sell" && " " + t('btn-stop_sell') }
                                        { options.type === "rate_stop_sell" && " " + t('enw-select-inventory_rate-stop-sell') }
                                        { options.type === "min_stay" && " " + t('enw-select-inventory_min-stay') }
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                    <BulkUpdateDatTime handleChangeDate={ handleChangeDate }/>
                    <ColAtm className="bulk-update-date-time group-inventory-dates">
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Mon"
                                control={<Checkbox color="primary" value="Mon" defaultChecked={true} />}
                                label={ t('day-short-monday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Tue"
                                control={<Checkbox color="primary" value="Tue" defaultChecked={true} />}
                                label={ t('day-short-tuesday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Wed"
                                control={<Checkbox color="primary" value="Wed" defaultChecked={true} />}
                                label={ t('day-short-wednesday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Thu"
                                control={<Checkbox color="primary" value="Thu" defaultChecked={true} />}
                                label={ t('day-short-thursday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Fri"
                                control={<Checkbox color="primary" value="Fri" defaultChecked={true} />}
                                label={ t('day-short-friday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Sat"
                                control={<Checkbox color="primary" value="Sat" defaultChecked={true} />}
                                label={ t('day-short-saturday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Sun"
                                control={<Checkbox color="primary" value="Sun" defaultChecked={true} />}
                                label={ t('day-short-sunday') }
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                    </ColAtm>
                    {
                        (state.update_type === "rate") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_stop_sell") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "min_stay") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                </Grid>
                <ColAtm className="mt-44" md={12}>
                    <ButtonAtm
                        className="m-auto room-rate-modal-save bg-red"
                        buttontext={ t('btn-save') }
                        type='submit'
                        autoFocus
                        color="primary"
                    />
                </ColAtm>
            </ValidatorForm>
            {
                showMessage()
            }
        </ColAtm>
    );
}

export default BulkUpdateTabs;
