import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import BulkUpdateTabs from "./BulkUpdateTab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const BulkUpdate = (props) =>  {
    const [open, setOpen] = React.useState(false);

    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect( () => {})


    return (
        <>
            <ButtonAtm className="type-plan-filter inventory-filter-box bulk-update-button" buttontext={ t('btn-bulk_update') } fullwidth={true} onClick={handleClickOpen} />
            <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                <DialogTitle className="red-bottom-border" id="rate-plan" onClose={handleClose}>
                    { t('btn-bulk_update') }
                </DialogTitle>
                <DialogContent>
                    <BulkUpdateTabs channelId={props.channelId} changeAllDays={props.changeAllDays} handleClose={handleClose}/>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default BulkUpdate;
