import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import Container from "@material-ui/core/Container";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import {
    NETWORK_CHANNEL_CANCELLATION_SHORT,
    NETWORK_CHANNEL_PAX_PRICING_SHORT,
    NETWORK_CHANNELS
} from "../../../../../constants/appUrls.constants";
import Loading from "../../../../molecules/Loading/Loading";
import {Animated} from "react-animated-css";

import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import {history} from "../../../../../utils/history";
import {
    addChannelPaymentPolicy, getChannelPaymentCancellationPolicy,
    getChannelPaymentPolicy
} from "../../../../../redux/actions/Network/Channel/channelPaymentPolicy.actions";
import ChannelPaymentForm from "./Components/ChannelPaymentForm";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {isDefined} from "../../../../../utils";
import {Grid} from "@material-ui/core";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";

let t =null;

class ChannelPayment extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getChannelPaymentCancellationPolicy();

        if ( this.props.match.params.id )
        {
            this.props.getChannelPaymentPolicy({ channel_id : this.props.match.params.id } )
            this.props.getChannelSetup({ channel_id : this.props.match.params.id } );
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
    }

    setVisible = () => {
        this.setState({
            isVisible: false
        })
        setTimeout(() => {
            history.push(NETWORK_CHANNELS)
        }, 800);
}

    render() {
        const roomPriceType = isDefined(this.props.channelSetupReducers.channelSetupSubmitData.data) && this.props.channelSetupReducers.channelSetupSubmitData.data.room_price_type.filter((price) => price.is_selected === true).map( price => price.room_pricing_type_id );
        const ChannelRoomPriceType = roomPriceType === false ? isDefined(this.props.channelSetupReducers.channelInformationData.channel_room_pricing_type) && this.props.channelSetupReducers.channelInformationData.channel_room_pricing_type.id : roomPriceType[0];

        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelPaymentReducer.fetching && this.props.channelInformationData.name + " " + t('enw-channels-payment_policy-title') }
                />
                <ChannelMenu menuClass="channel-menu"
                             PaymentColClass="active-menu-bg"
                             menuId={this.props.channelInformationData.id}
                             isConnected={this.props.channelInformationData.is_connected}
                             channelSetup={this.props.channelSetupReducers}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">

                            { this.props.channelPaymentReducer.fetching ? (
                                <Loading />
                            ) : (
                                <ChannelPaymentForm
                                    channelPayment={this.props.channelPaymentReducer}
                                    addChannelPaymentPolicy={this.props.addChannelPaymentPolicy}
                                />

                            )}
                        </div>
                    </Container>
                </Animated>
                {
                    isDefined(this.props.channelInformationData.logo) &&
                    <Grid className="channel-logo-bottom">
                        <img src={this.props.channelInformationData.logoUrl} alt="mini-logo" />
                    </Grid>
                }
                {
                    this.props.channelInformationData.is_connected &&
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-finish') }
                        backTo={ChannelRoomPriceType !== 2 ? NETWORK_CHANNEL_PAX_PRICING_SHORT + this.props.match.params.id : NETWORK_CHANNEL_CANCELLATION_SHORT + this.props.match.params.id }
                        component={Link}
                        nextTo={NETWORK_CHANNELS}
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        channelPaymentReducer : props.channelPaymentReducer,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelSetupReducers : props.channelSetupReducers,
    }
};

const mapDispatchToProps ={
    getChannelPaymentPolicy,
    addChannelPaymentPolicy,
    getChannelSetup,
    getChannelPaymentCancellationPolicy
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelPayment));
