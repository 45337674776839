import React, { useEffect, useState } from "react";
import BlockAtm from "../../../../../atomic/Block/Block";
import LabelAtm from "../../../../../atomic/Label/Label";
import ColAtm from "../../../../../atomic/Col/Col";
import Prices from "./Prices";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {isDefined} from "../../../../../../utils";

export default function QuickPricingAccommodation(props) {
    useEffect(() => {},[]);
    const [roomRate] = useState(props?.roomRate);
    const { t } = useTranslation();

    return (
        <>
        {
                <ColAtm className="inventory-avail-box">
                        <ColAtm xs={2} >
                            <Grid item md={1}></Grid>
                            <Grid item md={1}><i className="gray fas fa-tags" /></Grid>
                            <Grid item md={10}>
                                <LabelAtm labelClass="inventory-room-name" text={ t('enw-quick-price_sub-title')}  />
                            </Grid>
                            <BlockAtm className="channels-box" md={2}></BlockAtm>
                            <BlockAtm className="inventory-copy-box" md={3}></BlockAtm>
                        </ColAtm>
                        <ColAtm xs={10}>
                            {
                                isDefined(roomRate) && isDefined(roomRate.prices) && Object.keys(roomRate.prices).map( (key) => (
                                    <Prices prices={roomRate.prices[key].price}
                                            stop_sell={roomRate.prices[key].stop_sell}
                                            min_stay={roomRate.prices[key].min_stay}
                                            priceName={t(roomRate.prices[key].language_key) + " " + t('web-offer-price')}
                                            onChangeUpdateData={props.onChangeUpdateData}
                                            updatedData={ props.updatedData }
                                            currency_code={ roomRate.currency_code }
                                            channelAvailability={ props.channelAvailability }
                                            showStopSell={ props.showStopSell }
                                            showMinStay={ props.showMinStay }
                                            isAvail={false}
                                    />
                                ))
                            }
                        </ColAtm>
                </ColAtm>

        }
        </>


    );
}
