import React, {Component}   from 'react';

import LoginLeft            from "./components/LoginLeft";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import queryString from "query-string";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {isDefined} from "../../../../utils";
import {history} from "../../../../utils/history";
import {MOBILE_LOGIN_SHORT} from "../../../../constants/appUrls.constants";

class Login extends Component {
    constructor(props) {
        super(props);
        let queryParams = queryString.parse(this.props.location.search);
        this.state = {
            selectedTabIndex: queryParams.singin ? queryParams.singin : 0,
        }
    }

    componentDidMount() {
        if (isDefined(localStorage.getItem("mobile_login"))) {
            history.push(MOBILE_LOGIN_SHORT + localStorage.getItem("mobile_login"));
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <PageTitle title={ t('web-login-page_title') } />
                <LoginLeft selectedTabIndex={this.state.selectedTabIndex} />
            </>
        );
    }
}

Login.propTypes = {};

export default compose(withTranslation('common')) (Login);
