import React, {Component}   from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {downloadContentPhotos} from "../../../../../redux/actions/Content/DownloadContentPhotos/downloadContentPhotosActions";
import ContentPhotos from "./downloadPhotos";
let t =null;

class ContentPhotosDownload extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    render() {
        return (
        <ContentPhotos downloadContentPhotos={this.props.downloadContentPhotos} photoZipData={this.props.photoZipData} />
    );
    }
}
const mapStateToProps = (props) => {
    return {
        photoZipData : props.downloadContentPhotosReducers,
    }
};


const mapDispatchToProps ={
    downloadContentPhotos
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ContentPhotosDownload));
