import React, {Fragment, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import { ValidatorForm } from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../../utils";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function DeleteChannelContact(props) {


    const { t } = useTranslation();

    const {contact, channelContactReducers, getChannelContact, clearErrorMessage, deleteChannelContact, channelId} = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        clearErrorMessage();
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            channel_id: channelId,
            id: contact.id,
            property_channel_mapping_id: contact.property_channel_mapping_id
        }

        deleteChannelContact(params)
    };


    useEffect( () => {

        if (channelContactReducers.channelContactDelete.status === 200) {
            if(open){
                getChannelContact(channelId);
            }
            handleClose();
        }
    },[
        channelContactReducers,
        channelContactReducers.channelContactDelete
    ]);


    return (
        <>
            <ButtonAtm className="edit-channel-price-btn delete-channel-contact-icon"
                       title={t('btn-delete')}
                       endIcon={<i className="fas fa-trash-alt" />}
                       onClick={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                className="delete-channel-contact-modal"
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-channels-contact_delete-title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>

                            {
                                (isDefined(channelContactReducers.channelContactDeleteError) &&
                                    isDefined(channelContactReducers.channelContactDeleteError.response)
                                ) &&
                                <Alert severity="error">{channelContactReducers.channelContactDeleteError.response.data.message}</Alert>
                            }
                            <DialogContent>
                                <DialogContentText className="fs-18 f-w-600">
                                    {t('enw-channels-contact_update-text')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="justify-center">
                                <ButtonAtm
                                    onClick={handleClose}
                                    className="room-rate-modal-save bg-green m-0-8"
                                    type="button"
                                    buttontext={ t('btn-close') }
                                    color="primary"
                                />
                                <ButtonAtm
                                    className="room-rate-modal-save delete-channel-contact-btn m-0-8"
                                    type="submit"
                                    buttontext={ t('btn-delete') }
                                    color="primary"
                                />
                            </DialogActions>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
