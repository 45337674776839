import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../../utils";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function UpdateChannelContact(props) {


    const { t } = useTranslation();

    const {contact, channelContactReducers, getChannelContact, clearErrorMessage, updateChannelContact, channelId} = props;

    const [name, setName] = useState(contact.name);
    const [email, setEmail] = useState(contact.email);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        clearErrorMessage();

        setName(contact.name)
        setEmail(contact.email)
    };


    const changeName = ({target}) => {
        setName(target.value)
    };

    const changeMail = ({target}) => {
        setEmail(target.value)
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            channel_id: channelId,
            id: contact.id,
            property_channel_mapping_id: contact.property_channel_mapping_id,
            name: name,
            email: email
        }

        updateChannelContact(params)
    };


    useEffect( () => {

        if (channelContactReducers.channelContactUpdate.status === 200) {
            if(open){
                getChannelContact(channelId);
            }
            handleClose();
        }

        return () => {
            if(isDefined(channelContactReducers.channelContactUpdateError) &&
                isDefined(channelContactReducers.channelContactUpdateError.response)){
                setName(contact.name)
                setEmail(contact.email)
            }
        }
    },[
        channelContactReducers,
        channelContactReducers.channelContactUpdate,
        channelContactReducers.channelContactUpdateError
    ]);


    return (
        <>
            <ButtonAtm className="edit-channel-price-btn"
                       endIcon={<i className="fas fa-edit" />}
                       onClick={handleClickOpen}
                       title={t('btn-update')}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-channels-contact_update-title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>

                            {
                                (isDefined(channelContactReducers.channelContactUpdateError) &&
                                    isDefined(channelContactReducers.channelContactUpdateError.response)
                                ) &&
                                <Alert severity="error">{channelContactReducers.channelContactUpdateError.response.data.message}</Alert>
                            }

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextValidator
                                        label={ t('enw-input-name_surname') }
                                        name="name"
                                        fullWidth
                                        autoComplete="off"
                                        value={name}
                                        onChange={changeName}
                                        variant="outlined"
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidator
                                        label={ t('enw-input-email') }
                                        name="email"
                                        fullWidth
                                        autoComplete="off"
                                        value={email}
                                        onChange={changeMail}
                                        variant="outlined"
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </Grid>
                            </Grid>
                            <ColAtm className="mt-22" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
