import React            from 'react';
import { makeStyles }   from '@material-ui/core/styles';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Typography       from '@material-ui/core/Typography';
import PropTypes        from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    toolbar: {
        height: '80px',
        paddingRight: '0'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#8f94a2',
        fontSize: 20,
        fontWeight: 'bold',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#8f94a2'

        },
    },
    saveButton: {
        backgroundColor: '#d71f26',
        borderRadius: '0',
        padding: '29px 40px',
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#c62128',
        },
        [theme.breakpoints.up('xs')]: {
            padding: '29px 20px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '29px 54px',
        },
    },
}));

export default function PageHeaderSaveStatusAtm(props) {
    const classes = useStyles();

    return (
        <div className={classes.root} onChange={props.onChange}>
            <AppBar className={props.headerClass} position="static">
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" className={props.title}>
                        <i className={props.statusIconClass} />
                        {props.text}
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}
PageHeaderSaveStatusAtm.propTypes = {
    text: PropTypes.string,
    statusIconClass: PropTypes.string,
    title: PropTypes.string,
    headerClass: PropTypes.string,
    onChange: PropTypes.func,
};
