import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import {Grid, MenuItem} from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import InputAdornment from "@material-ui/core/InputAdornment";
import {getReservationDetail} from "../../../../../../redux/actions/Network/Reservation/reservationDetail.actions";
import {useDispatch, useSelector} from "react-redux";
import ColAtm from "../../../../../atomic/Col/Col";
import {updateTicket, updateTicketClearState} from "../../../../../../redux/actions/Network/Reservation/ticket.actions";
import {isDefined} from "../../../../../../utils";
import {Alert} from "@material-ui/lab";
import createOrUpdateTicketReducer
    from "../../../../../../redux/reducers/Network/Reservation/ReservationDetail/createOrUpdateTicketReducer";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const ReservationUpdateModal = (props) =>  {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateReservationData = useSelector(props => props.createOrUpdateTicketReducer.update_ticket)
    const errorUpdateReservationData = useSelector(props => props.createOrUpdateTicketReducer.update_ticket_error)

    const {reservation} = props;

    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(reservation.total);
    const [status, setStatus] = useState(reservation.status);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(updateTicketClearState());
    };

    const handleChangePrice = ({target}) => {
        setPrice(target.value);
    };

    const handleChangeStatus = ({target}) => {
        if(target.value === null) return 0;
        setStatus(target.value);
    }

    useEffect( () => {

        const params = {
            booking_id: reservation.id
        }

        if (isDefined(updateReservationData) && updateReservationData.status === 200) {

            handleClose();
            dispatch(getReservationDetail(params))
        }

    },[updateReservationData, dispatch, handleClose] )

    const onSubmit = () => {

        const params = {
            booking_id: reservation.id,
            total: price,
            currency_code: reservation.currency_code,
            status: status
        }

        dispatch(updateTicket(params))
    };

    return (
        <>
            <ButtonAtm className="filter-action-button float-right"
                       startIcon={<i className="fas fa-plus-circle" />} buttontext={t('enw-reservation_update-title')} fullwidth={true} onClick={handleClickOpen} />
            <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                <DialogTitle className="red-bottom-border" id="rate-plan" onClose={handleClose}>
                    {t('enw-reservation_update-title')}
                </DialogTitle>
                <DialogContent>

                    {isDefined(errorUpdateReservationData) &&
                    isDefined(errorUpdateReservationData.response) &&
                        <Alert severity="error">{errorUpdateReservationData.response.data.message}</Alert>}

                    <ValidatorForm onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextValidator
                                    label={ t('enw-input-price') }
                                    name="price"
                                    fullWidth
                                    autoComplete="off"
                                    value={price}
                                    onChange={handleChangePrice}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="start">
                                                {reservation.currency_code}
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectValidator
                                    onChange={handleChangeStatus}
                                    className="form-control"
                                    variant="outlined"
                                    fullWidth
                                    value={status}
                                    label={ t('enw-input-status') }
                                    validators={['required']}
                                    errorMessages={[ t("enw-notification_this_is_required") ]}
                                >
                                    <MenuItem value={0} >
                                        { t('enw-payment_transaction-status-cancel_refund') }
                                    </MenuItem>
                                    <MenuItem value={1} >
                                        { t('enw-channels-channel_setup-booking-title') }
                                    </MenuItem>
                                    <MenuItem value={2} >
                                        { t('enw-reservation-prebooking') }
                                    </MenuItem>
                                    <MenuItem value={3} >
                                        { t('enw-reservation-no-show') }
                                    </MenuItem>
                                </SelectValidator>
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default ReservationUpdateModal;
