import { offerConstants } from "../../../../constants/Offer/offer.constants";

const initialState = {
    fetching :false,
    offerData: {},
    storeFetching:false,
    offerStoreData : [],
    offerStoreStatus : false,
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offerConstants.GET_CREATE_OFFER_PENDING:
            return {
                ...state,
                fetching:true,
                error : {},
            };
        case offerConstants.GET_CREATE_OFFER_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerData: action.payload,
            };
        case offerConstants.GET_CREATE_OFFER_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.response //TODO : error kısmı iş sonu bakılcack
            };
        //STORE OFFER
        case offerConstants.STORE_OFFER_PENDING:
            return {
                ...state,
                storeFetching:true,
                offerStoreStatus:false,
                error : {},
            };
        case offerConstants.STORE_OFFER_FULFILLED:
            return {
                ...state,
                storeFetching : false,
                offerStoreStatus : true,
                offerStoreData : action.payload,
            };
        case offerConstants.STORE_OFFER_REJECTED:
            return {
                ...state,
                storeFetching : false,
                offerStoreStatus   : false,
                error :action.payload
            }
        default:
            return state
    }
}
