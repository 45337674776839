import {getUserToken, getProperty, getAppLang, isDefined} from "../../../../utils";
import {
    GET_AWARDS_CERTIFICATES, UPDATE_AWARDS_CERTIFICATES,
    GET_AWARDS_CERTIFICATES_CATEGORIES, DELETE_AWARDS_CERTIFICATES_PHOTO, CREATE_AWARDS_CERTIFICATES,
    AWARDS_CERTIFICATES_UPDATE_STATUS
} from "../../../../constants/api.constants";
import axios                            from 'axios';

export function getAwardsCertificates() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_AWARDS_CERTIFICATES',
            payload: axios.post(GET_AWARDS_CERTIFICATES, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getAwardsCertificatesCategories() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_AWARDS_CERTIFICATES_CATEGORIES',
            payload: axios.post(GET_AWARDS_CERTIFICATES_CATEGORIES, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function createAwardsCertificates(params) {
    const formData = new FormData();

    formData.append( 'property_id', getProperty());
    if (isDefined(params.file)) {
        formData.append('file', params.file, params.file.name);
    }
    formData.append( 'category_id', JSON.stringify(params.category_id));
    formData.append( 'name', params.name);
    formData.append( 'date', params.date);
    formData.append( 'url', params.url);
    formData.append( 'language_code', params.language_code);

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data',  'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_AWARDS_CERTIFICATES',
            payload: axios.post(CREATE_AWARDS_CERTIFICATES, requestOptions.data, {headers: requestOptions.headers})
        })
    }
}

export function updateAwardsCertificates(params) {
    const formData = new FormData();

    formData.append( 'property_id', getProperty());
    if (isDefined(params.file) && isDefined(params.file.type)) {
        formData.append('file', params.file, params.file.name);
    }
    formData.append( 'category_id', JSON.stringify(params.category_id));
    formData.append( 'name', params.name);
    formData.append( 'date', params.date);
    formData.append( 'url', params.url);
    formData.append( 'award_certificate_id', params.award_certificate_id);
    formData.append( 'language_code', params.language_code);

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data',  'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_AWARDS_CERTIFICATES',
            payload: axios.post(UPDATE_AWARDS_CERTIFICATES, requestOptions.data, {headers: requestOptions.headers})
        })
    }
}

export function deleteAwardsCertificatesPhoto(Id) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                award_certificate_id : Id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'DELETE_AWARDS_CERTIFICATES_PHOTO',
            payload: axios.post(DELETE_AWARDS_CERTIFICATES_PHOTO, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function AwardsCertificatesUpdateStatus(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                award_certificate_id: params.award_certificate_id,
                set_status :  params.set_status
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'AWARDS_CERTIFICATES_UPDATE_STATUS',
            payload: axios.post(AWARDS_CERTIFICATES_UPDATE_STATUS, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createOrUpdateAwardsCertificatesErrorClear() {

    return dispatch => {
        dispatch({
            type: 'AWARDS_CERTIFICATES_CLEAR_ERROR_STATE',
        })
    }
}
