import React from "react";
import ButtonAtm from "../../../../atomic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ColAtm from "../../../../atomic/Col/Col";
import InputAtm from "../../../../atomic/Input/Input";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const CopyOfferLink = (props) => {

    const { t } = useTranslation();
    const {dataToCopy} = props;
    const [open, setOpen] = React.useState(false);

    const handleCopy = () =>  {

        var copyText = document.getElementById("copyText");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ButtonAtm
                onClick={handleClickOpen}
                className={"offer-list-button"} startIcon={<i className="fas fa-copy" />}
            />
            <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={handleClose}>
                    { t('enw-offer_list-modal-copy_link-title') }
                </DialogTitle>
                <DialogContent className="pb-24">
                    <ColAtm>
                        <ColAtm sm={10}>
                            <InputAtm
                                readOnly
                                type="text"
                                value={dataToCopy}
                                variant="outlined"
                                id="copyText"
                            />
                        </ColAtm>
                        <ColAtm sm={1} className="offer-copy-icon">
                            <ButtonAtm
                                onClick={handleCopy}
                                className={"offer-list-button"} startIcon={<i className="fas fa-copy" />}
                            />
                        </ColAtm>
                    </ColAtm>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default CopyOfferLink
