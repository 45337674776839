import React from "react";
import {Route, Router, Switch} from "react-router-dom"
import {history} from "../utils/history";
import {HOMEPAGE, LOGIN, RESET_PASSWORD, MOBILE_LOGIN} from "../constants/appUrls.constants"

import { I18nextProvider } from 'react-i18next';
import i18next from '../translations';

import PrivateUrls from './WrappedRoutes/PrivateUrls';
import {PublicRoute} from "./PublicRoute";
import {PrivateRoute} from "./PrivateRoute";

import '../components/templates/Settings/scss/antd.scss'
import '../components/templates/Settings/scss/styles.scss'
import Box from '@material-ui/core/Box';

import Login from "../components/templates/Global/Login";
import MobileLogin from "../components/templates/Global/MobileLogin";

import ScrollToTop from "../components/templates/Settings/ScrollTop/ScrollTop";
import OfferPage from "../components/templates/Offer/Offer";
import ResetPassword from "../components/templates/Global/ResetPassword";
import Error from "../components/templates/Global/Errors/Error";

export const AppRoute = () => {
    return (
        <Router history={history}>
            <I18nextProvider i18n={i18next}>
                <Box className="layout">
                    <ScrollToTop>
                        <Switch>
                            <PublicRoute exact path={HOMEPAGE} restricted={false} component={ Login } />
                            <PublicRoute exact path={RESET_PASSWORD} restricted={false} component={ ResetPassword }  />
                            <PublicRoute exact path={LOGIN} restricted={false} component={ Login }  />
                            <PublicRoute exact path={MOBILE_LOGIN} restricted={false} component={ MobileLogin }  />
                            <Route exact path="/offer/:id" component={OfferPage} />
                            <PrivateRoute exact component={ PrivateUrls } />
                            <Route component={ Error }/>
                        </Switch>
                    </ScrollToTop>
                </Box>
            </I18nextProvider>
        </Router>
    );
};

