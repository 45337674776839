export const getAddonsConstants = {
    NETWORK_ADDONS_GET_PENDING  : 'NETWORK_ADDONS_GET_PENDING',
    NETWORK_ADDONS_GET_FULFILLED: 'NETWORK_ADDONS_GET_FULFILLED',
    NETWORK_ADDONS_GET_REJECTED : 'NETWORK_ADDONS_GET_REJECTED',
};

export const syncAddonsConstants = {
    NETWORK_ADDONS_SYNC_PENDING  : 'NETWORK_ADDONS_SYNC_PENDING',
    NETWORK_ADDONS_SYNC_FULFILLED: 'NETWORK_ADDONS_SYNC_FULFILLED',
    NETWORK_ADDONS_SYNC_REJECTED : 'NETWORK_ADDONS_SYNC_REJECTED',
    NETWORK_ADDONS_CLEAR_STATE   : 'NETWORK_ADDONS_CLEAR_STATE',
};
