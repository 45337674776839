import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import OfferInfo from "../../../../molecules/Offer/OfferInfo";
import ImportantNotes from "../../../../molecules/Offer/ImportantNotes";
import CancellationPolicy from "../../../../molecules/Offer/CancellationPolicy";
import Accommodation from "../../../../molecules/Offer/Accommodation";
import Rooms from "../../../../molecules/Offer/Rooms";
import Employess from "../../../../molecules/Offer/Employees";
import Amenities from "../../../../molecules/Offer/Amenities";
import Facilities from "../../../../molecules/Offer/Facilities";
import Photos from "../../../../molecules/Offer/Photos";

import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {Link} from "react-router-dom";
import {dateGlobal} from "../../../../../utils";
import {OFFER_PRICE_SHORT} from "../../../../../constants/appUrls.constants";

const OfferForm  = (props) => {

    const { t } = useTranslation();
    const {offerReducer,offerId} = props
    const [state, setState] = useState({
        accommodation_mapping :getSelectedAccommodation(offerReducer),
        room_mapping : getSelectedRoom(offerReducer),
        contact_mapping : getSelectedContactMapping(offerReducer),
        hotel_features_mapping: getSelectedData(offerReducer,"1"),
        fact_mapping : getSelectedData(offerReducer,"44"),
        photo_mapping : getSelectedPhoto(offerReducer),
        offerChange : false,
        start_date: Object.keys(offerReducer).length > 0  &&  offerReducer.start_date !== null ? offerReducer.start_date : dateGlobal( new Date() ),
        end_date: Object.keys(offerReducer).length > 0  &&  offerReducer.end_date !== null ?  offerReducer.end_date : dateGlobal(new Date( new Date().setDate(new Date().getDate()+13))),
    });

    const [coverPhotos,setCoverPhotos] = useState( []);
    const [status,setStatus] = useState( Object.keys(offerReducer).length > 0 ? offerReducer.get_offer.status : 0);

    let defaultValues = {}

    if(Object.keys(props.offerReducer).length > 0){
        defaultValues = {
            title: getOfferKeyData(offerReducer.get_offer,'title'),
            email: getOfferKeyData(offerReducer.get_offer,'email'),
            language: getOfferKeyData(offerReducer.get_offer,'language'),
            currency: getOfferKeyData(offerReducer.get_offer,'currency'),
            confirm_type: getOfferKeyData(offerReducer.get_offer,'confirm_type'),
            payment_list_type: getOfferKeyData(offerReducer.get_offer,'payment_list_type'),
            expire_date : getOfferKeyData(offerReducer.get_offer,'expire_date'),
            important_notes: getOfferByKeyName(offerReducer.get_offer,'offer_important_notes').length > 0 ? getOfferByKeyName(offerReducer.get_offer,'offer_important_notes') : [""],
            has_cancellation_policy: offerReducer.get_offer.offer_has_cancellation_policy.toString(),
            cancellation_policy: getOfferByKeyName(offerReducer.get_offer,'offer_cancellation_policy').length > 0 ? getOfferByKeyName(offerReducer.get_offer,'offer_cancellation_policy') : [{}],
        }
    }else{
        defaultValues = {
            title: "",
            email: "",
            language: "",
            currency: "",
            confirm_type: "",
            payment_list_type: "",
            expire_date : null,
            important_notes: [""],
            has_cancellation_policy: "false",
            cancellation_policy: [{}],
        }
    }

    const { register, handleSubmit,  errors , control, watch, setValue, getValues, formState, clearError} = useForm({
        mode: 'onChange',
        reValidateMode: "onChange",
        defaultValues,
        shouldFocusError: true,
    })
    const { dirty } = formState;


    useEffect( () => {
        if(offerReducer.length !== 0){
            let cover_photos = getSelectedCoverPhoto(offerReducer);
            setCoverPhotos(cover_photos);
        }
    },[])


    const onClickAccommodationType = (e) => {

        if(e.target.checked === true){
            setState({ ...state, accommodation_mapping: [...state.accommodation_mapping, e.target.value],offerChange: true } );
        }else if (e.target.checked === false){
            let temp_accommodation_mapping = state.accommodation_mapping;
            let index = temp_accommodation_mapping.indexOf(parseInt(e.target.value))
            if (index !== -1) {
                temp_accommodation_mapping.splice(index, 1);
                setState({...state, accommodation_mapping: temp_accommodation_mapping , offerChange: true });
            }
        }
    }

    const  onClickSelectRoom = (e) => {
        if(e.target.checked === true) {
            setState({ ...state, room_mapping: [...state.room_mapping, e.target.value] ,offerChange: true} );
        }else if (e.target.checked === false){
            let temp_room_mapping = state.room_mapping;
            let index = temp_room_mapping.indexOf( parseInt(e.target.value) )
            if (index > -1) {
                temp_room_mapping.splice(index, 1);
                setState({...state, room_mapping: temp_room_mapping, offerChange: true });
            }
        }
    }

    const  onClickEmployess = (e) => {
        if(e.target.checked === true) {
            setState({ ...state, contact_mapping: [...state.contact_mapping, e.target.value], offerChange: true } );
        }else if (e.target.checked === false){
            let temp_contact_mapping = state.contact_mapping;

            let index = temp_contact_mapping.indexOf(parseInt(e.target.value))
            if (index > -1) {
                temp_contact_mapping.splice(index, 1);
                setState({...state, contact_mapping: temp_contact_mapping, offerChange: true});
            }
        }
    }

    const  onClickHotelFeatures = (factIds) => {
        setState({ ...state, fact_mapping: factIds, offerChange: true } );
    }

    const  onClickFacility = (factIds) => {
        setState({ ...state, fact_mapping: factIds, offerChange: true } );
    }

    const onClickPhoto = (photoIds) => {

        if (photoIds.length === 0) {
            setCoverPhotos([]);
            setState({...state,  offerChange: true});
        }else{
            setState({...state, photo_mapping: photoIds,offerChange: true});
        }
    }

    const onClickCoverPhoto = (coverPhoto) => {
        setCoverPhotos(coverPhoto);
        setState({...state, offerChange: true});
    }

    const confirmType = offerReducer?.get_offer?.confirm_type;
    const acceptStatus = offerReducer?.get_offer?.accept_status;

    return (
        <div>
            <OfferInfo offerReducer={offerReducer}
                       register={register}
                       errors={errors}
                       control={control}
                       watch={watch}
                       setValue={setValue}
                       getValues={getValues}
            />
            <ColAtm className="offer-list-col">
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-building room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-facilities-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Facilities offerReducer={offerReducer}
                                    onClickFacility={onClickFacility}
                                    fact_mapping={state.fact_mapping}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-hotel room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-amenities-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Amenities offerReducer={offerReducer}
                                   onClickHotelFeatures={onClickHotelFeatures}
                                   fact_mapping={state.hotel_features_mapping}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-id-card-alt room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-general-tab-employees')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Employess offerReducer={offerReducer}
                                   onClickEmployess={onClickEmployess}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-bed room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-room-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Rooms offerReducer={offerReducer}
                               onClickSelectRoom={onClickSelectRoom}
                               room_mapping={state.room_mapping}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-concierge-bell room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-amenities-tab-accommodation')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Accommodation offerReducer={offerReducer}
                                       onClickAccommodationType={onClickAccommodationType}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-image room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title"  text={t('enw-photos-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="offer-photos-detail room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <Photos offerReducer={offerReducer}
                                onClickPhoto={onClickPhoto}
                                onClickCoverPhoto={onClickCoverPhoto}
                                photo_mapping={state.photo_mapping}
                                cover_photos={coverPhotos}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-pen room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-offer-important_notes-title')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ImportantNotes control={control}
                                        name="important_notes"
                                        register={register}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="offer-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand">
                        <ColAtm>
                            <ColAtm md={11}>
                                <i className="fas fa-calendar-minus room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-offer-cancellation_policy-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="cancellation-policy-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <CancellationPolicy register={register}
                                            errors={errors}
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                            clearError={clearError}
                                            name="cancellation_policy"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </ColAtm>

            <ColAtm md={((state.offerChange || dirty) && (confirmType === "HTL" && acceptStatus === 2)) ? 8 :
                (state.offerChange || dirty || (confirmType === "HTL" && acceptStatus === 2)) ? 6 : 4
            } spacing={3} className="m-auto">

                    {
                        ( state.offerChange || dirty ) ?

                            <ColAtm xs={((state.offerChange || dirty) && (confirmType === "HTL" && acceptStatus === 2)) ? 3 :
                                ( state.offerChange || dirty || (confirmType === "HTL" && acceptStatus === 2)  ) ? 4 : 6} className="m-auto">
                            <ButtonAtm
                                className="offer-manage-price-button red-button executive-list"
                                outline
                                fullwidth={true}
                                buttontext={ t('btn-save_offer') }
                                onClick={handleSubmit(data => {
                                    let param = {
                                        "offer_id"               : offerId,
                                        "title"                  : data.title,
                                        "email"                  : data.email,
                                        "confirm_type"           : data.confirm_type,
                                        "payment_list_type"      : data.payment_type_mapping_id,
                                        "currency"               : data.currency,
                                        "expire_date"            : data.expire_date,
                                        "language"               : data.language,
                                        "fact_mapping"           : state.fact_mapping,
                                        "hotel_features_mapping" : state.hotel_features_mapping,
                                        "contact_mapping"        : state.contact_mapping,
                                        "room_mapping"           : state.room_mapping,
                                        "accommodation_mapping"  : state.accommodation_mapping,
                                        "photo_mapping"          : state.photo_mapping,
                                        "cover_photos"           : coverPhotos,
                                        "important_notes"        : data.important_notes,
                                        "has_cancellation_policy": data.has_cancellation_policy == "true" ? true :false,
                                        "cancellation_policy"    : data.cancellation_policy === undefined ? [] : data.cancellation_policy
                                    }

                                    props.updateOffer( param );

                                })}
                            />
                            </ColAtm>

                            :
                            (
                                <></>
                            )
                    }

                <ColAtm xs={((state.offerChange || dirty) && (confirmType === "HTL" && acceptStatus === 2)) ? 3 :
                    ( state.offerChange || dirty || (confirmType === "HTL" && acceptStatus === 2) ) ? 4 : 6} className="m-auto">
                    {
                        (!state.offerChange || !dirty ) && props.offerUpdateStatus ?
                            (
                                <Link
                                    className="offer-manage-price-button blue-button executive-list mb-16 t-center"
                                    to={`${OFFER_PRICE_SHORT}?id=${offerId}&start_date=${state.start_date}&end_date=${state.end_date}`}
                                >
                                    { t('btn-manage_price') }
                                </Link>

                            ) :
                            (
                                <Link
                                    className="offer-manage-price-button blue-button executive-list mb-16 text"
                                    to={`${OFFER_PRICE_SHORT}?id=${offerId}&start_date=${state.start_date}&end_date=${state.end_date}`}
                                    disabled={ (state.offerChange || dirty ) }
                                >
                                    { t('btn-manage_price') }
                                </Link>
                            )
                    }

                </ColAtm>

                    {
                        confirmType === "HTL" && acceptStatus === 2 &&
                        <ColAtm xs={((state.offerChange || dirty) && (confirmType === "HTL" && acceptStatus === 2)) ? 3 :
                            ( state.offerChange || dirty || (confirmType === "HTL" && acceptStatus === 2) ) ? 4 : 6} className="m-auto">
                            <ButtonAtm
                                buttontext={ t('enw-input-active-confirm') }
                                className="offer-manage-price-button offer-green-button yellowButton executive-list mb-16 t-center p-10-5"
                                onClick={(e) => {
                                    let params = {
                                        offer_id: parseInt(offerId),
                                        accept_status : 1
                                    }
                                    props.offerAcceptStatus(params)
                                }}
                            />
                        </ColAtm>
                    }
                <ColAtm xs={((state.offerChange || dirty) && (confirmType === "HTL" && acceptStatus === 2)) ? 3 :
                    ( state.offerChange || dirty || (confirmType === "HTL" && acceptStatus === 2) ) ? 4 : 6} className="m-auto">
                    { status === 1 ? (
                        <ButtonAtm
                            buttontext={ t('enw-input-inactive') }
                            className="offer-manage-price-button orange-button executive-list p-10-5"
                            onClick={(e) => {
                                setStatus(0);
                                let params = {
                                    "offer_id": offerId,
                                    "status" : 0
                                }
                                props.updateOfferStatus(params)
                            }}
                        />
                    ) : (
                        <ButtonAtm
                            buttontext={ t('enw-input-active') }
                            className="offer-manage-price-button offer-green-button executive-list mb-16 t-center p-10-5"
                            onClick={(e) => {
                                setStatus(1);
                                let params = {
                                    "offer_id": offerId,
                                    "status" : 1
                                }
                                props.updateOfferStatus(params)
                            }}
                        />
                    )}
                </ColAtm>
            </ColAtm>
        </div>
    );
}

export default OfferForm;

const getOfferKeyData = (data,keyName) => {
    let tempData = [];

    if(data.hasOwnProperty(keyName)){
        tempData = data[keyName]
    }else{
        tempData = ''
    }

    return tempData;
}
const getOfferByKeyName = (data , keyName) => {

    let tempData = [];

    for (const dataKey of Object.keys(data)) {

        if( dataKey === keyName){

            tempData = data[dataKey]
        }
    }

    let convertedData =[];
    switch (keyName)
    {
        case 'offer_important_notes':
            convertedData = tempData.map(note =>note.note)
            break;
        case 'offer_cancellation_policy':
            convertedData = tempData.map(cpolicy => {
                return{
                    days_before: cpolicy.days_before,
                    type       : cpolicy.type,
                    value      : cpolicy.value,
                }
            })
            break;
        default:
            convertedData = [];
    }

    return convertedData
}

const getSelectedData = (reducer,keyName) => {
    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];
    Object.keys(reducer.get_facts).map( (factsKey) => {
        if(factsKey == keyName )
        {
            Object.keys(reducer.get_facts[factsKey].fact_subcategory).map( (factSubCatKey) => {
                reducer.get_facts[factsKey].fact_subcategory[factSubCatKey].fact.filter( (fact) => fact.is_selected === true )
                    .map(fact => tempData = [...tempData,fact.id] )
            })
        }
    })

    return tempData
}
const getSelectedRoom = (reducer) => {

    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];

    tempData = reducer.property_rooms.filter((room) => room.is_selected === true).map( room => room.id )

    return tempData
}
const getSelectedContactMapping = (reducer) => {

    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];

    tempData = reducer.executives.filter((executive) => executive.is_selected === true).map(executive => executive.id);

    return tempData
}
const getSelectedPhoto = (reducer) => {

    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];

    tempData = reducer.photos.filter((photo) => photo.is_selected === true).map( photo => photo.id )

    return tempData
}
const getSelectedCoverPhoto = (reducer) => {

    if(reducer.length === 0) return [];

    let tempData = [];
    tempData = reducer.photos.filter( (cover) => cover.is_cover === true).map( cover => cover.id )
    return tempData
}
const getSelectedAccommodation = (reducer) => {

    if(reducer.length === 0)
    {
        return [];
    }

    let tempData = [];
    tempData = reducer.accommodation_types.filter((fact) => fact.is_selected === true).map(fact => fact.id);
    return tempData
}
