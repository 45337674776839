import React ,{ useEffect } from "react";
import {useDispatch } from "react-redux";
import {useForm} from "react-hook-form";
import {FormHelperText, Grid} from "@material-ui/core";
import InputAtm from "../../../../atomic/Input/Input";
import ButtonAtm from "../../../../atomic/Button/Button";
import {useTranslation, withTranslation} from "react-i18next";
import {clearStateForgotPassword} from "../../../../../redux/actions/User/ForgotPassword/userForgotPassword"
import {clearStateUserRegister} from "../../../../../redux/actions/User/Register/userRegisterAction";
import CircularProgress from "@material-ui/core/CircularProgress";


const ForgotPassword = (props) => {
    const { register, handleSubmit,  errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        })
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        props.forgotPassword(data)
    }

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(clearStateUserRegister())
            dispatch(clearStateForgotPassword())
        }
    }, []);


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'center'}} className="password-form">
                <Grid container justify="space-between">
                    <InputAtm
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label={ t('enw-input-email') }
                        name="email"
                        autoComplete="off"
                        autoFocus
                        error={!!errors.email}
                        inputRef={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("enw-notification_invalid_email")
                            },
                            validate: value => value !== "" || t("enw-notification_this_is_required"),
                        })}
                    />
                    <FormHelperText error={!!errors.email}>
                        {errors.email && errors.email.message}
                    </FormHelperText>
                </Grid>
                <ButtonAtm
                    className="login-button"
                    fullwidth={true}
                    variant="contained"
                    color="primary"
                    buttontext={ t('btn-send') }
                    style={{textTransform: 'none' }}
                    type="submit"
                    endIcon={props.userForgotPasswordReducer.fetching && <CircularProgress color="secondary" />}
                    disabled={props.userForgotPasswordReducer.fetching}
                />
            </form>

        </>
    )
}

export default (withTranslation('common'))( ForgotPassword)
