import React, {Component} from 'react';
import {
    propertyRatePlanAdd,
    PropertyRoomRates,
    getAccommodationTypes,
    PropertyRoomAndRateList, PropertyRoomAndRateListForRateCreate
} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {connect} from "react-redux";
import ButtonAtm from "../../../../atomic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import RatePlanTabs from "./NewRatePlanForm";
import {isDefined} from "../../../../../utils";

let t=null

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class NewRatePlan extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    state = {
        open : false,
        notificationStatus : false
    };


    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClose = () => {
        this.setState({open:false});
        this.props.PropertyRoomAndRateListForRateCreate();
    };

    handlePropertyRatePlanAdd = (param) => {
        this.props.propertyRatePlanAdd(param);
    };

    propertyFactSearch = (param) => {
        if( param.length > 3 ){
            this.props.propertyFactSearchList(param);
        }
    };

    showNotification =  () => {

        if (this.state.notificationStatus && isDefined(this.props.ratePlanAddError))
        {
            return <Collapse className="w-100" in={this.state.notificationStatus}>
                <Alert
                    className="align-self-center rate-alert"
                    action={
                        <IconButton
                            aria-label="close"
                            color="error"
                            size="large"
                            onClick={() => {
                                this.setState({notificationStatus:false});
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity="error"
                >
                    {this.props.ratePlanAddError ? this.props.ratePlanAddError.response.statusText : null}
                </Alert>
            </Collapse>

        }

        return false;

    };

    render() {
        const {open} = this.state;

        return (
            <>
                <ButtonAtm
                    buttontext={ t('btn-new_rate') }
                    fullwidth={true}
                    startIcon={<i className="fas fa-plus-circle" />}
                    endIcon={<i className="fal fa-angle-right ml-29" />}
                    onClick={this.handleClickOpen}
                />
                <Dialog onClose={this.handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={open}>
                    <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.handleClose}>
                          { t('enw-room_and_rate-add_rate_plan-title') }
                    </DialogTitle>
                    <DialogContent className="p-12">
                        <RatePlanTabs
                            onRatePlanAdd={this.handlePropertyRatePlanAdd}
                            accommodationTypes={this.props.accommodationTypes}
                            factSearchList={this.props.factSearchList}
                            rooms={this.props.rooms}
                            showNotification={this.showNotification}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    componentDidMount() {
        this.props.getAccommodationTypes();
        if(this.props.urlParam === "new-rate"){
            this.setState({open : true});
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.ratePlanAddStatus){

            this.props.PropertyRoomRates();
            this.props.PropertyRoomAndRateList();
            this.props.PropertyRoomAndRateListForRateCreate();

            if(prevState.open === true)
            {
                this.setState({ open : false} )
            }
        }

        if(!this.props.ratePlanAddStatus && this.props.ratePlanAddError)
        {
            if(prevState.notificationStatus === false)
            {
                this.setState({ notificationStatus : true} )
            }
        }

    }
}

const mapStateToProps = (state) => {
    return{
        factSearchListStatus : state.propertyRoomAndRateReducers.factSearchListStatus,

        ratePlanAddStatus : state.propertyRoomAndRateReducers.ratePlanAddStatus,
        ratePlanAddError : state.propertyRoomAndRateReducers.ratePlanAddError,

        accommodationTypes : state.propertyRoomAndRateReducers.accommodationTypes,
    }
};

const mapDispatchToProps = {
    propertyRatePlanAdd,
    PropertyRoomRates,
    getAccommodationTypes,
    PropertyRoomAndRateList,
    PropertyRoomAndRateListForRateCreate
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(NewRatePlan));
