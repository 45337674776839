import React from "react";
import ColAtm from "../../atomic/Col/Col";
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

const InventorySkeleton = props => (
    <ColAtm className="skeleton-inventory-box">
        <Grid container spacing={3} className="skeleton-head">
            <Grid item sm={4}>
                <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item sm={8}>
                <Grid container className="inventory-price-padding">
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="circle" width={40} height={40} />
                </Grid>

            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item sm={4}>
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
            </Grid>
            <Grid item sm={8}>
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
                <Skeleton variant="rect" height={20} />
            </Grid>
        </Grid>
    </ColAtm>
);

export default InventorySkeleton;
