import {inventoryConstants} from "../../../../constants/Network/inventory.constants";


const initialState = {
    fetching: false,
    bulkUpdate:{},
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryConstants.PROPERTY_BULK_UPDATE_PENDING:
            return {
                ...state,
                updateFetching:true,
                bulkUpdate:{},
                error:{},
            };
        case inventoryConstants.PROPERTY_BULK_UPDATE_FULFILLED:
            return {
                ...state,
                updateFetching: false,
                bulkUpdate : action.payload
            };
        case inventoryConstants.PROPERTY_BULK_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                updateFetching: false
            };
        case inventoryConstants.PROPERTY_BULK_UPDATE_CLEAR:
            return {
                ...initialState
            };
        default:
            return {
                ...state
            };
    }
}
