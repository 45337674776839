import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, TextField} from "@material-ui/core";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import { ValidatorForm} from 'react-material-ui-form-validator';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {
    getFactList,
    updateFactClearState,
    updateFactMapping
} from "../../../../../redux/actions/Content/FactList/factListActions";
import {useDispatch, useSelector} from "react-redux";
import updateFactMappingReducer from "../../../../../redux/reducers/Content/FactList/updateFactMappingReducer";
import {isDefined} from "../../../../../utils";
import {Alert} from "@material-ui/lab";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

export default function FactDescriptionModal({description, id, pageId, type}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleSubmit } = useForm();

    const factData = useSelector(props => props.updateFactMappingReducer)

    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [state, setState] = useState({
        description: description
    });

    const handleClickOpen = () => {
        setOpen(true);
        dispatch(updateFactClearState());
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleTabChange = ( event, newValue ) => {
        setTabValue(newValue)
    };

    const handleChangeDescription = index => (e) => {
        let values = state.description;
        values[index]['description'] = e.target.value;
        setState({ ...state, values });
    };

    const onSubmit = () => {
        let params;

        params = {
            property_fact: [{
                id: id,
                is_selected: true,
                description: state.description,
            }]
        }

        dispatch(updateFactMapping(params))
    };

    useEffect( () => {
        if (factData.updateFactMappingStatus === 200 && open) {
            setOpen(false);
            dispatch(getFactList(pageId));
        }
    },[factData])

    return (
        <>
            <ButtonAtm className="edit-channel-price-btn"
                       endIcon={<i className="fas fa-edit" />}
                       onClick={handleClickOpen}
                       title={t('btn-update')}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-fact_description_title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {isDefined(factData) && factData.updateFactMappingStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                            {isDefined(factData.error.response) && <Alert severity="error">{factData.error.response.data.message}</Alert>}

                            <ColAtm className="d-block mb-55 fact-desc-container">
                                <ColAtm>
                                    <AppBar className="room-rate-tab-title" position="static">
                                        <Tabs className="room-rate-tabFont"
                                              value={tabValue}
                                              onChange={handleTabChange}
                                              aria-label="room-rate-tab"
                                              variant="scrollable"
                                              scrollButtons="on"
                                        >
                                            {
                                                state.description.map((title,value)=>{
                                                    const flag = <Grid container className="brand-desc-flag" spacing={1}> <Grid item sm={3}><img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} alt={title.language_code} /> </Grid> <Grid item sm={2}> {title.language_code}</Grid></Grid>
                                                    return (
                                                        <Tab label={ flag } {...a11yProps(value)} />
                                                    );
                                                })
                                            }
                                        </Tabs>
                                    </AppBar>

                                    {
                                        state.description.map((title, index)=> {
                                            return (
                                                <TabPanel value={tabValue} index={index} className="w-100">
                                                    <TextField name={title.language_code}
                                                               type="text"
                                                               variant="outlined"
                                                               className="text-capitalize"
                                                               value={title.description}
                                                               onChange={handleChangeDescription(index)}
                                                               label={ t('enw-fact_description') + " - " + title.language_code }
                                                               multiline={true}
                                                               fullWidth
                                                               required={false}
                                                               rows={2}
                                                    />
                                                </TabPanel>
                                            );
                                        })
                                    }
                                </ColAtm>
                            </ColAtm>

                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
