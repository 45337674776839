import {
    createTicketConstants,
    getTicketConstants, updateTicketConstants
} from "../../../../../constants/Network/reservation.constants";

const initialState = {

    get_ticket_fetching :false,
    get_ticket : [],
    get_ticket_error : {},

    create_ticket_fetching :false,
    create_ticket : [],
    create_ticket_error : {},

    update_ticket_fetching :false,
    update_ticket : [],
    update_ticket_error : {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getTicketConstants.GET_TICKET_PENDING:
            return {
                get_ticket_fetching:true,
                get_ticket_error: {},
            };
        case getTicketConstants.GET_TICKET_FULFILLED:
            return {
                ...state,
                get_ticket_fetching : false,
                get_ticket: action.payload,
                get_ticket_error: {},
            };
        case getTicketConstants.GET_TICKET_REJECTED:
            return {
                ...state,
                get_ticket_error: action.payload,
                get_ticket_fetching : false,
            };
        default:
            return state
    }
}
