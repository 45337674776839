import React from "react";
import Dialog from "@material-ui/core/Dialog";
//import WebForm from "./WebForm";
import WebForm from "./WebPreviewDetail";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Grid from "@material-ui/core/Grid";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {Link} from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import {WEB_EDIT_SHORT} from "../../../../../../constants/appUrls.constants";

let t=null;


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WebPreviewDialog extends React.Component{

    constructor(props) {
        super(props);
        t = props.t;
    }

    render(){
        return (
            <Dialog className="web-preview-dialog"
                    TransitionComponent={Transition}
                    onClose={this.props.handlePreviewClose}
                    fullScreen
                    aria-labelledby="customized-dialog-title"
                    open={this.props.open_preview}
            >
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.props.handlePreviewClose}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} md={2}>
                            <div className="d-grid">
                                <Link
                                    className={"site-edit-btn"}
                                    to={WEB_EDIT_SHORT + this.props.webId}
                                >
                                    {t('btn-edit')}
                                </Link>
                            </div>
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <ButtonAtm
                                className={"site-setup-btn"}
                                fullwidth={true}
                                buttontext={ t('btn-setup') }
                                onClick={this.props.openSetup}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <WebForm
                        handleClickOpen = {this.props.handleClickOpenPreview}
                        handlePreviewClose = {this.props.handlePreviewClose}
                        token = {this.props.token}
                        webId = {this.props.webId}
                        webDefaultLang={this.props.webDefaultLang}
                        frameReload={this.props.frameReload}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(withTranslation('common'))(WebPreviewDialog);
