import React, {Component}   from 'react';
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PaymentMenu from "../../../Global/Layout/Header/Submenu/PaymentMenu";
import {
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear,
    GetPaymentType,
    UpdatePaymentInstallmentStatus
} from "../../../../../redux/actions/Payment/Payment.actions";
import {connect} from "react-redux";
import PaymentInstallmentList from "./components/PaymentInstallmentList";
import {
    PAYMENT_SETUP_SHORT,
    PAYMENT_LIST
} from "../../../../../constants/appUrls.constants";
import {isDefined} from "../../../../../utils";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import FooterAtm from "../../../../atomic/Footer/Footer";

let t =null;

class PaymentInstallmentIndex extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getPaymentMappingList();
        this.props.getPaymentTypeList();
        if ( this.props.match.params.id )
        {
            this.props.GetPaymentType( { payment_type_id : this.props.match.params.id } );
            this.setState({
                payment_type_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/payment');
        }
    }


    render() {
        const boxStatus = isDefined(this.props.paymentMapping.updatePaymentMappingBoxStatusData.data) && this.props.paymentMapping.updatePaymentMappingBoxStatusData.data.status;
        const responseCurrencyCode = isDefined(this.props.paymentMapping.updatePaymentMappingBoxStatusData.data) && this.props.paymentMapping.updatePaymentMappingBoxStatusData.data.currency_code;
        const tryCurrencyStatus =
            (boxStatus && responseCurrencyCode === "TRY") ?
                1 :
                (!boxStatus && responseCurrencyCode === "TRY") ?
                    0
                    :
                    isDefined(this.props.paymentMapping.paymentGetList.currencies) && this.props.paymentMapping.paymentGetList.currencies[1].status;
        return (
            <>
                <PageTitle title={ t('enw-payment-payment_setup-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-payment_list') }
                    backButton={PAYMENT_LIST}
                    text={isDefined(this.props.paymentMapping) && this.props.paymentMapping.paymentGetList.name + " "+t('enw-payment-payment_installment')}
                />
                <PaymentMenu menuClass="channel-menu" InstallmentColClass="active-menu-bg"
                             menuId={this.props.match.params.id}
                             bankName={isDefined(this.props.paymentMapping) && this.props.paymentMapping.paymentGetList.name}
                             tryCurrencyStatus={tryCurrencyStatus}
                             boxStatus={boxStatus}
                             responseCurrencyCode={responseCurrencyCode}
                />
                <div className="container-width cancellation-policy-list">
                    {
                        isDefined(this.props.paymentMapping) && !this.props.paymentMapping.paymentGetListFetching ?
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <PaymentInstallmentList
                                        paymentMapping={this.props.paymentMapping}
                                        UpdatePaymentMapping={this.props.UpdatePaymentMapping}
                                        UpdatePaymentStatusMapping={this.props.UpdatePaymentStatusMapping}
                                        PaymentStatusSetDefault={this.props.PaymentStatusSetDefault}
                                        PaymentInstallmentUpdate={this.props.PaymentInstallmentUpdate}
                                        getPaymentMappingList={this.props.getPaymentMappingList}
                                        updatePaymentErrorClear={this.props.updatePaymentErrorClear}
                                        successPaymentClear={this.props.successPaymentClear}
                                        paymentTypeList={this.props.paymentMapping.paymentTypeList}
                                        paymentTypeGet={this.props.paymentMapping.paymentGetList}
                                        GetPaymentType={this.props.GetPaymentType}
                                        paymentTypeId={this.props.match.params.id}
                                        UpdatePaymentInstallmentStatus={this.props.UpdatePaymentInstallmentStatus}
                                    />
                                </Container>
                            </Animated>
                            :
                            <></>
                    }
                </div>
                {
                    (isDefined(this.props.paymentMapping) && !this.props.paymentMapping.paymentGetListFetching) &&
                    <Grid className="channel-logo-bottom">
                        <img src={`/assets/img/logo/banks-logo/${this.props.paymentMapping.paymentGetList.bank_code}.jpg`} alt="mini-logo" />
                    </Grid>
                }
                {
                    tryCurrencyStatus ?
                        <FooterAtm
                            buttonBackText={t('btn-back')}
                            iconRightClassName="fas fa-arrow-right"
                            buttonBackStyle={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                            }}
                            buttonNextStyle={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                            }}
                            iconLeftClassName="fas fa-arrow-left"
                            buttonNextText={t('btn-finish')}
                            backTo={PAYMENT_SETUP_SHORT + this.props.match.params.id}
                            component={Link}
                            nextTo={PAYMENT_LIST}
                            nextComponent={Link}
                        />
                        :
                        <></>
                }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        paymentMapping: props.paymentReducers
    }
};

const mapDispatchToProps ={
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear,
    GetPaymentType,
    UpdatePaymentInstallmentStatus
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PaymentInstallmentIndex));
