import {networkDashboard} from "../../../../constants/Network/networkDashboard.constants";

const initialState = {
    fetching : false,
    error : {},
    networkDashboardData: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case networkDashboard.NETWORK_DASHBOARD_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case networkDashboard.NETWORK_DASHBOARD_FULFILLED:
            return {
                ...state,
                fetching: false,
                networkDashboardData: action.payload.data,
            };
        case networkDashboard.NETWORK_DASHBOARD_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        default:
            return state
    }
}
