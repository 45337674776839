import React, {Component} from 'react';
import PageHeaderAtm from "../../../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import PopupList from "./PopupList";
import {getPopupList} from "../../../../../../redux/actions/Web/Popup/PopupActions";
import {WEB} from "../../../../../../constants/appUrls.constants";
import { WEB_EDIT_SHORT } from '../../../../../../constants/appUrls.constants';
import { getProperty, getAppLang } from '../../../../../../utils';
import { GET_WEB } from '../../../../../../constants/api.constants';
import axios from 'axios';

let t = null;

class PopupIndex extends Component {
    constructor(props) {
        super(props);
        t = props.t;

        this.state = {
            property_id : null
        }
    }

    componentDidMount() {
        this.props.getPopupList();

        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(GET_WEB, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            if(result.data.data.status){
                this.setState({property_id: result.data.data.id})
            } else {
                this.setState({property_id: null})
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        return (
            <>
                <PageTitle title={t('btn-popup-list')}/>
                <PageHeaderAtm exitText={t('enw-web-edit-page_title')} text={t('btn-create-popup')} backButton={this.state.property_id === null ? WEB : WEB_EDIT_SHORT + this.state.property_id}/>
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width pb-0">
                            <PopupList
                                list={this.props.listData}
                                fetching={this.props.fetching}
                            />
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        fetching: state.popupReducers.fetching,
        listData: state.popupReducers.listData,
        listDataError: state.popupReducers.listDataError
    }
};

const mapDispatchToProps = {
    getPopupList
};

export default compose(withTranslation('common'))(connect(mapStateToProps, mapDispatchToProps)(PopupIndex));
