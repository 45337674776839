import React from "react";
import {connect} from "react-redux";
import ColAtm from "../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {Link} from "react-router-dom";
import LabelAtm from "../../../atomic/Label/Label";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ButtonAtm from "../../../atomic/Button/Button";
import {getProfile} from "../../../../redux/actions/User/Profile/profileAction";
import {getBookingEngineDashboard} from "../../../../redux/actions/BookingEngine/BookingEngineDashboard/BookingEngineDashboard.actions";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Hidden} from "@material-ui/core";
import {
    APP,
    CONTENT,
    NETWORK,
    OFFER,
    WEB,
    PAYMENT,
    NETWORK_CHANNEL_INFORMATION_SHORT,
    NETWORK_CHANNEL_SETUP_SHORT, NOT_ALLOWED_DASHBOARD_SHORT
} from "../../../../constants/appUrls.constants";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {history} from "../../../../utils/history";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {getPropertyName, isDefined} from "../../../../utils";
import BookingEnginePreviewDialog from "../components/PreviewWeb/BookingEnginePreviewDialog";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import {propertyProducts} from "../../../../redux/actions/propertyProductsActions";
import NotAllowedPage from "../../Global/NotAllowedPage/NotAllowedPage";
import Charts from "../../Global/Charts";

let t=null;
const drawerWidth = 101;
const leftMenuWidth = 252;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '999',
    },
    leftMenu: {
        width: 0,
        flexShrink: 0,
    },
    leftMenuPaper: {
        width: leftMenuWidth,
        marginLeft: 102,
        marginTop: 81
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '81px',
        display: 'inline-grid'
    },
    listItem: {
        display: 'block',
        textAlign: 'center !important',
        margin: 'auto',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftBarShift: {
        width: `calc(100% - ${leftMenuWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        boxShadow: '0 2px 4px 0 #ccced5',
        backgroundColor: '#ffffff',
        padding: '11px 12.14px'
    },
    hide: {
        display: 'none',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 125,
        marginRight: -60,
        paddingRight: 0,
    },
    content: {
        flexGrow: 1,
        padding: '24px 68px',
    },
    toolbar: theme.mixins.toolbar,
}));

class CreateWeb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSuccessHeader: false,
            open : false,
            open_preview: false,
            copied: false
        };
        t = props.t;
    }

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClickOpenPreview = () => {
        this.setState({open_preview:true});
    };

    handleClose = () => {
        this.setState({open:false, open_preview:false});
    };

    handlePreviewClose = () => {
        this.setState({open_preview:false});
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getProfile();
        this.props.getBookingEngineDashboard();
        this.props.propertyProducts();
    }

    handleCopy = (e, copyText) =>  {
        e.preventDefault();
        navigator.clipboard.writeText(copyText)
        this.setState({copied:true});

        setTimeout(() => {
            this.setState({copied: false});
        }, 1000)

    }
    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={APP}
                               exitText={ t('btn-properties')}
                               text={ getPropertyName() }
                               showSuccessHeader={this.props.createStatus}
        />
    };

    render() {

        const allowedPage = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageOffer = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        function Sidebar(props) {
            const classes = useStyles();

            const sidebar = (
                <div className={classes.root}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <List className={classes.listItem}>
                            {props.siderbarItems.map((post , key) =>
                                <Link key={key} to={post.link} className="completed-title" variant="body2" color="textSecondary" >
                                    <ListItem className={post.isActive === true ? 'active-sideMenu sidebar-list': 'sidebar-list'} button key={post.id}>
                                        <div className={post.isActive === true ? 'active-sidebar': 'sidebar-items'}>
                                            <i className={post.icon} />
                                        </div>
                                        <ListItemText className="dashboard-menu" primary={post.text} />
                                    </ListItem>
                                </Link>
                            )}
                        </List>
                        <Charts />
                    </Drawer>
                </div>
            );
            return (
                <>
                    {sidebar}
                </>
            );
        }
        const siderbarItems = [
            {id: 1, isActive: false, text:  `${ t('enw-product_one-title') }`, icon: 'fas fa-hotel', link:'content'},
            {id: 2, isActive: false, text: `${t('enw-product_two-title') }`, icon: 'fas fa-chart-network', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? "network" : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#" },
            {id: 3, isActive: false, text: `${t('enw-product_three-title') }`, icon: 'fas fa-paperclip', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? "offer" : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#" },
            {id: 4, isActive: false, text: `${t('enw-product_four-title') }`, icon: 'fas fa-network-wired', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? "web" : NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#" },
            {id: 5, isActive: true, text: `${t('enw-product_six-title')}`, icon: 'fas fa-calendar-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? "booking-engine" : NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#" },
            {id: 6, isActive: false, text: `${t('enw-product_five-title') }`, icon: 'fas fa-money-bill-wave-alt', link: !this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? "payment" : NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#" }
        ];

        const {bookingEngine, bookingEngineReducer} = this.props;

        return (
            <>
                <PageTitle title={ t('be-search-booking_engine-title') } />
                {
                    this.headerStatusShow()
                }

                <Grid container className="web-dashboard">
                    <Grid item md={1} className="m-none" style={{ height: 'fit-content'}}>
                        <Sidebar siderbarItems={siderbarItems} />
                    </Grid>
                    <Grid item xs={12} className="m-auto">
                        <div>
                            <CssBaseline />
                            {
                                isDefined(allowedPage[0]) && !allowedPage[0].property_has_product ?
                                    <Grid item xs={12} lg={6} className="no-buy-product no-buy-product-web">
                                        <Card className="p-24">
                                            <NotAllowedPage />
                                        </Card>
                                    </Grid>
                                    :
                                    <Grid container spacing={3} className="mt-44 be-container-width">
                                        {
                                            isDefined(bookingEngine.booking_engine) && !bookingEngine.booking_engine.is_booking_engine_active ?
                                                <Grid item md={6} xs={12} className="m-auto">
                                                    <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                                        <Container maxWidth="xl">
                                                            <div className="container-width">
                                                                <ColAtm xs={12} className="photoButtonsRow one-page-content">
                                                                    <Link to={NETWORK_CHANNEL_INFORMATION_SHORT + 1}>
                                                                        <ColAtm>
                                                                            <div className="m-auto first-create-room">
                                                                    <span>
                                                                       <ButtonAtm
                                                                           buttontext={ t('btn-new_room') }
                                                                           className="filter-action-button"
                                                                           startIcon={<i className="fas fa-laptop" />}
                                                                       />
                                                                    </span>
                                                                            </div>
                                                                        </ColAtm>
                                                                        <LabelAtm labelClass="one-page-title" text={ t('btn-create_be') } />
                                                                        <LabelAtm labelClass="one-page-subtitle p-0-100" text={ t('enw-be-create_title') } />
                                                                    </Link>
                                                                </ColAtm>
                                                            </div>
                                                        </Container>
                                                    </Animated>
                                                </Grid>
                                                :
                                                <Grid item sm={8} lg={7} className="m-auto web-stats be-dashboard-items">
                                                    <Grid container spacing={3}>
                                                        <Grid item md={7} xs={12} className="m-auto pt-20">
                                                            <Card className="offer-dashboard__box-shadow web-dashboard booking-dashboard" item xs={12}>
                                                                <>
                                                                    <Button
                                                                        className="p-0"
                                                                        onClick={this.handleClickOpenPreview}
                                                                        fullWidth
                                                                    >
                                                                        <Box className="general_dashboard w-100 bg-orange" component="fieldset" mb={3} borderColor="transparent">
                                                                            <p className="preview-web-text orange">
                                                                                <i className="fas fa-search orange" />
                                                                                { t('btn-site_preview')}
                                                                            </p>
                                                                            <iframe className="site-frame" src={isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.booking_engine_url} scrolling="no" title="be-frame"/>
                                                                        </Box>
                                                                    </Button>

                                                                    <CardContent className="min-height-156 pt-20 pr-24 pl-24">
                                                                        <ColAtm>
                                                                            <ColAtm md={8} xl={9}>
                                                                                <Typography className="orange pb-12" gutterBottom variant="h5" component="h2">
                                                                                    { t('enw-be-info_title') }
                                                                                </Typography>
                                                                            </ColAtm>
                                                                        </ColAtm>
                                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                                            <Grid container>
                                                                                <Grid item xs={11}>
                                                                                    { t('enw-myw-domain_title') } : <Link to={{pathname: isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.booking_engine_url}} target="_blank">
                                                                                    {isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.booking_engine_url}
                                                                                </Link>
                                                                                </Grid>
                                                                                <Grid item xs={1}  className="be-copy-icon">
                                                                                    <Tooltip title={this.state.copied ? t('btn-copied') : t('btn-copy')}>
                                                                                        <i className="fas fa-copy"
                                                                                           onClick={(e) => this.handleCopy(e, isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.booking_engine_url)} />
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                                            { t('enw-myw-token') } :  <b> {isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.token} </b>
                                                                        </Typography>
                                                                        <BookingEnginePreviewDialog
                                                                            handleClickOpenPreview = {this.handleClickOpenPreview}
                                                                            handlePreviewClose = {this.handlePreviewClose}
                                                                            open_preview ={this.state.open_preview}
                                                                            url={isDefined(bookingEngine.booking_engine) && bookingEngine.booking_engine.booking_engine_url}
                                                                            openSetup={this.handleClickOpen}
                                                                            closeSetup = {this.handleClose}
                                                                        />

                                                                        <Divider className="mt-22" />

                                                                        <div className="mt-22">
                                                                            <Grid container justify="center" spacing={3}>
                                                                                <Grid item xs={12}>
                                                                                    <div className="d-grid">
                                                                                        <Link
                                                                                            className={"site-edit-btn"}
                                                                                            to={NETWORK_CHANNEL_SETUP_SHORT + 1}
                                                                                        >
                                                                                            {t('btn-edit')}
                                                                                        </Link>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </CardContent>
                                                                </>

                                                            </Card>
                                                        </Grid>
                                                        <Grid item md={5} xs={12} className="m-auto">
                                                            <Grid container spacing={3} className="mobile-offer-boxes-padding">
                                                                <Grid item xs={6}>
                                                                    <Paper className="offer-boxes">
                                                                        <ColAtm sm={12} className="pt-26">
                                                                            <i className="fas fa-calendar-check green fs-22 bg-icon-green m-auto b-r-50" />
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                    <span className="payment-info-text m-auto f-w-bold">
                                                                         {
                                                                             bookingEngineReducer.fetching ?
                                                                                 <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                                 (
                                                                                     <>{bookingEngineReducer.bookingEngineData.success_booking_count}</>
                                                                                 )
                                                                         }
                                                                    </span>
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                            <span className="offer-total-title">  { t('enw-booking_engine-dashboard-successful_bookings') } </span>
                                                                        </ColAtm>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Paper className="offer-boxes">
                                                                        <ColAtm sm={12} className="pt-26">
                                                                            <i className="fas fa-ballot-check orange fs-22 bg-icon-orange m-auto b-r-50" />
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                    <span className="payment-info-text m-auto f-w-bold">
                                                                          {
                                                                              bookingEngineReducer.fetching ?
                                                                                  <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                                  (
                                                                                      <>{bookingEngineReducer.bookingEngineData.all_booking_count}</>
                                                                                  )
                                                                          }
                                                                    </span>
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                            <span className="offer-total-title"> { t('enw-booking_engine-dashboard-all_booking') } </span>
                                                                        </ColAtm>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Paper className="offer-boxes">
                                                                        <ColAtm sm={12} className="pt-26">
                                                                            <i className="fas fa-funnel-dollar purple fs-22 bg-icon-purple m-auto b-r-50" />
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                    <span className="payment-info-text m-auto f-w-bold">
                                                                          {
                                                                              bookingEngineReducer.fetching ?
                                                                                  <CircularProgress className="circularProgress-be-page"  color="secondary"/> :
                                                                                  (
                                                                                      <>{bookingEngineReducer.bookingEngineData.conversion_rate}</>
                                                                                  )
                                                                          }
                                                                    </span>
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                            <span className="offer-total-title"> { t('enw-booking_engine-dashboard-conversion_count') } </span>
                                                                        </ColAtm>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Paper className="offer-boxes">
                                                                        <ColAtm sm={12} className="pt-26">
                                                                            <i className="fas fa-users blue fs-22 bg-icon-blue m-auto b-r-50" />
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                    <span className="payment-info-text m-auto f-w-bold">
                                                                          {
                                                                              bookingEngineReducer.fetching ?
                                                                                  <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                                                  (
                                                                                      <>{bookingEngineReducer.bookingEngineData.total_pax_count}</>
                                                                                  )
                                                                          }
                                                                    </span>
                                                                        </ColAtm>
                                                                        <ColAtm sm={12}>
                                                                            <span className="offer-total-title"> { t('enw-booking_engine-dashboard-guest_count') } </span>
                                                                        </ColAtm>
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </Grid>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <BottomNavigation className="m-navigation" value="booking">
                        <BottomNavigationAction value="content" icon={<i className="fas fa-hotel" />} onClick={() => { history.push(CONTENT)}}  />
                        <BottomNavigationAction value="network" icon={<i className="fas fa-chart-network" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ?isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#")}} />
                        <BottomNavigationAction value="offer" icon={<i className="fas fa-paperclip" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ?isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? OFFER : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#")}} />
                        <BottomNavigationAction value="web" icon={<i className="fas fa-network-wired" />} onClick={() => { history.push(!this.props.propertyProductsReducer.fetching ?isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? WEB :  NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#")}} />
                        <BottomNavigationAction value="booking" icon={<i className="fas fa-calendar-alt" />} />
                        <BottomNavigationAction value="payment" icon={<i className="fas fa-money-bill-wave-alt" />} onClick={() => {history.push(!this.props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? PAYMENT :  NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#")}} />
                    </BottomNavigation>
                </Hidden>
            </>
        )
    }
}
const mapStateToProps = (props) => {
    return {
        profileReducer : props.profileReducer,
        bookingEngine: props.BookingEngineDashboardReducers.bookingEngineData,
        bookingEngineReducer: props.BookingEngineDashboardReducers,
        propertyProductsReducer : props.propertyProductsReducer
    }
};

const mapDispatchToProps = {
    getProfile,
    getBookingEngineDashboard,
    propertyProducts
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(CreateWeb));
