import {inventoryConstants} from "../../../../constants/Network/inventory.constants";


const initialState = {
    fetching:false,
    inventory : [],
    inventoryUpdate : {},
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryConstants.PROPERTY_INVENTORY_GET_PENDING:
            return {
                ...state,
                fetching: true,
                inventory:[],
                inventoryUpdate : {},
                error : {},
            };
        case inventoryConstants.PROPERTY_INVENTORY_GET_FULFILLED:
            return {
                ...state,
                fetching: false,
                inventory : action.payload
            };
        case inventoryConstants.PROPERTY_INVENTORY_GET_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };

            // UPDATE OR CREATE
        case inventoryConstants.PROPERTY_INVENTORY_UPDATE_OR_CREATE_PENDING:
            return {
                ...state,
                fetching : false,
                inventoryUpdate : {},
                error : {}
            };
        case inventoryConstants.PROPERTY_INVENTORY_UPDATE_OR_CREATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                inventoryUpdate : action.payload
            };
        case inventoryConstants.PROPERTY_INVENTORY_UPDATE_OR_CREATE_REJECTED:
            return {
                ...state,
                fetching : false,
                error    : action.payload
            };
        default:
            return { ...state};
    }
}
