import React, {Component} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";

let t =null;

class TextCounter extends Component {
    constructor(props) {
        super(props);
        t = props.t;
        this.state = {
            aboutUsMaxCharCount : 4000,
            aboutUsCharCount : props.about && Object.keys(props.about).length > 0  ? props.about.map(value =>{
                return value.value === null ? 0 : value.value.length
            }) : [],
            index : props.index,
            content : props.title,
            aboutUs : props.about,
            changeComponents : props.changeComponents,
            changeExist : false
        };

    }


    handleChangeCharCount = (index) => (e) => {

        let tempCount = this.state.aboutUsCharCount;
        tempCount[index] = e.target.value.length;

        this.setState({
            aboutUsCharCount:tempCount,
            changeComponents: true
        });

        let values = this.state.aboutUs;
        values[index]["value"] = e.target.value;

        if(!this.state.changeExist){
            this.props.changeComponentsWithTextCounter();
            this.setState({
                changeExist: true
            });
        }

    };


    render() {
        return (
           <>
               <TextField name={this.state.content.language_code}
                          type="text"
                          variant="outlined"
                          className="text-capitalize"
                          value={this.state.content.value}
                          onChange={this.handleChangeCharCount(this.state.index)}
                          label={ t('enw-myweb-edit-about_us-title') + " - " + this.state.content.language_code }
                          multiline={true}
                          fullWidth
                          required={false}
                          rows={9}
                          inputProps={{maxLength : this.state.aboutUsMaxCharCount}}
               />
               <div style={{ width: '100%'}}>
                   <p style={{ float : 'right'}}> { this.state.aboutUsCharCount[this.state.index] }/ {this.state.aboutUsMaxCharCount}</p>
               </div>
           </>
        );
    }
};

export default compose(withTranslation('common')) (TextCounter);
