import {
    getAwardsCertificates,
    updateAwardsCertificates,
    getAwardsCertificatesCategories,
    deleteAwardsCertificatesPhoto,
    createAwardsCertificates,
    clearAwardsCertificatesError,
    awardsCertificatesUpdateStatus
} from "../../../../constants/Content/awardsCertificates.constants";

const initialState = {
    awardsCertificatesListFetching : false,
    awardsCertificatesListError : {},
    awardsCertificatesList: [],

    awardsCertificatesCategoriesFetching : false,
    awardsCertificatesCategoriesError : {},
    awardsCertificatesCategories: [],

    awardsCertificatesCreateFetching : false,
    awardsCertificatesCreateError : {},
    awardsCertificatesCreate: [],

    awardsCertificatesUpdateFetching : false,
    awardsCertificatesUpdateError : {},
    awardsCertificatesUpdate: [],

    awardsCertificatesDeletePhotoFetching : false,
    awardsCertificatesDeletePhotoError : {},
    awardsCertificatesDeletePhotoUpdate: [],

    awardsCertificatesUpdateStatusFetching : false,
    awardsCertificatesUpdateStatusError : {},
    awardsCertificatesStatusUpdate: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        //GET LIST
        case getAwardsCertificates.GET_AWARDS_CERTIFICATES_PENDING:
            return {
                ...state,
                awardsCertificatesListFetching: true,
                awardsCertificatesListError: {},
                awardsCertificatesStatusUpdate: {},
                awardsCertificatesUpdateStatusError: {},
            };
        case getAwardsCertificates.GET_AWARDS_CERTIFICATES_FULFILLED:
            return {
                ...state,
                awardsCertificatesListFetching: false,
                awardsCertificatesList: action.payload,
            };
        case getAwardsCertificates.GET_AWARDS_CERTIFICATES_REJECTED:
            return {
                ...state,
                awardsCertificatesListError: action.payload,
                awardsCertificatesListFetching: false
            };

            //GET CATEGORIES
        case getAwardsCertificatesCategories.GET_AWARDS_CERTIFICATES_CATEGORIES_PENDING:
            return {
                ...state,
                awardsCertificatesCategoriesFetching: true,
                awardsCertificatesCategoriesError: {}
            };
        case getAwardsCertificatesCategories.GET_AWARDS_CERTIFICATES_CATEGORIES_FULFILLED:
            return {
                ...state,
                awardsCertificatesCategoriesFetching: false,
                awardsCertificatesCategories: action.payload,
            };
        case getAwardsCertificatesCategories.GET_AWARDS_CERTIFICATES_CATEGORIES_REJECTED:
            return {
                ...state,
                awardsCertificatesCategoriesError: action.payload,
                awardsCertificatesCategoriesFetching: false
            };

            //Create
        case createAwardsCertificates.CREATE_AWARDS_CERTIFICATES_PENDING:
            return {
                ...state,
                awardsCertificatesCreateFetching: true,
                awardsCertificatesCreateError: {}
            };
        case createAwardsCertificates.CREATE_AWARDS_CERTIFICATES_FULFILLED:
            return {
                ...state,
                awardsCertificatesCreateFetching: false,
                awardsCertificatesCreate: action.payload,
            };
        case createAwardsCertificates.CREATE_AWARDS_CERTIFICATES_REJECTED:
            return {
                ...state,
                awardsCertificatesCreateError: action.payload,
                awardsCertificatesCreateFetching: false
            };

            //UPDATE
        case updateAwardsCertificates.UPDATE_AWARDS_CERTIFICATES_PENDING:
            return {
                ...state,
                awardsCertificatesUpdateFetching: true,
                awardsCertificatesUpdateError: {}
            };
        case updateAwardsCertificates.UPDATE_AWARDS_CERTIFICATES_FULFILLED:
            return {
                ...state,
                awardsCertificatesUpdateFetching: false,
                awardsCertificatesUpdate: action.payload,
            };
        case updateAwardsCertificates.UPDATE_AWARDS_CERTIFICATES_REJECTED:
            return {
                ...state,
                awardsCertificatesUpdateError: action.payload,
                awardsCertificatesUpdateFetching: false
            };

            //DELETE
        case deleteAwardsCertificatesPhoto.DELETE_AWARDS_CERTIFICATES_PHOTO_PENDING:
            return {
                ...state,
                awardsCertificatesDeletePhotoFetching: true,
                awardsCertificatesDeletePhotoError: {}
            };
        case deleteAwardsCertificatesPhoto.DELETE_AWARDS_CERTIFICATES_PHOTO_FULFILLED:
            return {
                ...state,
                awardsCertificatesDeletePhotoFetching: false,
                awardsCertificatesDeletePhotoUpdate: action.payload,
            };
        case deleteAwardsCertificatesPhoto.DELETE_AWARDS_CERTIFICATES_PHOTO_REJECTED:
            return {
                ...state,
                awardsCertificatesDeletePhotoError: action.payload,
                awardsCertificatesDeletePhotoFetching: false
            };
            //UPDATE STATUS
        case awardsCertificatesUpdateStatus.AWARDS_CERTIFICATES_UPDATE_STATUS_PENDING:
            return {
                ...state,
                awardsCertificatesUpdateStatusFetching: true,
                awardsCertificatesUpdateStatusError: {}
            };
        case awardsCertificatesUpdateStatus.AWARDS_CERTIFICATES_UPDATE_STATUS_FULFILLED:
            return {
                ...state,
                awardsCertificatesUpdateStatusFetching: false,
                awardsCertificatesStatusUpdate: action.payload,
            };
        case awardsCertificatesUpdateStatus.AWARDS_CERTIFICATES_UPDATE_STATUS_REJECTED:
            return {
                ...state,
                awardsCertificatesUpdateStatusError: action.payload,
                awardsCertificatesUpdateStatusFetching: false
            };
        case clearAwardsCertificatesError.AWARDS_CERTIFICATES_CLEAR_ERROR_STATE:
            return {
                ...state,
                awardsCertificatesCreate: [],
                awardsCertificatesUpdate: [],
                awardsCertificatesStatusUpdate: [],
                awardsCertificatesDeletePhotoError: {},
                awardsCertificatesUpdateStatusError: {},
                awardsCertificatesCreateError: {},
                awardsCertificatesUpdateError: {},
            };
        default:
            return state
    }
}
