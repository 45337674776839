import { getUserToken,getProperty ,getAppLang}                 from "../../utils/index";
import { GET_BRAND,UPDATE_BRAND, DELETE_BRAND_LOGO }                 from "../../constants/api.constants";
import axios                            from 'axios';

export function getBrand() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'BRAND',
            payload: axios.post(GET_BRAND, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function onBrandSubmit(params) {

    const formData = new FormData();

    formData.append( 'property_id', getProperty());
    if (params.photo != "") {
        formData.append('photo', params.photo, params.photo.name);
    }
    formData.append( 'title', JSON.stringify(params.title));
    formData.append( 'color_code_1', params.color_code_1);
    formData.append( 'color_code_2', params.color_code_2);
    formData.append( 'color_code_3', params.color_code_3);
    formData.append( 'colors', JSON.stringify(params.colors));

    const requestOptions = {
        headers: { 'Content-Type': 'multipart/form-data',  'authToken': getUserToken(), 'language' : getAppLang()},
        data: formData,
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_BRAND',
            payload: axios.post(UPDATE_BRAND, requestOptions.data, {headers: requestOptions.headers})
        })
    }
}

export function clearBrandLogo() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'DELETE_BRAND_LOGO',
            payload: axios.post(DELETE_BRAND_LOGO, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
