import axios from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../utils/index";
import {GET_PROPERTY_CONTACT,UPDATE_PROPERTY_CONTACT } from "../../constants/api.constants";

export function getContactList() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PROPERTY_CONTACT',
            payload: axios.post(GET_PROPERTY_CONTACT, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateContact(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty(),
                "contact":
                    {
                        "address"  : param.address,
                        "latitude" : parseFloat(param.latitude),
                        "longitude": parseFloat(param.longitude),
                    }
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_PROPERTY_CONTACT',
            payload: axios.post(UPDATE_PROPERTY_CONTACT, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}