import {inventoryLink} from "../../../../constants/Network/inventoryLink.constants";

const initialState = {
    fetching: false,
    inventoryLink : [],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryLink.PROPERTY_POST_INVENTORY_LINK_PENDING:
            return {
                ...state,
                fetching: true,
                inventoryLink: [],
                error : {}
            };
        case inventoryLink.PROPERTY_POST_INVENTORY_LINK_FULFILLED:
            return {
                ...state,
                fetching: false,
                inventoryLink : action.payload
            };
        case inventoryLink.PROPERTY_POST_INVENTORY_LINK_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case inventoryLink.PROPERTY_POST_INVENTORY_LINK_CLEAR:
            return {
                inventoryLink: [],
                error : {}
            };

        default:
            return { ...state};
    }
}
