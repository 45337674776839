import React from "react";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import ColAtm from "../../../../../atomic/Col/Col";
import BlockAtm from "../../../../../atomic/Block/Block";
import LabelAtm from "../../../../../atomic/Label/Label";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import {MenuItem} from "@material-ui/core";
import IconAtm from "../../../../../atomic/Icon/Icon";
import Typography from "@material-ui/core/Typography";
import {compose} from "redux";
import ChannelSetupStatus from "./ChannelSetupStatus";
import {ValidatorForm, SelectValidator, TextValidator} from 'react-material-ui-form-validator';
import ButtonAtm from "../../../../../atomic/Button/Button";
import {isDefined} from "../../../../../../utils";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";


let t =null;
class ChannelSetupForm extends React.Component {
    constructor(props){
        super(props);
        t = props.t;
    }
    state = {
        booking: [{}],
        availability: [{}],
        room_price_type: [{}],
        is_selected: "",
        channel_id: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel_id : '',
        currency_code: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.currency : '',
        parent_id: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.parent_id : '',
        channel_category_id: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.channel_category_id : '',
        currency_list: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.currency_list : [],
        contract_file: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.contract_file_url : [],
        connected_channel_id: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.connected_channel_id : null,
        connected_channel_action: this.props.channelSetup.channelSetupData.data && isDefined(this.props.channelSetup.channelSetupData.data.channel.connected_channel_action) ? JSON.parse(this.props.channelSetup.channelSetupData.data.channel.connected_channel_action) : {action_type:null, type:null, value:null},
        contract_file_path: "",
        askSetupType: null,
    };

    componentDidMount() {
        let booking = [];
        let availability = [];
        let room_price_type = [];

        if(this.props.channelSetup.channelSetupData.length  !== 0 ) {

            Object.keys(this.props.channelSetup.channelSetupData.data).map( key => {

                switch (key) {
                    case 'booking':
                        this.props.channelSetup.channelSetupData.data[key].length !== 0 ?
                            booking = this.props.channelSetup.channelSetupData.data[key].map(book => {
                                return{
                                    language_key: book.language_key,
                                    booking_type_id: book.booking_type_id,
                                    icon: book.icon,
                                    is_selected: book.is_selected
                                }
                            })
                            :
                            booking = [{
                                language_key: '',
                                booking_type_id: '',
                                icon: '',
                                is_selected: false

                            }]
                        break;
                    case 'availability':
                        this.props.channelSetup.channelSetupData.data[key].length !== 0 ?
                            availability = this.props.channelSetup.channelSetupData.data[key].map(avail => {
                                return{
                                    language_key: avail.language_key,
                                    availability_type_id: avail.availability_type_id,
                                    icon: avail.icon,
                                    is_selected: avail.is_selected,
                                }
                            })
                            :
                            availability = [{
                                language_key: '',
                                availability_type_id: '',
                                icon: '',
                                is_selected: '',
                            }]
                        break;
                    case 'room_price_type':
                        this.props.channelSetup.channelSetupData.data[key].length !== 0 ?
                            room_price_type = this.props.channelSetup.channelSetupData.data[key].map(roomPriceType => {
                                return{
                                    language_key: roomPriceType.language_key,
                                    room_pricing_type_id: roomPriceType.room_pricing_type_id,
                                    icon: roomPriceType.icon,
                                    is_selected: roomPriceType.is_selected,
                                }
                            })
                            :
                            room_price_type = [{
                                language_key: '',
                                room_pricing_type_id: '',
                                icon: '',
                                is_selected: '',
                            }]
                        break;
                }
            })
        }

        const channelAction = this.props.channelSetup.channelSetupData.data
            && JSON.parse(this.props.channelSetup.channelSetupData.data.channel.connected_channel_action)

        if (this.props.channelSetup.channelSetupData.data.channel.connected_channel_id !== 5 &&
            channelAction && isDefined(channelAction.action_type) && isDefined(channelAction.type) && isDefined(channelAction.value)) {
            this.setState({askSetupType: true})
        }

        this.setState({
            booking: booking.length > 0 ? booking : {
                language_key: '',
                booking_type_id: '',
                icon: '',
                is_selected: false
            },
            availability: availability.length > 0 ? availability : {
                language_key: "",
                availability_type_id: "",
                icon: "",
                is_selected: false,
            },
            room_price_type : room_price_type.length > 0 ? room_price_type : {
                language_key: '',
                room_pricing_type_id: '',
                icon: '',
                is_selected: false,
            },

        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let tempBooking = this.state.booking
        tempBooking = tempBooking.filter(book => book.is_selected && book )

        let tempAvailability = this.state.availability
        tempAvailability = tempAvailability.filter(avail => avail.is_selected && avail )

        let tempRoomPriceType = this.state.room_price_type
        tempRoomPriceType = tempRoomPriceType.filter(roomPriceType => roomPriceType.is_selected && roomPriceType )

        let tempCurrency = this.state.currency_code

        const params = {
            booking_type_id: !this.state.askSetupType ?  isDefined(tempBooking[0]) ? tempBooking[0]['booking_type_id'] : null : null,
            availability_type_id: !this.state.askSetupType ?  isDefined(tempAvailability[0]) ? tempAvailability[0]['availability_type_id'] : null : null,
            room_pricing_type_id: !this.state.askSetupType ? isDefined(tempRoomPriceType[0]) ? tempRoomPriceType[0]['room_pricing_type_id'] : null : null,
            currency_code: !this.state.askSetupType ? tempCurrency : null,
            channel_id: this.state.channel_id,
            contract_file: this.state.contract_file,
            connected_channel_id: this.state.connected_channel_id !== 5 ? this.state.askSetupType ? this.state.parent_id : null : this.state.connected_channel_id,
            connected_channel_action: this.state.connected_channel_id !== 5 ? this.state.askSetupType && isDefined(this.state.connected_channel_action) ? this.state.connected_channel_action : null : {"action_type":"INC","type":"FIX","value":"0"},
        }
        this.props.addChannelSetup(params);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

    };

    handleBookClick = (e, index) => {
        if( e.target.value === 'on') {
            let temp_booking_mapping = this.state.booking;

            temp_booking_mapping[index]['is_selected'] = true;

            temp_booking_mapping.map( (book,key) => {
                if(key !== index && book.is_selected === true){
                    temp_booking_mapping[key]['is_selected'] = false;
                }
            } );

            this.setState({
                 booking : temp_booking_mapping
             });
        }
    }

    handleChangeConnectedChannel = (e) => {
        if( e.target.checked) {
            this.setState({
                connected_channel_id : 5
             });
        } else {
            this.setState({
                connected_channel_id : null,
                connected_channel_action: null
            });
        }
    }

    handleRoomPricingTypeClick = (e, index) => {
        if( e.target.value === 'on') {
            let temp_room_pricing_mapping = this.state.room_price_type;

            temp_room_pricing_mapping[index]['is_selected'] = true;

            temp_room_pricing_mapping.map( (book,key) => {
                if(key !== index && book.is_selected === true){
                    temp_room_pricing_mapping[key]['is_selected'] = false;
                }
            } );

            this.setState({
                room_price_type : temp_room_pricing_mapping
            });
        }
    }

    handleAvailabilityClick = (e, index) => {
        if( e.target.value === 'on') {
            let temp_availability_mapping = this.state.availability;

            temp_availability_mapping[index]['is_selected'] = true;

            temp_availability_mapping.map( (avail,key) => {
                if(key !== index && avail.is_selected === true){
                    temp_availability_mapping[key]['is_selected'] = false;
                }
            } );

            this.setState({
                availability : temp_availability_mapping
            });
        }
    }

    selectCurrencyType = () => {
        const { currency_list } = this.state;

        if( currency_list  && currency_list.length > 0 ){

            return currency_list.map( (currency,key) => (
                    <MenuItem value={currency}  key={key}>
                        {currency}
                    </MenuItem>
                )
            );

        }else{

            return <LabelAtm variant="h6" component="h2" text={ t('enw-notification-no_results') } /> //TODO : bu kısımlar error olarak düzeltilmesi gerekiyor
        }
    };

    onFileChange = event => {
        const file = event.target.files[0];

        if (isDefined(file)) {
            this.setState({contract_file_path: URL.createObjectURL(file), contract_file: event.target.files[0]})
        }
    };

    handlePaymentChange = (e) => {
        const {name, value} = e.target;
        const list = this.state.connected_channel_action;

        list[name] = value;
        this.setState({
            connected_channel_action : list,
        })
    };

    handleSelectChannel = () => {
        this.setState({
            askSetupType : false,
            connected_channel_id: null,
            connected_channel_action: {action_type:null, type:null, value:null}
        })

    };

    handleSelectChildChannel = () => {

        if (this.state.connected_channel_id !== 5) {
            this.setState({
                askSetupType : true
            })
        }
    };


    render() {
        const {contract_file, contract_file_path, channel_id, connected_channel_action, parent_id, booking, availability, room_price_type, askSetupType, channel_category_id, connected_channel_id} = this.state;

        const channelConnectedStatus = isDefined(connected_channel_action) && connected_channel_action.action_type !== null
            && connected_channel_action.type !== null
            && connected_channel_action.value !== null

        const bookingIsSelected = isDefined(booking) && booking.filter(item => item.is_selected === true)
        const availabilityIsSelected = availability.filter(item => item.is_selected === true)
        const roomIsSelected = room_price_type.filter(item => item.is_selected === true)

        return (
            <div>
                <div className="m-5">
                    <ValidatorForm onSubmit={this.onSubmit} ref={"form"} onError={errors => console.log(errors)}>
                        {
                            parent_id === 1 && channel_category_id === 3 && !channelConnectedStatus &&
                            bookingIsSelected.length === 0 &&
                            availabilityIsSelected.length === 0 &&
                            roomIsSelected.length === 0 && askSetupType === null ?
                                <>
                                    <Grid container className="mb-16">
                                        <Grid item xs={12}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t("enw-channels-channel_setup-title") } />
                                                        </ColAtm>
                                                        <Grid container sm={12} className="availability-form-box">
                                                            <Grid>
                                                                {t("enw-channels-channel_setup-desc")}
                                                            </Grid>
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <BlockAtm md={12} className="t-center">
                                        <ButtonAtm
                                            className="room-rate-modal-save room-rate-save bg-dark-blue m-auto"
                                            buttontext={ t("enw-channels-channel_setup-independent-btn")}
                                            color="primary"
                                            onClick={this.handleSelectChannel}
                                        />
                                        <ButtonAtm
                                            className="room-rate-modal-save room-rate-save bg-dark-blue m-auto"
                                            buttontext={ t("enw-channels-channel_setup-booking-engine-btn") }
                                            color="primary"
                                            onClick={this.handleSelectChildChannel}
                                        />
                                    </BlockAtm>
                                </>

                            :

                            (channelConnectedStatus || askSetupType) && (connected_channel_id !== 5 && connected_channel_id !== null) ?

                                <>
                                    <Grid container className="mb-16">
                                        <Grid item xs={12}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t("enw-channels-channel_setup-booking-engine-btn") } />
                                                        </ColAtm>
                                                        <Grid container sm={12} className="availability-form-box">
                                                            <Grid item xs={12}>
                                                                <Grid container="mobile-no-spacing" spacing={3}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <SelectValidator
                                                                            name="action_type"
                                                                            className="form-control"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={ t('enw-input-setup_type') }
                                                                            value={isDefined(connected_channel_action) && connected_channel_action.action_type}
                                                                            onChange={e => this.handlePaymentChange(e)}
                                                                            validators={['required']}
                                                                            errorMessages={[t("enw-notification_this_is_required")]}
                                                                        >
                                                                            <MenuItem value={"INC"}>{ t('enw-input-increase') }</MenuItem>
                                                                            <MenuItem value={"DEC"}>{ t('enw-input-decrease') }</MenuItem>
                                                                        </SelectValidator>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <SelectValidator
                                                                            name="type"
                                                                            className="form-control"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={ t('enw-input-value_type') }
                                                                            value={isDefined(connected_channel_action) && connected_channel_action.type}
                                                                            onChange={e => this.handlePaymentChange(e)}
                                                                            validators={['required']}
                                                                            errorMessages={[t("enw-notification_this_is_required")]}
                                                                        >
                                                                            <MenuItem value={"PER"}>{ t('enw-input-percent') }</MenuItem>
                                                                            <MenuItem value={"FIX"}>{ t('enw-input-fixed') }</MenuItem>
                                                                        </SelectValidator>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextValidator
                                                                            name="value"
                                                                            type="text"
                                                                            variant="outlined"
                                                                            label={ t('enw-input-value') }
                                                                            autoComplete="off"
                                                                            value={isDefined(connected_channel_action) && connected_channel_action.value}
                                                                            onChange={e => this.handlePaymentChange(e)}
                                                                            labelWidth={70}
                                                                            validators={['required']}
                                                                            errorMessages={[t("enw-notification_this_is_required")]}
                                                                            fullWidth
                                                                            InputProps={{
                                                                                endAdornment: <>
                                                                                    {
                                                                                        isDefined(connected_channel_action) && connected_channel_action.type === "PER" ?
                                                                                            <InputAdornment position="start">
                                                                                                %
                                                                                            </InputAdornment>
                                                                                            :
                                                                                            isDefined(connected_channel_action) && connected_channel_action.type === "FIX" ?
                                                                                                <InputAdornment position="start">
                                                                                                    {this.state.currency_code}
                                                                                                </InputAdornment>
                                                                                                :
                                                                                                <InputAdornment position="start">

                                                                                                </InputAdornment>
                                                                                    }
                                                                                </>
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container>

                                        <BlockAtm md={12} className="t-center m-auto">
                                            {
                                                parent_id === 1 && channel_category_id === 3 &&
                                                <ButtonAtm
                                                    className="room-rate-modal-save room-rate-save bg-dark-blue m-auto"
                                                    buttontext={ t("enw-channels-channel_setup-independent-btn") }
                                                    color="primary"
                                                    onClick={this.handleSelectChannel}
                                                />
                                            }

                                            <ButtonAtm
                                                className="room-rate-modal-save room-rate-save bg-red m-auto"
                                                buttontext={ t('btn-complete') }
                                                color="primary"
                                                type="submit"
                                            />
                                        </BlockAtm>

                                    </Grid>
                                </>

                                :
                                <>
                                    <Grid container className="mb-16">
                                        {
                                            channel_id === "1" &&
                                            <Grid item xs={12} md={3}>
                                                <Card className="w-100 channel-border">
                                                    <CardContent>
                                                        <ColAtm>
                                                            <ColAtm className="mb-16">
                                                                <LabelAtm variant="h6" component="h2" text={ t('enw-channels-channel_setup-room_policy-title') } />
                                                            </ColAtm>
                                                            <Grid container className="policy-channel-form-box">
                                                                <Grid className="h-77">

                                                                    {
                                                                        !isDefined(contract_file)  ?
                                                                            <p>
                                                                                { t('enw-channels-channel_setup-room_policy-desc') }
                                                                            </p>
                                                                            :
                                                                            <p className="awards-preview-box fs-36">
                                                                                <i className="fas fa-file-pdf" />
                                                                                <a href={isDefined(contract_file_path) ? contract_file_path : contract_file} className="fs-18" target="_blank" rel="noopener noreferrer"> { t('btn-view') } </a>
                                                                            </p>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} className="brand-logo-upload mobile-awards-order-1">
                                                                    <input
                                                                        className="d-none"
                                                                        accept=".pdf"
                                                                        id="contained-button-file"
                                                                        multiple
                                                                        type="file"
                                                                        onChange={this.onFileChange}
                                                                    />
                                                                    <label htmlFor="contained-button-file">
                                                                        <Button
                                                                            className="soft-blue-button m-auto radius-8 w-100"
                                                                            component="span"
                                                                            startIcon={<i className="fas fa-cloud-upload-alt" />}>
                                                                            { t('btn-upload_pdf') }
                                                                        </Button>
                                                                    </label>
                                                                </Grid>
                                                            </Grid>
                                                        </ColAtm>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={channel_id === "1" ? 3 : 6}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t('enw-input-currency') } />
                                                        </ColAtm>
                                                        <Grid container className="currency-channel-form-box">
                                                            <SelectValidator
                                                                value={this.state.currency_code}
                                                                onChange={(e) => this.setState({currency_code: e.target.value}) }
                                                                className="form-control"
                                                                label={ t("enw-input-currency") }
                                                                variant="outlined"
                                                                fullWidth
                                                                validators={['required']}
                                                                errorMessages={[ t('enw-notification_this_is_required') ]}
                                                            >
                                                                {this.selectCurrencyType()}
                                                            </SelectValidator>
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        {
                                            channel_id === "1" &&
                                            <Grid item xs={12} md={2}>
                                                <Card className="w-100 channel-border">
                                                    <CardContent>
                                                        <ColAtm>
                                                            <ColAtm className="mb-16">
                                                                <LabelAtm variant="h6" component="h2" text={ t('enw-channel-setup_connected-channel-title') } />
                                                            </ColAtm>
                                                            <Grid container className="connected-channel-form-box">
                                                                <Grid item xs={6} sm={3} md={4}>
                                                                    <ColAtm>
                                                                        <BlockAtm className={"offer-fact-border-box fact-border-box gray-border"}>
                                                                            <input type="checkbox" name="connectedChannel"
                                                                                   className="channel-radio"
                                                                                   id={"connectedChannel"}
                                                                                   defaultChecked={connected_channel_id === 5}
                                                                                   onClick={(e) => { this.handleChangeConnectedChannel(e) }}
                                                                            />
                                                                            <label htmlFor={"connectedChannel"}>
                                                                                <label htmlFor={"connectedChannel"}>
                                                                                    <IconAtm iconClass={`offer-fact-icon fact-icon fas fa-link`} />
                                                                                    <BlockAtm className={"fact-icon-label"}>
                                                                                        <Typography noWrap className={"fact-name"} htmlFor={"connectedChannel"}> <i className="fas fa-check-circle blue" /> {t("enw-channel-setup_connected-channel-box")} </Typography>
                                                                                    </BlockAtm>
                                                                                </label>
                                                                            </label>
                                                                        </BlockAtm>
                                                                    </ColAtm>
                                                                </Grid>
                                                            </Grid>
                                                        </ColAtm>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={channel_id === "1" ? 4 : 6}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t('enw-channels-channel_setup-booking-title') } />
                                                        </ColAtm>
                                                        <Grid container sm={12} className="availability-form-box">
                                                            {this.state.booking.map((book, i) => {
                                                                return (
                                                                    <Grid item xs={6} md={channel_id === "1" ? 6 : 4}>
                                                                        <ColAtm>
                                                                            <BlockAtm className={"offer-fact-border-box fact-border-box gray-border"}>
                                                                                <input type={"radio"} name="booking"
                                                                                       className="channel-radio"
                                                                                       id={book.booking_type_id+"channel"}
                                                                                       defaultChecked={ book.is_selected }
                                                                                       onClick={(e) => { this.handleBookClick(e, i ) }}
                                                                                />
                                                                                <label htmlFor={book.booking_type_id+"channel"}>
                                                                                    <label htmlFor={book.booking_type_id+"channel"}>
                                                                                        <IconAtm iconClass={`offer-fact-icon fact-icon ${book.icon}`} />
                                                                                        <BlockAtm className={"fact-icon-label"}>
                                                                                            <Typography noWrap className={"fact-name"} htmlFor={book.booking_type_id}> <i className="fas fa-check-circle blue" /> {t(book.language_key)} </Typography>
                                                                                        </BlockAtm>
                                                                                    </label>
                                                                                </label>
                                                                            </BlockAtm>
                                                                        </ColAtm>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t("enw-room_and_rates-assign_rate-checkbox_title-availability") } />
                                                        </ColAtm>
                                                        <Grid container sm={12} className="availability-form-box">
                                                            {this.state.availability.map((avail, i) => {
                                                                return (
                                                                    <Grid item xs={6} sm={3} md={4}>
                                                                        <BlockAtm className={"offer-fact-border-box fact-border-box gray-border"}>
                                                                            <input type={"radio"} name="availability"
                                                                                   className="channel-radio"
                                                                                   id={avail.availability_type_id+"availability"}
                                                                                   defaultChecked={ avail.is_selected }
                                                                                   onClick={(e) => { this.handleAvailabilityClick(e, i) }}
                                                                            />
                                                                            <label htmlFor={avail.availability_type_id+"availability"}>
                                                                                <label htmlFor={avail.availability_type_id+"availability"}>
                                                                                    <IconAtm iconClass={`offer-fact-icon fact-icon ${avail.icon}`} />
                                                                                    <BlockAtm className={"fact-icon-label"}>
                                                                                        <Typography noWrap className={"fact-name"} htmlFor={avail.availability_type_id+"availability"}> <i className="fas fa-check-circle blue" /> {t(avail.language_key)} </Typography>
                                                                                    </BlockAtm>
                                                                                </label>
                                                                            </label>
                                                                        </BlockAtm>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card className="w-100 channel-border">
                                                <CardContent>
                                                    <ColAtm>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h2" text={ t('enw-channels-channel_setup-room_pricing-title') } />
                                                        </ColAtm>
                                                        <Grid container sm={12} className="availability-form-box">
                                                            {this.state.room_price_type.map((room_price_type, i) => {
                                                                return (
                                                                    <Grid item xs={6} sm={3} md={4}>
                                                                        <ColAtm>
                                                                            <BlockAtm className={"offer-fact-border-box fact-border-box gray-border"}>
                                                                                <input type={"radio"} name="room_price_type"
                                                                                       className="channel-radio"
                                                                                       id={room_price_type.room_pricing_type_id+"room_pricing"}
                                                                                       defaultChecked={ room_price_type.is_selected }
                                                                                       onClick={(e) => { this.handleRoomPricingTypeClick(e, i ) }}
                                                                                />
                                                                                <label htmlFor={room_price_type.room_pricing_type_id+"room_pricing"}>
                                                                                    <label htmlFor={room_price_type.room_pricing_type_id+"room_pricing"}>
                                                                                        <IconAtm iconClass={`offer-fact-icon fact-icon ${room_price_type.icon}`} />
                                                                                        <BlockAtm className={"fact-icon-label"}>
                                                                                            <Typography noWrap className={"fact-name"} htmlFor={room_price_type.room_pricing_type_id}> <i className="fas fa-check-circle blue" /> {t(room_price_type.language_key)} </Typography>
                                                                                        </BlockAtm>
                                                                                    </label>
                                                                                </label>
                                                                            </BlockAtm>
                                                                        </ColAtm>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </ColAtm>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container>

                                        <BlockAtm md={12} className="t-center m-auto">
                                            {
                                                parent_id === 1 && channel_category_id === 3 &&
                                                <ButtonAtm
                                                    className="room-rate-modal-save room-rate-save bg-dark-blue m-auto"
                                                    buttontext={ t("enw-channels-channel_setup-booking-engine-btn") }
                                                    color="primary"
                                                    onClick={this.handleSelectChildChannel}
                                                />
                                            }

                                            <ButtonAtm
                                                className="room-rate-modal-save room-rate-save bg-red m-auto"
                                                buttontext={ t('btn-complete') }
                                                color="primary"
                                                type="submit"
                                            />
                                        </BlockAtm>

                                    </Grid>
                                </>

                        }

                        <ChannelSetupStatus channelSetup = {this.props.channelSetup} />
                    </ValidatorForm>
                </div>
            </div>
        );
    }
}

ChannelSetupForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(withTranslation('common')) (ChannelSetupForm);
