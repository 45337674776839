import React from 'react'
import {useSelector} from "react-redux";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import InstallmentBox from "./InstallmentBox";
import {isDefined} from "../../../../../../utils";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const PaymentInstallmentList = (props) => {

    const {
        paymentMapping,
        UpdatePaymentMapping,
        UpdatePaymentStatusMapping,
        PaymentStatusSetDefault,
        PaymentInstallmentUpdate,
        updatePaymentErrorClear,
        successPaymentClear,
        paymentTypeGet,
        GetPaymentType,
        paymentTypeId,
        UpdatePaymentInstallmentStatus
    } = props;
    const { t } = useTranslation();
    const paymentBoxStatusReducersError = useSelector(props => props.paymentReducers?.errorUpdateStatusPaymentInstallmentsData)

    const showMessage = () => {
        const errorStatus = paymentBoxStatusReducersError?.response?.status
        if(errorStatus === 500 || errorStatus === 400 || errorStatus === 401)
        {
            let message = paymentBoxStatusReducersError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

    }

    return(
        <Grid container>
            {
                isDefined(paymentTypeGet.currencies) && paymentTypeGet.currencies.map( paymentMappingList => (

                    paymentMappingList.code === "TRY" &&
                    <Grid container>
                        {
                            paymentMappingList.status === 1 && paymentMappingList.has_installments === 1 &&
                            <>
                                <Grid item xs={12}>
                                    <h2 className="ml-22">{paymentMappingList.code} { t('enw-payment-installment_table') } </h2>
                                </Grid>
                                <InstallmentBox
                                    paymentMappingListData={paymentMappingList}
                                    paymentMapping={paymentMapping}
                                    UpdatePaymentMapping={UpdatePaymentMapping}
                                    UpdatePaymentStatusMapping={UpdatePaymentStatusMapping}
                                    PaymentStatusSetDefault={PaymentStatusSetDefault}
                                    PaymentInstallmentUpdate={PaymentInstallmentUpdate}
                                    updatePaymentErrorClear={updatePaymentErrorClear}
                                    successPaymentClear={successPaymentClear}
                                    GetPaymentType={GetPaymentType}
                                    paymentTypeId={paymentTypeId}
                                    UpdatePaymentInstallmentStatus={UpdatePaymentInstallmentStatus}
                                    paymentTypeGet={paymentTypeGet}
                                />
                            </>

                        }
                    </Grid>
                ))

            }
            {
                showMessage()
            }
        </Grid>
    )
}

export default PaymentInstallmentList;
