import React,{Component} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import moment from 'moment';
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {propertyGroupBulkUpdateClear} from "../../../../redux/actions/Network/GroupInventory/BulkUpdateAction";
import {getPropertyGroupInventory, updateOrCreateGroupInventory} from "../../../../redux/actions/Network/GroupInventory/groupInventoryAction";
import Rooms from "./components/Rooms";
import {NETWORK, NETWORK_INVENTORY_LIST} from "../../../../constants/appUrls.constants";
import {addDateAndReturnRange, isDefined} from "../../../../utils";
import PriceDateRange from "./components/PriceDateRange";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import {Grid} from "@material-ui/core";
import ButtonAtm from "../../../atomic/Button/Button";
import BulkUpdate from "./components/BulkUpdate/BulkUpdateModal";
import SuccessGroupInventoryModal from "../../../organisms/Modal/SuccessModal/SuccessGroupInventoryModal";
import { getPropertyName } from "../../../../utils";
import { getProperty } from "../../../../utils";
import axios from "axios";
import { USER_MENU } from "../../../../constants/api.constants";
import { getAppLang } from "../../../../utils";

let t=null;

class GroupInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allDays : addDateAndReturnRange(moment(new Date()).format("YYYY-MM-DD"), 14,'day'),
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).add(14, 'days').format("YYYY-MM-DD"),
            channelId : this.props.match.params.id,
            isPriceUpdate : false,
            showStopSell : false,
            showMinStay : false,
            propertyName: '',
        };
        t = props.t;
    }

    componentDidMount() {
        if ( this.props.match.params.id )
        {
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
        this.getPropertyInventory();

        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(USER_MENU, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            this.setState({propertyName: result.data.data.property_list[0].name + " - "});
        }).catch(function (error) {
            console.log(error);
        });
    }

    getPropertyInventory = () => {

        const {channelId, startDate, endDate} = this.state

        if ( channelId !== undefined && channelId !== '' && !isNaN(channelId) )
        {
            this.props.getPropertyGroupInventory( startDate, endDate, channelId );

        }else{
            this.props.history.replace(NETWORK);
        }
    }

    getPropertyInventoryDate = (startDate, endDate) => {

        const {channelId} = this.state

        if ( channelId !== undefined && channelId !== '' )
        {
            this.props.getPropertyGroupInventory( startDate, endDate, channelId );
        }
    }

    nextDate = (startDate, endDate) => {
        this.getPropertyInventoryDate(startDate,endDate);
    }

    previousDate = (startDate, endDate) => {
        this.getPropertyInventoryDate(startDate,endDate);
    }

    updatePropertyInventory = ( updateData ) => {
        const {channelId } = this.state
        this.props.updateOrCreateGroupInventory(channelId, updateData)
    }

    isChangePriceStatus =  () => {
        if(this.state.isPriceUpdate) return 0;
        else this.setState({isPriceUpdate : true})
    }

    changePriceStatus =  (status) => {
        if(this.state.isPriceUpdate === status) return 0;
        else this.setState({isPriceUpdate : status})
    }

    changeAllDays = ( allDays,startDate,endDate ) => {

        this.setState({
            allDays,
            startDate,
            endDate,
        })
    }

    showMessage = () => {
        const {channelId} = this.state;

        if( this.props.inventoryUpdate === 200 ){
            return <SuccessGroupInventoryModal status={true}
                                               message={t('enw-notification_success')}
                                               channelId={channelId}
            />
        }

        if( this.props.error !== {}  &&
            this.props.error?.response
        ){
            let message = this.props.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }
    showStopSell = () => {
        const {showMinStay} = this.state;

        this.setState({showStopSell: !this.state.showStopSell})

        if(showMinStay) {
            this.setState({showMinStay: false, showStopSell: true})
        }
    }

    showMinStay = () => {
       this.setState({showMinStay: !this.state.showMinStay})
        const {showStopSell} = this.state;

        if(showStopSell) {
            this.setState({showMinStay: true, showStopSell: false})
        }
    }


    render() {
        const {t, inventory, fetching} = this.props;
        const {allDays, startDate, endDate, showStopSell, showMinStay} = this.state;

        return(
            <>
                <PageTitle title={ t('enw-inventory-page_title') } />
                <PageHeaderSaveAtm backButton={NETWORK_INVENTORY_LIST}
                                   backButtonName={ t('enw-network-inventory_list-title') }
                                   text={ inventory !== undefined && !fetching &&  this.state.propertyName + inventory['name']}
                                   saveButtonDisabled={this.props.fetching || !this.state.isPriceUpdate}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width m-inventory-container">
                            <Grid container className="mb-16" spacing={3}>
                                <Grid item md={6}>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-dark-blue inventory-filter-box"
                                               buttontext={ t('enw-input-min_stay') }
                                               fullwidth={true}
                                               onClick={this.showMinStay}
                                               endIcon={showMinStay && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-red inventory-filter-box"
                                               buttontext={ t('btn-stop_sell') }
                                               fullwidth={true}
                                               onClick={this.showStopSell}
                                               endIcon={showStopSell && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <BulkUpdate channelId={this.props.match.params.id } changeAllDays={this.changeAllDays}/>
                                </Grid>
                            </Grid>
                            <ColAtm>
                                <PriceDateRange allDays={allDays}
                                                startDate={startDate}
                                                endDate={endDate}
                                                previousDate={this.previousDate}
                                                nextDate={this.nextDate}
                                                getPropertyInventoryDate={this.getPropertyInventoryDate}
                                                fetching={this.props.fetching}
                                />
                            </ColAtm>
                            <Rooms roomPrice={isDefined(inventory) && inventory}
                                   allDays={this.state.allDays}
                                   getPropertyInventory={this.props.getPropertyGroupInventory}
                                   updatePropertyInventory={this.updatePropertyInventory}
                                   changePriceStatus={this.changePriceStatus}
                                   isChangePriceStatus={this.isChangePriceStatus}
                                   inventoryUpdate={this.props.inventoryUpdate}
                                   fetching={this.props.fetching}
                                   showStopSell={showStopSell}
                                   showMinStay={showMinStay}
                            />
                        </div>
                    </Container>
                </Animated>
                {this.showMessage()}
            </>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        fetching        : props.groupInventoryReducer.fetching,
        error           : props.groupInventoryReducer.error,
        inventory       : props.groupInventoryReducer.inventory,
        inventoryUpdate : props.groupInventoryReducer.inventoryUpdate ?. status,
        inventoryUpdateError : props.groupInventoryReducer.inventoryUpdate ?. error,
    }
};

const mapDispatchToProps ={
    updateOrCreateGroupInventory,
    propertyGroupBulkUpdateClear,
    getPropertyGroupInventory
};
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(GroupInventory));
