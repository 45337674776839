import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../utils";
import { PAYMENT_TRANSACTION_LIST } from "../../../constants/api.constants";

export function getPaymentTransactionList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_TRANSACTION_LIST',
            payload: axios.post(PAYMENT_TRANSACTION_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

