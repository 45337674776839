import React from 'react';


class LandingIntro extends React.Component {
    render(){
        return (
            <iframe src="/assets/globe/globe.html" className="globe-frame" title="globe-frame"></iframe>   /* like this */
        );
    }
}
export default LandingIntro;

