import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../../atomic/Button/Button";
import NewRoomTabOld from "./NewRoomTabOld";
import {roomTypes} from "../../../../../../redux/actions/roomTypeActions";
import {allBedTypes} from "../../../../../../redux/actions/bedTypesActions";
import {
    propertyRoomAdd,
    PropertyRoomTypes,
    PropertyRoomAndRateList,
    getRoomDescription,
    addRoomAndRateClear
} from "../../../../../../redux/actions/propertyRoomAndRateActions";
import {getRoomViewTypes} from "../../../../../../redux/actions/Content/Room/roomViewTypesAction";
import {getRoomSizeTypes} from "../../../../../../redux/actions/Content/Room/roomSizeTypesAction";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

let t=null

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class NewRoomModal extends React.Component {

    constructor(props) {
        super(props);
        t = props.t;
    }
    state = {
        open : this.props.roomAddModalStatus
    };

    componentDidMount() {
        this.props.roomTypes();
        this.props.allBedTypes();
        this.props.getRoomViewTypes();
        this.props.getRoomSizeTypes();
        this.props.getRoomDescription();
    }

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClose = () => {
        this.setState({open:false});
        this.props.addRoomAndRateClear()
    };

    propertyAddRoom = (param) => {

        this.props.propertyRoomAdd(param);
        //this.props.onPropertyAddRoom(param)
    };

    render() {
        return (
        <>
            <ButtonAtm
                buttontext={ t('btn-new_room') }
                className="filter-action-button float-right"
                startIcon={<i className="fas fa-plus-circle" />}
                onClick={this.handleClickOpen}
            />
            <Dialog onClose={this.handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={this.state.open}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    { t('enw-room-rooms-modal-title') }
                </DialogTitle>
                <DialogContent>
                    <NewRoomTabOld allRoomTypes={this.props.allRoomTypes}
                                   allBedType={this.props.allBedType}
                                   propertyAddRoom={this.propertyAddRoom}
                                   roomViewTypes={this.props.roomViewTypes}
                                   roomSizeTypes={this.props.roomSizeTypes}
                                   getDescriptionData={this.props.descriptionData.data}
                                   rooms={this.props.rooms}
                    />
                </DialogContent>
            </Dialog>
        </>
        )
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(this.props.roomAndBedAddStatus){

            this.props.PropertyRoomAndRateList();
            this.props.PropertyRoomTypes();

            if(prevState.open === true)
            {
                this.setState({ open : false} )
            }
        }
    };
}

const mapStateToProps = (props) => {

    return {
        allRoomTypes : props.roomTypesReducer.allRoomTypes,
        allBedType : props.bedTypesReducer.allBedType,
        roomViewTypes: props.RoomViewTypesReducer.roomViewTypes,
        roomSizeTypes: props.RoomSizeTypeReducer.roomSizeTypes,
        roomAndBedAddStatus : props.propertyRoomAndRateReducers.roomAndBedAddStatus,
        descriptionData : props.propertyRoomAndRateReducers.descriptionData
    }
};

const mapDispatchToProps = {
    roomTypes,
    allBedTypes,
    PropertyRoomAndRateList,
    PropertyRoomTypes,
    propertyRoomAdd,
    getRoomDescription,
    getRoomViewTypes,
    getRoomSizeTypes,
    addRoomAndRateClear
};
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(NewRoomModal));
