import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {getLanguages} from "../../../redux/actions/languageActions";
import {compose} from "redux";
import {connect} from "react-redux";
import SelectLng from "./SelectedLanguage";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const arrow = `${process.env.PUBLIC_URL}/assets/img/language/sort-down-solid.svg`;
let t=null;
class HeaderOfferLanguage extends Component {
    static propTypes = {
        i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            mainButtonContent: <SelectLng />,
            showMainLang: false,
            anchorEl: null
        };
        t = props.t;
    }

    componentDidMount() {
        this.props.getLanguages();
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.mainLang !== this.props.mainLang){
            const { i18n } = this.props;
            i18n.changeLanguage(this.props.mainLang)
        }
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    changeLanguage = (lng, name) => {
        const { i18n ,t } = this.props;
        i18n.changeLanguage(lng);

            const LngList = () => (
                <div>
                    <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${lng}.svg`} alt={lng} />
                    <span> { t(name) } </span>
                </div>
            );

        this.setState({ mainButtonContent: <LngList />, showMainLang: true, anchorEl: null});
    };

    render() {

        const { mainButtonContent, anchorEl } = this.state;
        const menu = (
            <Menu
                className="zoom-1366"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
            >
                {
                    this.props.languagesReducer.languages.map( (language) => (
                         <MenuItem onClick={() => this.changeLanguage(language.code, language.language_key)}>
                            <div>
                                <img style={{width:"20px"}} src={`${process.env.PUBLIC_URL}/assets/img/language/${language.code}.svg`} alt={language.code} />
                                <span> {t(language.language_key)} </span>
                            </div>
                        </MenuItem>
                    ))
                }
            </Menu>

        );



        return (
            <>
                <Button className="login-language" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                    {
                        this.state.showMainLang ?
                            mainButtonContent
                            :
                            <SelectLng mainLang={this.props.mainLang} keyLang={t(this.props.keyLang)}/>
                    }
                    <img src={arrow} alt="arrow" />
                </Button>
                {menu}
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        languagesReducer : props.languagesReducer,
    }
};

const mapDispatchToProps ={
    getLanguages
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(HeaderOfferLanguage));
