import {
    paymentMappingListConstants,
    getPaymentTypeListConstants,
    createPaymentMappingConstants,
    updatePaymentMappingConstants,
    updatePaymentSetDefaultConstants,
    updatePaymentMappingBoxStatusConstants,
    paymentInstallmentUpdateConstants,
    getPaymentTypeConstants,
    paymentInstallmentUpdateStatusConstants
} from "../../../constants/Payment/payment.constants";

const initialState = {

    paymentGetList: [],
    paymentGetListFetching: false,
    errorPaymentGetList: null,

    paymentMappingList: [],
    MappingListFetching: false,
    errorMappingList: null,

    paymentTypeList: [],
    paymentTypeListFetching: false,
    errorPaymentTypeList: null,

    paymentMappingCreate: [],
    paymentMappingCreateFetching: false,
    errorPaymentMappingCreate: null,

    createPaymentMappingData: [],
    createPaymentMappingFetching: false,
    createMappingStatus: false,
    errorCreatePaymentMappingData: null,

    updatePaymentMappingData: [],
    updatePaymentMappingFetching: false,
    updateMappingStatus: false,
    errorUpdatePaymentMappingData: null,

    //Update Payment Setup Status
    updateStatusPaymentMappingData: [],
    updateStatusMappingFetching: false,
    updateStatusMappingStatus: false,
    errorUpdatePaymentMappingStatusData: null,

    //Update Installment Status
    updateStatusPaymentInstallmentsData: [],
    updateStatusPaymentInstallmentsFetching: false,
    updateStatusPaymentInstallmentsStatus: false,
    errorUpdateStatusPaymentInstallmentsData: null,

    updatePaymentMappingBoxStatusData: [],
    updatePaymentMappingBoxStatusFetching: false,
    updatePaymentMappingBoxStatusStatus: false,
    errorUpdatePaymentMappingBoxStatusData: null,

    //Update Payment Setup Data
    updatePaymentInstallmentData: [],
    updatePaymentInstallmentFetching: false,
    updatePaymentInstallmentStatus: false,
    errorUpdatePaymentInstallmentData: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getPaymentTypeConstants.PAYMENT_TYPE_GET_PENDING:
            return {
                ...state,
                paymentGetListFetching: true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingStatusData: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingData: null,
                errorUpdateStatusPaymentInstallmentsData: null,
                updatePaymentInstallmentStatus: null,
                updatePaymentMappingBoxStatusFetching: null,
                updateStatusMappingStatus: null,
                createMappingStatus: null,
                paymentGetList: [],
                paymentMappingList: [],
                paymentTypeList: [],
                createPaymentMappingData: [],
                updatePaymentMappingData: [],
                updateStatusPaymentMappingData: [],
                updatePaymentMappingBoxStatusData: [],
                updatePaymentInstallmentData: [],
                updateStatusPaymentInstallmentsData: [],
            };
        case getPaymentTypeConstants.PAYMENT_TYPE_GET_FULFILLED:
            return {
                ...state,
                paymentGetListFetching: false,
                paymentGetList: action.payload.data,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
            };
        case getPaymentTypeConstants.PAYMENT_TYPE_GET_REJECTED:
            return {
                ...state,
                errorPaymentGetList: action.payload,
                errorMappingList: null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                paymentGetListFetching: false,
            };

        case paymentMappingListConstants.PAYMENT_MAPPING_LIST_PENDING:
            return {
                ...state,
                MappingListFetching: true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorPaymentGetList: null,
                errorUpdatePaymentMappingStatusData: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingData: null,
                updatePaymentInstallmentStatus: false,
                updatePaymentMappingBoxStatusFetching: false,
                updateStatusMappingStatus: false,
                createMappingStatus: false
            };
        case paymentMappingListConstants.PAYMENT_MAPPING_LIST_FULFILLED:
            return {
                ...state,
                MappingListFetching: false,
                paymentMappingList: action.payload.data,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
            };
        case paymentMappingListConstants.PAYMENT_MAPPING_LIST_REJECTED:
            return {
                ...state,
                errorMappingList: action.payload,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                MappingListFetching: false,
            };

        case getPaymentTypeListConstants.PAYMENT_TYPE_LIST_PENDING:
            return {
                ...state,
                paymentTypeListFetching: false,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentGetList: null,
                updateStatusMappingFetching: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingStatusData: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                updatePaymentInstallmentStatus: null,
                updatePaymentMappingBoxStatusFetching: null,
                updateStatusMappingStatus: null,
                createMappingStatus: null
            };
        case getPaymentTypeListConstants.PAYMENT_TYPE_LIST_FULFILLED:
            return {
                ...state,
                paymentTypeList: action.payload,
                paymentTypeListFetching: false,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,

            };
        case getPaymentTypeListConstants.PAYMENT_TYPE_LIST_REJECTED:
            return {
                ...state,
                paymentTypeListFetching: false,
                errorMappingList:null,
                errorPaymentTypeList:action.payload,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,

            };

        case createPaymentMappingConstants.PAYMENT_CREATE_MAPPING_PENDING:
            return {
                ...state,
                createPaymentMappingFetching:true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: null,
                errorPaymentGetList: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                updateStatusMappingFetching: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                updatePaymentInstallmentStatus: null,
                updatePaymentMappingBoxStatusFetching: null,
                updateStatusMappingStatus: null,
                createMappingStatus: null
            };
        case createPaymentMappingConstants.PAYMENT_CREATE_MAPPING_FULFILLED:
            return {
                ...state,
                createPaymentMappingData: action.payload,
                createMappingStatus : action.payload.status,
                createPaymentMappingFetching:false,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
            };
        case createPaymentMappingConstants.PAYMENT_CREATE_MAPPING_REJECTED:
            return {
                ...state,
                errorCreatePaymentMappingData: action.payload,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,

            };

        case updatePaymentMappingConstants.PAYMENT_MAPPING_UPDATE_PENDING:
            return {
                ...state,
                updatePaymentMappingFetching:true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorUpdatePaymentMappingStatusData: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorPaymentGetList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                updatePaymentInstallmentStatus: null,
                updatePaymentMappingBoxStatusFetching: null,
                updateStatusMappingStatus: null,
                createMappingStatus: null
            };
        case updatePaymentMappingConstants.PAYMENT_MAPPING_UPDATE_FULFILLED:
            return {
                ...state,
                updatePaymentMappingData: action.payload,
                updateMappingStatus : action.payload.status,
                createPaymentMappingFetching:true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
            };
        case updatePaymentMappingConstants.PAYMENT_MAPPING_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdatePaymentMappingData: action.payload,
                errorCreatePaymentMappingData:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,

            };

        case updatePaymentSetDefaultConstants.PAYMENT_MAPPING_UPDATE_PENDING:
            return {
                ...state,
                updatePaymentMappingFetching:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentGetList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                errorUpdatePaymentMappingStatusData: null,
                updateStatusMappingFetching:true,
            };
        case updatePaymentSetDefaultConstants.PAYMENT_MAPPING_UPDATE_FULFILLED:
            return {
                ...state,
                updateStatusPaymentMappingData: action.payload,
                updateStatusMappingStatus : action.payload.status,
                createPaymentMappingFetching:true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: null,
                errorCreatePaymentMappingData: null,
            };
        case updatePaymentSetDefaultConstants.PAYMENT_MAPPING_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdatePaymentMappingData: null,
                errorCreatePaymentMappingData:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: action.payload
            };

        case updatePaymentMappingBoxStatusConstants.PAYMENT_MAPPING_UPDATE_STATUS_PENDING:
            return {
                ...state,
                updatePaymentMappingFetching:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                errorPaymentGetList: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingStatusData: null,
                updatePaymentMappingBoxStatusFetching:true,
            };
        case updatePaymentMappingBoxStatusConstants.PAYMENT_MAPPING_UPDATE_STATUS_FULFILLED:
            return {
                ...state,
                updatePaymentMappingBoxStatusData: action.payload,
                updatePaymentMappingBoxStatusStatus : action.payload.status,
                createPaymentMappingFetching:true,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: null,
                errorCreatePaymentMappingData: null,
            };
        case updatePaymentMappingBoxStatusConstants.PAYMENT_MAPPING_UPDATE_STATUS_REJECTED:
            return {
                ...state,
                errorUpdatePaymentMappingData: null,
                errorCreatePaymentMappingData:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingBoxStatusData: action.payload
            };

        case paymentInstallmentUpdateConstants.PAYMENT_INSTALLMENTS_UPDATE_PENDING:
            return {
                ...state,
                updatePaymentMappingFetching:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                errorPaymentGetList: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingStatusData: null,
                errorUpdatePaymentInstallmentData: null,
                updatePaymentInstallmentFetching:true,
            };
        case paymentInstallmentUpdateConstants.PAYMENT_INSTALLMENTS_UPDATE_FULFILLED:
            return {
                ...state,
                updatePaymentInstallmentData: action.payload,
                updatePaymentInstallmentStatus : action.payload.status,
                updatePaymentInstallmentFetching:true,
                errorMappingList:null,
                errorUpdatePaymentInstallmentData:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: null,
                errorCreatePaymentMappingData: null,
            };
        case paymentInstallmentUpdateConstants.PAYMENT_INSTALLMENTS_UPDATE_REJECTED:
            return {
                ...state,
                errorUpdatePaymentMappingData: null,
                errorCreatePaymentMappingData:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentInstallmentData: action.payload
            };

        case paymentInstallmentUpdateStatusConstants.PAYMENT_INSTALLMENTS_UPDATE_STATUS_PENDING:
            return {
                ...state,
                updatePaymentMappingFetching:null,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorCreatePaymentMappingData: null,
                errorUpdatePaymentMappingData: null,
                errorPaymentGetList: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdatePaymentMappingStatusData: null,
                updateStatusPaymentInstallmentsFetching:true,
            };
        case paymentInstallmentUpdateStatusConstants.PAYMENT_INSTALLMENTS_UPDATE_STATUS_FULFILLED:
            return {
                ...state,
                updateStatusPaymentInstallmentsData: action.payload,
                updateStatusPaymentInstallmentsStatus : action.payload.status,
                updateStatusPaymentInstallmentsFetching:false,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingStatusData: null,
                errorCreatePaymentMappingData: null,
            };
        case paymentInstallmentUpdateStatusConstants.PAYMENT_INSTALLMENTS_UPDATE_STATUS_REJECTED:
            return {
                ...state,
                errorUpdatePaymentMappingData: null,
                errorCreatePaymentMappingData:null,
                updateStatusPaymentInstallmentsFetching:false,
                errorMappingList:null,
                errorPaymentTypeList: null,
                errorPaymentMappingCreate: null,
                errorUpdatePaymentMappingBoxStatusData: null,
                errorUpdateStatusPaymentInstallmentsData: action.payload
            };

        case createPaymentMappingConstants.PAYMENT_CREATE_MAPPING_CLEAR_ERROR_STATE:
            return {
                ...state,
                errorUpdatePaymentMappingData: null,
                errorCreatePaymentMappingData:null,
                errorUpdatePaymentInstallmentData:null,
                errorUpdateStatusPaymentInstallmentsData: null

            };
        case createPaymentMappingConstants.PAYMENT_CREATE_MAPPING_CLEAR_SUCCESS_STATE:
            return {
                ...state,
                updateMappingStatus: null,
                createMappingStatus:null,
                updatePaymentInstallmentStatus:null,
                errorUpdateStatusPaymentInstallmentsData: null

            };

        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
