import React, {useEffect, useState} from "react";
import RemainingAvailability from "./RemainingAvailability";


export default function RemainingAvailabilities(props) {

    useEffect(() => {},[props.remainingAvailability]);

    const [availabilities] =  useState(  props.remainingAvailability   );

    return (
        <>
            {
                Object.entries(availabilities).map( ( [date, value] ) => (

                    <RemainingAvailability date={ date }
                                  value={ value }
                    />

                ))
            }
        </>
    )
}

