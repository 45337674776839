import {
    getChannelPaymentConstants,
    addChannelPaymentConstants,
    getChannelPaymentCancellationConstants
} from "../../../../constants/Network/Channels/channelCancellation.constans";

const initialState = {
    fetching : false,
    error : '',
    submitError : [],
    payments: [],
    channelData: [],
    channel_id: null,
    currency_code: null,
    updateStatus : null,
    status : null,
    cancellationData: null,
    cancellationDataFetching: false,
    channelTax: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getChannelPaymentConstants.GET_CHANNEL_PAYMENT_PENDING:
            return {
                ...state,
                fetching: true,
                payments: [],
                channelTax: [],
                submitError: [],
                updateStatus:null,
                status : false
            };
        case getChannelPaymentConstants.GET_CHANNEL_PAYMENT_FULFILLED:
            return {
                ...state,
                fetching: false,
                submitError: [],
                payments: action.payload.data.payments,
                channelTax: action.payload.data.channel_tax,
                channel_id: action.payload.data.channel_id,
                currency_code: action.payload.data.currency_code,
                status : false
            };
        case getChannelPaymentConstants.GET_CHANNEL_PAYMENT_REJECTED:
            return {
                ...state,
                error: action.payload,
                payments: [],
                channelTax: [],
                submitError: [],
                fetching: false,
                status : false
            };
        case addChannelPaymentConstants.ADD_CHANNEL_PAYMENT_PENDING:
            return {
                ...state,
                submitError: [],
                channelData: [],
                status : false
            };
        case addChannelPaymentConstants.ADD_CHANNEL_PAYMENT_FULFILLED:
            return {
                ...state,
                channelData: action.payload,
                submitError: [],
                status : true
            };
        case addChannelPaymentConstants.ADD_CHANNEL_PAYMENT_REJECTED:
            return {
                ...state,
                submitError: action.payload,
                channelData: [],
                status : false

            };
        case getChannelPaymentCancellationConstants.GET_CHANNEL_CANCELLATION_PENDING:
            return {
                ...state,
                cancellationData: [],
                cancellationDataFetching : true
            };
        case getChannelPaymentCancellationConstants.GET_CHANNEL_CANCELLATION_FULFILLED:
            return {
                ...state,
                cancellationData: action.payload,
                cancellationDataFetching : false
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
