import React, { Component } from 'react'
import ColAtm from "../../../../atomic/Col/Col";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {
    OFFER_CREATE,
    OFFER_LIST
} from "../../../../../constants/appUrls.constants";
import {CircularProgress, Hidden} from "@material-ui/core";

let t=null

class StatusBox extends Component{

    constructor(props) {
        super(props);
        t = props.t;
    }


    state = {
        passive : '',
        accepted : '',
        canceled : '',
        pending: '',
        total:'',
    }

    componentDidMount() {

        let passive = '';
        let accepted = '';
        let canceled =  '';
        let pending =  '';
        let total =  '';


        if(this.props.dashBoardOffer.offerData.length  !== 0 ) {
            const {dashBoardOffer} = this.props;

            Object.keys(dashBoardOffer.offerData).map( key => {

                switch (key) {
                    case 'passive':
                        passive = dashBoardOffer.offerData.passive
                        break;
                    case 'accepted':
                        accepted = dashBoardOffer.offerData.accepted
                        break;
                    case 'canceled':
                        canceled = dashBoardOffer.offerData.canceled
                        break;
                    case 'pending':
                        pending = dashBoardOffer.offerData.pending
                        break;
                    case 'total':
                        total = dashBoardOffer.offerData.total
                        break;
                }
            })


        }

        this.setState({
            passive : passive,
            accepted : accepted,
            canceled: canceled,
            pending: pending,
            total: total,
        },() => {
        })


    }
    render(){
        const passive = this.state.passive;
        const accepted = this.state.accepted;
        const pending = this.state.pending;
        const total = this.state.total;
        const fetching = this.props.fetching
        return(
            <Grid container spacing={3} className="mt-44 be-container-width">
                <Grid item sm={8} lg={7} className="m-auto web-stats be-dashboard-items">
                    <Grid container spacing={3}>
                        <Grid item md={7} xs={12} className="m-auto pt-20">
                            <Hidden xsDown>
                                <Card className="offer-dashboard__box-shadow" item xs={12}>
                                    <Box className="offer-dashboard" component="fieldset" mb={3} borderColor="transparent">
                                        <i className="fas fa-paperclip" />
                                    </Box>
                                    <CardContent className="min-height-153 pt-0 pr-24 pl-24">
                                        <ColAtm>
                                            <ColAtm md={8} xl={9}>
                                                <Typography className="blue pb-12" gutterBottom variant="h5" component="h2">
                                                    { t('enw-offer-dashboard-title') }
                                                </Typography>
                                            </ColAtm>
                                        </ColAtm>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            { t('enw-offer-dashboard-description') }
                                        </Typography>
                                    </CardContent>
                                    <ColAtm className="carousel-col general-carousel offer-block-tabs">
                                        <Link to={OFFER_CREATE}> { t('btn-create_offer') } </Link>
                                        <Link to={OFFER_LIST}> { t('btn-offer_list') } </Link>
                                    </ColAtm>
                                </Card>
                            </Hidden>
                            <Hidden smUp>
                                <Card className="offer-dashboard__box-shadow mobile-mb-0 mb-24" item xs={12}>
                                    <Card>
                                        <Grid container spacing={3}>
                                            <ColAtm>
                                                <ColAtm className="m-offer-dashboard bg-blue" item xs={4}>
                                                    <i className="fas fa-paperclip" />
                                                </ColAtm>
                                                <ColAtm item xs={8} className="border-shadow">
                                                    <CardContent className="w-100">
                                                        <ColAtm>
                                                            <ColAtm xs={12}>
                                                                <Typography className="blue" gutterBottom noWrap>
                                                                    { t('enw-offer-dashboard-title') }
                                                                </Typography>
                                                            </ColAtm>
                                                        </ColAtm>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            { t('enw-offer-dashboard-description') }
                                                        </Typography>
                                                    </CardContent>
                                                </ColAtm>
                                            </ColAtm>
                                            <ColAtm className="carousel-col general-carousel offer-block-tabs">
                                                <Link to={OFFER_CREATE}> { t('btn-create_offer') } </Link>
                                                <Link to={OFFER_LIST}> { t('btn-offer_list') } </Link>
                                            </ColAtm>
                                        </Grid>
                                    </Card>
                                </Card>
                            </Hidden>
                        </Grid>
                        <Grid item md={5} xs={12} className="m-auto">
                            <Grid container spacing={3} className="mobile-offer-boxes-padding">
                                <Grid item xs={6}>
                                    <Paper className="offer-boxes">
                                        <ColAtm sm={12} className="pt-26">
                                            <i className="fas fa-check green fs-22 bg-icon-green m-auto b-r-50" />
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="payment-info-text m-auto f-w-bold">
                                                 {
                                                     fetching ?
                                                         <CircularProgress className="circularProgress-be-page" color="secondary"/>
                                                         :
                                                         (
                                                             <>{accepted}</>
                                                         )
                                                 }
                                            </span>
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="offer-total-title">{ t('enw-offer-dashboard-accepted-count') }</span>
                                        </ColAtm>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className="offer-boxes">
                                        <ColAtm sm={12} className="pt-26">
                                            <i className="far fa-clock orange fs-22 bg-icon-orange m-auto b-r-50" />
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="payment-info-text m-auto f-w-bold">
                                                 {
                                                     fetching ?
                                                         <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                         (
                                                             <>{pending}</>
                                                         )
                                                 }
                                            </span>
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="offer-total-title"> { t('enw-offer-dashboard-pending-count') }</span>
                                        </ColAtm>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className="offer-boxes">
                                        <ColAtm sm={12} className="pt-26">
                                            <i className="fas fa-times red fs-22 bg-icon-red m-auto b-r-50" />
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="payment-info-text m-auto f-w-bold">
                                                {
                                                    fetching ?
                                                        <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                        (
                                                            <>{passive}</>
                                                        )
                                                }
                                            </span>
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="offer-total-title"> { t('enw-offer-dashboard-passive-count') } </span>
                                        </ColAtm>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className="offer-boxes">
                                        <ColAtm sm={12} className="pt-26">
                                            <i className="fab fa-buffer blue fs-22 bg-icon-blue m-auto b-r-50" />
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="payment-info-text m-auto f-w-bold">
                                                {
                                                    fetching ?
                                                        <CircularProgress className="circularProgress-be-page" color="secondary"/> :
                                                        (
                                                            <>{total}</>
                                                        )
                                                }
                                            </span>
                                        </ColAtm>
                                        <ColAtm sm={12}>
                                            <span className="offer-total-title"> { t('enw-offer-dashboard-total-count') } </span>
                                        </ColAtm>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default compose(withTranslation('common'))(StatusBox);
