import React, {Component}   from 'react';
import Typography from "@material-ui/core/Typography";
import BlockAtm from "../../atomic/Block/Block";


export default class OverFlowText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overflowed: false
        };
        this.textElement = React.createRef();
    }

    componentDidMount () {
        this.setState({
            isOverflowed: this.textElement.current.scrollWidth > this.textElement.current.clientWidth
        });
    }

    render () {
        const { isOverflowed } = this.state;
        return (
            <BlockAtm className="fact-icon-label text-scroll">
                <div className="text-scroll-div">
                    <Typography ref={this.textElement} className={`${!isOverflowed && "text-wrap"} fact-name text-nowrap`}>
                        <i className="fas fa-check-circle blue" />
                        {this.props.children}
                    </Typography>
                </div>
            </BlockAtm>
        );
    }
}

