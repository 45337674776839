import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {RESERVATION_DETAIL} from "../../../../constants/api.constants";


export function getReservationDetail(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                booking_id: param.booking_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'RESERVATION_DETAIL',
            payload: axios.post(RESERVATION_DETAIL, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}
