import {
    getChannelCancellationListConstants,
    updateChannelCancellationConstants
} from "../../../../../constants/Network/Channels/channelCancellation.constans";

const initialState = {
    fetching : false,
    updatePolicy: [],
    error : {},
    status : null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getChannelCancellationListConstants.GET_CHANNEL_CANCELLATION_LIST_PENDING:
            return {
                ...state,
                error: {},
            };
        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_PENDING:
            return {
                ...state,
                fetching: true,
                updatePolicy: [],
                error: {},
                status : null
            };
        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_FULFILLED:
            return {
                ...state,
                fetching: false,
                updatePolicy: action.payload,
                status : action.payload.status
            };
        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
                status : null
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
