import React from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import FactMenuItems from "./FactMenuItems";

let t=null;

class FactMenu extends React.Component{
    constructor(props) {
        super(props);
        t = props.t;
    }


    render() {
        return (
            <FactMenuItems getMenusItems={this.props.getMenusItem} pageId={this.props.pageId} />
        )
    }
}

export default compose(withTranslation('common')) (FactMenu);
