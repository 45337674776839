import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../../atomic/Block/Block";
import {useTranslation} from "react-i18next";
import UpdateInstallmentModal from "./UpdateInstallmentModal";
import InstallmentCheckbox from "./InstallmentCheckBox";

const PaymentsBox = (props) => {
    const {
        paymentMappingListData,
        paymentMapping,
        PaymentInstallmentUpdate,
        updatePaymentErrorClear,
        successPaymentClear,
        GetPaymentType,
        paymentTypeId,
        installmentData,
        UpdatePaymentInstallmentStatus
    } = props;

    const { t } = useTranslation();

    return(
        <ColAtm className={`offer-fact-col pos-relative`} md={2} sm={4} xs={6} >
            <UpdateInstallmentModal
                installmentData={installmentData}
                paymentMappingListData={paymentMappingListData}
                PaymentInstallmentUpdate={PaymentInstallmentUpdate}
                paymentMapping={paymentMapping}
                updatePaymentErrorClear={updatePaymentErrorClear}
                successPaymentClear={successPaymentClear}
                paymentTypeId={paymentTypeId}
                GetPaymentType={GetPaymentType}
            />
            <BlockAtm className="offer-fact-border-box fact-border-box">
                <InstallmentCheckbox
                    paymentMappingListData={paymentMappingListData}
                    UpdatePaymentInstallmentStatus={UpdatePaymentInstallmentStatus}
                    installmentData={installmentData}
                />
                <label htmlFor={paymentMappingListData.id +"installmentID" + installmentData.installment_id}>
                    <label htmlFor={paymentMappingListData.id +"installmentID" + installmentData.installment_id}>
                        <Grid item xs={12} className="cancellation-info-col">
                            <ColAtm className="channel-logo payment-create-btn" htmlFor={paymentMappingListData.id +"installmentID" + installmentData.installment_id}>
                                <label htmlFor={paymentMappingListData.id +"installmentID" + installmentData.installment_id}>
                                    <p className="active-installment-rate">% {installmentData.commission}</p>
                                </label>
                            </ColAtm>
                        </Grid>
                        <OverFlowText>{installmentData.installment + " "+t('enw-payment-installment')}</OverFlowText>
                    </label>
                </label>
            </BlockAtm>
        </ColAtm>
    )
}

export default PaymentsBox;
