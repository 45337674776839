import {roomFact} from "../../../../constants/Network/RoomAndRates/roomFact.constants";

const initialState = {
    fetching : false,
    status : false,
    roomFacts : {},
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case roomFact.ROOM_FACT_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                status: false,
                roomFacts:{}
            };
        case roomFact.ROOM_FACT_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                roomFacts: action.payload
            };
        case roomFact.ROOM_FACT_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                status: false
            };
        default:
            return state
    }
}
