import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation}    from "react-i18next";
import EditRoomForm from "./EditRoomForm";
import {updateRoom, updateRoomAndRateClear} from "../../../../../redux/actions/propertyRoomAndRateActions";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const EditRoom = (props) => {
    const dispatch = useDispatch();
    const [modalStatus, setModalStatus] = useState(true);
    const { t } = useTranslation();
    const updateRoomAction = (param) => {
        dispatch(updateRoom(param))
    }

    const handleClose = () =>  {
        if(modalStatus === true){
            setModalStatus(false);
            props.closeEditRoom();
            dispatch(updateRoomAndRateClear());
        }
    };

    useEffect( () => {

    },[] )


    return(
        <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="status" open={modalStatus}>
            <DialogTitle className="red-bottom-border"  id="status" onClose={handleClose}>
                { t('enw-room-edit_rooms-modal-title') }
            </DialogTitle>
            <DialogContent>
                <EditRoomForm roomInfo={props.editRoomData}
                              allRoomTypes={props.allRoomTypes}
                              allBedType={props.allBedType}
                              roomViewTypes={props.roomViewTypes}
                              roomSizeTypes={props.roomSizeTypes}
                              updateRoom={updateRoomAction}/>
            </DialogContent>
        </Dialog>
    )
}

export default EditRoom
