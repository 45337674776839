import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {CONTENT, CONTENT_PLACES_FACTS} from "../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import Loading from "../../../../molecules/Loading/Loading";
import PlacesTypeFilter from "../PlacesTypeFilter";
import ColAtm from "../../../../atomic/Col/Col";
import {Animated} from "react-animated-css";
import PlacesMenu from "../../../Global/Layout/Header/Submenu/Places";
import {getPlacesCategories,getPlaceList} from "../../../../../redux/actions/Content/Places/placesActions"
import PhotoList from "./PhotoList";

let t=null;

class PlacesPhoto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlaceCategoryId : 0,
            selectedRoomRateId : 0,
            roomAddModalStatus : false,
            redirect: false,
            showSuccessHeader: false,
        };
        t = props.t;
    }

    onChangePlaceFilter = (selectedIndex) => {
        this.setState({
            selectedPlaceCategoryId : selectedIndex,
        });
    };

    componentDidMount() {
        this.props.getPlaceList();
    }

    onClickNextButton = () => {
        this.setState({
            redirect: true,
            showSuccessHeader:true
        })

    };

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={CONTENT}
                               exitText={ t('btn-dashboard')}
                               text= { t('enw-content-places-page_title') }
                               showSuccessHeader={this.state.showSuccessHeader}
        />
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-content-places-place_photo-page_title')} />
                {
                    this.headerStatusShow()
                }
                <PlacesMenu photoPlaceColClass="active-menu-bg" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <Grid container spacing={2} className="photoButtons room-rate-filter-group">
                                <Grid item className="type-plan-buttons">
                                    { this.props.propertyRoomTypesFetching ? (
                                        <Loading />
                                    ) : (
                                        <PlacesTypeFilter onChangePlaceFilter={this.onChangePlaceFilter}/>
                                    )}
                                </Grid>
                            </Grid>

                            <ColAtm className="room-rate-list-col">
                                { !this.props.placeList.fetching &&
                                <PhotoList selectedPlaceCategoryId={this.state.selectedPlaceCategoryId} />
                                }
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backTo={CONTENT_PLACES_FACTS}
                    nextTo={CONTENT}
                    component={Link}
                    nextComponent={Link}
                    buttonNextText={ t('btn-finish') }
                    iconLeftClassName="fas fa-arrow-left"
                    backDisabled={this.state.showSuccessHeader}
                    nextDisabled={this.state.showSuccessHeader}
                    onClick={this.onClickNextButton}
                />
            </>
        )
    }
}
const mapStateToProps = (props) => {
    return{
        placeList : props.getPlaceListReducer.placeList
    }
};

const mapDispatchToProps = {
    getPlacesCategories,
    getPlaceList
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PlacesPhoto));
