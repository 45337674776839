export const getChannelContact = {
    NETWORK_CHANNEL_CONTACT_GET_PENDING  : 'NETWORK_CHANNEL_CONTACT_GET_PENDING',
    NETWORK_CHANNEL_CONTACT_GET_FULFILLED: 'NETWORK_CHANNEL_CONTACT_GET_FULFILLED',
    NETWORK_CHANNEL_CONTACT_GET_REJECTED : 'NETWORK_CHANNEL_CONTACT_GET_REJECTED',
};

export const createChannelContact = {
    NETWORK_CHANNEL_CONTACT_CREATE_PENDING  : 'NETWORK_CHANNEL_CONTACT_CREATE_PENDING',
    NETWORK_CHANNEL_CONTACT_CREATE_FULFILLED: 'NETWORK_CHANNEL_CONTACT_CREATE_FULFILLED',
    NETWORK_CHANNEL_CONTACT_CREATE_REJECTED : 'NETWORK_CHANNEL_CONTACT_CREATE_REJECTED',
};

export const updateChannelContact = {
    NETWORK_CHANNEL_CONTACT_UPDATE_PENDING  : 'NETWORK_CHANNEL_CONTACT_UPDATE_PENDING',
    NETWORK_CHANNEL_CONTACT_UPDATE_FULFILLED: 'NETWORK_CHANNEL_CONTACT_UPDATE_FULFILLED',
    NETWORK_CHANNEL_CONTACT_UPDATE_REJECTED : 'NETWORK_CHANNEL_CONTACT_UPDATE_REJECTED',
};


export const deleteChannelContact = {
    NETWORK_CHANNEL_CONTACT_DELETE_PENDING  : 'NETWORK_CHANNEL_CONTACT_DELETE_PENDING',
    NETWORK_CHANNEL_CONTACT_DELETE_FULFILLED: 'NETWORK_CHANNEL_CONTACT_DELETE_FULFILLED',
    NETWORK_CHANNEL_CONTACT_DELETE_REJECTED : 'NETWORK_CHANNEL_CONTACT_DELETE_REJECTED',
};

export const clearChannelContactError = {
    NETWORK_CHANNEL_CONTACT_CLEAR_ERROR_STATE : 'NETWORK_CHANNEL_CONTACT_CLEAR_ERROR_STATE',
};
