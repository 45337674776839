import { offerLandingConstants} from "../../../../constants/Offer/offerLanding.constants";

const initialState = {
    fetching :false,
    offerData: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offerLandingConstants.GET_OFFER_LANDING_PENDING:
            return {
                ...state,
                fetching:true
            };
        case offerLandingConstants.GET_OFFER_LANDING_FULFILLED:
            return {
                ...state,
                fetching : false,
                error    : false,
                offerData: action.payload,
            };
        case offerLandingConstants.GET_OFFER_LANDING_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.payload
            }
        default:
            return state
    }
}
