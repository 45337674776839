import React, { useEffect, useState } from "react";
import Accommodation from "./Accommodation";

export default function Accommodations(props) {
    useEffect(() => {});
    const [roomRates] = useState(props.roomRates);

    return (
        <>
        {
            Object.values(roomRates).map( ( roomRate ) => (
              <>
                  {
                      (!props.showMinStay && !props.showStopSell) ?
                      Object.values(roomRate).map( ( rate ) => (
                          <Accommodation roomRate={rate}
                                         onChangeUpdateData={props.onChangeUpdateData}
                                         updatedData={ props.updatedData }
                                         showStopSell={ props.showStopSell }
                                         showMinStay={ props.showMinStay }
                          />
                      ) )
                          :
                          <Accommodation roomRate={Object.values(roomRate)[0]}
                                         onChangeUpdateData={props.onChangeUpdateData}
                                         updatedData={ props.updatedData }
                                         showStopSell={ props.showStopSell }
                                         showMinStay={ props.showMinStay }
                          />
                  }
              </>
            ) )
        }
        </>
    );
}
