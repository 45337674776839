import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import {PROPERTY_BULK_UPDATE,GET_PROPERTY_BULK_UPDATE} from "../../../../constants/api.constants"


export function getPropertyGroupBulkUpdate(params){
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                channel_group_id : params.channel_id,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PROPERTY_BULK_UPDATE',
            payload: axios.post(GET_PROPERTY_BULK_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function propertyGroupBulkUpdate(params) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                update_type: params.update_type,
                value: params.value,
                start_date: params.start_date,
                end_date: params.end_date,
                availability_type_id : params.availability_type_id,
                channel_group_id: params.channel_group_id,
                room_rates: params.room_rates,
                include_days: params.include_days
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_BULK_UPDATE',
            payload: axios.post(PROPERTY_BULK_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
export function propertyGroupBulkUpdateClear(){

    return dispatch => {
        dispatch({
            type: 'PROPERTY_BULK_UPDATE_CLEAR',
        })
    }
}
