import React            from "react";
import PropTypes        from "prop-types";

import { Checkbox } from '@createnl/grouped-checkboxes';

const MultiCheckboxAtm = props => (
    <Checkbox
        type="checkbox"
        className={props.className}
        id={props.checkboxId}
        value={props.value}
        onChange={props.onChange}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        name={props.name}
        disabled={props.disabled}
    >
        {props.factName}
    </Checkbox>
);

MultiCheckboxAtm.propType = {
    name: PropTypes.name,
    checkboxId: PropTypes.any,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    control: PropTypes.any,
    onChange: PropTypes.func,
    factName: PropTypes.any,
    disabled: PropTypes.any,
    color: PropTypes.string,
    labelPlacement: PropTypes.string,
    value: PropTypes.string,

};

export default MultiCheckboxAtm;
