import React            from 'react';
import PropTypes        from "prop-types";

import { makeStyles }   from '@material-ui/core/styles';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Typography       from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import UserDrawer from "../../organisms/UserDrawer/UserDrawer";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    toolbar: {
        height: 80,
        [theme.breakpoints.down('sm')]: {
            height: 60,
            marginTop: 28,
        },
    },
    header: {
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 4px 0 rgba(143, 148, 162, 0.1)',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#8f94a2',
        fontSize: 20,
        fontWeight: 'bold',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#8f94a2'

        },
    },
    saveButton: {
        backgroundColor: '#d71f26',
        borderRadius: '0',
        padding: '29px 40px',
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#c62128',
        },
        [theme.breakpoints.up('xs')]: {
            padding: '29px 20px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '29px 40px',
        },
    },
    title: {
        flexGrow: 1,
        color: '#000000',
        textAlign: 'center',
        textTransform: 'upperCase'
    },
}));

export default function PageHeaderAtm(props) {
    const classes = useStyles();
    return (

          /*  props.showSuccessHeader ?
                (
                    <PageHeaderSaveStatusAtm
                        text={ t('enw-notification_success') }
                        title="title"
                        headerClass="success-header-bg"
                        statusIconClass="fas fa-check success-header-icon"
                    />
                ) :
                (*/
                    <div className={classes.root}>
                        <AppBar className={classes.header} position="static">
                            <Toolbar className={classes.toolbar}>
                                <Link to={props.backButton} edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={props.onClick}>
                                    <Hidden xsDown>
                                        <i className="fas fa-chart-pie" style={{marginRight: '13.5px'}} />
                                        {props.exitText}
                                    </Hidden>
                                    <Hidden smUp>
                                        <i className="fas fa-arrow-left m-back-btn"/>
                                        {
                                            props.isOffer &&
                                            props.exitText
                                        }
                                    </Hidden>
                                </Link>
                                <Typography variant="h6" className={classes.title}>
                                    {props.text}
                                </Typography>
                                {
                                    !props.isOffer &&
                                    <UserDrawer/>
                                }
                            </Toolbar>
                        </AppBar>
                    </div>
              //  )


    );
}
PageHeaderAtm.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    exitText: PropTypes.string,
    backButton: PropTypes.string,
};
