import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../utils";
import { PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST } from "../../../constants/api.constants";

export function getPaymentManualTransactionList(transactionId) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                payment_transaction_id: transactionId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST',
            payload: axios.post(PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

