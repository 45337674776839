import React, {Component}   from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getPdf} from "../../../../../redux/actions/Content/ContentPDF/contentPdfActions";
import ContentPdfLang from "./contentPdf";
import {getLanguages} from "../../../../../redux/actions/languageActions";
let t =null;

class ContentPdfIndex extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    render() {
        return (
            <ContentPdfLang getPdf={this.props.getPdf} languages={this.props.languages} getLanguages={this.props.getLanguages} />
        );
    }
}

const mapStateToProps = (props) => {
    return {
        languages : props.languagesReducer.languages,
    }
};

const mapDispatchToProps ={
    getLanguages,
    getPdf
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ContentPdfIndex));
