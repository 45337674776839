import {contentPdfConstants} from "../../../../constants/Content/contentPdf.constans";

const initialState = {
    fetching : false,
    status : false,
    pdfData : [],
    error : '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case contentPdfConstants.GET_CONTENT_PDF_PENDING:
            return {
                ...state,
                fetching: true,
                status: false,
            };
        case contentPdfConstants.GET_CONTENT_PDF_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                pdfData: action.payload,
            };
        case contentPdfConstants.GET_CONTENT_PDF_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
