import React, {useEffect, useState} from 'react';
import { makeStyles }   from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Typography       from '@material-ui/core/Typography';
import ButtonAtm        from "../Button/Button";
import PropTypes        from "prop-types";
import {Link}           from "react-router-dom";
import UserDrawer       from "../../organisms/UserDrawer/UserDrawer";
import PageHeaderSaveStatusAtm from "./PageHeaderSaveStatus";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    toolbar: {
        height: '80px',
        paddingRight: '0',
        [theme.breakpoints.down('sm')]: {
            height: 60,
            paddingTop: 22,
            marginBottom: 22
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#8f94a2',
        fontSize: 20,
        fontWeight: 'bold',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#8f94a2'

        },
    },
    saveButton: {
        backgroundColor: '#d71f26',
        borderRadius: '0',
        padding: '29px 40px',
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#c62128',
        },
        [theme.breakpoints.up('xs')]: {
            padding: '29px 20px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '29px 54px',
        },
    },
}));

export default function PageHeaderSaveAtm(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [successHeaderTime, setSuccessHeaderTime] = useState(props.successHeaderTime)
    const [errorHeaderTime, setErrorHeaderTime] = useState(props.errorHeaderTime)

    const viewHeader = () => {
        if(props.errorStatus){
            let message = props.errorStatus.response ? props.errorStatus.response.data.message : "Bir Şeyler Ters Gitti.Lütfen Daha Sonra Tekrar Deneyiniz!"

            if(errorHeaderTime){

                setTimeout(() => {
                    setErrorHeaderTime(false)
                }, 2000);
                return <PageHeaderSaveStatusAtm
                    text={message}
                    title="title"
                    headerClass="error-header-bg"
                    statusIconClass="fas fa-exclamation-triangle error-header-icon"
                />
            }else{

                return <div className={classes.root}>
                    <AppBar className="header" position="static">
                        <Toolbar className={classes.toolbar}>
                            <Link to={props.backButton} onClick={props.backOnClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <Hidden xsDown>
                                    <i className="fas fa-chart-pie" style={{marginRight: '13.5px'}} />
                                    { props.backButtonName ? props.backButtonName : t('btn-dashboard') }
                                </Hidden>
                                <Hidden smUp>
                                    <i className="fas fa-arrow-left m-back-btn"/>
                                </Hidden>
                            </Link>

                            <Typography variant="h6" className="title">
                                {props.text}
                            </Typography>
                            <ButtonAtm type="submit"
                                       htmlFor="general-form" onChane={props.onChange}
                                       form='general-form'
                                       className={classes.saveButton}
                                       buttontext={ t('btn-save') }
                                       size="large"
                                       color="inherit"
                                       onClick={props.onClick}
                                       disabled={props.saveButtonDisabled}/>
                            <UserDrawer />
                        </Toolbar>
                    </AppBar>
                </div>

            }

        }
       /* else if(props.processStatus && props.processResponse !== 200){
            return <PageHeaderSaveStatusAtm
                text={ t('enw-notification_starting_proccess') }
                title="title"
                headerClass="starting-process-header-bg"
                statusIconClass="fa fa-spinner starting-process-header-icon"
            />
        }*/else if(!props.processStatus && props.processResponse === 200){
           /* if(successHeaderTime){

                setTimeout(() => {
                    setSuccessHeaderTime(false)
                }, 2000);
                return <PageHeaderSaveStatusAtm
                    text={ t('enw-notification_success') }
                    title="title"
                    headerClass="success-header-bg"
                    statusIconClass="fas fa-check success-header-icon"
                />
            }else{*/
                return <div className={classes.root}>
                    <AppBar className="header" position="static">
                        <Toolbar className={classes.toolbar}>
                            <Link to={props.backButton} onClick={props.backOnClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <Hidden xsDown>
                                    <i className="fas fa-chart-pie" style={{marginRight: '13.5px'}} />
                                    { props.backButtonName ? props.backButtonName : t('btn-dashboard') }
                                </Hidden>
                                <Hidden smUp>
                                    <i className="fas fa-arrow-left m-back-btn"/>
                                </Hidden>
                            </Link>

                            <Typography variant="h6" className="title">
                                {props.text}
                            </Typography>
                            <ButtonAtm type="submit"
                                       htmlFor="general-form" onChane={props.onChange}
                                       form='general-form'
                                       className={classes.saveButton}
                                       buttontext={ t('btn-save') }
                                       size="large"
                                       color="inherit"
                                       onClick={props.onClick}
                                       disabled={props.saveButtonDisabled}/>
                            <UserDrawer />
                        </Toolbar>
                    </AppBar>
                </div>
          // }
        }
        else{
            return<div className={classes.root}>
                <AppBar className="header" position="static">
                    <Toolbar className={classes.toolbar}>
                        <Link to={props.backButton} onClick={props.backOnClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <Hidden xsDown>
                                <i className="fas fa-chart-pie" style={{marginRight: '13.5px'}} />
                                { props.backButtonName ? props.backButtonName : t('btn-dashboard') }
                            </Hidden>
                            <Hidden smUp>
                                <i className="fas fa-arrow-left m-back-btn"/>
                            </Hidden>
                        </Link>
                        <Typography variant="h6" className="title">
                            {props.text}
                        </Typography>
                        <ButtonAtm type="submit"
                                   htmlFor="general-form" onChane={props.onChange}
                                   form='general-form'
                                   className={classes.saveButton}
                                   buttontext={ t('btn-save') }
                                   size="large"
                                   color="inherit"
                                   onClick={props.onClick}
                                   disabled={props.saveButtonDisabled}/>
                        <UserDrawer />
                    </Toolbar>
                </AppBar>
            </div>
        }
    }

    useEffect( () => {
        return () => {
            if(!successHeaderTime){
                setSuccessHeaderTime(true)
            }
            if(!errorHeaderTime){
                setErrorHeaderTime(true)
            }
        };
    })

    return (
        <>
            {
                viewHeader()
            }
        </>
    );
}
PageHeaderSaveAtm.propTypes = {
    text: PropTypes.string,
    backButton: PropTypes.string,
    onClick: PropTypes.func,
    backOnClick: PropTypes.func,
    onChange: PropTypes.func,
};
