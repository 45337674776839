export const webConstants = {

    GET_WEB_PENDING  : 'GET_WEB_PENDING',
    GET_WEB_FULFILLED: 'GET_WEB_FULFILLED',
    GET_WEB_REJECTED : 'GET_WEB_REJECTED',

    CREATE_WEB_PENDING  : 'CREATE_WEB_PENDING',
    CREATE_WEB_FULFILLED: 'CREATE_WEB_FULFILLED',
    CREATE_WEB_REJECTED : 'CREATE_WEB_REJECTED',

    UPDATE_WEB_PENDING  : 'UPDATE_WEB_PENDING',
    UPDATE_WEB_FULFILLED: 'UPDATE_WEB_FULFILLED',
    UPDATE_WEB_REJECTED : 'UPDATE_WEB_REJECTED'

};
