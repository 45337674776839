import {getAddonsConstants, syncAddonsConstants} from "../../../../constants/Network/addons.constans";

const initialState = {
    getAddonsFetching : true,
    getAddonsError : {},
    getAddonsList: [],

    syncAddonsFetching : true,
    syncAddonsError : {},
    syncAddons: [],
    syncAddonsStatus: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getAddonsConstants.NETWORK_ADDONS_GET_PENDING:
            return {
                ...state,
                getAddonsFetching : true,
                getAddonsError : {},
                getAddonsList: [],
                syncAddonsFetching : true,
                syncAddonsError : {},
                syncAddons: [],
                syncAddonsStatus: false,
            };
        case getAddonsConstants.NETWORK_ADDONS_GET_FULFILLED:
            return {
                ...state,
                getAddonsFetching: false,
                getAddonsList: action.payload,
            };
        case getAddonsConstants.NETWORK_ADDONS_GET_REJECTED:
            return {
                ...state,
                getAddonsFetching: false,
                getAddonsError: action.payload,
                getAddonsList: [],
            };

        case syncAddonsConstants.NETWORK_ADDONS_SYNC_PENDING:
            return {
                ...state,
                syncAddonsFetching : true,
                syncAddonsError : {},
                syncAddons: [],
                syncAddonsStatus: false,
            };
        case syncAddonsConstants.NETWORK_ADDONS_SYNC_FULFILLED:
            return {
                ...state,
                syncAddonsFetching: false,
                syncAddons: action.payload.data,
                syncAddonsStatus: action.payload.status,
            };
        case syncAddonsConstants.NETWORK_ADDONS_SYNC_REJECTED:
            return {
                ...state,
                syncAddonsFetching: false,
                syncAddonsError: action.payload,
                syncAddons: [],
            };

        case syncAddonsConstants.NETWORK_ADDONS_CLEAR_STATE:
            return {
                ...state,
                syncAddonsFetching : true,
                syncAddonsError : {},
                syncAddons: [],
                syncAddonsStatus: false,
            };

        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
