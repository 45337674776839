import React, { useRef } from 'react'
import ColAtm from "../../../../atomic/Col/Col";
import {useForm} from "react-hook-form";
import LabelAtm from "../../../../atomic/Label/Label";
import InputAtm from "../../../../atomic/Input/Input";
import {FormHelperText} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const UpdatePasswordForm  = (props) => {
    const { register, handleSubmit,  errors, watch} = useForm({
        mode: "onChange",
        reValidateMode: 'onChange',
    });
    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = (data) => {
        props.updatePassword(data)
    }

    const { t } = useTranslation();

    return(
        <ColAtm container  direction="row" md={6} className="m-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="edit-profile-form" name="general-form" id="general-form" >
                <LabelAtm variant="h6" component="h2" text={ t('enw-update-password-subtitle') } />
                <InputAtm
                    name="old_password"
                    type="password"
                    variant="outlined"
                    label={ t('enw-input-password') }
                    fullWidth
                    error={!!errors.old_password}
                    inputRef={register({ required:  t("enw-notification_this_is_required") })}
                />
                <FormHelperText error={!!errors.old_password}>
                    {errors.old_password && errors.old_password.message}
                </FormHelperText>
                <InputAtm
                    name="password"
                    type="password"
                    variant="outlined"
                    label={ t('enw-input-new_password') }
                    fullWidth
                    error={!!errors.password}
                    inputRef={register({
                        required: "You must specify a password",
                        minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters"
                        }
                    })}
                />
                <FormHelperText error={!!errors.password}>
                    {errors.password && errors.password.message}
                </FormHelperText>
                <InputAtm
                    name="password_confirmation"
                    type="password"
                    variant="outlined"
                    label={ t('enw-input-re_enter_your_new_password') }
                    fullWidth
                    error={!!errors.password_confirmation}
                    inputRef={register({
                        validate: value =>
                            value === password.current || "The passwords do not match"
                    })}
                />
                <FormHelperText error={!!errors.password_confirmation}>
                    {errors.password_confirmation && errors.password_confirmation.message}
                </FormHelperText>
            </form>
        </ColAtm>
    )

}

export default UpdatePasswordForm;
