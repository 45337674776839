import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, Hidden, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getAppLang, isDefined} from "../../../../../../utils";
import LabelAtm from "../../../../../atomic/Label/Label";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputAtm from "../../../../../atomic/Input/Input";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TodayIcon from "@material-ui/icons/Today";
import moment from "moment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";
import ruLocale from "date-fns/locale/ru";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function NonRefundableDialog(props) {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [isAffect, setIsAffect] = useState(false);
    const [affectActionType, setAffectActionType] = useState(null);
    const [affectPriceType, setAffectPriceType] = useState(null);
    const [affectPriceValue, setAffectPriceValue] = useState(null);
    const [isDateRange, setIsDateRange] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const default_currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.default_currency ? props.channelInformationData.default_currency : '';
    const currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.currency ? props.channelInformationData.currency : default_currency ;

    const localeMap = {
        es: esLocale,
        de: deLocale,
        tr: trLocale,
        ru: ruLocale
    };

    const { handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            name : name,
            before_arrival : 999,
            value : null,
            type: null,
            is_nonrefundable: true,
            is_free_cancellation: false,
            is_affect : isAffect,
            affects_price_action_type: null,
            affects_price_type: null,
            affects_price_value: null,
            is_date_range : isDateRange,
            start_date: null,
            finish_date: null,
        }

        if(isAffect){
            Object.assign(params, {
                affects_price_action_type: affectActionType,
                affects_price_type: affectPriceType,
                affects_price_value: affectPriceValue
            })
        }

        if(isDateRange){
            Object.assign(params, {
                start_date: startDate,
                finish_date: endDate
            })
        }
        props.addChannelCancellation(params)
    };

    const changeStartDate = date => {
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeEndDate = date => {
        setEndDate(moment(date).format("YYYY-MM-DD"))
    }

    useEffect( () => {

        if (props.updateStatus === 200) {
            props.handleClose();
            setName("")
        }

        return () => {
            setAffectActionType("")
            setAffectPriceType("")
            setAffectPriceValue("")
            setIsAffect(false)
            setIsDateRange(false)
        }

    },[props.updateStatus, props.open] )

    return (
        <>
            <ButtonAtm
                buttontext={ t('btn-new_non_refundable_cancellation_policy') }
                fullwidth={true}
                startIcon={<i className="fas fa-plus-circle" />}
                endIcon={<i className="fal fa-angle-right ml-9" />}
                onClick={props.handleOpen}
            />
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
                key={2}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={props.handleClose}>
                    { t('btn-new_non_refundable_cancellation_policy') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)} className="cancellation-policy-form">
                            {/*{props.updateStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}*/}
                            {isDefined(props.updateError) && <Alert severity="error">{props.updateError.response.data.message}</Alert>}
                            {/* <Grid container spacing={3} className="mb-20">
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            label={ t('enw-input-room_name') }
                                            type="text"
                                            fullWidth
                                            autoComplete="off"
                                            name="name"
                                            variant="outlined"
                                            value={name} onChange={changeName}
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                        />
                                    </Grid>
                                </Grid>*/}
                            <Grid item xs={12}>
                                <Grid xs={12} sm={4} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <ColAtm>
                                                <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_affected-title') } />
                                            </ColAtm>
                                        </Grid>
                                        <Grid item>
                                            <input type={"radio"} name={"paymentValue"}
                                                   id={"paymentValue2"}
                                                   value={"false"}
                                                   className="d-none"
                                                   checked={ !isAffect }
                                                   onClick={(e) => { setIsAffect(false) }}
                                            />
                                            <label className="show-channel-detail"
                                                   htmlFor={"paymentValue2"}>
                                                { t('btn-not_affected') }
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <input type={"radio"} name={"paymentValue"}
                                                   id={"paymentValue"}
                                                   value={"true"}
                                                   className="d-none"
                                                   checked={ isAffect }
                                                   onClick={(e) => { setIsAffect(true) }}
                                            />
                                            <label className="show-channel-detail" htmlFor={"paymentValue"}>
                                                { t('btn-affected') }
                                            </label>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    isAffect ? (
                                        <Grid xs={12}>
                                            <Grid container spacing={3} className="mobile-no-spacing">
                                                <Grid item xs={12} md={4}>
                                                    <SelectValidator
                                                        name="action_type"
                                                        className="form-control"
                                                        variant="outlined"
                                                        fullWidth
                                                        label={ t('enw-input-setup_type') }
                                                        value={affectActionType}
                                                        onChange={e => setAffectActionType(e.target.value)}
                                                        validators={['required']}
                                                        errorMessages={[t("enw-notification_this_is_required")]}
                                                    >
                                                        <MenuItem value={"INC"}>{ t('enw-input-increase') }</MenuItem>
                                                        <MenuItem value={"DEC"}>{ t('enw-input-decrease') }</MenuItem>
                                                    </SelectValidator>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <SelectValidator
                                                        name="price_type"
                                                        className="form-control"
                                                        variant="outlined"
                                                        fullWidth
                                                        label={ t('enw-input-value_type') }
                                                        value={affectPriceType}
                                                        onChange={e => setAffectPriceType(e.target.value) }
                                                        validators={['required']}
                                                        errorMessages={[t("enw-notification_this_is_required")]}
                                                    >
                                                        <MenuItem value={"PER"}>{ t('enw-input-percent') }</MenuItem>
                                                        <MenuItem value={"FIX"}>{ t('enw-input-fixed') }</MenuItem>
                                                    </SelectValidator>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextValidator
                                                        //   id={pay.payment_type_id+"channel"}
                                                        name="value"
                                                        type="text"
                                                        variant="outlined"
                                                        label={ t('enw-input-value') }
                                                        autoComplete="off"
                                                        value={ affectPriceValue }
                                                        onChange={e => setAffectPriceValue(e.target.value)}
                                                        labelWidth={70}
                                                        validators={['required']}
                                                        errorMessages={[t("enw-notification_this_is_required")]}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <>
                                                                {
                                                                    affectPriceType === "PER" ?
                                                                        <InputAdornment position="start">
                                                                            %
                                                                        </InputAdornment>
                                                                        :
                                                                        affectPriceType === "FIX" ?
                                                                            <InputAdornment position="start">
                                                                                { currency }
                                                                            </InputAdornment>
                                                                            :
                                                                            <InputAdornment position="start">

                                                                            </InputAdornment>
                                                                }
                                                            </>
                                                        }}
                                                    />
                                                    <Hidden xsUp>
                                                        <InputAtm
                                                            name="payment_type_id"
                                                            type="text"
                                                            //    value={pay.payment_type_id}
                                                            variant="outlined"
                                                            label={"payment_type_id"}
                                                            autoComplete="off"
                                                            //  onChange={e => this.handlePaymentChange(e, i)}
                                                        />
                                                    </Hidden>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (<></>)
                                }

                                <Grid xs={12} sm={8}  className="mt-20">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <ColAtm>
                                                <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_date-range-title') } />
                                            </ColAtm>
                                        </Grid>
                                        <Grid item>
                                            <input type={"radio"} name={"dateRange"}
                                                   id={"dateRange2"}
                                                   value={"false"}
                                                   className="d-none"
                                                   checked={ !isDateRange }
                                                   onClick={(e) => { setIsDateRange(false) }}
                                            />
                                            <label className="show-channel-detail"
                                                   htmlFor={"dateRange2"}>
                                                { t('btn-not_affected') }
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <input type={"radio"} name={"dateRange"}
                                                   id={"dateRange"}
                                                   value={"true"}
                                                   className="d-none"
                                                   checked={ isDateRange }
                                                   onClick={(e) => { setIsDateRange(true) }}
                                            />
                                            <label className="show-channel-detail" htmlFor={"dateRange"}>
                                                { t('btn-affected') }
                                            </label>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    isDateRange ? (
                                        <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={ t('enw-input-from_date')}
                                                        className="w-100"
                                                        variant="inline"
                                                        autoOk
                                                        inputVariant="outlined"
                                                        format="dd.MM.yyyy"
                                                        value={startDate}
                                                        onChange={changeStartDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <TodayIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label={ t('enw-input-to_date')}
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        className="w-100"
                                                        format="dd.MM.yyyy"
                                                        autoOk
                                                        value={endDate}
                                                        minDate={ moment(startDate).add(1,'days') }
                                                        onChange={changeEndDate}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton>
                                                                        <InsertInvitationIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    ) : (<></>)
                                }

                            </Grid>
                            <ColAtm className="mt-22" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                    disabled={isDateRange && (startDate === null || endDate === null) && true}
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
