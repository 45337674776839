import React, {useEffect} from "react";
import {useFieldArray} from "react-hook-form";
import ColAtm from "../../atomic/Col/Col";
import InputAtm from "../../atomic/Input/Input";
import ButtonAtm from "../../atomic/Button/Button";
import {useTranslation} from "react-i18next";


const ImportantNotes = (props) => {

    const { t } = useTranslation();
    const { control, name, register } = props;
    const { fields,remove ,append } = useFieldArray({
        control,
        name: name
    });

    useEffect(() => {
            if (fields.length === 0) {
                return
            }
        }, [fields]
    );

    return (
        <>
            { fields.map((item,index) => {
                return (
                    <ColAtm sm={12} key={item.id}>
                        <ColAtm xs={7} sm={11}>
                            <InputAtm
                                multiline={true}
                                name={`important_notes[${index}]`}
                                label={t('enw-input-important_notes')}
                                rows={2}
                                variant="outlined"
                                rowsMax={4}
                                inputRef={register()}
                            />
                        </ColAtm>
                        <ColAtm xs={5} sm={1}>
                            <div className="btn-box">
                                {
                                    fields.length !== 1 &&
                                    <ButtonAtm
                                        className="delete-bed-button"
                                        startIcon={<i className="fas fa-minus-circle" />}
                                        onClick={ () => remove(index)}
                                    />
                                }
                                {
                                    fields.length - 1 === index &&
                                    <ButtonAtm
                                        className="new-bed-button ml-10"
                                        fullwidth={true}
                                        startIcon={<i className="fas fa-plus-circle" />}
                                        onClick={() => {
                                            append({ name: "important_notes" });
                                        }
                                        }
                                    />
                                }
                            </div>
                        </ColAtm>
                    </ColAtm>
                );
            })}
        </>
    );
};

export default ImportantNotes;
