import {
    promotionRoomRateListConstants,
    promotionListConstants,
    createPromotionConstants,
    updatePromotionConstants,
    getPromotionTypes,
    updateRoomRatePromotionConstants, clearPromotionError} from "../../../../constants/Network/promotion.constans";

const initialState = {
    promotionFetching : true,
    promotionError : {},
    promotionRoomRateList: [],

    promotionListFetching : true,
    promotionListError : {},
    promotionList: [],

    createPromotionFetching : true,
    createPromotionError : {},
    createPromotion: [],

    updatePromotionFetching : true,
    updatePromotionError : {},
    updatePromotion: [],

    getPromotionTypesFetching : true,
    getPromotionTypesError : {},
    getPromotionTypes: [],

    updateRoomRatePromotionFetching : true,
    updateRoomRatePromotionError : {},
    updateRoomRatePromotion: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case promotionRoomRateListConstants.PROMOTION_ROOM_RATE_LIST_PENDING:
            return {
                ...state,
                promotionFetching: true,
                promotionListFetching: true,
                promotionRoomRateList: [],
                promotionError: {},
                promotionListError: {},
                createPromotionError : {},
                updatePromotionError: {},
                getPromotionTypesError: {},
                updateRoomRatePromotionError: {},
                createPromotion: [],
                updatePromotion: [],
                promotionList: [],
                updateRoomRatePromotion: []
            };
        case promotionRoomRateListConstants.PROMOTION_ROOM_RATE_LIST_FULFILLED:
            return {
                ...state,
                promotionFetching: false,
                promotionRoomRateList: action.payload.data.rooms,
            };
        case promotionRoomRateListConstants.PROMOTION_ROOM_RATE_LIST_REJECTED:
            return {
                ...state,
                promotionFetching: false,
                promotionError: action.payload,
                promotionRoomRateList: [],
            };

        case promotionListConstants.PROMOTION_LIST_PENDING:
            return {
                ...state,
                promotionFetching: true,
                promotionListFetching: true,
                promotionList: [],
                promotionListError: {},
                promotionError: {},
                promotionRoomRateList: [],
                createPromotionError : {},
                updatePromotionError: {},
                getPromotionTypesError: {},
                updateRoomRatePromotionError: {},
                createPromotion: [],
                updatePromotion: [],
                updateRoomRatePromotion: []
            };
        case promotionListConstants.PROMOTION_LIST_FULFILLED:
            return {
                ...state,
                promotionListFetching: false,
                promotionList: action.payload.data,
            };
        case promotionListConstants.PROMOTION_LIST_REJECTED:
            return {
                ...state,
                promotionListFetching: false,
                promotionListError: action.payload,
                promotionList: [],
            };

        case createPromotionConstants.PROMOTION_CREATE_PENDING:
            return {
                ...state,
                createPromotionFetching: true,
                createPromotion: [],
                createPromotionError: {},
                promotionError: {},
            };
        case createPromotionConstants.PROMOTION_CREATE_FULFILLED:
            return {
                ...state,
                createPromotionFetching: false,
                createPromotion: action.payload,
            };
        case createPromotionConstants.PROMOTION_CREATE_REJECTED:
            return {
                ...state,
                createPromotionFetching: false,
                createPromotionError: action.payload,
                createPromotion: [],
            };

        case updatePromotionConstants.PROMOTION_UPDATE_PENDING:
            return {
                ...state,
                updatePromotionFetching: true,
                updatePromotion: [],
                updatePromotionError: {},
                promotionError: {},
            };
        case updatePromotionConstants.PROMOTION_UPDATE_FULFILLED:
            return {
                ...state,
                updatePromotionFetching: false,
                updatePromotion: action.payload,
            };
        case updatePromotionConstants.PROMOTION_UPDATE_REJECTED:
            return {
                ...state,
                updatePromotionFetching: false,
                updatePromotionError: action.payload,
                updatePromotion: [],
            };

        case getPromotionTypes.PROMOTION_TYPES_GET_PENDING:
            return {
                ...state,
                getPromotionTypesFetching: true,
                getPromotionTypes: [],
                getPromotionTypesError: {},
                promotionError: {},
            };
        case getPromotionTypes.PROMOTION_TYPES_GET_FULFILLED:
            return {
                ...state,
                getPromotionTypesFetching: false,
                getPromotionTypes: action.payload.data,
            };
        case getPromotionTypes.PROMOTION_TYPES_GET_REJECTED:
            return {
                ...state,
                getPromotionTypesFetching: false,
                getPromotionTypesError: action.payload,
                getPromotionTypes: [],
            };

        case updateRoomRatePromotionConstants.PROMOTION_ROOM_RATE_UPDATE_PENDING:
            return {
                ...state,
                updateRoomRatePromotionFetching: true,
                updateRoomRatePromotion: [],
                updateRoomRatePromotionError: {},
                promotionError: {},
            };
        case updateRoomRatePromotionConstants.PROMOTION_ROOM_RATE_UPDATE_FULFILLED:
            return {
                ...state,
                updateRoomRatePromotionFetching: false,
                updateRoomRatePromotion: action.payload,
            };
        case updateRoomRatePromotionConstants.PROMOTION_ROOM_RATE_UPDATE_REJECTED:
            return {
                ...state,
                updateRoomRatePromotionFetching: false,
                updateRoomRatePromotionError: action.payload,
                updateRoomRatePromotion: [],
            };

        case clearPromotionError.PROMOTION_CLEAR_ERROR_STATE:
            return {
                ...state,
                createPromotionError : {},
                createPromotion: [],
                updatePromotionError : {},
                updatePromotion: [],
                updateRoomRatePromotionError: {},
                updateRoomRatePromotion: [],
            };

        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
