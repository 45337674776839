import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {LIST_FILTER, RESERVATION_EXCEL, RESERVATION_LIST} from "../../../../constants/api.constants";


export function getReservationList(filter) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                filter
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'RESERVATION_LIST',
            payload: axios.post(RESERVATION_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getReservationExcel(filter) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                filter,
                excelExport: true
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'RESERVATION_EXCEL',
            payload: axios.post(RESERVATION_EXCEL, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getReservationListFilter() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'LIST_FILTER',
            payload: axios.post(LIST_FILTER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function getReservationExcelClear() {
    return dispatch => {
        dispatch({
            type: 'RESERVATION_EXCEL_CLEAR_STATE',
        })
    }
}
