import React, {Component} from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import RowAtm from "../../../atomic/Row/Row";
import Container from "@material-ui/core/Container";
import {connect}  from "react-redux";
import {Animated} from "react-animated-css";
import ResetPasswordForm from "./components/ResetPasswordForm";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ButtonAtm from "../../../atomic/Button/Button";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import queryString from 'query-string';
import {resetPassword} from "../../../../redux/actions/User/ResetPassword/resetPasswordAction"
import SuccessModal from "../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import { history }    from "../../../../utils/history";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    toolbar: {
        height: '80px',
        paddingRight: '0'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#8f94a2',
        fontSize: 20,
        fontWeight: 'bold',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#8f94a2'

        },
    },
    saveButton: {
        backgroundColor: '#d71f26',
        borderRadius: '0',
        padding: '29px 40px',
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#c62128',
        },
        [theme.breakpoints.up('xs')]: {
            padding: '29px 20px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '29px 54px',
        },
    },
});

class ResetPassword extends Component {

    constructor(props){
        super(props);
        let queryParams = queryString.parse(this.props.location.search);
        this.state = {
           email : queryParams.email,
           hash : queryParams.key
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        if( (this.state.email === null || this.state.email === undefined) ||
            (this.state.hash === null || this.state.hash === undefined) ||
            (this.state.email === "" || this.state.hash === "")
        ){
            history.push('/')
        }
    }

    showMessage = () => {
        if( this.props.resetPasswordReducer.resetPasswordStatus === true ){
            return <SuccessModal status={true} message={"Şifreniz Başarılı Bir Şekilde Güncellenmiştir!"} redirectUrl={"/login?singin=1"}/>
        }

        if( this.props.resetPasswordReducer.resetPasswordStatus === false &&
            this.props.resetPasswordReducer.error.response
        ){
            let message = this.props.resetPasswordReducer.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
        return (
            <>
                <PageTitle title={ t('web-reset_password-page_title') } />
                <div className={classes.root}>
                    <AppBar className="header" position="static">
                        <Toolbar className={classes.toolbar}>
                            <Link to="/login" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <i className="fas fa-sign-in-alt" style={{marginRight: '13.5px'}} />
                                Login
                            </Link>
                            <Typography variant="h6" className="title">
                                Reset Password
                            </Typography>
                            <ButtonAtm type="submit" htmlFor="general-form" form='general-form' className={classes.saveButton} buttontext="Save" size="large" color="inherit" />
                        </Toolbar>
                    </AppBar>
                </div>
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <RowAtm>

                                <ResetPasswordForm resetPassword={this.props.resetPassword}
                                                   email={this.state.email}
                                                   hash ={this.state.hash}
                                />
                                {
                                    this.showMessage()
                                }
                            </RowAtm>
                        </div>
                    </Container>
                </Animated>
            </>

        );
    }
}
const mapStateToProps = (props) => {

    return{
        resetPasswordReducer : props.resetPasswordReducer
    }
};

const mapDispatchToProps = {
    resetPassword
};

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (connect(mapStateToProps,mapDispatchToProps)(ResetPassword));
