import React from "react";
import Dialog from "@material-ui/core/Dialog";
import WebForm from "./WebNewForm";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WebDialog extends React.Component{

    constructor(props) {
        super(props);
        t = props.t;
    }

    render(){
        return (
            <Dialog onClose={this.props.handleClose} disableBackdropClick={true} aria-labelledby="my-web-dialog" open={this.props.open} TransitionComponent={Transition}>
                <DialogTitle className="red-bottom-border" onClose={this.props.handleClose} id="my-web-dialog">
                     { t('enw-myw-domain_title') }
                </DialogTitle>
                <DialogContent>
                    <WebForm
                        languages={this.props.languages}
                        createWeb={this.props.createWeb}
                        updateWeb={this.props.updateWeb}
                        updateEditWebData={this.props.updateEditWebData}
                        webReducer={this.props.webReducer}
                        handleClickOpen = {this.props.handleClickOpen}
                        handleClose = {this.props.handleClose}
                        handleSubmitClose = {this.props.handleSubmitClose}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(withTranslation('common'))(WebDialog);
