import {getAppLang, getUserToken} from "../../utils";
import axios from "axios";
import {ROOM_TYPE_LIST} from "../../constants/api.constants";

export function roomTypes() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json', 'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "locale": getAppLang()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_TYPE',
            payload: axios.post(ROOM_TYPE_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}