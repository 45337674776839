import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import OverFlowText from "../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../atomic/Block/Block";
import PromotionCheckBox from "./PromotionCheckBox";
import UpdatePromotion from "./updatePromotion";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { isDefined } from "../../../../../utils";

const Promotion = (props) => {
    const {promotionReducers, roomId, rateId, updatePromotion, getPromotionRoomRateList, createOrUpdatePromotionErrorClear, rate, getPromotionList, channelId} = props;
    const { t } = useTranslation();

    return(
        <Grid container spacing={3}>
            {
                rate.room_rate_channel_promotion.map( promotion => (
                    <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                        <UpdatePromotion
                            promotion={promotion}
                            channelInformationData={props.channelInformationData}
                            updatePromotion={updatePromotion}
                            promotionReducers={promotionReducers}
                            getPromotionRoomRateList={getPromotionRoomRateList}
                            createOrUpdatePromotionErrorClear={createOrUpdatePromotionErrorClear}
                            getPromotionList={getPromotionList}
                        />
                        <BlockAtm className="offer-fact-border-box fact-border-box">
                            <PromotionCheckBox roomId={roomId}
                                               rateId={rateId}
                                               promotionId={promotion.id}
                                               promotionName={promotion.name}
                                               rateMappingId={props.rateMappingId}
                                               isSelected={promotion.is_selected}
                                               promotionReducers={promotionReducers}
                                               channelId={channelId}
                            />
                            <label htmlFor={"room" + roomId + "rate" + rateId + "id" + promotion.id + "channel" + channelId}>
                                <label htmlFor={"room" + roomId + "rate" + rateId + "id" + promotion.id + "channel" + channelId}>
                                    <LabelAtm
                                        labelClass="cancellation-value"
                                        text={"%" +promotion.amount}
                                    />
                                    <OverFlowText>  
                                        {
                                            isDefined(promotion.title) &&
                                            promotion.title ? promotion.title :
                                            promotion.promotion_type_id === 3 ?
                                                t('promotion_type-early_booking-before_day') + " - " + promotion.day_before + " " + t('enw-input-day_before') + ", " + t('enw-input-min_stay') + " " + promotion.min_stay + " " :
                                                promotion.promotion_type_id === 2 ?
                                                    t('promotion_type-early_booking-period') + " - " + t('enw-input-from_date') + ": " + moment(promotion.start_date).format("DD-MM-YYYY") + ", " + t('enw-input-to_date') + ": " + moment(promotion.end_date).format("DD-MM-YYYY") :
                                                    promotion.promotion_type_id === 5 ?
                                                        t('promotion_type-discount') + (promotion.min_stay ? " - " + t('enw-input-min_stay') + ": " + promotion.min_stay : "")
                                                        : t('promotion_type-last-minute') + " - " + promotion.day_before + " " + t('enw-input-last_minute') + ", " + t('enw-input-min_stay') + " " + promotion.min_stay + " "
                                        }
                                        </OverFlowText>
                                </label>
                            </label>
                        </BlockAtm>
                    </ColAtm>
                ))
            }

        </Grid>
    )
}

export default Promotion;
