import {generalInformationConstants} from "../../../constants/Content/generalinformation.constans";

const initialState = {
    fetching : false,
    property_info: {},
    additional_info: {},
    property_type:[],
    property_chains: [],
    minimum_age_policies: [],
    countries: [],
    general_timezone: [],
    property_language_spoken: [],
    buttonPassiveStatus:false,
    updateFetching:false,
    updateData:{},
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case generalInformationConstants.GENERAL_INFORMATION_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus:true,
                updateData:{}
            };
        case generalInformationConstants.GENERAL_INFORMATION_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus:false,
                property_info: action.payload.get_property.property_info,
                additional_info: action.payload.get_property.additional_info,
                property_language_spoken: action.payload.get_property.property_language_spoken,
                property_type: action.payload.property_type,
                property_chains: action.payload.property_chains,
                minimum_age_policies: action.payload.minimum_age_policies,
                countries: action.payload.countries,
                general_timezone: action.payload.general_timezone,
            };
        case generalInformationConstants.GENERAL_INFORMATION_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                buttonPassiveStatus:false,
            };
        // UPDATE
        case generalInformationConstants.UPDATE_GENERAL_INFORMATION_PENDING:
            return {
                ...state,
                buttonPassiveStatus: true,
                updateFetching: true,
            };
        case generalInformationConstants.UPDATE_GENERAL_INFORMATION_FULFILLED:
            return {
                ...state,
                buttonPassiveStatus: false,
                updateFetching: false,
                updateData: action.payload.status,
            };
        case generalInformationConstants.UPDATE_GENERAL_INFORMATION_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                buttonPassiveStatus: false,
                updateFetching: false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
