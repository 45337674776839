import React, {Component} from 'react';

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getProperty} from "../../../../../../utils";
import UpdateChannelGroupModal from "./updateChannelGroupModal";
import {
    getChannelGroupChannels,
    getChannelGroupChannelsSingle,
    updateChannelGroupClear
} from "../../../../../../redux/actions/Network/Channel/channelGroup.actions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
import {listChannels} from "../../../../../../redux/actions/channelListActions";

let t =null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class updateChannelGroup extends Component {

    constructor(props) {
        super(props);
        t = props.t;

        this.state = {
            open: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
    }

    handleClickOpen = () => {
        this.setState({open:true});

        const params = {
            property_id: getProperty(),
            group_id: this.props.groupChannel.id,
        }

        this.props.getChannelGroupChannelsSingle(params);
    };

    handleClose = () => {
        this.setState({open:false});
        this.props.updateChannelGroupClear();
        if (this.props.updateData.status === 200) {
            this.props.listChannels("null");
            this.props.getChannelGroupChannels();
        }
    };

    render() {
        const {open} = this.state;
        const {single_get_mapping, single_get_fetching} = this.props;

        return (
            <div className="w-100">
                <Button
                    className="edit-channel-price-btn"
                    endIcon={<i className="fas fa-edit" />}
                    onClick={this.handleClickOpen}
                    title={t('btn-update')}
                />
                <Dialog
                    className="group-channel-modal"
                    open={open}
                    onClose={this.handleClose}
                    disableBackdropClick={true}
                    key={3}
                    fullScreen
                    TransitionComponent={Transition}
                >
                    <DialogTitle className="red-bottom-border" onClose={this.handleClose}>
                        { !single_get_fetching && single_get_mapping.name} {" " + t('enw-channels-group_update-title') }
                    </DialogTitle>
                    <DialogContent>
                        {
                            !single_get_fetching &&
                            <UpdateChannelGroupModal singleGetMapping={single_get_mapping} />
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        single_get_mapping : props.channelGroupReducers.single_get_mapping,
        single_get_fetching : props.channelGroupReducers.single_get_fetching,
        updateData : props.channelGroupReducers.update_mapping,
    }
};

const mapDispatchToProps ={
    getChannelGroupChannelsSingle,
    updateChannelGroupClear,
    listChannels,
    getChannelGroupChannels
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(updateChannelGroup));
