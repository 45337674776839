import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import Container from "@material-ui/core/Container";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    NETWORK,
    NETWORK_CHANNEL_ROOM_RATE_SHORT,
    NETWORK_CHANNEL_CANCELLATION_SHORT
} from "../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";

import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {Grid} from "@material-ui/core";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import ContactList from "./components/ContactList";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";

import {
    getChannelContact,
    addChannelContact,
    updateChannelContact,
    deleteChannelContact,
    createOrUpdateChannelContactErrorClear
} from "../../../../../redux/actions/Network/Channel/channelContactActions";
import CreateContactModal from "./components/createContactModal";
import {getChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import {isDefined} from "../../../../../utils";

let t =null;

class ChannelContact extends Component {

    constructor(props){
        super(props);
        this.state = {
            isVisible: true,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        if ( this.props.match.params.id ) {
            this.props.getChannelSetup({ channel_id : this.props.match.params.id } );
            this.props.getChannelContact(this.props.match.params.id );
        } else {
            this.props.history.replace(NETWORK);
        }
    };

    notConnectContact = () => {
        if (isDefined(this.props.channelSetupReducers.channelInformationData.channel_category_id) &&
            this.props.channelSetupReducers.channelInformationData.channel_category_id !== 5) {
            this.props.history.replace(NETWORK);
        }
    }

    render() {

        const {channelContactReducers} = this.props;

        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelContactReducers.channelContactListFetching && this.props.channelInformationData.name + " " + t('enw-channels-contact_title') }
                />
                <ChannelMenu menuClass="channel-menu"
                             ContactColClass="active-menu-bg"
                             menuId={this.props.channelInformationData.id}
                             isConnected={this.props.channelInformationData.is_connected}
                             channelSetup={this.props.channelSetupReducers}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        {
                            !channelContactReducers.channelContactListFetching &&
                            <div className="container-width">
                                { channelContactReducers.channelContactList && channelContactReducers.channelContactList.length < 1 ? (
                                    <ColAtm>
                                        <Container maxWidth="xl">
                                            <div className="offer-container-width">
                                                <ColAtm>
                                                    <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                        <ColAtm>
                                                            <div className="m-auto first-create-room first-channel-contact-btn">
                                                                <span>
                                                                   <CreateContactModal
                                                                       clearErrorMessage = {this.props.createOrUpdateChannelContactErrorClear}
                                                                       addChannelContact = {this.props.addChannelContact}
                                                                       channelContactReducers = {this.props.channelContactReducers}
                                                                       getChannelContact = {this.props.getChannelContact}
                                                                       channelId={this.props.match.params.id}
                                                                   />
                                                                </span>
                                                            </div>
                                                        </ColAtm>
                                                        <LabelAtm labelClass="one-page-title" text={ t('enw-channels-contact_create-title') } />
                                                        <LabelAtm labelClass="one-page-subtitle" text={ t('enw-channels-contact_create-desc') } />
                                                    </ColAtm>
                                                </ColAtm>
                                            </div>
                                        </Container>
                                    </ColAtm>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12} className="mb-16">
                                            <Grid item  className="photoButtonsRow">
                                                <CreateContactModal
                                                    clearErrorMessage = {this.props.createOrUpdateChannelContactErrorClear}
                                                    addChannelContact = {this.props.addChannelContact}
                                                    channelContactReducers = {this.props.channelContactReducers}
                                                    getChannelContact = {this.props.getChannelContact}
                                                    channelId={this.props.match.params.id}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="mt-22">
                                            <ContactList
                                                clearErrorMessage = {this.props.createOrUpdateChannelContactErrorClear}
                                                updateChannelContact = {this.props.updateChannelContact}
                                                deleteChannelContact = {this.props.deleteChannelContact}
                                                channelContactReducers = {this.props.channelContactReducers}
                                                getChannelContact = {this.props.getChannelContact}
                                                channelId={this.props.match.params.id}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        }

                        {
                            this.notConnectContact()
                        }

                    </Container>
                </Animated>
                {
                    this.props.channelInformationData.is_connected &&
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-next') }
                        backTo={NETWORK_CHANNEL_ROOM_RATE_SHORT + this.props.match.params.id }
                        component={Link}
                        nextTo={NETWORK_CHANNEL_CANCELLATION_SHORT + this.props.match.params.id }
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        channelContactReducers: props.channelContactReducers,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelSetupReducers : props.channelSetupReducers
    }
};

const mapDispatchToProps ={
    getChannelContact,
    addChannelContact,
    updateChannelContact,
    deleteChannelContact,
    createOrUpdateChannelContactErrorClear,
    getChannelSetup
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelContact));
