import React from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LabelAtm from "../../../../atomic/Label/Label";
import {useTranslation} from "react-i18next";


const PlaceListInfo = ( { place }) => {
    const { t } = useTranslation();
    return(
        <ColAtm>
            <Grid container className="offer-page-block" xs={12}>
                <Grid item xs={12} md={6}>
                    <h2> { t('enw-content-place_info-title') } </h2>
                    <Divider />
                    <ColAtm className="mb-24">
                        <ColAtm xs={12} sm={4} className="offer-facility-information">
                            <LabelAtm text={ t('enw-input-working_hour') }/>
                            <LabelAtm text={ t(place.place_working_hour_language_key) }/>
                        </ColAtm>
                        <ColAtm xs={12} sm={4} className="offer-facility-information">
                            <LabelAtm text={ t('enw-input-category_type') }/>
                            {
                                Object.keys(place.category_tree).map((key) =>(

                                    Object.keys( place.category_tree[key] ).map((perKey) => (

                                            <LabelAtm text= { perKey === 'language_key' && t(place.category_tree[key][perKey]) }/>

                                    ))
                                ))
                            }
                        </ColAtm>
                        <ColAtm xs={12} sm={4} className="offer-facility-information">
                            <LabelAtm text={ t('enw-content-add_place-is_reservation_required') }/>
                            <LabelAtm text={ place.reservation_requirement ? t('enw-input-checkbox-yes') : t('enw-input-checkbox-no')  }/>
                        </ColAtm>
                    </ColAtm>
                </Grid>
            </Grid>
        </ColAtm>
    )

}

export default PlaceListInfo
