import React ,{ useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {FormControl, FormHelperText, Grid, withStyles} from "@material-ui/core";
import InputAtm from "../../../../atomic/Input/Input";
import ButtonAtm from "../../../../atomic/Button/Button";
import {withTranslation} from "react-i18next";
import ColAtm from "../../../../atomic/Col/Col";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {clearStateUserRegister,userRegister} from "../../../../../redux/actions/User/Register/userRegisterAction";
import {getLanguages} from "../../../../../redux/actions/languageActions";
import {useTranslation} from "react-i18next";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RegisterConditionsText from "./RegisterConditionsText";
import PhoneInput from "react-phone-input-2";
import {getAppLang} from "../../../../../utils";
import styled from "styled-components";

const PhoneCard = styled.div`
              .react-tel-input .form-control+div:before {
                    content: '${props => props.lang}' !important;
                    width: 80px !important;
                  }`;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const RegisterForm = () => {

    const { t } = useTranslation();

    const { register, handleSubmit,  errors , getValues, control} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        })
    const [openModal,setOpenModal ] = useState(false);
    const [openConditionsModal, setOpenConditionsModal ] = useState(false);
    const [checked, setChecked] = React.useState(false);

    const dispatch = useDispatch();


    const onSubmit = () => {
       setOpenModal(true);
    }

    const showConditions = () => {
        setOpenConditionsModal(true);
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const registerUser = () => {
        //TODO : Bu kısmın kaldırılıp test edilmesi gerekiyor.Modalların tekrar açılmaması için
        setOpenModal(false)
        dispatch(userRegister(formValue))
    }

    useEffect(() => {
        dispatch(getLanguages())
        return () => {
            dispatch(clearStateUserRegister())
        }
    }, [openModal]);

    const formValue = getValues();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'center'}} className="register-form" autoComplete="off">
                <Grid container spacing={2} justify="space-between" className="login-name-area">
                    <Grid item xs={12} sm={6}>
                        <InputAtm
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            autoComplete="off"
                            label={ t('input-name') }
                            name="name"
                            autoFocus
                            error={!!errors.name}
                            inputRef={register({
                                required: true,
                                maxLength:50,
                                minLength:3
                            })}
                        />
                        <FormHelperText error={!!errors.name}>
                            {errors.name?.type === "maxLength" && "Your input exceed max Length"}
                            {errors.name?.type === "minLength" && "Your input exceed min Length"}
                            {errors.name?.type === "required" && t("enw-notification_this_is_required") }
                            {errors.name && errors.name.message}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputAtm
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="surname"
                            label={ t('input-surname') }
                            autoComplete="off"
                            name="surname"
                            autoFocus
                            error={!!errors.surname}
                            inputRef={register({
                                required: t("enw-notification_this_is_required"),
                                minLength:3
                            })}
                        />
                        <FormHelperText error={!!errors.surname}>
                            {errors.surname && errors.surname.message}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <InputAtm
                    id="property"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    label={ t('enw-input-property_name') }
                    name="property_name"
                    autoFocus
                    error={!!errors.property_name}
                    inputRef={register({
                        required: t("enw-notification_this_is_required") , minLength:3
                    })}
                />
                <FormHelperText error={!!errors.property_name}>
                    {errors.property_name && errors.property_name.message}
                </FormHelperText>

                <PhoneCard lang={t('enw-input-phone')}>
                    <FormControl variant="outlined" fullWidth className="mt-0">
                    <Controller
                        as={
                            <PhoneInput
                                name="phone"
                                country={getAppLang() ? getAppLang() :  'de'}
                                enableSearch={true}
                                prefix={""}
                                label={ t('enw-input-phone')}
                                placeholder={ t('enw-input-phone')}
                            />

                        }
                        name="phone"
                        control={control}
                        error={!!errors.phone}
                    />
                </FormControl>
                </PhoneCard>
                <InputAtm
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label={ t('enw-input-email') }
                    name="email"
                    autoComplete="enw-mail"
                    autoFocus
                    error={!!errors.email}
                    inputRef={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t("enw-notification_invalid_email")
                        },
                        validate: value => value !== "" || t("enw-notification_this_is_required"),
                    })}
                />
                <FormHelperText error={!!errors.email}>
                    {errors.email && errors.email.message}
                </FormHelperText>

                <Grid item xs={12} className="register-conditions-text">
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onChange={handleChange}
                                name="conditionsCheckbox"
                            />
                        }
                    />
                    <Typography>
                       <span className="open-conditions-btn" onClick={showConditions}>{ t('web-register-conditions-modal-main_title') }; </span>
                        { t('web-register-conditions-desc') }
                    </Typography>
                </Grid>
                <ButtonAtm
                    className="login-button"
                    fullwidth={true}
                    variant="contained"
                    color="primary"
                    buttontext={ t('btn-register') }
                    style={{textTransform: 'none' }}
                    type="submit"
                    disabled={checked ? false : true}
                />
            </form>
            <Dialog onClose={() => setOpenModal(false)} aria-labelledby="status" open={openModal}>
                <DialogTitle className="red-bottom-border"  id="status" onClose={()=> setOpenModal(false)}>
                    { t('web-register-modal-main_title') }
                </DialogTitle>
                <DialogContent>
                    <ColAtm>
                        <ColAtm xs={12} md={12} className="photoButtonsRow">
                            <p className="register-text">{ t('web-register-model-title') } <br /> <span> { formValue.email } </span> </p>
                            <ColAtm md={12} spacing={3} className="m-auto">
                                <ColAtm md={6}>
                                    <ButtonAtm
                                        className="red-button"
                                        fullwidth={true}
                                        outline
                                        buttontext={ t('btn-back') }
                                        onClick={()=> setOpenModal(false)}
                                    />
                                </ColAtm>
                                <ColAtm md={6}>
                                    <ButtonAtm
                                        className="soft-green-confirm-button red-button"
                                        fullwidth={true}
                                        outline
                                        buttontext={ t('btn-confirm') }
                                        onClick={registerUser}
                                    />
                                </ColAtm>
                            </ColAtm>
                        </ColAtm>
                    </ColAtm>
                </DialogContent>
            </Dialog>

            <Dialog onClose={() => setOpenConditionsModal(false)} aria-labelledby="status" open={openConditionsModal}>
                <DialogTitle className="red-bottom-border"  id="status" onClose={()=> setOpenConditionsModal(false)}>
                    { t('web-register-conditions-modal-main_title') }
                </DialogTitle>
                <DialogContent>
                    <RegisterConditionsText />
                </DialogContent>
            </Dialog>

        </>
    )


}


export default (withTranslation('common'))( RegisterForm)
