import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Link} from "react-router-dom";

import {OFFER_EDIT_SHORT} from "../../../../../constants/appUrls.constants";
import CopyOfferLink from "./CopyOfferLink";
import ExportOfferListToExcel from "./ExportOfferListToExcel";
import {isDefined} from "../../../../../utils";
import {Tooltip} from "@material-ui/core";


const OfferListTable = (props) => {

    const { t } = useTranslation();
    const [data , setData] = useState([]);
    const [dataForExcel , setDataForExcel] = useState([]);
    const {offerList} = props;
    const originLocation = window.location.origin;

    useEffect( () => {
        if( Object.keys(offerList).length > 0 ){
            let tempData = [];
            let tempExcel = [];
            Object.keys(offerList).forEach( (v) => {
                tempData.push( [
                    offerList[v]['ticket_code'],
                    offerList[v]['title'],
                    offerList[v]['total']+' '+offerList[v]['currency'],
                    offerList[v]['created_at'],
                    offerList[v]['expire_date'],
                    { accept_status: offerList[v]['accept_status'], accept_status_language_key: offerList[v]['offer_accept_status']['language_key'] },
                    offerList[v]['status'],
                    { id: offerList[v]['id'],offer_code: offerList[v]['offer_code'] },
                    offerList[v]['is_payment_received'],
                ])

                tempExcel.push({
                    ticket_code: offerList[v]['ticket_code'],
                    title : offerList[v]['title'],
                    total : offerList[v]['total']+' '+offerList[v]['currency'],
                    created_at : offerList[v]['created_at'],
                    expire_date : offerList[v]['expire_date'],
                    accept_status : offerList[v]['offer_accept_status']['name'],
                    status : offerList[v]['status'],

                })
            })
            setData( [...tempData])
            setDataForExcel( tempExcel)
        }
    },[offerList])

    const  buildCsvData = () => {
        let data = [];
        if( Object.keys(offerList).length > 0 ){
            let tempDataExcel = [];
            Object.keys(offerList).forEach( (v) => {

                tempDataExcel.push(
                    {data: [
                            offerList[v]['ticket_code'],
                            offerList[v]['title'],
                            offerList[v]['total']+' '+offerList[v]['currency'],
                            moment.unix(offerList[v]['created_at']).format("DD-MM-YYYY"),
                            moment(offerList[v]['expire_date']).format("DD-MM-YYYY"),
                            offerList[v]['offer_accept_status']['name']
                        ]}
                )
            })

            data = [ ...tempDataExcel]
        }

        return data;

    }

    const options = {
        filter: false,
        selectableRowsOnClick: false,
        selectableRows: false,
        searchOpen: true,
        searchPlaceholder: t('btn-search'),
        print: false,
        download: false,
        textLabels: {
            body: {
                noMatch: t('datatable-body-nomatch'),
                toolTip: t('datatable-body-tooltip'),
            },
            pagination: {
                next: t('datatable-pagination-next'),
                previous: t('datatable-pagination-previous'),
                rowsPerPage: t('datatable-pagination-rowsperpage'),
                displayRows: t('datatable-pagination-of'),
            },
            toolbar: {
                search: t('datatable-toolbar-search'),
                downloadCsv: t('datatable-toolbar-download_excel'),
                print: t('datatable-toolbar-print'),
                viewColumns: t('datatable-toolbar-view_columns'),
                filterTable: t('datatable-toolbar-filter_table'),
            },
            filter: {
                all: t('datatable-filter-all'),
                title: t('datatable-filter-title'),
                reset: t('datatable-filter-reset'),
            },
            viewColumns: {
                title: t('datatable-filter-view_columns-title'),
                titleAria: t('datatable-filter-view_columns-title_aria'),
            },
            selectedRows: {
                text: t('datatable-selected_rows-text'),
                delete: t('datatable-selected_rows-delete'),
                deleteAria: t('datatable-selected_rows-delete-aria')
            },
        },
        downloadOptions:{
            filename:'ExtranetworkOfferList.csv',
            separator:','
        },
        onDownload: (buildHead, buildBody, columns) => {
            let excelData = buildCsvData()
            return "\uFEFF" + buildHead(columns) + buildBody(excelData);
        },
        customToolbar: () => {
            return ( <ExportOfferListToExcel data={dataForExcel} /> );
        }
    };

    const columns = [
        {
            name : t('enw-offer_list-column-ticket_code'),
            options:{
                filter:false,
            }
        },
        {
            name : t('enw-offer_list-column-offer_title'),
            options:{
                filter:false,
            }
        },
        {
            name : t('enw-offer_list-column-price'),
            options:{
                filter:true,
                customBodyRender: (value, key) => {
                    return  <span className="offer-list-price"> {value} {isDefined(data[key["rowIndex"]][8]) ?
                        <Tooltip title={data[key["rowIndex"]][8] ? t('enw-success-payment-tooltip') : t('enw-waiting-payment-tooltip')}>
                            <i className={`fas fa-money-bill ${data[key["rowIndex"]][8] ? "success-payment" : "waiting-payment"}`}/>
                        </Tooltip>
                         : ""}
                    </span>
                }
            }
        },
        {
            name : t('enw-offer_list-column-effective_date'),
            options:{
                filter:true,
                customBodyRender: (value) => {
                    return moment.unix(value).format("DD-MM-YYYY");
                },
            }
        },
        {
            name : t('enw-offer_list-column-expire_date'),
            options:{
                filter:true,
                customBodyRender: (value) => {
                    return moment(value).format("DD-MM-YYYY");
                },

            }
        },
        {
            name : t('enw-offer_list-column-approved'),
            options:{
                filter:true,
                customBodyRender: (value) => {
                    const {accept_status, accept_status_language_key} = value
                    let approved = null;
                    if( accept_status === 1 ){
                        approved = <span style={{ color: "green" }}>{t(accept_status_language_key)}</span>;
                    }else if ( accept_status === 0 ){
                        approved = <span style={{ color: "red" }}>{t(accept_status_language_key)}</span>;
                    }else if ( accept_status === 2 ){
                        approved = <span style={{ color: "darkorange" }}>{t(accept_status_language_key)}</span>;
                    }else{
                        approved = <span style={{ color: "orange" }}>{t(accept_status_language_key)}</span>;
                    }

                    return approved
                },
            }
        },
        {
            name : t('enw-offer_list-column-status'),
            options:{
                filter:true,
                customBodyRender: (value) => {
                    return value === 1 ? <span className="active-offer">{ t('enw-input-active') }</span> : <span className="inactive-offer">{ t('enw-input-inactive') }</span>
                }
            }
        },
        {
            name : 'Actions',
            options:{
                customBodyRender: (value) => {
                    const {id, offer_code} = value
                    return (
                        <>
                            <Link className="offer-list-button" to={`${OFFER_EDIT_SHORT}${id}`}><i className={"fas fa-pen"} /></Link>
                            <Link target={localStorage.getItem("mobile_login") ? "" : "_blank"} className="offer-list-button" to={`/offer/${offer_code}`}><i className={"fas fa-eye"} /></Link>
                            <CopyOfferLink dataToCopy={originLocation +'/offer/'+ offer_code}/>
                        </>
                    )
                }
            }
        }
    ]

    return(
        <MUIDataTable
            title={ t('enw-offer_list-title') }
            data={data}
            columns={columns}
            options={options}
        />
    )
}

export default OfferListTable
