import { menuConstants} from "../../constants/Public/menu.constants";
import { getUserToken,getAppLang,getProperty }  from "../../utils/index";
import { USER_MENU } from "../../constants/api.constants";
import axios from 'axios';

export const userMenu = () => {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };
    return (dispatch) => {
        return axios.post(USER_MENU, requestOptions.body, {
            headers: requestOptions.headers
        })
            .then(response => {
                dispatch(userMenuSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const userMenuSuccess =  (data) => {
    return {
        type: menuConstants.USER_MENU_SUCCESS,
        property_menu: data.data.property_list[0].property_menu[0]
    }
};
