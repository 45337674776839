import {getPlaceList } from "../../../../constants/Content/places.constanst";
import {placeFact} from "../../../../constants/Content/placeFact.constants";

const initialState = {
    fetching : false,
    placeList:[],
    error : {},
    updateFactFetching : false,
    updateFactStatus : false,
    updateFactError : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getPlaceList.GET_PLACE_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                updateFactError:{},
                updateFactStatus:{},
                error:{}
            };
        case getPlaceList.GET_PLACE_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                placeList: action.payload?.data?.places
            };
        case getPlaceList.GET_PLACE_LIST_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };

        case placeFact.PLACE_FACT_UPDATE_PENDING:
            return {
                ...state,
                updateFactFetching: true,
                updateFactStatus:{},
                updateFactError : {},
            };
        case placeFact.PLACE_FACT_UPDATE_FULFILLED:
            return {
                ...state,
                updateFactFetching: false,
                updateFactStatus:action.payload,
            };
        case placeFact.PLACE_FACT_UPDATE_REJECTED:
            return {
                ...state,
                updateFactError: action.payload,
                updateFactFetching: false,
            };
        case placeFact.PLACE_FACT_UPDATE_CLEAR:
            return {
                ...state,
                updateFactStatus:{}
            }
        default:
            return state
    }
}
