import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import ButtonAtm from "../../../atomic/Button/Button";
import {getAppLang} from "../../../../utils";

const NotAllowedPage = () => {

    const { t } = useTranslation();

    const redirectProperty = () => {
        window.open("https://www.extranetwork.com/" + getAppLang() + "/contact");
    }

    return (
        <div style={{backgroundColor: '#fdfdfd'}}>
            <Grid container className="t-center">
                <Grid item xs={12}>
                    <img src="/assets/img/gif/lock.gif" className="lock-gif" />
                </Grid>
                <Grid item xs={12} className="m-auto">
                    <h1 className="mb-0">{ t('enw-not-product_title') }</h1>
                    <h3>{ t('enw-not-product_desc') }</h3>
                </Grid>
            </Grid>
            <Grid container className="t-center">
                <Grid item xs={12} lg={6} className="m-auto">
                    <ButtonAtm
                        className="not-allowed-button bg-red"
                        buttontext={ t('enw-not-product_btn') }
                        autoFocus
                        color="primary"
                        onClick={redirectProperty}
                    />
                </Grid>
            </Grid>
        </div>
    )
}


export default (withTranslation('common'))(NotAllowedPage)
