import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import PaymentsBox from "./PaymentsBox";
import {isDefined} from "../../../../../../utils";
import CreatePaymentModal from "./CreatePaymentModal";
import {Grid} from "@material-ui/core";

const PaymentList = (props) => {

    const {
        paymentMapping,
        UpdatePaymentMapping,
        UpdatePaymentStatusMapping,
        PaymentStatusSetDefault,
        PaymentInstallmentUpdate,
        updatePaymentErrorClear,
        successPaymentClear,
        paymentTypeGet,
        createPaymentMapping,
        GetPaymentType,
        paymentTypeList,
        paymentGetListFetching
    } = props;

    const paymentPolicyListError = useSelector(props => props.paymentMapping?.errorPaymentTypeList)
    const paymentBoxStatusReducersError = useSelector(props => props.paymentReducers?.errorUpdatePaymentMappingBoxStatusData)

    useEffect( () => {},[paymentPolicyListError]);

    const showMessage = () => {
        if(paymentPolicyListError?.response?.status === 400 || paymentPolicyListError?.response?.status === 500)
        {
            let message = paymentPolicyListError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    const showBoxStatusErrorMessage = () => {
        const errorStatus = paymentBoxStatusReducersError?.response?.status
        if(errorStatus === 500 || errorStatus === 400 || errorStatus === 401)
        {
            let message = paymentBoxStatusReducersError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    return(
        <Grid container>
            {
                !paymentGetListFetching &&
                <>
                    {
                        isDefined(paymentTypeGet.currencies) && paymentTypeGet.currencies.map( paymentMappingList => (

                            <>
                                {
                                    isDefined(paymentMappingList.fields) ?
                                        <PaymentsBox
                                            paymentMappingListData={paymentMappingList}
                                            paymentMapping={paymentMapping}
                                            UpdatePaymentMapping={UpdatePaymentMapping}
                                            UpdatePaymentStatusMapping={UpdatePaymentStatusMapping}
                                            PaymentStatusSetDefault={PaymentStatusSetDefault}
                                            PaymentInstallmentUpdate={PaymentInstallmentUpdate}
                                            updatePaymentErrorClear={updatePaymentErrorClear}
                                            successPaymentClear={successPaymentClear}
                                            paymentTypeGet={paymentTypeGet}
                                            GetPaymentType={GetPaymentType}
                                            paymentTypeList={paymentTypeList}

                                        />
                                        :
                                        <CreatePaymentModal
                                            paymentMappingList={paymentMappingList}
                                            paymentMapping={paymentMapping}
                                            createPaymentMapping={createPaymentMapping}
                                            updatePaymentErrorClear={updatePaymentErrorClear}
                                            successPaymentClear={successPaymentClear}
                                            paymentTypeGet={paymentTypeGet}
                                            GetPaymentType={GetPaymentType}
                                        />
                                }
                            </>
                        ))

                    }
                </>
            }

            {
                showMessage()
            }
            {
                showBoxStatusErrorMessage()
            }
        </Grid>
    )
}

export default PaymentList;
