import {brandConstants} from "../../../constants/Content/brand.constants";

const initialState = {
    fetching : true,
    buttonPassiveStatus:false,
    updateFetching: false,
    updateData: {},
    error : {},
    get_property_brand: {},
    brandLogoStatus: false,
    brandLogoFetching: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case brandConstants.BRAND_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus:true,
                updateData: {},
                error : {}
            };
        case brandConstants.BRAND_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                get_property_brand: action.payload.get_property_brand,
            };
        case brandConstants.BRAND_REJECTED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                error: action.payload
            };
        //Update
        case brandConstants.UPDATE_BRAND_PENDING:
            return {
                ...state,
                buttonPassiveStatus: true,
                updateFetching: true,
                updateData:{},
                error : {}
            };
        case brandConstants.UPDATE_BRAND_FULFILLED:
            return {
                ...state,
                updateFetching: false,
                buttonPassiveStatus: false,
                updateData: action.payload.status,
            };
        case brandConstants.UPDATE_BRAND_REJECTED:
            return {
                ...state,
                error: action.payload,
                updateFetching: false,
                buttonPassiveStatus: false,
            };
        case brandConstants.DELETE_BRAND_LOGO_PENDING:
            return {
                ...state,
                buttonPassiveStatus: true,
                brandLogoFetching: true,
                updateData:{},
                brandLogoStatus : false,
                error : {}
            };
        case brandConstants.DELETE_BRAND_LOGO_FULFILLED:
            return {
                ...state,
                brandLogoFetching: false,
                buttonPassiveStatus: false,
                brandLogoStatus: action.payload.status,
            };
        case brandConstants.DELETE_BRAND_LOGO_REJECTED:
            return {
                ...state,
                error: action.payload,
                brandLogoFetching: false,
                buttonPassiveStatus: false,
                brandLogoStatus : false
            };
        default:
            return state
    }
}
