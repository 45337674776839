import { propertyConstants } from "../../constants/Public/property.constants";

const initialState = {
    property_list   : ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case propertyConstants.PROPERTY_REQUEST:
            return {
                ...state,
                payload    : action.response
            };
        case propertyConstants.PROPERTY_SUCCESS:
            return {
                ...state,
                property_list: action.property_list,
                payload: action.response
            };
        case propertyConstants.PROPERTY_FAILURE:
            return {};
        default:
            return state
    }
}
