import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../../atomic/Block/Block";
import PaymentCheckBox from "../../components/PaymentCheckBox";
import UpdatePaymentModal from "../../components/UpdatePaymentModal";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@material-ui/core";

const PaymentsBox = (props) => {
    const {
        paymentMappingListData,
        paymentMapping,
        UpdatePaymentMapping,
        UpdatePaymentStatusMapping,
        GetPaymentType,
        paymentTypeGet,
        updatePaymentErrorClear,
        successPaymentClear
    } = props;
    const { t } = useTranslation();

    return(
        <ColAtm className={`offer-fact-col pos-relative ${paymentMappingListData.is_default ? "default-payment" : ""}`} md={2} sm={4} xs={6} >
             <UpdatePaymentModal
                 paymentMappingListData={paymentMappingListData}
                 paymentMapping={paymentMapping}
                 UpdatePaymentMapping={UpdatePaymentMapping}
                 updatePaymentErrorClear={updatePaymentErrorClear}
                 successPaymentClear={successPaymentClear}
                 GetPaymentType={GetPaymentType}
                 paymentTypeGet={paymentTypeGet}
             />
            <BlockAtm className="offer-fact-border-box fact-border-box">
                {
                    !paymentMappingListData.is_default &&
                    <PaymentCheckBox
                        paymentMappingListData={paymentMappingListData}
                        UpdatePaymentStatusMapping={UpdatePaymentStatusMapping}
                    />
                }
                <Grid item>
                    <div className="p-6 float-left p-absolute">
                        <input type={"radio"} name={"paymentValue"}
                               id={"paymentValue" + paymentMappingListData.id}
                               className="d-none payment-radio-button"
                               defaultChecked={ paymentMappingListData.is_default }
                        />
                        {
                            paymentMappingListData.is_default ?
                            <label >
                                <Tooltip title={ t('general-default') }>
                                    <i className="channel-active-icon fas fa-star green bg-icon-green"/>
                                </Tooltip>
                            </label>
                                :
                            <></>
                        }
                    </div>
                </Grid>
                <label htmlFor={paymentMappingListData.id}>
                    <label htmlFor={paymentMappingListData.id}>
                        <Grid item xs={12} className="cancellation-info-col">
                            <ColAtm className="channel-logo payment-create-btn" htmlFor={paymentMappingListData.id}>
                                <label htmlFor={paymentMappingListData.id}>
                                    <p className="payment-code">{paymentMappingListData.code}</p>
                                </label>
                            </ColAtm>
                        </Grid>
                        <OverFlowText>{t(paymentMappingListData.language_key)}</OverFlowText>
                    </label>
                </label>
            </BlockAtm>
        </ColAtm>
    )
}

export default PaymentsBox;
