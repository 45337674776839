import axios from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import { CREATE_OFFER,STORE_OFFER } from "../../../../constants/api.constants";


export function createOffer() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "locale" : getAppLang(),
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CREATE_OFFER',
            payload: axios.post(CREATE_OFFER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function offerStore(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "locale"                 : getAppLang(),
                "property_id"            : getProperty(),
                "title"                  : param.title,
                "currency"               : param.currency,
                "email"                  : param.email,
                "confirm_type"           : param.confirm_type,
                "payment_type_mapping_id": param.payment_list_type,
                "expire_date"            : param.expire_date,
                "language"               : param.language,
                "fact_mapping"           : param.fact_mapping,
                "hotel_features_mapping" : param.hotel_features_mapping,
                "contact_mapping"        : param.contact_mapping,
                "room_mapping"           : param.room_mapping,
                "accommodation_mapping"  : param.accommodation_mapping,
                "photo_mapping"          : param.photo_mapping,
                "cover_photos"           : param.cover_photos,
                "important_notes"        : param.important_notes,
                "has_cancellation_policy": param.has_cancellation_policy,
                "cancellation_policy"    : param.cancellation_policy,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'STORE_OFFER',
            payload: axios.post(STORE_OFFER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}
