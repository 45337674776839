import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer           from '@material-ui/core/Drawer';
import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemText     from '@material-ui/core/ListItemText';
import CssBaseline      from '@material-ui/core/CssBaseline';
import Typography       from '@material-ui/core/Typography';
import Card             from '@material-ui/core/Card';
import CardContent      from '@material-ui/core/CardContent';
import ColAtm           from "../../../../atomic/Col/Col";
import 'react-circular-progressbar/dist/styles.css';
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {CircularProgressbar} from "react-circular-progressbar";
import Divider from "@material-ui/core/Divider";
import ButtonAtm from "../../../../atomic/Button/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import StatusBox from "./StatusBox";
import {getDashboardOffer} from "../../../../../redux/actions/Offer/OfferDashboard/dasboardActions";
import Grid from "@material-ui/core/Grid";
import {propertyProducts} from "../../../../../redux/actions/propertyProductsActions";
import {isDefined} from "../../../../../utils";
import NotAllowedPage from "../../../Global/NotAllowedPage/NotAllowedPage";
import {NOT_ALLOWED_DASHBOARD_SHORT} from "../../../../../constants/appUrls.constants";
import Charts from "../../../Global/Charts";


let t=null

const drawerWidth = 101;
const leftMenuWidth = 252;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '999',
    },
    leftMenu: {
        width: 0,
        flexShrink: 0,
    },
    leftMenuPaper: {
        width: leftMenuWidth,
        marginLeft: 102,
        marginTop: 81
    },
    drawerPaper: {
        width: drawerWidth,
        height: '-webkit-fill-available',
        marginTop: '81px',
        display: 'inline-grid'
    },
    listItem: {
        display: 'block',
        textAlign: 'center !important',
        margin: 'auto',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftBarShift: {
        width: `calc(100% - ${leftMenuWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        boxShadow: '0 2px 4px 0 #ccced5',
        backgroundColor: '#ffffff',
        padding: '11px 12.14px'
    },
    hide: {
        display: 'none',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 125,
        marginRight: -60,
        paddingRight: 0,
    },
    content: {
        flexGrow: 1,
        padding: '24px 68px',
    },
    toolbar: theme.mixins.toolbar,
}));

class OfferDashboardData extends Component{

    constructor(props) {
        super(props);
        t = props.t;
    }

    render(){

        const dashboard = this.props.property_dashboard;
        const leftDrawer = this.props.left_drawer;
        const fetching = this.props.fetching
        const dashboardOffer = this.props.dashboardOfferReducer


        const allowedPage = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageOffer = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        function Dashboard(props) {
            const classes = useStyles();
            function Sidebar(props) {
                const sidebar = (
                    <div className={classes.root}>
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <List className={classes.listItem}>
                                {props.siderbarItems.map((post , key) =>
                                    <Link key={key} to={post.link} className="completed-title" variant="body2" color="textSecondary" >
                                        <ListItem className={post.isActive === true ? 'active-sideMenu sidebar-list': 'sidebar-list'} button key={post.id}>
                                            <div className={post.isActive === true ? 'active-sidebar': 'sidebar-items'}>
                                                <i className={post.icon} />
                                            </div>
                                            <ListItemText className="dashboard-menu" primary={post.text} />
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                            <Charts />
                        </Drawer>
                    </div>
                );
                return (
                    <>
                        {sidebar}
                    </>
                );
            }

            const siderbarItems = [
                {id: 1, isActive: false, text: `${ t('enw-product_one-title') }`, icon: 'fas fa-hotel', link:'content'},
                {id: 2, isActive: false, text: `${t('enw-product_two-title') }`, icon: 'fas fa-chart-network', link: !props.propertyProductsReducer.fetching ? isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? "network" : NOT_ALLOWED_DASHBOARD_SHORT + "/network" : "#" },
                {id: 3, isActive: true, text: `${t('enw-product_three-title') }`, icon: 'fas fa-paperclip', link: !props.propertyProductsReducer.fetching ? isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? "offer" : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" : "#" },
                {id: 4, isActive: false, text: `${t('enw-product_four-title') }`, icon: 'fas fa-network-wired', link: !props.propertyProductsReducer.fetching ? isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? "web" : NOT_ALLOWED_DASHBOARD_SHORT + "/web" : "#" },
                {id: 5, isActive: false, text: `${t('enw-product_six-title')}`, icon: 'fas fa-calendar-alt', link: !props.propertyProductsReducer.fetching ? isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? "booking-engine" : NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" : "#" },
                {id: 6, isActive: false, text: `${t('enw-product_five-title') }`, icon: 'fas fa-money-bill-wave-alt', link: !props.propertyProductsReducer.fetching ? isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? "payment" : NOT_ALLOWED_DASHBOARD_SHORT + "/payment" : "#" }
            ];

            const [open] = React.useState(false);
            return (
                <Grid container className="payment-dashboard">
                    <Grid item md={1} className="m-none" style={{ height: 'fit-content'}}>
                        <Sidebar siderbarItems={siderbarItems} />
                        <Drawer
                            className={classes.leftMenu}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            classes={{
                                paper: classes.leftMenuPaper,
                            }}
                        >
                            <div className={classes.drawerHeader}>
                                <IconButton className="drawer-back-button">
                                    <i className="fas fa-arrow-left" />
                                </IconButton>
                            </div>
                            <ColAtm className="progress-bar-content">
                                <CircularProgressbar
                                    className="profile-rate"
                                    counterClockwise
                                />
                                <p className="profile-power">
                                    Profile Rate
                                    <br/>
                                    <span className="bold-black-text"></span>
                                </p>
                                <Card className="drawer-info">
                                    <CardContent>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            You can increase the profile strength by starting to fill the Photo and amenties fields.
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Divider />
                                <ColAtm>
                                    <ColAtm>
                                        <Typography className="bold-black-text">
                                            What are the benefits of increasing Profile Strength?
                                        </Typography>
                                    </ColAtm>
                                    <ColAtm className="hints-padding">
                                        <ColAtm md={3} xl={2}>
                                            <i className="far fa-lightbulb-on hints-icon" />
                                        </ColAtm>
                                        <ColAtm className="hints-border" md={9} xl={10}>
                                            <Typography className="dashboard-hints" style={{textAlign:'left', paddingLeft: '10px'}}>
                                                Completing your property's general informations
                                            </Typography>
                                        </ColAtm>
                                    </ColAtm>
                                    <Divider />
                                    <ColAtm className="hints-padding">
                                        <ColAtm md={3} xl={2}>
                                            <i className="far fa-lightbulb-on hints-icon" />
                                        </ColAtm>
                                        <ColAtm className="hints-border" md={9} xl={10}>
                                            <Typography className="dashboard-hints" style={{textAlign:'left', paddingLeft: '10px'}}>
                                                Updating room details.
                                            </Typography>
                                        </ColAtm>
                                    </ColAtm>
                                    <Divider />
                                    <ColAtm className="hints-padding">
                                        <ColAtm md={3} xl={2}>
                                            <i className="far fa-lightbulb-on hints-icon" />
                                        </ColAtm>
                                        <ColAtm className="hints-border" md={9} xl={10}>
                                            <Typography className="dashboard-hints" style={{textAlign:'left', paddingLeft: '10px'}}>
                                                Upload your property's photos
                                            </Typography>
                                        </ColAtm>
                                    </ColAtm>
                                    <Divider />
                                    <ButtonAtm className="photoUploadButton all-hints-button" buttontext={'All View'} fullwidth={true} />

                                </ColAtm>
                            </ColAtm>
                        </Drawer>
                    </Grid>
                    <Grid item xs={12} className="m-auto web-dashboard">
                        <div>
                            <CssBaseline />
                            {
                                isDefined(allowedPage[0]) && !allowedPage[0].property_has_product ?
                                    <Grid item xs={12} lg={6} className="no-buy-product">
                                        <Card className="p-24">
                                            <NotAllowedPage />
                                        </Card>
                                    </Grid>
                                    :
                                    <StatusBox fetching={fetching} dashBoardOffer={dashboardOffer} />
                            }
                        </div>
                    </Grid>
                </Grid>
            );
        }
        return(
            <Dashboard dashboard={dashboard} leftDrawer={leftDrawer} propertyProductsReducer={this.props.propertyProductsReducer} />
        );
    }
}

const mapStateToProps = (props) => {
    return {
        dashboardOfferReducer : props.dashboardOfferReducer
    }
};

const mapDispatchToProps ={
    getDashboardOffer
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(OfferDashboardData));
