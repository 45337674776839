import React from "react";
import {NoSsr} from "@material-ui/core";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../atomic/Col/Col";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {getAppLang, isDefined} from "../../../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import {PAYMENT_UPDATE_SHORT} from "../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";

let t=null;

class Detail extends React.Component {

    constructor(props){
        super(props);
        t = props.t;

        this.state = {
            copied: false
        };
    }

    handleCopy = (e, copyText) =>  {
        e.preventDefault();
        navigator.clipboard.writeText(copyText)
        this.setState({copied:true});

        setTimeout(() => {
            this.setState({copied: false});
        }, 1000)

    }

    render() {

        const updateParamsData = (
            isDefined(this.props.manualTransactionList) &&
            isDefined(this.props.manualTransactionList.paramsArray)) &&
            this.props.manualTransactionList.paramsArray;


        const manualTransactionList = this.props.manualTransactionList &&
            Object.keys(this.props.manualTransactionList).length > 0 ? this.props.manualTransactionList : null;

        return manualTransactionList ? (
            <NoSsr>
                <Link to={ PAYMENT_UPDATE_SHORT+manualTransactionList.id} className="filter-action-button float-right mb-24">
                    <i className="fas fa-plus-circle" /> { t('btn-update') }
                </Link>
                <ColAtm className="offer-info-block">
                    <h2>{ t('btn-details') }</h2>
                    <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                        <Divider />
                    </Grid>
                    <Grid item container xs={12} className="reservation-info-block">
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-reservation-detail-order_id') }</h3>
                            <p>{ manualTransactionList.order_id || '-' }</p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-title') }</h3>
                            <p>{ updateParamsData.title || '-' }</p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-description') }</h3>
                            <p>{ updateParamsData.description || '-' }</p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-email') }</h3>
                            <p>{ updateParamsData.email || '-' }</p>
                        </Grid>

                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-transaction_type') }</h3>
                            <p>
                                { manualTransactionList.transaction_type || '-'}
                            </p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-offer_list-column-effective_date') }</h3>
                            <p>{ moment.unix(manualTransactionList.created_at).format("DD-MM-YYYY HH:mm:ss") }</p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-fine') }</h3>
                            <p> {updateParamsData.base_amount} {manualTransactionList.base_currency} </p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-input-commission') }</h3>
                            <p>{ updateParamsData.commission || '-' }</p>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <h3>{ t('enw-payment-total_amount') }</h3>
                            <p> {updateParamsData.amount} {manualTransactionList.currency} </p>
                        </Grid>
                        <Grid item lg={4}>
                        </Grid>
                        {
                            manualTransactionList.installment > 0 &&
                            <Grid item xs={12}>
                                <h3>{ t('be-input-special_note') }</h3>
                                <p>{ manualTransactionList.installment }</p>
                            </Grid>
                        }
                        <Grid item xs={12} lg={4}>
                            <h3>{ t('enw-payment-transaction_detail-payment_link-title') }</h3>
                            <a href={manualTransactionList.payment_link} target="_blank" rel="noreferrer noopener"> {manualTransactionList.payment_link} </a>

                            <Tooltip title={this.state.copied ? t('btn-copied') : t('btn-copy')}>
                                <i className="fas fa-copy"
                                   onClick={(e) =>
                                       this.handleCopy(e, isDefined(manualTransactionList.payment_link) && manualTransactionList.payment_link)}
                                />
                            </Tooltip>
                        </Grid>
                        {
                            isDefined(manualTransactionList.related_transactions) &&
                            manualTransactionList.related_transactions.some(item => item.status === 1) &&
                                <Grid item xs={12} lg={4}>
                                    <h3>{ t('enw-payment-transaction_detail-payment_receipt-link-title') }</h3>
                                    <a href={manualTransactionList.payment_link + "/" + getAppLang() + "/receipt"} target="_blank" rel="noreferrer noopener"> {manualTransactionList.payment_link + "/" + getAppLang() + "/receipt"} </a>

                                    <Tooltip title={this.state.copied ? t('btn-copied') : t('btn-copy')}>
                                        <i className="fas fa-copy"
                                           onClick={(e) =>
                                               this.handleCopy(e, isDefined(manualTransactionList.related_transactions) && manualTransactionList.payment_link + "/" + getAppLang() + "/receipt")}
                                        />
                                    </Tooltip>
                                </Grid>
                        }

                    </Grid>
                </ColAtm>
                {
                    manualTransactionList.related_transactions.length > 0 &&
                    <ColAtm className="offer-info-block pb-24">
                        <h2>{ t('enw-reservation-detail-transaction-title') }</h2>
                        <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                            <Divider />
                        </Grid>
                        <Grid item container xs={12} className="reservation-info-block pt-0 pb-0">

                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-reservation-detail-transaction_code') }</h3>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-fine') }</h3>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-payment-installment') }</h3>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-input-time_of_transaction') }</h3>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                                <h3>{ t('enw-offer_list-column-status') }</h3>
                            </Grid>
                            <Grid item xs={6} lg={2}>
                            </Grid>
                            {
                                isDefined(manualTransactionList.related_transactions) &&
                                manualTransactionList.related_transactions.map((transaction, transactionKey) => (
                                    <>
                                        {
                                            transactionKey > 0 &&
                                            <Grid item spacing={3} xs={12} className="mt-10 mb-16">
                                                <Divider />
                                            </Grid>
                                        }

                                        <Grid item xs={6} lg={2}>
                                            <p>{ transaction.code || '-' }</p>
                                        </Grid>
                                        <Grid item xs={6} lg={2}>
                                            {
                                                transaction.exchange_rate === 1 ?
                                                    <p>{ transaction.amount } { transaction.currency }</p> :
                                                    <p>{ transaction.amount } { transaction.currency } ({transaction.base_amount} { transaction.base_currency })</p>
                                            }
                                        </Grid>
                                        <Grid item xs={6} lg={2}>
                                            <p>{ transaction.installment }</p>
                                        </Grid>
                                        <Grid item xs={6} lg={2}>
                                            <p>{ moment.unix(transaction.created_at ).format("DD-MM-YYYY HH:mm:ss") || '-'  }</p>
                                        </Grid>
                                        <Grid item xs={6} lg={2}>
                                            {
                                                transaction.status === 0 ? <p className="red">{ t('enw-payment_transaction-status-error') }  </p> :
                                                    transaction.status === 1 ? <p className="green">{ t('enw-payment_transaction-status-success') } </p> :
                                                        transaction.status === 2 ? <p className="blue">{ t('enw-payment_transaction-status-start') } </p>  :
                                                            transaction.status === 3 ? <p className="orange">{ t('enw-payment_transaction-status-pending') } </p>:
                                                                transaction.status === 4 && <p className="purple">{ t('enw-payment_transaction-status-cancel_refund') } </p>
                                            }
                                        </Grid>
                                        {
                                            isDefined(transaction.message) &&
                                            <Grid item xs={12}>
                                                <h3>{ t('enw-reservation-detail-transaction_message-title') }</h3>
                                                <p>{ transaction.message }</p>
                                            </Grid>
                                        }
                                        <Grid item xs={6} lg={2}>
                                        </Grid>
                                    </>
                                ))
                            }
                        </Grid>
                    </ColAtm>
                }

            </NoSsr>
        ) :
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center">
                <h2>  { t('enw-notification-no_results') } </h2>
            </Grid>
    }


}

export default compose(withTranslation('common')) (Detail);
