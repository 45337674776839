import { menuConstants } from "../../constants/Public/menu.constants";

const initialState = {
    property_menu   : ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case menuConstants.USER_MENU_REQUEST:
            return {
                ...state,
                payload    : action.response
            };
        case menuConstants.USER_MENU_SUCCESS:
            return {
                ...state,
                property_menu: action.property_menu,
                payload: action.response
            };
        case menuConstants.USER_MENU_FAILURE:
            return {};
        default:
            return state
    }
}
