import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import {getChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import {
    UpdateChannelCancellation,
    addChannelCancellation,
    getChannelCancellationList,
    UpdateCancellationPolicy
} from "../../../../../redux/actions/Network/Channel/channelCancellation.actions";
import {Grid} from "@material-ui/core";
import ChannelCancellationPolicyButtons
    from "../../../../organisms/ChannelCancellationPolicyButtons/ChannelCancellationPolicyButtons";
import FreeDialog from "./components/createFreeCancellation";
import NonRefundableDialog from "./components/createNonRefundableCancellation";
import RefundableDialog from "./components/createRefundCancellation";
import FooterAtm from "../../../../atomic/Footer/Footer";
import ResponsiveDialog from "./components/ResponsiveDialog";
import {
    NETWORK_CHANNEL_PAX_PRICING_SHORT,
    NETWORK_CHANNEL_PAYMENT_SHORT,
    NETWORK_CHANNEL_ROOM_RATE_SHORT,
    NETWORK_CHANNEL_CONTACT_SHORT
} from "../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";
import {isDefined} from "../../../../../utils";
import ColAtm from "../../../../atomic/Col/Col";
import AddRoomButton from "../../../../organisms/AddRoomButton/AddRoomButton";
import LabelAtm from "../../../../atomic/Label/Label";
import Rooms from "./components/Rooms";
let t =null;

class ChannelCancellation extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true,
            open : false,
            openFree : false,
            openRefundable : false,
            cancellationPolicyStatus : false
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup( { channel_id : this.props.match.params.id } );
            this.props.getChannelCancellationList( { channel_id : this.props.match.params.id } );
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
    }

    handleClose = () => {
        this.setState({open:false});
    };
    handleOpen = () => {
        this.setState({open:true});
    };
    handleFreeClose = () => {
        this.setState({openFree:false});
    };
    handleFreeOpen = () => {
        this.setState({openFree:true});
    };
    handleRefundableClose = () => {
        this.setState({openRefundable:false});
    };
    handleRefundableOpen = () => {
        this.setState({openRefundable:true});
    };

    render() {
        const roomPriceType = isDefined(this.props.channelSetupReducers.channelSetupSubmitData.data) && this.props.channelSetupReducers.channelSetupSubmitData.data.room_price_type.filter((price) => price.is_selected === true).map( price => price.room_pricing_type_id );
        const ChannelRoomPriceType = roomPriceType === false ? isDefined(this.props.channelSetupReducers.channelInformationData.channel_room_pricing_type) && this.props.channelSetupReducers.channelInformationData.channel_room_pricing_type.id : roomPriceType[0];

        const channelCategoryId = isDefined(this.props.channelSetupReducers.channelInformationData.channel_category_id) &&
            this.props.channelSetupReducers.channelInformationData.channel_category_id;

        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelSetupReducers.fetching && this.props.channelInformationData.name + " " + t('enw-offer-cancellation_policy-title') }
                />
                <ChannelMenu menuClass="channel-menu" CancellationColClass="active-menu-bg"
                             menuId={this.props.channelInformationData.id}
                             isConnected={this.props.channelInformationData.is_connected}
                             channelSetup={this.props.channelSetupReducers}
                />

                {
                    !this.props.cancellationFetching && !this.props.setupFetching ?
                        (Object.keys(this.props.channelCancellationListData).length > 0
                            && Object.keys(this.props.channelCancellationListData[0].property_room_rate_mapping[0].room_rate_channel_cancellation_policy).length > 0 ) ? (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width cancellation-policy-list">
                                        <Grid container>
                                            <Grid item xs={12} className="mb-16">
                                                <Grid item  className="photoButtonsRow">
                                                    <ChannelCancellationPolicyButtons
                                                        FreeCancellation={<FreeDialog
                                                            addChannelCancellation={this.props.addChannelCancellation}
                                                            updateStatus={this.props.updateStatus}
                                                            updateError={this.props.updateError}
                                                            handleClose={this.handleFreeClose}
                                                            channelInformationData={this.props.channelInformationData}
                                                            handleOpen={this.handleFreeOpen}
                                                            open={this.state.openFree}
                                                        />}
                                                        NonRefundableCancellation={
                                                            <NonRefundableDialog
                                                                addChannelCancellation={this.props.addChannelCancellation}
                                                                updateStatus={this.props.updateStatus}
                                                                updateError={this.props.updateError}
                                                                handleClose={this.handleClose}
                                                                channelInformationData={this.props.channelInformationData}
                                                                handleOpen={this.handleOpen}
                                                                open={this.state.open}
                                                            />
                                                        }
                                                        RefundableCancellation={<RefundableDialog
                                                            addChannelCancellation={this.props.addChannelCancellation}
                                                            updateStatus={this.props.updateStatus}
                                                            updateError={this.props.updateError}
                                                            handleClose={this.handleRefundableClose}
                                                            channelInformationData={this.props.channelInformationData}
                                                            handleOpen={this.handleRefundableOpen}
                                                            open={this.state.openRefundable}
                                                        />}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    (!this.props.cancellationFetching && !this.props.setupFetching) &&
                                                    <Rooms
                                                        channelId = {this.props.match.params.id}
                                                        channelInformationData={this.props.channelInformationData}
                                                        channelCancellationListData={this.props.channelCancellationListData}
                                                        UpdateChannelCancellation={this.props.UpdateChannelCancellation}
                                                        UpdateCancellationPolicy={this.props.UpdateCancellationPolicy}
                                                        updatePolicyStatus={this.props.channelCancellationReducers.updatePolicyStatus}
                                                        updatePolicyError={this.props.channelCancellationReducers.updatePolicyError}
                                                        getChannelCancellationList={this.props.getChannelCancellationList}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Container>
                            </Animated>
                        )
                        :
                        (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width">
                                        <ColAtm>
                                            <Container maxWidth="xl">
                                                <div className="offer-container-width">
                                                    <ColAtm>
                                                        <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                            <ColAtm>
                                                                <div className="m-auto first-create-room">
                                                                    <span>
                                                                        <AddRoomButton
                                                                            roomMenuItem={
                                                                                <ResponsiveDialog
                                                                                    addChannelCancellation={this.props.addChannelCancellation}
                                                                                    updateStatus={this.props.updateStatus}
                                                                                    updateError={this.props.updateError}
                                                                                    handleClose={this.handleClose}
                                                                                    channelInformationData={this.props.channelInformationData}
                                                                                    handleOpen={this.handleOpen}
                                                                                    open={this.state.open}
                                                                                />
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </ColAtm>
                                                            <LabelAtm labelClass="one-page-title" text={ t('enw-channels-create_cancellation_policy-title') } />
                                                            <LabelAtm labelClass="one-page-subtitle" text={ t('enw-channels-create_cancellation_policy-sub_title') } />
                                                        </ColAtm>
                                                    </ColAtm>
                                                </div>
                                            </Container>
                                        </ColAtm>
                                    </div>
                                </Container>
                            </Animated>
                         )
                        :
                        <></>
                }
                {
                    isDefined(this.props.channelInformationData.logo) &&
                    <Grid className="channel-logo-bottom">
                        <img src={this.props.channelInformationData.logoUrl} alt="mini-logo" />
                    </Grid>
                }
                {
                    this.props.channelInformationData.is_connected &&
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-next') }
                        backTo={channelCategoryId === 5 ? NETWORK_CHANNEL_CONTACT_SHORT + this.props.match.params.id : NETWORK_CHANNEL_ROOM_RATE_SHORT + this.props.match.params.id}
                        component={Link}
                        nextTo={  ChannelRoomPriceType !== 2 ? NETWORK_CHANNEL_PAX_PRICING_SHORT + this.props.match.params.id : NETWORK_CHANNEL_PAYMENT_SHORT + this.props.match.params.id  }
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        channelCancellationListData : props.channelCancellationReducers.channelCancellationListData,
        setupFetching : props.channelSetupReducers.fetching,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        updateStatus : props.channelCancellationReducers.updateStatus,
        channelCancellationReducers : props.channelCancellationReducers,
        updateError : props.channelCancellationReducers.updateError,
        cancellationFetching : props.channelCancellationReducers.fetching,
        channelSetupReducers : props.channelSetupReducers,
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    getChannelCancellationList,
    addChannelCancellation,
    UpdateChannelCancellation,
    UpdateCancellationPolicy
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelCancellation));
