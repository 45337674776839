import React, {Component}   from 'react';
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {listChannels} from "../../../../../../redux/actions/channelListActions";
import InventoryList from "./InventoryList";
import {getChannelGroupChannels} from "../../../../../../redux/actions/Network/Channel/channelGroup.actions";
import GetChannels from "../../../Channels/components/channelList";
let t =null;

class InventoryListIndex extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.listChannels();
        this.props.getChannelGroupChannels();
    }

    render() {
        return (
            <>
                <PageTitle title={  t('enw-network-inventory_list-page_title') } />

                <InventoryList
                    channel_list={this.props.channel_list}
                    channelGroupList={this.props.channel_group_list}
                    channel_list_reducers={this.props.channel_list_reducers}
                />

            </>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        channel_list : props.channelListReducers.channel_list,
        channel_group_list : props.channelGroupReducers,
        channel_list_reducers : props.channelListReducers
    }
};

const mapDispatchToProps ={
    listChannels,
    getChannelGroupChannels
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(InventoryListIndex));
