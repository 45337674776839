import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    CHANNEL_PAYMENT_GET, CHANNEL_PAYMENT_ADD, GET_CHANNEL_CANCELLATION
} from "../../../../constants/api.constants";


export function getChannelPaymentPolicy(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CHANNEL_PAYMENT',
            payload: axios.post(CHANNEL_PAYMENT_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getChannelPaymentCancellationPolicy() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CHANNEL_CANCELLATION',
            payload: axios.post(GET_CHANNEL_CANCELLATION, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response?.data?.data)
        })
    }
}

export function addChannelPaymentPolicy(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                channel_tax: params.channel_tax,
                payments : params.payment,

            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ADD_CHANNEL_PAYMENT',
            payload: axios.post(CHANNEL_PAYMENT_ADD, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
