import React, {Component}   from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";

import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import {Animated} from "react-animated-css";
import LabelAtm from "../../../atomic/Label/Label";
import {ClickAwayListener, makeStyles} from "@material-ui/core";
import ButtonAtm from "../../../atomic/Button/Button";
import BlockAtm from "../../../atomic/Block/Block";
import RequestBox from "../../../molecules/RequestBox/RequestBox";
import {NETWORK} from "../../../../constants/appUrls.constants";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    div: {
        position: 'absolute',
        top: 28,
        right: 0,
        left: 0,
        width: '120px',
        zIndex: '9',
        borderRadius: '4px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.11)',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

function RateFilter() {
    const classes = useStyles();
    const [title, setTitle] = React.useState("Corporate");
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.wrapper}>
                <ButtonAtm
                    className="channels-filter-button"
                    buttontext={title}
                    type="button"
                    endIcon={<i className="fas fa-sort-down" />}
                    onClick={handleClick}
                />
                {open ? (
                    <BlockAtm className={classes.div}>
                        <ButtonAtm
                            className="inventory-channels-button"
                            buttontext="Corporate"
                            type="button"
                            onClick={() => setTitle("Corporate")}
                        />
                        <ButtonAtm
                            className="inventory-channels-button"
                            buttontext="OTA"
                            type="button"
                            onClick={() => setTitle("OTA")}
                        />
                        <ButtonAtm
                            className="inventory-channels-button"
                            buttontext="Tour Operator"
                            type="button"
                            onClick={() => setTitle("Tour Operator")}
                        />
                        <ButtonAtm
                            className="inventory-channels-button"
                            buttontext="GDS"
                            type="button"
                            onClick={() => setTitle("GDS")}
                        />
                    </BlockAtm>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

class Requests extends Component {
    render() {
        return (
            <>
                <PageTitle title="Channels - ExtraNetWork" />
                <PageHeaderAtm exitText="Dashboard" backButton={NETWORK} text="Channels" />
                <Container maxWidth="xl">
                    <div className="container-width">
                        <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                            <ColAtm>
                                <ColAtm>
                                    <LabelAtm labelClass="fact-title" variant="h6" component="h2" text="Pending" />
                                    <RateFilter />
                                </ColAtm>
                                <ColAtm>
                                    <RequestBox
                                        checkboxId={3}
                                        src="/assets/img/brands/tui-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"hotwire"}
                                        text="Confirm"
                                        logoLabel="Corporate"
                                        htmlFor={3}
                                    />
                                    <RequestBox
                                        checkboxId={4}
                                        src="/assets/img/brands/kayak-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"expedia"}
                                        text="Confirm"
                                        logoLabel="Tour Operator"
                                        htmlFor={4}
                                    />
                                    <RequestBox
                                        checkboxId={4}
                                        src="/assets/img/brands/booking-com-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"expedia"}
                                        logoLabel="Corporate"
                                        text="Confirm"
                                        htmlFor={4}
                                    />
                                    <RequestBox
                                        checkboxId={4}
                                        src="/assets/img/brands/tripadvisor-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"expedia"}
                                        logoLabel="Corporate"
                                        text="Confirm"
                                        htmlFor={4}
                                    />
                                    <RequestBox
                                        checkboxId={4}
                                        src="/assets/img/brands/priceline-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"expedia"}
                                        logoLabel="Corporate"
                                        text="Confirm"
                                        htmlFor={4}
                                    />
                                    <RequestBox
                                        checkboxId={4}
                                        src="/assets/img/brands/trivago-logo.svg"
                                        className="channel-box"
                                        colClassname="channel-logo-col"
                                        value={2}
                                        defaultChecked={true}
                                        factName={"expedia"}
                                        logoLabel="Corporate"
                                        text="Confirm"
                                        htmlFor={4}
                                    />
                                </ColAtm>
                            </ColAtm>
                        </Animated>

                    </div>
                </Container>
            </>
        );
    }
}
export default Requests;
