import React, {useEffect} from 'react'
import Rates from "./Rates";
import {useSelector} from "react-redux";
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";

const Rooms = (props) => {

    const { promotionReducers, updatePromotion, getPromotionList, getPromotionRoomRateList, createOrUpdatePromotionErrorClear } = props
    const updatePromotionRoomRateError = useSelector(props => props.promotionReducers?.updateRoomRatePromotionError)

    useEffect( () => {},[updatePromotionRoomRateError]);

   const showMessage = () => {

        if(updatePromotionRoomRateError?.response?.status === 400 || updatePromotionRoomRateError?.response?.status === 500)
        {
            let message = updatePromotionRoomRateError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

    }

    return(
        <>
            {
                promotionReducers.promotionRoomRateList.map( room => (
                    <ExpansionPanel className="accordion-room-table" key={room.id} defaultExpanded>
                        <ExpansionPanelSummary
                            expandIcon={<i className="fas fa-caret-down" />}
                            aria-label="Expand">
                            <ColAtm>
                                <ColAtm md={10} xl={11}>
                                    <i className="fas fa-bed room-rate-bed ml-16" />
                                    <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                </ColAtm>
                            </ColAtm>
                        </ExpansionPanelSummary>
                        <Rates channelId={props.channelId}
                               roomId={room.id}
                               channelInformationData={props.channelInformationData}
                               rates={room.property_room_rate_mapping}
                               promotionReducers={promotionReducers}
                               updatePromotion={updatePromotion}
                               getPromotionList={getPromotionList}
                               getPromotionRoomRateList={getPromotionRoomRateList}
                               createOrUpdatePromotionErrorClear={createOrUpdatePromotionErrorClear}
                        />
                    </ExpansionPanel>

                ))

            }
            {
                showMessage()
            }
        </>
    )
}

export default Rooms;
