import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Controller, useFieldArray} from "react-hook-form";

import InputAtm from "../../atomic/Input/Input";
import ColAtm from "../../atomic/Col/Col";
import Grid from "@material-ui/core/Grid";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormHelperText
} from "@material-ui/core";
import ButtonAtm from "../../atomic/Button/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import {isDefined} from "../../../utils";


const CancellationPolicy = (props) => {

    const { t } = useTranslation();
    const {register, errors, control, watch, name, clearError, setValue} = props

    const { fields, remove, append} = useFieldArray({
        control,
        name: name
    });

    const has_cancellation_policy = watch("has_cancellation_policy");

    useEffect( () => {
        if( has_cancellation_policy === "false" && errors.hasOwnProperty("cancellation_policy") === true){
            clearError(["cancellation_policy"]);
            setValue([
                { cancellation_policy: [{"days_before": null, "type": null, "value": null}] }
            ])
        }
    },[has_cancellation_policy])

    const w = watch();

    return(
        <ColAtm xs={12}>
            <form className="w-100">
                <div className="d-grid">
                    {
                        has_cancellation_policy === "true" &&
                        fields.map( (item, index) => (
                            <ColAtm xs={12} key={item.id} className="cancellation-form">
                                <Grid xs={11}  spacing={3} container>
                                    <ColAtm sm={4}>

                                        <InputAtm
                                            name={`cancellation_policy[${index}].days_before`}
                                            type="number"
                                            label={t('enw-input-days_before')}
                                            variant="outlined"
                                            autoComplete="off"
                                            inputRef={
                                                register( {
                                                    min: { value: 1, message: 'En az bir (1) gün olmalı'},
                                                    pattern: { value: /^[0-9]+$/i, message: 'pozitif bir sayı giriniz'},
                                                })
                                            }
                                            error={errors.cancellation_policy && errors.cancellation_policy[index]
                                            && errors.cancellation_policy[index].days_before}
                                        />
                                        <FormHelperText
                                            error={errors.cancellation_policy && errors.cancellation_policy[index] && errors.cancellation_policy[index].days_before}
                                        >
                                            {
                                                errors.cancellation_policy && errors.cancellation_policy[index] && errors.cancellation_policy[index].days_before.message
                                            }
                                        </FormHelperText>
                                    </ColAtm>
                                    <ColAtm sm={4} className="form-group">
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>{ t('enw-input-value_type') }</InputLabel>
                                            <Controller
                                                as={
                                                    <Select
                                                        className="form-control"
                                                        label={ t('enw-input-rate') }
                                                    >
                                                        <MenuItem value={'PER'}>{ t('enw-input-percent') }</MenuItem>
                                                        <MenuItem value={'FIX'}>{ t('enw-input-fixed') } </MenuItem>
                                                    </Select>
                                                }
                                                name={`cancellation_policy[${index}].type`}
                                                control={control}
                                                defaultValue=""
                                            />
                                        </FormControl>
                                    </ColAtm>
                                    <ColAtm sm={4}>
                                        <InputAtm
                                            label={t('enw-input-value')}
                                            name={`cancellation_policy[${index}].value`}
                                            variant="outlined"
                                            autoComplete="off"
                                            inputRef={register()}
                                            InputProps={{
                                                endAdornment: <>
                                                    {
                                                        (isDefined(w) && isDefined(w[`cancellation_policy[${index}].type`])) &&
                                                        w[`cancellation_policy[${index}].type`]=== "PER" ?
                                                            <InputAdornment position="start">
                                                                %
                                                            </InputAdornment>
                                                            :
                                                            (isDefined(w) && isDefined(w[`cancellation_policy[${index}].type`])) &&
                                                            w[`cancellation_policy[${index}].type`] === "FIX" ?
                                                                <InputAdornment position="start">
                                                                    {w[`currency`] }
                                                                </InputAdornment>
                                                                :
                                                                <InputAdornment position="start">
                                                                    { "" }
                                                                </InputAdornment>
                                                    }
                                                </>
                                            }}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        />
                                    </ColAtm>
                                </Grid>
                                <ColAtm sm={1}>
                                    <div className="btn-box mt-32">
                                        {
                                            fields.length !== 1 &&
                                            <ButtonAtm
                                                className="delete-bed-button"
                                                startIcon={<i className="fas fa-minus-circle" />}
                                                onClick={ () => remove(index)}
                                            />
                                        }
                                        {
                                            fields.length - 1 === index &&
                                            <ButtonAtm
                                                className="new-bed-button ml-10"
                                                fullwidth={true}
                                                startIcon={<i className="fas fa-plus-circle" />}
                                                onClick={() => {
                                                    append({
                                                        "days_before": null,
                                                        "type": null,
                                                        "value": null
                                                    });
                                                }
                                                }
                                            />
                                        }
                                    </div>
                                </ColAtm>
                            </ColAtm>
                        ))
                    }
                    <Grid container xs={12} className="cancellation-policy-btn">
                        <Grid item className="mr-9">
                            <label className={"non-cancellation-btn"} htmlFor="haveRefundable">
                                <i className={'fa fa-check'} aria-hidden="true" />
                                {t('btn-irrevocable')}
                            </label>
                        </Grid>
                        <Grid item>
                            <label className={"cancellation-btn"} htmlFor="haveRevocable">
                                <i className={'fa fa-check' } aria-hidden="true" />
                                {t('btn-revocable')}
                            </label>
                        </Grid>
                    </Grid>

                    {
                        has_cancellation_policy &&
                        <ColAtm className="cancel-radio">
                            <FormControl component="fieldset" className="w-100">
                                <Controller
                                    as={
                                        <RadioGroup>
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio id="haveRefundable" className="d-none" />}
                                            />

                                            <FormControlLabel
                                                value="true"
                                                control={<Radio id="haveRevocable" className="d-none" />}
                                            />

                                        </RadioGroup>
                                    }
                                    name="has_cancellation_policy"
                                    control={control}
                                />
                            </FormControl>
                        </ColAtm>
                    }
                </div>
            </form>
        </ColAtm>
    )
}

export default CancellationPolicy;
