import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../../utils";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BlockAtm from "../../../../../atomic/Block/Block";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CreatePaymentModal(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [defaultPayment, setDefaultPayment] = useState(false);

    const [state, setState] = useState({
        bankCodes: {}
    });


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setState( { ...state, bankCodes: [] })

        props.updatePaymentErrorClear();
        props.successPaymentClear();
    };

    const handleChangeSetDefault = (e) => {
        let checked = isDefined(e.target.checked) && e.target.checked
        setDefaultPayment(checked)
    }

    const handleChangeBankCodes = (e,key) => {

        let values = state.bankCodes;
        values[key] = e.target.value;
        setState({ ...state, values });
    }

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();
        const params = {
            payment_type_id : props.paymentTypeGet.id,
            currency: props.paymentMappingList.code,
            fields: Object.assign({}, state.bankCodes),
            is_default: defaultPayment
        }
        props.createPaymentMapping(params);
    };

    useEffect( () => {

        if (props.paymentMapping.createMappingStatus === 200 && open) {
            props.GetPaymentType({ payment_type_id : props.paymentTypeGet.id });
            handleClose();
        }
    },[props.paymentMapping.createMappingStatus] )

    return (
        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
            <BlockAtm className="offer-fact-border-box fact-border-box" onClick={handleClickOpen}>
                <label>
                    <label>
                        <Grid item xs={12} className="cancellation-info-col">
                            <ColAtm className="channel-logo payment-create-btn">
                                <label>
                                    <p>{props.paymentMappingList.code}</p>
                                </label>
                            </ColAtm>
                        </Grid>
                        <OverFlowText>{t(props.paymentMappingList.language_key)}</OverFlowText>
                    </label>
                </label>
            </BlockAtm>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                        { t('enw-payment-new_payment-title') }
                    </DialogTitle>
                    <DialogContent>
                        <Fragment>
                            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                {props.paymentMapping.createMappingStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                                {isDefined(props.paymentMapping.errorCreatePaymentMappingData) && <Alert severity="error">{props.paymentMapping.errorCreatePaymentMappingData.response.data.message}</Alert>}
                                    <Grid container className="payment-create-dialog" spacing={3}>
                                        {
                                            Object.keys(props.paymentMappingList.bank_params).map( (paymentCode) => (
                                                <Grid item xs={12} md={Object.keys(props.paymentMappingList.bank_params).length > 1 ? 6 : 12}>
                                                    <TextValidator
                                                        label={ t(props.paymentMappingList.bank_params[paymentCode].name) }
                                                        type="text"
                                                        fullWidth
                                                        value={state.bankCodes[paymentCode]}
                                                        onChange={(e) => handleChangeBankCodes(e, paymentCode)}
                                                        name={paymentCode}
                                                        autoComplete="off"
                                                        variant="outlined"
                                                        validators={['required']}
                                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                        <ColAtm md={12} className="mt-22 d-block">
                                            <FormControlLabel
                                                className="desc-text"
                                                name="default"
                                                control={<Checkbox color="primary" value="false" defaultChecked={false} />}
                                                label={ t('enw-input-is_default')+"?" }
                                                labelPlacement="left"
                                                onClick={handleChangeSetDefault}
                                            />
                                        </ColAtm>
                                    </Grid>

                                    <ColAtm className="mt-32" md={12}>
                                        <ButtonAtm
                                            className="m-auto room-rate-modal-save bg-red"
                                            type="submit"
                                            buttontext={ t('btn-save') }
                                            color="primary"
                                        />
                                    </ColAtm>
                            </ValidatorForm>
                        </Fragment>
                    </DialogContent>
                </Dialog>
            </Dialog>
        </ColAtm>
    );
}
