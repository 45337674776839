import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../../atomic/Block/Block";
import CancellationPolicyCheckBox from "./CancellationPolicyCheckBox";
import UpdatePolicy from "./updatePolicy";
import {useTranslation} from "react-i18next";

const CancellationPolicy = (props) => {
    const {cancellations ,roomId, rateId} = props;
    const currency = props.channelInformationData?.currency;
    const { t } = useTranslation();

    return(
        <>
            <Grid container spacing={3}>
                {
                    cancellations.map( cancellation => (
                        <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                            <UpdatePolicy
                                cancellation={cancellation}
                                UpdateCancellationPolicy={props.UpdateCancellationPolicy}
                                updatePolicyError={props.updatePolicyError}
                                updatePolicyStatus={props.updatePolicyStatus}
                                channelInformationData={props.channelInformationData}
                                getChannelCancellationList={props.getChannelCancellationList}
                            />
                            <BlockAtm className="offer-fact-border-box fact-border-box">
                                <CancellationPolicyCheckBox roomId={roomId}
                                                            rateId={rateId}
                                                            cancellationId={cancellation.id}
                                                            cancellationName={cancellation.name}
                                                            rateMappingId={props.rateMappingId}
                                                            channelId={props.channelId}
                                                            isSelected={cancellation.is_selected}
                                />
                                <label htmlFor={"room" + roomId + "rate" + rateId + cancellation.id+" "+cancellation.name}>
                                    <label htmlFor={"room" + roomId + "rate" + rateId + cancellation.id+" "+cancellation.name}>
                                        <Grid item xs={12} className="cancellation-info-col">
                                            <LabelAtm
                                                labelClass="cancellation-value"
                                                text={(cancellation.value !== null && cancellation.value > 0 )
                                                    ? (cancellation.type === "PER" ?
                                                        "%" + cancellation.value
                                                        : cancellation.value + " " + currency)
                                                    : t('enw-input-free')}
                                            />
                                        </Grid>
                                        <OverFlowText>{cancellation.is_nonrefundable === 1 ? t('btn-new_non_refundable_cancellation_policy') : cancellation.is_free_cancellation === 1 ?  cancellation.before_arrival +" "+ t('enw-input-days_before') +" "+ t('btn-new_free_cancellation_policy') : cancellation.before_arrival +" "+ t('enw-input-days_before') +" "+ t('btn-new_refundable_cancellation_policy')}</OverFlowText>
                                    </label>
                                </label>
                            </BlockAtm>
                        </ColAtm>
                    ))
                }

            </Grid>
        </>
    )
}

export default CancellationPolicy;
