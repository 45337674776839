import React from "react";
import FactCheckBox from "../../molecules/FactContent/FactContent";


const Rooms = (props) => {
    const {offerReducer} = props;

    const rooms = offerReducer.hasOwnProperty("property_rooms") &&
    Object.keys(offerReducer.property_rooms).length > 0 ? offerReducer.property_rooms : [];

    return(
        <>
            {
                rooms.map( (room,key) => (
                    <FactCheckBox
                        checkboxId={room.id+" "+room.name}
                        className="fact-box"
                        colClassname="offer-fact-col"
                        blockClass="offer-fact-border-box fact-border-box"
                        labelIconClass="fact-icon-label"
                        iconClass="offer-fact-icon fact-icon fas fa-home"
                        value={room.id}
                        key={key}
                        md={2}
                        defaultChecked={room.is_selected}
                        factName="name"
                        text={room.name}
                        tooltip={room.name}
                        labelClass="fact-name"
                        htmlFor={room.id+" "+room.name}
                        onClick={props.onClickSelectRoom}
                    />
                ))
            }
        </>
    )
}

export default Rooms;
