import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import Container from "@material-ui/core/Container";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import {
    NETWORK,
    NETWORK_CHANNEL_CANCELLATION_SHORT, NETWORK_CHANNEL_PAYMENT_SHORT
} from "../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";

import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {Grid} from "@material-ui/core";
import ChannelPaxPolicyButtons from "../../../../organisms/ChannelPaxPolicyButtons/ChannelPaxPolicyButtons";
import AdultPaxModal from "./components/createAdultPaxModal";
import ChildPaxModal from "./components/createChildPaxModal";

import {
    getChannelPaxList,
    addChannelAdultPricing,
    addChannelChildPricing,
    updateChannelAdultPricing,
    updateChannelChildPricing, updateChannelPricingErrorClear
} from "../../../../../redux/actions/Network/Channel/channelPaxPricing";
import ChannelPaxList from "./components/ChannelPaxList";
import {isDefined} from "../../../../../utils";
import AdultPaxDecreaseModal from "./components/createAdultPaxDecreaseModal";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";

let t =null;

class ChannelPaxPricing extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true,
            openAdultModal : false,
            openAdultDecreaseModal : false,
            openChildModal : false,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup({ channel_id : this.props.match.params.id } );
            this.props.getChannelPaxList({ channel_id : this.props.match.params.id } );
        }
        else{
            this.props.history.replace(NETWORK);
        }
    }
    handleAdultClose = (e) => {
        this.setState({openAdultModal:false});
        this.props.updateChannelPricingErrorClear();
    };
    handleAdultOpen = () => {
        this.setState({openAdultModal:true});
    };
    handleAdultDecreaseClose = (e) => {
        this.setState({openAdultDecreaseModal:false});
        this.props.updateChannelPricingErrorClear();
    };
    handleAdultDecreaseOpen = () => {
        this.setState({openAdultDecreaseModal:true});
    };
    handleChildClose = (e) => {
        this.setState({openChildModal:false});
        this.props.updateChannelPricingErrorClear();
    };
    handleChildOpen = () => {
        this.setState({openChildModal:true});
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm
                    exitText={ t('btn-channel_list') }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelSetupReducers.fetching && this.props.channelInformationData.name + " " + t('enw-channels-person_pricing-title') }
                />
                <ChannelMenu menuClass="channel-menu"
                             PaxColClass="active-menu-bg"
                             menuId={this.props.channelInformationData.id}
                             isConnected={this.props.channelInformationData.is_connected}
                             channelSetup={this.props.channelSetupReducers}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width cancellation-policy-list">
                            <Grid container>
                                <Grid item xs={12} className="mb-16">
                                    <Grid item  className="photoButtonsRow">

                                        <ChannelPaxPolicyButtons
                                            AdultPax={<AdultPaxModal
                                                addChannelAdultPricing={this.props.addChannelAdultPricing}
                                                open={this.state.openAdultModal}
                                                channelInformationData={this.props.channelInformationData}
                                                updateStatus={this.props.channelPaxPricingReducers.addAdultPricingStatus}
                                                updateError={this.props.channelPaxPricingReducers.errorAddAdult}
                                                handleClose={this.handleAdultClose}
                                                handleOpen={this.handleAdultOpen}
                                                getChannelPaxList={this.props.getChannelPaxList}
                                            />}
                                            AdultPaxDecrease={<AdultPaxDecreaseModal
                                                  addChannelAdultPricing={this.props.addChannelAdultPricing}
                                                  open={this.state.openAdultDecreaseModal}
                                                  channelInformationData={this.props.channelInformationData}
                                                  updateStatus={this.props.channelPaxPricingReducers.addAdultPricingStatus}
                                                  updateError={this.props.channelPaxPricingReducers.errorAddAdult}
                                                  handleClose={this.handleAdultDecreaseClose}
                                                  handleOpen={this.handleAdultDecreaseOpen}
                                                  getChannelPaxList={this.props.getChannelPaxList}
                                              />}
                                            ChildPax={<ChildPaxModal
                                                addChannelChildPricing={this.props.addChannelChildPricing}
                                                open={this.state.openChildModal}
                                                channelInformationData={this.props.channelInformationData}
                                                updateStatus={this.props.channelPaxPricingReducers.addChildPricingStatus}
                                                updateError={this.props.channelPaxPricingReducers.errorAddChild}
                                                handleClose={this.handleChildClose}
                                                handleOpen={this.handleChildOpen}
                                                getChannelPaxList={this.props.getChannelPaxList}
                                            />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        (!this.props.channelPaxPricingReducers.fetching && !this.props.setupFetching) &&
                                        <ChannelPaxList
                                            channelId={this.props.match.params.id}
                                            channelInformationData={this.props.channelInformationData}
                                            channelPaxPricingList={this.props.channelPaxPricingReducers.getPricingList}
                                            updateChannelAdultPricing={this.props.updateChannelAdultPricing}
                                            updateChannelChildPricing={this.props.updateChannelChildPricing}
                                            updateAdultPricingStatus={this.props.channelPaxPricingReducers.updateAdultPricingStatus}
                                            errorUpdateAdult={this.props.channelPaxPricingReducers.errorUpdateAdult}
                                            updateChildPricingStatus={this.props.channelPaxPricingReducers.updateChildPricingStatus}
                                            errorUpdateChild={this.props.channelPaxPricingReducers.errorUpdateChild}
                                            getChannelPaxList={this.props.getChannelPaxList}
                                            updateChannelAdultPricingMapping={this.props.updateChannelAdultPricingMapping}
                                            updateChannelChildPricingMapping={this.props.updateChannelChildPricingMapping}
                                            errorUpdateChildMapping={this.props.channelPaxPricingReducers.errorUpdateChildMapping}
                                            clearErrorMessage = {this.props.updateChannelPricingErrorClear}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Animated>
                {
                    isDefined(this.props.channelInformationData.logo) &&
                    <Grid className="channel-logo-bottom">
                        <img src={this.props.channelInformationData.logoUrl} alt="mini-logo" />
                    </Grid>
                }
                {
                    this.props.channelInformationData.is_connected &&
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-next') }
                        backTo={NETWORK_CHANNEL_CANCELLATION_SHORT + this.props.match.params.id}
                        component={Link}
                        nextTo={NETWORK_CHANNEL_PAYMENT_SHORT + this.props.match.params.id}
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        setupFetching : props.channelSetupReducers.fetching,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelSetupReducers : props.channelSetupReducers,
        channelPaxPricingReducers: props.channelPaxPricingReducers,
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    updateChannelPricingErrorClear,
    getChannelPaxList,
    addChannelAdultPricing,
    addChannelChildPricing,
    updateChannelAdultPricing,
    updateChannelChildPricing,
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelPaxPricing));
