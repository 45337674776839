import React from "react";
import FactCheckBox from "../../molecules/FactContent/FactContent";


const Employees = (props) => {
    const {offerReducer} = props;

    const employees = offerReducer.hasOwnProperty("executives") &&
    Object.keys(offerReducer.executives).length > 0 ? offerReducer.executives : [];

    return(
        <>
            {
                employees.map( (employee,key) => (
                    <FactCheckBox
                        checkboxId={employee.id+""+employee.name_surname}
                        className="fact-box"
                        colClassname="offer-fact-col"
                        blockClass="offer-fact-border-box fact-border-box"
                        labelIconClass="fact-icon-label"
                        iconClass="offer-fact-icon fact-icon fas fa-address-card"
                        value={employee.id}
                        defaultChecked={employee.is_selected}
                        key={key}
                        md={2}
                        factName="name"
                        text={employee.name_surname}
                        tooltip={employee.name_surname}
                        labelClass="fact-name"
                        htmlFor={employee.id+""+employee.name_surname}
                        onChange={props.onClickEmployess}
                    />
                ))
            }
        </>
    )
}

export default Employees;
