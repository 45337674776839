import React, {Fragment, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useSelector} from "react-redux";
import PaymentCancellationPolicy from "./CancellationPolicy";
import Grid from "@material-ui/core/Grid";
import LabelAtm from "../../../../../atomic/Label/Label";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../../atomic/Block/Block";
import ButtonAtm from "../../../../../atomic/Button/Button";
import {isDefined} from "../../../../../../utils";
import ColAtm from "../../../../../atomic/Col/Col";
import {ValidatorForm, SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {Hidden, MenuItem} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputAtm from "../../../../../atomic/Input/Input";
import Slide from "@material-ui/core/Slide";
import {Alert} from "@material-ui/lab";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function ChannelPaymentCancellationModal(
    {
        selectedCancellation,
        handleClickCancellationPolicy,
        cancellation_list,
        index,
        pay,
        handleChangePaymentValue,
        handlePaymentChange,
        currency_code,
        handleChangePaymentCreditCard,
        onSubmit,
        status
}) {
    const { t } = useTranslation();

    const {cancellationData} = useSelector(props => props?.channelPaymentReducer);

    const [open, setOpen] = React.useState(false);
    const [hideStatus, setHideStatus] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect( () => {
        setHideStatus(false);
        if(status && open) {
            setHideStatus(true);
            setTimeout(() => {
                setOpen(false);
                setHideStatus(false);
            }, 1000)
        }
    }, [status])

    return (
        <>
            <ButtonAtm className={selectedCancellation ? "selected-cancellation-btn edit-channel-price-btn" : "not-selected-cancellation-btn edit-channel-price-btn"}
                       endIcon={<i className="fas fa-pen" />}
                       onClick={handleClickOpen}
                       title={t('btn-update')}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                TransitionComponent={Transition}
                fullScreen
                aria-labelledby="responsive-dialog-title"
                className="web-preview-dialog"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-payment-cancellation_modal_title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        {(hideStatus && status) && <Alert severity="success">{t('enw-quick-pricing_modal-success-message')}</Alert>}
                        <div>
                                <ValidatorForm onSubmit={onSubmit} name="general-form" id="general-form">

                                <Grid container className="payment-modal-container">
                                    {!pay.is_selected &&

                                    <Grid item sm={6} lg={3} className="channel-radio-center">
                                        <label htmlFor={pay.payment_type_id+"payment"}>
                                            <Grid container>
                                                <Grid item sm={12}>
                                                    <ColAtm className="mb-16">
                                                        <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-booking-instant_confirmation-title') } />
                                                    </ColAtm>
                                                </Grid>
                                            </Grid>
                                        </label>
                                    </Grid>
                                    }

                                    {pay.is_selected &&
                                    <Grid item sm={6} lg={2} className="channel-radio-group">
                                        <Grid container spacing={1}>
                                            <Grid item sm={12}>
                                                <ColAtm className="mb-16">
                                                    <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_affected-title') } />
                                                </ColAtm>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <input type={"radio"} name={"paymentValue" + pay.payment_type_id}
                                                       id={"paymentValue2" + pay.payment_type_id}
                                                       value={"false"}
                                                       className="d-none"
                                                       checked={ !pay.is_affected_price }
                                                       onClick={(e) => { handleChangePaymentValue(e, index) }}
                                                />
                                                <label className="show-channel-detail"
                                                       htmlFor={"paymentValue2" + pay.payment_type_id}>
                                                    { t('btn-not_affected') }
                                                </label>
                                            </Grid>
                                            <Grid item>
                                                <input type={"radio"} name={"paymentValue" + pay.payment_type_id}
                                                       id={"paymentValue" + pay.payment_type_id}
                                                       value={"true"}
                                                       className="d-none"
                                                       checked={ pay.is_affected_price }
                                                       onClick={(e) => { handleChangePaymentValue(e, index) }}
                                                />
                                                <label className="show-channel-detail" htmlFor={"paymentValue" + pay.payment_type_id}>
                                                    { t('btn-affected') }
                                                </label>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }

                                    {pay.is_affected_price === 1 && pay.is_selected &&
                                    <Grid item sm={12} lg={5}>
                                        <Grid container spacing={3} className="mobile-no-spacing">
                                            <Grid item xs={12} sm={4}>
                                                <SelectValidator
                                                    name="action_type"
                                                    className="form-control"
                                                    variant="outlined"
                                                    fullWidth
                                                    label={ t('enw-input-setup_type') }
                                                    value={pay.action_type}
                                                    onChange={e => handlePaymentChange(e, index)}
                                                    validators={['required']}
                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                >
                                                    <MenuItem value={"INC"}>{ t('enw-input-increase') }</MenuItem>
                                                    <MenuItem value={"DEC"}>{ t('enw-input-decrease') }</MenuItem>
                                                </SelectValidator>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <SelectValidator
                                                    name="value_type"
                                                    className="form-control"
                                                    variant="outlined"
                                                    fullWidth
                                                    label={ t('enw-input-value_type') }
                                                    value={pay.value_type}
                                                    onChange={e => handlePaymentChange(e, index)}
                                                    validators={['required']}
                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                >
                                                    <MenuItem value={"PER"}>{ t('enw-input-percent') }</MenuItem>
                                                    <MenuItem value={"FIX"}>{ t('enw-input-fixed') }</MenuItem>
                                                </SelectValidator>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextValidator
                                                    id={pay.payment_type_id+"channel"}
                                                    name="value"
                                                    type="text"
                                                    variant="outlined"
                                                    label={ t('enw-input-value') }
                                                    autoComplete="off"
                                                    value={pay.value}
                                                    onChange={e => handlePaymentChange(e, index)}
                                                    labelWidth={70}
                                                    validators={['required']}
                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <>
                                                            {
                                                                pay.value_type === "PER" ?
                                                                    <InputAdornment position="start">
                                                                        %
                                                                    </InputAdornment>
                                                                    :
                                                                    pay.value_type === "FIX" ?
                                                                        <InputAdornment position="start">
                                                                            {currency_code}
                                                                        </InputAdornment>
                                                                        :
                                                                        <InputAdornment position="start">

                                                                        </InputAdornment>
                                                            }
                                                        </>
                                                    }}
                                                />
                                                <Hidden xsUp>
                                                    <InputAtm
                                                        name="payment_type_id"
                                                        type="text"
                                                        value={pay.payment_type_id}
                                                        variant="outlined"
                                                        label={"payment_type_id"}
                                                        autoComplete="off"
                                                        onChange={e => handlePaymentChange(e, index)}
                                                    />
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }


                                    {pay.is_selected && pay.code === "HTL" &&
                                    <ColAtm className="mt-32 border-top-red-border">
                                        <Grid container>
                                            <Grid item sm={12}>
                                                <ColAtm className="mb-16 mt-14">
                                                    <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-payment-data-title') } />
                                                </ColAtm>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} lg={2} className="channel-radio-group">
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <input type={"radio"} name={"paymentCardValue" + pay.payment_type_id}
                                                           id={"paymentCardValue2" + pay.payment_type_id}
                                                           value={"false"}
                                                           className="d-none"
                                                           checked={ !pay.is_get_payment_data }
                                                           onClick={(e) => { handleChangePaymentCreditCard(e, index) }}
                                                    />
                                                    <label className="show-channel-detail"
                                                           htmlFor={"paymentCardValue2" + pay.payment_type_id}>
                                                        { t('btn-not_affected') }
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <input type={"radio"} name={"paymentCardValue" + pay.payment_type_id}
                                                           id={"paymentCardValue" + pay.payment_type_id}
                                                           value={"true"}
                                                           className="d-none"
                                                           checked={ pay.is_get_payment_data }
                                                           onClick={(e) => { handleChangePaymentCreditCard(e, index) }}
                                                    />
                                                    <label className="show-channel-detail" htmlFor={"paymentCardValue" + pay.payment_type_id}>
                                                        { t('btn-affected') }
                                                    </label>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ColAtm>
                                    }

                                    <ColAtm className="mt-32 border-top-red-border">
                                        <Grid container>
                                            <Grid item sm={12}>
                                                <ColAtm className="mb-16 mt-14">
                                                    <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-payment-cancellation-policy-title') } />
                                                </ColAtm>
                                            </Grid>
                                        </Grid>
                                        {
                                            isDefined(cancellationData) && isDefined(cancellationData.cancellation_policies) && cancellationData?.cancellation_policies.map( cancellation => (
                                                    <BlockAtm className="offer-fact-border-box fact-border-box payment-cancellation">
                                                        <PaymentCancellationPolicy
                                                            cancellation={cancellation}
                                                            handleClickCancellationPolicy={handleClickCancellationPolicy}
                                                            cancellation_list={cancellation_list}
                                                            index={index}
                                                        />
                                                        <label htmlFor={cancellation.id+" "+cancellation.name}>
                                                            <label htmlFor={cancellation.id+" "+cancellation.name}>
                                                                <Grid item xs={12} className="cancellation-info-col">
                                                                    <LabelAtm
                                                                        labelClass="cancellation-value"
                                                                        text={cancellation.value}
                                                                    />
                                                                </Grid>
                                                                <OverFlowText>{cancellation.is_nonrefundable === 1 ? t('btn-new_non_refundable_cancellation_policy') : cancellation.is_free_cancellation === 1 ?  cancellation.before_arrival +" "+ t('enw-input-days_before') +" "+ t('btn-new_free_cancellation_policy') : cancellation.before_arrival +" "+ t('enw-input-days_before') +" "+ t('btn-new_refundable_cancellation_policy')}</OverFlowText>
                                                            </label>
                                                        </label>
                                                    </BlockAtm>
                                                )
                                            )
                                        }
                                    </ColAtm>
                                </Grid>
                                <BlockAtm md={12} className="t-center mt-22">
                                    <ButtonAtm
                                        className="room-rate-modal-save room-rate-save bg-red m-auto"
                                        buttontext={ t('btn-save') }
                                        type="submit"
                                        autoFocus
                                        color="primary"
                                    />
                                </BlockAtm>
                                </ValidatorForm>

                            </div>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
