import React from 'react';
import PropTypes    from 'prop-types';

import CheckboxAtm  from "../../atomic/Checkbox/Checkbox";
import ColAtm       from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";

const MywebPhotos = props => {
    const index = props.selectedPhotos.findIndex(id => id == props.checkboxId);

    return  (
        <div className={props.colClassname} htmlFor={props.htmlFor}>
            <BlockAtm className={props.blockClass} onClick={props.onClick} htmlFor={props.htmlFor}>
                <CheckboxAtm
                    name={props.name}
                    id={props.id}
                    className={props.className}
                    onChange={props.onChange}
                    checkboxId={props.checkboxId}
                    value={props.value}
                    labelPlacement={props.labelPlacement}
                    checked={props.checked}
                    defaultChecked={props.defaultChecked}
                />
                <ColAtm htmlFor={props.htmlFor}>
                    <ColAtm htmlFor={props.htmlFor}>
                        <ColAtm className="channel-logo" htmlFor={props.htmlFor}>
                            <label htmlFor={props.htmlFor}>
                                {
                                    index + 1 !== 0 &&
                                    <span className="photo-number">{index + 1}</span>
                                }
                                <i className="fas fa-check"/>
                                <img src={props.src} htmlFor={props.htmlFor}/>
                            </label>
                        </ColAtm>
                    </ColAtm>
                </ColAtm>
            </BlockAtm>
        </div>
    )
};

MywebPhotos.propTypes = {
    id: PropTypes.any,
    cover_id: PropTypes.any,
    name: PropTypes.any,
    src: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    value: PropTypes.any,
    cover_value: PropTypes.any,
    htmlFor: PropTypes.any,
    cover_key: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    cover_onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    cover_checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
};

export default MywebPhotos;
