import React, {Component}   from 'react';

import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import {Link} from "react-router-dom";
import FooterAtm from "../../../atomic/Footer/Footer";
import Menu from "../../Global/Layout/Header/Submenu/General";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import {clearBrandLogo, getBrand, onBrandSubmit} from "../../../../redux/actions/brandActions";
import {connect} from "react-redux";
import {dashboardPropertyActions} from "../../../../redux/actions/dasboardActions";
import 'rc-color-picker/assets/index.css';
import FromBrandLast from "./fromBrandLast";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../utils/history";
import {
    CONTENT,
    CONTENT_LOCATION,
    CONTENT_AWARDS_AND_CERTIFICATES
} from "../../../../constants/appUrls.constants";
let t=null;

class Brand extends Component {

    constructor(props){
        super(props);
        this.headerStatusShow = this.headerStatusShow.bind(this)
        this.state = {
            statusHeader: false,
            redirect: false,
        };
        t = props.t;
    }

    componentDidMount() {
        this.props.getBrand();
        this.props.dashboardPropertyActions();
    }

    headerStatusShow = () =>{
        return  <PageHeaderSaveAtm
            saveText="Save"
            backText={ t('btn-dashboard') }
            backButton={CONTENT}
            text={ t('enw-general-title') }
            saveButtonDisabled={this.props.buttonPassiveStatus}
            processStatus={this.props.updateFetching}
            processResponse={this.props.updateData}
            errorStatus={Object.keys(this.props.error).length > 0 ? this.props.error : false}
            successHeaderTime={true}
        />
    };

    saveNextButton = () => {
       /* setTimeout(() => {
            this.setState({
                redirect: true,
            })
        }, 2000)*/
        this.setState({
            redirect: true,
        })
    };

    renderRedirect = () => {
        if (this.props.updateData === 200) {
            if (this.state.redirect) {
                setTimeout(() => {
                    history.push(CONTENT_AWARDS_AND_CERTIFICATES)
                }, 1000);
            }
        }
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-general-brand-page_title') } />
                {
                    this.headerStatusShow()
                }
                <Menu brandColClass="active-menu-bg" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width brand-container">
                            <div className="progress">
                                <div className="outer">
                                    <div className="inner"></div>
                                </div>
                            </div>
                            {
                                !this.props.fetching &&
                                    <FromBrandLast
                                        get_property_brand={this.props.get_property_brand}
                                        onBrandSubmitForm={this.props.onBrandSubmit}
                                        hotel_name={this.props.hotel_name}
                                        clearBrandLogo={this.props.clearBrandLogo}
                                        brandReducer={this.props.brandReducer}
                                    />
                            }

                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backDisabled={this.props.buttonPassiveStatus}
                    nextDisabled={this.props.buttonPassiveStatus}
                    buttonNextText={ t('btn-next') }
                    backTo={CONTENT_LOCATION}
                    component={Link}
                    iconLeftClassName="fas fa-arrow-left"
                    form='general-form'
                    onClick={this.saveNextButton}
                />
                {this.renderRedirect()}
            </>
        );
    }
}

const mapStateToProps = (props) => {
    return{
        fetching : props.brandReducer.fetching,
        buttonPassiveStatus : props.brandReducer.buttonPassiveStatus,
        updateFetching : props.brandReducer.updateFetching,
        updateData : props.brandReducer.updateData,
        error : props.brandReducer.error,
        hotel_name : props.dashboardReducers.left_drawer.hotel_name,
        get_property_brand : props.brandReducer.get_property_brand,
        brandReducer: props.brandReducer
    }
};

const mapDispatchToProps = {
    getBrand,
    dashboardPropertyActions,
    onBrandSubmit,
    clearBrandLogo
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Brand));

