import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import axios from "axios";
import {ROOM_VIEW_TYPE} from "../../../../constants/api.constants";

export function getRoomViewTypes() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_VIEW_TYPE',
            payload: axios.post(ROOM_VIEW_TYPE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}