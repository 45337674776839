import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Animated} from "react-animated-css";
import ChannelRoomRateList from "./components/ChannelRoomRateList";
import Container from "@material-ui/core/Container";
import ChannelMenu from "../../../Global/Layout/Header/Submenu/Channel";
import {getChannelRoomRate, updateChannelRoomRate} from "../../../../../redux/actions/Network/Channel/channelRoomRateActions";
import {getChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";
import {
    NETWORK_CHANNEL_CANCELLATION_SHORT,
    NETWORK_CHANNEL_SETUP_SHORT,
    NETWORK_CHANNEL_CONTACT_SHORT
} from "../../../../../constants/appUrls.constants";
import {isDefined} from "../../../../../utils";
import {Grid} from "@material-ui/core";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";

let t =null;

class ChannelRoomRate extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        if ( this.props.match.params.id )
        {
            this.props.getChannelRoomRate( { channel_id : this.props.match.params.id } );
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup( { channel_id : this.props.match.params.id } );
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
    }


    render() {
        const channelCategoryId = isDefined(this.props.channelSetup.channelInformationData.channel_category_id) &&
            this.props.channelSetup.channelInformationData.channel_category_id;

        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm
                    exitText={ t("btn-channel_list") }
                    backButton={"/app/network/channels"}
                    text={!this.props.channelSetup.fetching &&  this.props.channelInformationData.name + " " + t('enw-channels-room_rate-title') }
                />
                <ChannelMenu menuClass="channel-menu" infoColClass="active-menu-bg" RoomRateColClass="active-menu-bg"
                             isConnected={this.props.channelInformationData.is_connected}
                             channelSetup={this.props.channelSetup}
                             menuId={this.props.channelInformationData.id}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            {
                                (!this.props.getChannelRoomRateReducers.fetching && !this.props.channelSetup.fetching) &&
                                <ChannelRoomRateList getChannelRoomRateData={this.props.getChannelRoomRateData}
                                                     channelInformationData={this.props.channelInformationData}
                                                     updateChannelRoomRate={this.props.updateChannelRoomRate}
                                                     channelSetup={this.props.channelSetup}
                                />
                            }
                        </div>
                    </Container>
                </Animated>
                {
                    isDefined(this.props.channelInformationData.logo) &&
                    <Grid className="channel-logo-bottom">
                        <img src={this.props.channelInformationData.logoUrl} alt="mini-logo" />
                    </Grid>
                }
                {
                    (this.props.channelInformationData.is_connected || (this.props.updateChannelRoomRate?.status === 200)) &&
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-next') }
                        backTo={NETWORK_CHANNEL_SETUP_SHORT + this.props.match.params.id}
                        component={Link}
                        nextTo={channelCategoryId === 5 ? NETWORK_CHANNEL_CONTACT_SHORT + this.props.match.params.id : NETWORK_CHANNEL_CANCELLATION_SHORT + this.props.match.params.id}
                        nextComponent={Link}
                    />
                }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        getChannelRoomRateReducers : props.channelRoomRateReducer,
        getChannelRoomRateData : props.channelRoomRateReducer.getChannelRoomRate,
        updateChannelRoomRate : props.channelRoomRateUpdateReducer?.updateChannelRoomRate,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelSetup : props.channelSetupReducers,
    }
};

const mapDispatchToProps ={
    getChannelRoomRate,
    getChannelSetup,
    updateChannelRoomRate
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelRoomRate));
