import {landingContactFormConstants} from "../../../../constants/Public/landingContactForm.constants";

const initialState = {
    fetching : false,
    status: {},
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case landingContactFormConstants.LANDING_CONTACT_FORM_PENDING:
            return {
                ...state,
                fetching: true,
                status: {},
                error: {}
            };
        case landingContactFormConstants.LANDING_CONTACT_FORM_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: action.payload.status
            };
        case landingContactFormConstants.LANDING_CONTACT_FORM_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case landingContactFormConstants.LANDING_CONTACT_FORM_CLEAR_STATE:
            return {
                ...state,
                fetching : false,
                status: {},
                error: {},
            };
        default:
            return state
    }
}
