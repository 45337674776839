import axios          from 'axios';
import {getAppLang, getUserToken} from "../../../../utils";
import { GET_PROFILE,UPDATE_PROFILE }   from "../../../../constants/api.constants";


export function getProfile() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'language' : getAppLang(),
            'authToken': getUserToken(),
        },
        body: {

        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PROFILE',
            payload: axios.post(GET_PROFILE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateProfile(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'language' : getAppLang(),
            'authToken': getUserToken(),
        },
        body: {
            params: {
                name: param.name,
                surname : param.surname,
                gender : param.gender,
                language: param.language,
                phone: param.phone
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_PROFILE',
            payload: axios.post(UPDATE_PROFILE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}