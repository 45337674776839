import React, {Component}   from 'react';
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PaymentMenu from "../../../Global/Layout/Header/Submenu/PaymentMenu";
import {
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear,
    GetPaymentType
} from "../../../../../redux/actions/Payment/Payment.actions";
import {connect} from "react-redux";
import PaymentList from "./components/PaymentList";
import {PAYMENT_LIST, PAYMENT_INSTALLMENT_SHORT} from "../../../../../constants/appUrls.constants";
import {isDefined} from "../../../../../utils";
import {Grid} from "@material-ui/core";
import FooterAtm from "../../../../atomic/Footer/Footer";
import {Link} from "react-router-dom";

let t =null;

class PaymentSetupIndex extends Component {

    constructor(props){
        super(props);
        t = props.t;

        this.state= {
            paymentStatus: false
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getPaymentMappingList();
        this.props.getPaymentTypeList();
        if ( this.props.match.params.id )
        {
            this.props.GetPaymentType( { payment_type_id : this.props.match.params.id } );
            this.setState({
                payment_type_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/payment');
        }
    }

    render() {
        let installmentData = isDefined(this.props.paymentMapping.paymentGetList.currencies) && this.props.paymentMapping.paymentGetList.currencies.filter( (placesCategories) => {
            return placesCategories.code === "TRY"
        })

        const boxStatus = isDefined(this.props.paymentMapping.updatePaymentMappingBoxStatusData.data) && this.props.paymentMapping.updatePaymentMappingBoxStatusData.data.status;
        const responseCurrencyCode = isDefined(this.props.paymentMapping.updatePaymentMappingBoxStatusData.data) && this.props.paymentMapping.updatePaymentMappingBoxStatusData.data.currency_code;
        const tryCurrencyStatus =
            (boxStatus && responseCurrencyCode === "TRY") ?
                1 :
                (!boxStatus && responseCurrencyCode === "TRY") ?
                    0
                    :
                    isDefined(installmentData[0]) && installmentData[0].status;

        return (
            <>
                <PageTitle title={ t('enw-payment-payment_setup-page_title') } />
                        <>

                            <PageHeaderAtm
                                exitText={ t('btn-payment_list') }
                                backButton={PAYMENT_LIST}
                                text={isDefined(this.props.paymentMapping) && this.props.paymentMapping.paymentGetList.name + " "+ t('enw-payment-payment_setup-page_title')}
                            />
                            <PaymentMenu menuClass="channel-menu" setupColClass="active-menu-bg"
                                         menuId={this.props.match.params.id}
                                         bankName={isDefined(this.props.paymentMapping) && this.props.paymentMapping.paymentGetList.name}
                                         tryCurrencyStatus={tryCurrencyStatus}
                                         boxStatus={boxStatus}
                                         responseCurrencyCode={responseCurrencyCode}
                            />
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width cancellation-policy-list">
                                        {
                                            isDefined(this.props.paymentMapping) && !this.props.paymentMapping.paymentGetListFetching ?
                                            <PaymentList
                                                paymentMapping={this.props.paymentMapping}
                                                UpdatePaymentMapping={this.props.UpdatePaymentMapping}
                                                UpdatePaymentStatusMapping={this.props.UpdatePaymentStatusMapping}
                                                PaymentStatusSetDefault={this.props.PaymentStatusSetDefault}
                                                PaymentInstallmentUpdate={this.props.PaymentInstallmentUpdate}
                                                getPaymentMappingList={this.props.getPaymentMappingList}
                                                updatePaymentErrorClear={this.props.updatePaymentErrorClear}
                                                successPaymentClear={this.props.successPaymentClear}
                                                paymentTypeList={this.props.paymentMapping.paymentTypeList}
                                                paymentTypeGet={this.props.paymentMapping.paymentGetList}
                                                paymentGetListFetching={this.props.paymentMapping.paymentGetListFetching}
                                                createPaymentMapping={this.props.createPaymentMapping}
                                                GetPaymentType={this.props.GetPaymentType}
                                            />
                                                :
                                            <>
                                            </>
                                        }
                                    </div>
                                </Container>
                            </Animated>
                            {
                                (isDefined(this.props.paymentMapping) && !this.props.paymentMapping.paymentGetListFetching) &&
                                <Grid className="channel-logo-bottom">
                                    <img src={`/assets/img/logo/banks-logo/${this.props.paymentMapping.paymentGetList.bank_code}.jpg`} alt="mini-logo" />
                                </Grid>
                            }
                        </>
                {
                    tryCurrencyStatus || (boxStatus && responseCurrencyCode === "TRY") ?
                    <FooterAtm
                        buttonBackText={ t('btn-back') }
                        iconRightClassName="fas fa-arrow-right"
                        buttonBackStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        buttonNextStyle={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        iconLeftClassName="fas fa-arrow-left"
                        buttonNextText={ t('btn-next') }
                        component={Link}
                        backDisabled={true}
                        nextTo={PAYMENT_INSTALLMENT_SHORT + this.props.match.params.id}
                        nextComponent={Link}
                    />
                    :
                        (!boxStatus && responseCurrencyCode === "TRY") ?
                            <></>
                            :
                    <></>
                }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        paymentMapping: props.paymentReducers
    }
};

const mapDispatchToProps ={
    getPaymentMappingList,
    getPaymentTypeList,
    createPaymentMapping,
    UpdatePaymentMapping,
    UpdatePaymentStatusMapping,
    PaymentStatusSetDefault,
    PaymentInstallmentUpdate,
    updatePaymentErrorClear,
    successPaymentClear,
    GetPaymentType
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PaymentSetupIndex));
