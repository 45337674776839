import {channelSetupConstants} from "../../../../constants/Network/Channels/channelSetup.constants";
import {updateChannelConstants} from "../../../../constants/Network/Channels/updateChannel.constans";

const initialState = {
    fetching : true,
    error : '',
    errorStatus : '',
    submitError : [],
    channelSetupData: [],
    channelInformationData: [],
    channelSetupSubmitData: [],
    channelInformationDataCategory: [],
    channelInformationAvaility: {},
    channelUpdate: [],
    bulkUpdateOptions : []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case channelSetupConstants.CHANNEL_SETUP_GET_PENDING:
            return {
                ...state,
                fetching: true,
                channelSetupSubmitData: [],
                error: '',
                errorStatus: '',
                submitError: [],
                channelSetupData: [],
                channelInformationData: [],
                channelInformationDataCategory: [],
                channelUpdate: [],
                channelInformationAvaility: [],
                bulkUpdateOptions: [],
            };
        case channelSetupConstants.CHANNEL_SETUP_GET_FULFILLED:
            return {
                ...state,
                fetching: false,
                channelSetupSubmitData: [],
                submitError: [],
                channelSetupData: action.payload,
                channelInformationData: action.payload.data.channel,
                bulkUpdateOptions: action.payload.data?.bulk_update_options,
                channelInformationDataCategory: action.payload.data.channel.property_channel_category,
                channelInformationAvaility: action.payload.data.channel.channel_availability_type,
            };
        case channelSetupConstants.CHANNEL_SETUP_GET_REJECTED:
            return {
                ...state,
                error: action.payload,
                channelSetupSubmitData: [],
                submitError: [],
                fetching: false
            };
        case channelSetupConstants.CHANNEL_SETUP_ADD_PENDING:
            return {
                ...state,
                fetching: false,
                submitError: [],
                channelSetupSubmitData: [],
            };
        case channelSetupConstants.CHANNEL_SETUP_ADD_FULFILLED:
            return {
                ...state,
                channelSetupSubmitData: action.payload,
                submitError: [],
                fetching: false,
            };
        case channelSetupConstants.CHANNEL_SETUP_ADD_REJECTED:
            return {
                ...state,
                submitError: action.payload,
                fetching: false,
                channelSetupSubmitData: [],

            };
        case updateChannelConstants.CHANNEL_MAPPING_ADD_PENDING:
            return {
                ...state,
                channelUpdate: [],
                fetching: false,
            };
        case updateChannelConstants.CHANNEL_MAPPING_ADD_FULFILLED:
            return {
                ...state,
                fetching: false,
                channelUpdate: action.payload,
            };
        case updateChannelConstants.CHANNEL_MAPPING_ADD_REJECTED:
            return {
                ...state,
                errorStatus: action.payload,
                fetching: false
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
