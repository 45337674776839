import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import {getCurrency, isDefined} from "../../../../../utils";
import {Alert} from "@material-ui/lab";
import {GetAddons, SyncAddons} from "../../../../../redux/actions/Network/Addons/addonsActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a12yProps(index) {
    return {
        id: `place-description-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}


export default function AddonSyncModal({id, pageId, open, handleClickOpen, handleClose, type, fact, descriptionData}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleSubmit } = useForm();

    const addonsReducers = useSelector(props => props.addonsReducers)

    const [descriptionTabValue, setDescriptionTabValue] = useState(0);
    const [addonData, setAddonData] = useState(fact?.channelAddon);

    const changeTitle = (e, id) => {
        const title = [...addonData];
        title[id]['title'] = e.target.value;
        setAddonData(title)
    };
    const changeType = (e, id) => {
        const type = [...addonData];
        type[id]['type'] = e.target.value;
        setAddonData(type)
    };
    const changeStatus = (e, id) => {
        const type = [...addonData];
        type[id]['status'] = e.target.value;
        setAddonData(type)
    };
    const changeAmount = (e, id) => {
        const amount = [...addonData];
        amount[id]['amount'] = e.target.value;
        setAddonData(amount)
    };


    const handleChangeCharCount = (e, id, tabKey) => {
        let values = [...addonData];
        values[id]['description'][tabKey]['description'] = e.target.value;
        setAddonData(values)
    };

    const onSubmit = () => {
        let params;

        params = {
            channel_id: pageId,
            property_addon_id: id,
            channelAddon: addonData,
        }

        dispatch(SyncAddons(params))
    };

    useEffect( () => {

        if (addonsReducers.syncAddonsStatus === 200 && open) {
            handleClose();
            dispatch(GetAddons(pageId));
        }

        if (addonsReducers.syncAddonsStatus === 200) {
            setAddonData(fact?.channelAddon);
        }

    },[addonsReducers])

    useEffect( () => {
        setAddonData(addonData);
    },[addonData])


    const handleDeleteFields = id => () => {
        let tempOptions = [...addonData];
        tempOptions.splice(id, 1);
        setAddonData(tempOptions);
    };

    const handleChangeDescriptionTab = (event, newValue) => {
        setDescriptionTabValue(newValue);
    };

    const handleAddField = id => evt => {

        setAddonData(addonData.concat([{
            title: "",
            amount: "",
            channel_id: pageId,
            type: "",
            status: "1",
            description:[
                {language_code:"en",description:null},
                {language_code:"fr",description:null},
                {language_code:"de",description:null},
                {language_code:"ru",description:null},
                {language_code:"es",description:null},
                {language_code:"tr",description:null}
            ]
        }
        ]));
    };

    return (
        <>
            {
                type !== "notSelected" ?
                    <ButtonAtm className="edit-channel-price-btn"
                               endIcon={<i className="fas fa-edit" />}
                               onClick={handleClickOpen}
                    />
                    :
                    <ColAtm onClick={handleClickOpen}>
                        <div className="MuiGrid-root fact-border-box MuiGrid-item">
                            <i className={"fact-icon " + fact.icon} aria-hidden="true"/>
                            <div className="MuiGrid-root fact-icon-label MuiGrid-item">
                                <div className="MuiGrid-root fact-icon-label text-scroll MuiGrid-item">
                                    <div className="text-scroll-div">
                                        <p className="MuiTypography-root text-wrap fact-name text-nowrap MuiTypography-body1">
                                        {t(fact.language_key)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ColAtm>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-fact_description_title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {isDefined(addonsReducers) && addonsReducers?.syncAddonsStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                            {isDefined(addonsReducers?.syncAddonsError?.response) && <Alert severity="error">{addonsReducers?.syncAddonsError?.response?.data?.message}</Alert>}

                            <Grid container>
                                {
                                    addonData.map((factData, index) => (
                                        <Grid xs={12}>
                                            <h3>{addonData.length > 1 && `${t(fact.language_key)} - ${index + 1}`}</h3>
                                            <Grid container className={addonData.length > 1 && "addon-box"}>
                                                <ColAtm xs={10}>
                                                    <Grid container spacing={3} className="mobile-no-spacing">
                                                        <Grid item xs={12} sm={4}>
                                                            <TextValidator
                                                                name="title"
                                                                type="text"
                                                                variant="outlined"
                                                                label={ t('enw-input-title') }
                                                                autoComplete="off"
                                                                value={factData.title}
                                                                onChange={(e) => changeTitle(e, index)}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <TextValidator
                                                                name="value"
                                                                type="text"
                                                                variant="outlined"
                                                                label={ t('enw-input-addon-value') }
                                                                autoComplete="off"
                                                                value={factData.amount}
                                                                onChange={(e) => changeAmount(e, index)}
                                                                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                                                validators={['required']}
                                                                errorMessages={[t("enw-notification_this_is_required")]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <SelectValidator
                                                                name="addon_type"
                                                                className="form-control"
                                                                variant="outlined"
                                                                fullWidth
                                                                label={ t('enw-input-addon_type') }
                                                                value={factData.type}
                                                                onChange={(e) => changeType(e, index)}
                                                                validators={['required']}
                                                                errorMessages={[t("enw-notification_this_is_required")]}
                                                            >
                                                                <MenuItem value={"ONT"}>{ t('enw-input-ont') }</MenuItem>
                                                                <MenuItem value={"PRP"}>{ t('enw-input-prp') }</MenuItem>
                                                            </SelectValidator>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <SelectValidator
                                                                name="addon_type"
                                                                className="form-control"
                                                                variant="outlined"
                                                                fullWidth
                                                                label={ t('enw-input-status') }
                                                                value={factData.status}
                                                                onChange={(e) => changeStatus(e, index)}
                                                                validators={['required']}
                                                                errorMessages={[t("enw-notification_this_is_required")]}
                                                            >
                                                                <MenuItem value={1}>{ t('enw-input-active') }</MenuItem>
                                                                <MenuItem value={0}>{ t('enw-input-inactive') }</MenuItem>
                                                            </SelectValidator>
                                                        </Grid>
                                                    </Grid>
                                                    <ColAtm className="d-block brand-container place-desc">
                                                        <ColAtm>
                                                            <AppBar className="room-rate-tab-title" position="static">
                                                                <Tabs className="room-rate-tabFont"
                                                                      value={descriptionTabValue}
                                                                      onChange={handleChangeDescriptionTab}
                                                                      aria-label="place-description-tab"
                                                                      variant="scrollable"
                                                                      scrollButtons="on"
                                                                >
                                                                    {
                                                                        factData.description.map((title,value) => {
                                                                            const flag = <Grid container className="brand-desc-flag" spacing={1}>
                                                                                <Grid item sm={3}>
                                                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} alt={title.language_code} />
                                                                                </Grid>
                                                                                <Grid item sm={2}>
                                                                                    {title.language_code}
                                                                                </Grid>
                                                                            </Grid>
                                                                            return (
                                                                                <Tab label={ flag } {...a12yProps(value)} />
                                                                            );
                                                                        })
                                                                    }
                                                                </Tabs>
                                                            </AppBar>


                                                            {
                                                                factData.description.map((title, tabKey) => {
                                                                    return (
                                                                        <TabPanel value={descriptionTabValue} index={tabKey} className="w-100">
                                                                            <TextField
                                                                                name={title.language_code}
                                                                                type="text"
                                                                                variant="outlined"
                                                                                className="text-capitalize"
                                                                                onChange={(e) => handleChangeCharCount(e, index, tabKey)}
                                                                                value={title.description}
                                                                                label={ t('enw-input-description') + " - " + title.language_code }
                                                                                multiline={true}
                                                                                fullWidth
                                                                                required={false}
                                                                                rows={2}
                                                                            />
                                                                        </TabPanel>
                                                                    );
                                                                })
                                                            }
                                                        </ColAtm>
                                                    </ColAtm>
                                                </ColAtm>
                                                <ColAtm md={1} className="form-group pt-24 pl-13 d-block" style={{ paddingTop:10}}>
                                                    <ButtonAtm className="delete-bed-button" fullwidth={true}
                                                               startIcon={<i className="fas fa-minus-circle" />}
                                                               onClick={handleDeleteFields(index)}
                                                    />
                                                </ColAtm>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>


                            <ColAtm xs={12} className="form-group pb-32 d-block">
                                <ButtonAtm className="room-rate-add soft-blue-button m-auto radius-8"
                                           fullwidth={true}
                                           buttontext={ t('btn-add_new-addon') }
                                           startIcon={<i className="fas fa-plus-circle" />}
                                           onClick={handleAddField(id)}
                                />
                            </ColAtm>

                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
