import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import RoomInfoDetails from "../RoomDetails/RoomInfo";
import ButtonAtm from "../../../../../atomic/Button/Button";
import EditRoom from "../EditRoom/EditRoom";
import {Grid, Hidden} from "@material-ui/core";
import {PropertyRoomAndRateList,PropertyRoomTypes} from "../../../../../../redux/actions/propertyRoomAndRateActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const RoomList = props => {
    const {rooms} = props;
    const [expanded, setExpanded] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [editRoom, setEditRoom] = React.useState([]);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const openEditRoom = (room) => {
        setEditRoom(room);
        setEditModalStatus(true);
    }
    const closeEditRoom = () => {
        setEditModalStatus(false);
    }

    useEffect( () => {
        if(props.updateStatus === 200)
        {
            if(editModalStatus){
                closeEditRoom();
                dispatch(PropertyRoomAndRateList());
                dispatch(PropertyRoomTypes());
            }

        }
    },[editModalStatus,props.updateStatus] )

    if(rooms && rooms.length === 0 )
    {
        return (
            <h2>{ t('enw-notification-no_results') }</h2>
        )
    }else{
        return (

            <>
                {
                    props.selectedRoomTypeId === 0 ? (
                            props.rooms.map( (room, key) => (

                                    <ExpansionPanel className="accordion-room-table" key={key} expanded={expanded === key} onChange={handleChange(key)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<i className="fas fa-caret-down" />}
                                            aria-label="Expand">
                                            <Grid container
                                                  justify="space-between"
                                                  alignItems="center"
                                            >
                                                <Grid xs={7} item className="d-inherit">
                                                    <i className="fas fa-bed room-rate-bed" />
                                                    <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                                </Grid>
                                                <Grid xs={5} item onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}>
                                                    <Hidden xsDown>
                                                        <ButtonAtm className="edit-room-btn"
                                                                   onClick={ ()=> openEditRoom(room) }
                                                                   buttontext={ t('btn-edit_room') }/>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <ButtonAtm className="edit-room-btn"
                                                                   onClick={ ()=> openEditRoom(room) }
                                                                   buttontext={<i className="fas fa-pen" /> }/>
                                                    </Hidden>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item room-detail-info">
                                            <RoomInfoDetails roomInfo={room}/>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ))
                    ) : (
                        props.rooms.filter( (room) => room.room_type_id === props.selectedRoomTypeId )
                            .map( (room, key) => (

                            <ExpansionPanel className="accordion-room-table" key={key} expanded={expanded === key} onChange={handleChange(key)}>
                                <ExpansionPanelSummary
                                    expandIcon={<i className="fas fa-caret-down" />}
                                    aria-label="Expand">
                                    <Grid container
                                          justify="space-between"
                                          alignItems="center"
                                    >
                                        <Grid xs={7} item className="d-inherit">
                                            <i className="fas fa-bed room-rate-bed" />
                                            <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                        </Grid>
                                        <Grid xs={5} item onClick={(event) => event.stopPropagation()}
                                              onFocus={(event) => event.stopPropagation()}>
                                            <Hidden xsDown>
                                                <ButtonAtm className="edit-room-btn"
                                                           onClick={ ()=> openEditRoom(room) }
                                                           buttontext={ t('btn-edit_room') }/>
                                            </Hidden>
                                            <Hidden smUp>
                                                <ButtonAtm className="edit-room-btn"
                                                           onClick={ ()=> openEditRoom(room) }
                                                           buttontext={<i className="fas fa-pen" /> }/>
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item room-detail-info">
                                    <RoomInfoDetails roomInfo={room} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))
                    )
                }

                {
                    editModalStatus &&
                            <EditRoom editRoomData={editRoom}
                                      closeEditRoom={closeEditRoom}
                                      allRoomTypes={props.allRoomTypes}
                                      allBedType={props.allBedType}
                                      roomViewTypes={props.roomViewTypes}
                                      roomSizeTypes={props.roomSizeTypes}
                                      rooms={props.rooms}
                            />
                }

            </>
        );
    }
};

RoomList.propTypes = {
    rooms: PropTypes.array.isRequired
};
RoomList.defaultProps = {};

export default RoomList;
