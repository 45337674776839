import React, {Component}   from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {listChannels} from "../../../../redux/actions/channelListActions";
import GetChannels from "./components/channelList";
import {addChannelSetup, getChannelSetup} from "../../../../redux/actions/Network/Channel/channelSetup.actions";
import {getChannelGroupChannels, channelGroupCreateData} from "../../../../redux/actions/Network/Channel/channelGroup.actions";

let t =null;

class Channels extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        this.props.listChannels("null");
        this.props.channelGroupCreateData();
        this.props.getChannelGroupChannels();
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <GetChannels channelSetupReducers={this.props.channelInformationData}
                             channelInformationDataCategory={this.props.channelInformationDataCategory}
                             getChannelSetup={this.props.getChannelSetup}
                             channel_list={this.props.channel_list}
                             channelGroupList={this.props.channel_group_list}
                             channel_list_reducers={this.props.channel_list_reducers}
                />
            </>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        channel_list_reducers : props.channelListReducers,
        channel_list : props.channelListReducers.channel_list,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelInformationDataCategory : props.channelSetupReducers.channelInformationDataCategory,
        channel_group_list : props.channelGroupReducers,
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    addChannelSetup,
    listChannels,
    getChannelGroupChannels,
    channelGroupCreateData
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Channels));
