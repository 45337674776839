import {
    createPaymentCodeConstants,
    createTicketConstants,
    getTicketConstants, updateTicketConstants
} from "../../../../../constants/Network/reservation.constants";

const initialState = {

    create_ticket_fetching :false,
    create_ticket : [],
    create_ticket_error : {},

    update_ticket_fetching :false,
    update_ticket : [],
    update_ticket_error : {},

    create_payment_code_fetching :false,
    create_payment_code : [],
    create_payment_code_error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
            //CREATE TICKET
        case createTicketConstants.CREATE_TICKET_PENDING:
            return {
                get_ticket_fetching: false,
                create_ticket_fetching:true,
                create_ticket_error: {},
            };
        case createTicketConstants.CREATE_TICKET_FULFILLED:
            return {
                ...state,
                create_ticket_fetching : false,
                create_ticket: action.payload,
                create_ticket_error: {},
            };
        case createTicketConstants.CREATE_TICKET_REJECTED:
            return {
                ...state,
                create_ticket_error: action.payload,
                create_ticket_fetching : false,
            };
        case createTicketConstants.CREATE_TICKET_CLEAR:
            return {
                ...state,
                create_ticket_fetching :false,
                create_ticket : [],
                create_ticket_error : {},
            };

            //UPDATE TICKET
        case updateTicketConstants.UPDATE_TICKET_PENDING:
            return {
                update_ticket_fetching:true,
                update_ticket_error: {}
            };
        case updateTicketConstants.UPDATE_TICKET_FULFILLED:
            return {
                ...state,
                update_ticket_fetching : false,
                update_ticket: action.payload,
                update_ticket_error: {}
            };
        case updateTicketConstants.UPDATE_TICKET_REJECTED:
            return {
                ...state,
                update_ticket_error: action.payload,
                update_ticket_fetching : false
            };
        case updateTicketConstants.UPDATE_TICKET_CLEAR:
            return {
                ...state,
                update_ticket_fetching :false,
                update_ticket : [],
                update_ticket_error : {},
            };

            //CREATE PAYMENT CODE
        case createPaymentCodeConstants.CREATE_PAYMENT_CODE_PENDING:
            return {
                create_payment_code_fetching:true,
                create_payment_code_error: {}
            };
        case createPaymentCodeConstants.CREATE_PAYMENT_CODE_FULFILLED:
            return {
                ...state,
                create_payment_code_fetching : false,
                create_payment_code: action.payload,
                create_payment_code_error: {}
            };
        case createPaymentCodeConstants.CREATE_PAYMENT_CODE_REJECTED:
            return {
                ...state,
                create_payment_code_error: action.payload,
                create_payment_code_fetching : false
            };
        case createPaymentCodeConstants.CREATE_PAYMENT_CODE_CLEAR:
            return {
                ...state,
                create_payment_code_fetching :false,
                create_payment_code : [],
                create_payment_code_error : {},
            };
        default:
            return state
    }
}
