import React, {Component} from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import RowAtm from "../../../atomic/Row/Row";
import Container from "@material-ui/core/Container";
import {connect}                from "react-redux";
import {Animated} from "react-animated-css";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import UpdatePasswordForm from "./components/UpdatePasswordForm";
import {updatePassword} from "../../../../redux/actions/User/UpdatePassword/updatePasswordAction"
import SuccessModal from "../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {CONTENT} from "../../../../constants/appUrls.constants";
let t=null;

class UpdatePassword extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
    }

    showMessage = () => {
        if( this.props.updatePasswordReducer.updatePasswordStatus === true ){
            return <SuccessModal status={true} message={"Şifreniz Başarılı Bir Şekilde Güncellenmiştir!"}/>
        }

        if( this.props.updatePasswordReducer.updatePasswordStatus === false &&
            this.props.updatePasswordReducer.error.response
        ){
            let message = this.props.updatePasswordReducer.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }


    render() {
        return (
            <>
                <PageTitle title={ t('enw-update-password-page_title') } />
                <PageHeaderSaveAtm
                    saveText="Save"
                    backText={ t('btn-dashboard') }
                    backButton={CONTENT}
                    text={ t('enw-update-password-title') }
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" className="border-top-red-border">
                        <div className="container-width">
                            <RowAtm>
                                <UpdatePasswordForm updatePassword={this.props.updatePassword}/>
                            </RowAtm>
                            {
                                this.showMessage()
                            }
                        </div>
                    </Container>
                </Animated>
            </>

        );
    }
}

const mapStateToProps = (props) => {

    return{
        updatePasswordReducer : props.updatePasswordReducer
    }
};

const mapDispatchToProps = {
    updatePassword
};

//export default connect(mapStateToProps, mapDispatchToProps )(UpdatePassword);
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(UpdatePassword));
