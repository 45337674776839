import {channelConstants, channelModalConstants} from "../../constants/Network/Channels/channel.constans";

const initialState = {
    fetching : false,
    status : false,
    error : '',
    channel_list: {},
    fetching_modal : false,
    error_modal : '',
    channel_list_modal: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case channelConstants.CHANNEL_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case channelConstants.CHANNEL_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                channel_list: action.payload.data,
            };
        case channelConstants.CHANNEL_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case channelModalConstants.CHANNEL_LIST_MODAL_PENDING:
            return {
                ...state,
                fetching_modal: true
            };
        case channelModalConstants.CHANNEL_LIST_MODAL_FULFILLED:
            return {
                ...state,
                fetching_modal: false,
                channel_list_modal: action.payload.data,
            };
        case channelModalConstants.CHANNEL_LIST_MODAL_REJECTED:
            return {
                ...state,
                error_modal: action.payload,
                fetching_modal: false
            };
        default:
            return state
    }
}
