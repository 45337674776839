import React,{Component} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import moment from 'moment';

import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {
    getPropertyInventory,
    getQuickPricing,
    updateOrCreateInventory
} from "../../../../redux/actions/Network/Inventory/inventoryAction";
import { propertyBulkUpdateClear,getPropertyBulkUpdate } from "../../../../redux/actions/Network/Inventory/BulkUpdateAction";
import { InventoryLink, InventoryLinkClear } from "../../../../redux/actions/Network/Inventory/inventoryLinkAction";
import { getChannelSetup } from "../../../../redux/actions/Network/Channel/channelSetup.actions";
import Rooms from "./components/Rooms";
import {NETWORK, NETWORK_INVENTORY_LIST} from "../../../../constants/appUrls.constants";
import {addDateAndReturnRange, isDefined} from "../../../../utils";
import PriceDateRange from "./components/PriceDateRange";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import {Grid} from "@material-ui/core";
import BulkUpdate from "./components/BulkUpdate/BulkUpdateModal";
import ButtonAtm from "../../../atomic/Button/Button";
import SuccessInventoryModal from "../../../organisms/Modal/SuccessModal/SuccessInventoryModal";
import SuccessModal from "../../../organisms/Modal/SuccessModal/SuccessModal";
import Competitor from "./components/Competitor";
import {
    getCpaClearState,
    getCpaDailyPrice,
    getPropertyCpa, setSyncCpaCompetitorClearState
} from "../../../../redux/actions/Network/Inventory/CompetitorActions";
import QuickPricing from "./components/QuickPricing";
import {getChannelCancellationList} from "../../../../redux/actions/Network/Channel/channelCancellation.actions";
import { getPropertyName } from "../../../../utils";
import { getProperty } from "../../../../utils";
import axios from "axios";
import { USER_MENU } from "../../../../constants/api.constants";
import { getAppLang } from "../../../../utils";
let t=null;

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allDays : addDateAndReturnRange(moment(new Date()).format("YYYY-MM-DD"), 14,'day'),
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).add(14, 'days').format("YYYY-MM-DD"),
            channelId : this.props.match.params.id,
            isPriceUpdate : false,
            showStopSell : false,
            showMinStay : false,
            showCompetitor : false,
            showQuickPricing : false,
            propertyName : '',
        };
        t = props.t;
    }

    componentDidMount() {
        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup({ channel_id : this.props.match.params.id });
            this.props.getChannelCancellationList({ channel_id : this.props.match.params.id });
            this.props.getQuickPricing(this.props.match.params.id);

            this.setState({
                channel_id: this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
        this.getPropertyInvertory();
        this.props.getPropertyCpa();
        this.props.InventoryLinkClear();

        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(USER_MENU, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            this.setState({propertyName: result.data.data.property_list[0].name + " - "});
        }).catch(function (error) {
            console.log(error);
        });
    }

    getPropertyInvertory = () => {

        const {channelId, startDate, endDate} = this.state

        if ( channelId !== undefined && channelId !== '' && !isNaN(channelId) )
        {
            this.props.getPropertyInventory( startDate, endDate, channelId );
            this.props.getPropertyBulkUpdate({ channel_id: channelId });

        }else{
            this.props.history.replace(NETWORK);
        }
    }

    getPropertyInvertoryDate = (startDate, endDate) => {

        const {channelId} = this.state

        if ( channelId !== undefined && channelId !== '' )
        {
            this.props.getPropertyInventory( startDate, endDate, channelId );
        }
    }

    nextDate = (startDate, endDate) => {
        this.getPropertyInvertoryDate(startDate,endDate);
    }

    previousDate = (startDate, endDate) => {
        this.getPropertyInvertoryDate(startDate,endDate);
    }

    updatePropertyInvertory = ( updateData ) => {
        const { channelId } = this.state
        this.props.updateOrCreateInventory(channelId, updateData)
    }

    inventoryLinkPost = () => {
        const { channelId } = this.state
        this.props.InventoryLink(channelId)
    }

    isChangePriceStatus =  () => {
        if(this.state.isPriceUpdate) return 0;
        else this.setState({isPriceUpdate : true})
    }

    changePriceStatus =  (status) => {
        if(this.state.isPriceUpdate === status) return 0;
        else this.setState({isPriceUpdate : status})
    }

    changeAllDays = ( allDays,startDate,endDate ) => {

        this.setState({
            allDays,
            startDate,
            endDate,
        })
    }

    showMessage = () => {
        const {channelId} = this.state;
        if( this.props.inventoryUpdate === 200 ){
            return <SuccessInventoryModal status={true}
                                          message={t('enw-notification_success')}
                                          channelId={channelId}
            />
        }

        if( this.props.error !== {}  &&
            this.props.error?.response
        ){
            let message = this.props.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    showInventoryLinkMessage = () => {

        const linkData = isDefined(this.props.inventoryLinkData) && isDefined(this.props.inventoryLinkData.inventoryLink) && this.props.inventoryLinkData.inventoryLink
        const error = isDefined(this.props.inventoryLinkData) && isDefined(this.props.inventoryLinkData.error) && this.props.inventoryLinkData.error

        if( linkData.status === 200 ){
            return <SuccessModal status={true}
                                 message={t('enw-notification_success')}
            />
        }

        if( error !== {}  &&
            error?.response
        ){
            let message = error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    showStopSell = () => {
        const { showMinStay, showCompetitor, showQuickPricing } = this.state;

        this.setState({showStopSell: !this.state.showStopSell})

        if(showMinStay || showCompetitor || showQuickPricing) {
            this.setState({showQuickPricing: false, showMinStay: false, showCompetitor: false, showStopSell: true})
        }
    }
    showMinStay = () => {
       this.setState({showMinStay: !this.state.showMinStay})
        const { showStopSell, showCompetitor, showQuickPricing } = this.state;

        if(showStopSell || showCompetitor || showQuickPricing) {
            this.setState({showMinStay: true, showQuickPricing: false, showCompetitor: false, showStopSell: false})
        }
    }

    showCompetitor = () => {
        const { showStopSell, showMinStay, showCompetitor, showQuickPricing } = this.state;

        this.setState({showCompetitor: !this.state.showCompetitor})

        if(showStopSell || showMinStay || showQuickPricing) {
            this.setState({showQuickPricing: false, showMinStay: false, showStopSell: false, showCompetitor: true})
        }

        if (showCompetitor === false) {
            this.props.setSyncCpaCompetitorClearState();
            this.props.getCpaClearState();

            window.stop();
        }
    }

    showQuickPricing = () => {
        this.setState({showQuickPricing: !this.state.showQuickPricing})
        const { showStopSell, showCompetitor, showMinStay } = this.state;

        if(showStopSell || showCompetitor || showMinStay) {
            this.setState({showQuickPricing: true, showMinStay: false, showCompetitor: false, showStopSell: false})
        }
    }


    render() {
        const {t, inventory, channelInformationData, channelInformationFetching, getCpa} = this.props;
        const {allDays, startDate, endDate, showStopSell, showMinStay, showCompetitor, showQuickPricing} = this.state;

        return(
            <>
                <PageTitle title={ t('enw-inventory-page_title') } />
                <PageHeaderSaveAtm backButton={NETWORK_INVENTORY_LIST}
                                   backButtonName={ t('enw-network-inventory_list-title') }
                                   text={ channelInformationData !== undefined && !channelInformationFetching && this.state.propertyName + channelInformationData['name']}
                                   saveButtonDisabled={this.props.fetching || !this.state.isPriceUpdate}
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width m-inventory-container">
                            <Grid container className="mb-16" spacing={3}>
                                <Grid item md={channelInformationData.channel_category_id !== 5 && 2}>
                                </Grid>

                                {
                                    channelInformationData.channel_category_id === 5 &&
                                    <Grid item xs={12} md={2}>
                                        <ButtonAtm className="inventory-action-btn bg-dark-orange inventory-filter-box inventory-link-text"
                                                   buttontext={ t('btn-inventory-link') }
                                                   fullwidth={true}
                                                   onClick={this.inventoryLinkPost}
                                                   startIcon={<i className="fas fa-envelope" />}
                                                   endIcon={<span> <br/> {t('btn-inventory-link-desc')}</span>}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-dark-orange inventory-filter-box"
                                               buttontext={ t('enw-input-quick-pricing') }
                                               fullwidth={true}
                                               onClick={this.showQuickPricing}
                                               endIcon={showQuickPricing && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-green inventory-filter-box"
                                               buttontext={ t('enw-input-competitor') }
                                               fullwidth={true}
                                               onClick={this.showCompetitor}
                                               endIcon={showCompetitor && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-dark-blue inventory-filter-box"
                                               buttontext={ t('enw-input-min_stay') }
                                               fullwidth={true}
                                               onClick={this.showMinStay}
                                               endIcon={showMinStay && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonAtm className="inventory-action-btn bg-red inventory-filter-box"
                                               buttontext={ t('btn-stop_sell') }
                                               fullwidth={true}
                                               onClick={this.showStopSell}
                                               endIcon={showStopSell && <i className="far fa-times" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <BulkUpdate channelId={this.props.match.params.id } changeAllDays={this.changeAllDays}/>
                                </Grid>

                            </Grid>
                            <ColAtm>
                                <PriceDateRange allDays={allDays}
                                                startDate={startDate}
                                                endDate={endDate}
                                                previousDate={this.previousDate}
                                                nextDate={this.nextDate}
                                                getPropertyInvertoryDate={this.getPropertyInvertoryDate}
                                                fetching={this.props.fetching}
                                                getCpa={getCpa}
                                                showCompetitor={showCompetitor}
                                                getCpaDailyPrice={this.props.getCpaDailyPrice}
                                                getCpaClearState={this.props.getCpaClearState}
                                />
                            </ColAtm>
                            {
                                showQuickPricing ?
                                    <>
                                        <QuickPricing
                                            showQuickPricing={showQuickPricing}
                                            allDays={this.state.allDays}
                                            roomPrice={inventory}
                                            channelId={this.state.channelId}
                                            getPropertyInventory={this.props.getPropertyInventory}
                                            updatePropertyInvertory={this.updatePropertyInvertory}
                                            changePriceStatus={this.changePriceStatus}
                                            isChangePriceStatus={this.isChangePriceStatus}
                                            channelAvailability={this.props.channelAvailability}
                                            fetching={this.props.fetching}
                                            getQuickPricing={this.props.getQuickPricingData}
                                        />
                                        <Rooms roomPrice={inventory}
                                               allDays={this.state.allDays}
                                               getPropertyInventory={this.props.getPropertyInventory}
                                               updatePropertyInvertory={this.updatePropertyInvertory}
                                               changePriceStatus={this.changePriceStatus}
                                               isChangePriceStatus={this.isChangePriceStatus}
                                               inventoryUpdate={this.props.inventoryUpdate}
                                               channelAvailability={this.props.channelAvailability}
                                               fetching={this.props.fetching}
                                               showStopSell={showStopSell}
                                               showMinStay={showMinStay}
                                               type="quickPrice"
                                        />
                                    </>
                                    :
                                showCompetitor ?

                                    <>
                                        <Competitor
                                            showCompetitor={showCompetitor}
                                            allDays={this.state.allDays}
                                        />

                                        <Rooms roomPrice={inventory}
                                               allDays={this.state.allDays}
                                               getPropertyInventory={this.props.getPropertyInventory}
                                               updatePropertyInvertory={this.updatePropertyInvertory}
                                               changePriceStatus={this.changePriceStatus}
                                               isChangePriceStatus={this.isChangePriceStatus}
                                               inventoryUpdate={this.props.inventoryUpdate}
                                               channelAvailability={this.props.channelAvailability}
                                               fetching={this.props.fetching}
                                               showStopSell={showStopSell}
                                               showMinStay={showMinStay}
                                        />
                                    </>
                                    :
                                    <Rooms roomPrice={inventory}
                                           allDays={this.state.allDays}
                                           getPropertyInventory={this.props.getPropertyInventory}
                                           updatePropertyInvertory={this.updatePropertyInvertory}
                                           changePriceStatus={this.changePriceStatus}
                                           isChangePriceStatus={this.isChangePriceStatus}
                                           inventoryUpdate={this.props.inventoryUpdate}
                                           channelAvailability={this.props.channelAvailability}
                                           fetching={this.props.fetching}
                                           showStopSell={showStopSell}
                                           showMinStay={showMinStay}

                                    />
                            }

                        </div>
                    </Container>
                </Animated>
                {this.showMessage()}
                {this.showInventoryLinkMessage()}
            </>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        fetching        : props.inventoryReducer.fetching,
        error           : props.inventoryReducer.error,
        inventory       : props.inventoryReducer.inventory,
        inventoryUpdate : props.inventoryReducer.inventoryUpdate ?. status,
        inventoryUpdateError : props.inventoryReducer.inventoryUpdate ?. error,
        channelInformationFetching : props.channelSetupReducers.fetching,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelAvailability : props.channelSetupReducers.channelInformationAvaility,
        channelSetupData : props.channelSetupReducers.channelSetupData.data,
        inventoryLinkData: props.inventoryLinkReducer,
        getCpa: props.cpaReducer.getCpa,
        quickPricingData: props.quickPricingReducer.quickPricing,
        getQuickPricingData: props.quickPricingReducer,
    }
};

const mapDispatchToProps ={
    getPropertyInventory,
    getChannelSetup,
    updateOrCreateInventory,
    getPropertyBulkUpdate,
    InventoryLink,
    InventoryLinkClear,
    propertyBulkUpdateClear,
    getPropertyCpa,
    getCpaDailyPrice,
    getCpaClearState,
    setSyncCpaCompetitorClearState,
    getChannelCancellationList,
    getQuickPricing
};
export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Index));
