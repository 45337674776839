import React from 'react';
import ColAtm from "../../../../../atomic/Col/Col";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LabelAtm from "../../../../../atomic/Label/Label";
import {useTranslation} from "react-i18next";
import {isDefined} from "../../../../../../utils";

export default function RoomInfoDetails( { roomInfo } ) {
    const { t } = useTranslation();
    return (
            <ColAtm>
                <Grid container className="offer-page-block" xs={12}>
                    <Grid item xs={12} md={6}>
                        <h2> { t('room-rooms-detail-room_info-title') } </h2>
                        <Divider />
                        <ColAtm className="mb-24">
                            <ColAtm xs={12} sm={4} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-room-type') }/>
                                <LabelAtm text={ t(roomInfo.property_room_type.language_key) }/>
                            </ColAtm>
                            <ColAtm xs={12} sm={4} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-room_view') }/>
                                {
                                    roomInfo.property_room_view_mapping.length !== 0 &&
                                    roomInfo.property_room_view_mapping.map( (room_view) => (
                                        <span className="d-block"> { t(room_view.language_key) }</span>
                                    ) )
                                }
                            </ColAtm>
                            <ColAtm xs={6} sm={4} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-max_room_occupancy') }/>
                                <LabelAtm text= {roomInfo.max_occupancy}/>
                            </ColAtm>
                            <ColAtm xs={6} sm={4} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-max_adults') } />
                                <LabelAtm text= {roomInfo.max_adult}/>
                            </ColAtm>
                            <ColAtm xs={6} sm={4} className="offer-facility-information">
                                <LabelAtm text={ t('enw-input-max_children') } />
                                <LabelAtm text= {roomInfo.max_child}/>
                            </ColAtm>
                            {
                                isDefined(roomInfo.room_size) &&
                                <ColAtm xs={6} sm={4} className="offer-facility-information">
                                    <LabelAtm text={ t('enw-input-room_size') } />
                                    <LabelAtm text= {roomInfo.room_size + " " + roomInfo.room_size_type}/>
                                </ColAtm>
                            }
                        </ColAtm>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h2> { t('room-rooms-detail-bed_types-title') } </h2>
                        <Divider />
                        <ColAtm className="mb-24">
                            {
                                roomInfo.property_room_bed_group.length !== 0 ?
                                    roomInfo.property_room_bed_group.map( (beds,key) => (
                                        <ColAtm xs={12} sm={6} className="offer-facility-information">
                                            <p> { t('room-rooms-detail-bed_types-title') } {key+1}</p>
                                            <p className="room-type-list-content">
                                                {
                                                    beds.map( bed => (
                                                        <span className="d-block"> &#9679; { t(bed.bed_type_language_key) } ({bed.count} { t('room-rooms-detail-bed_types-count')})</span>
                                                    ))
                                                }
                                            </p>
                                        </ColAtm>

                                    ) ) : <ColAtm>  </ColAtm>
                            }
                        </ColAtm>
                    </Grid>
                </Grid>
            </ColAtm>
    );
}
