import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import axios from "axios";
import {CHANNEL_MAPPING_ADD} from "../../../../constants/api.constants";

export function updateChannelConnect(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_channel: [
                    {
                        id: params.id,
                        is_selected:  params.is_selected
                    }
                ]
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CHANNEL_MAPPING_ADD',
            payload: axios.post(CHANNEL_MAPPING_ADD, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
