import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ColAtm from "../../../../atomic/Col/Col";
import ButtonAtm from "../../../../atomic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import {Grid, withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {PROPERTY_CONTENT_CODE} from "../../../../../constants/api.constants";
import axios from "axios";
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {useForm} from "react-hook-form";
import {getAppLang, getProperty} from "../../../../../utils";
import {Alert} from "@material-ui/lab";
import BlockAtm from "../../../../atomic/Block/Block";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button"/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function MultimediaCodeDownload(props) {
    const {handleSubmit} = useForm();
    const {t} = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(props.contentCode === " " ? "" : props.contentCode);
    const [message, setMessage] = useState("");
    const [noPassword, setNoPassword] = useState("");
    const [checkDisabled, setCheckDisabled] = useState(props.contentCode === " " ? true : false);

    useEffect(() => {
        setMessage("");
    }, []);

    const handleChange = ({target}) => {
        setValue(target.value);
    };

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            params: {
                property_id: getProperty(),
                content_code: value === "" ? " " : value,
            }
        }

        axios.post(PROPERTY_CONTENT_CODE, params, {
            headers: {
                'content-type': 'application/json',
                'authToken': localStorage.getItem('auth'),
                "language": getAppLang(),
            }
        })
            .then(() => {
                setMessage("success");
                setTimeout(() => {
                    setOpenModal(false);
                    setMessage("");
                }, 1000);
            })
            .catch(() => {
                setMessage("error")
            })
    };

    const modalOpen = () => {
        setMessage("");
        setOpenModal(true)
    }

    const handleCheckboxChange = ({target}) => {
        if (target.checked) {
            setNoPassword(" ")
            setCheckDisabled(true)
            setValue("")
        }
        else {
            setCheckDisabled(false)
        }
        
    }

    return (
        <div>
            <button className="download-content-photo-btn" onClick={modalOpen}>
                <i className="fas fa-lock-alt"/> {t('btn-multimedia-download-code')}
            </button>
            <Dialog onClose={() => setOpenModal(false)} aria-labelledby="status" open={openModal}
                    className="web-preview-dialog">
                <DialogTitle className="red-bottom-border" id="status" onClose={() => setOpenModal(false)}>
                    {t('btn-multimedia-download-code')}
                </DialogTitle>
                <DialogContent>
                    <Grid container className="p-24">
                        {message === "success" &&
                        <Alert className="w-100" severity="success">{t('enw-success-message')}</Alert>}
                        {message === "error" &&
                        <Alert className="w-100" severity="error">{t('enw-error-message')}</Alert>}
                        <BlockAtm md={12}>
                            <p className="f-w-600">
                                {(t('enw-input-multimedia-download-code-desc'))}
                            </p>
                        </BlockAtm>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)} onError={errors => console.log(errors)}
                                       className="w-100">
                            <TextValidator
                                name="code"
                                type="text"
                                fullWidth
                                value={value}
                                variant="outlined"
                                label={t('enw-input-multimedia-download-code')}
                                placeholder={t('enw-input-multimedia-download-code')}
                                autoComplete="off"
                                onChange={handleChange}
                                inputProps={{maxLength: 10}}
                                disabled={checkDisabled}
                            />
                            <p className='mtm-error'>{value?.length < 5 && value !== "" ? t('enw-input-multimedia-error_message') : ""}</p>
                            <FormControlLabel
                                className="desc-text pr-10 pl-10-mobile"
                                name="noPass"
                                control={<Checkbox color="primary" value={noPassword} defaultChecked={checkDisabled} id="1" />}
                                label={ t('enw-input-multimedia-no_password') }
                                labelPlacement="left"
                                onClick={handleCheckboxChange}
                            />
                            <ColAtm className="mt-32" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    buttontext={t('btn-save')}
                                    color="primary"
                                    type="submit"
                                    disabled={value?.length < 5 && value !== "" ? true : false}
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

