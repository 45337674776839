import React, {Component, Fragment} from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ButtonAtm from "../../../../atomic/Button/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {NoSsr} from "@material-ui/core";
import CoverPhoto from "../../../../molecules/CoverPhoto/CoverPhoto";
import ShowLiveWeb from "./showLiveWeb";
import MywebPhotos from "../../../../molecules/MywebPhotos/MywebPhotos";
import FactCheckBox from "../../../../molecules/FactContent/FactContent";
import {CheckboxGroup, NoneCheckerCheckbox} from "@createnl/grouped-checkboxes";
import MultiFactCheckBox from "../../../../molecules/MultiFactContent/MultiFactContent";
import ColorPicker from "rc-color-picker";
import Alert from '@material-ui/lab/Alert';
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextCounter from "./TextCounter";
import BlockAtm from "../../../../atomic/Block/Block";
import OverFlowText from "../../../../molecules/OverFlowText/OverFlowText";
import Dialog from "@material-ui/core/Dialog";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {isDefined} from "../../../../../utils";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import InputAtm from "../../../../atomic/Input/Input";
import CreateBlogModal from "./Blog/CreateBlog";
import {PAYMENT_UPDATE_SHORT, WEB_BLOG_LIST, WEB_POPUP_LIST} from "../../../../../constants/appUrls.constants";
import {Link} from "react-router-dom";

let t =null;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class WebEditAccordions extends Component {
    constructor(props) {
        super(props);
        t = props.t;

        this.state = {
            property_web_id: this.props.getEditContentWeb.editWebData.id ? this.props.getEditContentWeb.editWebData.id : '',
            aboutUs : [],
            tabValue : 0,
            tabMenuValue : 0,
            action: "",
            photo_mapping: [],
            cover_photos: [],
            languages: [],
            rooms: [],
            menus: [],
            forPostMenus: [{}],
            web_base: false,
            show_success_modal: false,
            open : true,
            cover_button : false,
            changeComponents: false,
            coverPhotoRequired: false,
            count: 1,
            topCount: 1,
            openModal:false,
            menu_list: [],
            selectedMenuClass: null,
            selectedTopMenuClass: null,
            menuPostData: [],
            myweb_contact_email: null,
            kvkk_mersis_no: "",
            kvkk_data_controller: "",
            kvkk_contact_person: "",
            kvkk_address: "",
            kvkk_email: "",
            kvkk_phone: "",
            kvkk_tax_number: "",
            kvkk_tax_office: "",
            kvkk_web_site: "",
            kvkk_contact_email: "",
            kvkk_contact_address: "",
            property_country_code: null,
            isEmail: false,
            weather_active: null,
            colors : this.props.getEditContentWeb.editWebData.colors && Object.keys(this.props.getEditContentWeb.editWebData.colors).length > 0 ? this.props.getEditContentWeb.editWebData.colors :
                [
                    {color_number : 1, color_code : ''},
                    {color_number : 2, color_code : ''}
                ],
        };

        this.onClickPhoto = this.onClickPhoto.bind(this);
        this.onClickCoverPhoto = this.onClickCoverPhoto.bind(this);
        this.onClickLang = this.onClickLang.bind(this);
        this.onClickRoom = this.onClickRoom.bind(this);
        this.selectAllMenu = this.selectAllMenu.bind(this);
        this.handleDeleteColor = this.handleDeleteColor.bind(this);
        this.handleAddColor = this.handleAddColor.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onPreview = this.onPreview.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.languageList = this.languageList.bind(this);
        this.roomList = this.roomList.bind(this);
    }

    componentDidMount() {
        let photo_mapping = [];
        let cover_photos = [];
        let languages = [];
        let rooms = [];
        let menus = [];
        let forPostMenus = [];
        let menuPostData = [];
        let menu_list = [];
        let countMenu = 1;
        let topCountMenu = 1;
        let aboutUs = [];
        let myweb_contact_email = [];
        let kvkk_mersis_no = [];
        let kvkk_data_controller = [];
        let kvkk_contact_person = [];
        let kvkk_address = [];
        let kvkk_email = [];
        let kvkk_phone = [];
        let kvkk_tax_number = [];
        let kvkk_tax_office = [];
        let kvkk_web_site = [];
        let kvkk_contact_email = [];
        let kvkk_contact_address = [];
        let property_country_code = [];
        let weather_active = null;


        if(this.props.getEditContentWeb.editWebData.length  !== 0 ) {
            photo_mapping = this.props.getEditContentWeb.editWebData.property_web_photos.filter((photo) => photo.is_selected && !photo.is_cover).map(photo => photo.photo_id);
            cover_photos = this.props.getEditContentWeb.editWebData.property_web_photos.filter((cover) => cover.is_cover === true).map(cover => cover.photo_id);
            languages = this.props.getEditContentWeb.editWebData.languages.filter((languages) => languages.is_selected === true).map(languages => languages.code);
            rooms = this.props.getEditContentWeb.editWebData.rooms.filter((rooms) => rooms.is_selected === true).map(rooms => rooms.id);
            countMenu = this.props.getEditContentWeb.editWebData.menus.filter((menus) => menus.is_selected === true).length;
            topCountMenu = this.props.getEditContentWeb.editWebData.menus.filter((menus) => menus.is_selected === true).length;
            menus = this.props.getEditContentWeb.editWebData.menus;
            myweb_contact_email = this.props.getEditContentWeb.editWebData.additional_info.myweb_contact_email;
            kvkk_mersis_no = this.props.getEditContentWeb.editWebData.additional_info.kvkk_mersis_no;
            kvkk_data_controller = this.props.getEditContentWeb.editWebData.additional_info.kvkk_data_controller;
            kvkk_contact_person = this.props.getEditContentWeb.editWebData.additional_info.kvkk_contact_person;
            kvkk_address = this.props.getEditContentWeb.editWebData.additional_info.kvkk_address;
            kvkk_email = this.props.getEditContentWeb.editWebData.additional_info.kvkk_email;
            kvkk_phone = this.props.getEditContentWeb.editWebData.additional_info.kvkk_phone;
            kvkk_tax_number = this.props.getEditContentWeb.editWebData.additional_info.kvkk_tax_number;
            kvkk_tax_office = this.props.getEditContentWeb.editWebData.additional_info.kvkk_tax_office;
            kvkk_web_site = this.props.getEditContentWeb.editWebData.additional_info.kvkk_web_site;
            kvkk_contact_email = this.props.getEditContentWeb.editWebData.additional_info.kvkk_contact_email;
            kvkk_contact_address = this.props.getEditContentWeb.editWebData.additional_info.kvkk_contact_address;
            property_country_code = this.props.getEditContentWeb.editWebData.property_country_code;
            weather_active = this.props.getEditContentWeb.editWebData.weather_active;
            menu_list = this.props.getEditContentWeb.editWebData.menus.filter((menus) => menus.is_selected === true).map(menu => {
                return{
                    language_key: menu.language_key,
                    order_number_mapping: menu.order_number_mapping,
                    menu_type: menu.menu_type,
                    menu_code: menu.menu_code,
                    status: menu.status,
                }
            });
            menuPostData = this.props.getEditContentWeb.editWebData.menus.filter((menus) => menus.is_selected === true).map(menu => {
                return{
                    id: menu.id,
                    order_number: menu.order_number_mapping,
                    menu_type: menu.menu_type,
                    menu_code: menu.menu_code,
                }
            })
            aboutUs = this.props.getEditContentWeb.editWebData.property_web_about_us ? this.props.getEditContentWeb.editWebData.property_web_about_us : [];
        }

        this.setState({
            photo_mapping : photo_mapping,
            cover_photos : cover_photos,
            myweb_contact_email : myweb_contact_email,
            kvkk_mersis_no : kvkk_mersis_no,
            kvkk_data_controller : kvkk_data_controller,
            kvkk_contact_person : kvkk_contact_person,
            kvkk_address: kvkk_address,
            kvkk_email: kvkk_email,
            kvkk_phone: kvkk_phone,
            kvkk_tax_number: kvkk_tax_number,
            kvkk_tax_office: kvkk_tax_office,
            kvkk_web_site: kvkk_web_site,
            kvkk_contact_email: kvkk_contact_email,
            kvkk_contact_address: kvkk_contact_address,
            property_country_code : property_country_code,
            isEmail: isDefined(myweb_contact_email) ? true : false,
            languages : languages,
            rooms : rooms,
            menus : menus,
            forPostMenus : forPostMenus,
            count : countMenu,
            topCount : topCountMenu,
            menuPostData : menuPostData,
            menu_list : menu_list,
            cover_button : false,
            aboutUs : aboutUs,
            weather_active: weather_active
        });

        if (cover_photos && cover_photos.length && cover_photos.filter( (cover) => cover.is_cover === true)) {
            this.setState({coverPhotoRequired: true})
        } else {
            this.setState({coverPhotoRequired: false})
        }
    }


    photoList = () => {

        let photos = '';

        if(this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.property_web_photos.length !== 0 ){
            photos =  this.props.getEditContentWeb.editWebData.property_web_photos.map( (photo,key) => (
                <MywebPhotos
                    checkboxId={photo.photo_id}
                    className="channel-box"
                    colClassname="room-photo-grid"
                    blockClass="photo-select-border-box channel-border-box show-cover numbered-photos"
                    src={photo.photo_url_thump}
                    value={photo.photo_id}
                    defaultChecked={photo.is_selected && !photo.is_cover}
                    labelClass="fact-name"
                    key={key}
                    htmlFor={photo.photo_id}
                    onClick={this.onClickPhoto}
                    selectedPhotos={this.state.photo_mapping}
                />
            ))
        }
        return photos;
    };

    onClickPhoto = (e) => {
        e.stopPropagation();
        this.setState({
            changeComponents: true
        });
        if(e.target.checked) {
            this.setState({
                photo_mapping: [ ...this.state.photo_mapping, Number(e.target.value)],
                cover_button : true
            });
        }else{
            let temp_photo_mapping = this.state.photo_mapping;
            let index = temp_photo_mapping.findIndex((item) => item == Number(e.target.value))
            if (index !== -1) {
                temp_photo_mapping.splice(index, 1);
                this.setState({photo_mapping: temp_photo_mapping,cover_button : true});
            }
        }
    };

    sliderPhotoList = () => {

        let photos = '';

        if(this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.property_web_photos.length !== 0 ){
            photos =  this.props.getEditContentWeb.editWebData.property_web_photos.map( (photo,key) => (
                photo.is_compatible_with_myweb_slider === 1 &&
                <CoverPhoto
                    className="channel-box"
                    colClassname="room-photo-grid"
                    blockClass="photo-select-border-box channel-border-box show-cover"
                    src={photo.photo_url_thump}
                    labelClass="fact-name"
                    cover_key={key}
                    defaultCoverChecked={photo.is_cover}
                    cover_id={photo.photo_id+""+photo.photo_id}
                    cover_value={photo.photo_id}
                    cover_onClick={this.onClickCoverPhoto}
                />
            ))
        }
        return photos;
    };

    onClickCoverPhoto = (e) => {
        e.stopPropagation();
        this.setState({
            changeComponents: true,
            coverPhotoRequired: true
        });
        if(e.target.checked) {
            this.setState({
                cover_photos: [ ...this.state.cover_photos, e.target.value],
            });
        }else{
            let temp_photo_mapping = this.state.cover_photos;
            let index = temp_photo_mapping.findIndex((item) => item == e.target.value)
            if (index !== -1) {
                temp_photo_mapping.splice(index, 1);
                this.setState({cover_photos: temp_photo_mapping});
            }
        }

    };

    languageList = () => {

        let languages = '';

        if(this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.languages.length !== 0 ){
            languages =  this.props.getEditContentWeb.editWebData.languages.map( (language,key) => (
                <FactCheckBox
                    checkboxId={language.code}
                    className="fact-box"
                    colClassname="offer-fact-col"
                    blockClass="offer-fact-border-box fact-border-box"
                    labelIconClass="fact-icon-label"
                    src={`${process.env.PUBLIC_URL}/assets/img/language/${language.code}.svg`}
                    value={language.code}
                    defaultChecked={language.is_selected}
                    labelClass="fact-name"
                    imgClass="myweb-langs"
                    sm={4} md={2}
                    key={key}
                    htmlFor={language.code}
                    onClick={this.onClickLang}
                    text={t(language.language_key)}
                />
            ))
        }
        return languages;
    };

    roomList = () => {

        let rooms = '';

        if(this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.rooms.length !== 0 ){
            rooms =  this.props.getEditContentWeb.editWebData.rooms.map( (room,key) => (
                <FactCheckBox
                    checkboxId={room.id}
                    className="fact-box"
                    colClassname="offer-fact-col"
                    blockClass="offer-fact-border-box fact-border-box"
                    labelIconClass="fact-icon-label"
                    iconClass="offer-fact-icon fact-icon fas fa-bed"
                    value={room.id}
                    defaultChecked={room.is_selected}
                    labelClass="fact-name"
                    sm={4} md={2}
                    key={key}
                    htmlFor={room.id}
                    onClick={this.onClickRoom}
                    text={room.name}
                />
            ))
        }
        return rooms;
    };

    onClickLang = (e) => {
        e.stopPropagation();
        this.setState({
            changeComponents: true
        });
        if(e.target.checked) {
            this.setState({
                languages: [ ...this.state.languages, e.target.value]
            });
        }else{
            let temp_languages = this.state.languages;
            let index = temp_languages.findIndex((item) => item == e.target.value)
            if (index !== -1) {
                temp_languages.splice(index, 1);
                this.setState({languages: temp_languages});
            }
        }
    };

    onClickRoom = (e) => {
        e.stopPropagation();
        this.setState({
            changeComponents: true
        });
        if(e.target.checked) {
            this.setState({
                rooms: [ ...this.state.rooms, e.target.value]
            });
        }else{
            let temp_rooms = this.state.rooms;
            let index = temp_rooms.findIndex((item) => item === Number(e.target.value))
            if (index !== -1) {
                temp_rooms.splice(index, 1);
                this.setState({rooms: temp_rooms});
            }
        }
    };

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClose = () => {
        this.setState({open:false});
    };

    handleClickOpenModal = () => {
        this.setState({openModal:true, changeComponents: true});
    };

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({openModal:false});
    };

    onClickWeatherCheckbox = (e) => {
        if (e.target.checked) {
            this.setState({weather_active: 1});
        }
        else {
            this.setState({weather_active: null});
        }
        this.setState({
            changeComponents: true
        });
    };

    onPreview = (e) => {
        e.preventDefault();
        this.setState({
            web_base : true,
            open: true,
            show_success_modal : false,
        });
        this.props.updatePreviewEditContentWeb( this.state );
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            action : e.target.value,
            web_base : true,
            show_success_modal : true,
        });
        this.props.updateEditContentWeb( this.state );
    };

    onClickMenu = (e, key, openModal) => {

        if (e.target.value === undefined) return 0;

        if (openModal === "openModal") {
            this.setState({
                openModal: true
            });
        } else {
            this.setState({
                openModal: false
            });
        }
        this.setState({
            changeComponents: true
        });

        if(e.target.checked) {
            let temp_menus = this.state.menus;
            temp_menus[key]['order_number'] = this.state.count;
            temp_menus[key]['is_selected'] = true;
            temp_menus[key]['menu_code'] = "LVL1";
            temp_menus[key]['order_number_mapping'] = this.state.count + 1;
            temp_menus[key]['status'] = 1;

            this.setState({
                count:  this.state.count + 1,
                menu_list : [ ...this.state.menu_list,
                    {
                        language_key:temp_menus[key]['language_key'],
                        order_number_mapping:temp_menus[key]['order_number_mapping'],
                        menu_type:temp_menus[key]['menu_type'],
                        menu_code:temp_menus[key]['menu_code'],
                        status:temp_menus[key]['status']
                    }
                ],
                menuPostData : [ ...this.state.menuPostData,
                        {
                            id:temp_menus[key]['id'],
                            order_number:temp_menus[key]['order_number'] + 1,
                            menu_type:temp_menus[key]['menu_type'],
                            menu_code:temp_menus[key]['menu_code'],
                        }
                ]
            });
        }else{
            let temp_menus = this.state.menus;
            temp_menus[key]['order_number'] = this.state.count;
            temp_menus[key]['is_selected'] = false;
            temp_menus[key]['status'] = 0;

            let index = temp_menus.findIndex((item) => item == e.target.value)
            if (index !== -1) {
                temp_menus.splice(index, 1);
                this.setState({
                    menus: temp_menus,
                    count:  temp_menus[key]['order_number'] - 1
                });
            }
        }
    };

    onClickTopMenu = (e, key, openModal) => {

        if (e.target.value === undefined) return 0;

        this.setState({
            changeComponents: true
        });

        if (openModal === "openModal") {
            this.setState({
                openModal: true
            });
        } else {
            this.setState({
                openModal: false
            });
        }

        if(e.target.checked) {
            let temp_menus = this.state.menus;
            temp_menus[key]['order_number'] = this.state.topCount;
            temp_menus[key]['is_selected'] = true;
            temp_menus[key]['menu_code'] = "TOP";
            temp_menus[key]['order_number_mapping'] = this.state.topCount + 1;
            temp_menus[key]['status'] = 1;

            this.setState({
                topCount:  this.state.topCount + 1,
                menu_list : [ ...this.state.menu_list,
                    {
                        language_key:temp_menus[key]['language_key'],
                        order_number_mapping:temp_menus[key]['order_number_mapping'],
                        menu_type:temp_menus[key]['menu_type'],
                        menu_code:temp_menus[key]['menu_code'],
                        status:temp_menus[key]['status']
                    }
                ],
                menuPostData : [ ...this.state.menuPostData,
                    {
                        id:temp_menus[key]['id'],
                        order_number:temp_menus[key]['order_number'] + 1,
                        menu_type:temp_menus[key]['menu_type'],
                        menu_code:temp_menus[key]['menu_code'],
                    }
                ]
            });
        }else{
            let temp_menus = this.state.menus;
            temp_menus[key]['order_number'] = this.state.topCount;
            temp_menus[key]['is_selected'] = false;
            temp_menus[key]['status'] = 0;

            let index = temp_menus.findIndex((item) => item == e.target.value)
            if (index !== -1) {
                temp_menus.splice(index, 1);
                this.setState({
                    menus: temp_menus,
                    topCount:  temp_menus[key]['order_number'] - 1
                });
            }
        }
    };

    handleChange = (e) => {
        this.setState({
            changeComponents: true,
            [e.target.name]: e.target.value
        });
    };

    handleChangeColor = index => (e) => {
        const colors = [...this.state.colors];
        colors[index]['color_code'] = e.color;
        this.setState({
            changeComponents: true,
            colors : colors
        });
    };

    handleAddColor = () => {
        let colors = this.state.colors;
        let colorLength = this.state.colors.length + 1;
        let newColors = [
            ...colors,
            { color_number : (colorLength), color_code: '#ffffff'}
        ];
        this.setState({ colors : newColors, changeComponents: true});
    };

    handleDeleteColor = () => {
        let colorLength = this.state.colors.length;
        let colors = this.state.colors.filter( (color, index) => {
            return (colorLength - 1) !== index;
        });
        this.setState({
            colors: colors,
            changeComponents: true,
        });
    };

    onCheckboxChange = (checkboxes) => {
        let deSelectAll = '';
        checkboxes.map((checkbox) => {
            {
                if (checkbox.checked === true) {
                    deSelectAll = checkbox.checked = false
                }
            }
            return deSelectAll;
        });
    }

    selectAllMenu = () => {

        const filteredContactMenu = this.state.menus.filter(menus => menus.menu_code === "LVL1" && menus.alias === "Contact");

        let menus = '';
        this.state.menus.map((menus) => {
            {
                if (menus.is_selected === true && menus.menu_code === "LVL1") {
                    menus = menus.is_selected = false
                }
            }
            return menus;
        });

        this.setState({
            count: 0,
            menu_list: this.state.menu_list.filter(
                menus => menus.menu_code !== "LVL1",
            ),
            selectedMenuClass: false,
            isEmail: false,
            myweb_contact_email: filteredContactMenu.length > 0 ? null : this.state.myweb_contact_email,
            changeComponents: true,
            menuPostData: this.state.menuPostData.filter(
                menus => menus.menu_code !== "LVL1",
            )
        });

    };

    selectAllTopMenu = () => {

        const filteredContactMenu = this.state.menus.filter(menus => menus.menu_code === "TOP" && menus.alias === "Contact");

        let menus = '';
        this.state.menus.map((menus) => {
            {
                if (menus.is_selected === true && menus.menu_code === "TOP") {
                    menus = menus.is_selected = false
                }
            }
            return menus;
        });

        this.setState({
            topCount: 0,
            menu_list: this.state.menu_list.filter(
                menus => menus.menu_code !== "TOP"
            ),
            selectedTopMenuClass: false,
            isEmail: false,
            changeComponents: true,
            myweb_contact_email: filteredContactMenu.length > 0 ? null : this.state.myweb_contact_email,
            menuPostData: this.state.menuPostData.filter(
                menus => menus.menu_code !== "TOP",
            )
        });

    };

    handleTabChange = ( event, newValue ) => {
        this.setState( {tabValue : newValue});
    };

    handleTabMenuChange = ( event, newValue ) => {
        this.setState( {tabMenuValue : newValue});
    };

    changeComponentsWithTextCounter = () => {
        this.setState({
            changeComponents: true
        });
    }

    handleChangeMail = (e) => {
        this.setState({
            myweb_contact_email: e.target.value
        });
    }

    emailRef = React.createRef();
    handleBlurMail = (e) => {
        this.emailRef.current.validate(e.target.value);
    }

    render() {
        const {changeComponents, coverPhotoRequired, openModal, myweb_contact_email, isEmail,
            kvkk_mersis_no, kvkk_data_controller, kvkk_contact_person, kvkk_address, kvkk_email,
            kvkk_phone, kvkk_tax_number, kvkk_tax_office, kvkk_web_site, kvkk_contact_email, kvkk_contact_address, property_country_code, weather_active} = this.state;
        const colorNumbers = { 0 : 'one', 1 : 'two', 2 : 'three'};

        let textfields =  this.state.aboutUs.map((title, index)=>{
            return (
                <TabPanel value={this.state.tabValue} index={index} className="w-100">
                    <TextCounter
                        about = { this.props.getEditContentWeb.editWebData.property_web_about_us }
                        index={index}
                        title = { title}
                        changeComponents = { changeComponents }
                        changeComponentsWithTextCounter = { this.changeComponentsWithTextCounter }
                    />
                </TabPanel>
            );
        })

        const descriptionTabs = this.state.aboutUs.map((title,value)=>{
            const flag = <Grid container className="brand-desc-flag" spacing={1}> <Grid item sm={3}><img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} /> </Grid> <Grid item sm={2}> {title.language_code}</Grid></Grid>
            return (
                <Tab label={ flag } {...a11yProps(value)} />
            );
        })

        return (
            <NoSsr>
                <div>
                    <Link to={WEB_POPUP_LIST} className="filter-action-button float-right mb-24 ml-22">
                            <i className="fas fa-plus-circle" /> { t('btn-popup-list') }
                    </Link>

                    <Link to={WEB_BLOG_LIST} className="filter-action-button float-right mb-24">
                        <i className="fas fa-plus-circle" /> { t('btn-blog-list') }
                    </Link>
                </div>
                
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-images room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={t('enw-web-edit-select_web_photos')} />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="offer-photos-detail room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-photos-title') } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myw-edit_photos-desc') }
                            />
                        </ColAtm>
                        {this.photoList()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-image room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-select_cover_photo-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="offer-photos-detail room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('btn-selected_cover_photo') } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myw-edit_cover_photos-desc') }
                            />
                        </ColAtm>
                        {this.sliderPhotoList()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-bed room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-select_rooms-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-myweb-edit-room-title') } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myw-edit-room-desc') }
                            />
                        </ColAtm>
                        {this.roomList()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-globe-europe room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-select_languages-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-myweb-edit-languages-title') } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myw-edit-languages-desc') }
                            />
                        </ColAtm>
                        {this.languageList()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-palette room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-select_colors-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-input-title-colors') } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myweb-edit-colors-desc') }
                            />
                        </ColAtm>
                        <ColAtm md={5}>
                            <ColAtm className="d-block mb-55">
                                <ColAtm className="color-select">
                                    {this.state.colors.map((color, index) => ( <ColAtm xs={3} sm={2} className="d-block">
                                        <LabelAtm component="h3" text={ t('enw-input-color_'+ colorNumbers[index] ) } />
                                        <ColorPicker
                                            animation="slide-up"
                                            defaultColor=""
                                            color={color.color_code}
                                            onChange={this.handleChangeColor(index)}
                                        />
                                    </ColAtm>))}
                                    {this.state.colors.length > 2 && (<>
                                        <ColAtm xs={3} md={1} className="form-group" style={{ paddingTop:27, paddingBottom:5, paddingLeft: 5, marginBottom:8}}>
                                            <ButtonAtm className="delete-bed-button" fullwidth={true}
                                                       startIcon={<i className="fas fa-minus-circle" />}
                                                       onClick={this.handleDeleteColor}
                                            />
                                        </ColAtm>
                                        <ColAtm md={1}></ColAtm>
                                    </> ) }
                                    {this.state.colors.length < 3 ? ( <ColAtm xs={3} md={1} className="form-group" style={{ paddingTop:27, paddingBottom:5, paddingLeft: 5, marginBottom:8}}>
                                        <ButtonAtm className="new-bed-button"
                                                   startIcon={<i className="fas fa-plus-circle" />}
                                                   onClick={this.handleAddColor}
                                        />
                                    </ColAtm>) : ('')}
                                </ColAtm>
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-browser room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-select_menus-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-myweb-edit-menus-title')  } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myweb-edit-select_menus-desc') }
                            />
                        </ColAtm>
                        <AppBar position="static" style={{ 'color': 'black' }}>
                            <Tabs value={this.state.tabMenuValue} onChange={this.handleTabMenuChange} aria-label="room-rate-tab">
                                <Tab label={ t('enw-myweb-edit-menu-main_menu') } {...a11yProps(0)} />
                                <Tab label={ t('enw-myweb-edit-menu-top_menu') } {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.tabMenuValue} index={0} className="w-100">
                            {
                                this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.menus.length !== 0 ?
                                    <ColAtm className="recommended-box web-menu-list">
                                        <CheckboxGroup onChange={this.onCheckboxChange}>
                                            <ColAtm md={10} sm={8}></ColAtm>
                                            <ColAtm md={2} sm={4} className="select-all">
                                                <label className="select-all-chck">
                                                    <NoneCheckerCheckbox
                                                        onChange={ this.selectAllMenu }
                                                    />
                                                    <span className="select-all-web-menus">{ t('btn-select_all') }</span>
                                                </label>
                                            </ColAtm>
                                            {
                                                this.state.menus.map((menus, key) => (
                                                    <NoSsr>
                                                        {
                                                            (menus.is_selected && menus.alias === "Contact" && menus.menu_code === "LVL1") ?
                                                                <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                                                                    <BlockAtm className="offer-fact-border-box fact-border-box additional-web-contact-btn" onClick={this.handleClickOpenModal}>
                                                                        <label>
                                                                            <label>
                                                                                <Grid item xs={12} className="cancellation-info-col">
                                                                                    <ColAtm className="channel-logo">
                                                                                        <label>
                                                                                            <i className="fas fa-pen" />
                                                                                            <i className={"offer-fact-icon fact-icon " + menus.icon} />
                                                                                            {
                                                                                                isDefined(menus.is_selected && menus.order_number_mapping) &&
                                                                                                <Typography className={"menu-order-numbers"}>{menus.order_number_mapping}</Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </ColAtm>
                                                                                </Grid>
                                                                                <OverFlowText>{t(menus.language_key)}</OverFlowText>
                                                                            </label>
                                                                        </label>
                                                                    </BlockAtm>
                                                                    <Dialog
                                                                        open={openModal}
                                                                        onClose={this.handleCloseModal}
                                                                        aria-labelledby="responsive-dialog-title"
                                                                        className="installment-modal web-mail-modal"
                                                                    >
                                                                        <Dialog onClose={this.handleCloseModal} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={openModal}>
                                                                            <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={this.handleCloseModal}>
                                                                                 { t('enw-myweb-edit-additional_email-title') }
                                                                            </DialogTitle>
                                                                            <DialogContent>
                                                                                <Fragment>
                                                                                    <Grid item sm={12}>
                                                                                        <ColAtm className="mb-16">
                                                                                            <LabelAtm variant="h6" component="h3" text={ t('enw-myweb-edit-add_contact_form_question') }  />
                                                                                        </ColAtm>
                                                                                    </Grid>
                                                                                    <Grid container spacing={3} >
                                                                                        <Grid item>
                                                                                            <input type="radio"
                                                                                                   className="d-none"
                                                                                                   name={"paymentValue"}
                                                                                                   id={"paymentValue2"}
                                                                                                   value={"false"}
                                                                                                   defaultChecked={ !isEmail }
                                                                                                   onClick={(e) => { this.setState({isEmail: false, myweb_contact_email: null}) }}
                                                                                            />
                                                                                            <label className="show-channel-detail"
                                                                                                   htmlFor={"paymentValue2"}>
                                                                                                { t('enw-input-checkbox-no') }
                                                                                            </label>
                                                                                        </Grid>
                                                                                        <Grid item >
                                                                                            <input type="radio"
                                                                                                   className="d-none"
                                                                                                   name={"paymentValue"}
                                                                                                   id={"paymentValue"}
                                                                                                   value={"true"}
                                                                                                   onClick={(e) => { this.setState({isEmail: true}) }}
                                                                                                   defaultChecked={ isEmail }
                                                                                            />
                                                                                            <label className="show-channel-detail" htmlFor={"paymentValue"}>
                                                                                                { t('enw-input-checkbox-yes') }
                                                                                            </label>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <ValidatorForm onSubmit={this.handleCloseModal} instantValidate={false}>
                                                                                        <Grid container className="payment-create-dialog pb-21" spacing={3}>
                                                                                        {
                                                                                            isEmail &&
                                                                                            <Grid item xs={12} className="mt-22">
                                                                                                    <TextValidator
                                                                                                        ref={this.emailRef}
                                                                                                        type="text"
                                                                                                        fullWidth
                                                                                                        value={myweb_contact_email}
                                                                                                        placeholder={ t('enw-myweb-edit-additional_email-title') }
                                                                                                        label={ t('enw-myweb-edit-additional_email-title')}
                                                                                                        onChange={(e) => this.handleChangeMail(e)}
                                                                                                        onBlur={(e) => this.handleBlurMail(e)}
                                                                                                        autoComplete="off"
                                                                                                        variant="outlined"
                                                                                                        validators={['required', 'isEmail']}
                                                                                                        errorMessages={[t("enw-notification_this_is_required"), t('enw-notification_invalid_email') ]}
                                                                                                    />
                                                                                                </Grid>
                                                                                        }
                                                                                        </Grid>
                                                                                        <ButtonAtm
                                                                                            className="soft-red-status-button mt-14"
                                                                                            outline
                                                                                            type="submit"
                                                                                            fullwidth={true}
                                                                                            buttontext={ t('btn-ok') }
                                                                                        />
                                                                                    </ValidatorForm>
                                                                                </Fragment>
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    </Dialog>
                                                                </ColAtm>
                                                                :
                                                                (menus.menu_type !== 'PLC') &&
                                                                <MultiFactCheckBox
                                                                    checkboxId={"menu-"+menus.id+"-type-"+menus.menu_type+"-code-"+menus.menu_code}
                                                                    className="fact-box"
                                                                    blockClass="offer-fact-border-box fact-border-box"
                                                                    labelIconClass="fact-icon-label"
                                                                    colClassname={`offer-fact-col ${"selected-web-menu-" + menus.is_selected}`}
                                                                    iconClass={`offer-fact-icon fact-icon ${menus.icon}`}
                                                                    value={menus.id}
                                                                    md={2}
                                                                    menuCount={menus.is_selected && menus.order_number_mapping}
                                                                    menuCountClass="menu-order-numbers"
                                                                    labelClassName="pos-relative"
                                                                    key={"menu-"+menus.id}
                                                                    text={t(menus.language_key)}
                                                                    labelClass="fact-name"
                                                                    htmlFor={"menu-"+menus.id+"-type-"+menus.menu_type+"-code-"+menus.menu_code}
                                                                    checked={ (menus.menu_code === "LVL1" && menus.is_selected) ? true : false}
                                                                    onChange={(e) => { this.onClickMenu(e, key, menus.alias === "Contact" && "openModal")}}
                                                                />
                                                               
                                                        }
                                                       
                                                    </NoSsr>
                                                ))
                                            }
                                            {
                                                this.state.menus.map((menuStatus, key) => (
                                                    (menuStatus.status === 1) && (menuStatus.menu_type === 'PLC') &&
                                                    <MultiFactCheckBox
                                                        checkboxId={"menu-"+menuStatus.id+"-type-"+menuStatus.menu_type+"-code-"+menuStatus.menu_code}
                                                        className="fact-box"
                                                        blockClass="offer-fact-border-box fact-border-box"
                                                        labelIconClass="fact-icon-label"
                                                        colClassname={`offer-fact-col ${"selected-web-menu-" + menuStatus.is_selected}`}
                                                        iconClass={`offer-fact-icon fact-icon ${menuStatus.icon}`}
                                                        value={menuStatus.id}
                                                        md={2}
                                                        menuCount={menuStatus.is_selected && menuStatus.order_number_mapping}
                                                        menuCountClass="menu-order-numbers"
                                                        labelClassName="pos-relative"
                                                        key={"menu-"+menuStatus.id}
                                                        text={t(menuStatus.language_key)}
                                                        labelClass="fact-name"
                                                        htmlFor={"menu-"+menuStatus.id+"-type-"+menuStatus.menu_type+"-code-"+menuStatus.menu_code}
                                                        checked={ (menuStatus.menu_code === "LVL1" && menuStatus.is_selected) ? true : false}
                                                        onChange={(e) => { this.onClickMenu(e, key, menuStatus.alias === "Contact" && "openModal")}}
                                                    />
                                                ))
                                            }
                                            <MultiFactCheckBox
                                                checkboxId={"menu-weather"}
                                                className="fact-box"
                                                blockClass="offer-fact-border-box fact-border-box"
                                                labelIconClass="fact-icon-label"
                                                colClassname={`offer-fact-col ${"selected-web-menu-" + "weather"}`}
                                                iconClass={`offer-fact-icon fact-icon fas fa-sun-cloud`}
                                                value={this.state.weather_active}
                                                md={2}
                                                // menuCount={menuStatus.is_selected && menuStatus.order_number_mapping}
                                                menuCountClass="menu-order-numbers"
                                                labelClassName="pos-relative"
                                                text={t('enw-menu-weater_widget-title')}
                                                labelClass="fact-name"
                                                htmlFor={"menu-weather"}
                                                checked={this.state.weather_active === 1 ? true : false}
                                                onClick={this.onClickWeatherCheckbox}
                                            />
                                        </CheckboxGroup>
                                    </ColAtm>
                                    :
                                    ""
                            }
                        </TabPanel>
                        <TabPanel value={this.state.tabMenuValue} index={1} className="w-100">
                            {
                                this.props.getEditContentWeb.editWebData.length !== 0 && this.props.getEditContentWeb.editWebData.menus.length !== 0 ?
                                    <ColAtm className="recommended-box web-menu-list">
                                        <CheckboxGroup onChange={this.onCheckboxChange}>
                                            <ColAtm md={10} sm={8}></ColAtm>
                                            <ColAtm md={2} sm={4} className="select-all">
                                                <label className="select-all-chck">
                                                    <NoneCheckerCheckbox
                                                        onChange={ this.selectAllTopMenu }
                                                    />
                                                    <span className="select-all-web-menus">{ t('btn-select_all') }</span>
                                                </label>
                                            </ColAtm>
                                            {
                                                this.state.menus.map((menus, key) => (
                                                    <NoSsr>
                                                        {
                                                            (menus.is_selected && menus.alias === "Contact" && menus.menu_code === "TOP") ?
                                                                <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6} >
                                                                    <BlockAtm className="offer-fact-border-box fact-border-box additional-web-contact-btn" onClick={this.handleClickOpenModal}>
                                                                        <label>
                                                                            <label>
                                                                                <Grid item xs={12} className="cancellation-info-col">
                                                                                    <ColAtm className="channel-logo payment-create-btn">
                                                                                        <label>
                                                                                            <i className="fas fa-pen" />
                                                                                            <i className={"offer-fact-icon fact-icon " + menus.icon} />
                                                                                            {
                                                                                                isDefined(menus.is_selected && menus.order_number_mapping) &&
                                                                                                <Typography className={"menu-order-numbers"}>{menus.order_number_mapping}</Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </ColAtm>
                                                                                </Grid>
                                                                                <OverFlowText>{t(menus.language_key)}</OverFlowText>
                                                                            </label>
                                                                        </label>
                                                                    </BlockAtm>
                                                                    <Dialog
                                                                        open={openModal}
                                                                        onClose={this.handleCloseModal}
                                                                        aria-labelledby="responsive-dialog-title"
                                                                        className="installment-modal web-mail-modal"
                                                                    >
                                                                        <Dialog onClose={this.handleCloseModal} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={openModal}>
                                                                            <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={this.handleCloseModal}>
                                                                                { t('enw-myweb-edit-additional_email-title') }
                                                                            </DialogTitle>
                                                                            <DialogContent>
                                                                                <Fragment>
                                                                                    <Grid item sm={12}>
                                                                                        <ColAtm className="mb-16">
                                                                                            <LabelAtm variant="h6" component="h3" text={ t('enw-myweb-edit-add_contact_form_question') }  />
                                                                                        </ColAtm>
                                                                                    </Grid>
                                                                                    <Grid container spacing={3} >
                                                                                        <Grid item>
                                                                                            <input type="radio"
                                                                                                   className="d-none"
                                                                                                   name={"paymentValue"}
                                                                                                   id={"paymentValue2"}
                                                                                                   value={"false"}
                                                                                                   defaultChecked={ !isEmail }
                                                                                                   onClick={(e) => { this.setState({isEmail: false, myweb_contact_email: null}) }}
                                                                                            />
                                                                                            <label className="show-channel-detail"
                                                                                                   htmlFor={"paymentValue2"}>
                                                                                                { t('enw-input-checkbox-no') }
                                                                                            </label>
                                                                                        </Grid>
                                                                                        <Grid item >
                                                                                            <input type="radio"
                                                                                                   className="d-none"
                                                                                                   name={"paymentValue"}
                                                                                                   id={"paymentValue"}
                                                                                                   value={"true"}
                                                                                                   onClick={(e) => { this.setState({isEmail: true}) }}
                                                                                                   defaultChecked={ isEmail }
                                                                                            />
                                                                                            <label className="show-channel-detail" htmlFor={"paymentValue"}>
                                                                                                { t('enw-input-checkbox-yes') }
                                                                                            </label>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <ValidatorForm onSubmit={this.handleCloseModal} instantValidate={false}>
                                                                                        <Grid container className="payment-create-dialog pb-21" spacing={3}>
                                                                                            {
                                                                                                isEmail &&
                                                                                                <Grid item xs={12} className="mt-22">
                                                                                                    <TextValidator
                                                                                                        ref={this.emailRef}
                                                                                                        type="text"
                                                                                                        fullWidth
                                                                                                        value={myweb_contact_email}
                                                                                                        placeholder={ t('enw-myweb-edit-additional_email-title') }
                                                                                                        label={ t('enw-myweb-edit-additional_email-title')}
                                                                                                        onChange={(e) => this.handleChangeMail(e)}
                                                                                                        onBlur={(e) => this.handleBlurMail(e)}
                                                                                                        autoComplete="off"
                                                                                                        variant="outlined"
                                                                                                        validators={['required', 'isEmail']}
                                                                                                        errorMessages={[t("enw-notification_this_is_required"), t('enw-notification_invalid_email') ]}
                                                                                                    />
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                        <ButtonAtm
                                                                                            className="soft-red-status-button mt-14"
                                                                                            outline
                                                                                            type="submit"
                                                                                            fullwidth={true}
                                                                                            buttontext={ t('btn-ok') }
                                                                                        />
                                                                                    </ValidatorForm>
                                                                                </Fragment>
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    </Dialog>
                                                                </ColAtm>
                                                                :
                                                                (menus.menu_type !== 'PLC') &&
                                                                <MultiFactCheckBox
                                                                    checkboxId={"menu-"+menus.id+"-type-"+menus.menu_type+"-code-"+menus.menu_code}
                                                                    className="fact-box"
                                                                    blockClass="offer-fact-border-box fact-border-box"
                                                                    labelIconClass="fact-icon-label"
                                                                    colClassname={`offer-fact-col ${"selected-web-menu-" + menus.is_selected}`}
                                                                    iconClass={`offer-fact-icon fact-icon ${menus.icon}`}
                                                                    value={menus.id}
                                                                    md={2}
                                                                    menuCount={menus.is_selected && menus.order_number_mapping}
                                                                    menuCountClass="menu-order-numbers"
                                                                    labelClassName="pos-relative"
                                                                    key={"menu-"+menus.id}
                                                                    text={t(menus.language_key)}
                                                                    labelClass="fact-name"
                                                                    htmlFor={"menu-"+menus.id+"-type-"+menus.menu_type+"-code-"+menus.menu_code}
                                                                    checked={(menus.menu_code === "TOP" &&menus.is_selected) ? true : false}
                                                                    onChange={(e) => { this.onClickTopMenu(e, key, menus.alias === "Contact" && "openModal")}}
                                                                />
                                                        }
                                                    </NoSsr>
                                                ))
                                            }
                                            {
                                                this.state.menus.map((menuStatus, key) => (
                                                    (menuStatus.status === 1) && (menuStatus.menu_type === 'PLC') &&
                                                    <MultiFactCheckBox
                                                        checkboxId={"menu-"+menuStatus.id+"-type-"+menuStatus.menu_type+"-code-"+menuStatus.menu_code}
                                                        className="fact-box"
                                                        blockClass="offer-fact-border-box fact-border-box"
                                                        labelIconClass="fact-icon-label"
                                                        colClassname={`offer-fact-col ${"selected-web-menu-" + menuStatus.is_selected}`}
                                                        iconClass={`offer-fact-icon fact-icon ${menuStatus.icon}`}
                                                        value={menuStatus.id}
                                                        md={2}
                                                        menuCount={menuStatus.is_selected && menuStatus.order_number_mapping}
                                                        menuCountClass="menu-order-numbers"
                                                        labelClassName="pos-relative"
                                                        key={"menu-"+menuStatus.id}
                                                        text={t(menuStatus.language_key)}
                                                        labelClass="fact-name"
                                                        htmlFor={"menu-"+menuStatus.id+"-type-"+menuStatus.menu_type+"-code-"+menuStatus.menu_code}
                                                        checked={(menuStatus.menu_code === "TOP" &&menuStatus.is_selected) ? true : false}
                                                        onChange={(e) => { this.onClickTopMenu(e, key, menuStatus.alias === "Contact" && "openModal")}}
                                                    />
                                                ))
                                            }
                                            <MultiFactCheckBox
                                                checkboxId={"menu-weather"}
                                                className="fact-box"
                                                blockClass="offer-fact-border-box fact-border-box"
                                                labelIconClass="fact-icon-label"
                                                colClassname={`offer-fact-col ${"selected-web-menu-" + "weather"}`}
                                                iconClass={`offer-fact-icon fact-icon fas fa-sun-cloud`}
                                                value={this.state.weather_active}
                                                md={2}
                                                // menuCount={menuStatus.is_selected && menuStatus.order_number_mapping}
                                                menuCountClass="menu-order-numbers"
                                                labelClassName="pos-relative"
                                                text={t('enw-menu-weater_widget-title')}
                                                labelClass="fact-name"
                                                htmlFor={"menu-weather"}
                                                checked={this.state.weather_active === 1 ? true : false}
                                                onClick={this.onClickWeatherCheckbox}
                                            />
                                        </CheckboxGroup>
                                    </ColAtm>
                                    :
                                    ""
                            }
                        </TabPanel>
                        <ColAtm>
                            <LabelAtm labelClass="preview-title" text={ t('btn-preview')} />
                        </ColAtm>
                        <ColAtm className="top-menu-preview">
                            {
                                this.state.menu_list.map((menus) => (
                                    menus.menu_code === "TOP" && menus.menu_type !== 'PLC' &&
                                    <p className={`${"menu-order-" + menus.order_number_mapping}`}>{t(menus.language_key)}</p>
                                ))
                            }
                            {
                                this.state.menu_list.map((menuStatus) => (
                                    menuStatus.menu_code === "TOP" && menuStatus.status === 1 && menuStatus.menu_type === 'PLC' &&
                                    <p className={`${"menu-order-" + menuStatus.order_number_mapping}`}>{t(menuStatus.language_key)}</p>
                                ))
                            }
                        </ColAtm>
                        <ColAtm className="menu-preview">
                            {
                                this.state.menu_list.map((menus) => (
                                    menus.menu_code === "LVL1" && menus.menu_type !== 'PLC' &&
                                    <p className={`${"menu-order-" + menus.order_number_mapping}`}>{t(menus.language_key)}</p>
                                ))
                            }
                            {
                                this.state.menu_list.map((menuStatus) => (
                                    menuStatus.menu_code === "LVL1" && menuStatus.status === 1 && menuStatus.menu_type === 'PLC' &&
                                    <p className={`${"menu-order-" + menuStatus.order_number_mapping}`}>{t(menuStatus.language_key)}</p>
                                ))
                            }
                        </ColAtm>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="accordion-room-table">
                    <ExpansionPanelSummary
                        expandIcon={<i className="fas fa-caret-down" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="showPhotos">
                        <ColAtm>
                            <ColAtm md={10} xl={11}>
                                <i className="fas fa-file-alt room-rate-bed" />
                                <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-about_us-title') } />
                            </ColAtm>
                        </ColAtm>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">

                        <ColAtm xs={12} className="d-block mb-20">
                            <LabelAtm variant="h2" text={ t('enw-myweb-edit-about_us-title')  } labelClass="mb-20" />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-myweb-edit-about_us-desc')  }
                            />
                            <AppBar position="static" style={{ 'color': 'black' }}>
                                <Tabs value={this.state.tabValue}
                                      onChange={this.handleTabChange}
                                      aria-label="room-rate-tab"
                                      variant="scrollable"
                                      scrollButtons="on"
                                >
                                    {descriptionTabs}
                                </Tabs>
                            </AppBar>
                            {textfields}
                        </ColAtm>

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {
                    property_country_code === "TR" &&
                    <ExpansionPanel className="accordion-room-table">
                        <ExpansionPanelSummary
                            expandIcon={<i className="fas fa-caret-down" />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="showPhotos">
                            <ColAtm>
                                <ColAtm md={10} xl={11}>
                                    <i className="fas fa-file-signature room-rate-bed" />
                                    <LabelAtm labelClass="room-rate-list-title" text={ t('enw-myweb-edit-kvkk-title') } />
                                </ColAtm>
                            </ColAtm>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item bg-white-color">

                            <ColAtm xs={12} className="d-block mb-20">
                                <LabelAtm variant="h2" text={ t('enw-myweb-edit-kvkk-title')  } labelClass="mb-20" />
                                <LabelAtm variant="p" labelClass="f-w-500"
                                          text={ t('enw-myweb-edit-kvkk-desc')  }
                                />
                                <Grid container spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_data_controller"
                                            label={t('enw-myweb-edit-kvkk-data_controller')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_data_controller}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_contact_person"
                                            label={t('enw-myweb-edit-kvkk-contact-person')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_contact_person}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_address"
                                            label={t('enw-myweb-edit-kvkk_address')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_address}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_phone"
                                            label={t('enw-myweb-edit-kvkk_phone')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_phone}
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_tax_number"
                                            label={t('enw-myweb-edit-kvkk_tax-number')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_tax_number}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_tax_office"
                                            label={t('enw-myweb-edit-kvkk_tax-office')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_tax_office}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_mersis_no"
                                            label={t('enw-myweb-edit-kvkk-mernis-no')}
                                            type="text"
                                            variant="outlined"
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                            value={kvkk_mersis_no}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_email"
                                            label={t('enw-myweb-edit-kvkk_email')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_email}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_web_site"
                                            label={t('enw-myweb-edit-kvkk_web-site')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_web_site}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mobile-no-spacing">
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_contact_email"
                                            label={t('enw-myweb-edit-kvkk_request-email')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_contact_email}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputAtm
                                            name="kvkk_contact_address"
                                            label={t('enw-myweb-edit-kvkk_request-address')}
                                            type="text"
                                            variant="outlined"
                                            value={kvkk_contact_address}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </ColAtm>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
                {
                    (changeComponents && !coverPhotoRequired) &&
                    <Alert severity="warning">{ t('enw-myweb-edit-cover_photo-warning') } </Alert>
                }
                <ColAtm sm={10} md={6} lg={4} spacing={3} className="m-auto">
                    <ColAtm sm={6} className="m-auto pt-0">
                        <ButtonAtm
                            type="submit"
                            className="site-preview-btn"
                            onClick={(e) => this.onPreview(e) }
                            outline
                            fullwidth={true}
                            buttontext={t('btn-preview')}
                        />
                    </ColAtm>
                    <ColAtm sm={6} className="m-auto">
                        <ButtonAtm
                            type="submit"
                            className="red-button"
                            outline
                            onClick={(e) => this.onSubmit(e) }
                            fullwidth={true}
                            buttontext={t('btn-save')}
                            disabled={(changeComponents && coverPhotoRequired) ? false : true}
                        />
                        {
                            this.state.web_base &&
                                <ShowLiveWeb
                                    saveEditContentWeb={this.props.saveEditContentWeb}
                                    getEditContentWeb={this.props.getEditContentWeb}
                                    web_base={this.state.web_base}
                                    handleClickOpen = {this.handleClickOpen}
                                    handleClose = {this.handleClose}
                                    open ={this.state.open}
                                    show_success_modal ={this.state.show_success_modal}
                                />

                        }
                    </ColAtm>
                </ColAtm>
            </NoSsr>
        );
    }
};

export default compose(withTranslation('common')) (WebEditAccordions);
