import {inventoryConstants} from "../../../../constants/Network/inventory.constants";

const initialState = {
    fetching:false,
    getCpa : [],
    error : {},

    fetchingDaily:false,
    getCpaDaily : [],
    errorDaily : {},

    fetchingSyncCompetitor: false,
    syncCompetitor : [],
    errorSyncCompetitor : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryConstants.GET_CPA_COMPETITOR_PENDING:
            return {
                ...state,
                fetching: true,
                getCpa: [],
                error : {},
            };
        case inventoryConstants.GET_CPA_COMPETITOR_FULFILLED:
            return {
                ...state,
                fetching: false,
                getCpa : action.payload
            };
        case inventoryConstants.GET_CPA_COMPETITOR_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };

        case inventoryConstants.CPA_DAILY_PRICE_PENDING:
            return {
                ...state,
                fetchingDaily: true,
                getCpaDaily: [],
                errorDaily : {},
            };
        case inventoryConstants.CPA_DAILY_PRICE_FULFILLED:
            return {
                ...state,
                fetchingDaily: false,
                getCpaDaily : action.payload
            };
        case inventoryConstants.CPA_DAILY_PRICE_REJECTED:
            return {
                ...state,
                errorDaily: action.payload,
                fetchingDaily: false
            };

        case inventoryConstants.GET_CPA_COMPETITOR_CLEAR_DATA:
            return {
                ...state,
                fetchingDaily: true,
                getCpaDaily: false,
                errorDaily : {},
            };
        case inventoryConstants.SYNC_CPA_COMPETITOR_PENDING:
            return {
                ...state,
                fetchingSyncCompetitor: true,
                syncCompetitor: [],
                errorDaily : {},
            };
        case inventoryConstants.SYNC_CPA_COMPETITOR_FULFILLED:
            return {
                ...state,
                fetchingSyncCompetitor: false,
                syncCompetitor : action.payload
            };
        case inventoryConstants.SYNC_CPA_COMPETITOR_REJECTED:
            return {
                ...state,
                errorSyncCompetitor: action.payload,
                fetchingSyncCompetitor: false
            };
        case inventoryConstants.SYNC_CPA_COMPETITOR_CLEAR_DATA:
            return {
                ...state,
                fetchingSyncCompetitor: false,
                syncCompetitor : false,
                errorSyncCompetitor : {},
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
