export const BASE_API = (process.env.NODE_ENV === 'production')
    ? 'https://api.extranetwork.com/app/v1'
    : 'http://api.extranetwork.local/app/v1';

/*MYWEB için değiştirilmesi gereken url*/
export const BASE_LIVE_WEB = (process.env.NODE_ENV === 'production')
    ? 'https://myweb.extranetwork.com/live/'
    : 'http://myweb.extranetwork.local/live/';


/*MYWEB preview için değiştirilmesi gereken url*/
export const BASE_WEB = (process.env.NODE_ENV === 'production')
    ? 'https://myweb.extranetwork.com/preview/'
    : 'http://myweb.extranetwork.local/preview/';


//export const BASE_API = "http://api.extranetwork.local/app/v1";

/*User*/
export const LOGIN_URL = BASE_API+"/auth/login";
export const LOGOUT_URL = BASE_API+"/logout";
export const REGISTER = BASE_API+"/user/register-user-with-property";
export const REFRESH_TOKEN = BASE_API+"/auth/refresh-token";
export const GET_PROFILE  = BASE_API+"/user/profile/get";
export const UPDATE_PROFILE  = BASE_API+"/user/profile/update";
export const UPDATE_PASSWORD  = BASE_API+"/user/change-password";
export const FORGOT_PASSWORD  = BASE_API+"/user/forgot-password";
export const RESET_PASSWORD  = BASE_API+"/user/reset-password";

/*Languages*/
export const GET_LANGUAGES = BASE_API+"/languages/get/app";
export const GET_ALL_LANGUAGES = BASE_API+"/languages/get/all";

/*Property*/
export const GET_PROPERTY  = BASE_API+"/property/info/get";
export const UPDATE_PROPERTY  = BASE_API+"/property/info/update";
export const LIST_PROPERTY  = BASE_API+"/property/info/list";
export const CREATE_PROPERTY = BASE_API+"/property/create";
export const PROPERTY_PRODUCTS = BASE_API+"/property/property-products/get";
export const PROPERTY_CONTENT_CODE = BASE_API+"/property/update/content-code";

/*Dashboard*/
export const GET_PROPERTY_DASHBOARD  = BASE_API+"/property/dashboard";

/*Contact*/
export const UPDATE_PROPERTY_CONTACT = BASE_API+"/property/contact/update";
export const GET_PROPERTY_CONTACT = BASE_API+"/property/contact/list";

/*Content*/
export const GET_PROPERTY_EXECUTIVE  = BASE_API+"/property/executive/list";
export const UPDATE_PROPERTY_EXECUTIVE  = BASE_API+"/property/executive/update";
export const DELETE_PROPERTY_EXECUTIVE  = BASE_API+"/property/executive/delete";
export const ADD_PROPERTY_EXECUTIVE  = BASE_API+"/property/executive/add";
export const GET_CONTENT_PDF  = BASE_API+"/property/download-pdf";
export const DOWNLOAD_CONTENT_PHOTOS = BASE_API+"/property/download-property-photos";

export const GET_FACT_lIST = BASE_API+"/property/fact/get-subcategory-facts";

/*Executive*/
export const UPDATE_CONTENT = BASE_API+"/property/content/update";
export const LIST_CONTENT = BASE_API+"/property/content/list";

/*Additional Info*/
export const UPDATE_ADDITIONAL_INFO = BASE_API+"/property/additional-info/update";
export const LIST_ADDITIONAL_INFO = BASE_API+"/property/additional-info/list";
export const INSERT_ADDITIONAL_INFO = BASE_API+"/property/additional-info/insert";

/*Facts*/
export const UPDATE_FACT_MAPPING  = BASE_API+"/property/fact-mapping/update";
export const GET_FACTS  = BASE_API+"/property/fact/list";

/*Photo*/
export const PHOTO_UPLOAD  = BASE_API+"/property/photo/upload";
export const PHOTO_LIST  = BASE_API+"/property/photo/list";
export const PHOTO_CATEGORY_LIST  = BASE_API+"/property/photo/category/list";
export const PHOTO_SET_ORDER  = BASE_API+"/property/photo/order";
export const SET_PHOTO_CATEGORY  = BASE_API+"/property/photo/category/set";
export const SET_DEFAULT_PHOTOS  = BASE_API+"/property/photo/set-default";
export const PUBLISH_PHOTOS  = BASE_API+"/property/photo/publish";
export const DELETE_PHOTOS  = BASE_API+"/property/photo/delete";

/*Config*/
export const CONFIG_GET = BASE_API+"/property/config/list";
export const CONFIG_UPDATE = BASE_API+"/property/config/update";

/*Other Services*/
export const PROPERTY_TYPES = BASE_API+"/property/type/list";
export const CHAIN_LIST = BASE_API+"/property/chain/list";
export const DESTINATION_SEARCH = BASE_API+"/destination/search";
export const LANGUAGES = BASE_API+"/languages";
export const USER_MENU = BASE_API+"/user-menu";

/* Property Room and Rate List */
export const PROPERTY_ROOM_RATE_LIST      = BASE_API+"/property/rooms-with-rate-channel/get";
export const PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE    = BASE_API+"/property/rooms-with-rate-channel/get";
export const PROPERTY_ROOM_RATE_LIST_BEDS = BASE_API+"/property/room/get";
export const PROPERTY_ROOM_TYPE           = BASE_API+"/property/property-room-types";
export const PROPERTY_ROOM_RATE           = BASE_API+"/property/room-rate/get";
export const PROPERTY_ROOM_AND_BED_ADD    = BASE_API+"/property/room/add-room-bed";
export const PROPERTY_ROOM_AND_BED_UPDATE = BASE_API+"/property/room-rate/update";
export const PROPERTY_RATE_PLAN_ADD       = BASE_API+"/property/room-rate/add-with-inclusion";
export const PROPERTY_FACT_SEARCH_LIST    = BASE_API+"/property/fact/search-property-facts";
export const PROPERTY_ASSIGN_RATE         = BASE_API+"/property/room-rate-mapping/add-with-setup";
export const GET_ACCOMMODATION_TYPES      = BASE_API+"/property/accommodation-types/get";
export const GET_EDIT_RATE                = BASE_API+"/property/room-rate/edit";
export const PROPERTY_EDIT_RATE_UPDATE    = BASE_API+"/property/room-rate/update";
export const CONNECT_RATE                 = BASE_API+"/property/room-rate/get-connected";
export const SYNC_RATE                    = BASE_API+"/property/room-rate/sync-connected";

/* Property Inventory*/
export const PROPERTY_INVENTORY_GET = BASE_API+"/property/rooms-inventory/get";
export const PROPERTY_INVENTORY_UPDATE_OR_CREATE = BASE_API+"/property/room-rate-mapping/rate-availability-update";
export const PROPERTY_BULK_UPDATE = BASE_API+"/property/room-rate-mapping/bulk-update";
export const GET_PROPERTY_BULK_UPDATE = BASE_API+"/property/bulk-update-params/get";
export const PROPERTY_POST_INVENTORY_LINK = BASE_API+"/property/room-rate-mapping/post-inventory-link";

/* Property Inventory Channel Group*/
export const PROPERTY_GROUP_INVENTORY_GET = BASE_API+"/property/group-inventory/get";


/* ROOM */
export const ROOM_TYPE_LIST = BASE_API+"/property/room-type/list";
export const BED_TYPE_LIST = BASE_API+"/property/room-bed-type/list";
export const ROOM_FACT_LIST = BASE_API+"/property/room-fact/list";
export const ROOM_FACT_UPDATE = BASE_API+"/property/room-fact-mapping/update";
export const ROOM_FACT_FEATURE_UPDATE = BASE_API+"/property/room-fact-mapping/is-feature/update";
export const ROOM_PHOTO_LIST = BASE_API+"/property/room-photo/list";
export const ROOM_PHOTO_UPDATE = BASE_API+"/property/room-photo-mapping/update";
export const ROOM_VIEW_TYPE = BASE_API+"/property/room-view-type/list";
export const GET_EDIT_ROOM = BASE_API+"/property/room/get-room-bed";
export const UPDATE_ROOM = BASE_API+"/property/room/update-room-bed";
export const GET_ROOM_SIZE_TYPES = BASE_API+"/property/room-size-types/get";
export const GET_ROOM_DESCRIPTION = BASE_API+"/languages/get-null-description";

/*NETWORK*/

export const NETWORK_DASHBOARD = BASE_API+"/property/pab-dashboard";
export const CHANNEL_LIST = BASE_API+"/property/channel/get";
export const CHANNEL_UPDATE = BASE_API+"/property/channel/update";
export const CHANNEL_ADD = BASE_API+"/property/channel/add";
export const CHANNEL_MAPPING_GET = BASE_API+"/property/property-channel-mapping/get";
export const CHANNEL_MAPPING_ADD = BASE_API+"/property/property-channel-mapping/update";
export const ROOM_RATE_CHANNEL_UPDATE = BASE_API+"/property/room-rate-channel-mapping/update";
export const CHANNEL_SETUP_GET = BASE_API+"/property/property-channel-setup/get";
export const CHANNEL_SETUP_ADD = BASE_API+"/property/property-channel-setup/add";
export const CHANNEL_ROOM_RATE_GET = BASE_API+"/property/channel-room-rate-mapping/get";
export const GET_CHANNEL_CANCELLATION = BASE_API+"/property/cancellation-policy/list";
export const GET_CHANNEL_CANCELLATION_LIST = BASE_API+"/property/room-rate-channel-cancellation-policy/list";
export const UPDATE_CHANNEL_CANCELLATION_POLICY = BASE_API+"/property/room-rate-channel-cancellation-policy/update";
export const UPDATE_CANCELLATION_POLICY = BASE_API+"/property/cancellation-policy/update";
export const CREATE_CHANNEL_CANCELLATION = BASE_API+"/property/cancellation-policy/create";
export const CHANNEL_PAYMENT_GET = BASE_API+"/property/channel/payment-setup/get";
export const CHANNEL_PAYMENT_ADD = BASE_API+"/property/channel/payment-setup/add";

export const NETWORK_CHANNEL_PRICING_GET = BASE_API+"/property/room-rate-channel-pricing-policy/list"
export const NETWORK_CHANNEL_PRICING_ADULT_CREATE = BASE_API+"/property/person-pricing-policy-adult/create";
export const NETWORK_CHANNEL_PRICING_ADULT_UPDATE = BASE_API+"/property/person-pricing-policy-adult/update";
export const NETWORK_CHANNEL_PRICING_ADULT_MAPPING_UPDATE = BASE_API+"/property/room-rate-channel-pricing-adult-policy/update";
export const NETWORK_CHANNEL_PRICING_CHILD_CREATE = BASE_API+"/property/person-pricing-policy-child/create";
export const NETWORK_CHANNEL_PRICING_CHILD_UPDATE = BASE_API+"/property/person-pricing-policy-child/update";
export const NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE = BASE_API+"/property/room-rate-channel-pricing-child-policy/update";
export const NETWORK_CHANNEL_GROUP_GET = BASE_API+"/property/channel-group/get";
export const NETWORK_CHANNEL_GROUP_ADD = BASE_API+"/property/channel-group/add";
export const NETWORK_CHANNEL_GROUP_UPDATE = BASE_API+"/property/channel-group/update";
export const NETWORK_CHANNEL_GROUP_CREATE_DATA = BASE_API+"/property/channel-group/create";
export const NETWORK_ADDONS_GET = BASE_API+"/property/addon/get";
export const NETWORK_ADDONS_SYNC = BASE_API+"/property/addon/sync";
export const NETWORK_COUPONS_GET = BASE_API+"/property/coupon/get";
export const NETWORK_COUPONS_SYNC = BASE_API+"/property/coupon/sync";

/*Channel Contact*/
export const NETWORK_CHANNEL_CONTACT_GET = BASE_API+"/property/channel-contact/get";
export const NETWORK_CHANNEL_CONTACT_CREATE = BASE_API+"/property/channel-contact/add";
export const NETWORK_CHANNEL_CONTACT_UPDATE = BASE_API+"/property/channel-contact/update";
export const NETWORK_CHANNEL_CONTACT_DELETE = BASE_API+"/property/channel-contact/delete";

/*Brand*/
export const GET_BRAND = BASE_API+"/property/brand/get";
export const UPDATE_BRAND = BASE_API+"/property/brand/update";
export const DELETE_BRAND_LOGO = BASE_API+"/property/brand/clear-logo";

/*Awards And Certificates*/
export const GET_AWARDS_CERTIFICATES = BASE_API+"/property/awards-certificates/list";
export const GET_AWARDS_CERTIFICATES_CATEGORIES = BASE_API+"/property/awards-certificates/categories";
export const UPDATE_AWARDS_CERTIFICATES = BASE_API+"/property/awards-certificates/update";
export const CREATE_AWARDS_CERTIFICATES = BASE_API+"/property/awards-certificates/create";
export const DELETE_AWARDS_CERTIFICATES_PHOTO = BASE_API+"/property/awards-certificates/delete-photo";
export const AWARDS_CERTIFICATES_UPDATE_STATUS = BASE_API+"/property/awards-certificates/update-status";

/* Offer */
export const CREATE_OFFER = BASE_API+"/property/offer/create";
export const GET_OFFER = BASE_API+"/property/offer/edit";
export const UPDATE_OFFER = BASE_API+"/property/offer/update";
export const STORE_OFFER = BASE_API+"/property/offer/store";
export const GET_OFFER_LIST = BASE_API+"/property/offer/list";
export const GET_OFFER_PRICE = BASE_API+"/property/offer/price/get";
export const STORE_OFFER_PRICE = BASE_API+"/property/offer/price/store";
export const GET_OFFER_LANDING = BASE_API+"/offer/publish";
export const GET_OFFER_DASHBOARD = BASE_API+"/property/offer/dashboard";
export const APPROVE_OFFER = BASE_API+"/offer/accept";
export const UPDATE_OFFER_STATUS = BASE_API+"/property/offer/status/update";
export const OFFER_ACCEPT_STATUS = BASE_API+"/property/offer/accept-status/update";

/* Web */
export const CREATE_WEB = BASE_API+"/property/web/create";
export const GET_WEB = BASE_API+"/property/web/list";
export const UPDATE_WEB = BASE_API+"/property/web/update";
export const GET_EDIT_CONTENT_WEB = BASE_API+"/property/web/get-edit-content";
export const UPDATE_EDIT_CONTENT_WEB = BASE_API+"/property/web/update-content";
export const PUBLISH_WEB = BASE_API+"/property/web/publish";

/* Reservation */
export const RESERVATION_LIST = BASE_API+"/property/booking/list";
export const RESERVATION_EXCEL = BASE_API+"/property/booking/list";
export const RESERVATION_DETAIL = BASE_API+"/property/booking/detail";
export const GET_TICKET = BASE_API+"/booking/ticket";
export const CREATE_TICKET = BASE_API+"/booking/ticket/create";
export const UPDATE_TICKET = BASE_API+"/property/booking/update";
export const CREATE_PAYMENT_CODE = BASE_API+"/property/booking/paymentData";
export const LIST_FILTER = BASE_API+"/property/booking/list-filter";
export const RESERVATION_UPDATE_MAIL = BASE_API+"/property/booking/sendEmail";

/*Payment*/
export const PAYMENT_MAPPING_LIST = BASE_API+"/property/payment-mapping/list";
export const PAYMENT_TYPE_LIST = BASE_API+"/property/payment-type/list";
export const PAYMENT_CREATE_MAPPING = BASE_API+"/property/payment-mapping/create";
export const PAYMENT_MAPPING_UPDATE = BASE_API+"/property/payment-mapping/update";
export const PAYMENT_MAPPING_UPDATE_STATUS = BASE_API+"/property/payment-mapping/update-status";
export const PAYMENT_MAPPING_SET_DEFAULT = BASE_API+"/property/payment-mapping/set-default";
export const PAYMENT_INSTALLMENTS_UPDATE = BASE_API+"/property/payment-installments/update";
export const PAYMENT_INSTALLMENTS_UPDATE_STATUS = BASE_API+"/property/payment-installments/update-status";
export const PAYMENT_TYPE_GET = BASE_API+"/property/payment-type/get";
export const PAYMENT_TRANSACTION_LIST = BASE_API+"/property/transaction/list";
export const PAYMENT_MANUAL_TRANSACTION_DETAIL_LIST = BASE_API+"/property/manual-payment/get";
export const GET_PAYMENT_DASHBOARD = BASE_API+"/property/payment/dashboard";
export const CREATE_MANUAL_PAYMENT = BASE_API+"/property/manual-payment/create";
export const GET_CREATE_MANUAL_PAYMENT_DATA = BASE_API+"/property/manual-payment/create-form";
export const UPDATE_MANUAL_PAYMENT = BASE_API+"/property/manual-payment/update";
export const GET_UPDATE_MANUAL_PAYMENT_DATA = BASE_API+"/property/manual-payment/edit-form";

/*Booking Engine*/
export const BOOKING_ENGINE_DASHBOARD = BASE_API+"/property/booking-engine/dashboard";

/* Places */
export const GET_PLACE_CATEGORIES  = BASE_API+"/property/place-categories/single-list";
export const CREATE_PLACE  = BASE_API+"/property/place/create";
export const GET_PLACE_LIST  = BASE_API+"/property/place/list";
export const GET_PLACE_PHOTO_LIST  = BASE_API+"/property/place-photo/list";
export const UPDATE_PLACE_PHOTO  = BASE_API+"/property/place-photo-mapping/update";
export const UPDATE_PLACE  = BASE_API+"/property/place/update";
export const PLACE_FACT_LIST = BASE_API+"/property/place-facilities/list";
export const PLACE_FACT_UPDATE = BASE_API+"/property/place-facilities/update";
export const PLACE_CATEGORY_FIELDS = BASE_API+"/property/place-category-fields/get";

/* Promotions */
export const PROMOTION_ROOM_RATE_LIST = BASE_API+"/property/room-rate-channel-promotion/list";
export const PROMOTION_LIST = BASE_API+"/property/property-promotion/list";
export const PROMOTION_CREATE = BASE_API+"/property/property-promotion/create";
export const PROMOTION_UPDATE = BASE_API+"/property/property-promotion/update";
export const PROMOTION_ROOM_RATE_UPDATE = BASE_API+"/property/room-rate-channel-promotion/update";
export const PROMOTION_TYPES_GET = BASE_API+"/property/promotion-type/list";
export const DELETE_PROMOTION = BASE_API+"/property/property-promotion/delete";

//Landing Page Contact Form
export const LANDING_CONTACT_FORM = BASE_API+"/contact-form";

export const CPA_DAILY_PRICE = BASE_API+"/cpa/property/competitor-daily-price";
export const GET_CPA_COMPETITOR = BASE_API+"/cpa/property/competitor/get";
export const SYNC_CPA_COMPETITOR = BASE_API+"/cpa/property/competitor/sync";
export const DOWNLOAD_CPA_EXCEL = BASE_API+"/cpa/property/competitor/excel";


// Quick Pricing
export const GET_QUICK_PRICING = BASE_API+"/cpa/property/quick-pricing/get";
export const UPDATE_QUICK_PRICING = BASE_API+"/cpa/property/quick-pricing/rate";
export const SYNC_QUICK_PRICING = BASE_API+"/cpa/property/quick-pricing/sync";

// Dashboard Plus
export const DASHBOARD_PLUS = BASE_API+"/property/dashboard-plus";
export const WEB_VISITOR = BASE_API+"/property/dashboard-plus/web-visitor";
export const GUEST_DEMOGRAPHIC = BASE_API+"/property/dashboard-plus/guest-demographic";
export const TOP_CHANNEL = BASE_API+"/property/dashboard-plus/top-channel";
export const CHANNEL_FORECAST = BASE_API+"/property/dashboard-plus/channel-forecast";


// BLOG
export const BLOG_LIST = BASE_API+"/property/web/content/get";
export const BLOG_CATEGORY_LIST = BASE_API+"/property/web/content-category/get";
export const BLOG_GET_CONTENT = BASE_API+"/property/web/content/get";
export const CREATE_BLOG = BASE_API+"/property/web/content/create";
export const UPDATE_BLOG = BASE_API+"/property/web/content/update";
export const DELETE_BLOG = BASE_API+"/property/web/content/delete";
export const CREATE_IMAGE = BASE_API+"/property/web/content/image";


// Popup
export const POPUP_LIST = BASE_API+"/property/web/popup/get";
export const POPUP_GET_CONTENT = BASE_API+"/property/web/popup/get";
export const CREATE_POPUP = BASE_API+"/property/web/popup/create";
export const UPDATE_POPUP = BASE_API+"/property/web/popup/update";
export const DELETE_POPUP = BASE_API+"/property/web/popup/delete";
export const CREATE_IMAGE_POPUP = BASE_API+"/property/web/popup/image";
