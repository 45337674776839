import React from 'react';
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";


export default function BookingChart ({forecast, channelForecast}) {
    const { t } = useTranslation();

    return (
       <Grid container className="d-block channel-forecast">
           <div className="forecast-header">
               { channelForecast.title }
           </div>
           <Grid className="d-flex channel-forecast-date mb-16">
               <div className="forecast-title" />
               {
                   channelForecast && channelForecast.period.map(data => (
                       <div className="d-flex">
                           {
                               (channelForecast.title).indexOf("Monthly") !== -1 ?
                                   data :
                                   <span className="forecast-daily-title">{data.split("-")[2]}</span>
                           }
                       </div>
                   ))
               }
               <div className="d-flex">
                   <span className="forecast-daily-title">{ t('web-offer-total') }</span>
               </div>
           </Grid>
           {
               forecast && forecast.map(data => (
                   <div className="d-flex forecast-item">
                       <span className="forecast-title">{data.title}</span>
                       <div className="channel-forecast-price">
                           {Object.values(data.data).map(price => (
                               <div>{price}</div>
                           ))}
                           <div className="fw-600">{data.total}</div>
                       </div>
                   </div>
               ))
           }

           <Grid className="d-flex channel-forecast-date mt-14">
               <span className="forecast-title">{ t('web-offer-total') }</span>
               {
                   channelForecast && Object.values(channelForecast.forecastTotal).map(data => (
                       <div className="d-flex">
                           {
                               (channelForecast.title).indexOf("Monthly") !== -1 ?
                                   <span className="forecast-monthly-title">{data}</span> :
                                   <span className="forecast-daily-title">{data}</span>
                           }
                       </div>
                   ))
               }
               <div className="d-flex">
                   <span className="forecast-daily-title"></span>
               </div>
           </Grid>
       </Grid>
    );
}