import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useTranslation} from "react-i18next";

const AllRoomTypes = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedRoomType, setSelectedRoomType] = React.useState(t('enw-input-all_room_types'));
    const {propertyRoomTypes} = props;

    const handleMenuItemClick = (event, index) => {

        setSelectedIndex(index);

        if(index !== 0){
            propertyRoomTypes.map(  (roomType) =>  {
                if( roomType.id === index ) {
                    props.onChangeRoomFilter(roomType.id);
                    setSelectedRoomType(t(roomType.language_key));
                }
            });
        }else{
            props.onChangeRoomFilter(index);
            setSelectedRoomType(t('enw-input-all_room_types'));
        }

        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    useEffect(() => {
        setSelectedRoomType(t('enw-input-all_room_types'));
    });

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <ButtonGroup ref={anchorRef} aria-label="split button">
                    <Button
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        className="type-plan-filter" buttontext={ t('enw-input-all_room_types') } fullWidth={true} startIcon={<i className="fas fa-bed" />}
                    >
                        {selectedRoomType}
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        <MenuItem
                                            selected={ selectedIndex === 0 }
                                            onClick={event => handleMenuItemClick(event, 0)}>
                                            { t('enw-input-all_room_types') }
                                        </MenuItem>
                                        {propertyRoomTypes.map((roomType, index) => (
                                            <MenuItem
                                                key={index}
                                                selected={roomType.id === selectedIndex}
                                                onClick={event => handleMenuItemClick(event, roomType.id)}
                                            >
                                                { t(roomType.language_key) }
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default AllRoomTypes;
