import React from 'react';
import PropTypes from 'prop-types';

import CheckboxAtm from "../../atomic/Checkbox/Checkbox";
import ColAtm from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";
import OverFlowText from "../OverFlowText/OverFlowText";

const RoomRateChannels = props => (
    <ColAtm className={props.colClassname} xs={6} md={2} sm={props.sm} htmlFor={props.htmlFor}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} htmlFor={props.htmlFor} xs={12}>
            <CheckboxAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                checkboxId={props.checkboxId}
                value={props.value}
                labelPlacement={props.labelPlacement}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
            />
            <ColAtm htmlFor={props.htmlFor}>
                <ColAtm htmlFor={props.htmlFor}>
                    <ColAtm className="channel-logo channel-overflow-box-img" htmlFor={props.htmlFor}>
                        <label htmlFor={props.htmlFor}>
                            <img src={props.src} alt={props.text}
                                 onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/img/brands/1.png";
                            }}/>
                        </label>
                    </ColAtm>
                    <BlockAtm className="fact-border-box channel-overflow-box">
                        <label htmlFor={props.htmlFor}>
                            <label style={{position: 'relative', display: 'grid'}}
                                   htmlFor={props.htmlFor}>
                                <OverFlowText>
                                    {props.text}
                                </OverFlowText>
                            </label>
                        </label>
                    </BlockAtm>
                </ColAtm>
            </ColAtm>
        </BlockAtm>
    </ColAtm>
);

RoomRateChannels.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    src: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    sm: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    text: PropTypes.any,
};

export default RoomRateChannels;
