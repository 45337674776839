import axios          from 'axios';
import {getAppLang, getUserToken} from "../../../../utils";
import { UPDATE_PASSWORD }   from "../../../../constants/api.constants";


export function updatePassword(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'language' : getAppLang(),
            'authToken': getUserToken(),
        },
        body: {
            params: {
                old_password : params.old_password,
                password : params.password,
                password_confirmation : params.password_confirmation
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_PASSWORD',
            payload: axios.post(UPDATE_PASSWORD, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}