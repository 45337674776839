import { editWebConstants } from "../../../../constants/Web/editWebContent.constans";

const initialState = {
    fetching :false,
    updateEditWebData: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case editWebConstants.UPDATE_EDIT_CONTENT_WEB_PENDING:
            return {
                ...state,
                fetching:true,
            };
        case editWebConstants.UPDATE_EDIT_CONTENT_WEB_FULFILLED:
            return {
                ...state,
                fetching : false,
                error    : false,
                updateEditWebData: action.payload,
            };
        case editWebConstants.UPDATE_EDIT_CONTENT_WEB_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.payload
            }
        default:
            return state
    }
}
