import React from "react";
import LabelAtm from "../../../../atomic/Label/Label";
import ButtonAtm from "../../../../atomic/Button/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
let t=null;

class ListItem extends React.Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    handleClick = () => {
        this.props.handlePlaceSelect(this.props.place);
        this.props.updateLocationAddress({
            address: this.props.place.formatted_address,
            location: {
                lat: this.props.place.geometry.location.lat(),
                long: this.props.place.geometry.location.lng()
            },
        })
    }

    onHoverPin = () => {
        this.props.updateLocationAddress({
            address: this.props.place.formatted_address,
            location: {
                lat: this.props.place.geometry.location.lat(),
                long: this.props.place.geometry.location.lng()
            },
        })
    }

    render() {
        return (
            <div className="location-search-results" onMouseOver={this.onHoverPin}>
                <h3><img className="location-type-icon" src={this.props.place.icon} alt={this.props.place.name} />
                    {this.props.place.name}
                </h3>
                <LabelAtm text={this.props.place.formatted_address} labelClass={"approved-location-text d-block"} />
                <ButtonAtm
                    className={"soft-blue-button edit-location-btn"}
                    buttontext={ t('btn-this_is_my_property') }
                    onClick={this.handleClick}
                />
            </div>
        );
    }
}

export default compose(withTranslation('common'))(ListItem);
