import React from "react";
import SuccessModal from "../../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";
import {NoSsr} from "@material-ui/core";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {OFFER_LIST} from "../../../../../constants/appUrls.constants";
let t=null;

class OfferPriceStatus extends React.Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    showMessages = () => {

        if( this.props.updatePriceOfferReducer && this.props.updatePriceOfferReducer.storeOfferStatus.status === 200){
            return <SuccessModal status={true} message={ t('enw-offer-approve-message') } redirectUrl={OFFER_LIST} />
        } else if (this.props.updatePriceOfferReducer && this.props.updatePriceOfferReducer.errorStatus.response !== undefined ) {
            let message = this.props.updatePriceOfferReducer.errorStatus.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        return (
            <NoSsr>
                {
                    !this.props.updatePriceOfferReducer.fetching &&
                        <NoSsr>
                            {
                                this.showMessages()
                            }
                        </NoSsr>
                }

            </NoSsr>
        )
    }
}

export default compose(withTranslation('common')) (OfferPriceStatus);
