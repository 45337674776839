import {roomFact} from "../../../../constants/Network/RoomAndRates/roomFact.constants";

const initialState = {
    fetching : false,
    roomFactStatus :{},
    roomFactFeature :{},
    roomFactRecommended :{},
    roomFactRecommendedFeature:{},
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case roomFact.ROOM_FACT_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                roomFactStatus:{},
                roomFactFeature:{},
                roomFactRecommended:{},
                roomFactRecommendedFeature:{},
                error : {},
            };
        case roomFact.ROOM_FACT_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomFactStatus:action.payload,
            };
        case roomFact.ROOM_FACT_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
            };
        // ROOM_FACT_FEATURE_UPDATE
        case roomFact.ROOM_FACT_FEATURE_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                roomFactStatus:{},
                roomFactFeature:{},
                roomFactRecommended:{},
                roomFactRecommendedFeature:{},
                error : {},
            };
        case roomFact.ROOM_FACT_FEATURE_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomFactFeature:action.payload,
            };
        case roomFact.ROOM_FACT_FEATURE_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
            };
        // ROOM_FACT_RECOMMENDED_UPDATE
        case roomFact.ROOM_FACT_RECOMMENDED_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                roomFactStatus:{},
                roomFactFeature:{},
                roomFactRecommended:{},
                roomFactRecommendedFeature:{},
                error : {},
            };
        case roomFact.ROOM_FACT_RECOMMENDED_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomFactRecommended:action.payload,
            };
        case roomFact.ROOM_FACT_RECOMMENDED_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
            };
        // ROOM_FACT_RECOMMENDED_FEATURE_UPDATE
        case roomFact.ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                roomFactStatus:{},
                roomFactFeature:{},
                roomFactRecommended:{},
                roomFactRecommendedFeature:{},
                error : {},
            };
        case roomFact.ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomFactRecommendedFeature:action.payload,
            };
        case roomFact.ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
            };
        case roomFact.ROOM_FACT_UPDATE_CLEAR:
            return {
                initialState
            }
        default:
            return state
    }
}
