import React, {Component} from 'react';
import {propertyFactSearchList, propertyRatePlanUpdate,PropertyRoomRates,getAccommodationTypes} from "../../../../../redux/actions/propertyRoomAndRateActions";
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import NewRoomTypeTab from "./UpdateRatePlanForm";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {isDefined} from "../../../../../utils";
let t=null


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class UpdateRatePlan extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    state = {
        open : false,
        notificationStatus : false
    };

    handlePropertyRatePlanAdd = (param) => {
        this.props.propertyRatePlanUpdate(param);
    };

    propertyFactSearch = (param) => {
        if( param.length > 3 ){
            this.props.propertyFactSearchList(param);
        }
    };

    showNotification =  () => {
        if (isDefined(this.state.notificationStatus) ) {
            let message = isDefined(this.props.ratePlanUpdateError) && this.props.ratePlanUpdateError.response.data.message
            return  <Collapse className="w-100" in={this.state.notificationStatus}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="error"
                            size="large"
                            onClick={() => {
                                this.setState({notificationStatus:false});
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity="error"
                >
                    {message}
                </Alert>
            </Collapse>
        }

        return false;

    };

    render() {
        return (
            <Dialog onClose={this.props.closeEditRoom} disableBackdropClick={true} aria-labelledby="rate-plan" open={this.props.open}>
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.props.closeEditRoom}>
                     { t('enw-network-rate_edit-title') }
                </DialogTitle>
                <DialogContent>
                    {this.showNotification()}
                    <NewRoomTypeTab
                        onRatePlanAdd={this.handlePropertyRatePlanAdd}
                        searchFact={this.propertyFactSearch}
                        factSearchList={this.props.factSearchList}
                        accommodationTypes={this.props.accommodationTypes}
                        editRateData={this.props.editRateData}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    componentDidMount() {
        this.props.getAccommodationTypes();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.ratePlanUpdateStatus){
            this.props.PropertyRoomRates();
        }

        if(!this.props.ratePlanUpdateStatus && this.props.ratePlanUpdateError)
        {
            if(prevState.notificationStatus === false)
            {
                this.setState({ notificationStatus : true} )
            }
        }

    }
}

const mapStateToProps = (state) => {
    return{
        factSearchListStatus : state.propertyRoomAndRateReducers.factSearchListStatus,
        factSearchList : state.propertyRoomAndRateReducers.factSearchList,

        ratePlanUpdateStatus : state.propertyRoomAndRateReducers.ratePlanUpdateStatus,
        ratePlanUpdateError : state.propertyRoomAndRateReducers.ratePlanUpdateError,

        accommodationTypes : state.propertyRoomAndRateReducers.accommodationTypes,
    }
};

const mapDispatchToProps = {
    propertyRatePlanUpdate,
    propertyFactSearchList,
    PropertyRoomRates,
    getAccommodationTypes
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(UpdateRatePlan));
