import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import CheckboxAtm from "../../../../../atomic/Checkbox/Checkbox";

let lastChangeID = null;
const InstallmentCheckbox = (props) => {

    const {paymentMappingListData, UpdatePaymentInstallmentStatus, installmentData} = props;

    const paymentReducers = useSelector(props => props.paymentReducers?.updateStatusPaymentInstallmentsData)
    const [isSelected , setIsSelected ] = useState( props.installmentData.status );

    const handleUpdatePayment = (e) => {

        let params = {}
        if(isSelected){
            params = {
                property_payment_mapping_id: paymentMappingListData.id,
                installment_id: installmentData.installment_id,
                set_status : 0
            };
            UpdatePaymentInstallmentStatus( params )
            lastChangeID = paymentMappingListData.id +"installmentID" + installmentData.installment_id
        }else{
            params = {
                property_payment_mapping_id: paymentMappingListData.id,
                installment_id: installmentData.installment_id,
                set_status : 1
            };
            UpdatePaymentInstallmentStatus( params )
            lastChangeID = paymentMappingListData.id +"installmentID" + installmentData.installment_id
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {
        if (paymentReducers?.status === 200 && lastChangeID === paymentMappingListData.id +"installmentID" + installmentData.installment_id){
            setState();
        }
    },[paymentReducers])

    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={paymentMappingListData.id +"installmentID" + installmentData.installment_id}
            value={paymentMappingListData.id +"installmentID" + installmentData.installment_id}
            checked={isSelected}
            onChange={handleUpdatePayment}
        />
    )


}
export default InstallmentCheckbox;
