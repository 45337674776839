import React, { useEffect, useState } from "react";
import QuickPricingAccommodation from "./Accommodation";


export default function QuickPricingAccommodations(props) {
    useEffect(() => {});
    const [roomRates] = useState(props.roomRates);

    return (
        <QuickPricingAccommodation
            roomRate={roomRates[0]}
            onChangeUpdateData={props.onChangeUpdateData}
            updatedData={ props.updatedData }
            channelAvailability={ props.channelAvailability }
            showStopSell={ props.showStopSell }
            showMinStay={ props.showMinStay }
        />
    );
}
