import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../../../atomic/Button/Button";
import ColAtm from "../../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../../atomic/Label/Label";
import {useTranslation} from "react-i18next";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const ErrorModal = (props) => {
    const [modalStatus, setModalStatus] = useState(props.status);
    const [message] = useState(props.message)
    const [errorCode] = useState(props.errorCode)
    const { t } = useTranslation();

     const handleClose = () =>  {
        if(modalStatus === true){
            setModalStatus(false);
        }

    };

    const handleChangeTabIndex = (e,tabIndex) => {
       props.handleChangeTabIndex(e,tabIndex);
        handleClose();
    }

     const onClickShowForgotPassword = () => {
        handleClose();
        props.onClickShowForgotPassword();
    }

    const renderButton = () => {
        if (errorCode === 100){
            return (<ColAtm>
                <ColAtm xs={12} md={12} className="photoButtonsRow">
                    <ColAtm md={12} className="m-auto">
                        <ColAtm md={6}>
                            <ButtonAtm
                                className="error-sign-up-button bg-red"
                                outline
                                fullwidth={true}
                                buttontext={ t('btn-forgot_password') }
                                onClick={onClickShowForgotPassword}/>
                        </ColAtm>
                        <ColAtm md={6}>
                            <ButtonAtm
                                className="error-sign-up-button bg-green"
                                outline
                                fullwidth={true}
                                buttontext={ t('btn-login') }
                                onClick={ (e) => handleChangeTabIndex(e,1)}
                            />
                        </ColAtm>
                    </ColAtm>
                </ColAtm>
            </ColAtm>)
        }
        else if(errorCode === 200){
            return <ButtonAtm
                className="soft-red-status-button"
                outline
                fullwidth={true}
                buttontext="Ok"
                onClick={handleClose}/>
        }
    }


    return (
        <>
            <Dialog onClose={handleClose} className="status-modal" aria-labelledby="status" open={modalStatus}>
                <DialogTitle className="red-bottom-border"  id="status" onClose={handleClose}>
                    { t('modal-error-title') }
                </DialogTitle>
                <DialogContent>
                    <ColAtm>
                        <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow status-modal-content">
                            <ColAtm>
                                <div className="m-auto">
                                        <span>
                                            <i
                                                className="fas fa-times"
                                                style={{margin: 'auto', fontSize: '87px', color: '#d71f26', backgroundColor: '#fefafa', borderRadius: '135px', padding: '40px 54px'}}
                                            />
                                        </span>
                                </div>
                            </ColAtm>
                            <LabelAtm labelClass="status-modal-subtitle" text={ t(message) } />
                            {
                                renderButton()
                            }
                        </ColAtm>
                    </ColAtm>
                </DialogContent>
            </Dialog>
        </>
    )


}

export default ErrorModal;
