export const factConstants = {
    FACT_REQUEST:       'USERS_FACT_REQUEST',
    FACT_SUCCESS:       'USERS_FACT_SUCCESS',
    FACT_FAILURE:       'USERS_FACT_FAILURE',

    UPDATE_FACT_MAPPING_PENDING  : 'UPDATE_FACT_MAPPING_PENDING',
    UPDATE_FACT_MAPPING_FULFILLED: 'UPDATE_FACT_MAPPING_FULFILLED',
    UPDATE_FACT_MAPPING_REJECTED : 'UPDATE_FACT_MAPPING_REJECTED',
    UPDATE_FACT_MAPPING_CLEAR : 'UPDATE_FACT_MAPPING_CLEAR',
};
