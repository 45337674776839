import {webConstants} from "../../constants/Public/web.constants";
import {publishWebConstants} from "../../constants/Web/publishWeb.constants";

const initialState = {
    fetching : true,
    error : {},
    submitError : {},
    createStatus : false,
    domains : [],
    updateData: [],
    fetchingPublish :true,
    publishWebData: [],
    errorPublish    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        // Get Web
        case webConstants.GET_WEB_PENDING:
            return {
                ...state,
                fetching: true,
                error: {},
                submitError: {},
                updateData: [],
                domains:[],
                publishWebData: [],
                errorPublish    :{},
            };
        case webConstants.GET_WEB_FULFILLED:
            return {
                ...state,
                fetching: false,
                submitError: {},
                updateData: [],
                domains: action.payload
            };
        case webConstants.GET_WEB_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
                submitError: {},
                updateData: [],
            };


        // Create Web
        case webConstants.CREATE_WEB_PENDING:
            return {
                ...state,
                updateData: [],
                error: {},
                submitError: {},
                publishWebData: [],
                errorPublish    :{},
            };
        case webConstants.CREATE_WEB_FULFILLED:
            return {
                ...state,
                submitError: {},
                updateData: action.payload,
                createStatus: action.payload.status,
                domains:[...state.domains,action.payload.data] // action.payload.data.domain
            };
        case webConstants.CREATE_WEB_REJECTED:
            return {
                ...state,
                submitError: action.payload,
                createStatus: action.payload.status
            };


        case publishWebConstants.PUBLISH_WEB_PENDING:
            return {
                ...state,
                fetchingPublish:true,
                errorPublish: {},
                updateData: [],
                submitError: {},
                publishWebData: [],
            };
        case publishWebConstants.PUBLISH_WEB_FULFILLED:
            return {
                ...state,
                fetchingPublish : false,
                publishWebData: action.payload,
                errorPublish: {},
            };
        case publishWebConstants.PUBLISH_WEB_REJECTED:
            return {
                ...state,
                publishWebData: [],
                errorPublish: action.payload,
                fetchingPublish : false,
            };

        // Update Web
        case webConstants.UPDATE_WEB_PENDING:
            return {
                ...state,
                updateData: [],
                submitError: {},
                publishWebData: [],
                errorPublish    :{},
            };
        case webConstants.UPDATE_WEB_FULFILLED:
            return {
                ...state.domains,
                updateData: action.payload,
                submitError: {},
                domains: action.payload.data,
            };
        case webConstants.UPDATE_WEB_REJECTED:
            return {
                ...state,
                submitError: action.payload,
                fetching: false,
            };
        default:
            return state
    }
}
