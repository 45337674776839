import React from 'react';

class MobileMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: props.address,
            location: props.location
        };
        this.getCurrentLocation();
    }

    setFallbackAddress = () => {
        /**
         * Browser üzerinden adres istenirse bu fonkiyonu çalıtır!!
         */
        /*this.setState({
            address: this.props.address,
            location: this.props.location
        }, () => this.props.updateLocationAddress(this.state) );*/

        this.props.updateLocationAddress(this.state)
    }

    getCurrentLocation = () => {
        /**
         * Browser üzerinden adres istenirse bu fonkiyonu çalıtır!!
         */
       /* if (navigator.geolocation) {
            let successCallback = (position) => {
                //console.log("position: ", position);
                let location = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    },
                    address = 'UNKNOWN ADDRESS!';
                let { google } = this.props;
                let geocoder = new google.maps.Geocoder();
                let latlng = new google.maps.LatLng(location.lat, location.lng);

                this.props.findLatLang(latlng, geocoder)
                    .then(result => {
                        address = result;
                        this.props.updateLocationAddress({
                            location: location,
                            address: address
                        })
                    })
                    .catch(error => {
                        this.props.updateLocationAddress({
                            location: location,
                            address: address
                        })
                    });
            };

            let errorCallback = (err) => {
                //console.log("Geolocation Navigator Error", err);
                if (err.code === 1) {
                    //console.log("Geolocation Permission Denied! Choose your location manually.")
                }
                this.setFallbackAddress();
            }

            let options = {
                enableHighAccuracy: true
            };

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
        } else {
            console.log('Geolocation is not supported by this browser! Manually enter your location.');
            this.setFallbackAddress();
        }*/

        this.setFallbackAddress();
    }

    render() {
        return (
            <div id='map' style={{width:'%100',height:'80vh'}}></div>
        );
    }
}

export default MobileMap;
