import React from "react";
import SuccessModal from "../../../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import {NoSsr} from "@material-ui/core";
import {isDefined} from "../../../../../../utils";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {NETWORK_CHANNELS} from "../../../../../../constants/appUrls.constants";

let t=null;

class ChannelDisconnect extends React.Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    showMessages = () => {

        if( this.props.updateChannelData.channelUpdate && this.props.updateChannelData.channelUpdate.status === 200 ){
            return <SuccessModal status={true} message={ this.props.channelName + " "+ t('enw-notification_disconnected_success') } redirectUrl={NETWORK_CHANNELS} />
        } else if (isDefined(this.props.updateChannelData.error.response) ) {
            let message = this.props.updateChannelData.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        return (
            <NoSsr>
                {
                    !this.props.updateChannelData.fetching &&
                    <NoSsr>
                        {
                            this.showMessages()
                        }
                    </NoSsr>
                }

            </NoSsr>
        )
    }
}

export default compose(withTranslation('common')) (ChannelDisconnect);
