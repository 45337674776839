export const brandConstants = {
    BRAND_PENDING  : 'BRAND_PENDING',
    BRAND_FULFILLED: 'BRAND_FULFILLED',
    BRAND_REJECTED : 'BRAND_REJECTED',

    UPDATE_BRAND_PENDING  : 'UPDATE_BRAND_PENDING',
    UPDATE_BRAND_FULFILLED: 'UPDATE_BRAND_FULFILLED',
    UPDATE_BRAND_REJECTED : 'UPDATE_BRAND_REJECTED',

    DELETE_BRAND_LOGO_PENDING : 'DELETE_BRAND_LOGO_PENDING',
    DELETE_BRAND_LOGO_FULFILLED : 'DELETE_BRAND_LOGO_FULFILLED',
    DELETE_BRAND_LOGO_REJECTED : 'DELETE_BRAND_LOGO_REJECTED'
};
