import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Backdrop, CircularProgress, Grid, MenuItem} from "@material-ui/core";
import {SelectValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import InputAtm from "../../../../../atomic/Input/Input";
import ColAtm from "../../../../../atomic/Col/Col";
import ButtonAtm from "../../../../../atomic/Button/Button";
import Editor from 'react-simple-wysiwyg';
import {getProperty, isDefined} from "../../../../../../utils";
import {
    createPopup,
    createPopupClearState, getPopupList,
    uploadPopupImage,
    uploadPopupImageClearState
} from "../../../../../../redux/actions/Web/Popup/PopupActions";
import {getLanguages} from "../../../../../../redux/actions/languageActions";
import Alert from "@material-ui/lab/Alert";
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../../atomic/PageHeader/PageHeader";
import {WEB_POPUP_LIST} from "../../../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {history} from "../../../../../../utils/history";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {formattedMonth, getAppLang} from "../../../../../../utils";
import {FormHelperText, FormControl} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {createFilterOptions} from "@material-ui/lab";
import moment from "moment/moment";

import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";

const localeMap = {
    es: esLocale,
    de: deLocale,
    tr: trLocale
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#d71f26',
    },
}))

export default function CreatePopupModal(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {handleSubmit} = useForm();

    const createImage = useSelector(props => props.popupReducers?.createImage);
    const popupData = useSelector(props => props.popupReducers?.createPopup);
    const createPopupError = useSelector(props => props.popupReducers?.createPopupError);
    const languageList = useSelector(props => props.languagesReducer?.languages);
    const createImageError = useSelector(props => props.popupReducers?.createImageError);
    const createImageFetching = useSelector(props => props.popupReducers?.createImageFetching);

    const dispatch = useDispatch();

    const [filePath, setFilePath] = useState("");
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        dispatch(getLanguages());
    }, [dispatch]);
    
    useEffect(() => {
        if (popupData.status === 200) {
            dispatch(getPopupList());
            dispatch(createPopupClearState());
            dispatch(uploadPopupImageClearState());
            history.push(WEB_POPUP_LIST)
        }
    }, [popupData]);

    useEffect(() => {
        setFilePath("")
        setFile("")
        setTitle("")
        setLanguage("")
        setStartDate(new Date())
        setEndDate(new Date())
        dispatch(createPopupClearState());
        dispatch(uploadPopupImageClearState());
    }, [dispatch]);

    useEffect(() => {
        if (createImageError) {
            setFilePath("")
            setFile("")
        }
    }, [createImageError]);

    const handleChangeTitle = ({target}) => {
        setTitle(target.value);
    };

    const selectLang = ({target}) => {
        setLanguage(target.value)
    };

    const popupStartDate = ({target}) => {
        setStartDate(target.value)
    }

    const popupEndDate = ({target}) => {
        setEndDate(target.value)
    }
    
    const register = (props) => {
        const {register} = props
    }

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            title: title,
            start_date: moment(startDate).format("yyyy-MM-DD").toString(),
            end_date: moment(endDate).format("yyyy-MM-DD").toString(),
            language_code: language ? language : null,
            image: createImage?.image ? createImage.image : null,
        }

        dispatch(createPopup(params));
    };


    const onFileChange = event => {
        event.preventDefault();
        const file = event.target.files[0];
        if (isDefined(file)) {
            setFilePath(URL.createObjectURL(file))
            setFile(event.target.files[0])
        }
        const params = {
            property_id: getProperty(),
            file: file,
        }
        dispatch(uploadPopupImage(params));
    };
    
    const defaultValues ={
        language: 'tr'
    }
    
    return (
        <div className="mb-64">
            <PageTitle title={t('btn-create-popup')}/>
            <PageHeaderAtm backButton={WEB_POPUP_LIST} exitText={t('enw-popup-title')} text={t('btn-create-popup')}/>
            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                <Container maxWidth="xl" className='root-div'>
                    <div className="container-width">
                        <Fragment>
                            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                <Grid container className="blog-edit-and-create" spacing={3} md={8}>
                                    {popupData?.status === 200 &&
                                        <Alert className="w-100" severity="success">{t('enw-redirect_to_save-message')}</Alert>}
                                    {createPopupError &&
                                        <Alert className="w-100" severity="error">{createPopupError?.response?.data?.message}</Alert>}
                                    {createImageError &&
                                        <Alert className="w-100" severity="error">{createImageError?.response?.data?.message}</Alert>}
                                    {
                                        createImageFetching &&
                                        <Backdrop className={classes.backdrop} open>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                    <Grid item xs={12} md={8}>
                                        <InputAtm
                                            label={t('enw-input-title')}
                                            placeholder={t('enw-input-title')}
                                            variant="outlined"
                                            autoComplete="off"
                                            onChange={handleChangeTitle}
                                            validators={['required']}
                                            errorMessages={[t("enw-notification_this_is_required")]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SelectValidator
                                            name="category"
                                            onChange={selectLang}
                                            value={language}
                                            className="form-control"
                                            variant="outlined"
                                            fullWidth
                                            label={t('enw-input-language')}
                                        >
                                            {
                                                languageList?.map((lang) => (
                                                    <MenuItem value={lang.code}>
                                                        <img src={`/assets/img/language/${lang.code}.svg`} alt="en"
                                                             style={{width: 20, marginRight: 8}}/> {t(lang.language_key)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </SelectValidator>
                                    </Grid>
                                    <div className='d-flex w-100 p-12 popup-div'>
                                            <div className='w-100'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                            <DatePicker
                                                autoOk
                                                disableToolbar
                                                id="start_date"
                                                name="start_date"
                                                className="MuiGrid-grid-xs-12 w-100"
                                                inputVariant="outlined"
                                                format="dd.MM.yyyy"
                                                margin="normal"
                                                label={t('enw-input-start_date')}
                                                variant="inline"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                value={startDate}
                                                minDate={moment()}
                                            //  error={!!errors.expire_date}
                                            />
                                        </MuiPickersUtilsProvider>
                                            </div>
                                        <div className='ml-29 w-100 popup-ml'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                            <DatePicker
                                                autoOk
                                                disableToolbar
                                                id="end_date"
                                                name="end_date"
                                                className="MuiGrid-grid-xs-12 ml-29 w-100"
                                                inputVariant="outlined"
                                                format="dd.MM.yyyy"
                                                margin="normal"
                                                label={t('enw-input-end_date')}
                                                variant="inline"
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                value={endDate}
                                            //  error={!!errors.expire_date}
                                            />
                                        </MuiPickersUtilsProvider>
                                        </div>
                                        <div className='ml-29 mt-22 w-100 popup-ml'>
                                        <Grid item xs={12} className="blog-img-area">
                                            <input
                                                className="d-none"
                                                accept="image/png, image/jpeg, .pdf"
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={onFileChange}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button
                                                    className="room-rate-add soft-blue-button m-auto radius-8 h-58 w-100-i"
                                                    component="span"
                                                    startIcon={<i className="fas fa-cloud-upload-alt"/>}>
                                                    {t('btn-upload_image')}
                                                </Button>
                                            </label>
                                        </Grid>
                                        </div>
                                            </div>
                                            <Grid item xs={12}>
                                        <div>
                                            {
                                                !isDefined(file) ?
                                                    <label htmlFor="contained-button-file"
                                                           className="cursor-pointer"></label>
                                                    :
                                                    <div className="blog-preview-img m-w-auto mt-33">
                                                        <img src={filePath}/>
                                                    </div>
                                            }
                                        </div>
                                            </Grid>
                                </Grid>
                                <ColAtm className="mt-32" md={12}>
                                    <ButtonAtm
                                        className="m-auto room-rate-modal-save bg-red"
                                        buttontext={t('btn-save')}
                                        color="primary"
                                        type="submit"
                                        disabled={!isDefined(file) ? true : !isDefined(title)}
                                    />
                                </ColAtm>
                            </ValidatorForm>
                        </Fragment>
                    </div>
                </Container>
            </Animated>
        </div>
    );
}
