import React from 'react';
import PropTypes from "prop-types";


export default function AddPlaceButton(props) {

    return (
        <div className="w-100">
            {props.placeMenuItem}
        </div>
    );
}

AddPlaceButton.propType = {
    placeMenuItem: PropTypes.string,
};

