export const offerConstants = {
    GET_CREATE_OFFER_PENDING  :   'GET_CREATE_OFFER_PENDING',
    GET_CREATE_OFFER_FULFILLED:   'GET_CREATE_OFFER_FULFILLED',
    GET_CREATE_OFFER_REJECTED :   'GET_CREATE_OFFER_REJECTED',

    STORE_OFFER_PENDING  :   'STORE_OFFER_PENDING',
    STORE_OFFER_FULFILLED:   'STORE_OFFER_FULFILLED',
    STORE_OFFER_REJECTED :   'STORE_OFFER_REJECTED',

    GET_OFFER_PRICE_PENDING  : 'GET_OFFER_PRICE_PENDING',
    GET_OFFER_PRICE_FULFILLED: 'GET_OFFER_PRICE_FULFILLED',
    GET_OFFER_PRICE_REJECTED : 'GET_OFFER_PRICE_REJECTED',

    STORE_OFFER_PRICE_PENDING  : 'STORE_OFFER_PRICE_PENDING',
    STORE_OFFER_PRICE_FULFILLED: 'STORE_OFFER_PRICE_FULFILLED',
    STORE_OFFER_PRICE_REJECTED : 'STORE_OFFER_PRICE_REJECTED',

    GET_OFFER_PENDING  : 'GET_OFFER_PENDING',
    GET_OFFER_FULFILLED: 'GET_OFFER_FULFILLED',
    GET_OFFER_REJECTED : 'GET_OFFER_REJECTED',

    UPDATE_OFFER_PENDING  : 'UPDATE_OFFER_PENDING',
    UPDATE_OFFER_FULFILLED: 'UPDATE_OFFER_FULFILLED',
    UPDATE_OFFER_REJECTED : 'UPDATE_OFFER_REJECTED',

    UPDATE_OFFER_STATUS_PENDING  : 'UPDATE_OFFER_STATUS_PENDING',
    UPDATE_OFFER_STATUS_FULFILLED: 'UPDATE_OFFER_STATUS_FULFILLED',
    UPDATE_OFFER_STATUS_REJECTED : 'UPDATE_OFFER_STATUS_REJECTED',

    OFFER_ACCEPT_STATUS_PENDING  : 'OFFER_ACCEPT_STATUS_PENDING',
    OFFER_ACCEPT_STATUS_FULFILLED: 'OFFER_ACCEPT_STATUS_FULFILLED',
    OFFER_ACCEPT_STATUS_REJECTED : 'OFFER_ACCEPT_STATUS_REJECTED',
}