import React from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

let t=null;


class FactSubtitle extends React.Component{
    constructor(props) {
        super(props);
        t = props.t;
    }

    render() {
        return (
            <>
                <ColAtm>
                    {
                        <LabelAtm labelClass="fact-title" variant="h6" component="h2" text={ t(this.props.this_sub_category.title_language_key) } />
                    }
                </ColAtm>
            </>
        )
    }
}


export default compose(withTranslation('common')) (FactSubtitle);