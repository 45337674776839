import React from 'react'
import {compose} from "redux";
import {withTranslation} from "react-i18next";
let t=null;

class LoginCarousel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      axis: 'x'
    }
    this.setAxis = axis => {
      return () => this.setState({'axis': axis})
    }
    t = props.t;
  }
  render () {
    return (
      <div style={{textAlign: 'center', margin: 'auto'}}>
          <p style={{backgroundColor: '#f4f7fc', height: '100%', fontSize: '28px', fontWeight: 'bold', color: '#2d3039'}}>
            { t('login-carousel-title') }
            <span style={{display: 'block', fontSize: '18px', fontWeight: '500', color: '#8f94a2', width: '467px', margin: 'auto'}}>
               { t('login-carousel-description') }
              </span>
          </p>
      </div>
    )
  }
}

//export default LoginCarousel;
export default compose(withTranslation('common')) (LoginCarousel);
