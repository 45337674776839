import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import ColAtm from "../../../../../atomic/Col/Col";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {PAYMENT_SETUP_SHORT, PAYMENT_INSTALLMENT_SHORT} from "../../../../../../constants/appUrls.constants";

const useStyles = makeStyles(theme => ({
    root: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#d71f26',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#d71f26'
        },
    }
}));

export default function PaymentMenu(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Hidden xsDown>
            <ColAtm md={12} className={props.menuClass}>
                <ColAtm className={props.setupColClass}  sm={props.tryCurrencyStatus || (props.boxStatus && props.responseCurrencyCode === "TRY") ? 6 : 12}>
                    <Link to={PAYMENT_SETUP_SHORT + props.menuId} className={classes.aLink}>
                        {props.bankName} { t('enw-payment-payment_setup-page_title') }
                    </Link>
                </ColAtm>
                {
                    props.tryCurrencyStatus || (props.boxStatus && props.responseCurrencyCode === "TRY") ?
                        <ColAtm className={props.InstallmentColClass} sm={6}>
                            <Link to={PAYMENT_INSTALLMENT_SHORT + props.menuId} className={classes.aLink}>
                                {props.bankName} { t('enw-payment-payment_installment') }
                            </Link>
                        </ColAtm>
                        :
                        <></>
                }
            </ColAtm>
        </Hidden>
    );
}
PaymentMenu.propTypes = {
    text: PropTypes.string,
    menuClass: PropTypes.string,
    setupColClass: PropTypes.string,
    InstallmentColClass: PropTypes.string,
};
