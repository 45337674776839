import React, {Component}   from 'react';
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getChannelSetup, addChannelSetup} from "../../../../../redux/actions/Network/Channel/channelSetup.actions";
import {updateChannelConnect} from "../../../../../redux/actions/Network/Channel/channelConnectActions";
import ChannelInformationData from "./Components/ChannelInformationData";
import {Grid} from "@material-ui/core";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {NETWORK_CHANNELS} from "../../../../../constants/appUrls.constants";

let t =null;

class ChannelInformation extends Component {

    constructor(props){
        super(props);
        this.state = {
            channel_id : '',
            isVisible: true
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";

        if ( this.props.match.params.id )
        {
            this.props.getChannelSetup( { channel_id : this.props.match.params.id } );
            this.setState({
                channel_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/network');
        }
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />
                <PageHeaderAtm backButton={NETWORK_CHANNELS}
                               exitText={ t('btn-channel_list') }
                               text={ this.props.channelSetupReducers.parent_id !== null ? this.props.channelInformationData.name + " " + t('enw-channel-connect-title') : this.props.channelInformationData.name + " " + t('enw-general-tab-information') } />
                <Grid container className={ this.props.channelSetupReducers.parent_id !== null ? "mt-70" : "w-100 m-channel-information"} style={{overflowY: "hidden"}}>
                    {
                        !this.props.channelSetupReducers.fetching &&
                        <ChannelInformationData
                            channelSetup={this.props.channelSetupReducers}
                            channelSetupReducers={this.props.channelInformationData}
                            channelInformationDataCategory={this.props.channelInformationDataCategory}
                            updateChannelConnect={this.props.updateChannelConnect}
                            updateChannelData={this.props.updateChannel}
                            addChannelSetup={this.props.addChannelSetup}
                            channelId={this.state.channel_id}
                        />
                    }
                </Grid>
            </>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        channelSetupReducers : props.channelSetupReducers,
        channelInformationData : props.channelSetupReducers.channelInformationData,
        channelInformationDataCategory : props.channelSetupReducers.channelInformationDataCategory,
        updateChannel : props.channelSetupReducers,
    }
};

const mapDispatchToProps ={
    getChannelSetup,
    addChannelSetup,
    updateChannelConnect
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ChannelInformation));
