import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonAtm from "../../../../atomic/Button/Button";
import {useTranslation} from "react-i18next";
import {BASE_API} from "../../../../../constants/api.constants";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {CircularProgress, Grid} from "@material-ui/core";
import {getAppLang, getUserToken, isDefined} from "../../../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import {
    getCpaClearState,
    getCpaDailyPrice, getPropertyCpa,
    setSyncCpaCompetitor,
    setSyncCpaCompetitorClearState
} from "../../../../../redux/actions/Network/Inventory/CompetitorActions";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const UpdateOrCreateCompetitor = ({ allDays, getCpa, type }) =>  {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { syncCompetitor, errorSyncCompetitor } = useSelector(props => props?.cpaReducer)

    const [isOpen, setIsOpen] = useState(false); // Open modal
    const [data, setData] = useState(getCpa);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 3;


    const handleClickOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    const onChangeHandle = async value => {

        if (value.length >= 3 && data.length < 10) {
            const response = await fetch(BASE_API + "/cpa/property",
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authToken': getUserToken(),
                        'language' : getAppLang()
                    },
                    method: "POST",
                    body: JSON.stringify({keyword: value})
                })

            const data = await response.json();
            setOptions(Object.keys(data?.data).map(key => data?.data[key]));
        }

    };

    const selectedHotels = (selectedData) => {
        setData(selectedData)
    };

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(setSyncCpaCompetitor(data));
    };

    useEffect( () => {

        if (syncCompetitor.status === 200) {
            window.stop();


            setTimeout(() => {
                dispatch(getPropertyCpa());

                allDays.map((day) => {
                    getCpa.map((cpa) => {
                        dispatch(getCpaDailyPrice(day, cpa?.key))
                    });
                });
            }, 100)

            setIsOpen(false);
        }

    },[allDays, dispatch, getCpa, syncCompetitor])


    useEffect( () => {

        if (isOpen === false) {
            dispatch(setSyncCpaCompetitorClearState());
            dispatch(getCpaClearState());
        }

    },[dispatch, isOpen])

    return (
        <>
            <ButtonAtm
                className={type !== "edit" ? "filter-action-button" : "cogs-inventory-btn"}
                startIcon={type !== "edit" && <i className="fas fa-tags" />}
                buttontext={type !== "edit" ? t('enw-cpa_add-hotel') : <i className="fas fa-cog gray fs-22" /> }
                onClick={handleClickOpen}
            />
            <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="rate-plan" open={isOpen}>
                <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={handleClose}>
                    {t('enw-cpa_add-hotel-modal-title')}
                </DialogTitle>
                <DialogContent>
                    {syncCompetitor.status === 200 && <Alert severity="success">{t('enw-cpa_add-hotel-success-message')}</Alert>}
                    {isDefined(errorSyncCompetitor?.response) && <Alert severity="error">{errorSyncCompetitor?.response?.data?.message}</Alert>}

                    <Alert severity="info"> { t('enw-cpa_add-hotel-info-message') } </Alert>

                    <form onSubmit={onSubmit}>
                        <Grid container>
                            <Grid item xs={12}>

                                <Autocomplete
                                    id="add-hotel"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    options={options}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={option => isDefined(option.location) ? option?.name + " (" + option.location + ")" : option?.name}
                                    onChange={ (options, value) => {selectedHotels(value)} }
                                    multiple
                                    defaultValue={getCpa}
                                    loading={loading}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={ t('enw-cpa_add-hotel-label') }
                                            variant="outlined"
                                            onChange={ev => {
                                                if (ev.target.value !== "" || ev.target.value !== null) {
                                                    onChangeHandle(ev.target.value);
                                                }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} className="t-center mt-14">
                                <ButtonAtm
                                    type="submit"
                                    className="room-rate-modal-save room-rate-save bg-red m-auto"
                                    buttontext={ t('btn-save') }
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default UpdateOrCreateCompetitor;
