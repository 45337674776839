import React from "react";
import {NoSsr} from "@material-ui/core";
import { Redirect } from 'react-router-dom';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
let t=null;

class NotFoundOffer extends React.Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    NotFoundOffer = () => {

        if (this.props.offerStatus.error) {
            if (this.props.offerStatus.error.response.data.status === 404) {
                return  <Redirect to="/404" />
            }
        }

    }

    render() {
        return (
            <NoSsr>
                {
                    !this.props.offerStatus.fetching &&
                    <NoSsr>
                        {
                            this.NotFoundOffer()
                        }
                    </NoSsr>
                }
            </NoSsr>
        )
    }
}

export default compose(withTranslation('common')) (NotFoundOffer);
