import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProperty} from "../../../../../../utils";
import {updateChannelChildPricingMapping} from "../../../../../../redux/actions/Network/Channel/channelPaxPricing";
import CheckboxAtm from "../../../../../atomic/Checkbox/Checkbox";

let lastChangeID = null;
const ChildCheckboxAtm = (props) => {

    const { roomId, childId, childName,rateMappingId ,channelId} = props;
    const [isSelected , setIsSelected ] = useState(props.isSelected);
    const updateChildPricingPolicyStatus = useSelector(props => props.updatePersonPricingReducer?.updateChildPricingPolicyStatus)
    const dispatch = useDispatch();


    const handleUpdateChild = (e) => {

        let params = {}
        if(isSelected){
            params = {
                property_id: getProperty(),
                channel_id: channelId,
                room_rate_channel_mapping_id: rateMappingId,
                pricing_policy_child_id: childId,
                is_selected: false
            };
            dispatch( updateChannelChildPricingMapping( params ) )
            lastChangeID = rateMappingId+""+childId
        }else{
            params = {
                property_id: getProperty(),
                channel_id: channelId,
                room_rate_channel_mapping_id: rateMappingId,
                pricing_policy_child_id: childId,
                is_selected: true
            };
            dispatch( updateChannelChildPricingMapping( params ) )
            lastChangeID = rateMappingId+""+childId
        }
    }

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {

        if (updateChildPricingPolicyStatus === 200 && lastChangeID === rateMappingId+""+childId){
            setState();
        }

    },[updateChildPricingPolicyStatus])

    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={"room" +roomId + "rate" + rateMappingId + childId + "adult" + childName }
            value={childId}
            checked={isSelected}
            onChange={handleUpdateChild}
        />
    )

}
export default ChildCheckboxAtm;
