import React, {useEffect, useState} from "react";
import moment from "moment";
import Container from "@material-ui/core/Container";
import {Button, Grid, Tooltip} from "@material-ui/core";

import ColAtm from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";

import InventorySkeleton from "../../../../molecules/InventorySkeleton/InventorySkeleton";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import UpdateOrCreateCompetitorModal from "./UpdateOrCreateCompetitorModal";
import {getCpaDailyPrice} from "../../../../../redux/actions/Network/Inventory/CompetitorActions";
import {getAppLang, getCurrency, getProperty, isDefined} from "../../../../../utils";
import {DOWNLOAD_CPA_EXCEL} from "../../../../../constants/api.constants";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Competitor =  (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const { getCpa, fetching, getCpaDaily } = useSelector(props => props?.cpaReducer)
    const lsAllDays = JSON.parse(localStorage.getItem("allDays"));

    const [priceList, setPriceList] = useState([]);
    const [excelUrl, setExcelUrl] = useState("");
    const [disableExcelBtn, setDisableExcelBtn] = useState(false);


    useEffect( () => {

        lsAllDays.map((day) => {
            getCpa.map((cpa) => {
                dispatch(getCpaDailyPrice(day, cpa?.key))
            });
        });

    },[])

    useEffect( () => {
        if (getCpaDaily !== false) {
            setPriceList([...priceList, getCpaDaily])
        } else {
            setPriceList([])
        }
    },[getCpaDaily])


    const downloadExcel = () => {
        const url = DOWNLOAD_CPA_EXCEL;

        if (excelUrl === "") {
            setDisableExcelBtn(true)
        }

        const data = ({
            params: {
                property_id: getProperty(),
                start_date: lsAllDays[0],
                finish_date: lsAllDays[lsAllDays.length - 1],
                currency : getCurrency()
            }
        });
        axios.post(url, data,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            setExcelUrl(result.data.data.url)
            setDisableExcelBtn(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect( () => {
        if (excelUrl !== "") {
            window.open(excelUrl)
        }

        setExcelUrl("")
        setDisableExcelBtn(false)
    },[excelUrl])

    return (
        <>
            {
                fetching ?
                    <InventorySkeleton/>
                    :
                    <>
                        {
                            getCpa.length > 0 ?
                                <>
                                    <ColAtm className="inventory-box">
                                        <ColAtm className="inventory-room-box">
                                            <ColAtm xs={3} lg={4} className="align-self-center">
                                                <BlockAtm md={1} className="m-h-auto">
                                                    <UpdateOrCreateCompetitorModal
                                                        allDays={localStorage.getItem("allDays")}
                                                        getCpa={getCpa}
                                                        type="edit"
                                                    />
                                                </BlockAtm>
                                                <BlockAtm xs={6}>
                                                    <LabelAtm labelClass="inventory-room-name" text={ t('enw-cpa_price-title')} />
                                                </BlockAtm>
                                                <Grid item xs={4} />
                                            </ColAtm>
                                            <ColAtm xs={7} className="inventory-date-padding pos-relative" />
                                            <ColAtm xs={1} className="competitor-excel">
                                                {
                                                    !disableExcelBtn ?
                                                        <Tooltip title={ t('datatable-toolbar-download_excel') }>
                                                            <Button type="button"
                                                                    onClick={downloadExcel}
                                                                    variant="contained"
                                                                    color="primary"
                                                            >
                                                                <i className="fas fa-file-excel fs-16"/>
                                                            </Button>
                                                        </Tooltip>
                                                        :
                                                        <div className={classes.wrapper}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={disableExcelBtn}
                                                            >
                                                                <i className="fas fa-file-excel fs-16"/>
                                                            </Button>
                                                            {disableExcelBtn && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                        </div>
                                                }
                                            </ColAtm>
                                        </ColAtm>
                                        {getCpa?.map((cpa) =>
                                        <ColAtm className="inventory-avail-box m-h-33">
                                            <ColAtm xs={2} >
                                                <Grid item md={1}></Grid>
                                                <Grid item md={1}><i className="gray fas fa-hotel" /></Grid>
                                                <Grid item md={10}>
                                                    <LabelAtm labelClass="inventory-room-name" text={cpa?.name} />
                                                </Grid>
                                                <BlockAtm className="channels-box" md={2}></BlockAtm>
                                                <BlockAtm className="inventory-copy-box" md={3}></BlockAtm>
                                            </ColAtm>
                                            <ColAtm xs={10}>
                                                <Grid container className="inventory-rate-price">
                                                    <ColAtm xs={2} />
                                                    <Grid xs={10} className="m-overflow-inventory-price">
                                                        <ColAtm className="inventory-price-padding">
                                                            {
                                                                priceList
                                                                    .filter( (price) => price?.competitorPropertyKey === cpa?.key )
                                                                    .sort((a, b) => t(a.date) > t(b.date ) ? 1:-1)
                                                                    .map( (value) => (
                                                                        <BlockAtm
                                                                            className={`${(moment(value.date).locale('de').weekday() == 5 || moment(value.date).locale('de').weekday() == 6 ) && 'weekend-inputs'} inventory-price t-center f-w-600`}
                                                                        >
                                                                            <Tooltip title={ isDefined(value.provider) ? value.provider : "-" }>
                                                                                <div className="MuiFormControl-root MuiTextField-root type-number-hidden MuiFormControl-fullWidth">{ isDefined(value.amount) ? value.amount : "-" }</div>
                                                                            </Tooltip>
                                                                            <p className={`${(moment(value.date).locale('de').weekday() == 5 || moment(value.date).locale('de').weekday() == 6 ) && 'weekend-radius'} null-inventory`}> &nbsp;</p>

                                                                        </BlockAtm>
                                                                    ))
                                                            }
                                                            <p className="inventory-currency">{getCurrency()}</p>
                                                        </ColAtm>
                                                    </Grid>
                                                </Grid>
                                            </ColAtm>
                                        </ColAtm>
                                        )}

                                    </ColAtm>
                                </>
                                :
                                <Grid container spacing={3} className="mt-60 web-dashboard centered-position">
                                    <Container maxWidth="xl">
                                        <div className="container-width">
                                            <ColAtm xs={12} className="photoButtonsRow one-page-content">
                                                <ColAtm>
                                                    <div className="m-auto first-create-room">
                                                        <UpdateOrCreateCompetitorModal
                                                            allDays={localStorage.getItem("allDays")}
                                                            getCpa={getCpa}
                                                        />
                                                    </div>
                                                </ColAtm>
                                                <LabelAtm labelClass="one-page-title" text={ t('btn-create_cpa-first-title') } />
                                                <LabelAtm labelClass="one-page-subtitle p-0-100" text={ t('btn-create_cpa-first-desc') } />
                                            </ColAtm>
                                        </div>
                                    </Container>
                                </Grid>
                        }
                    </>
            }
        </>
    )
}

export default Competitor;
