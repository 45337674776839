import React, {Component} from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import RowAtm from "../../../atomic/Row/Row";
import Container from "@material-ui/core/Container";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import FooterAtm from "../../../atomic/Footer/Footer";
import Menu from "../../Global/Layout/Header/Submenu/General";
import {getPropertyInfo,updatePropertyGeneralInformation} from "../../../../redux/actions/generalInformationActions";
import {getLanguages} from "../../../../redux/actions/AlllanguageActions";
import {connect}  from "react-redux";
import {Animated} from "react-animated-css";
import GenerelInformationForm from "./components/GeneralInformationForm";
import {history} from "../../../../utils/history";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {CONTENT, CONTENT_COMMUNICATION} from "../../../../constants/appUrls.constants";
let t=null

class Index extends Component {

    constructor(props){
        super(props);
        this.headerStatusShow = this.headerStatusShow.bind(this)
        this.state = {
            statusHeader: false,
            redirect: false,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        this.props.getPropertyInfo();
        this.props.getLanguages();
    }

    headerStatusShow = () =>{

        return  <PageHeaderSaveAtm
            saveText="Save"
            backText={ t('btn-dashboard') }
            backButton={CONTENT}
            text={ t('enw-general-title') }
            saveButtonDisabled={this.props.buttonPassiveStatus}
            processStatus={this.props.updateFetching}
            processResponse={this.props.updateData}
            errorStatus={Object.keys(this.props.error).length > 0 ? this.props.error : false}
            successHeaderTime={true}
        />

    }

    saveNextButton = () => {
        this.setState({
            redirect: true,
        })
    };

    renderRedirect = () => {
        if (this.props.generalInformationReducer.updateData === 200) {
            if (this.state.redirect) {
                /*history.push(CONTENT_COMMUNICATION)*/
                setTimeout(() => {
                    //return <Redirect to="/communication" />
                    history.push(CONTENT_COMMUNICATION)
                }, 1000);
            }
        }
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-general-information-pagetitle') } />
                {this.headerStatusShow()}
                <Menu infoColClass="active-menu-bg"
                />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <RowAtm>

                                {
                                    !this.props.fetching &&
                                    <GenerelInformationForm  property_info={this.props.property_info}
                                                             additional_info={this.props.additional_info}
                                                             property_chains={this.props.property_chains}
                                                             property_type={this.props.property_type}
                                                             countries={ this.props.countries }
                                                             property_language_spoken={ this.props.property_language_spoken }
                                                             general_timezone={ this.props.general_timezone }
                                                             minimum_age_policy={ this.props.minimum_age_policies }
                                                             languages={ this.props.languages }
                                                             updateInformation={this.props.updatePropertyGeneralInformation}
                                    />
                                }
                            </RowAtm>
                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextText={ t('btn-next') }
                    nextDisabled={this.props.buttonPassiveStatus}
                    backDisabled={true}
                    iconLeftClassName="fas fa-arrow-left"
                    form='general-form'
                    onClick={this.saveNextButton}
                />
                {this.renderRedirect()}
            </>

        );
    }
}

const mapStateToProps = (props) => {

    return{

        //Processing Props
        fetching : props.generalInformationReducer.fetching,
        buttonPassiveStatus : props.generalInformationReducer.buttonPassiveStatus,
        updateFetching : props.generalInformationReducer.updateFetching,
        updateData : props.generalInformationReducer.updateData,
        error : props.generalInformationReducer.error,
        // page Data
        property_info : props.generalInformationReducer.property_info,
        additional_info : props.generalInformationReducer.additional_info,
        countries : props.generalInformationReducer.countries,
        general_timezone : props.generalInformationReducer.general_timezone,
        property_language_spoken : props.generalInformationReducer.property_language_spoken,
        property_type   : props.generalInformationReducer.property_type,
        property_chains : props.generalInformationReducer.property_chains,
        minimum_age_policies : props.generalInformationReducer.minimum_age_policies,
        generalInformationReducer : props.generalInformationReducer,
        languages : props.allLanguagesReducer,
    }
};

const mapDispatchToProps = {
    getPropertyInfo,
    getLanguages,
    updatePropertyGeneralInformation
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Index));
