import React, {Component} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import queryString from 'query-string';
import {getPriceOffer, storePriceOffer} from "../../../../redux/actions/Offer/PriceOffer/priceOfferAction";

import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import PageHeaderSaveAtm from "../../../atomic/PageHeader/PageHeaderSave";
import PriceDateRange from "./components/PriceDateRange";
import {OFFER, OFFER_EDIT_SHORT, OFFER_LIST} from "../../../../constants/appUrls.constants";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import Rooms from "./components/Rooms"
import {constructListOfIntervals} from "../../../../utils";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal";
import Loading from "../../../molecules/Loading/Loading";
import OfferPriceStatus from "./components/OfferPriceStatus";

class OfferPrice extends Component {

    constructor(props) {
        super(props);
        let queryParams = queryString.parse(this.props.location.search);

        this.state = {
            offerId : queryParams.id,
            allDays : constructListOfIntervals(queryParams.start_date, queryParams.end_date,'day'),
            start_date : queryParams.start_date,
            end_date : queryParams.end_date,
        };
    }

    fechingOfferPrice  = () => {

        if ( this.state.offerId !== undefined || this.state.offerId !== '' )
        {
            this.props.getPriceOffer( { offer_id : this.state.offerId } );
        }
        else{
            this.props.history.replace( OFFER_LIST );
        }
    }

    componentDidMount() {
        this.fechingOfferPrice();
    }

    checkDate = () => {
        if ( !Array.isArray(this.state.allDays) ) {
            let message = this.state.allDays
            return  <ErrorModal status={true} message={message} redirectUrl={OFFER_LIST}/>
        }
    }

    dateRangeChange = (startDate, endDate) => {
        let changeDate = constructListOfIntervals(startDate, endDate,'day');
        this.setState({
            allDays : changeDate,
            start_date : startDate,
            end_date : endDate
        })
    };

    render() {
        const {t ,offerPriceData} = this.props;
        const {allDays} = this.state;

        return (
            <>
                <PageTitle title={ t('enw-offer-price-page_title') } />
                <PageHeaderSaveAtm backButton={OFFER_EDIT_SHORT + this.state.offerId} backButtonName={t("enw-edit_offer-title")} text={ t('enw-offer-price-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>

                    {
                        this.checkDate()
                    }
                        <Container maxWidth="xl">
                            {
                                this.props.priceOfferReducer.fetching  ?
                                    (
                                        <Loading />
                                    ) :(
                                        <div className="inventory-container-width offer-price-page">
                                            <ColAtm>
                                                <PriceDateRange allDays={allDays}
                                                                dateRangeChange={this.dateRangeChange}
                                                                startDate={this.state.start_date}
                                                                endDate={this.state.end_date}
                                                />
                                            </ColAtm>
                                            <ColAtm>
                                                <Rooms  roomPrice={offerPriceData.room_price}
                                                        currency={offerPriceData.currency}
                                                        allDays={allDays}
                                                        dateRangeChange={this.dateRangeChange}
                                                        storePriceOffer={this.props.storePriceOffer}
                                                        startDate={this.state.start_date}
                                                        endDate={this.state.end_date}
                                                        offerId={this.state.offerId}
                                                />
                                            </ColAtm>
                                            <OfferPriceStatus updatePriceOfferReducer={this.props.updatePriceOfferReducer} />
                                        </div>
                                    )
                            }
                        </Container>
                </Animated>
            </>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        priceOfferReducer : props.priceOfferReducer,
        offerPriceData : props.priceOfferReducer && Object.keys(props.priceOfferReducer.offerPrice).length > 0 ?  props.priceOfferReducer.offerPrice : {},
        updatePriceOfferReducer : props.priceOfferReducer
    }
};

const mapDispatchToProps ={
    getPriceOffer,
    storePriceOffer
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(OfferPrice));
