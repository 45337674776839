import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import BlockAtm from "../../../../../atomic/Block/Block";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import ChannelListModal from "./ChannelListModal";
import {history} from "../../../../../../utils/history";

const ChannelList = (props) => {
    const {channelList} = props;

    const handleClickOpen = (e, id) => {

        history.push(`/app/network/channel-information/${id}`)
    };

    return(
        <Grid container spacing={3}>
            {
                Object.values(channelList).map( channel => (
                    <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6}>
                        {
                            (channel.has_children && channel.is_selected) &&
                            <ChannelListModal
                                channel={channel}
                            />
                        }
                        <BlockAtm className="offer-fact-border-box fact-border-box channel-group-box" onClick={(e) => handleClickOpen(e, channel.id)}>
                            <label htmlFor={"id-" + channel.id}>
                                <label style={{position: 'relative'}}
                                       htmlFor={"id-" + channel.id}>
                                    <div style={{backgroundImage: `url(`+ channel.logoUrl +`)`}} className={channel.is_selected ? "channel-logo-bg": channel.is_pending ? "channel-logo-bg channel-grayscale-pending-box" : "channel-logo-bg channel-grayscale-box"} >
                                        <i className={channel.is_selected ? "channel-active-icon fad fa-dot-circle green" : channel.is_pending && "channel-active-icon fad fa-dot-circle blue"} />
                                    </div>
                                    <BlockAtm className={channel.is_selected ? "selected-channel-block" : "de-select-channel"}>
                                        <OverFlowText>
                                            {channel.name}
                                        </OverFlowText>
                                    </BlockAtm>
                                </label>
                            </label>
                        </BlockAtm>
                    </ColAtm>
                ))
            }
        </Grid>
    )
}
export default ChannelList;
