import {facilityContactConstants} from "../../constants/Content/facilitycontact.constans";

const initialState = {
    fetching : false,
    buttonPassiveStatus:false,
    updateFetching:false,
    updateData:null,
    error : {},
    property_contact:{},
    social_media_addresses: {},
    get_property: {},
    country_code: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case facilityContactConstants.FACILITY_CONTACT_PENDING:
            return {
                ...state,
                fetching: true,
                buttonPassiveStatus:true,
                updateData:null,
                country_code: null,
            };
        case facilityContactConstants.FACILITY_CONTACT_FULFILLED:
            return {
                ...state,
                fetching: false,
                buttonPassiveStatus: false,
                property_contact: action.payload.property_contact,
                get_property: action.payload.get_property,
                social_media_addresses: action.payload.property_contact.social_media_addresses,
                country_code: action.payload.country_code,
            };
        case facilityContactConstants.FACILITY_CONTACT_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                buttonPassiveStatus: false
            };
        // Update
        case facilityContactConstants.UPDATE_FACILITY_CONTACT_PENDING:
            return {
                ...state,
                updateFetching: true,
                buttonPassiveStatus: true,
                updateData:null,
            };
        case facilityContactConstants.UPDATE_FACILITY_CONTACT_FULFILLED:
            return {
                ...state,
                updateFetching: false,
                buttonPassiveStatus: false,
                updateData: action.payload.status,
            };
        case facilityContactConstants.UPDATE_FACILITY_CONTACT_REJECTED:
            return {
                ...state,
                updateFetching: false,
                buttonPassiveStatus: false,
                error: action.payload
            };
        default:
            return state
    }
}
