import {propertyRoomAndRateList } from "../../../../constants/Network/RoomAndRates/roomAndRate.constants";

const initialState = {
    fetching : false,
    roomInfo : {},
    updateStatus:null,
    updateFetching:false,
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case propertyRoomAndRateList.GET_EDIT_ROOM_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case propertyRoomAndRateList.GET_EDIT_ROOM_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomInfo: action.payload
            };
        case propertyRoomAndRateList.GET_EDIT_ROOM_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        default:
            return state
    }
}
