import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import { GET_FACT_lIST,UPDATE_FACT_MAPPING } from "../../../../constants/api.constants";


export function getFactList(sub_category_id) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                sub_category_id : sub_category_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_FACT_LIST',
            payload: axios.post(GET_FACT_lIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateFactMapping(params) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                property_fact:params.property_fact
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_FACT_MAPPING',
            payload: axios.post(UPDATE_FACT_MAPPING, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updateFactClearState() {

    return dispatch => {
        dispatch({
            type: 'UPDATE_FACT_MAPPING_CLEAR',
        })
    }
}

