import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Link} from "react-router-dom";
import {NETWORK_RESERVATION_DETAIL_SHORT, PAYMENT_TRANSACTION_DETAIL_SHORT} from "../../../../constants/appUrls.constants";
import {isDefined} from "../../../../utils";
import ExportTransactionListToExcel from "./ExportTransactionListToExcel";

export default function PaymentBookingList(props) {
    const { t } = useTranslation();
    const [dataForExcel , setDataForExcel] = useState([]);

    const columns = [
        t('enw-input-day_of_transaction'),
        t("Transaction Type"),
        t('enw-reservation-detail-order_id'),
        t('enw-reservation-detail-transaction_code'),
        t('enw-reservation-detail-bank_name'),
        t('be-search-card_number-title'),
        t('enw-input-fine'),
        t('enw-offer_list-column-status'),
        t('enw-reservation-table-actions')
    ];


    const {reservationList} = props;
    const [reservations , setReservations] = useState([]);


    useEffect( () => {
        if(reservationList && reservationList.transactions &&  Object.keys(reservationList.transactions).length > 0){
            let tempData = [];
            let tempExcel = [];
            reservationList.transactions.map((transaction) => {
                tempData.push([
                    moment(transaction.created_at).format("DD-MM-YYYY HH:mm:ss"),
                    transaction.transaction_type === "LNK" ?  transaction.transaction_type + " - Payment Link" : transaction.transaction_type + " - Booking",
                    transaction.order_id,
                    transaction.code,
                    <p> {transaction.bank_name}
                        {transaction.installment > 0 && <span> ({transaction.installment + " " + t('enw-payment-installment')})</span>}
                    </p>,
                    transaction.credit_card_number,
                    <p>
                        {
                            transaction.exchange_rate === 1 ?
                                <>{ transaction.amount } { transaction.currency }</> :
                                <>{ transaction.amount } { transaction.currency } ({transaction.base_amount} { transaction.base_currency })</>
                        }
                    </p>,

                    <p className={
                        transaction.status === 0 ? "red" :
                            transaction.status === 1 ? "green" :
                                transaction.status === 3 ? "orange" :
                                    transaction.status === 4 ? "purple" :
                                        "blue"
                    }>
                        {t(transaction.status_language_key)}
                    </p>,
                    transaction.transaction_type === "BKG" ?
                        isDefined(transaction.booking_detail) && isDefined(transaction.booking_detail[0]) &&
                        <Link className="offer-list-button"
                              to={NETWORK_RESERVATION_DETAIL_SHORT +`${isDefined(transaction.booking_detail) && transaction.booking_detail[0].id}`} target="_blank">
                            <i className={"fas fa-eye"} />
                        </Link>
                        :
                        <Link className="offer-list-button"
                              to={PAYMENT_TRANSACTION_DETAIL_SHORT + transaction.id}>
                            <i className={"fas fa-eye"} />
                        </Link>
                ])

                tempExcel.push({
                    transactionDate: moment(transaction.created_at).format("DD-MM-YYYY HH:mm:ss"),
                    transactionCode: transaction.code,
                    transactionType : transaction.transaction_type === "LNK" ?  transaction.transaction_type + " - " + t('enw-payment-transaction_payment-link') : transaction.transaction_type + " - " + t('enw-payment-transaction_booking'),
                    orderId : transaction.order_id,
                    bankName : transaction.bank_name,
                    installment : transaction.installment,
                    creditCardNumber : transaction.credit_card_number,
                    status : t(transaction.status_language_key),
                    amount: transaction.amount + transaction.currency + " " + ("(" + transaction.base_amount + transaction.base_currency + ")")
                })
            });
            setReservations(tempData);
            setDataForExcel(tempExcel)
        }
    },[reservationList])

    const options = {
        filter: false,
        selectableRowsOnClick: false,
        selectableRows: false,
        searchOpen: true,
        searchPlaceholder: t('btn-search'),
        print: false,
        download: false,
        textLabels: {
            body: {
                noMatch: t('datatable-body-nomatch'),
                toolTip: t('datatable-body-tooltip'),
            },
            pagination: {
                next: t('datatable-pagination-next'),
                previous: t('datatable-pagination-previous'),
                rowsPerPage: t('datatable-pagination-rowsperpage'),
                displayRows: t('datatable-pagination-of'),
            },
            toolbar: {
                search: t('datatable-toolbar-search'),
                downloadCsv: t('datatable-toolbar-download_excel'),
                print: t('datatable-toolbar-print'),
                viewColumns: t('datatable-toolbar-view_columns'),
                filterTable: t('datatable-toolbar-filter_table'),
            },
            filter: {
                all: t('datatable-filter-all'),
                title: t('datatable-filter-title'),
                reset: t('datatable-filter-reset'),
            },
            viewColumns: {
                title: t('datatable-filter-view_columns-title'),
                titleAria: t('datatable-filter-view_columns-title_aria'),
            },
            selectedRows: {
                text: t('datatable-selected_rows-text'),
                delete: t('datatable-selected_rows-delete'),
                deleteAria: t('datatable-selected_rows-delete-aria')
            }
        },
        customToolbar: () => {
            return ( <ExportTransactionListToExcel data={dataForExcel} /> );
        }
    };

    return (
        <MUIDataTable
            title={ t('enw-payment-transaction-list-title') }
            data={reservations}
            columns={columns}
            options={options}
        />
    )
}

