import React from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";

let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    menuLinks: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#e46a6b',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#e46a6b'
        },
    }
});

class FactMenuItems extends React.Component{
    constructor(props) {
        super(props);
        t = props.t;
    }


    menuList = () => {
        let MenuItems = '';
        const { classes, pageId } = this.props;

        if(this.props.getMenusItems !== 0 && this.props.getMenusItems.menu !== 0 && this.props.getMenusItems.menu !== undefined && this.props.getMenusItems.menu !== null  && this.props.getMenusItems.menu !== [] ){
            MenuItems =  this.props.getMenusItems.menu.map( (data) => (
                <ColAtm className={`${pageId === data.id && "active-menu-bg"} fact-menu-width-${this.props.getMenusItems.menu.length}`}>
                    <Link to={ data.url } className={classes.aLink}>
                        { t(data.language_key) }
                    </Link>
                </ColAtm>

            ))
        }
        return MenuItems;
    }

    render() {
        return (
            <>
                {this.menuList()}
            </>
        )
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (FactMenuItems);
