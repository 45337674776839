import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import { ROOM_FACT_LIST, ROOM_FACT_UPDATE,ROOM_FACT_FEATURE_UPDATE} from "../../../../constants/api.constants";

export function RoomFactListGet(roomId) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : roomId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_LIST',
            payload: axios.post(ROOM_FACT_LIST, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function RoomFactUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : param.room_id,
                "property_room_fact": param.property_room_fact
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_UPDATE',
            payload: axios.post(ROOM_FACT_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data?.status )
        })
    }
}

export function RoomFactFeatureUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : param.room_id,
                "fact_id": param.fact_id,
                "is_feature": param.is_feature
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_FEATURE_UPDATE',
            payload: axios.post(ROOM_FACT_FEATURE_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data?.status )
        })
    }
}

export function RoomFactRecommendedUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : param.room_id,
                "property_room_fact": param.property_room_fact
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_RECOMMENDED_UPDATE',
            payload: axios.post(ROOM_FACT_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data?.status )
        })
    }
}

export function RoomFactRecommendedFeatureUpdate(param) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "room_id" : param.room_id,
                "fact_id": param.fact_id,
                "is_feature": param.is_feature
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_RECOMMENDED_FEATURE_UPDATE',
            payload: axios.post(ROOM_FACT_FEATURE_UPDATE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data?.status )
        })
    }
}



export function roomFactUpdateClear() {
    return dispatch => {
        dispatch({
            type: 'ROOM_FACT_UPDATE_CLEAR'
        })
    }
}