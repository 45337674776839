import React from 'react';
import Container from "@material-ui/core/Container";
import ColAtm from "../../../../atomic/Col/Col";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import FactBoxs from "./CouponBoxes";
import FactHeader from "./CouponHeader";
import CouponNewModal from "./CouponNewModal";
import LabelAtm from "../../../../atomic/Label/Label";
import {Animated} from "react-animated-css";

let t=null;

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    menuLinks: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#eaeaea'
        },
    }
});

class Coupons extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            accept_status : '',
            redirect: false,
            showSuccessHeader: false,
            checkedStatus: true
        }
        t = props.t;
    }


    render() {

        return (
            <>
                <FactHeader this_main_category={this.props.this_main_category} />
                <Container maxWidth="xl">
                    {
                        !this.props.getCouponsFetching &&
                        <div className="container-width fact-list">
                            {
                                this.props.CouponsListData.length > 0 ?
                                    <>
                                        <CouponNewModal pageId={this.props.pageId} />
                                        <ColAtm>
                                            <FactBoxs
                                                get_coupons={this.props.CouponsListData}
                                                fetching={this.props.fetching}
                                                pageId={this.props.pageId}
                                            />
                                        </ColAtm>
                                    </>
                                    :
                                    <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                        <Container maxWidth="xl">
                                            <div className="container-width">
                                                <ColAtm>
                                                    <Container maxWidth="xl">
                                                        <div className="offer-container-width">
                                                            <ColAtm>
                                                                <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                                    <ColAtm>
                                                                        <div className="m-auto first-create-room first-coupon-btn">
                                                                <span>
                                                                   <CouponNewModal pageId={this.props.pageId} />
                                                                </span>
                                                                        </div>
                                                                    </ColAtm>
                                                                    <LabelAtm labelClass="one-page-title" text={ t('enw-create_coupon-subtitle') } />
                                                                    <LabelAtm labelClass="one-page-subtitle" text={ t('enw-create_coupon-desc') } />
                                                                </ColAtm>
                                                            </ColAtm>
                                                        </div>
                                                    </Container>
                                                </ColAtm>
                                            </div>
                                        </Container>
                                    </Animated>
                            }

                        </div>
                    }
                </Container>
            </>
        )
    }
}

export default compose(withTranslation('common'), withStyles(styles, { withTheme: true })) (Coupons);
