export const popupConstants = {
    POPUP_LIST_PENDING: 'POPUP_LIST_PENDING',
    POPUP_LIST_FULFILLED: 'POPUP_LIST_FULFILLED',
    POPUP_LIST_REJECTED: 'POPUP_LIST_REJECTED',

    POPUP_IMAGE_UPLOAD_PENDING: 'POPUP_IMAGE_UPLOAD_PENDING',
    POPUP_IMAGE_UPLOAD_FULFILLED: 'POPUP_IMAGE_UPLOAD_FULFILLED',
    POPUP_IMAGE_UPLOAD_REJECTED: 'POPUP_IMAGE_UPLOAD_REJECTED',

    CREATE_POPUP_PENDING: 'CREATE_POPUP_PENDING',
    CREATE_POPUP_FULFILLED: 'CREATE_POPUP_FULFILLED',
    CREATE_POPUP_REJECTED: 'CREATE_POPUP_REJECTED',
    CREATE_POPUP_CLEAR: 'CREATE_POPUP_CLEAR',

    UPDATE_POPUP_PENDING: 'UPDATE_POPUP_PENDING',
    UPDATE_POPUP_FULFILLED: 'UPDATE_POPUP_FULFILLED',
    UPDATE_POPUP_REJECTED: 'UPDATE_POPUP_REJECTED',
    UPDATE_POPUP_CLEAR: 'UPDATE_POPUP_CLEAR',

    CREATE_IMAGE_POPUP_PENDING: 'CREATE_IMAGE_POPUP_PENDING',
    CREATE_IMAGE_POPUP_FULFILLED: 'CREATE_IMAGE_POPUP_FULFILLED',
    CREATE_IMAGE_POPUP_REJECTED: 'CREATE_IMAGE_POPUP_REJECTED',
    CREATE_IMAGE_POPUP_CLEAR: 'CREATE_IMAGE_POPUP_CLEAR',
};
