import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {getProperty, isDefined} from "../../../../../../utils";
import {Grid} from "@material-ui/core";
import {updateChannelGroupChannels} from "../../../../../../redux/actions/Network/Channel/channelGroup.actions";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import LabelAtm from "../../../../../atomic/Label/Label";
import RoomRateChannels from "../../../../../molecules/RoomRateChannels/RoomRateChannels";
import Collapse from '@material-ui/core/Collapse';

export default function UpdateChannelGroupModalForm(props) {

    const { t } = useTranslation();

    const {singleGetMapping} = props;

    const updateData = useSelector(props => props.channelGroupReducers?.update_mapping)
    const updateError = useSelector(props => props.channelGroupReducers?.update_error)

    const selectedChannels = isDefined(singleGetMapping) && isDefined(singleGetMapping.channel_group_active_channels)
        && Object.values(singleGetMapping.channel_group_active_channels).filter(item => item.in_group === true)
    let selectedChannelsIds = isDefined(selectedChannels) && selectedChannels && selectedChannels.map(choice => (choice.id));

    const [name, setName] = useState(singleGetMapping.name);
    const [message, setMessage] = useState(false);
    const [tempChannelMapping, setTempChannelMapping] = useState(selectedChannelsIds)
    const dispatch = useDispatch();

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            name: name,
            group_id: singleGetMapping.id,
            channels: tempChannelMapping,
        }
        dispatch(updateChannelGroupChannels(params))

    };

    const handleChangeName = ({target}) => {
        setName(target.value);
    };

    const handleChange = e => {

        if (e.target.checked === true) {
            setTempChannelMapping([...tempChannelMapping, Number(e.target.value)]);
        } else {
            const selectedAcc = tempChannelMapping.filter(a => {
                if (a === Number(e.target.value)) return false;
                return true;
            });
            setTempChannelMapping([...selectedAcc]);
        }
    };

    useEffect( () => {
        if(updateData.status === 200) {
            setMessage(true)

            const timeout = setTimeout(() => {
                setMessage(false);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    },[updateData])


    return (
        <div className="w-100">
            <Fragment>
                <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                    <Grid container className="channel-new-group-title pt-20">
                        <Grid item xs={12} className="mb-12">
                            {isDefined(updateError)
                            && isDefined(updateError.response)
                            && <Alert severity="error">{updateError.response.data.message}</Alert>}

                            <Collapse in={updateData.status === 200 && message}>
                                <Alert>
                                    {t('enw-notification_saved_successfully')}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <LabelAtm variant="h2" text={ t('enw-channels-group_name') } />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-channels-group_name-desc') }
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextValidator
                                name="name"
                                type="text"
                                className="w-100"
                                variant="outlined"
                                label={ "Name" }
                                value={name}
                                onChange={handleChangeName}
                                autoComplete="off"
                                validators={['required']}
                                errorMessages={[t("enw-notification_this_is_required")]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="bg-gray p-12 pt-32 radius-8">
                        <Grid xs={12} className="channel-new-group-title">
                            <LabelAtm variant="h2" text={ t('enw-channels-group_channel-list') } />
                            <LabelAtm variant="p" labelClass="f-w-500"
                                      text={ t('enw-channels-group_channel-list-desc') }
                            />
                        </Grid>
                        {isDefined(singleGetMapping) &&
                        isDefined(singleGetMapping.channel_group_active_channels) &&
                        Object.values(singleGetMapping.channel_group_active_channels).map((arr) => (
                            <RoomRateChannels
                                checkboxId={arr.id}
                                className="fact-box"
                                colClassname="offer-fact-col room-rate-channel-list p-0-24"
                                blockClass="channel-border-box offer-fact-border-box fact-border-box mb-16"
                                src={`/assets/img/brands/${arr.id}.png`}
                                checked={
                                    tempChannelMapping.lastIndexOf(Number(arr.id)) >= 0 ? true : false
                                }
                                value={arr.id}
                                sm={4}
                                factName={arr.name}
                                text={arr.name}
                                labelClass="fact-name"
                                htmlFor={arr.id}
                                onChange={e => handleChange(e)}
                            />
                        ))}
                    </Grid>
                    <ColAtm className="mt-32" md={12}>
                        <ButtonAtm
                            className="m-auto room-rate-modal-save bg-red"
                            type="submit"
                            buttontext={ t('btn-save') }
                            color="primary"
                        />
                    </ColAtm>
                </ValidatorForm>
            </Fragment>
        </div>
    );
}
