import React, {Component}       from 'react';
import {compose}                from "redux";
import {connect}                from "react-redux";
import {withTranslation}        from "react-i18next";
import {isProperty, setPropertyId, isDefined, userLogout, setPropertyName} from "../../../../../utils/index";
import {history} from "../../../../../utils/history";
import {CONTENT, CONTENT_INFORMATION, LOGIN, MOBILE_LOGIN_SHORT} from "../../../../../constants/appUrls.constants"
import {getPropertyList}         from '../../../../../redux/actions/propertyActions'
import {onCreatePropertySubmit} from "../../../../../redux/actions/createPropertyActions";
import PropertyButtons          from "../../../../organisms/PropertyButtons/PropertyButtons";
import ColAtm                   from "../../../../atomic/Col/Col";
import BlockAtm from "../../../../atomic/Block/Block";
import ButtonAtm from "../../../../atomic/Button/Button";
import { Redirect } from 'react-router-dom';
import {Grid} from "@material-ui/core";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import LabelAtm from "../../../../atomic/Label/Label";
import {logout,clearState} from "../../../../../redux/actions/userActions";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

class propertySelect extends Component {

    constructor(props){
        super(props);
        this.state = {
            redirect: false,
            open: false
        };
    }

    handleClickOpen = () => {
        this.setState({open: true})
    };

    handleClose = () => {
        this.setState({open: false})
    };

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        const {getPropertyList} = this.props;
        getPropertyList();
        this.setState({ redirect: false });

    }
    handleClick = (e, propertyName) =>{
        e.preventDefault();
        let id = e.target.value;
        if( isDefined(id) ) {
            setPropertyId(id);
            setPropertyName(propertyName);
            isProperty() ? history.push(CONTENT) : history.push("/")
        }
    };

    handleCreateProperty = (e) => {
        e.preventDefault();
        this.props.onCreatePropertySubmit(this.state);
        this.setState({ redirect: true });
    };
    selectPropertyID = () => {
        let selectProps = '';
        const listCount = this.props.property_list;
        const {property_list} = this.props;
        if(property_list.length === 0){
            return;
        }

        if(listCount.length === 1) {

            selectProps = this.props.property_list.map( (property, key) => (
                    <>
                        {localStorage.getItem("property") !== null ?
                            <ColAtm xs={6} sm={4} md={3} xl={3} key={key} value={property.id}>
                                <PropertyButtons
                                    blockClassName="property-box"
                                    value={property.id}
                                    text={property.name}
                                    tooltip={property.name}
                                    buttontext={property.name}
                                    labelClass="property-box__title"
                                    className="properties"
                                    onClick={(e) => this.handleClick(e, property.name)}
                                    style={property.default_photo === "/assets/img/placeholder.png" ?
                                        {
                                            backgroundImage: "url(" + property.default_photo +")",
                                            backgroundRepeat: 'no-repeat',
                                            backgroundColor: '#ccced5',
                                            backgroundPosition: 'center',
                                            backgroundSize: '38px',
                                            color: 'transparent',
                                            borderRadius: '0px',
                                            borderBottomLeftRadius: '8px',
                                            borderBottomRightRadius: '8px'
                                        }
                                        :
                                        {
                                            backgroundImage: "url(" + property.default_photo +")",
                                            backgroundRepeat: 'round',
                                            color: 'transparent',
                                            borderRadius: '0px',
                                            borderBottomLeftRadius: '8px',
                                            borderBottomRightRadius: '8px'
                                        }
                                    } />
                            </ColAtm>
                            :
                            <>
                                { setPropertyId(property.id) }
                                { setPropertyName(property.name) }
                                <Redirect to={CONTENT} />
                            </>
                        }
                    </>
                )
            );
            return selectProps;

        }
        else {
            selectProps = this.props.property_list.map( (property, key) => (
                    <>
                        { property !== null && (
                            <ColAtm xs={6} sm={4} md={3} xl={3} key={key} value={property.id}>
                                <PropertyButtons
                                    blockClassName="property-box"
                                    value={property.id}
                                    text={property.name}
                                    buttontext={property.name}
                                    tooltip={property.name}
                                    labelClass="property-box__title"
                                    className="properties"
                                    onClick={ (e) => this.handleClick(e, property.name)}
                                    style={property.default_photo === "/assets/img/placeholder.png" ?
                                        {
                                            backgroundImage: "url(" + property.default_photo +")",
                                            backgroundRepeat: 'no-repeat',
                                            backgroundColor: '#ccced5',
                                            backgroundPosition: 'center',
                                            backgroundSize: '38px',
                                            color: 'transparent',
                                            borderRadius: '0px',
                                            borderBottomLeftRadius: '8px',
                                            borderBottomRightRadius: '8px'
                                        }
                                        :
                                        {
                                            backgroundImage: "url(" + property.default_photo +")",
                                            backgroundRepeat: 'round',
                                            color: 'transparent',
                                            borderRadius: '0px',
                                            borderBottomLeftRadius: '8px',
                                            borderBottomRightRadius: '8px'
                                        }
                                    } />
                            </ColAtm>
                        )}

                    </>
                )
            );
            return selectProps;
        }
    };


    renderRedirect = () => {
        if (this.props.create_property.status === 200 && this.state.redirect) {
            localStorage.setItem('property', this.props.property_id);
            return <Redirect to={CONTENT_INFORMATION} />
        }
    };

    logOut = () => {
        const { logout } = this.props;
        logout();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {logoutStatus,clearState} = this.props
        if (prevProps.logoutStatus !== logoutStatus ){
            if(logoutStatus === 200){
                clearState()
                userLogout();
                if (isDefined(localStorage.getItem("mobile_login"))) {
                    history.push(MOBILE_LOGIN_SHORT + localStorage.getItem("mobile_login"));
                } else {
                    history.push(LOGIN);
                }
            }
        }
    }

    render() {
        const listCount = this.props.property_list;
        const { t } = this.props;
        const { open } = this.state;

        return (
            <>
                <PageTitle title={ t('enw-select_property-page_title') }  />
                <PageHeaderAtm exitText={ t('btn-exit') }  text={ t('enw-select_property-title') } onClick={this.handleClickOpen}/>
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="property-container">
                            <BlockAtm>
                                <ColAtm>
                                    <LabelAtm labelClass="property-label" text={ t('enw-select_property-desc') } />
                                </ColAtm>
                                <Grid
                                    container
                                    direction="row"
                                    justify={listCount.length < 2 ? "center" : "flex-start"}
                                    alignItems="center"
                                >
                                    {this.selectPropertyID()}
                                    <ColAtm xs={6} md={3}>
                                        <BlockAtm className="properties new-property" style={{height: '209px'}}>
                                            <ButtonAtm
                                                className="properties new-property-btn"
                                                startIcon={<i className="fas fa-plus" />}
                                                onClick={this.handleCreateProperty}
                                                buttontext={ t('btn-create_a_new_hotel') }
                                            />
                                            {this.renderRedirect()}
                                        </BlockAtm>
                                    </ColAtm>
                                </Grid>
                            </BlockAtm>
                        </div>
                    </Container>
                </Animated>
                <div>
                    <Dialog
                        open={open}
                        onClose={this.handleClose}
                        aria-labelledby="logout-modal"
                        className="logout-modal"
                        disableBackdropClick={true}
                    >
                        <DialogTitle id="logout-modal" className="red-bottom-border" onClose={this.handleClose}>{t('btn-exit')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="fs-18 f-w-600">
                                {t('enw-logout_exit-text')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ButtonAtm
                                onClick={this.handleClose}
                                className="room-rate-modal-save bg-green m-0-8"
                                type="submit"
                                buttontext={ t('btn-back') }
                                color="primary"
                            />
                            <ButtonAtm
                                onClick={this.logOut}
                                className="room-rate-modal-save bg-red m-0-8"
                                type="submit"
                                buttontext={ t('btn-exit') }
                                color="primary"
                            />
                        </DialogActions>
                    </Dialog>
                </div>
            </>
        );
    }
}
const mapStateToProps = (props) => {

    return{
        property_list : props.propertyReducer.property_list,
        create_property : props.createPropertyReducer.create_property,
        property_id : props.createPropertyReducer.property_id,
        logoutStatus : props.userReducer.userLogoutStatus
    }
};


const mapDispatchToProps = {
    onCreatePropertySubmit,
    getPropertyList,
    logout,
    clearState
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(propertySelect));
