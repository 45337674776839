import React from "react";
import {connect} from "react-redux";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {getLandingOffer} from "../../../../redux/actions/Offer/LandingOffer/landingOfferAction";
import {approveOfferSubmit} from "../../../../redux/actions/Offer/ApproveOffer/approveOfferActions";
import LandingOfferData from "./components/LandingOfferData";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {NoSsr} from "@material-ui/core";
import {OFFER_LIST} from "../../../../constants/appUrls.constants";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
let t=null;

class OfferPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            property_brand : null,
            offer_code : '',
        };
        t = props.t;
    }

    componentDidMount() {

        if ( this.props.match.params.id )
        {
            this.props.getLandingOffer( { offer_code : this.props.match.params.id } );
            this.setState({
                offer_code:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace('/offer');
        }
    }

    render() {
        return (
            <NoSsr>
                <PageTitle title={ t('enw-landing_offer-page_title') } offerBody={true} />
                {
                    localStorage.getItem("mobile_login") &&
                    <PageHeaderAtm backButton={OFFER_LIST} exitText={ t('btn-offer_list')} isOffer />
                }
                    {
                        !this.props.landingOfferReducer.fetching &&
                            <LandingOfferData
                                landingOffer = {this.props.landingOfferReducer}
                                approveOffer = {this.props.approveOfferReducer}
                                approveOfferSubmit = {this.props.approveOfferSubmit}
                            />
                    }
            </NoSsr>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        landingOfferReducer : props.landingOfferReducer,
        approveOfferReducer : props.approveOfferReducer,
    }
};

const mapDispatchToProps ={
    getLandingOffer,
    approveOfferSubmit
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(OfferPage));
