import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ColAtm from "../../../atomic/Col/Col";
import Dialog from "@material-ui/core/Dialog";
import {CircularProgress, Hidden, withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
import VisitorsCalendar from "./VisitorsChart";
import {WorldMap} from "react-svg-worldmap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {getAppLang, getProperty, getPropertyName, getUserName} from "../../../../utils";
import axios from "axios";
import {
    CHANNEL_FORECAST,
    DASHBOARD_PLUS,
    GUEST_DEMOGRAPHIC, TOP_CHANNEL,
    WEB_VISITOR
} from "../../../../constants/api.constants";
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import {TotalForecast} from "./TotalForecast";
import BookingChart from "./BookingChart";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Charts() {
    const dateFormat = 'YYYY-MM-DD';
    const appLanguage = localStorage.getItem("lng");
    const userName = getUserName();
    const propertyName = getPropertyName();
    moment.locale(appLanguage)
    const { t } = useTranslation();
    const [dashboardPlusData, setDashboardPlusData] = useState(false);
    const [webVisitorData, setWebVisitorData] = useState(false);
    const [guestDemographic, setGuestDemographic] = useState(false);
    const [topChannel, setTopChannel] = useState(false);
    const [channelForecast, setChannelForecast] = useState(false);
    const [focusedInput, setFocusedInput] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).add(-20, 'days').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState( moment(new Date()).format("YYYY-MM-DD"));
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (openModal && endDate) {
            getDashboardChartData();
        }
    }, [openModal, endDate]);

    function getDashboardChartData() {
        setIsLoading(true);

        const params = {
            params: {
                property_id : getProperty(),
                start_date : startDate,
                finish_date : endDate
            }
        }

        // Dashboard Plus
        axios.post(DASHBOARD_PLUS, params,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            setDashboardPlusData(result.data.data);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });

        // Web Visitor
        axios.post(WEB_VISITOR, params,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            setWebVisitorData(result.data.data);
        }).catch(function (error) {
            console.log(error);
        });

        // Guest Demographic
        axios.post(GUEST_DEMOGRAPHIC, params,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            setGuestDemographic(result.data.data);
        }).catch(function (error) {
            console.log(error);
        });

        // Top Channel
        axios.post(TOP_CHANNEL, params,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            setTopChannel(result.data.data);
        }).catch(function (error) {
            console.log(error);
        });

        // Top Channel
        axios.post(CHANNEL_FORECAST, params,{
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then( (result) => {
            setChannelForecast(result.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const dateRangeChange = (startDate, endDate) => {
        setEndDate(endDate)
        setStartDate(startDate)
    };

    const onDatesChange = ({startDate, endDate}) => {
        if (endDate != null || startDate != null) {
            if (focusedInput === "startDate") {
                let endDate = null;
                setStartDate(startDate);
                setEndDate(endDate);
                return
            }
            if (focusedInput == "endDate") {
                if( (endDate != null && startDate != null) ){
                    dateRangeChange(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
                }
            }
        }
    }

    const isOutsideRange = (day) => {
        if (focusedInput == "endDate") {
            return day.isAfter(moment(startDate, dateFormat).add(180, 'days')) || day.isBefore(startDate);
        }
        return false;
    }

    const onFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    const visitors = webVisitorData && webVisitorData?.country.map(data => {
        return data.formatted
    })

    const isEnwUser = localStorage.getItem("detailedInfo") &&
        localStorage.getItem("detailedInfo") !== "null" &&
        JSON.parse(localStorage.getItem("detailedInfo"))?.email?.indexOf("extranetwork.com");

    return (
        <>
            <img className="mb-30 min-h-31 m-auto c-pointer" src="/mini-logo.svg" alt="mini-logo" onClick={() => isEnwUser !== -1 && setOpenModal(true)} />
            <Dialog
                onClose={() => setOpenModal(false)}
                aria-labelledby="status"
                open={openModal}
                className="web-preview-dialog"
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogTitle className="red-bottom-border"  id="status" onClose={()=> setOpenModal(false)}>
                    { t('enw-dashboard_plus-title') }
                </DialogTitle>
                <DialogContent>
                    {
                        isLoading ?
                            <Grid className="d-flex h-100">
                                <CircularProgress color="secondary" className="m-auto" />
                            </Grid>
                            :
                            <Grid container className="mobile-offer-boxes-padding charts-container p-18">
                                <Grid container>
                                    <Grid xs={12} md={6}>
                                        <DateRangePicker
                                            onDatesChange={onDatesChange}
                                            onFocusChange={onFocusChange}
                                            focusedInput={focusedInput}
                                            startDate={moment(startDate).locale(appLanguage)}
                                            endDate={moment(endDate).locale(appLanguage)}
                                            displayFormat="MMM DD YYYY"
                                            isOutsideRange={isOutsideRange}
                                            minimumNights={0}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <p className="user-menu-name t-center"><span>{ t('enw-drawer_menu-title') }</span> {userName} <span>{propertyName}</span></p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Paper className="offer-boxes first-row-chart">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-moon blue fs-36 m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="fs-22 pt-18 m-auto f-w-bold">
                                                    {dashboardPlusData.lengthOfStay}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-length_of_stay') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Paper className="offer-boxes first-row-chart">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-bed orange fs-36 m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="fs-22 pt-18 m-auto f-w-bold">
                                                    {dashboardPlusData.lengthOfBooking}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-length_of_booking') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Paper className="offer-boxes first-row-chart">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-calendar-day green fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                    {dashboardPlusData.todayCheckin}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-today_checkin') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Paper className="offer-boxes first-row-chart">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-calendar-day red fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                    {dashboardPlusData.todayCheckout}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-today_checkout') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Paper className="offer-boxes" style={{width: "94%"}}>
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-money-bill-wave-alt purple fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                   {dashboardPlusData?.totalBooking?.totalRevenue} {dashboardPlusData?.totalBooking?.currencyCode}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-total_revenue') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Paper className="offer-boxes">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-concierge-bell red fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                   {dashboardPlusData?.totalBooking?.totalCount}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-total_booking_count') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Paper className="offer-boxes">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-coins black fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                   {dashboardPlusData?.averageDailyRate?.averageDailyRate} {dashboardPlusData?.averageDailyRate?.currencyCode}
                                               </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-average_daily_rate') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Paper className="offer-boxes">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-users aqua-blue fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                   <span className="fs-22 pt-18 m-auto f-w-bold">
                                        {dashboardPlusData?.totalPax?.totalPaxCount}
                                    </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-total_pax_count') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Paper className="offer-boxes">
                                            <ColAtm md={12} className="pt-26">
                                                <i className="fas fa-calculator yellow fs-36 bg-icon-purple m-auto b-r-50" />
                                            </ColAtm>
                                            <ColAtm md={12}>
                                               <span className="fs-22 pt-18 m-auto f-w-bold">
                                                   {dashboardPlusData?.totalPax?.averageRevenuePerPax} {dashboardPlusData?.totalPax?.currencyCode}
                                                </span>
                                            </ColAtm>
                                            <ColAtm md={12}>
                                                <span className="offer-total-title"> { t('enw-dashboard-average_revenue_per_pax') } </span>
                                            </ColAtm>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid md={12} className="charts-border mt-22">
                                    <BookingChart forecast={channelForecast?.forecast} channelForecast={channelForecast} />
                                </Grid>
                                <Grid container>
                                    <Grid xs={12} md={5}>
                                        <Grid className="charts-border mt-22 mr-26" style={{height: "96%"}}>
                                            <Grid container className="channel-chart-header">
                                                <Grid xs={4}>
                                                    { t('enw-chart-channel_name') }
                                                </Grid>
                                                <Grid xs={2}>
                                                    { t('enw-chart-channel_count') }
                                                </Grid>
                                                <Grid xs={4} className="t-right">
                                                    { t('enw-chart-channel_total') }
                                                </Grid>
                                                <Grid xs={2} className="t-right">
                                                    { t('enw-chart-channel_percentage') }
                                                </Grid>
                                            </Grid>
                                            <Grid container className="mt-12">
                                                {
                                                    topChannel && topChannel?.map((channel, index) => (
                                                        <Grid container key={index} className="channel-chart-list">
                                                            <Grid xs={4} className="chart-name">
                                                                {channel?.name}
                                                            </Grid>
                                                            <Grid xs={2} className="t-center">
                                                                {channel?.count}
                                                            </Grid>
                                                            <Grid xs={4} className="price">
                                                                {channel?.commonCurrencyTotal} <span className="f-w-500">{channel?.commonCurrencyCode}</span>
                                                            </Grid>
                                                            <Grid xs={2} className="t-right">
                                                                % {channel?.percentage}
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={7} className="charts-border mt-22">
                                        <TotalForecast forecastTotal={channelForecast?.forecastTotal} />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={6} className="mt-32">
                                    {
                                        webVisitorData &&
                                        <Grid container className="charts-border" style={{width: "96%"}}>
                                            <Grid item xs={12} md={7}>
                                                <Hidden smUp>
                                                    <WorldMap color="red" value-suffix="people" size={"sm"} data={visitors} />
                                                </Hidden>
                                                <Hidden xsDown>
                                                    <WorldMap color="red" value-suffix="people" size={"md"} data={visitors} />
                                                </Hidden>
                                            </Grid>
                                            <Grid item xs={12} md={5} className="citizens-count">
                                                { visitors.length > 0 ? visitors.map( (value, key) => {
                                                    return (<p key={key}>{ value.country_name } <span>{ value.value }</span></p>)
                                                }) : <span className="fs-56 m-auto f-w-bold">0</span>}
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid md={6} className="charts-border mt-32">
                                    <VisitorsCalendar guestDemographic={guestDemographic} />
                                </Grid>
                            </Grid>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}

