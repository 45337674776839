import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../../utils";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import BlockAtm from "../../../../../atomic/Block/Block";
import InputAtm from "../../../../../atomic/Input/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CreateInstallmentModal(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const {
        paymentMappingListData,
        paymentMapping,
        installmentData,
        paymentTypeId
    } = props;


    const [state, setState] = useState({
        installmentCode: [
            {
                installment: props.installmentData.installment,
                commission: null
            }]
    });

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setState( { ...state, bankCodes: [] })

        props.updatePaymentErrorClear();
        props.successPaymentClear();
    };

    const handleChangeBankCodes = (e,key) => {
        let values = state.installmentCode;
        values[0]['commission'] = e.target.value;
        setState({ ...state, values });
    }

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();


        const params = {
            property_payment_mapping_id : paymentMappingListData.id,
            has_installments : true,
            installments: state.installmentCode
        }
        props.PaymentInstallmentUpdate(params);
    };

    useEffect( () => {

        if (paymentMapping.updatePaymentInstallmentStatus === 200 && open) {
            props.GetPaymentType({ payment_type_id : paymentTypeId });
            handleClose();
        }
    },[paymentMapping.updatePaymentInstallmentStatus] )

    return (
        <ColAtm className="offer-fact-col" md={2} sm={4} xs={6} >
            <BlockAtm className="offer-fact-border-box fact-border-box" onClick={handleClickOpen}>
                <Grid item xs={12} className="cancellation-info-col">
                    <ColAtm className="channel-logo payment-create-btn">
                        <p>%</p>
                    </ColAtm>
                </Grid>
                <OverFlowText>{installmentData.installment + " "+t('enw-payment-installment')}</OverFlowText>
            </BlockAtm>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                className="installment-modal"
            >
                <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                        {paymentMapping.paymentGetList.name + " " + paymentMappingListData.code + " " + installmentData.installment + ". " + t('enw-payment-installment') }
                    </DialogTitle>
                    <DialogContent>
                        <Fragment>
                            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                {paymentMapping.updatePaymentInstallmentStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                                {isDefined(paymentMapping.errorUpdatePaymentInstallmentData) && <Alert severity="error">{paymentMapping.errorUpdatePaymentInstallmentData.response.data.message}</Alert>}
                                <Grid container className="payment-create-dialog" spacing={3}>
                                    <Grid item xs={12}>
                                        <InputAtm
                                            label={installmentData.installment+ "."+t('enw-payment-installment_commission')}
                                            autoComplete="off"
                                            name={installmentData.installment}
                                            value={installmentData.commission}
                                            onChange={(e) => handleChangeBankCodes(e, props.key)}
                                            variant="outlined"
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="start">
                                                        %
                                                    </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <ColAtm className="mt-32" md={12}>
                                    <ButtonAtm
                                        className="m-auto room-rate-modal-save bg-red"
                                        type="submit"
                                        buttontext={ t('btn-save') }
                                        color="primary"
                                    />
                                </ColAtm>
                            </ValidatorForm>
                        </Fragment>
                    </DialogContent>
                </Dialog>
            </Dialog>
        </ColAtm>
    );
}
