import React            from 'react';
import PropTypes        from 'prop-types';

import ImagedButton     from "../../molecules/ImagedButton/ImagedButton";

const PropertyButtons = props => (
    <ImagedButton className={props.className}
                  colStyle={props.colStyle}
                  blockClassName={props.blockClassName}
                  labelClass={props.labelClass}
                  onClick={props.onClick}
                  type={props.type}
                  htmlType={props.htmlType}
                  value={props.value}
                  tooltip={props.tooltip}
                  style={props.style}
                  text={props.text}
                  buttontext={props.buttontext}
    />
);

PropertyButtons.propTypes = {
    className:  PropTypes.string,
    colStyle:  PropTypes.string,
    blockClassName: PropTypes.string,
    tooltip: PropTypes.string,
    labelClass: PropTypes.string,
    onClick:    PropTypes.func,
    htmlType:   PropTypes.func,
    type:       PropTypes.string,
    buttontext: PropTypes.string,
    text:       PropTypes.any,
    value:      PropTypes.any,
    style:      PropTypes.any
};

export default PropertyButtons;
