import React, {useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation}    from "react-i18next";
import EditPlacesModal from "./EditPlace";
import {getPlaceCategoryFieldsUpdateClearStatus} from "../../../../../redux/actions/Content/Places/placesActions";
import {useDispatch} from "react-redux";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const EditPlace = (props) => {

    const [modalStatus, setModalStatus] = useState(true);
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClose = () =>  {

        if(modalStatus === true){

            setModalStatus(false);
            props.closePlace();
            dispatch(getPlaceCategoryFieldsUpdateClearStatus())
            setValue(0)
        }
    };

    return(
        <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="status" open={modalStatus}>
            <DialogTitle className="red-bottom-border"  id="status" onClose={handleClose}>
                { t('enw-content-place-edit_place-title') }
            </DialogTitle>
            <DialogContent>
                <EditPlacesModal place={props.place}
                                 value={value}
                                 setValue={setValue}
                                 closePlace={handleClose}/>
            </DialogContent>
        </Dialog>
    )
}

export default EditPlace
