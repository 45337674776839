import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    ADD_PROPERTY_EXECUTIVE, DELETE_PROPERTY_EXECUTIVE,
    GET_PROPERTY_EXECUTIVE,
    UPDATE_PROPERTY_EXECUTIVE
} from "../../../../constants/api.constants";

export function getExecutiveContact() {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_EXECUTIVE_CONTACT',
            payload: axios.post(GET_PROPERTY_EXECUTIVE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function addExecutiveContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "executive_type": [
                    {
                        "executive_type_id" : params.user_executive_type_id,
                        "name_surname"      : params.name_surname,
                        "extension"         : params.extension,
                        "email"             : params.email,
                        "phone"             : params.phone,
                        "mobile"            : params.mobile,
                        "fax"               : params.fax,
                    }
                ]
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'ADD_EXECUTIVE_CONTACT',
            payload: axios.post(ADD_PROPERTY_EXECUTIVE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data )
        })
    }
}

export function updateExecutiveContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "property_rate_for": "Content.General.FacilityContact",
                "executive_type": {

                    "1":{
                        "executive_type_id" : params.user_executive_type_id,
                        "id"                : params.id,
                        "name_surname"      : params.name_surname,
                        "extension"         : params.extension,
                        "email"             : params.email,
                        "phone"             : params.phone,
                        "mobile"            : params.mobile,
                        "fax"               : params.fax,
                    }
                }
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_EXECUTIVE_CONTACT',
            payload: axios.post(UPDATE_PROPERTY_EXECUTIVE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data )
        })
    }
}

export function deleteExecutiveContact(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty(),
                "property_executive_id": params.id,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'DELETE_EXECUTIVE_CONTACT',
            payload: axios.post(DELETE_PROPERTY_EXECUTIVE, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data )
        })
    }
}

export function clearExecutiveContact() {

    return dispatch => {
        dispatch({
            type: 'CLEAR_EXECUTIVE_CONTACT',
        })
    }

}