import { offerApproveConstants} from "../../../../constants/Offer/approveOffer.constants";

const initialState = {
    fetching :false,
    offerData: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offerApproveConstants.APPROVE_OFFER_PENDING:
            return {
                fetching:true,
                error: {},
            };
        case offerApproveConstants.APPROVE_OFFER_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerData: action.payload,
                error: {},
            };
        case offerApproveConstants.APPROVE_OFFER_REJECTED:
            return {
                ...state,
                offerData: [],
                error: action.payload,
                fetching : false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
