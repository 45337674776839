import {getCouponsConstants, syncCouponsConstants} from "../../../../constants/Network/coupons.constans";

const initialState = {
    getCouponsFetching : true,
    getCouponsError : {},
    getCouponsList: [],

    syncCouponsFetching : true,
    syncCouponsError : {},
    syncCoupons: [],
    syncCouponsStatus: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getCouponsConstants.NETWORK_COUPONS_GET_PENDING:
            return {
                ...state,
                getCouponsFetching : true,
                getCouponsError : {},
                getCouponsList: [],
                syncCouponsFetching : true,
                syncCouponsError : {},
                syncCoupons: [],
                syncCouponsStatus: false,
            };
        case getCouponsConstants.NETWORK_COUPONS_GET_FULFILLED:
            return {
                ...state,
                getCouponsFetching: false,
                getCouponsList: action.payload,
            };
        case getCouponsConstants.NETWORK_COUPONS_GET_REJECTED:
            return {
                ...state,
                getCouponsFetching: false,
                getCouponsError: action.payload,
                getCouponsList: [],
            };

        case syncCouponsConstants.NETWORK_COUPONS_SYNC_PENDING:
            return {
                ...state,
                syncCouponsFetching : true,
                syncCouponsError : {},
                syncCoupons: [],
                syncCouponsStatus: false,
            };
        case syncCouponsConstants.NETWORK_COUPONS_SYNC_FULFILLED:
            return {
                ...state,
                syncCouponsFetching: false,
                syncCoupons: action.payload.data,
                syncCouponsStatus: action.payload.status,
            };
        case syncCouponsConstants.NETWORK_COUPONS_SYNC_REJECTED:
            return {
                ...state,
                syncCouponsFetching: false,
                syncCouponsError: action.payload,
                syncCoupons: [],
            };

        case syncCouponsConstants.NETWORK_COUPONS_CLEAR_STATE:
            return {
                ...state,
                syncCouponsFetching : true,
                syncCouponsError : {},
                syncCoupons: [],
                syncCouponsStatus: false,
            };

        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
