import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProperty} from "../../../../../../utils";
import {updateChannelAdultPricingMapping} from "../../../../../../redux/actions/Network/Channel/channelPaxPricing";
import CheckboxAtm from "../../../../../atomic/Checkbox/Checkbox";

let lastChangeID = null;
const AdultCheckBox = (props) => {

    const { roomId, rateMappingId, adultId, adultName ,channelId, adultActionType, rateId } = props;
    const [isSelected , setIsSelected ] = useState(props.isSelected);
    const updateAdultPricingPolicyStatus = useSelector(props => props.updatePersonPricingReducer?.updateAdultPricingPolicyStatus)

    const dispatch = useDispatch();

    const handleUpdateAdult = (e) => {

        let params = {}
        if(isSelected){
            params = {
                property_id: getProperty(),
                channel_id:channelId,
                room_rate_channel_mapping_id : rateMappingId,
                pricing_policy_adult_id : adultId,
                is_selected: false
            }
            dispatch( updateChannelAdultPricingMapping( params ) )
            lastChangeID = roomId+'-'+rateId+'-'+rateMappingId+'-'+adultId+'-'+adultActionType+'-'+adultName;
        }else{
            params = {
                property_id: getProperty(),
                channel_id: channelId,
                room_rate_channel_mapping_id : rateMappingId,
                pricing_policy_adult_id : adultId,
                is_selected: true
            }
            dispatch( updateChannelAdultPricingMapping( params ) )
            lastChangeID = roomId+'-'+rateId+'-'+rateMappingId+'-'+adultId+'-'+adultActionType+'-'+adultName;
        }
    }


    const setState = () => {
        if(isSelected){
            setIsSelected(false);
        }else{
            setIsSelected(true)
        }
    }

    useEffect( () => {

        if ( (updateAdultPricingPolicyStatus === 200) && ( lastChangeID === roomId+'-'+rateId+'-'+rateMappingId+'-'+adultId+'-'+adultActionType+'-'+adultName) ){
            setState();
            lastChangeID=null;
        }

    },[updateAdultPricingPolicyStatus])

    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={"room" +roomId + "rateMapping" + rateMappingId + "rate" + rateId+ "adult" + adultId+adultName+'type'+adultActionType }
            value={adultId}
            checked={isSelected}
            onChange={ (e) => { handleUpdateAdult(e) } }
        />
    )

}

export default AdultCheckBox
