import React from "react";
import {connect} from "react-redux";
import ColAtm from "../../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";

import {PropertyRoomTypes, PropertyRoomAndRateList} from "../../../../../redux/actions/propertyRoomAndRateActions";

import AllRoomTypes from "./Filters/AllRoomTypes";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import RoomMenu from "../../../Global/Layout/Header/Submenu/Room";
import {Link} from "react-router-dom";
import FooterAtm from "../../../../atomic/Footer/Footer";
import Loading from "../../../../molecules/Loading/Loading";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../../utils/history";
import {CONTENT, CONTENT_ROOM_PHOTOS, CONTENT_ROOMS} from "../../../../../constants/appUrls.constants";
import {Grid} from "@material-ui/core";
import Room from "./List/Room"
import {roomFactUpdateClear} from "../../../../../redux/actions/Content/Room/roomFactAction";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";
let t=null;

class AmenitiesRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRoomTypeId : 0,
            selectedRoomRateId : 0,
            roomAddModalStatus : false,
            redirect: false,
            showSuccessHeader: false,
        };
        t = props.t;
    }

    onChangeRoomFilter = (selectedIndex) => {
        this.setState({
            selectedRoomTypeId : selectedIndex,
        });
    };

    componentDidMount() {
        this.props.PropertyRoomAndRateList();
        this.props.PropertyRoomTypes();
        this.props.roomFactUpdateClear();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    showMessage = () => {

        if(this.props.roomFactUpdateError?.response?.status === 400)
        {
            let message = this.props.roomFactUpdateError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }


    onClickNextButton = () => {
        this.setState({
            redirect: true,
            showSuccessHeader:true
        })

    };

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={CONTENT}
                               exitText={ t('btn-dashboard') }
                               text={ t('enw-room-title') }
                               showSuccessHeader={this.state.showSuccessHeader}
        />
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            /*setTimeout(() => {
                history.push(CONTENT_ROOM_PHOTOS)
            }, 2000);*/
            history.push(CONTENT_ROOM_PHOTOS)
        }
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-room-room_amenities-page_title') } />
                {
                    this.headerStatusShow()
                }
                <RoomMenu amenitiesRoomsColClass="active-menu-bg" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <Grid container spacing={2} className="photoButtons room-rate-filter-group">
                                <Grid item className="type-plan-buttons">
                                    { this.props.propertyRoomTypesFetching  && this.props.propertyRoomRateListFetching? (

                                        <Loading />

                                    ) : (
                                        <AllRoomTypes propertyRoomTypes={this.props.propertyRoomTypes }
                                                      onChangeRoomFilter={this.onChangeRoomFilter}
                                        />
                                     )}

                                </Grid>
                            </Grid>
                            <ColAtm className="room-rate-list-col">
                                { !this.props.propertyRoomRateListFetching && !this.props.propertyRoomTypesFetching &&
                                    <Room  rooms={this.props.propertyRoomRateList}
                                           selectedRoomTypeId={this.state.selectedRoomTypeId}
                                    />
                                }
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backTo={CONTENT_ROOMS}
                    component={Link}
                    nextComponent={Link}
                    buttonNextText={ t('btn-next') }
                    iconLeftClassName="fas fa-arrow-left"
                    backDisabled={this.state.showSuccessHeader}
                    nextDisabled={this.state.showSuccessHeader}
                    onClick={this.onClickNextButton}
                />
                {this.renderRedirect()}
                {this.showMessage()}
            </>
        )
    }
}
const mapStateToProps = (props) => {

    return{
        propertyRoomRateListFetching : props.propertyRoomAndRateReducers.propertyRoomRateListFetching,
        propertyRoomRateList : props.propertyRoomAndRateReducers.propertyRoomRateList,
        propertyRoomTypesFetching : props.propertyRoomAndRateReducers.propertyRoomTypesFetching,
        propertyRoomTypes : props.propertyRoomAndRateReducers.propertyRoomTypes,
        roomFactUpdateError :  props.roomFactUpdateReducer?.error,
    }
};

const mapDispatchToProps = {
    PropertyRoomAndRateList,
    PropertyRoomTypes,
    roomFactUpdateClear,
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(AmenitiesRoom));
