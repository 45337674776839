import {roomTypesList} from "../../constants/Network/RoomAndRates/roomType.constants";

const initialState = {
    fetching : false,
    status : false,
    allRoomTypes:[],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case roomTypesList.ROOM_TYPE_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case roomTypesList.ROOM_TYPE_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                allRoomTypes : action.payload
            };
        case roomTypesList.ROOM_TYPE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                status: false
            };
        default:
            return state
    }
}
