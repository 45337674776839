import { userConstants } from "../../../../constants/Public/user.constants";

const initialState = {
    fetching :false,
    updatePasswordStatus: false,
    updatePasswordData: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case userConstants.UPDATE_PASSWORD_PENDING:
            return {
                fetching : false,
                updatePasswordStatus:false,
                error:{},
            };
        case userConstants.UPDATE_PASSWORD_FULFILLED:
            return {
                ...state,
                fetching : false,
                updatePasswordStatus : true,
                updatePasswordData: action.payload,
            };
        case userConstants.UPDATE_PASSWORD_REJECTED:
            return {
                ...state,
                fetching : false,
                updatePasswordStatus:false,
                error: action.payload
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
