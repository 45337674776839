import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {Grid, MenuItem} from "@material-ui/core";
import ColAtm from "../../../../../atomic/Col/Col";
import LabelAtm from "../../../../../atomic/Label/Label";
import ButtonAtm from "../../../../../atomic/Button/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BulkUpdateDatTime from "./BulkUpdateDateTime";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import Rooms from "./Rooms";
import Rates from "./Rates";
import moment from "moment";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import ErrorModal from "../../../../../organisms/Modal/ErrorModal/ErrorModal";
import SuccessBulkUpdateModal from "../../../../../organisms/Modal/SuccessModal/SuccessBulkUpdateModal";
import {
    propertyBulkUpdate,
    propertyBulkUpdateClear
} from "../../../../../../redux/actions/Network/Inventory/BulkUpdateAction";
import {getPropertyInventory} from "../../../../../../redux/actions/Network/Inventory/inventoryAction";
import {constructListOfIntervals} from "../../../../../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


const BulkUpdateTabs = (props) => {

    const classes = useStyles();

    const {t} = useTranslation();
    const bulkUpdateRoomRate = useSelector(props => props.bulkUpdateReducer?.bulkUpdateData?.room_rates);
    let bulkUpdateOptions = useSelector(props => props.bulkUpdateReducer?.bulkUpdateData?.bulk_update_options);
    bulkUpdateOptions[6] = {
        availability_type_id: 1,
        language_key: "property_availability_type-room_availability",
        name: "Room Availability",
        type: "rate_by_rate",
    }

    const bulkUpdateData = useSelector(props => props.bulkUpdateOptionsReducer?.bulkUpdate);
    const bulkUpdateError = useSelector(props => props.bulkUpdateOptionsReducer?.error)
    const dispatch = useDispatch();

    const [state, setState] = React.useState({
        update_type: "",
        availability_type_id: "",
        value: "",
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(new Date()).add(1, 'days').format("YYYY-MM-DD")
    });
    const [roomRates, setRoomRates] = React.useState([]);
    const [options, setOptions] = React.useState('');
    const [includeDays, setIncludeDays] = useState([]);

    const handleChangeOptions = (e) => {
        if (e.target.value === undefined) return 0
        let selectedOption = bulkUpdateOptions.find((options, index) => index === e.target.value)
        let update_type = selectedOption?.type;
        let availability_type_id = selectedOption?.availability_type_id;

        setOptions(e.target.value)
        setState({...state, update_type, availability_type_id, value: ""});
        setRoomRates([]);
    };

    const handleChangeDate = (startDate, endDate) => {
        if (endDate == null) {
            setState({...state, start_date: startDate});
            //buraya end_get_data burada setstate edilecek!!!
        } else {
            setState({...state, end_date: endDate});
        }

    }
    const onChangeValue = (e) => {
        setState({...state, value: e.target.value})
    }
    const setChangeRoomRate = (param) => {
        setRoomRates(param)
    }
    const changeStopSell = (e) => {
        setState({...state, value: e.target.value})
    };

    const onClickIncludeDay = (e) => {
        if (e.target.value === undefined) return 0;

        if (includeDays.includes(e.target.value)) {
            let temp = includeDays;
            let index = temp.indexOf(e.target.value);
            temp.splice(index, 1);
            setIncludeDays(temp);
        } else {
            setIncludeDays([...includeDays, e.target.value])
        }
    }

    const onSubmit = () => {
        let params = {
            update_type: state.update_type,
            value: state.value,
            start_date: state.start_date,
            end_date: state.end_date,
            availability_type_id: state.availability_type_id,
            channel_id: props.channelId,
            room_rates: buildRoomRateMapping(roomRates),
            include_days: includeDays
        }
        dispatch(propertyBulkUpdate(params))
    }

    const buildRoomRateMapping = (param) => {
        let room_rates = [];

        Object.entries(param).map(([index, room]) => {

            let room_rate_mapping_id = [];
            Object.entries(room.room_rate_mapping).map(([index, rate]) => {
                rate.rate_selected && room_rate_mapping_id.push(rate.room_rate_mapping_id)
                rate.rate_value && room_rate_mapping_id.push({[rate.room_rate_mapping_id]: rate.rate_value})
            })
            if (room_rate_mapping_id.length > 0) {
                room_rates = [...room_rates, {room_id: room.id, room_rate_mapping_id: room_rate_mapping_id}]

            } else {
                if (room.room_selected) {
                    room_rates = [...room_rates, {room_id: room.id, room_rate_mapping_id: room_rate_mapping_id}]
                }
            }
        })

        return room_rates;
    }

    /*
    *  modalın  kapatılınca dataların çekilmesi
     */
    const getPropertyInventoryFunc = () => {

        let start_date = localStorage.getItem('inventory_start_date')
        let end_date = localStorage.getItem('inventory_end_date')

        if (bulkUpdateData && bulkUpdateData.status === 200) {
            let allDays = constructListOfIntervals(start_date, end_date, 'day');
            if (allDays.length > 15) {
                let ext = allDays.length - 15
                allDays.splice(allDays.length - ext, allDays.length - 1)
            }
            let startDate = allDays[0];
            let endDate = allDays[allDays.length - 1];

            props.changeAllDays(allDays, startDate, endDate)
            dispatch(getPropertyInventory(startDate, endDate, props.channelId))
            dispatch(propertyBulkUpdateClear())
        } else {
            let allDays = constructListOfIntervals(start_date, end_date, 'day');
            if (allDays.length > 15) {
                let ext = allDays.length - 15
                allDays.splice(allDays.length - ext, allDays.length - 1)
            }
            let startDate = allDays[0];
            let endDate = allDays[allDays.length - 1];

            props.changeAllDays(allDays, startDate, endDate)
            dispatch(getPropertyInventory(startDate, endDate, props.channelId))
        }
    }

    const showMessage = () => {

        if( bulkUpdateData && bulkUpdateData.status === 200 ){
            return <SuccessBulkUpdateModal status={true} message={ t('enw-notification_saved_successfully') } runFunction={getPropertyInventoryFunc} />
        }

        if (bulkUpdateError?.response?.status === 400 || bulkUpdateError?.response?.status === 500) {
            let message = bulkUpdateError?.response.data.message
            return <ErrorModal status={true} message={message}/>
        }
    }

    useEffect( () => {},[bulkUpdateData,bulkUpdateError]);

    useEffect(() => {
        setIncludeDays(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"])

        return () => {

            //dispatch(getPropertyInventory(state.start_date,state.end_date, props.channelId));
            dispatch(propertyBulkUpdateClear());

        };
    }, [dispatch]);

    return (
        <ColAtm className={classes.root}>
            <ValidatorForm onSubmit={onSubmit} onError={errors => console.log(errors)}>
                <Grid container className="select-room-types" spacing={1}>
                    <AppBar className="room-rate-tab-title" position="static">
                        <ColAtm className="room-rate-tabFont" value="0">
                            <LabelAtm labelClass="rate-plan-title" text={t('inventory-bulk_update_details')}/>
                        </ColAtm>
                    </AppBar>
                    <Grid item xs={12} md={6} className="form-group">
                        <SelectValidator
                            value={options}
                            onChange={handleChangeOptions}
                            className="form-control"
                            variant="outlined"
                            fullWidth
                            label={t('enw-input-select')}
                            validators={['required']}
                            errorMessages={[t("enw-notification_this_is_required")]}
                        >
                            {
                                bulkUpdateOptions.map((options, index) => (
                                    <MenuItem value={index}>
                                        { t(options.language_key) }
                                        { options.type === "rate" && " "+t('web-offer-price') }
                                        { options.type === "room_stop_sell" && " " + t('btn-stop_sell') }
                                        { options.type === "rate_stop_sell" && " " + t('enw-select-inventory_rate-stop-sell') }
                                        { options.type === "min_stay" && " " + t('enw-select-inventory_min-stay') }
                                        { options.type === "quick_pricing" && " " + t('enw-select-inventory_quick-pricing') }
                                        { options.type === "rate_by_rate" && " "+t('web-offer-price') + ' (Beta)'}
                                    </MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            state.update_type === "availability" &&
                             (
                                 <TextValidator
                                     className="w-100"
                                     name="availability_value"
                                     onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                     variant="outlined"
                                     label={ t('enw-room_and_rates-assign_rate-checkbox_title-availability') }
                                     placeholder={ t('enw-room_and_rates-assign_rate-checkbox_title-availability') }
                                     autoComplete="off"
                                     value={state.value}
                                     onChange={onChangeValue}
                                     validators={['required','minNumber:0', 'maxNumber:10000']}
                                     errorMessages={[ t("enw-notification_this_is_required"),`minNumber:0`, `maxNumber:10000`]}
                                />
                            )
                        }
                        {
                            (state.update_type === "room_stop_sell" || state.update_type === "rate_stop_sell") &&
                             (
                                 <SelectValidator
                                     value={state.value}
                                     onChange={changeStopSell}
                                     className="form-control"
                                     variant="outlined"
                                     fullWidth
                                     label={ t('enw-input-select') }
                                     validators={['required']}
                                     errorMessages={[ t("enw-notification_this_is_required") ]}
                                 >
                                     <MenuItem value={0}>
                                         {t('enw-select-inventory_stop-sell-passive')}
                                     </MenuItem>
                                     <MenuItem value={1}>
                                         {t('enw-select-inventory_stop-sell-active')}
                                     </MenuItem>
                                 </SelectValidator>
                            )
                        }
                        {
                            (state.update_type === "rate" || state.update_type === "min_stay" || state.update_type === "quick_pricing") &&
                                <TextValidator
                                    className="bulk-update-to w-100"
                                    name="rate_value"
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                    variant="outlined"
                                    label={ state.update_type === "min_stay" ? t('enw-select-inventory_min-stay') : t('enw-input-inventory_rate') }
                                    placeholder={ state.update_type === "min_stay" ? t('enw-select-inventory_min-stay') : t('enw-input-inventory_rate') }
                                    autoComplete="off"
                                    value={state.value}
                                    onChange={onChangeValue}
                                    validators={['required','minNumber:0']}
                                    errorMessages={[ t("enw-notification_this_is_required"),`minNumber:0`]}
                                />
                        }
                    </Grid>
                    <BulkUpdateDatTime handleChangeDate={handleChangeDate}/>
                    <ColAtm className="bulk-update-date-time">
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Mon"
                                control={<Checkbox color="primary" value="Mon" defaultChecked={true}/>}
                                label={t('day-short-monday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Tue"
                                control={<Checkbox color="primary" value="Tue" defaultChecked={true}/>}
                                label={t('day-short-tuesday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Wed"
                                control={<Checkbox color="primary" value="Wed" defaultChecked={true}/>}
                                label={t('day-short-wednesday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Thu"
                                control={<Checkbox color="primary" value="Thu" defaultChecked={true}/>}
                                label={t('day-short-thursday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Fri"
                                control={<Checkbox color="primary" value="Fri" defaultChecked={true}/>}
                                label={t('day-short-friday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Sat"
                                control={<Checkbox color="primary" value="Sat" defaultChecked={true}/>}
                                label={t('day-short-saturday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                        <ColAtm md={2} className="mt-22 d-block">
                            <FormControlLabel
                                className="desc-text"
                                name="Sun"
                                control={<Checkbox color="primary" value="Sun" defaultChecked={true}/>}
                                label={t('day-short-sunday')}
                                labelPlacement="left"
                                onClick={onClickIncludeDay}
                            />
                        </ColAtm>
                    </ColAtm>
                    {
                        state.update_type === "availability" && state.availability_type_id === 1 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        state.update_type === "room_stop_sell" && state.availability_type_id === 1 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        state.update_type === "availability" && state.availability_type_id === 2 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        state.update_type === "room_stop_sell" && state.availability_type_id === 2 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        state.update_type === "availability" && state.availability_type_id === 3 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        state.update_type === "room_stop_sell" && state.availability_type_id === 3 &&
                        <Rooms roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_by_rate") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "quick_pricing") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_stop_sell") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "min_stay") && state.availability_type_id === 1 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate") && state.availability_type_id === 2 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_by_rate") && state.availability_type_id === 2 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "quick_pricing") && state.availability_type_id === 2 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_stop_sell") && state.availability_type_id === 2 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "min_stay") && state.availability_type_id === 2 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate") && state.availability_type_id === 3 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_by_rate") && state.availability_type_id === 3 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "quick_pricing") && state.availability_type_id === 3 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "rate_stop_sell") && state.availability_type_id === 3 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                    {
                        (state.update_type === "min_stay") && state.availability_type_id === 3 &&
                        <Rates roomRate={bulkUpdateRoomRate}
                               availabilityTypeId={state.availability_type_id}
                               updateType={state.update_type}
                               setChangeRoomRate={setChangeRoomRate}
                        />
                    }
                </Grid>
                <ColAtm className="mt-44" md={12}>
                    <ButtonAtm
                        className="m-auto room-rate-modal-save bg-red"
                        buttontext={t('btn-save')}
                        type='submit'
                        autoFocus
                        color="primary"
                    />
                </ColAtm>
            </ValidatorForm>
            {
                showMessage()
            }
        </ColAtm>
    );
}

export default BulkUpdateTabs;
