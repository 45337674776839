import React        from 'react';
import PropTypes    from 'prop-types';

import ColAtm       from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";


const RequestBox = props => (
    <ColAtm className={props.colClassname} style={{width: '%14.285'}} xs={12}>
        <BlockAtm className="channels-border-box" onClick={props.onClick}>
            <ColAtm htmlFor={props.htmlFor}>
                <ColAtm htmlFor={props.htmlFor}>
                    <ColAtm className="channel-logo">
                        <BlockAtm>
                            <label className="channels-logo-label"> {props.logoLabel} </label>
                            <img src={props.src} />
                        </BlockAtm>
                    </ColAtm>
                    <ColAtm>
                        <ColAtm className="request-label-col bg-soft-green m-auto">
                            <label className="channel-label"> Confirm </label>
                        </ColAtm>
                        <ColAtm className="request-label-col bg-gray m-auto">
                            <label className="channel-label soft-gray"> Rejected </label>
                        </ColAtm>
                    </ColAtm>
                </ColAtm>
            </ColAtm>
        </BlockAtm>
    </ColAtm>
);

RequestBox.propTypes = {
    src: PropTypes.any,
    iconClass: PropTypes.any,
    htmlFor: PropTypes.number,
    colClassname: PropTypes.string,
    logoLabel: PropTypes.string,
    blockClass: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    labelClass: PropTypes.string,
    text: PropTypes.any,
};

export default RequestBox;
