import { channelRoomRateUpdateConstants } from "../../../../constants/Network/Channels/channelRoomRate.constants";

const initialState = {
    fetching : false,
    updateChannelRoomRate: [],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case channelRoomRateUpdateConstants.ROOM_RATE_CHANNEL_UPDATE_PENDING:
            return {
                ...state,
                fetching: true,
                error: {}
            };
        case channelRoomRateUpdateConstants.ROOM_RATE_CHANNEL_UPDATE_FULFILLED:
            return {
                ...state,
                fetching: false,
                updateChannelRoomRate: action.payload,
            };
        case channelRoomRateUpdateConstants.ROOM_RATE_CHANNEL_UPDATE_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        case channelRoomRateUpdateConstants.ROOM_RATE_CHANNEL_UPDATE_CLEAR:
            return {
                initialState
            };
        default:
            return state
    }
}
