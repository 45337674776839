import React, {Component} from 'react';
import LabelAtm from "../../../../atomic/Label/Label";
import ButtonAtm from "../../../../atomic/Button/Button";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
let t=null;

class SavedAddress extends Component {
    constructor(props) {
        super(props);
        this.state={
            address : this.props.address,
            addressEditingStatus : false,
        }
        t = props.t;
    }

    handleEditClick = (e) => {
        this.props.setStateUpdateEditingSavedAddress(true)
    };

    updatePropertyContant= () => {
        this.props.updatePropertyContant({
            "address"  : this.state.address,
            "latitude" : parseFloat(this.props.location.lat),
            "longitude": parseFloat(this.props.location.long),
        })
    }

    render() {
        const {propertyHasAnAdress,editingSavedAddress} = this.props
        return (
            <div className={ (propertyHasAnAdress && !editingSavedAddress) ? "location-results" : "d-none" }>
                <ul className="list-group">
                    <div>
                        <h3 className="location-find-results">{ t('enw-general-location-your_property_address') }</h3>
                    </div>
                    <div className="location-search-results">
                        <h3>
                            <i className={"fas fa-check-circle location-check-icon mt-5"}/>
                            {this.props.propertyName}
                        </h3>
                        <LabelAtm text={this.props.address} labelClass={"approved-location-text d-block"} />
                        <ButtonAtm
                            className={"soft-blue-button edit-location-btn"}
                            buttontext={ t('btn-edit_address') }
                            onClick={this.handleEditClick}

                        />
                    </div>
                </ul>
            </div>
        );
    }
}

//export default SavedAddress;
export default compose(withTranslation('common'))(SavedAddress);
