import {getPlacePhotoList } from "../../../../constants/Content/places.constanst";

const initialState = {
    fetching : false,
    placePhotoList:[],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getPlacePhotoList.GET_PLACE_PHOTO_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                placePhotoList: [],
            };
        case getPlacePhotoList.GET_PLACE_PHOTO_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                placePhotoList: action.payload?.data
            };
        case getPlacePhotoList.GET_PLACE_PHOTO_LIST_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        default:
            return state
    }
}
