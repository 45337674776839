import {createPropertyConstants} from "../../constants/Public/createProperty.constans";

const initialState = {
    fetching : false,
    status : false,
    error : '',
    create_property: '',
    property_id: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case createPropertyConstants.CREATE_PROPERTY_PENDING:
            return {
                ...state,
                fetching: false,
                status: false,
                create_property: false,
            };
        case createPropertyConstants.CREATE_PROPERTY_FULFILLED:
            return {
                ...state,
                fetching: false,
                create_property: action.payload,
                property_id: action.payload.data.id,
            };
        case createPropertyConstants.CREATE_PROPERTY_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
