import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import { NETWORK_COUPONS_GET, NETWORK_COUPONS_SYNC } from "../../../../constants/api.constants";

export function GetCoupons(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_COUPONS_GET',
            payload: axios.post(NETWORK_COUPONS_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function SyncCoupons(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                property_addon_id: params.property_addon_id,
                id: params.id,
                title: params.title,
                code: params.code,
                start_date: params.start_date,
                end_date: params.end_date,
                type: params.type,
                value: params.value,
                is_notify: params.is_notify,
                status: params.status
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_COUPONS_SYNC',
            payload: axios.post(NETWORK_COUPONS_SYNC, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function CouponDataClear() {
    return dispatch => {
        dispatch({
            type: 'NETWORK_COUPONS_CLEAR_STATE',
        })
    }
}