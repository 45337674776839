import axios                                   from 'axios';
import {getUserToken, getAppLang} from "../../../../utils";
import { GET_OFFER_LANDING }            from "../../../../constants/api.constants";


export function getLandingOffer(offerId) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: offerId
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_OFFER_LANDING',
            payload: axios.post(GET_OFFER_LANDING, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

