import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../utils";
import {
    PAYMENT_MAPPING_UPDATE,
    PAYMENT_MAPPING_UPDATE_STATUS,
    PAYMENT_MAPPING_SET_DEFAULT,
    PAYMENT_MAPPING_LIST,
    PAYMENT_CREATE_MAPPING,
    PAYMENT_TYPE_LIST,
    PAYMENT_INSTALLMENTS_UPDATE,
    PAYMENT_TYPE_GET,
    PAYMENT_INSTALLMENTS_UPDATE_STATUS
} from "../../../constants/api.constants";


export function getPaymentMappingList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_MAPPING_LIST',
            payload: axios.post(PAYMENT_MAPPING_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getPaymentTypeList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_TYPE_LIST',
            payload: axios.post(PAYMENT_TYPE_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createPaymentMapping (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                payment_type_id: params.payment_type_id,
                currency : params.currency,
                fields: params.fields,
                is_default: params.is_default,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_CREATE_MAPPING',
            payload: axios.post(PAYMENT_CREATE_MAPPING, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdatePaymentMapping (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_payment_mapping_id: params.property_payment_mapping_id,
                fields: params.fields,
                is_default: params.is_default
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_MAPPING_UPDATE',
            payload: axios.post(PAYMENT_MAPPING_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdatePaymentStatusMapping (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_payment_mapping_id: params.property_payment_mapping_id,
                set_status: params.set_status,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_MAPPING_UPDATE_STATUS',
            payload: axios.post(PAYMENT_MAPPING_UPDATE_STATUS, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdatePaymentInstallmentStatus (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_payment_mapping_id: params.property_payment_mapping_id,
                installment_id: params.installment_id,
                set_status: params.set_status,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_INSTALLMENTS_UPDATE_STATUS',
            payload: axios.post(PAYMENT_INSTALLMENTS_UPDATE_STATUS, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function PaymentStatusSetDefault (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_payment_mapping_id: params.property_payment_mapping_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_MAPPING_SET_DEFAULT',
            payload: axios.post(PAYMENT_MAPPING_SET_DEFAULT, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function PaymentInstallmentUpdate (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                property_payment_mapping_id: params.property_payment_mapping_id,
                has_installments: params.has_installments,
                installments: params.installments,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_INSTALLMENTS_UPDATE',
            payload: axios.post(PAYMENT_INSTALLMENTS_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function GetPaymentType (params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                payment_type_id: params.payment_type_id
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PAYMENT_TYPE_GET',
            payload: axios.post(PAYMENT_TYPE_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function updatePaymentErrorClear() {
    return dispatch => {
        dispatch({
            type: 'PAYMENT_CREATE_MAPPING_CLEAR_ERROR_STATE',
        })
    }
}
export function successPaymentClear() {
    return dispatch => {
        dispatch({
            type: 'PAYMENT_CREATE_MAPPING_CLEAR_SUCCESS_STATE',
        })
    }
}
