export const blogConstants = {
    BLOG_LIST_PENDING: 'BLOG_LIST_PENDING',
    BLOG_LIST_FULFILLED: 'BLOG_LIST_FULFILLED',
    BLOG_LIST_REJECTED: 'BLOG_LIST_REJECTED',

    BLOG_IMAGE_UPLOAD_PENDING: 'BLOG_IMAGE_UPLOAD_PENDING',
    BLOG_IMAGE_UPLOAD_FULFILLED: 'BLOG_IMAGE_UPLOAD_FULFILLED',
    BLOG_IMAGE_UPLOAD_REJECTED: 'BLOG_IMAGE_UPLOAD_REJECTED',

    CREATE_BLOG_PENDING: 'CREATE_BLOG_PENDING',
    CREATE_BLOG_FULFILLED: 'CREATE_BLOG_FULFILLED',
    CREATE_BLOG_REJECTED: 'CREATE_BLOG_REJECTED',
    CREATE_BLOG_CLEAR: 'CREATE_BLOG_CLEAR',

    UPDATE_BLOG_PENDING: 'UPDATE_BLOG_PENDING',
    UPDATE_BLOG_FULFILLED: 'UPDATE_BLOG_FULFILLED',
    UPDATE_BLOG_REJECTED: 'UPDATE_BLOG_REJECTED',
    UPDATE_BLOG_CLEAR: 'UPDATE_BLOG_CLEAR',

    CREATE_IMAGE_PENDING: 'CREATE_IMAGE_PENDING',
    CREATE_IMAGE_FULFILLED: 'CREATE_IMAGE_FULFILLED',
    CREATE_IMAGE_REJECTED: 'CREATE_IMAGE_REJECTED',
    CREATE_IMAGE_CLEAR: 'CREATE_IMAGE_CLEAR',
};
