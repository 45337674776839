import React, {useEffect, useMemo, useState} from "react";
import Price from "./Price"
import {Grid} from "@material-ui/core";
import LabelAtm from "../../../../atomic/Label/Label";
import BlockAtm from "../../../../atomic/Block/Block";
import {isDefined} from "../../../../../utils";
import StopSellPrice from "./StopSellPrice";
import MinStay from "./MinStay";
import {useTranslation} from "react-i18next";


export default function Prices(props) {
    const { t } = useTranslation();

    useEffect(() => {},[props.prices]);

    const [prices] =  useState( useMemo(() => props.prices , [props.prices] ) );
    const [stop_sell] =  useState( useMemo(() => props.stop_sell , [props.stop_sell] ) );
    const [min_stay] =  useState( useMemo(() => props.min_stay , [props.min_stay] ) );
    const { priceName } = props;
    return (
        <Grid container className={props.isAvail ? "inventory-rate-detail" : "inventory-rate-price"}>
            <Grid item xs={2}>
                <BlockAtm md={12}>
                    <LabelAtm labelClass="inventory-room-rates" text={props.showStopSell ? t('btn-stop_sell') : props.showMinStay ? t('enw-input-min_stay') :  priceName} />
                </BlockAtm>
            </Grid>
            <Grid item xs={10} className="m-overflow-inventory-price">
                <Grid container className="inventory-price-padding">
                    {
                        props.showStopSell ?

                        Object.entries(stop_sell).map( ( [date, value] ) => (

                            <StopSellPrice date={date}
                                   value={value}
                                   onChangeUpdateData={props.onChangeUpdateData}
                                   updatedData={ props.updatedData }
                                   showStopSell={ props.showStopSell }
                            />

                        ))
                        :

                        props.showMinStay ?
                            Object.entries(min_stay).map( ( [date, value] ) => (

                                <MinStay date={date}
                                         value={value}
                                         onChangeUpdateData={props.onChangeUpdateData}
                                         updatedData={ props.updatedData }
                                         showStopSell={ props.showStopSell }
                                />

                            ))
                        :
                        Object.entries(prices).map( ( [date, value] ) => (

                            <Price date={date}
                                   value={value}
                                   onChangeUpdateData={props.onChangeUpdateData}
                                   updatedData={ props.updatedData }
                                   channelAvailability={ props.channelAvailability }
                                   showStopSell={ props.showStopSell }
                            />

                        ))
                    }
                    {
                        isDefined(props.currency_code) && !props.showStopSell && !props.showMinStay &&
                        <p className="inventory-currency">
                            {props.currency_code}
                        </p>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
