import React from 'react';
import Helmet from 'react-helmet';
import {getAppLang} from "../../../utils";

const PageTitle = ({ title, offerBody }) => {
    var defaultTitle = ' ExtraNetwork';
    return (
        <Helmet htmlAttributes={{ lang : getAppLang() }}>
            <title>{title ? title : defaultTitle}</title>
            {
                offerBody ?
                    <body className="no-overflow" />
                    :
                    <body/>
            }
        </Helmet>
    );
};

export { PageTitle };
