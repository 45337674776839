import React, { useEffect, useState } from "react";
import BlockAtm from "../../../../atomic/Block/Block";
import LabelAtm from "../../../../atomic/Label/Label";
import ColAtm from "../../../../atomic/Col/Col";
import Prices from "./Prices";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function Accommodation(props) {
    useEffect(() => {},[]);
    const [roomRate] = useState(props.roomRate);
    const { t } = useTranslation();

    return (
        <>
        {
                <ColAtm className="inventory-avail-box">
                        <ColAtm xs={2} >
                            <Grid item md={1}></Grid>
                            <Grid item md={1}><i className="gray fas fa-tags" /></Grid>
                            <Grid item md={10}>
                                <LabelAtm labelClass="inventory-room-name" text={roomRate.name} />
                            </Grid>
                            <BlockAtm className="channels-box" md={2}></BlockAtm>
                            <BlockAtm className="inventory-copy-box" md={3}></BlockAtm>
                        </ColAtm>
                        <ColAtm xs={10}>
                            <Prices prices={roomRate.price}
                                    stop_sell={roomRate.stop_sell}
                                    min_stay={roomRate.min_stay}
                                    priceName={t(roomRate.language_key) + " " + t('web-offer-price')}
                                    onChangeUpdateData={props.onChangeUpdateData}
                                    updatedData={ props.updatedData }
                                    currency_code={ roomRate.currency }
                                    showStopSell={ props.showStopSell }
                                    showMinStay={ props.showMinStay }
                                    isAvail={false}
                            />
                            {
                                roomRate.hasOwnProperty('availabilities') && Object.keys(roomRate.availabilities).length > 0 &&
                                <Prices prices={roomRate.availability}
                                        stop_sell={roomRate.stop_sell}
                                        min_stay={roomRate.min_stay}
                                        priceName={ t(roomRate.language_key) + " " + t('enw-room_and_rates-assign_rate-checkbox_title-availability')}
                                        onChangeUpdateData={props.onChangeUpdateData}
                                        updatedData={ props.updatedData }
                                        showStopSell={ props.showStopSell }
                                        showMinStay={ props.showMinStay }
                                        isAvail={true}
                                />
                            }
                        </ColAtm>
                </ColAtm>

        }
        </>


    );
}
