export const inventoryConstants = {
    PROPERTY_INVENTORY_GET_PENDING  : 'PROPERTY_INVENTORY_GET_PENDING',
    PROPERTY_INVENTORY_GET_FULFILLED: 'PROPERTY_INVENTORY_GET_FULFILLED',
    PROPERTY_INVENTORY_GET_REJECTED : 'PROPERTY_INVENTORY_GET_REJECTED',

    PROPERTY_INVENTORY_UPDATE_OR_CREATE_PENDING  : 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_PENDING',
    PROPERTY_INVENTORY_UPDATE_OR_CREATE_FULFILLED: 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_FULFILLED',
    PROPERTY_INVENTORY_UPDATE_OR_CREATE_REJECTED : 'PROPERTY_INVENTORY_UPDATE_OR_CREATE_REJECTED',

    PROPERTY_BULK_UPDATE_PENDING    : 'PROPERTY_BULK_UPDATE_PENDING',
    PROPERTY_BULK_UPDATE_FULFILLED  : 'PROPERTY_BULK_UPDATE_FULFILLED',
    PROPERTY_BULK_UPDATE_REJECTED   : 'PROPERTY_BULK_UPDATE_REJECTED',

    GET_PROPERTY_BULK_UPDATE_PENDING    : 'GET_PROPERTY_BULK_UPDATE_PENDING',
    GET_PROPERTY_BULK_UPDATE_FULFILLED  : 'GET_PROPERTY_BULK_UPDATE_FULFILLED',
    GET_PROPERTY_BULK_UPDATE_REJECTED   : 'GET_PROPERTY_BULK_UPDATE_REJECTED',

    GET_CPA_COMPETITOR_PENDING    : 'GET_CPA_COMPETITOR_PENDING',
    GET_CPA_COMPETITOR_FULFILLED  : 'GET_CPA_COMPETITOR_FULFILLED',
    GET_CPA_COMPETITOR_REJECTED   : 'GET_CPA_COMPETITOR_REJECTED',
    GET_CPA_COMPETITOR_CLEAR_DATA   : 'GET_CPA_COMPETITOR_CLEAR_DATA',

    CPA_DAILY_PRICE_PENDING    : 'CPA_DAILY_PRICE_PENDING',
    CPA_DAILY_PRICE_FULFILLED  : 'CPA_DAILY_PRICE_FULFILLED',
    CPA_DAILY_PRICE_REJECTED   : 'CPA_DAILY_PRICE_REJECTED',

    SYNC_CPA_COMPETITOR_PENDING    : 'SYNC_CPA_COMPETITOR_PENDING',
    SYNC_CPA_COMPETITOR_FULFILLED  : 'SYNC_CPA_COMPETITOR_FULFILLED',
    SYNC_CPA_COMPETITOR_REJECTED   : 'SYNC_CPA_COMPETITOR_REJECTED',
    SYNC_CPA_COMPETITOR_CLEAR_DATA   : 'SYNC_CPA_COMPETITOR_CLEAR_DATA',

    PROPERTY_BULK_UPDATE_CLEAR : 'PROPERTY_BULK_UPDATE_CLEAR',

    UPDATE_QUICK_PRICING_PENDING    : 'UPDATE_QUICK_PRICING_PENDING',
    UPDATE_QUICK_PRICING_FULFILLED  : 'UPDATE_QUICK_PRICING_FULFILLED',
    UPDATE_QUICK_PRICING_REJECTED   : 'UPDATE_QUICK_PRICING_REJECTED',
    UPDATE_QUICK_PRICING_CLEAR      : 'UPDATE_QUICK_PRICING_CLEAR',

    GET_QUICK_PRICING_PENDING    : 'GET_QUICK_PRICING_PENDING',
    GET_QUICK_PRICING_FULFILLED  : 'GET_QUICK_PRICING_FULFILLED',
    GET_QUICK_PRICING_REJECTED   : 'GET_QUICK_PRICING_REJECTED',

};