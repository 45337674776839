import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {getAppLang, getProperty, isDefined} from "../../../../../utils";
import ColAtm from "../../../../atomic/Col/Col";
import ButtonAtm from "../../../../atomic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {PHOTO_LIST} from "../../../../../constants/api.constants";
import axios from "axios";
// import MywebPhotos from "../../../../molecules/MywebPhotos/MywebPhotos";
import MywebPhotosDownload from '../../../../molecules/MywebPhotos/MywebPhotosDownload';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function ContentPhotos(props) {
    const { t } = useTranslation();
    const [status, setStatus] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [selectedPhotosLength, setSelectedPhotosLength] = useState(0);

    const downloadPhotos = () => {
        if (selectedPhotos.length > 0) {
            props.downloadContentPhotos(selectedPhotos)
        } else {
            props.downloadContentPhotos()
        }
        setStatus(true)
    }

    useEffect(() => {
        if ((isDefined(props.photoZipData.contentPhotosData) && isDefined(props.photoZipData.contentPhotosData.data)) && props.photoZipData.contentPhotosData.status === 200) {
            setTimeout(() => {
                setStatus(false)
            }, 1000);

            if (status === true) {
                window.open(props.photoZipData.contentPhotosData.data.zipFile, "_blank")
            }
        }
    });

    useEffect(() => {
        if (openModal) {
            const getlist = PHOTO_LIST;

            const postData = {
                params:
                    {
                        locale: "en",
                        property_id: getProperty(),
                    }
            };

            axios.post(getlist, postData, {
                headers: {
                    'content-type': 'application/json',
                    'authToken': localStorage.getItem('auth'),
                    "language": getAppLang(),
                }
            })
                .then(res => {
                    setPhotos(res.data.data)
                })
                .catch(err => console.log('Errors =  ' + err.data))
        }
    }, [openModal]);


    const onClickPhoto = (e) => {
        if(e.target.checked) {
            setSelectedPhotos([...selectedPhotos, e.target.value])
            setSelectedPhotosLength(selectedPhotos.length)
        } else {
            let temp = selectedPhotos;
            let index = temp.indexOf(e.target.value);
            if ( index > -1 ) {
                temp.splice(index, 1);
                setSelectedPhotos(temp);
                setSelectedPhotosLength(index)
            }
        }
    };


    return (
        <div>
            <button className="download-content-photo-btn" onClick={() => setOpenModal(true)}>
                <i className="fas fa-cloud-download-alt"/> { t('btn-download_photos') }
            </button>
            <Dialog onClose={() => setOpenModal(false)} aria-labelledby="status" open={openModal} className="web-preview-dialog" fullScreen>
                <DialogTitle className="red-bottom-border"  id="status" onClose={()=> setOpenModal(false)}>
                    { t('btn-download_photos') }
                </DialogTitle>
                <DialogContent>
                    <ColAtm className="mt-32">
                        {
                            photos.map( (photo,key) => (
                                <MywebPhotosDownload
                                    checkboxId={photo.id}
                                    className="channel-box"
                                    colClassname="room-photo-grid"
                                    blockClass="photo-select-border-box channel-border-box show-cover"
                                    src={photo.photo_url_thump}
                                    value={photo.id}
                                    labelClass="fact-name"
                                    defaultChecked={false}
                                    key={key}
                                    htmlFor={photo.id}
                                    onClick={onClickPhoto}
                                />
                            ))
                        }

                    </ColAtm>
                    <ColAtm className="mt-32 download-all-photos-btn" md={12}>
                        <ButtonAtm
                            className="m-auto room-rate-modal-save bg-red"
                            buttontext={selectedPhotos.length > 0 ? t('btn-download_selected') : t('btn-download_all')}
                            color="primary"
                            onClick={(e) => downloadPhotos(e)}
                        />
                    </ColAtm>
                </DialogContent>
            </Dialog>
        </div>
    );
}

