import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Link} from "react-router-dom";
import {NETWORK_RESERVATION_DETAIL_SHORT} from "../../../../constants/appUrls.constants";
import ExportReservationListToExcel from "./ExportReservationListToExcel";
import {Badge} from "@material-ui/core";

export default function ReservationListDatatable(props) {
    const {t} = useTranslation();
    const [dataForExcel, setDataForExcel] = useState([]);

    const columns = [
        t('enw-reservation-table-date_of_purchase'),
        t('enw-reservation-table-reservation_number'),
        t('enw-input-name_surname'),
        t('enw-reservation-table-price'),
        t('enw-reservation-table-channels'),
        {
            name: t('enw-reservation-table-booking_type'),
            options: {
                filter: true,
                searchable: true,
                customBodyRender: (value) => {
                    return <span className="table-status-bg"
                                 style={{backgroundColor: `${value['bg-color']}`}}>{t(value?.language_key)}</span>;
                }
            }
        },
        {
            name: t('enw-reservation-table-payment_type'),
            options: {
                filter: true,
                searchable: true,
                customBodyRender: (value) => {

                    return t(value);
                }
            }
        },
        t('enw-reservation-table-check_in'),
        t('enw-reservation-table-actions'),
        {
            options: {
                display: false,
            }
        },
    ];


    const {reservationList, fetching} = props;
    const [reservations, setReservations] = useState([]);


    useEffect(() => {
        if (!fetching && reservationList && reservationList.booking && Object.keys(reservationList.booking).length > 0) {
            let tempData = [];
            let tempExcel = [];
            reservationList.booking.map((booking, index) => {
                tempData.push([
                    moment.unix(booking.reservation_time).format("DD-MM-YYYY HH:mm:ss"),
                    booking.booking_code,
                    booking.booking_contact.name + " " + booking.booking_contact.surname,
                    booking.total + " " + booking.currency_code,
                    booking.booking_channel.name,
                    booking.booking_status,
                    booking.booking_payment_type.language_key,
                    moment(booking.checkin_date).format("DD-MM-YYYY"),
                    <Link className="offer-list-button" to={NETWORK_RESERVATION_DETAIL_SHORT + `${booking.id}`}>
                        <Badge badgeContent={booking.booking_active_message_count} color="secondary">
                            <i className={"fas fa-eye"}/>
                        </Badge>
                    </Link>,
                    booking?.is_viewed,
                ])

                tempExcel.push({
                    bookingCode: booking.booking_code,
                    name: booking.booking_contact.name + " " + booking.booking_contact.surname,
                    total: booking.total + " " + booking.currency_code,
                    channelName: booking.booking_channel.name,
                    status: booking.status,
                    paymentType: t(booking.booking_payment_type.language_key),
                    checkinDate: booking.checkin_date,
                    createDate: booking.reservation_time,
                })
            });

            setReservations(tempData);
            setDataForExcel(tempExcel)
        }
    }, [reservationList])

    const options = {
        filter: false,
        selectableRowsOnClick: false,
        selectableRows: false,
        searchOpen: true,
        searchPlaceholder: t('btn-search'),
        print: false,
        download: false,
        sortOrder: {
            name: 'name',
            direction: 'desc'
        },
        setRowProps: row => {
            if (row[9] === true) {
                return {
                    className: "table-viewed-data"
                };
            }
        },
        textLabels: {
            body: {
                noMatch: t('datatable-body-nomatch'),
                toolTip: t('datatable-body-tooltip'),
            },
            pagination: {
                next: t('datatable-pagination-next'),
                previous: t('datatable-pagination-previous'),
                rowsPerPage: t('datatable-pagination-rowsperpage'),
                displayRows: t('datatable-pagination-of'),
            },
            toolbar: {
                search: t('datatable-toolbar-search'),
                downloadCsv: t('datatable-toolbar-download_excel'),
                print: t('datatable-toolbar-print'),
                viewColumns: t('datatable-toolbar-view_columns'),
                filterTable: t('datatable-toolbar-filter_table'),
            },
            filter: {
                all: t('datatable-filter-all'),
                title: t('datatable-filter-title'),
                reset: t('datatable-filter-reset'),
            },
            viewColumns: {
                title: t('datatable-filter-view_columns-title'),
                titleAria: t('datatable-filter-view_columns-title_aria'),
            },
            selectedRows: {
                text: t('datatable-selected_rows-text'),
                delete: t('datatable-selected_rows-delete'),
                deleteAria: t('datatable-selected_rows-delete-aria')
            },
        },
        customToolbar: () => {
            return (
                <ExportReservationListToExcel
                    data={dataForExcel}
                    ReservationExcel={props.ReservationExcel}
                />
            );
        }
    };

    return (
        <>
            {
                !fetching && reservationList &&
                <MUIDataTable
                    title={t('enw-reservation-subtitle')}
                    data={reservations}
                    columns={columns}
                    options={options}
                />
            }
        </>
    )
}

