import rootReducers from "../reducers";
import {createStore, applyMiddleware, compose, } from "redux";
import thunk from "redux-thunk";
import promise from 'redux-promise-middleware';
import {getAppLang, getUserToken, setExpireTime, userLogin, userLogout} from '../../utils';
import moment from "moment";
import jwt_decode from "jwt-decode";
import axios from "axios";
import {REFRESH_TOKEN} from "../../constants/api.constants";

async function refleshToken() {
    let responseToken = null;
    let responseExpireTime = null;
    try {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'authToken': getUserToken(),
                'language' : getAppLang()
            }};
        const response = await axios.get(REFRESH_TOKEN,{headers: requestOptions.headers} );
        responseToken = await response?.data?.data?.token;
        responseExpireTime = await response?.data?.data?.expire_time;


        if( responseToken === undefined || responseExpireTime === undefined){
            throw " Couldn't renew token ";
        }
        userLogin(responseToken);
        setExpireTime(responseExpireTime)
    } catch (error) {
        console.error(error);
    }

    //return await responseToken;
}

/**
 *
 * @param getState
 * @returns {function(*): function(*=): *}
 * @comment action typelerina göre fitreleme yapabilecek bir function.
 */

const checkTokenExpirationMiddleware = store => next => action => {

    try {
        const token = getUserToken();
        if(token === null ||token === undefined ){
            throw 'Token not Founded!';
        }

        const tokenDecode = jwt_decode(token);
        if(!tokenDecode.hasOwnProperty('exp')){
            throw 'Exprimetime not Found!'
        }

        const expireTime = tokenDecode.exp;
        const endDate = moment( moment.unix( expireTime ).format("YYYY-MM-DD H:mm:ss") ) ;
        const currentDate = moment( moment( new Date() ).format("YYYY-MM-DD H:mm:ss") );
        const diffDate =  endDate.diff( currentDate,'minutes' );

        if( diffDate !== 0 && diffDate <=13 ){
           refleshToken();
        }

        if(diffDate === 0 || diffDate  < 0 ){
            next(action);
            userLogout();
        }
        next(action);

    }catch (error){
        console.debug('Middeware => ',error)
        next(action);
    }

};

const middleware = [ promise, thunk,checkTokenExpirationMiddleware];
/*
if (process.env.NODE_ENV === `development`) {
    const loggerMiddleware = createLogger();
    middleware.push(loggerMiddleware);
}
 */

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
} else {
    composeEnhancers = compose;
}
const enhancer = composeEnhancers(applyMiddleware(...middleware),);

const store = createStore(rootReducers, enhancer);

export default store;
