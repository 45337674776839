/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import ColAtm from "../../../../../atomic/Col/Col";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {
    CONTENT_PLACES,
    CONTENT_PLACES_PHOTOS,
    CONTENT_PLACES_FACTS
} from "../../../../../../constants/appUrls.constants";

const useStyles = makeStyles(theme => ({
    root: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#e46a6b',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#e46a6b'
        },
    }
}));

export default function PlacesMenu(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Hidden xsDown>
            <ColAtm md={12} className={classes.root}>
                <ColAtm className={props.createPlacesColClass} sm={4}>
                    <Link to={CONTENT_PLACES} className={classes.aLink} disabled={props.createRoomDisabled}>
                        { t('btn-places') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.placesFacts} sm={4}>
                    <Link to={CONTENT_PLACES_FACTS} className={classes.aLink} disabled={props.placesFactsDisabled}>
                        { t('btn-places_facts') }
                    </Link>
                </ColAtm>
                <ColAtm className={props.photoPlaceColClass} sm={4}>
                    <Link to={CONTENT_PLACES_PHOTOS} className={classes.aLink} disabled={props.amenitiesRoomsDisabled}>
                        { t('btn-place_photos') }
                    </Link>
                </ColAtm>
            </ColAtm>
        </Hidden>
    );
}
PlacesMenu.propTypes = {
    createPlacesColClass: PropTypes.string,
    placesFacts: PropTypes.string,
    photoPlaceColClass: PropTypes.string,
    createRoomDisabled: PropTypes.bool,
    placesFactsDisabled: PropTypes.bool,
    amenitiesRoomsDisabled: PropTypes.bool,
};
