import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    NETWORK_ADDONS_GET,
    NETWORK_ADDONS_SYNC
} from "../../../../constants/api.constants";

export function GetAddons(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_ADDONS_GET',
            payload: axios.post(NETWORK_ADDONS_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function SyncAddons(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params.channel_id,
                property_addon_id: params.property_addon_id,
                channelAddon: params.channelAddon,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'NETWORK_ADDONS_SYNC',
            payload: axios.post(NETWORK_ADDONS_SYNC, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function AddonDataClear() {
    return dispatch => {
        dispatch({
            type: 'NETWORK_ADDONS_CLEAR_STATE',
        })
    }
}