import React        from 'react';
import PropTypes    from 'prop-types';

import ColAtm       from "../../atomic/Col/Col";
import BlockAtm from "../../atomic/Block/Block";
import ButtonAtm from "../../atomic/Button/Button";
import OverFlowText from "../OverFlowText/OverFlowText";


const ChannelListBox = props => (
    <ColAtm htmlFor={props.htmlFor} xs={6} sm={4} md={2} className={props.colClassname}>
        <BlockAtm className={props.blockClass} onClick={props.onClick} htmlFor={props.htmlFor}>
            <ButtonAtm
                name={props.name}
                id={props.id}
                className={props.className}
                onChange={props.onChange}
                value={props.value}
                labelPlacement={props.labelPlacement}
            />
            <ColAtm htmlFor={props.htmlFor}>
                <ColAtm htmlFor={props.htmlFor}>
                    <ColAtm className="channel-logo" htmlFor={props.htmlFor}>
                        <label htmlFor={props.htmlFor}>
                            <div style={props.src} className={props.channelImage} htmlFor={props.htmlFor}>
                                <i className={props.activeIcon} />
                            </div>
                        </label>
                    </ColAtm>
                    <label className={props.channelBlockClass} htmlFor={props.htmlFor}>
                        <BlockAtm htmlFor={props.htmlFor}>
                            <OverFlowText className={props.channelLabelClass} htmlFor={props.htmlFor}> {props.text} </OverFlowText>
                        </BlockAtm>
                    </label>
                </ColAtm>
            </ColAtm>
        </BlockAtm>
    </ColAtm>
);

ChannelListBox.propTypes = {
    id: PropTypes.any,
    name: PropTypes.any,
    src: PropTypes.any,
    className: PropTypes.string,
    iconClass: PropTypes.any,
    value: PropTypes.any,
    htmlFor: PropTypes.any,
    colClassname: PropTypes.string,
    blockClass: PropTypes.string,
    checkboxId: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any,
    defaultChecked: PropTypes.any,
    labelClass: PropTypes.string,
    activeIcon: PropTypes.string,
    channelLabelClass: PropTypes.any,
    channelBlockClass: PropTypes.any,
    to: PropTypes.any,
    text: PropTypes.any,
    channelImage: PropTypes.any,
};

export default ChannelListBox;
