import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import ColAtm from "../../../../../atomic/Col/Col";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {isDefined} from "../../../../../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        height: '40px',
        textAlign: 'center',
        color: '#e46a6b',
        background: '#fae1e1',
    },
    aLink: {
        margin: 'auto',
        color: '#d71f26',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover, &:focus": {
            color: '#d71f26'
        },
    }
}));

export default function ChannelMenu(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const roomPriceType = isDefined(props.channelSetup.channelInformationData.channel_room_pricing_type) && props.channelSetup.channelInformationData.channel_room_pricing_type.id;
    const channelCategoryId = isDefined(props.channelSetup.channelInformationData.channel_category_id) && props.channelSetup.channelInformationData.channel_category_id;
    const ChannelRoomPriceType = isDefined(props.channelSetup.channelSetupSubmitData.data) ? props.channelSetup.channelSetupSubmitData.data.room_price_type.filter((price) => price.is_selected === true).map( price => price.room_pricing_type_id ) : roomPriceType;
    const width = channelCategoryId === 5 ?
        {width: (ChannelRoomPriceType === 1 || ChannelRoomPriceType[0] === 1) ? '16.666%' : "20%"}
        :
        {width: (ChannelRoomPriceType === 1 || ChannelRoomPriceType[0] === 1) ? '20%' : "25%"}

    return (
        <Hidden xsDown>
            { props.channelSetup.fetching ?
                <ColAtm md={12} className={props.menuClass} lastId={props.menuId}>

                </ColAtm>
                :
                <ColAtm md={12} className={props.menuClass} lastId={props.menuId}>
                    <ColAtm className={props.setupColClass} style={width}>
                        <Link to={"/app/network/channel-setup/" + props.menuId} className={classes.aLink}
                              disabled={props.setupDisabled}>
                            {t("enw-channels-channel_setup-title")}
                        </Link>
                    </ColAtm>
                    <ColAtm className={props.RoomRateColClass} style={width}>
                        <Link to={"/app/network/channel-room-rate/" + props.menuId} className={classes.aLink}
                              disabled={props.RoomRateDisabled}>
                            {t("enw-channels-room_rate-title")}
                        </Link>
                    </ColAtm>

                    {
                        channelCategoryId === 5 &&
                        <ColAtm className={props.ContactColClass} style={width}>
                            <Link to={"/app/network/channel-contact/" + props.menuId} className={classes.aLink}
                                  disabled={props.ContactDisabled}>
                                {t("enw-channels-contact_title")}
                            </Link>
                        </ColAtm>
                    }

                    <ColAtm className={props.CancellationColClass} style={width}>
                        <Link to={"/app/network/channel-cancellation/" + props.menuId} className={classes.aLink}
                              disabled={props.CancellationDisabled}>
                            {t("enw-offer-cancellation_policy-title")}
                        </Link>
                    </ColAtm>
                    {
                        (ChannelRoomPriceType === 1 || ChannelRoomPriceType[0] === 1) &&

                        <ColAtm className={props.PaxColClass} style={width}>
                            <Link to={"/app/network/channel-pax-pricing/" + props.menuId} className={classes.aLink}
                                  disabled={props.PaxDisabled}>
                                {t("enw-channels-person_pricing-title")}
                            </Link>
                        </ColAtm>
                    }

                    <ColAtm className={props.PaymentColClass} style={width}>
                        <Link to={"/app/network/channel-payment/" + props.menuId} className={classes.aLink}
                              disabled={props.PaymentDisabled}>
                            {t("enw-channels-payment_policy-title")}
                        </Link>
                    </ColAtm>
                </ColAtm>
            }
        </Hidden>
    );
}
ChannelMenu.propTypes = {
    text: PropTypes.string,
    menuClass: PropTypes.string,
    infoColClass: PropTypes.string,
    setupColClass: PropTypes.string,
    RoomRateColClass: PropTypes.string,
    CancellationColClass: PropTypes.string,
    AdultColClass: PropTypes.string,
    RefundableColClass: PropTypes.string,
    PaxColClass: PropTypes.string,
    PaymentColClass: PropTypes.string,
    ContactColClass: PropTypes.string,
    infoDisabled: PropTypes.bool,
    RoomRateDisabled: PropTypes.bool,
    CancellationDisabled: PropTypes.bool,
    AdultDisabled: PropTypes.bool,
    setupDisabled: PropTypes.bool,
    PaxDisabled: PropTypes.bool,
    RefundableDisabled: PropTypes.bool,
    PaymentDisabled: PropTypes.bool,
    ContactDisabled: PropTypes.bool,
};
