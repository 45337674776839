import React, {Component}   from 'react';
import PropTypes            from 'prop-types';
import { connect }          from "react-redux";
import {compose}            from "redux";
import {withTranslation}    from "react-i18next";
import { history }          from "../../../../../utils/history";
import {
    isLogged,
    checkExpireTime
} from "../../../../../utils"
import {APP} from '../../../../../constants/appUrls.constants'
import CssBaseline          from '@material-ui/core/CssBaseline';
import Paper                from '@material-ui/core/Paper';
import Grid                 from '@material-ui/core/Grid';
import ImageAtm             from "../../../../atomic/Image/Image";
import LoginRegisterTab from "./LoginRegisterTab";
import ForgotPassword from "./ForgotPassword";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import {forgotPassword} from "../../../../../redux/actions/User/ForgotPassword/userForgotPassword"
import SuccessModal from "../../../../organisms/Modal/SuccessModal/SuccessModal"
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal"
import HeaderLandingLanguage from "../../../../organisms/HeaderLanguage/HeaderLandingLanguage";

let t=null;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            Version 1.1.28
        </Typography>
    );
}

class LoginLeft extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showForgotPassword: false,
            selectedTabIndex:  parseInt(this.props.selectedTabIndex),
        }
        t = props.t;
    }


    componentDidMount() {

        //TODO : expireTime ile ilgili uyarı mesajı verilmesi gerekiyor
        if( isLogged() && checkExpireTime() ){
            history.push(APP);
        }
    }

    onClickShowForgotPassword = () => {
        this.setState({
            showForgotPassword: true,
        })
    }

    handleChangeRegisterTab = (tabIndex) => {
        this.setState({
            selectedTabIndex: tabIndex
        })
    }

    toggle = () => this.setState((currentState) => ( {showForgotPassword: !currentState.showForgotPassword} ));

    showMessageForgotPassword = () => {
        if( this.props.userForgotPasswordReducer.forgotUpdateStatus === true ){
            return <SuccessModal status={true}
                                 message={"We have emailed you a link to reset your password"}
                                 handleFonction ={this.handleChangeRegisterTab}
                                 toggle ={this.toggle}
            />
        }

        if( this.props.userForgotPasswordReducer.forgotUpdateStatus === false &&
            this.props.userForgotPasswordReducer.error.response
        ){
            let message = this.props.userForgotPasswordReducer.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        const { t, oneSignalId } = this.props;
        const { showForgotPassword,selectedTabIndex } = this.state;
        return (
            <Grid className="login-grid" container component="main">
                <CssBaseline />
                <Grid className="login-left" item xs={12} component={Paper} elevation={6} square>
                    <ImageAtm className="login-logo" src="/logo.svg" />
                    <HeaderLandingLanguage />
                    {
                        showForgotPassword ? (

                            <div className="login-label login-password-label">
                                <ColAtm>
                                    <ButtonAtm
                                        buttontext={ t('btn-back') }
                                        className="login-back"
                                        startIcon={<i className="fas fa-arrow-left" />}
                                        onClick={this.toggle}
                                    />
                                </ColAtm>
                                <ForgotPassword forgotPassword={this.props.forgotPassword} userForgotPasswordReducer={this.props.userForgotPasswordReducer}/>
                                {
                                    this.showMessageForgotPassword()
                                }
                            </div>
                        ) : (
                            <div className="login-label">
                                <LoginRegisterTab onClickShowForgotPassword={this.onClickShowForgotPassword}
                                                  selectedTabIndex={selectedTabIndex}
                                                  oneSignalId={oneSignalId}
                                />

                                <Box mt={5}>
                                    <Copyright/>
                                </Box>
                            </div>
                        )
                    }

                </Grid>
            </Grid>
        );
    }
}
LoginLeft.propTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => {
    return {
        userForgotPasswordReducer : props.userForgotPasswordReducer,
    }
};

const mapDispatchToProps ={
    forgotPassword
};

export default compose(withTranslation('common')) ( connect(mapStateToProps, mapDispatchToProps )(LoginLeft));
