import React from "react";
import {connect} from "react-redux";
import { Redirect } from 'react-router-dom';
import Facts from "./components/Coupons";
import {GetCoupons} from "../../../../redux/actions/Network/Coupons/couponsActions";

class Coupons extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            property_brand : null,
            sub_category_id : '',
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        if ( this.props.match.params.id ) {
            this.props.GetCoupons( this.props.match.params.id );

            this.setState({
                sub_category_id: this.props.match.params.id
            })
        }
        else {
            return <Redirect to="/404" />
        }

    }

    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id) {
            this.props.GetCoupons(nextProps.match.params.id);
        }
    }

    render() {
        return (
            <Facts
                CouponsListData = {this.props.couponsReducers.getCouponsList}
                getCouponsFetching = {this.props.couponsReducers.getCouponsFetching}
                fetching = {this.props.couponsReducers.fetching}
                pageId={this.props.match.params.id}
            />
        )
    }
}


const mapStateToProps = (props) => {
    return {
        couponsReducers : props.couponsReducers
    }
};

const mapDispatchToProps ={
    GetCoupons
};


export default connect(mapStateToProps, mapDispatchToProps )(Coupons);
