import React, {useEffect} from "react";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import LabelAtm from "../../../../../atomic/Label/Label";
import ColAtm from "../../../../../atomic/Col/Col";
import Adult from "./Adult";
import Child from "./Child";

const Rates = (props) => {

   useEffect( () => {},[])

    return(
        <>
            {
                props.roomRates.map(rate => (
                    <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item">
                        <ExpansionPanel className="accordion-rate-table" defaultExpanded>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header">
                                <ColAtm>
                                    <ColAtm md={10} xl={11}>
                                        <i className="fas fa-tags rate-tag-icon" />
                                        <LabelAtm labelClass="rate-title"  text={rate.name} />
                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                <Adult adultPricingPolicy={rate.room_rate_channel_adult_pricing_policy}
                                       roomId={props.roomId}
                                       rateId={rate.id}
                                       channelId={props.channelId}
                                       rateMappingId={rate.room_rate_channel_mapping_id}
                                       currency={props.currency}
                                       channelInformationData={props.channelInformationData}
                                       updateChannelAdultPricing={props.updateChannelAdultPricing}
                                       updateAdultPricingStatus={props.updateAdultPricingStatus}
                                       getChannelPaxList={props.getChannelPaxList}
                                       errorUpdateAdult={props.errorUpdateAdult}
                                       clearErrorMessage = {props.clearErrorMessage}
                                />
                                <hr className="channel-pax-hr" />
                                <Child childPricingPolicy={rate.room_rate_channel_child_pricing_policy}
                                       roomId={props.roomId}
                                       channelId={props.channelId}
                                       rateMappingId={rate.room_rate_channel_mapping_id}
                                       currency={props.currency}
                                       updateChannelChildPricing={props.updateChannelChildPricing}
                                       updateChildPricingStatus={props.updateChildPricingStatus}
                                       getChannelPaxList={props.getChannelPaxList}
                                       channelInformationData={props.channelInformationData}
                                       errorUpdateChild={props.errorUpdateChild}
                                       clearErrorMessage = {props.clearErrorMessage}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </ExpansionPanelDetails>
                ))
            }

        </>
    )

}

export default Rates;