import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getProperty, isDefined} from "../../../../../../utils";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function UpdateChildPaxModal(props) {
    const { t } = useTranslation();
    const default_currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.default_currency ? props.channelInformationData.default_currency : '';
    const currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.currency ? props.channelInformationData.currency : default_currency ;

    const [name, setName] = useState(props.child.name);
    const [type, setType] = useState((props.child.type === "FIX" && props.child.value === 0) ? "FREE" : props.child.type);
    const [val, setVal] = useState(props.child.value);
    const [adult, setAdult] = useState(props.child.adult);
    const [childOrder, setChildOrder] = useState(props.child.child_order);
    const [ageStart, setAgeStart] = useState(props.child.child_age_start);
    const [ageEnd, setAgeEnd] = useState(props.child.child_age_end);
    const [open, setOpen] = React.useState(false);
    const [rateMappingId] = React.useState(props.rateMappingId);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.clearErrorMessage();

        setName(props.child.name)
        setVal(props.child.value)
        setAdult(props.child.adult)
        setChildOrder(props.child.child_order)
        setAgeStart(props.child.child_age_start)
        setAgeEnd(props.child.child_age_end)
        setType((props.child.type === "FIX" && props.child.value === 0) ? "FREE" : props.child.type)
    };

    const changeVal = ({target}) => {
        const validated = (target.value).match(/^(\d*\.{0,1}\d{0,2}$)/)
        if (validated) {
            setVal(target.value)
        }
    }
    const handleChangeType = ({target}) => {
        setType(target.value);
    };
    const handleChangeAdult = ({target}) => {
        setAdult(target.value);
    };
    const handleChangeChildOrder = ({target}) => {
        setChildOrder(target.value);
    };
    const handleChangeAgeStart = ({target}) => {
        setAgeStart(target.value);
    };
    const handleChangeAgeEnd = ({target}) => {
        setAgeEnd(target.value);
    };

    const { handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            name: name,
            adult: adult,
            pricing_policy_id: props.child.id,
            child_order: childOrder,
            child_age_start: ageStart,
            child_age_end: ageEnd,
            type: type === "FREE" ? "FIX" : type,
            value: type === "FREE" ? 0 : val,
            room_rate_channel_mapping_id : rateMappingId
        };
        props.updateChannelChildPricing(params)
    };

    useEffect( () => {


        if (props.updateChildPricingStatus === 200) {
            if(open){
                props.getChannelPaxList({ channel_id : props.channelInformationData.id } );
            }
            handleClose();
        }

    },[props.updateChildPricingStatus, props.errorUpdateChild] )

    return (
        <>
            <ButtonAtm className="edit-channel-price-btn"
                       endIcon={<i className="fas fa-edit" />}
                       onClick={handleClickOpen}
                       title={t('btn-update')}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-channel-pricing-update_child_policy-title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {/* {props.updateChildPricingStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}*/}
                            {isDefined(props.errorUpdateChild) && <Alert severity="error">{props.errorUpdateChild.response.data.message}</Alert>}
                            <Grid container spacing={3} className="mb-20 pos-relative mobile-no-spacing">
                                {/*<Grid item xs={12} sm={4}>
                                        <TextValidator
                                            label={ t('enw-input-room_name') }
                                            type="text"
                                            fullWidth
                                            name="name"
                                            autoComplete="off"
                                            variant="outlined"
                                            value={name} onChange={changeName}
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                        />
                                    </Grid>*/}
                                <Grid item xs={12} sm={4}>
                                    <SelectValidator
                                        name="adult"
                                        onChange={handleChangeAdult}
                                        value={adult}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-channels-pricing-number_of_adults') }
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        {[...Array(10)].map((x, i) =>
                                            <MenuItem value={i+1} key={i}>
                                                {i+1}
                                            </MenuItem>
                                        )}
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SelectValidator
                                        name="childOrder"
                                        onChange={handleChangeChildOrder}
                                        value={childOrder}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-channels-pricing-which_child') }
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        {[...Array(10)].map((x, i) =>
                                            <MenuItem value={i+1} key={i}>
                                                {i+1}.
                                            </MenuItem>
                                        )}
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={6} sm={2} className="m-child-age-policy-first">
                                    <TextValidator
                                        label={ t('enw-channels-pricing-age_range') }
                                        name="ageStart"
                                        fullWidth
                                        autoComplete="off"
                                        value={ageStart} onChange={handleChangeAgeStart}
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        validators={['required','minNumber:0', `maxNumber:${ isDefined(ageEnd) ? ageEnd : 18}`]}
                                        errorMessages={[ t("enw-notification_this_is_required"),`minNumber:0`, `maxNumber:${ isDefined(ageEnd) ? ageEnd : 18}`]}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2} className="m-child-age-policy-second">
                                    <TextValidator
                                        label={ t('enw-channels-pricing-age_range') }
                                        name="ageEnd"
                                        fullWidth
                                        autoComplete="off"
                                        value={ageEnd} onChange={handleChangeAgeEnd}
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        validators={['required', `minNumber:${isDefined(ageStart) ? ageStart : 0}`, 'maxNumber:18',]}
                                        errorMessages={[ t("enw-notification_this_is_required"),`minNumber:${isDefined(ageStart) ? ageStart : 0}`, 'maxNumber 18']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SelectValidator
                                        name="type"
                                        onChange={handleChangeType}
                                        value={type}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-value_type') }
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        <MenuItem value={"FREE"}>
                                            { t('enw-input-free') }
                                        </MenuItem>
                                        <MenuItem value={"PER"}>
                                            { t('enw-input-percent') }
                                        </MenuItem>
                                        <MenuItem value={"FIX"}>
                                            { t('enw-input-fixed') }
                                        </MenuItem>
                                    </SelectValidator>
                                </Grid>
                                {
                                    type !== "FREE" &&
                                    <Grid item xs={12} sm={4}>
                                        <TextValidator
                                            label={ t('enw-input-value') }
                                            name="value"
                                            fullWidth
                                            autoComplete="off"
                                            value={val}
                                            onChange={changeVal}
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                            InputProps={{
                                                endAdornment: <>
                                                    {
                                                        type === "PER" ?
                                                            <InputAdornment position="start">
                                                                %
                                                            </InputAdornment>
                                                            :
                                                            type === "FIX" ?
                                                                <InputAdornment position="start">
                                                                    {currency}
                                                                </InputAdornment>
                                                                :
                                                                <InputAdornment position="start">

                                                                </InputAdornment>
                                                    }
                                                </>
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </>
    );
}
