import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {getLanguages} from "../../../redux/actions/languageActions";
import {compose} from "redux";
import {connect} from "react-redux";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
let t=null;

class Language extends Component {
    static propTypes = {
        i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
        t = props.t;
    }

    componentDidMount() {

        if(this.props.languages.length === 0)
        {
            this.props.getLanguages();
        }
    }

    changeLanguage = (lng) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lng);
    };

    render() {
        return (
            <List component="nav" aria-label="language list" className="language-list">
                {
                    this.props.languages.map( (language) => (
                        <ListItem key={language.code} onClick={() => this.changeLanguage(language.code, language.language_key)} button>
                            <ListItemIcon style={{minWidth: 40}}>
                                <img style={{width:"20px"}} src={`/assets/img/language/${language.code}.svg`} alt={language.code} />
                            </ListItemIcon>
                            <ListItemText primary={t(language.language_key)} />
                        </ListItem>
                    ))
                }
            </List>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        languages : props.languagesReducer.languages,
    }
};

const mapDispatchToProps ={
    getLanguages
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Language));
