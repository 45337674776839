import { getUserToken, getAppLang}                 from "../../utils/index";
import { CREATE_PROPERTY }                 from "../../constants/api.constants";
import axios                            from 'axios';

export function onCreatePropertySubmit() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'CREATE_PROPERTY',
            payload: axios.post(CREATE_PROPERTY, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data )
        })
    }
}
