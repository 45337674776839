//Public Url
export const HOMEPAGE = "/";
export const PRODUCTS = "/products";
export const PRICING = "/pricing";
export const LEGAL_NOTICE = "/legal-notice";
export const PROTECTION_PERSONAL_DATA_ANNOUNCEMENT = "/protection-personal-data-announcement";
export const ONLINE_PRIVACY_POLICY = "/online-privacy-policy";
export const CONTACT = "/contact";
export const LOGIN = "/login";
export const MOBILE_LOGIN = "/mobile-login/:id";
export const MOBILE_LOGIN_SHORT = "/mobile-login/";
export const E404 = "/404";
export const RESET_PASSWORD = "/reset-password";
export const NOT_ALLOWED_PAGE = "/app/403";
export const NOT_ALLOWED_DASHBOARD = "/app/not-purchased/:id";
export const NOT_ALLOWED_DASHBOARD_SHORT = "/app/not-purchased";

//Private Urls
export const APP = "/app";
export const CONTENT = "/app/content";
export const CONTENT_INFORMATION = "/app/content/information";
export const CONTENT_COMMUNICATION = "/app/content/communication";
export const CONTENT_EMPLOYEES = "/app/content/employees";
export const CONTENT_LOCATION = "/app/content/location";
export const CONTENT_BRAND = "/app/content/brand";
export const CONTENT_PHOTOS = "/app/content/photos";
export const CONTENT_ROOMS = "/app/content/rooms";
export const CONTENT_ROOM_AMENITIES = "/app/content/room-amenities";
export const CONTENT_ROOM_PHOTOS = "/app/content/room-photos";
export const CONTENT_FACTS = "/app/content/facts/:id";
export const CONTENT_FACTS_SHORT = "/app/content/facts/";
export const CONTENT_AWARDS_AND_CERTIFICATES = "/app/content/award-and-certificates"

export const CONTENT_PLACES = "/app/content/places"
export const CONTENT_PLACES_PHOTOS = "/app/content/places/photo"
export const CONTENT_PLACES_FACTS = "/app/content/places/facts"

export const NETWORK = "/app/network"
export const NETWORK_RESERVATIONS = "/app/network/reservations"
export const NETWORK_RESERVATION_DETAIL_SHORT = "/app/network/reservation/"
export const NETWORK_RESERVATION_DETAIL = "/app/network/reservation/:id"
export const NETWORK_INVENTORY_LIST = "/app/network/inventory-list"
export const NETWORK_INVENTORY = "/app/network/inventory/:id"
export const NETWORK_GROUP_INVENTORY = "/app/network/group-inventory/:id"
export const NETWORK_CHANNELS = "/app/network/channels"
export const NETWORK_PROMOTION = "/app/network/promotion/:id"
export const NETWORK_PROMOTION_SHORT = "/app/network/promotion"
export const NETWORK_PROMOTION_CHANNEL_LIST = "/app/network/promotion-channel-list"

export const NETWORK_ROOM_AND_RATES = "/app/network/room-and-rates"
export const NETWORK_ROOM_AND_RATES_ID = "/app/network/room-and-rates/:id"
export const NETWORK_ADDONS_CHANNEL_LIST = "/app/network/addons"
export const NETWORK_ADDONS = "/app/network/addons/:id"
export const NETWORK_COUPONS_CHANNEL_LIST = "/app/network/coupons"
export const NETWORK_COUPONS = "/app/network/coupons/:id"

//id olan chnanel linkleri
export const NETWORK_CHANNEL_SETUP = "/app/network/channel-setup/:id"
export const NETWORK_CHANNEL_PAYMENT = "/app/network/channel-payment/:id"
export const NETWORK_CHANNEL_CANCELLATION = "/app/network/channel-cancellation/:id"
export const NETWORK_CHANNEL_INFORMATION = "/app/network/channel-information/:id"
export const NETWORK_CHANNEL_ROOM_RATE = "/app/network/channel-room-rate/:id"
export const NETWORK_CHANNEL_PAX_PRICING = "/app/network/channel-pax-pricing/:id"
export const NETWORK_CHANNEL_CONTACT = "/app/network/channel-contact/:id"


//linklerde kullanılmak için id olmayan chnanel urlleri

export const NETWORK_CHANNEL_SETUP_SHORT = "/app/network/channel-setup/"
export const NETWORK_CHANNEL_PAYMENT_SHORT = "/app/network/channel-payment/"
export const NETWORK_CHANNEL_CANCELLATION_SHORT = "/app/network/channel-cancellation/"
export const NETWORK_CHANNEL_INFORMATION_SHORT = "/app/network/channel-information/"
export const NETWORK_CHANNEL_ROOM_RATE_SHORT = "/app/network/channel-room-rate/"
export const NETWORK_CHANNEL_PAX_PRICING_SHORT = "/app/network/channel-pax-pricing/"
export const NETWORK_CHANNEL_CONTACT_SHORT = "/app/network/channel-contact/"


export const OFFER = "/app/offer"
export const OFFER_CREATE = "/app/offer/create"
export const OFFER_EDIT = "/app/offer/edit/:id"
export const OFFER_EDIT_SHORT = "/app/offer/edit/" // linklerde kullanılması için
export const OFFER_LIST = "/app/offer/list"
export const OFFER_PRICE = "/app/offer/pricing/:id?"
export const OFFER_PRICE_SHORT = "/app/offer/pricing/"

export const WEB = "/app/web"
export const WEB_EDIT = "/app/web/edit/:id"
export const WEB_EDIT_SHORT = "/app/web/edit/" // linklerde kullanılması için
export const WEB_BLOG_LIST = "/app/web/blog-list"
export const WEB_BLOG_CREATE = "/app/web/blog/create"
export const WEB_BLOG_EDIT = "/app/web/blog/edit/:id"
export const WEB_BLOG_EDIT_SHORT = "/app/web/blog/edit"

export const BOOKING_ENGINE = "/app/booking-engine"

export const PAYMENT = "/app/payment"
export const PAYMENT_LIST = "/app/payment/payment-list"
export const PAYMENT_SETUP = "/app/payment/payment-setup/:id"
export const PAYMENT_SETUP_SHORT = "/app/payment/payment-setup/"
export const PAYMENT_INSTALLMENT = "/app/payment/payment-installment/:id"
export const PAYMENT_INSTALLMENT_SHORT = "/app/payment/payment-installment/"
export const PAYMENT_TRANSACTION_LIST = "/app/payment/payment-transaction-list/"
export const PAYMENT_CREATE = "/app/payment/create/"
export const PAYMENT_UPDATE = "/app/payment/update/:id"
export const PAYMENT_UPDATE_SHORT = "/app/payment/update/"
export const PAYMENT_TRANSACTION_DETAIL = "/app/payment/transaction-detail/:id"
export const PAYMENT_TRANSACTION_DETAIL_SHORT = "/app/payment/transaction-detail/"

export const USER_EDIT_PROFILE = "/app/user/edit-profile" //  /app/user/edit-profile
export const USER_UPDATE_PASSWORD = "/app/user/update-password"  // /update-password


export const WEB_POPUP_LIST = "/app/web/popup-list"
export const WEB_POPUP_CREATE = "/app/web/popup/create"
export const WEB_POPUP_EDIT = "/app/web/popup/edit/:id"
export const WEB_POPUP_EDIT_SHORT = "/app/web/popup/edit"
