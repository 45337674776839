import React from "react";
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../../../atomic/Col/Col";
import UpdateChannelContact from "./UpdateChannelContact";
import BlockAtm from "../../../../../atomic/Block/Block";
import OverFlowText from "../../../../../molecules/OverFlowText/OverFlowText";
import {isDefined} from "../../../../../../utils";
import DeleteChannelContact from "./DeleteChannelContact";

const Contact = (props) => {
    const {
        channelContactReducers,
        updateChannelContact,
        deleteChannelContact,
        getChannelContact,
        clearErrorMessage,
        channelId
    } = props;

    return(
        <Grid container spacing={3}>
            {
                isDefined(channelContactReducers) && isDefined(channelContactReducers.channelContactList) &&
                channelContactReducers.channelContactList.map( contact => (
                    <ColAtm className="offer-fact-col pos-relative" md={2} sm={4} xs={6}>
                        <DeleteChannelContact
                            contact={contact}
                            getChannelContact={getChannelContact}
                            deleteChannelContact={deleteChannelContact}
                            channelContactReducers={channelContactReducers}
                            clearErrorMessage={clearErrorMessage}
                            channelId={channelId}
                        />
                        <UpdateChannelContact
                            contact={contact}
                            getChannelContact={getChannelContact}
                            updateChannelContact={updateChannelContact}
                            channelContactReducers={channelContactReducers}
                            clearErrorMessage={clearErrorMessage}
                            channelId={channelId}
                        />
                        <BlockAtm className="offer-fact-border-box fact-border-box channel-group-box">
                            <label htmlFor={"id-" + contact.id}>
                                <label style={{position: 'relative'}}
                                       htmlFor={"id-" + contact.id}>
                                    <i className={"offer-fact-icon fact-icon fas fas fa-id-card-alt"} />
                                    <OverFlowText>
                                        {contact.name}
                                    </OverFlowText>
                                </label>
                            </label>
                        </BlockAtm>
                    </ColAtm>
                ))
            }
        </Grid>
    )
}
export default Contact;
