import React, {Component} from 'react';
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import LabelAtm from "../../../atomic/Label/Label";
import {Link} from "react-router-dom";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
let t =null;

class Error extends Component {
    constructor(props){
        super(props);
        t = props.t;
    }
    render() {
        return (
            <>
                <PageTitle title="404 Not Found - ExtraNetWork" />
                <PageHeaderAtm exitText={ t('btn-back') } backButton="/app" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width">
                            <ColAtm>
                                <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content d-grid">
                                    <i className="fas fa-exclamation-triangle" style={{margin: 'auto', fontSize: '100px', color: '#d71f26', backgroundColor: '#f5f3f7', borderRadius: '135px', padding: '74px 62px'}} />
                                    <LabelAtm labelClass="one-page-title" text="404" />
                                    <LabelAtm labelClass="one-page-subtitle" text={ t('enw-not-found-page_title') } />
                                    <Link className="photoUploadButton error-home-button" to="/app"> { t('enw-not-found-page_btn') } </Link>
                                </ColAtm>
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}

export default compose(withTranslation('common'))( Error);
