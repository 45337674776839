import React, {Component}   from 'react';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PhotoAccordion from "./components/WebEditAccordions";
import {connect} from "react-redux";
import {getEditContentWeb} from "../../../../redux/actions/Web/EditWeb/getEditContentWebActions";
import {updateEditContentWeb, updatePreviewEditContentWeb} from "../../../../redux/actions/Web/EditWeb/updateEditContentWebActions";
import {WEB} from "../../../../constants/appUrls.constants";

let t =null;

class EditWeb extends Component {
    constructor(props) {
        super(props);
        t = props.t;

        this.state = {
            property_web_id : '',
            open : false,
        };
    }

    componentDidMount() {

        if ( this.props.match.params.id )
        {
            this.props.getEditContentWeb( { property_web_id : this.props.match.params.id } );

            this.setState({
                property_web_id:this.props.match.params.id
            })
        }
        else{
            this.props.history.replace(WEB);
        }
    }

    handleClickOpen = () => {
        this.setState({open:true});
    };

    handleClose = () => {
        this.setState({open:false});
    };

    render() {
        return (
            <>
                <PageTitle title={ t('enw-web-edit_page-page_title') } />
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={WEB} text={ t('enw-web-edit-page_title') } />
                    {
                        !this.props.getEditContentWebReducer.fetching &&
                        <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                            <Container maxWidth="xl">
                                <div className="container-width">
                                    <ColAtm className="edit-web">
                                        <PhotoAccordion
                                            getEditContentWeb={this.props.getEditContentWebReducer}
                                            saveEditContentWeb={this.props.updateEditContentWebReducer}
                                            updateEditContentWeb={this.props.updateEditContentWeb}
                                            updatePreviewEditContentWeb={this.props.updatePreviewEditContentWeb}
                                            handleClickOpen = {this.handleClickOpen}
                                            handleClose = {this.handleClose}
                                            open={this.state.open}
                                        />
                                    </ColAtm>
                                </div>
                            </Container>
                        </Animated>
                    }
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        getEditContentWebReducer : props.getEditContentWebReducer,
        updateEditContentWebReducer: props.updateEditContentWebReducer
    }
};

const mapDispatchToProps ={
    getEditContentWeb,
    updateEditContentWeb,
    updatePreviewEditContentWeb
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(EditWeb));
