import { propertyConstants} from "../../constants/Public/property.constants";
import { getUserToken }  from "../../utils/index";
import { LIST_PROPERTY } from "../../constants/api.constants";
import axios from 'axios';

export const getPropertyList = () => {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : 'tr'},
        body: {params: {
                "locale" : "en" //TODO: dil localstoragedan çekilecek
            }}
    };
    return (dispatch) => {
        return axios.post(LIST_PROPERTY, requestOptions.body, {
            headers: requestOptions.headers
        })
            .then(response => {
                dispatch(getPropertyListSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const getPropertyListSuccess =  (data) => {
    return {
        type: propertyConstants.PROPERTY_SUCCESS,
        property_list: data.data.property_list
    }
};
