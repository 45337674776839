import { userConstants } from "../../../../constants/Public/user.constants";

const initialState = {
    fetching :false,
    updateFetching :false,
    profileData: [],
    updateProfileData: [],
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_PROFILE_PENDING:
            return {
                ...state,
                fetching: true,
                updateFetching: false,
                profileData:[],
                updateProfileData:[],
                error: {}
            };
        case userConstants.GET_PROFILE_FULFILLED:
            return {
                ...state,
                fetching : false,
                profileData: action.payload,
                error: {}
            };
        case userConstants.GET_PROFILE_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.payload
            };
        // UPDATE_PROFILE
        case userConstants.UPDATE_PROFILE_PENDING:
            return {
                ...state,
                updateFetching: true,
                updateProfileData:[],
            };
        case userConstants.UPDATE_PROFILE_FULFILLED:
            return {
                ...state,
                updateFetching : false,
                updateProfileData: action.payload,
            };
        case userConstants.UPDATE_PROFILE_REJECTED:
            return {
                ...state,
                updateFetching : false,
                error: action.payload
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
