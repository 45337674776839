export const getAwardsCertificates = {
    GET_AWARDS_CERTIFICATES_PENDING  : 'GET_AWARDS_CERTIFICATES_PENDING',
    GET_AWARDS_CERTIFICATES_FULFILLED: 'GET_AWARDS_CERTIFICATES_FULFILLED',
    GET_AWARDS_CERTIFICATES_REJECTED : 'GET_AWARDS_CERTIFICATES_REJECTED',
};

export const getAwardsCertificatesCategories = {
    GET_AWARDS_CERTIFICATES_CATEGORIES_PENDING  : 'GET_AWARDS_CERTIFICATES_CATEGORIES_PENDING',
    GET_AWARDS_CERTIFICATES_CATEGORIES_FULFILLED: 'GET_AWARDS_CERTIFICATES_CATEGORIES_FULFILLED',
    GET_AWARDS_CERTIFICATES_CATEGORIES_REJECTED : 'GET_AWARDS_CERTIFICATES_CATEGORIES_REJECTED',
};

export const createAwardsCertificates = {
    CREATE_AWARDS_CERTIFICATES_PENDING  : 'CREATE_AWARDS_CERTIFICATES_PENDING',
    CREATE_AWARDS_CERTIFICATES_FULFILLED: 'CREATE_AWARDS_CERTIFICATES_FULFILLED',
    CREATE_AWARDS_CERTIFICATES_REJECTED : 'CREATE_AWARDS_CERTIFICATES_REJECTED',
};

export const updateAwardsCertificates = {
    UPDATE_AWARDS_CERTIFICATES_PENDING  : 'UPDATE_AWARDS_CERTIFICATES_PENDING',
    UPDATE_AWARDS_CERTIFICATES_FULFILLED: 'UPDATE_AWARDS_CERTIFICATES_FULFILLED',
    UPDATE_AWARDS_CERTIFICATES_REJECTED : 'UPDATE_AWARDS_CERTIFICATES_REJECTED',
};

export const deleteAwardsCertificatesPhoto = {
    DELETE_AWARDS_CERTIFICATES_PHOTO_PENDING  : 'DELETE_AWARDS_CERTIFICATES_PHOTO_PENDING',
    DELETE_AWARDS_CERTIFICATES_PHOTO_FULFILLED: 'DELETE_AWARDS_CERTIFICATES_PHOTO_FULFILLED',
    DELETE_AWARDS_CERTIFICATES_PHOTO_REJECTED : 'DELETE_AWARDS_CERTIFICATES_PHOTO_REJECTED',
};

export const awardsCertificatesUpdateStatus = {
    AWARDS_CERTIFICATES_UPDATE_STATUS_PENDING  : 'AWARDS_CERTIFICATES_UPDATE_STATUS_PENDING',
    AWARDS_CERTIFICATES_UPDATE_STATUS_FULFILLED: 'AWARDS_CERTIFICATES_UPDATE_STATUS_FULFILLED',
    AWARDS_CERTIFICATES_UPDATE_STATUS_REJECTED : 'AWARDS_CERTIFICATES_UPDATE_STATUS_REJECTED',
};
export const clearAwardsCertificatesError = {
    AWARDS_CERTIFICATES_CLEAR_ERROR_STATE : 'AWARDS_CERTIFICATES_CLEAR_ERROR_STATE',
};
