import React from "react";
import {connect} from "react-redux";
import { Redirect } from 'react-router-dom';
import {getFactList} from "../../../../redux/actions/Content/FactList/factListActions";
import Facts from "./components/Facts";

class FactList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            property_brand : null,
            sub_category_id : '',
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        if ( this.props.match.params.id ) {
            this.props.getFactList( this.props.match.params.id );

            this.setState({
                sub_category_id: this.props.match.params.id
            })
        }
        else {
            return <Redirect to="/404" />
        }

    }

    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id) {
            this.props.getFactList(nextProps.match.params.id);
        }
    }

    render() {
        return (
            <Facts
                FactListData = {this.props.FactListReducer.factData}
                getMenusData = {this.props.FactListReducer.getMenus}
                this_main_category = {this.props.FactListReducer.this_main_category}
                this_sub_category = {this.props.FactListReducer.this_sub_category}
                fetching = {this.props.FactListReducer.fetching}
                pageId={this.props.match.params.id}
            />
        )
    }
}


const mapStateToProps = (props) => {
    return {
        FactListReducer : props.FactListReducer
    }
};

const mapDispatchToProps ={
    getFactList
};


export default connect(mapStateToProps, mapDispatchToProps )(FactList);
