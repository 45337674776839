import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

let pageLocation = window.location.pathname.split('/');

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      backend:{
          loadPath: '/language/{{lng}}/{{ns}}.json'
      },
      fallbackLng: "en",
      debug: false,
      detection:{
          order: ['localStorage','navigator','cookie'],

          //Secim yapılmış arayacak Keyler
          lookupCookie: 'lng',
          lookupLocalStorage: pageLocation[1] === "offer" ? "offerLang" : 'lng',

          //dillerin saklayacağımız alanllar
          caches: ['localStorage','cookie'],
      },

      // ortak kullanılan namespace kısmı
      ns: ['common'],
      defaultNS: 'common',

      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      react: {
        //wait: true
          useSuspense: false
      }
    });

export default i18n;
