import { offerConstants } from "../../../../constants/Offer/offer.constants";

const initialState = {
    fetching :false,
    offerData: [],
    offerUpdateData :[],
    offerUpdateStatus : false,
    offerAcceptStatus : false,
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offerConstants.GET_OFFER_PENDING:
            return {
                ...state,
                fetching:true,
                offerData: [],
                offerUpdateStatus:false,
                offerAcceptStatus:false,
                error    :{}
            };
        case offerConstants.GET_OFFER_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerData: action.payload,
            };
        case offerConstants.GET_OFFER_REJECTED:
            return {
                ...state,
                fetching : false,
                error: action.payload //TODO : error kısmı iş sonu bakılcack
            };
        // OFFER UPDATE
        case offerConstants.UPDATE_OFFER_PENDING:
            return {
                ...state,
                fetching:true,
                offerUpdateStatus:false,
                error    :{}
            };
        case offerConstants.UPDATE_OFFER_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerUpdateData: action.payload,
                offerUpdateStatus: true
            };
        case offerConstants.UPDATE_OFFER_REJECTED:
            return {
                ...state,
                fetching : false,
                offerUpdateStatus:false,
                error: action.payload //TODO : error kısmı iş sonu bakılcack
            };
        // OFFER STATUS UPDATE
        case offerConstants.UPDATE_OFFER_STATUS_PENDING:
            return {
                ...state,
                fetching:true,
                offerUpdateStatus:false,
                error    :{}
            };
        case offerConstants.UPDATE_OFFER_STATUS_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerUpdateData: action.payload,
                offerUpdateStatus: true
            };
        case offerConstants.UPDATE_OFFER_STATUS_REJECTED:
            return {
                ...state,
                fetching : false,
                offerUpdateStatus:false,
                error: action.payload //TODO : error kısmı iş sonu bakılcack
            };
        // OFFER ACCEPT STATUS UPDATE
        case offerConstants.OFFER_ACCEPT_STATUS_PENDING:
            return {
                ...state,
                fetching:true,
                offerUpdateStatus:false,
                offerAcceptStatus:false,
                error    :{}
            };
        case offerConstants.OFFER_ACCEPT_STATUS_FULFILLED:
            return {
                ...state,
                fetching : false,
                offerUpdateStatus:true,
                offerAcceptStatus: action.payload
            };
        case offerConstants.OFFER_ACCEPT_STATUS_REJECTED:
            return {
                ...state,
                fetching : false,
                offerAcceptStatus:false,
                error: action.payload
            };
        default:
            return state
    }
}
