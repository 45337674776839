import axios                                   from 'axios';
import {getUserToken, getProperty, getAppLang} from "../../../../utils";
import {GET_OFFER, OFFER_ACCEPT_STATUS, UPDATE_OFFER, UPDATE_OFFER_STATUS} from "../../../../constants/api.constants";


export function getEditOffer(offerId) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id" : getProperty(),
                "offer_id" : offerId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_OFFER',
            payload: axios.post(GET_OFFER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateOffer(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "locale"                 : getAppLang(),
                "property_id"            : getProperty(),
                "offer_id"               : param.offer_id,
                "title"                  : param.title,
                "email"                  : param.email,
                "currency"               : param.currency,
                "confirm_type"           : param.confirm_type,
                "payment_type_mapping_id": param.payment_list_type,
                "expire_date"            : param.expire_date,
                "language"               : param.language,
                "fact_mapping"           : param.fact_mapping,
                "hotel_features_mapping" : param.hotel_features_mapping,
                "contact_mapping"        : param.contact_mapping,
                "room_mapping"           : param.room_mapping,
                "accommodation_mapping"  : param.accommodation_mapping,
                "photo_mapping"          : param.photo_mapping,
                "cover_photos"           : param.cover_photos,
                "important_notes"        : param.important_notes,
                "has_cancellation_policy": param.has_cancellation_policy,
                "cancellation_policy"    : param.cancellation_policy,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_OFFER',
            payload: axios.post(UPDATE_OFFER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function updateOfferStatus(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                "property_id"            : getProperty(),
                "offer_id"                 : param.offer_id,
                "status"                 : param.status,

            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_OFFER_STATUS',
            payload: axios.post(UPDATE_OFFER_STATUS, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function offerAcceptStatus(param) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authToken': getUserToken(),
            'language' : getAppLang()
        },
        body: {
            params: {
                property_id: getProperty(),
                offer_id: param.offer_id,
                accept_status: 1,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'OFFER_ACCEPT_STATUS',
            payload: axios.post(OFFER_ACCEPT_STATUS, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}