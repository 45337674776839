import React, {Component}   from 'react';
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {PAYMENT_TRANSACTION_LIST} from "../../../../constants/appUrls.constants";
import {getPaymentManualTransactionList} from "../../../../redux/actions/Payment/PaymentTransactionDetail.actions";
import Detail from "./components/Detail";
let t =null;

class TransactionDetail extends Component {
    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        if(this.props.match.params.id ){
            this.props.getPaymentManualTransactionList(this.props.match.params.id);
        }else{
            this.props.history.replace(PAYMENT_TRANSACTION_LIST);
        }
    }

    render() {

        return (
            <>
                <PageTitle title={ t('enw-payment-manual_payment_detail-page_title') } />
                <PageHeaderAtm exitText={ t('enw-payment-transaction_list-page_title') } backButton={PAYMENT_TRANSACTION_LIST} text={ t('enw-payment-manual_payment_detail-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width reservation-detail">
                            {
                                !this.props.fetching && this.props.manualTransactionList &&
                                Object.keys(this.props.manualTransactionList).length > 0 ?
                                <Detail manualTransactionList={this.props.manualTransactionList} /> : <></>
                            }
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (props) => {
    return{
        fetching : props.paymentManualTransactionListReducer.fetching,
        manualTransactionList : props.paymentManualTransactionListReducer.manualTransactionList,
        error : props.paymentManualTransactionListReducer.error
    }
};

const mapDispatchToProps = {
    getPaymentManualTransactionList
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(TransactionDetail));
