import {networkChannelPriceingAdultPolicyUpdate ,
    networkChannelPriceingChildPolicyUpdate} from "../../../../../constants/Network/Channels/channelPaxPricing.constans";

const initialState = {
    fetching : false,
    updateChildPricingPolicyStatus: null,
    updateAdultPricingPolicyStatus: null,
    error:{}
};


export default function (state = initialState, action) {
    switch (action.type) {
        case networkChannelPriceingChildPolicyUpdate.NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_PENDING:
            return {
                ...state,
                fetching :true,
                updateChildPricingPolicyStatus:null,
                updateAdultPricingPolicyStatus:null,
                error:{}
            }
        case networkChannelPriceingChildPolicyUpdate.NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_FULFILLED:
            return {
                ...state,
                fetching :false,
                updateChildPricingPolicyStatus:action.payload?.status
            }
        case networkChannelPriceingChildPolicyUpdate.NETWORK_CHANNEL_PRICING_CHILD_POLICY_UPDATE_REJECTED:
            return {
                ...state,
                fetching :false,
                updateChildPricingPolicyStatus:null,
                updateAdultPricingPolicyStatus:null,
                error: action.payload
            }

         //Adult
        case networkChannelPriceingAdultPolicyUpdate.NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_PENDING:
            return {
                ...state,
                fetching : true,
                updateChildPricingPolicyStatus:null,
                updateAdultPricingPolicyStatus:null,
                error:{}
            }
        case networkChannelPriceingAdultPolicyUpdate.NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_FULFILLED:
            return {
                ...state,
                fetching : false,
                updateAdultPricingPolicyStatus:action.payload?.status
            }
        case networkChannelPriceingAdultPolicyUpdate.NETWORK_CHANNEL_PRICING_ADULT_POLICY_UPDATE_REJECTED:
            return {
                ...state,
                fetching : false,
                updateAdultPricingPolicyStatus:null,
                updateChildPricingPolicyStatus:null,
                error: action.payload
            }
        case networkChannelPriceingAdultPolicyUpdate.NETWORK_CHANNEL_PRICING_POLICY_CLEAR_STATE_UPDATE:
            return {...initialState}
        default:
            return state
    }
}
