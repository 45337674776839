import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";
import PaymentsBox from "./PaymentsBox";
import {isDefined} from "../../../../../utils";
import Grid from "@material-ui/core/Grid";

const PaymentList = (props) => {

    const {
        paymentTypeList
    } = props;

    const paymentPolicyListError = useSelector(props => props.paymentMapping?.errorPaymentTypeList)

    useEffect( () => {},[paymentPolicyListError]);

    const showMessage = () => {

        if(paymentPolicyListError?.response?.status === 400 || paymentPolicyListError?.response?.status === 500)
        {
            let message = paymentPolicyListError?.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }

    }

    return(
        <Grid container className="p-8">
            {
                isDefined(paymentTypeList.data) && paymentTypeList.data.payment_types.map( paymentListData => (
                    <PaymentsBox
                        paymentTypeList={paymentListData}
                    />
                ))

            }
            {
                showMessage()
            }
        </Grid>
    )
}

export default PaymentList;
