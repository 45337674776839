import axios from 'axios';
import { getUserToken,getProperty ,getAppLang}  from "../../utils/index";
import { UPDATE_PROPERTY_CONTACT, GET_PROPERTY_CONTACT } from "../../constants/api.constants";

export function getContactList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                "property_id" : getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'FACILITY_CONTACT',
            payload: axios.post(GET_PROPERTY_CONTACT, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

export function onFacilityContactSubmit(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                contact:
                        {
                            phone: params.phone,
                            mobile:params.mobile,
                            mobile2:  params.mobile2,
                            fax: params.fax,
                            email: params.email,
                            web: params.web,
                            social_media_addresses : {
                                facebook  : params.facebook,
                                twitter     : params.twitter,
                                instagram   : params.instagram,
                            }
                        },
                property_info: {
                    official_name: params.official_name,
                    tax_office: params.tax_office,
                    tax_number: params.tax_number,
                }
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'UPDATE_FACILITY_CONTACT',
            payload: axios.post(UPDATE_PROPERTY_CONTACT, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
