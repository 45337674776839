import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ children }) {
    const { pathname } = useLocation();

    useEffect(() => {
        document.body.scrollTop = 0;
        if (window.location.hash === "#contentproduct") document.body.scrollTop = 550;
        if (window.location.hash === "#networkproduct") document.body.scrollTop = 1650;
        if (window.location.hash === "#offerproduct") document.body.scrollTop = 2650;
        if (window.location.hash === "#mywebproduct") document.body.scrollTop = 3750;
        if (window.location.hash === "#bookingproduct") document.body.scrollTop = 4850;
        if (window.location.hash === "#paymentproduct") document.body.scrollTop = 5950;
        if (window.location.hash === "#contact-faq") document.body.scrollTop = 800;
    }, [pathname]);

    return children;
}


export default ScrollToTop;
