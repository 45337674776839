import {popupConstants} from "../../../../constants/Web/popup.constants";

const initialState = {
    fetching: false,
    listData: [],
    listDataError: false,

    createPopupFetching: false,
    createPopup: [],
    createPopupError: false,

    updatePopupFetching: false,
    updatePopup: [],
    updatePopupError: false,

    createImageFetching: false,
    createImage: [],
    createImageError: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case popupConstants.POPUP_LIST_PENDING:
            return {
                ...state,
                fetching: true,
            };
        case popupConstants.POPUP_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                listDataError: false,
                listData: action.payload,
            };
        case popupConstants.POPUP_LIST_REJECTED:
            return {
                ...state,
                fetching: false,
                listDataError: action.payload
            }

        case popupConstants.CREATE_POPUP_PENDING:
            return {
                ...state,
                createPopupFetching: true,
            };
        case popupConstants.CREATE_POPUP_FULFILLED:
            return {
                ...state,
                createPopupFetching: false,
                createPopupError: false,
                createPopup: action.payload,
            };
        case popupConstants.CREATE_POPUP_REJECTED:
            return {
                ...state,
                createPopupFetching: false,
                createPopupError: action.payload
            };
        case popupConstants.CREATE_POPUP_CLEAR:
            return {
                ...state,
                createPopupFetching: false,
                createPopup: [],
                createPopupError: false,
            };

        case popupConstants.UPDATE_POPUP_PENDING:
            return {
                ...state,
                updatePopupFetching: true,
            };
        case popupConstants.UPDATE_POPUP_FULFILLED:
            return {
                ...state,
                updatePopupFetching: false,
                updatePopupError: false,
                updatePopup: action.payload,
            };
        case popupConstants.UPDATE_POPUP_REJECTED:
            return {
                ...state,
                updatePopupFetching: false,
                updatePopupError: action.payload
            }
        case popupConstants.UPDATE_POPUP_CLEAR:
            return {
                ...state,
                updatePopupFetching: false,
                updatePopup: [],
                updatePopupError: false,
            };

        case popupConstants.CREATE_IMAGE_POPUP_PENDING:
            return {
                ...state,
                createImageFetching: true,
            };
        case popupConstants.CREATE_IMAGE_POPUP_FULFILLED:
            return {
                ...state,
                createImageFetching: false,
                createImageError: false,
                createImage: action.payload,
            };
        case popupConstants.CREATE_IMAGE_POPUP_REJECTED:
            return {
                ...state,
                createImageFetching: false,
                createImageError: action.payload
            }
        case popupConstants.CREATE_IMAGE_POPUP_CLEAR:
            return {
                ...state,
                createImageFetching: false,
                createImage: [],
                createImageError: false,
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
