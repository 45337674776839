import {bedTypesList} from "../../constants/Network/RoomAndRates/bedType.constants";

const initialState = {
    fetching : false,
    status : false,
    allBedType:[],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case bedTypesList.BED_TYPE_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case bedTypesList.BED_TYPE_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                allBedType: action.payload
            };
        case bedTypesList.BED_TYPE_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                status: false
            };
        default:
            return state
    }
}
