export const propertyRoomAndRateList = {
    PROPERTY_ROOM_AND_RATE_LIST_PENDING  : 'PROPERTY_ROOM_AND_RATE_LIST_PENDING',
    PROPERTY_ROOM_AND_RATE_LIST_FULFILLED: 'PROPERTY_ROOM_AND_RATE_LIST_FULFILLED',
    PROPERTY_ROOM_AND_RATE_LIST_REJECTED : 'PROPERTY_ROOM_AND_RATE_LIST_REJECTED',

    PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_PENDING  : 'PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_PENDING',
    PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_FULFILLED: 'PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_FULFILLED',
    PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_REJECTED : 'PROPERTY_ROOM_AND_RATE_LIST_FOR_RATE_REJECTED',

    PROPERTY_ROOM_TYPE_PENDING  : 'PROPERTY_ROOM_TYPE_PENDING',
    PROPERTY_ROOM_TYPE_FULFILLED: 'PROPERTY_ROOM_TYPE_FULFILLED',
    PROPERTY_ROOM_TYPE_REJECTED : 'PROPERTY_ROOM_TYPE_REJECTED',

    PROPERTY_ROOM_RATE_PENDING  : 'PROPERTY_ROOM_RATE_PENDING',
    PROPERTY_ROOM_RATE_FULFILLED: 'PROPERTY_ROOM_RATE_FULFILLED',
    PROPERTY_ROOM_RATE_REJECTED : 'PROPERTY_ROOM_RATE_REJECTED',

    PROPERTY_ROOM_AND_BED_ADD_PENDING  : 'PROPERTY_ROOM_AND_BED_ADD_PENDING',
    PROPERTY_ROOM_AND_BED_ADD_FULFILLED: 'PROPERTY_ROOM_AND_BED_ADD_FULFILLED',
    PROPERTY_ROOM_AND_BED_ADD_REJECTED : 'PROPERTY_ROOM_AND_BED_ADD_REJECTED',
    PROPERTY_ROOM_AND_BED_ADD_CLEAR_DATA : 'PROPERTY_ROOM_AND_BED_ADD_CLEAR_DATA',

    PROPERTY_RATE_PLAN_ADD_PENDING  : 'PROPERTY_RATE_PLAN_ADD_PENDING',
    PROPERTY_RATE_PLAN_ADD_FULFILLED: 'PROPERTY_RATE_PLAN_ADD_FULFILLED',
    PROPERTY_RATE_PLAN_ADD_REJECTED : 'PROPERTY_RATE_PLAN_ADD_REJECTED',

    PROPERTY_FACT_SEARCH_LIST_PENDING  : 'PROPERTY_FACT_SEARCH_LIST_PENDING',
    PROPERTY_FACT_SEARCH_LIST_FULFILLED: 'PROPERTY_FACT_SEARCH_LIST_FULFILLED',
    PROPERTY_FACT_SEARCH_LIST_REJECTED : 'PROPERTY_FACT_SEARCH_LIST_REJECTED',

    PROPERTY_ASSIGN_RATE_PENDING  : 'PROPERTY_ASSIGN_RATE_PENDING',
    PROPERTY_ASSIGN_RATE_FULFILLED: 'PROPERTY_ASSIGN_RATE_FULFILLED',
    PROPERTY_ASSIGN_RATE_REJECTED : 'PROPERTY_ASSIGN_RATE_REJECTED',

    GET_EDIT_ROOM_PENDING  : 'GET_EDIT_ROOM_PENDING',
    GET_EDIT_ROOM_FULFILLED: 'GET_EDIT_ROOM_FULFILLED',
    GET_EDIT_ROOM_REJECTED : 'GET_EDIT_ROOM_REJECTED',

    UPDATE_ROOM_PENDING  : 'UPDATE_ROOM_PENDING',
    UPDATE_ROOM_FULFILLED: 'UPDATE_ROOM_FULFILLED',
    UPDATE_ROOM_REJECTED : 'UPDATE_ROOM_REJECTED',

    GET_ACCOMMODATION_TYPES_PENDING  : 'GET_ACCOMMODATION_TYPES_PENDING',
    GET_ACCOMMODATION_TYPES_FULFILLED: 'GET_ACCOMMODATION_TYPES_FULFILLED',
    GET_ACCOMMODATION_TYPES_REJECTED : 'GET_ACCOMMODATION_TYPES_REJECTED',

    PROPERTY_ROOM_AND_BED_UPDATE_PENDING  : 'PROPERTY_ROOM_AND_BED_UPDATE_PENDING',
    PROPERTY_ROOM_AND_BED_UPDATE_FULFILLED: 'PROPERTY_ROOM_AND_BED_UPDATE_FULFILLED',
    PROPERTY_ROOM_AND_BED_UPDATE_REJECTED : 'PROPERTY_ROOM_AND_BED_UPDATE_REJECTED',
    PROPERTY_ROOM_AND_BED_UPDATE_CLEAR_DATA : 'PROPERTY_ROOM_AND_BED_UPDATE_CLEAR_DATA',

    GET_EDIT_RATE_PENDING  : 'GET_EDIT_RATE_PENDING',
    GET_EDIT_RATE_FULFILLED: 'GET_EDIT_RATE_FULFILLED',
    GET_EDIT_RATE_REJECTED : 'GET_EDIT_RATE_REJECTED',
    GET_EDIT_RATE_CLEAR_DATA : 'GET_EDIT_RATE_CLEAR_DATA',

    PROPERTY_EDIT_RATE_UPDATE_PENDING  : 'PROPERTY_EDIT_RATE_UPDATE_PENDING',
    PROPERTY_EDIT_RATE_UPDATE_FULFILLED: 'PROPERTY_EDIT_RATE_UPDATE_FULFILLED',
    PROPERTY_EDIT_RATE_UPDATE_REJECTED : 'PROPERTY_EDIT_RATE_UPDATE_REJECTED',

    CONNECT_RATE_UPDATE_PENDING  : 'CONNECT_RATE_UPDATE_PENDING',
    CONNECT_RATE_UPDATE_FULFILLED: 'CONNECT_RATE_UPDATE_FULFILLED',
    CONNECT_RATE_UPDATE_REJECTED : 'CONNECT_RATE_UPDATE_REJECTED',
    CONNECT_RATE_UPDATE_CLEAR_DATA : 'CONNECT_RATE_UPDATE_CLEAR_DATA',
};
