import axios                                   from 'axios';
import {getUserToken, getAppLang} from "../../../../utils";
import {APPROVE_OFFER} from "../../../../constants/api.constants";


export function approveOfferSubmit(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                offer_id : params.offer_id,
                offer_code : params.offer_code
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'APPROVE_OFFER',
            payload: axios.post(APPROVE_OFFER, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}


