import React, { Component } from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Drawer           from '@material-ui/core/Drawer';
import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemText     from '@material-ui/core/ListItemText';
import CssBaseline      from '@material-ui/core/CssBaseline';
import clsx             from 'clsx';
import Card             from '@material-ui/core/Card';
import Grid             from '@material-ui/core/Grid';
import ColAtm           from "../../../atomic/Col/Col";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {dashboardPropertyActions} from "../../../../redux/actions/dasboardActions";
import 'react-circular-progressbar/dist/styles.css';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {userMenu} from "../../../../redux/actions/menuActions";
import {Link} from "react-router-dom";
import 'pure-react-carousel/dist/react-carousel.es.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {Hidden} from "@material-ui/core";
import {
    APP,
    BOOKING_ENGINE, CONTENT,
    NETWORK, NOT_ALLOWED_DASHBOARD_SHORT,
    OFFER,
    PAYMENT,
    WEB
} from "../../../../constants/appUrls.constants";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {history} from "../../../../utils/history";
import {getPropertyName, isDefined} from "../../../../utils";
import {propertyProducts} from "../../../../redux/actions/propertyProductsActions";
import NotAllowedPage from "./NotAllowedPage";
import Charts from "../Charts";

const drawerWidth = 101;
const leftMenuWidth = 252;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: '999',
    },
    leftMenu: {
        width: 0,
        flexShrink: 0,
    },
    leftMenuPaper: {
        width: leftMenuWidth,
        marginLeft: 102,
        marginTop: 81
    },
    drawerPaper: {
        width: drawerWidth,
        height: '-webkit-fill-available',
        marginTop: '81px',
        display: 'inline-grid'
    },
    listItem: {
        display: 'block',
        textAlign: 'center !important',
        margin: 'auto',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftBarShift: {
        width: `calc(100% - ${leftMenuWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        boxShadow: '0 2px 4px 0 #ccced5',
        backgroundColor: '#ffffff',
        padding: '11px 12.14px'
    },
    hide: {
        display: 'none',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 125,
        marginRight: -60,
        paddingRight: 120,
    },
    content: {
        flexGrow: 1,
        padding: '24px 68px',
    },
    toolbar: theme.mixins.toolbar,
}));

class NotPurchasedDashboard extends Component{
    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        const {dashboardPropertyActions, userMenu, propertyProducts} = this.props;
        dashboardPropertyActions();
        userMenu();
        propertyProducts();
    }

    render(){
        const dashboard = this.props.property_dashboard;
        const leftDrawer = this.props.left_drawer;
        const { t } = this.props;

        const allowedPageNetwork = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "network" === item.route)

        const allowedPageOffer = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "offer" === item.route)

        const allowedPageWeb = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "web" === item.route)

        const allowedPageBookingEngine = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "booking-engine" === item.route)

        const allowedPagePayment = !this.props.propertyProductsReducer.fetching &&
            isDefined(this.props.propertyProductsReducer) &&
            isDefined(this.props.propertyProductsReducer.get_products) &&
            Object.values(this.props.propertyProductsReducer.get_products.data).filter(item => "payment" === item.route)

        function Dashboard(props) {
            const classes = useStyles();




            function Sidebar(props) {

                const sidebar = (
                    <div className={classes.root}>
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <List className={classes.listItem}>
                                {props.siderbarItems.map((post, key) =>
                                    <Link key={key} to={post.link} className="completed-title" variant="body2" color="textSecondary" >
                                        <ListItem className={post.isActive === true ? 'active-sideMenu sidebar-list': 'sidebar-list'} button key={post.id}>
                                            <div className={post.isActive === true ? 'active-sidebar': 'sidebar-items'}>
                                                <i className={post.icon} />
                                            </div>
                                            <ListItemText className="dashboard-menu" primary={post.text} />
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                            <Charts />
                        </Drawer>
                    </div>
                );
                return (
                    <>
                        {sidebar}
                    </>
                );
            }

            const siderbarItems = [
                {id: 1, isActive: props.lastUrl === "content" ? true : false, text:  `${ t('enw-product_one-title') }`, icon: 'fas fa-hotel', link:CONTENT},
                {id: 2, isActive: props.lastUrl === "network" ? true : false, text: `${t('enw-product_two-title') }`, icon: 'fas fa-chart-network', link: isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" },
                {id: 3, isActive: props.lastUrl === "offer" ? true : false, text: `${t('enw-product_three-title') }`, icon: 'fas fa-paperclip', link: isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? OFFER : NOT_ALLOWED_DASHBOARD_SHORT + "/offer" },
                {id: 4, isActive: props.lastUrl === "web" ? true : false, text: `${t('enw-product_four-title') }`, icon: 'fas fa-network-wired', link: isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? WEB :  NOT_ALLOWED_DASHBOARD_SHORT + "/web" },
                {id: 5, isActive: props.lastUrl === "booking-engine" ? true : false, text: `${t('enw-product_six-title')}`, icon: 'fas fa-calendar-alt', link: isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? BOOKING_ENGINE :  NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine" },
                {id: 6, isActive: props.lastUrl === "payment" ? true : false, text: `${t('enw-product_five-title') }`, icon: 'fas fa-money-bill-wave-alt', link: isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? PAYMENT :  NOT_ALLOWED_DASHBOARD_SHORT + "/payment" }
            ];

            return (
                <ColAtm>
                    <Hidden smDown>
                        <ColAtm md={1} style={{ height: 'fit-content'}}>
                            <Sidebar siderbarItems={siderbarItems} />
                        </ColAtm>
                    </Hidden>
                    <ColAtm sm={8} md={11} className="m-auto">
                        <div className={classes.root}>
                            <CssBaseline />
                            <main
                                className={clsx("dashboard-content")}
                            >
                                <div className={classes.drawerHeader} />
                                <div className={classes.root}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={11}>
                                            <Hidden xsDown>
                                                <Card className="p-24 no-buy-product-content">
                                                    <NotAllowedPage />
                                                    <Carousel>
                                                    </Carousel>
                                                </Card>
                                            </Hidden>
                                            <Hidden smUp>
                                                <Card className="p-24 no-shadow">
                                                    <NotAllowedPage />
                                                    <Carousel>
                                                    </Carousel>
                                                </Card>
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                </div>
                            </main>
                        </div>
                    </ColAtm>
                </ColAtm>
            );
        }


        return(
            <>
                <PageTitle title={ t('enw-dashboard-page_title') } />
                <PageHeaderAtm exitText={ t('btn-properties') } backButton={APP} text={ getPropertyName() } />
                <Container maxWidth="xl">
                    <div className="dashboard-width">
                        <Dashboard
                            dashboard={dashboard}
                            leftDrawer={leftDrawer}
                            propertyProductsReducer={this.props.propertyProductsReducer}
                            lastUrl={this.props.match.params.id}
                        />
                    </div>
                </Container>
                <Hidden mdUp>
                    <BottomNavigation className="m-navigation" value={this.props.match.params.id}>
                        <BottomNavigationAction value="content" icon={<i className="fas fa-hotel" />} onClick={() => { history.push(CONTENT)}}  />
                        <BottomNavigationAction value="network" icon={<i className="fas fa-chart-network" />} onClick={() => { history.push(isDefined(allowedPageNetwork[0]) && allowedPageNetwork[0].property_has_product ? NETWORK : NOT_ALLOWED_DASHBOARD_SHORT + "/network" )}} />
                        <BottomNavigationAction value="offer" icon={<i className="fas fa-paperclip" />} onClick={() => { history.push(isDefined(allowedPageOffer[0]) && allowedPageOffer[0].property_has_product ? OFFER : NOT_ALLOWED_DASHBOARD_SHORT + "/offer")}} />
                        <BottomNavigationAction value="web" icon={<i className="fas fa-network-wired" />} onClick={() => { history.push(isDefined(allowedPageWeb[0]) && allowedPageWeb[0].property_has_product ? WEB :  NOT_ALLOWED_DASHBOARD_SHORT + "/web")}} />
                        <BottomNavigationAction value="booking-engine" icon={<i className="fas fa-calendar-alt" />} onClick={() => { history.push(isDefined(allowedPageBookingEngine[0]) && allowedPageBookingEngine[0].property_has_product ? BOOKING_ENGINE :  NOT_ALLOWED_DASHBOARD_SHORT + "/booking-engine")}} />
                        <BottomNavigationAction value="payment" icon={<i className="fas fa-money-bill-wave-alt" />} onClick={() => { history.push(isDefined(allowedPagePayment[0]) && allowedPagePayment[0].property_has_product ? PAYMENT :  NOT_ALLOWED_DASHBOARD_SHORT + "/payment")}} />
                    </BottomNavigation>
                </Hidden>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        propertyProductsReducer : state.propertyProductsReducer
    }
};

export default compose(withTranslation('common')) (connect(mapStateToProps, {dashboardPropertyActions, userMenu, propertyProducts})(NotPurchasedDashboard));
