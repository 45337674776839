import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import FactCheckBoxA from "./FactCheckBoxA";
import FactRecommended from "./FactRecommended";

const FactList = ( { roomId, selectedRoom } ) => {

    const roomFactList = useSelector(props => props.roomFactReducer?.roomFacts)

    useEffect( () => {},[])

    return(
        <>
            {
                Object.keys(roomFactList).length > 0 && roomId === selectedRoom && roomFactList.show_recommended === true &&
                <FactRecommended roomFactList={roomFactList}  roomId={roomId}  />
            }

            {
                roomId === selectedRoom && Object.keys(roomFactList).length > 0 && roomFactList.show_recommended === true && roomFactList.get_room_facts.map( (fact) => (
                    fact.is_recommended === false &&
                    <FactCheckBoxA fact={fact} roomId={roomId}  />
                ))
            }

            {
                roomId === selectedRoom && Object.keys(roomFactList).length > 0 && roomFactList.show_recommended === false && roomFactList.get_room_facts.map( (fact) => (
                    <FactCheckBoxA fact={fact} roomId={roomId}  />
                ))
            }

        </>
    )

}
export default FactList;
