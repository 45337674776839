import React from "react";
import PropTypes from "prop-types";

const IconAtm = props => (
        <i className={props.iconClass} />
);

IconAtm.propType = {
    iconClass: PropTypes.any,
};

export default IconAtm;
