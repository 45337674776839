import React, {Component}   from 'react';

import LoginLeft            from "./components/LoginLeft";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import queryString from "query-string";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class MobileLogin extends Component {
    constructor(props) {
        super(props);
        let queryParams = queryString.parse(this.props.location.search);
        this.state = {
            selectedTabIndex: queryParams.singin ? queryParams.singin : 0,
            one_signal_id: this.props.match.params.id
        }
    }
    render() {
        const { t } = this.props;

        return (
            <>
                <PageTitle title={ t('web-login-page_title') } />
                <LoginLeft selectedTabIndex={this.state.selectedTabIndex} oneSignalId={this.state.one_signal_id} />
            </>
        );
    }
}

export default compose(withTranslation('common')) (MobileLogin);
