import React, {Component} from "react"
import {Route,Switch} from "react-router-dom"
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {
        APP,
        CONTENT,
        CONTENT_INFORMATION,
        CONTENT_LOCATION,
        CONTENT_BRAND,
        CONTENT_EMPLOYEES,
        CONTENT_COMMUNICATION,
        CONTENT_PHOTOS,
        CONTENT_ROOMS,
        CONTENT_PLACES,
        CONTENT_PLACES_PHOTOS,
        CONTENT_PLACES_FACTS,
        CONTENT_AWARDS_AND_CERTIFICATES,
        NETWORK_INVENTORY_LIST,
        CONTENT_ROOM_PHOTOS,
        CONTENT_ROOM_AMENITIES,
        NETWORK,
        NETWORK_RESERVATIONS,
        NETWORK_INVENTORY,
        NETWORK_CHANNELS,
        NETWORK_ROOM_AND_RATES,
        NETWORK_CHANNEL_PAX_PRICING,
        OFFER,
        OFFER_CREATE,
        OFFER_EDIT,
        OFFER_LIST,
        OFFER_PRICE,
        WEB,
        WEB_EDIT,
        CONTENT_FACTS,
        USER_EDIT_PROFILE,
        USER_UPDATE_PASSWORD,
        NETWORK_CHANNEL_SETUP,
        NETWORK_CHANNEL_PAYMENT,
        NETWORK_CHANNEL_CANCELLATION,
        NETWORK_CHANNEL_INFORMATION,
        NETWORK_CHANNEL_ROOM_RATE,
        NETWORK_ROOM_AND_RATES_ID,
        NETWORK_RESERVATION_DETAIL,
        BOOKING_ENGINE,
        PAYMENT,
        PAYMENT_SETUP,
        PAYMENT_INSTALLMENT,
        PAYMENT_LIST,
        PAYMENT_TRANSACTION_LIST,
        PAYMENT_CREATE,
        PAYMENT_UPDATE,
        PAYMENT_TRANSACTION_DETAIL,
        NETWORK_PROMOTION,
        NETWORK_PROMOTION_CHANNEL_LIST,
        NOT_ALLOWED_PAGE,
        NETWORK_GROUP_INVENTORY,
        NOT_ALLOWED_DASHBOARD,
        NETWORK_CHANNEL_CONTACT,
        NETWORK_ADDONS,
        NETWORK_ADDONS_CHANNEL_LIST,
        NETWORK_COUPONS,
        NETWORK_COUPONS_CHANNEL_LIST,
        WEB_BLOG_LIST,
        WEB_BLOG_CREATE, WEB_BLOG_EDIT,
        WEB_POPUP_LIST,
        WEB_POPUP_CREATE,
        WEB_POPUP_EDIT
} from "../../constants/appUrls.constants"

import Dashboard                from "../../components/templates/Content/Dashboard/Dashboard";
import PabDashboard             from "../../components/templates/Pab/NetworkDashboard/NetworkDashboard";
import Error                    from "../../components/templates/Global/Errors/Error";
import NotAllowedPage           from "../../components/templates/Global/NotAllowedPage/NotAllowedPage";
import NotPurchasedDashboard    from "../../components/templates/Global/NotAllowedPage/NotPurchasedDashboard";

import GeneralInformation       from "../../components/templates/Content/GeneralInformation";
import FacilityContact          from "../../components/templates/Content/FacilityContact";
import Requests                 from "../../components/templates/Content/Requests/Requests";
import Reservation              from "../../components/templates/Pab/Reservation";
import ContentCreateRoom        from "../../components/templates/Content/Room/CreateRoom";
import AmenitiesRoom            from "../../components/templates/Content/Room/AmenitiesOfRoom";
import RoomPhotos               from "../../components/templates/Content/Room/RoomPhotos";
import Brand                    from "../../components/templates/Content/Brand";
import Profile                  from "../../components/templates/Global/Profile";
import UpdatePassword           from "../../components/templates/Global/UpdatePassword";
import FactList                 from "../../components/templates/Content/FactList";
import Places                   from "../../components/templates/Content/Places";
import PlacesPhoto              from "../../components/templates/Content/Places/Photo";
import PlacesFacts              from "../../components/templates/Content/Places/Facts";
import AwardsCertificates       from "../../components/templates/Content/AwardsCertificates";
//PAB
import RoomAndRates             from "../../components/templates/Pab/RoomAndRates";

import SelectProperty           from "../../components/templates/Global/SelectProperty";
import Location                 from "../../components/templates/Content/Location";
import ExecutiveContact         from "../../components/templates/Content/ExecutiveContact";
import HotelGallery             from "../../components/templates/Content/Photos";
import InventoryListIndex       from "../../components/templates/Pab/Inventory/components/InventoryList/index";
import Inventory                from "../../components/templates/Pab/Inventory/";
import GroupInventory           from "../../components/templates/Pab/GroupInventory";
import Channels                 from "../../components/templates/Pab/Channels";
import ChannelSetup             from "../../components/templates/Pab/Channels/ChannelSetup";
import ChannelPayment           from "../../components/templates/Pab/Channels/ChannelPayment";
import ChanelCancellation       from "../../components/templates/Pab/Channels/ChanelCancellation";
import ChannelPaxPricing        from "../../components/templates/Pab/Channels/ChannelPaxPricing";
import PromotionIndex           from "../../components/templates/Pab/Promotion";
import PromotionChannelIndex    from "../../components/templates/Pab/Promotion/components/PromotionChannelList";
import Addons                   from "../../components/templates/Pab/AddOns";
import AddonChannelIndex        from "../../components/templates/Pab/AddOns/AddonsChannelList";
import Coupons                  from "../../components/templates/Pab/Coupons";
import CouponsChannelList       from "../../components/templates/Pab/Coupons/CouponsChannelList";

//NETWORK CHANNEL

import ChannelInformation       from "../../components/templates/Pab/Channels/ChannelInformation";
import ChannelRoomRate          from "../../components/templates/Pab/Channels/ChanelRoomRate";
import ChannelContact           from "../../components/templates/Pab/Channels/ChannelContact";

//OFFER
import OfferDashboard           from "../../components/templates/Offer/Dashboard/OfferDashboard";
import CreateOffer              from "../../components/templates/Offer/CreateOffer";
import OfferPrice               from "../../components/templates/Offer/Price";
import OfferList                from "../../components/templates/Offer/OfferList";
import FirstCreateOffer         from "../../components/templates/Offer/FirstCreateOffer/FirstCreateOffer";
import EditOfferForm            from "../../components/templates/Offer/EditOffer";

import CreateWeb                from "../../components/templates/Web/CreateWeb";
import EditWeb                  from "../../components/templates/Web/EditWeb";
import ReservationDetail from "../../components/templates/Pab/Reservation/ReservationDetail";

//BOOKING ENGINE

import BookingEngineDashboard from "../../components/templates/BookingEngine/Dashboard";

//PAYMENT
import PaymentDashboard from "../../components/templates/Payment/PaymentDashboard/PaymentDashboard";
import PaymentSetupIndex from "../../components/templates/Payment/PaymentList/PaymentSetup/PaymentSetupIndex";
import PaymentListIndex from "../../components/templates/Payment/PaymentList";
import PaymentInstallmentIndex
        from "../../components/templates/Payment/PaymentList/PaymentInstallment/PaymentInstallmentIndex";
import TransactionList from "../../components/templates/Payment/PaymentBooking";
import CreateManualPayment from "../../components/templates/Payment/CreateManualPayment";
import UpdateManualPayment from "../../components/templates/Payment/UpdateManualPayment";
import TransactionDetail from "../../components/templates/Payment/TransactionDetail";
import BlogIndex from "../../components/templates/Web/EditWeb/components/Blog";
import CreateBlog from "../../components/templates/Web/EditWeb/components/Blog/CreateBlog";
import EditBlog from "../../components/templates/Web/EditWeb/components/Blog/EditBlog";

import PopupIndex from "../../components/templates/Web/EditWeb/components/Popup";
import CreatePopup from "../../components/templates/Web/EditWeb/components/Popup/CreatePopup";
import EditPopup from "../../components/templates/Web/EditWeb/components/Popup/EditPopup";

class PrivateUrls extends Component {

    render() {
        return(
            <Switch>
                <Route exact path={APP} component={ SelectProperty } />
                <Route exact path={CONTENT} component={Dashboard} />
                <Route exact path={CONTENT_INFORMATION} component={GeneralInformation} />
                <Route exact path={CONTENT_LOCATION} component={Location} />
                <Route exact path={CONTENT_COMMUNICATION} component={FacilityContact} />
                <Route exact path={CONTENT_EMPLOYEES} component={ExecutiveContact} />
                <Route exact path={CONTENT_BRAND} component={Brand} />
                <Route exact path={CONTENT_PHOTOS} component={HotelGallery} />
                <Route exact path={CONTENT_ROOMS} component={ContentCreateRoom} />
                <Route exact path={CONTENT_ROOM_AMENITIES} component={AmenitiesRoom} />
                <Route exact path={CONTENT_ROOM_PHOTOS} component={RoomPhotos} />
                <Route exact path={CONTENT_FACTS} component={FactList} />
                <Route exact path={CONTENT_PLACES} component={Places} />
                <Route exact path={CONTENT_PLACES_FACTS} component={PlacesFacts} />
                <Route exact path={CONTENT_PLACES_PHOTOS} component={PlacesPhoto} />
                <Route exact path={CONTENT_AWARDS_AND_CERTIFICATES} component={AwardsCertificates} />

                <Route exact path={USER_EDIT_PROFILE} component={ Profile } />
                <Route exact path={USER_UPDATE_PASSWORD} component={ UpdatePassword } />

                <Route exact path={NETWORK} component={ PabDashboard }/>
                <Route exact path={NETWORK_RESERVATIONS} component={Reservation} />
                <Route exact path={NETWORK_RESERVATION_DETAIL} component={ReservationDetail} />
                <Route exact path={NETWORK_ROOM_AND_RATES} component={RoomAndRates} />
                <Route exact path={NETWORK_ROOM_AND_RATES_ID} component={RoomAndRates} />
                <Route exact path={NETWORK_INVENTORY} component={Inventory} />
                <Route exact path={NETWORK_GROUP_INVENTORY} component={GroupInventory} />
                <Route exact path={NETWORK_INVENTORY_LIST} component={InventoryListIndex} />
                <Route exact path={NETWORK_CHANNELS} component={Channels} />
                <Route exact path={NETWORK_CHANNEL_SETUP} component={ChannelSetup} />
                <Route exact path={NETWORK_CHANNEL_PAYMENT} component={ChannelPayment} />
                <Route exact path={NETWORK_CHANNEL_CANCELLATION} component={ChanelCancellation} />
                <Route exact path={NETWORK_CHANNEL_INFORMATION} component={ChannelInformation} />
                <Route exact path={NETWORK_CHANNEL_ROOM_RATE} component={ChannelRoomRate} />
                <Route exact path={NETWORK_CHANNEL_PAX_PRICING} component={ChannelPaxPricing} />
                <Route exact path={NETWORK_PROMOTION} component={PromotionIndex} />
                <Route exact path={NETWORK_PROMOTION_CHANNEL_LIST} component={PromotionChannelIndex} />
                <Route exact path={NETWORK_CHANNEL_CONTACT} component={ChannelContact} />
                <Route exact path={NETWORK_ADDONS} component={Addons} />
                <Route exact path={NETWORK_ADDONS_CHANNEL_LIST} component={AddonChannelIndex} />
                <Route exact path={NETWORK_COUPONS} component={Coupons} />
                <Route exact path={NETWORK_COUPONS_CHANNEL_LIST} component={CouponsChannelList} />
                <Route exact path="/requests" component={Requests} />

                <Route exact path={OFFER} component={OfferDashboard} />
                <Route exact path="/new-create-offer" component={FirstCreateOffer} />
                <Route exact path={OFFER_CREATE} component={CreateOffer} />
                <Route exact path={OFFER_EDIT} component={EditOfferForm} />
                <Route exact path={OFFER_PRICE} component={OfferPrice} />
                <Route exact path={OFFER_LIST} component={OfferList} />

                <Route exact path={BOOKING_ENGINE} component={BookingEngineDashboard} />

                <Route exact path={PAYMENT} component={PaymentDashboard} />
                <Route exact path={PAYMENT_LIST} component={PaymentListIndex} />
                <Route exact path={PAYMENT_SETUP} component={PaymentSetupIndex} />
                <Route exact path={PAYMENT_INSTALLMENT} component={PaymentInstallmentIndex} />
                <Route exact path={PAYMENT_TRANSACTION_LIST} component={TransactionList} />
                <Route exact path={PAYMENT_CREATE} component={CreateManualPayment} />
                <Route exact path={PAYMENT_UPDATE} component={UpdateManualPayment} />
                <Route exact path={PAYMENT_TRANSACTION_DETAIL} component={TransactionDetail} />

                <Route exact path={WEB} component={ CreateWeb }/>
                <Route exact path={WEB_EDIT} component={ EditWeb }/>
                <Route exact path={WEB_BLOG_LIST} component={ BlogIndex }/>
                <Route exact path={WEB_BLOG_CREATE} component={ CreateBlog }/>
                <Route exact path={WEB_BLOG_EDIT} component={ EditBlog }/>

                <Route exact path={WEB_POPUP_LIST} component={ PopupIndex }/>
                <Route exact path={WEB_POPUP_CREATE} component={ CreatePopup }/>
                <Route exact path={WEB_POPUP_EDIT} component={ EditPopup }/>
                <Route exact path={NOT_ALLOWED_PAGE} component={ NotAllowedPage }/>
                <Route exact path={NOT_ALLOWED_DASHBOARD} component={ NotPurchasedDashboard }/>
                <Route component={ Error }/>
            </Switch>

        )
    }
}

export default compose(withTranslation('common'))(PrivateUrls);
