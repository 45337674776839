import React, {useEffect, useState} from 'react';
import FactDefaultBox from "../../../../molecules/FactDefaultBox/FactDefaultBox";
import {useDispatch, useSelector} from "react-redux";
import CouponSyncModal from "./CouponSyncModal";
import {CouponDataClear, SyncCoupons} from "../../../../../redux/actions/Network/Coupons/couponsActions";
import {isDefined} from "../../../../../utils";

let lastFactUpdateId=null;
const CouponBox = (props) => {

    const { coupon } = props
    const [isSelected , setIsSelected] = useState( coupon.status );
    const syncStatus = useSelector(props => props.couponsReducers.syncCouponsStatus)

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        dispatch(CouponDataClear());
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect( () => {

        if (syncStatus === 200 && lastFactUpdateId === coupon?.id+"-"+coupon?.title+"-"+coupon?.code){
            setState();
        }
    },[syncStatus]);

    const setState = () => {
        if(isSelected){
            setIsSelected(false)
        }else{
            setIsSelected(true)
        }
    }
    const handleUpdateClick = (e,coupon) => {

        let params = {}
        lastFactUpdateId = coupon?.id+"-"+coupon?.title+"-"+coupon?.code

        if(isSelected){
            params = {
                channel_id: props.pageId,
                id: coupon?.id,
                title: coupon?.title,
                code: coupon?.code,
                start_date: coupon?.start_date,
                end_date: coupon?.end_date,
                type: coupon?.type,
                is_notify: coupon?.is_notify,
                value: coupon?.value,
                status: false
            }
            dispatch(SyncCoupons(params))
        }else{
            params = {
                channel_id: props.pageId,
                id: coupon?.id,
                title: coupon?.title,
                code: coupon?.code,
                start_date: coupon?.start_date,
                end_date: coupon?.end_date,
                type: coupon?.type,
                is_notify: coupon?.is_notify,
                value: coupon?.value,
                status: true
            }
            dispatch(SyncCoupons(params))
        }
    }

    useEffect( () => {
        if (syncStatus === 200 && lastFactUpdateId === coupon?.id+"-"+coupon?.title+"-"+coupon?.code) {
            dispatch(CouponDataClear());
            setState();
        }
    },[syncStatus])

        return (
            <div>
                <>
                    <CouponSyncModal
                        fact={coupon}
                        id={coupon.id}
                        pageId={props.pageId}
                        open={open}
                        type="modal"
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                    />
                    <FactDefaultBox
                        checkboxId={coupon.id+"-"+coupon.title+"-"+coupon.code}
                        name={coupon.title}
                        className="fact-box"
                        blockClass="fact-border-box"
                        labelIconClass="fact-icon-label"
                        iconClass={`fact-icon fas fa-badge-percent`}
                        key={coupon.id+"-"+coupon.title+"-"+coupon.code}
                        checked={isSelected}
                        factName={coupon.title}
                        tooltip={coupon.title}
                        text={isDefined(coupon.title) ? coupon.code + " - " + coupon.title : coupon.code}
                        labelClass="fact-name"
                        htmlFor={coupon.id}
                        onClick={ (e) => { handleUpdateClick(e, coupon)} }
                    />
                </>
            </div>
        );

}

export default CouponBox;
