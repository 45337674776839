import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../../utils";
import {
    PROMOTION_TYPES_GET, PROMOTION_UPDATE, PROMOTION_ROOM_RATE_LIST, PROMOTION_CREATE, PROMOTION_LIST, PROMOTION_ROOM_RATE_UPDATE
} from "../../../../constants/api.constants";

export function getPromotionRoomRateList(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                channel_id: params,
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_ROOM_RATE_LIST',
            payload: axios.post(PROMOTION_ROOM_RATE_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function getPromotionList() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_LIST',
            payload: axios.post(PROMOTION_LIST, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}
export function GetPromotionTypes() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                parent_id : 1
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_TYPES_GET',
            payload: axios.post(PROMOTION_TYPES_GET, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function CreatePromotion(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                title: params.title,
                promotion_type_id: params.promotion_type_id,
                start_date: params.start_date,
                end_date: params.end_date,
                reservation_start_date: params.reservation_start_date,
                reservation_end_date: params.reservation_end_date,
                day_before: params.day_before,
                last_minute : params.lastMinute,
                amount: params.amount,
                min_stay: params.min_stay,
                is_mobile: params.is_mobile,
                days: params.days,
                is_time: params.is_time,
                start_time: params.start_time,
                end_time: params.end_time,
                params: null,
                detail: null,
                exclude_dates: params.exclude_dates
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_CREATE',
            payload: axios.post(PROMOTION_CREATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdatePromotion(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                title: params.title,
                promotion_id: params.promotion_id,
                start_date: params.start_date,
                end_date: params.end_date,
                reservation_start_date: params.reservation_start_date,
                reservation_end_date: params.reservation_end_date,
                day_before: params.day_before,
                last_minute: params.lastMinute,
                amount: params.amount,
                min_stay: params.min_stay,
                is_mobile: params.is_mobile,
                days: params.days,
                is_time: params.is_time,
                start_time: params.start_time,
                end_time: params.end_time,
                params: null,
                detail: null,
                exclude_dates: params.exclude_dates
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_UPDATE',
            payload: axios.post(PROMOTION_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function UpdateRoomRatePromotion(params) {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty(),
                room_rate_channel_mapping_id: params.room_rate_channel_mapping_id,
                property_promotion_id: params.property_promotion_id,
                is_selected: params.is_selected
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROMOTION_ROOM_RATE_UPDATE',
            payload: axios.post(PROMOTION_ROOM_RATE_UPDATE, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function createOrUpdatePromotionErrorClear() {

    return dispatch => {
        dispatch({
            type: 'PROMOTION_CLEAR_ERROR_STATE',
        })
    }
}
