import React from "react";
import PropTypes from "prop-types";

import Grid from '@material-ui/core/Grid';

const RowAtm = props => (
    <Grid className={props.className} container spacing={props.spacing}>
        {props.children}
    </Grid >
);
RowAtm.propType = {
    spacing: PropTypes.number,
    children: PropTypes.string,
    className: PropTypes.string,
};

export default RowAtm;
