import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import ColAtm from "../../../../atomic/Col/Col";
import {TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
let t=null;

class SelectedPlace extends Component {

    constructor(props) {
        super(props);
        this.state =  {
            activeStep: 1,
            address : null,
        }
        t = props.t;
    }

    componentDidUpdate(prevProps, prevState) {
        const isSelectedPlace = this.props.selectedPlace !== null ? true : false;

        if(this.props.backToSearch === true && isSelectedPlace){

            this.setState({
                address : this.props.address
            },() => this.props.setStateBackToSearch(false) )


        }else{
            if(this.state.address === null && this.props.address !== null){
                this.setState({
                    address : this.props.address
                })
            }
        }
    }

    updatePropertyContant = () => {
        let param = {};
        if(this.state.address === null){
            param = {
                "address"  : this.props.address,
                "latitude" : this.props.location.lat,
                "longitude": this.props.location.long,
            }
        }else{
            param = {
                "address"  : this.state.address,
                "latitude" : this.props.location.lat,
                "longitude": this.props.location.long,
            }
        }

        this.props.updatePropertyContant(param)
        this.props.setStateUpdateAddress(param.address)
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
    }

    render() {
        const isSelectedPlace = this.props.selectedPlace !== null ? true : false;
        const {activeStep} = this.state
        return (
            <div className={ isSelectedPlace ? "location-stepper-root" : "d-none" }>
                <div>
                    <h3 className="location-find-results"> { t('enw-general-location-verify_address_title') }</h3>
                </div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={0}>
                        <StepLabel> { t('enw-general-location-your_address-title') } : {this.state.address}</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel> { t('enw-general-location-verify_address_title') }</StepLabel>
                        <StepContent>
                            <Typography>
                                <ColAtm className="location-card">
                                    <TextField
                                        multiline={true}
                                        type="text"
                                        name="address"
                                        label={ t('enw-input-address') }
                                        variant="outlined"
                                        rows={2}
                                        fullWidth
                                        value={this.state.address}
                                        //defaultValue={this.props.address}
                                        onChange={this.handleChange}
                                    />
                                </ColAtm>
                            </Typography>
                            <div className="mt-22">
                                <div>
                                    <Button
                                        onClick={this.props.backSeacrh}
                                        startIcon={<i className="fas fa-search-location" />}
                                    >
                                        { t('btn-back_search') }
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.updatePropertyContant}
                                        startIcon={<i className="fas fa-check" />}
                                    >
                                        { t('btn-finish') }
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

const mapStateToProps = (props) => {
    return{
        updateData : props.contactReducers.updateData,
        error : props.contactReducers.error,
    }
}


export default compose(withTranslation('common'))(connect(mapStateToProps, {} )(SelectedPlace));
