import React from 'react';
import ColAtm from "../../../../../atomic/Col/Col";
import CancellationPolicy from "./CancellationPolicy";
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

const Rates = (props) => {

    const { rates } = props

    return(
        <>
            {
                rates.map( rate => (
                    <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item" key={rate.id}>
                        <ExpansionPanel className="accordion-rate-table" defaultExpanded>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header">
                                <ColAtm>
                                    <ColAtm md={10} xl={11}>
                                        <i className="fas fa-tags rate-tag-icon" />
                                        <LabelAtm labelClass="rate-title"  text={rate.name} />
                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                <CancellationPolicy cancellations={rate.room_rate_channel_cancellation_policy}
                                                    rateMappingId={rate.room_rate_channel_mapping_id}
                                                    channelId={props.channelId}
                                                    roomId={props.roomId}
                                                    rateId={rate?.id}
                                                    UpdateCancellationPolicy={props.UpdateCancellationPolicy}
                                                    updatePolicyError={props.updatePolicyError}
                                                    updatePolicyStatus={props.updatePolicyStatus}
                                                    channelInformationData={props.channelInformationData}
                                                    getChannelCancellationList={props.getChannelCancellationList}

                                />
                            </ExpansionPanelDetails>
                            <img className="flip-transform" src="/assets/img/icons/channel-arrow.png" alt="channel-arrow"/>
                        </ExpansionPanel>
                    </ExpansionPanelDetails>
                ))
            }
        </>
    )
}

export default Rates;
