import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {FormControl, Grid, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getProperty, getYears, isDefined} from "../../../../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useSelector, useDispatch} from "react-redux";
import { getLanguages } from '../../../../../redux/actions/languageActions';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CreateAwardsCertificates(props) {

    const startYear = new Date().getFullYear();
    const endYear = 1900;
    const years = getYears(startYear, endYear);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {awardsCertificatesReducers, getAwardsCertificates, clearErrorMessage, createAwardsCertificates} = props;

    const languageList = useSelector(props => props.languagesReducer?.languages);

    const [date, setDate] = useState("");
    const [url, setUrl] = useState("");
    const [category, setCategory] = useState("");
    const [filePath, setFilePath] = useState("");
    const [file, setFile] = useState("");
    const [language, setLanguage] = useState("");

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        clearErrorMessage();

        setDate("")
        setUrl("")
        setCategory("")
        setFilePath("")
        setFile("")
        setLanguage("")
    };

    const changeCategory = ({target}) => {
        setCategory(target.value)
    };

    const changeDate = (e, value) => {
        setDate(value)
    };

    const changeUrl = ({target}) => {
        setUrl(target.value)
    };

    const changeLanguage = ({target}) => {
        setLanguage(target.value)
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_id: getProperty(),
            name: null,
            date: date,
            url: url,
            category_id: category,
            file: file,
            language_code: language ? language : "",
        }

        createAwardsCertificates(params)
    };

    const handleDeleteAwardsCertificates = () => {
        setFilePath("")
        setFile("")
    };

    useEffect( () => {
        dispatch(getLanguages());
        if (awardsCertificatesReducers.awardsCertificatesCreate.status === 200) {
            if(open){
                getAwardsCertificates();
            }
            handleClose();
        }

        return () => {
            if(isDefined(awardsCertificatesReducers.awardsCertificatesCreateError) &&
                isDefined(awardsCertificatesReducers.awardsCertificatesCreateError.response)){
                setDate("")
                setUrl("")
                setCategory("")
                setLanguage("")
            }
        }
    },[
        awardsCertificatesReducers.awardsCertificatesCreate,
        awardsCertificatesReducers.awardsCertificatesCreateError,
        dispatch
    ]);


    const onFileChange = event => {
        const file = event.target.files[0];

        if (isDefined(file)) {
            setFilePath(URL.createObjectURL(file))
            setFile(event.target.files[0])
        }
    };

    return (
        <div className="w-100">
            <Button className="filter-action-button float-right" aria-controls="filter-action-menu" aria-haspopup="true" onClick={handleClickOpen}>
                <i className="fas fa-plus-circle" /> { t('btn-create_award_and_certificate') }
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    {  t('enw-general-awards_and_certificates_create_modal-title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>

                            {
                                (isDefined(awardsCertificatesReducers.awardsCertificatesCreateError) &&
                                    isDefined(awardsCertificatesReducers.awardsCertificatesCreateError.response)
                                ) &&
                                <Alert severity="error">{awardsCertificatesReducers.awardsCertificatesCreateError.response.data.message}</Alert>
                            }

                            <Grid container spacing={3} className="mobile-no-spacing">
                                <Grid item xs={12} sm={6}>
                                    <SelectValidator
                                        name="category"
                                        onChange={changeCategory}
                                        value={category}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-award_and_certificate') }
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        {
                                            isDefined(awardsCertificatesReducers.awardsCertificatesCategories) &&
                                            isDefined(awardsCertificatesReducers.awardsCertificatesCategories.award_certificate_categories) &&
                                            awardsCertificatesReducers.awardsCertificatesCategories.award_certificate_categories.map( (awardsCertificatesCategory) => (
                                                <MenuItem value={awardsCertificatesCategory.id} >
                                                    { awardsCertificatesCategory.name }
                                                </MenuItem>
                                            ))
                                        }
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            id="date"
                                            options={years}
                                            getOptionLabel={option => String(option)}
                                            defaultValue={date}
                                            onChange={(e, value) => changeDate(e, value)}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        className={"auto-complete-select"}
                                                        fullWidth
                                                        {...params}
                                                        label={ t('enw-input-year') }
                                                        variant="outlined"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        label={ t('enw-input-url') }
                                        name="url"
                                        fullWidth
                                        autoComplete="off"
                                        value={url}
                                        onChange={changeUrl}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectValidator
                                        name="language"
                                        onChange={changeLanguage}
                                        value={language}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-language') }
                                        // validators={['required']}
                                        // errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                    {
                                        languageList?.map((lang) => (
                                            <MenuItem value={lang.code}>
                                                <img src={`/assets/img/language/${lang.code}.svg`} alt="en"
                                                        style={{width: 20, marginRight: 8}}/> {t(lang.language_key)}
                                            </MenuItem>
                                        ))
                                    }
                                    </SelectValidator>
                                    </Grid>
                                </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3>{ t('enw-awards_or_certificates-upload-certificate-title') }</h3>
                                    <p>{ t('enw-awards_or_certificates-upload_valid_format-title') }: <span className="f-w-bold">PDF, JPG, PNG</span></p>
                                </Grid>
                                <Grid item xs={8} md={4} className="mobile-awards-order-2">
                                    <Grid className="brand-logo-box">

                                        {
                                            !isDefined(file)  ?
                                                <label htmlFor="contained-button-file" className="cursor-pointer">
                                                    <img src="/assets/img/icons/awards-file-format.png"/>
                                                </label>
                                                :
                                                file.type === "application/pdf" || file === "PDF" ?
                                                    <p className="awards-preview-box">
                                                        <i className="fas fa-file-pdf" />
                                                        <a href={filePath} target="_blank"> { t('btn-view') } </a>
                                                    </p>
                                                    :
                                                    <img src={filePath} />
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    isDefined(file) &&
                                    <Grid item xs={3} md={2} className="brand-logo-upload mobile-awards-order-2">
                                        <ButtonAtm className="delete-bed-button p-12" fullwidth={true}
                                                   startIcon={<i className="fas fa-trash" />}
                                                   onClick={() => handleDeleteAwardsCertificates()}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6} className="brand-logo-upload mobile-awards-order-1">
                                    <input
                                        className="d-none"
                                        accept="image/png, image/jpeg, .pdf"
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={onFileChange}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button
                                            className="room-rate-add soft-blue-button m-auto radius-8"
                                            component="span"
                                            startIcon={<i className="fas fa-cloud-upload-alt" />}>
                                            { t('btn-upload_image_or_pdf') }
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
}
