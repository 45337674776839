import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid, Hidden, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import {getChannelCancellationList} from "../../../../../../redux/actions/Network/Channel/channelCancellation.actions";
import {useDispatch} from "react-redux";
import {getAppLang, isDefined} from "../../../../../../utils";
import LabelAtm from "../../../../../atomic/Label/Label";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputAtm from "../../../../../atomic/Input/Input";
import esLocale from "date-fns/locale/es";
import deLocale from "date-fns/locale/de";
import trLocale from "date-fns/locale/tr";
import ruLocale from "date-fns/locale/ru";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TodayIcon from "@material-ui/icons/Today";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function ResponsiveDialog(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [name] = useState("");
    const [arrival, setArrival] = useState("");
    const [type, setType] = useState(null);
    const [val, setVal] = useState(null);
    const [cancelVal, setCancelVal] = useState(null);
    const [isAffect, setIsAffect] = useState(false);
    const [affectActionType, setAffectActionType] = useState(null);
    const [affectPriceType, setAffectPriceType] = useState(null);
    const [affectPriceValue, setAffectPriceValue] = useState(null);
    const [isDateRange, setIsDateRange] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const default_currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.default_currency ? props.channelInformationData.default_currency : '';
    const currency = Object.keys(props.channelInformationData).length > 0 && props.channelInformationData.currency ? props.channelInformationData.currency : default_currency ;

    const localeMap = {
        es: esLocale,
        de: deLocale,
        tr: trLocale,
        ru: ruLocale
    };

    const changeArrival = ({target}) => {
        setArrival(target.value)
    }
    const changeVal = ({target}) => {
        setVal(target.value)
    }
    const handleChange = ({target}) => {
        setType(target.value);
    };

    const handleChangeType = ({target}) => {
        setCancelVal(target.value);
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            name : name,
            before_arrival : cancelVal === 1 ? 999 : parseInt(arrival),
            value : cancelVal === 2 ? val : null,
            type: cancelVal === 2 ? type : null,
            is_nonrefundable: cancelVal === 1 ? true : false,
            is_free_cancellation: cancelVal === 0 ? true : false,
            is_affect : isAffect,
            affects_price_action_type : null,
            affects_price_type : null,
            affects_price_value : null,
            is_date_range : isDateRange,
            start_date: null,
            finish_date: null,
        }

        if(isAffect){
            Object.assign(params, {affects_price_action_type: affectActionType, affects_price_type: affectPriceType, affects_price_value: affectPriceValue  })
        }

        if(isDateRange){
            Object.assign(params, {
                start_date: startDate,
                finish_date: endDate
            })
        }

        props.addChannelCancellation(params);
    };

    const changeStartDate = date => {
        setStartDate(moment(date).format("YYYY-MM-DD"))
    }

    const changeEndDate = date => {
        setEndDate(moment(date).format("YYYY-MM-DD"))
    }

    useEffect( () => {

        let params = {
            channel_id: props.channelInformationData.id
        }
        if (props.updateStatus === 200) {
            dispatch(getChannelCancellationList(params));
            props.handleClose();
        }

        return () => {
            setCancelVal("")
            setArrival("")
            setType("")
            setVal("")
            setAffectActionType("")
            setAffectPriceType("")
            setAffectPriceValue("")
            setIsAffect(false)
            setIsDateRange(false)
        }

    },[props.updateStatus, props.open] )

    return (
        <div>
            <Button className="filter-action-button float-right" aria-controls="filter-action-menu" aria-haspopup="true" onClick={props.handleOpen}>
                <i className="fas fa-plus-circle" /> { t('btn-new_cancellation_policy') }
            </Button>
            <Dialog onClose={props.handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={props.handleClose}>
                    { t('btn-new_cancellation_policy') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                            {props.updateStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                            {isDefined(props.updateError) && <Alert severity="error">{props.updateError.response.data.message}</Alert>}
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <SelectValidator
                                        name="typee"
                                        onChange={handleChangeType}
                                        value={cancelVal}
                                        className="form-control"
                                        variant="outlined"
                                        fullWidth
                                        label={ t('enw-input-select-type') }
                                        validators={['required']}
                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                    >
                                        <MenuItem value={0}>
                                            { t('btn-free_cancellation') }
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            { t('btn-irrevocable') }
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            { t('btn-revocable') }
                                        </MenuItem>
                                    </SelectValidator>
                                </Grid>
                                {
                                    (cancelVal !== null  && cancelVal !== 1) &&
                                    <Grid item sm={4}>
                                        <TextValidator
                                            label={ t('enw-input-days_before') }
                                            type="number"
                                            name="before_arrival"
                                            fullWidth
                                            value={arrival} onChange={changeArrival}
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={[ t("enw-notification_this_is_required") ]}
                                        />
                                    </Grid>
                                }
                                {
                                    cancelVal !== null  &&
                                    <>
                                        {
                                            cancelVal === 2 &&
                                            <>
                                                <Grid item sm={4}>
                                                    <SelectValidator
                                                        name="type"
                                                        onChange={handleChange}
                                                        value={type}
                                                        className="form-control"
                                                        variant="outlined"
                                                        fullWidth
                                                        label={ "Value Type" }
                                                        validators={['required']}
                                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                                    >
                                                        <MenuItem value={"PER"}>
                                                            { t('enw-input-percent') }
                                                        </MenuItem>
                                                        <MenuItem value={"FIX"}>
                                                            { t('enw-input-fixed') }
                                                        </MenuItem>
                                                    </SelectValidator>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <TextValidator
                                                        label={ t('enw-input-value') }
                                                        type="number"
                                                        name="value"
                                                        fullWidth
                                                        value={val}
                                                        onChange={changeVal}
                                                        variant="outlined"
                                                        validators={['required']}
                                                        errorMessages={[ t("enw-notification_this_is_required") ]}
                                                        InputProps={{endAdornment:
                                                                <>
                                                                    {
                                                                        type === "PER" ?
                                                                            <InputAdornment position="start">
                                                                                %
                                                                            </InputAdornment>
                                                                            :
                                                                            type === "FIX" ?
                                                                                <InputAdornment position="start">
                                                                                    { currency }
                                                                                </InputAdornment>
                                                                                :
                                                                                <InputAdornment position="start">

                                                                                </InputAdornment>
                                                                    }
                                                                </>
                                                        }}/>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item sm={12}>
                                            <Grid sm={4} >
                                                <Grid container spacing={4}>
                                                    <Grid item sm={12}>
                                                        <ColAtm className="mb-16">
                                                            <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_affected-title') } />
                                                        </ColAtm>
                                                    </Grid>
                                                    <Grid item>
                                                        <input type={"radio"} name={"paymentValue"}
                                                               id={"paymentValue2"}
                                                               value={"false"}
                                                               className="d-none"
                                                               checked={ !isAffect }
                                                               onClick={(e) => { setIsAffect(false) }}
                                                        />
                                                        <label className="show-channel-detail"
                                                               htmlFor={"paymentValue2"}>
                                                            { t('btn-not_affected') }
                                                        </label>
                                                    </Grid>
                                                    <Grid item>
                                                        <input type={"radio"} name={"paymentValue"}
                                                               id={"paymentValue"}
                                                               value={"true"}
                                                               className="d-none"
                                                               onClick={(e) => { setIsAffect(true) }}
                                                               checked={ isAffect }
                                                        />
                                                        <label className="show-channel-detail" htmlFor={"paymentValue"}>
                                                            { t('btn-affected') }
                                                        </label>

                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {
                                                isAffect ? (
                                                    <Grid sm={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item sm={4}>
                                                                <SelectValidator
                                                                    name="action_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={ t('enw-input-setup_type') }
                                                                    value={affectActionType}
                                                                    onChange={e => setAffectActionType(e.target.value)}
                                                                    validators={['required']}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={"INC"}>{ t('enw-input-increase') }</MenuItem>
                                                                    <MenuItem value={"DEC"}>{ t('enw-input-decrease') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item sm={4}>
                                                                <SelectValidator
                                                                    name="price_type"
                                                                    className="form-control"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={ t('enw-input-value_type') }
                                                                    value={affectPriceType}
                                                                    onChange={e => setAffectPriceType(e.target.value) }
                                                                    validators={['required']}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                >
                                                                    <MenuItem value={"PER"}>{ t('enw-input-percent') }</MenuItem>
                                                                    <MenuItem value={"FIX"}>{ t('enw-input-fixed') }</MenuItem>
                                                                </SelectValidator>
                                                            </Grid>
                                                            <Grid item sm={4}>
                                                                <TextValidator
                                                                    //   id={pay.payment_type_id+"channel"}
                                                                    name="value"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    label={ t('enw-input-value') }
                                                                    autoComplete="off"
                                                                    value={ affectPriceValue }
                                                                    onChange={e => setAffectPriceValue(e.target.value)}
                                                                    labelWidth={70}
                                                                    validators={['required']}
                                                                    errorMessages={[t("enw-notification_this_is_required")]}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: <>
                                                                            {
                                                                                affectPriceType === "PER" ?
                                                                                    <InputAdornment position="start">
                                                                                        %
                                                                                    </InputAdornment>
                                                                                    :
                                                                                    affectPriceType === "FIX" ?
                                                                                        <InputAdornment position="start">
                                                                                            { currency }
                                                                                        </InputAdornment>
                                                                                        :
                                                                                        <InputAdornment position="start">

                                                                                        </InputAdornment>
                                                                            }
                                                                        </>
                                                                    }}
                                                                />
                                                                <Hidden xsUp>
                                                                    <InputAtm
                                                                        name="payment_type_id"
                                                                        type="text"
                                                                        //    value={pay.payment_type_id}
                                                                        variant="outlined"
                                                                        label={"payment_type_id"}
                                                                        autoComplete="off"
                                                                        //  onChange={e => this.handlePaymentChange(e, i)}
                                                                    />
                                                                </Hidden>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : (<></>)
                                            }

                                            <Grid xs={12} sm={8}  className="mt-20">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ColAtm>
                                                            <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-rate_date-range-title') } />
                                                        </ColAtm>
                                                    </Grid>
                                                    <Grid item>
                                                        <input type={"radio"} name={"dateRange"}
                                                               id={"dateRange2"}
                                                               value={"false"}
                                                               className="d-none"
                                                               checked={ !isDateRange }
                                                               onClick={(e) => { setIsDateRange(false) }}
                                                        />
                                                        <label className="show-channel-detail"
                                                               htmlFor={"dateRange2"}>
                                                            { t('btn-not_affected') }
                                                        </label>
                                                    </Grid>
                                                    <Grid item>
                                                        <input type={"radio"} name={"dateRange"}
                                                               id={"dateRange"}
                                                               value={"true"}
                                                               className="d-none"
                                                               checked={ isDateRange }
                                                               onClick={(e) => { setIsDateRange(true) }}
                                                        />
                                                        <label className="show-channel-detail" htmlFor={"dateRange"}>
                                                            { t('btn-affected') }
                                                        </label>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                isDateRange ? (
                                                    <Grid container justify="space-between" spacing={3} className="mobile-no-spacing">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[getAppLang()]}>
                                                            <Grid item xs={12} md={6}>
                                                                <DatePicker
                                                                    margin="normal"
                                                                    label={ t('enw-input-from_date')}
                                                                    className="w-100"
                                                                    variant="inline"
                                                                    autoOk
                                                                    inputVariant="outlined"
                                                                    format="dd.MM.yyyy"
                                                                    value={startDate}
                                                                    onChange={changeStartDate}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton>
                                                                                    <TodayIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <DatePicker
                                                                    margin="normal"
                                                                    label={ t('enw-input-to_date')}
                                                                    variant="inline"
                                                                    inputVariant="outlined"
                                                                    className="w-100"
                                                                    format="dd.MM.yyyy"
                                                                    autoOk
                                                                    value={endDate}
                                                                    minDate={ moment(startDate).add(1,'days') }
                                                                    onChange={changeEndDate}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton>
                                                                                    <InsertInvitationIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                ) : (<></>)
                                            }

                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <ColAtm className="mt-13" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
}
