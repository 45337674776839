import {getUserToken, getProperty} from "../../../../utils";
import {GET_CONTENT_PDF} from "../../../../constants/api.constants";
import axios                            from 'axios';

export function getPdf(param) {

    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            authToken: getUserToken(),
            language : param
        },
        body: {
            params: {
                property_id: getProperty(),
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_CONTENT_PDF',
            payload: axios.post(GET_CONTENT_PDF, requestOptions.body, {headers: requestOptions.headers, responseType: 'arraybuffer'})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                })
        })
    }
}

