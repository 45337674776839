import axios from 'axios';
import {getAppLang, getProperty, getUserToken} from "../../../../utils";
import {
    PROPERTY_POST_INVENTORY_LINK
} from "../../../../constants/api.constants"


export function InventoryLink (channelId) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id : getProperty(),
                channel_id  : channelId
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'PROPERTY_POST_INVENTORY_LINK',
            payload: axios.post(PROPERTY_POST_INVENTORY_LINK, requestOptions.body, {headers: requestOptions.headers})
                .then(response => response.data)
        })
    }
}

export function InventoryLinkClear(){

    return dispatch => {
        dispatch({
            type: 'PROPERTY_POST_INVENTORY_LINK_CLEAR',
        })
    }
}
