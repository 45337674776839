import {groupChannelGetConstants,
    groupChannelAddConstants,
    singleGroupChannelGetConstants,
    groupChannelUpdateConstants, groupChannelCreateDataConstants
} from "../../../../constants/Network/Channels/groupChannel.constans";

const initialState = {
    get_fetching : false,
    get_mapping: [],
    get_error : [],

    single_get_fetching : false,
    single_get_mapping: [],
    single_get_error : [],

    add_fetching : false,
    add_mapping: [],
    add_error: [],

    update_fetching : false,
    update_mapping: [],
    update_error: [],

    get_create_fetching : false,
    get_create_mapping: [],
    get_create_error: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case groupChannelGetConstants.NETWORK_CHANNEL_GROUP_GET_PENDING:
            return {
                ...state,
                get_fetching: true,
                get_mapping: [],
                get_error: []
            };
        case groupChannelGetConstants.NETWORK_CHANNEL_GROUP_GET_FULFILLED:
            return {
                ...state,
                get_fetching: false,
                get_mapping: action.payload.data,
                get_error: []
            };
        case groupChannelGetConstants.NETWORK_CHANNEL_GROUP_GET_REJECTED:
            return {
                ...state,
                get_fetching: false,
                get_mapping: [],
                get_error: action.payload
            };

        case groupChannelCreateDataConstants.NETWORK_CHANNEL_GROUP_CREATE_DATA_PENDING:
            return {
                ...state,
                get_create_fetching: true,
                get_create_mapping: [],
                get_create_error: []
            };
        case groupChannelCreateDataConstants.NETWORK_CHANNEL_GROUP_CREATE_DATA_FULFILLED:
            return {
                ...state,
                get_create_fetching: false,
                get_create_mapping: action.payload.data,
                get_create_error: []
            };
        case groupChannelCreateDataConstants.NETWORK_CHANNEL_GROUP_CREATE_DATA_REJECTED:
            return {
                ...state,
                get_create_fetching: false,
                get_create_mapping: [],
                get_create_error: action.payload
            };
        case singleGroupChannelGetConstants.NETWORK_SINGLE_CHANNEL_GROUP_GET_PENDING:
            return {
                ...state,
                single_get_fetching: true,
                single_get_mapping: [],
                single_get_error: []
            };
        case singleGroupChannelGetConstants.NETWORK_SINGLE_CHANNEL_GROUP_GET_FULFILLED:
            return {
                ...state,
                single_get_fetching: false,
                single_get_mapping: action.payload.data,
                single_get_error: []
            };
        case singleGroupChannelGetConstants.NETWORK_SINGLE_CHANNEL_GROUP_GET_REJECTED:
            return {
                ...state,
                single_get_fetching: false,
                single_get_mapping: [],
                single_get_error: action.payload
            };

        case groupChannelAddConstants.NETWORK_CHANNEL_GROUP_ADD_PENDING:
            return {
                ...state,
                add_fetching: true,
                add_error: [],
                add_mapping: []
            };
        case groupChannelAddConstants.NETWORK_CHANNEL_GROUP_ADD_FULFILLED:
            return {
                ...state,
                add_fetching: false,
                add_mapping: action.payload,
                add_error: []
            };
        case groupChannelAddConstants.NETWORK_CHANNEL_GROUP_ADD_REJECTED:
            return {
                ...state,
                add_fetching: false,
                add_error: action.payload,
                add_mapping: []

            };
        case groupChannelAddConstants.NETWORK_CHANNEL_GROUP_ADD_CLEAR_STATE:
            return {
                ...state,
                add_mapping: [],
                add_error: []
            };
        case groupChannelUpdateConstants.NETWORK_CHANNEL_GROUP_UPDATE_PENDING:
            return {
                ...state,
                update_fetching: true,
                update_error: [],
                update_mapping: []
            };
        case groupChannelUpdateConstants.NETWORK_CHANNEL_GROUP_UPDATE_FULFILLED:
            return {
                ...state,
                update_fetching: false,
                update_mapping: action.payload,
                update_error: []

            };
        case groupChannelUpdateConstants.NETWORK_CHANNEL_GROUP_UPDATE_REJECTED:
            return {
                ...state,
                update_fetching: false,
                update_error: action.payload
            };
        case groupChannelUpdateConstants.NETWORK_CHANNEL_GROUP_UPDATE_CLEAR_STATE:
            return {
                ...state,
                update_mapping: [],
                update_error: []
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
