import React, {useState} from "react";
import ColAtm from "../../../../../atomic/Col/Col";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {cloneDeep} from "lodash"
import InputAtm from "../../../../../atomic/Input/Input";

const Rates = (props) => {
    const {setChangeRoomRate, updateType, availabilityTypeId} = props
    const [cloneRoomRate, setCloneRoomRate] = useState(cloneDeep(props.roomRate));
    const [dataChange, setDataChange] = useState(0);

    const onClickRate = (e, roomId) => {
        if (e.target.value === undefined) return 0;
        let tempData = cloneRoomRate;
        let findIndex = tempData.findIndex(room => room.id === parseInt(roomId))
        if (findIndex !== -1) {
            let findRoom = tempData.find(room => room.id === parseInt(roomId))
            let rateCount = findRoom.room_rate_mapping.length;
            let positiveCount = 0;
            let negativeCount = 0;
            if (Object.entries(findRoom).length > 0) {
                if (props.updateType === "rate_by_rate") {
                    Object.entries(findRoom.room_rate_mapping).map(([index, rate]) => {
                        if (rate.id === parseInt(e.target.id)) {
                            rate.rate_value = e.target.value
                        }
                        if (rate.rate_value) {
                            positiveCount = ++positiveCount
                        } else {
                            negativeCount = ++negativeCount
                            findRoom.room_selected = false
                        }
                    })
                    tempData[findIndex] = findRoom
                } else {
                    Object.entries(findRoom.room_rate_mapping).map(([index, rate]) => {
                        if (rate.id === parseInt(e.target.value)) {
                            rate.rate_selected ? rate.rate_selected = false : rate.rate_selected = true
                        }
                        if (rate.rate_selected) {
                            positiveCount = ++positiveCount
                        } else {
                            negativeCount = ++negativeCount
                            findRoom.room_selected = false
                        }
                    })

                    if (rateCount === negativeCount) {
                        findRoom.room_selected = false
                    } else if (rateCount === positiveCount) {
                        findRoom.room_selected = true
                    }
                    tempData[findIndex] = findRoom
                }
            }
            setCloneRoomRate(tempData);
            setChangeRoomRate(tempData);
            setDataChange(dataChange + 1);
        }
    }
    const onClickRoom = (e) => {
        if (e.target.value === undefined) return 0;

        let tempData = cloneRoomRate;
        let findIndex = tempData.findIndex(room => room.id === parseInt(e.target.value))
        if (findIndex !== -1) {
            let findRoom = tempData.find(room => room.id === parseInt(e.target.value))
            if (Object.entries(findRoom).length > 0) {
                findRoom.room_selected = !findRoom.room_selected;
                Object.entries(findRoom.room_rate_mapping).map(([index, rate]) => {
                    rate.rate_selected = !!findRoom.room_selected;
                });
                tempData[findIndex] = findRoom
            }
        }
        setCloneRoomRate(tempData)
        setChangeRoomRate(tempData)
        setDataChange(dataChange + 1)
    }

    return (
        <ColAtm className="bulk-update-rates">
            {
                Object.entries(cloneRoomRate).map(([index, room]) => {
                    return (
                        <>
                            <ColAtm md={12} className={`${props.updateType === "rate_by_rate" ? "rate-by-rate-label" : ""} mt-22 bulk-update-room-name`}>
                                <FormControlLabel
                                    className="desc-text bulk-update-room-icon"
                                    name={"room" + room.id + room.name + availabilityTypeId + updateType}
                                    control={<Checkbox color="primary" value={room.id} checked={room.room_selected} disabled={props.updateType === "rate_by_rate"} />}
                                    label={room.name}
                                    labelPlacement="left"
                                    onClick={onClickRoom}
                                />
                            </ColAtm>
                            {
                                Object.entries(room.room_rate_mapping).map(([index, rate]) => {
                                    return (
                                        <ColAtm>
                                            <ColAtm md={1} className="bulk-update-null-col"/>
                                            {
                                                props.updateType === "rate_by_rate" ?
                                                    <ColAtm md={11}
                                                            className="mt-8 d-block bulk-update-room-name bulk-update-rate-input">
                                                        <InputAtm
                                                            label={rate.name}
                                                            placeholder={rate.name}
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            id={rate.id}
                                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, '') }}
                                                            onChange={(e) => onClickRate(e, room.id)}
                                                        />
                                                    </ColAtm>
                                                    :
                                                    <ColAtm md={11} className="mt-8 d-block bulk-update-room-name">
                                                        <FormControlLabel
                                                            className="desc-text"
                                                            name={"rate" + rate.id + "room" + room.id + "room.name" + availabilityTypeId + updateType}
                                                            control={<Checkbox color="primary" value={rate.id}
                                                                               checked={rate.rate_selected}/>}
                                                            label={rate.name}
                                                            labelPlacement="left"
                                                            onClick={(e) => onClickRate(e, room.id)}
                                                        />
                                                    </ColAtm>
                                            }
                                        </ColAtm>
                                    )
                                })
                            }
                        </>
                    )
                })
            }
        </ColAtm>
    )
}

export default Rates
