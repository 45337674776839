import React      from 'react';
import PropTypes  from 'prop-types';

import Typography from '@material-ui/core/Typography';

const LabelAtm = props => (
    <Typography className={props.labelClass} onClick={props.onClick} id={props.id} style={props.style} component={props.component} color={props.color} variant={props.variant}>{props.text}{props.icon}</Typography>
);

LabelAtm.propTypes = {
    text: PropTypes.any,
    icon: PropTypes.any,
    labelClass: PropTypes.string,
    style: PropTypes.any,
    component: PropTypes.string,
    onClick: PropTypes.any,
    variant: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string
};

export default LabelAtm;
