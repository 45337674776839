export const getCreateManualPayment = {
    GET_CREATE_MANUAL_PAYMENT_DATA_PENDING  : 'GET_CREATE_MANUAL_PAYMENT_DATA_PENDING',
    GET_CREATE_MANUAL_PAYMENT_DATA_FULFILLED: 'GET_CREATE_MANUAL_PAYMENT_DATA_FULFILLED',
    GET_CREATE_MANUAL_PAYMENT_DATA_REJECTED : 'GET_CREATE_MANUAL_PAYMENT_DATA_REJECTED',
};

export const createManualPayment = {
    CREATE_MANUAL_PAYMENT_PENDING  : 'CREATE_MANUAL_PAYMENT_PENDING',
    CREATE_MANUAL_PAYMENT_FULFILLED: 'CREATE_MANUAL_PAYMENT_FULFILLED',
    CREATE_MANUAL_PAYMENT_REJECTED : 'CREATE_MANUAL_PAYMENT_REJECTED',
};

export const getUpdateManualPayment = {
    GET_UPDATE_MANUAL_PAYMENT_DATA_PENDING  : 'GET_UPDATE_MANUAL_PAYMENT_DATA_PENDING',
    GET_UPDATE_MANUAL_PAYMENT_DATA_FULFILLED: 'GET_UPDATE_MANUAL_PAYMENT_DATA_FULFILLED',
    GET_UPDATE_MANUAL_PAYMENT_DATA_REJECTED : 'GET_UPDATE_MANUAL_PAYMENT_DATA_REJECTED',
};

export const updateManualPayment = {
    UPDATE_MANUAL_PAYMENT_PENDING  : 'UPDATE_MANUAL_PAYMENT_PENDING',
    UPDATE_MANUAL_PAYMENT_FULFILLED: 'UPDATE_MANUAL_PAYMENT_FULFILLED',
    UPDATE_MANUAL_PAYMENT_REJECTED : 'UPDATE_MANUAL_PAYMENT_REJECTED',
};
