import React from "react";
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button';

const ButtonAtm = props => (
    <Button
        id={props.id}
        className={props.className}
        htmlFor={props.htmlFor}
        ref={props.ref}
        onClick={props.onClick}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        variant={props.variant}
        color={props.color}
        type={props.type}
        value={props.value}
        style={props.style}
        form={props.form}
        title={props.title}
        fullWidth={props.fullwidth}
        disabled={props.disabled}
    >
        {props.buttontext}
    </Button>
);

ButtonAtm.propType = {
    style: PropTypes.any,
    className: PropTypes.any,
    htmlFor: PropTypes.string,
    ref: PropTypes.string,
    id: PropTypes.any,
    fullwidth: PropTypes.any,
    onClick: PropTypes.func,
    type: PropTypes.string,
    buttontext: PropTypes.string,
    form: PropTypes.string,
    startIcon: PropTypes.any,
    endIcon: PropTypes.any,
    title: PropTypes.any,
    value: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ButtonAtm;
