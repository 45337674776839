import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import Menu from "../../Global/Layout/Header/Submenu/General";
import Location from "./components/Location";

import {getContactList,updateContact} from "../../../../redux/actions/contactActions"
import {getPropertyInfo} from "../../../../redux/actions/generalInformationActions"
import {Animated} from "react-animated-css";
import Container from "@material-ui/core/Container";
import FooterAtm from "../../../atomic/Footer/Footer";
import {Link, Redirect} from "react-router-dom";
import PageHeader from "../../../atomic/PageHeader/PageHeader";
import {
    CONTENT,
    CONTENT_BRAND,
    CONTENT_COMMUNICATION,
    CONTENT_EMPLOYEES
} from "../../../../constants/appUrls.constants";

let t = null;

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getContactList();
        this.props.getPropertyInfo();
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={CONTENT_COMMUNICATION} />
        }
    };

    render() {
        const {fetching,address,latitude,longitude,propertyName ,getInfoPropertyFetching,updateContact } = this.props
        return (
            <div>
                <PageTitle title={ t('enw-general-location-page_title') } />
                <PageHeader backButton={CONTENT}
                            exitText={ t('btn-dashboard') }
                            text={ t('enw-general-title') }/>
                <Menu locationColClass="active-menu-bg" />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl" style={{padding: 0}}>
                        {
                            ( !fetching && !getInfoPropertyFetching) &&
                                <Location propertyName={propertyName}
                                     propertyAddress={address}
                                     propertyLatitude={latitude}
                                     propertyLongitude={longitude}
                                     updatePropertyContantStatus={this.props.updateData}
                                     updatePropertyContant={updateContact}/>
                        }
                    </Container>
                </Animated>
                <FooterAtm
                    buttonBackText={ t('btn-back') }
                    iconRightClassName="fas fa-arrow-right"
                    buttonBackStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    buttonNextStyle={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    backTo={CONTENT_EMPLOYEES}
                    nextTo={CONTENT_BRAND}
                    nextDisabled={this.props.buttonPassiveStatus}
                    backDisabled={this.props.buttonPassiveStatus}
                    component={Link}
                    nextComponent={Link}
                    buttonNextText={ t('btn-next') }
                    iconLeftClassName="fas fa-arrow-left"
                    onClick={this.handleSaveUpdateClick}
                />
                {this.renderRedirect()}
            </div>
        );
    }
}


const mapStateToProps = (props) => {
    return{
        //Processing Props

        getInfoPropertyFetching : props.generalInformationReducer.fetching,
        fetching : props.contactReducers.fetching,
        updateFetching : props.contactReducers.updateFetching,
        buttonPassiveStatus : props.contactReducers.buttonPassiveStatus,
        updateData : props.contactReducers.updateData,
        error : props.contactReducers.error,

        // Maps Data
        address : props.contactReducers.address,
        latitude : props.contactReducers.latitude,
        longitude : props.contactReducers.longitude,
        propertyName : props.generalInformationReducer.hasOwnProperty("property_info") &&
            props.generalInformationReducer.property_info.hasOwnProperty("name") ? props.generalInformationReducer.property_info.name : null,
    }
}

const mapDispatchToProps = {
    getContactList,
    updateContact,
    getPropertyInfo,
}

export default compose(withTranslation('common'))(connect(mapStateToProps, mapDispatchToProps )(Index));
