import React from 'react';
import {getAppLang, getProperty} from "../../../../../../utils";
import axios from "axios";
import {useDispatch} from "react-redux";
import {getPopupList} from "../../../../../../redux/actions/Web/Popup/PopupActions";
import {DELETE_POPUP} from "../../../../../../constants/api.constants";
import {Dialog, DialogActions, DialogTitle, Button, DialogContentText} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const DeletePopup = ({popup}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const deletePopup = () => {
        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(DELETE_POPUP + "/" + popup.id, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            dispatch(getPopupList());
        }).catch(function (error) {
            console.log(error);
        });
    }

    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

    return (
        
        <div className="blog-delete-btn" >
          <Button variant="outlined" className='delete-modal-button' onClick={handleClickOpen}>
        <i className="fas fa-trash-alt"></i>
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="red-bottom-border" id="my-web-dialog">
              {t('enw-web_alert-title')}
              <IconButton aria-label="close" className='closeIcon' onClick={handleClose}>
                  <CloseIcon className="close-button" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="register-text color-black" id="alert-dialog-slide-description">
                {t('enw-web_alert-msg')}
              </DialogContentText>
              <DialogActions className='pb-0 m-auto'>
                <Button onClick={handleClose} autoFocus className="room-rate-modal-save bg-dark-grey">{t('enw-input-checkbox-no')}</Button>
                <Button onClick={deletePopup} className="room-rate-modal-save bg-red">{t('enw-input-checkbox-yes')}</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
    )
}

export default DeletePopup;
