import React from "react";
import {connect} from "react-redux";
import ColAtm from "../../../../atomic/Col/Col";
import Container from "@material-ui/core/Container";
import {Animated} from "react-animated-css";
import {PageTitle} from "../../../../atomic/PageHeader/PageTitle";
import NewRoomModal from "./NewRoom/NewRoomModal";
import RoomList from "./List/RoomList";

import {Grid} from "@material-ui/core";
import {PropertyRoomAndRateList,PropertyRoomTypes,PropertyRoomRates,propertyAssignRate} from "../../../../../redux/actions/propertyRoomAndRateActions";
import AllRoomTypes from "./Filters/AllRoomTypes";
import PageHeaderAtm from "../../../../atomic/PageHeader/PageHeader";
import AddRoomButton from "../../../../organisms/AddRoomButton/AddRoomButton";
import RoomMenu from "../../../Global/Layout/Header/Submenu/Room";
import {Link} from "react-router-dom";
import FooterAtm from "../../../../atomic/Footer/Footer";
import LabelAtm from "../../../../atomic/Label/Label";
import Loading from "../../../../molecules/Loading/Loading";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../../utils/history";
import {roomTypes} from "../../../../../redux/actions/roomTypeActions";
import {allBedTypes} from "../../../../../redux/actions/bedTypesActions";
import {getRoomViewTypes} from "../../../../../redux/actions/Content/Room/roomViewTypesAction";
import {getRoomSizeTypes} from "../../../../../redux/actions/Content/Room/roomSizeTypesAction";
import {
    CONTENT,
    CONTENT_FACTS_SHORT,
    CONTENT_ROOM_AMENITIES
} from "../../../../../constants/appUrls.constants";
let t=null;

class ContentCreateRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRoomTypeId : 0,
            selectedRoomRateId : 0,
            roomAddModalStatus : false,
            redirect: false,
            showSuccessHeader: false,
        };
        t = props.t;
    }


    onChangeRoomFilter = (selectedIndex) => {
        this.setState({
            selectedRoomTypeId : selectedIndex,
        });
    };

    onAddAssignRate = (param) => (e) => {
        this.props.propertyAssignRate(param);
    };

    componentDidMount() {
        this.props.PropertyRoomAndRateList();
        this.props.PropertyRoomTypes();
        this.props.roomTypes();
        this.props.allBedTypes();
        this.props.getRoomViewTypes();
        this.props.getRoomSizeTypes();
    }

    onClickNextButton = () => {
        this.setState({
            redirect: true,
            showSuccessHeader:true
        })

    };

    headerStatusShow = () =>{
        return  <PageHeaderAtm backButton={CONTENT}
                               exitText={ t('btn-dashboard')}
                               text={ t('enw-room-title') }
                               showSuccessHeader={this.state.showSuccessHeader}
        />
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            /*setTimeout(() => {
                history.push(CONTENT_ROOM_AMENITIES)
            }, 2000);*/
            history.push(CONTENT_ROOM_AMENITIES)
        }
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-room-rooms-page_title') } />
                {
                    this.headerStatusShow()
                }

                { this.props.propertyRoomRateListFetching ? (

                    <Loading />

                ) : (
                    <>
                    { this.props.propertyRoomRateList.length < 1 ? (
                            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                <Container maxWidth="xl">
                                    <div className="container-width">
                                        <ColAtm>
                                            <Container maxWidth="xl">
                                                <div className="offer-container-width">
                                                    <ColAtm>
                                                        <ColAtm xs={12} md={4} lg={4} xl={4}  className="photoButtonsRow one-page-content">
                                                            <ColAtm>
                                                                <div className="m-auto first-create-room">
                                                                <span>
                                                                    <AddRoomButton
                                                                        roomMenuItem={
                                                                            <NewRoomModal roomAddModalStatus={this.state.roomAddModalStatus}
                                                                                          onPropertyAddRoom={this.onPropertyAddRoom}
                                                                                          rooms={this.props.propertyRoomRateList}
                                                                            />
                                                                        }
                                                                    />
                                                                </span>
                                                                </div>
                                                            </ColAtm>
                                                            <LabelAtm labelClass="one-page-title" text={ t('enw-landing_create_room-subtitle') } />
                                                            <LabelAtm labelClass="one-page-subtitle" text={ t('enw-landing_create_room-desc') } />
                                                        </ColAtm>
                                                    </ColAtm>
                                                </div>
                                            </Container>
                                        </ColAtm>
                                    </div>
                                </Container>
                            </Animated>
                        ) : (
                            <>
                                <RoomMenu
                                    createRoomColClass="active-menu-bg"
                                />
                                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                                    <Container maxWidth="xl">
                                        <div className="container-width">
                                            <Grid container spacing={2} className="photoButtons room-rate-filter-group">
                                                <Grid item className="type-plan-buttons">
                                                    { this.props.propertyRoomTypesFetching ? (
                                                        <Loading />
                                                    ) : (
                                                        <AllRoomTypes propertyRoomTypes={this.props.propertyRoomTypes }
                                                                      onChangeRoomFilter={this.onChangeRoomFilter}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item className="photoButtonsRow">
                                                    <AddRoomButton
                                                        roomMenuItem={
                                                            <NewRoomModal roomAddModalStatus={this.state.roomAddModalStatus}
                                                                          onPropertyAddRoom={this.onPropertyAddRoom}
                                                                          rooms={this.props.propertyRoomRateList}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <ColAtm className="room-rate-list-col">
                                                { !this.props.propertyRoomRateListFetching &&


                                                <RoomList rooms={this.props.propertyRoomRateList}
                                                          updateStatus={this.props.updateStatus}
                                                          allRoomTypes={this.props.allRoomTypes}
                                                          allBedType={this.props.allBedType}
                                                          roomViewTypes={this.props.roomViewTypes}
                                                          roomSizeTypes={this.props.roomSizeTypes}
                                                          selectedRoomTypeId={this.state.selectedRoomTypeId}/>
                                                }
                                            </ColAtm>
                                        </div>
                                    </Container>
                                </Animated>
                                <FooterAtm
                                    buttonBackText={ t('btn-back') }
                                    iconRightClassName="fas fa-arrow-right"
                                    buttonBackStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                    buttonNextStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }}
                                    backTo={CONTENT_FACTS_SHORT+'223'}
                                    component={Link}
                                    nextComponent={Link}
                                    buttonNextText={ t('btn-next') }
                                    iconLeftClassName="fas fa-arrow-left"
                                    backDisabled={this.state.showSuccessHeader}
                                    nextDisabled={this.state.showSuccessHeader}
                                    onClick={this.onClickNextButton}
                                />
                                {this.renderRedirect()}
                            </>
                        )}
                        </>
                )}

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        propertyRoomRateListFetching : state.propertyRoomAndRateReducers.propertyRoomRateListFetching,
        propertyRoomRateList : state.propertyRoomAndRateReducers.propertyRoomRateList,

        propertyRoomTypesFetching : state.propertyRoomAndRateReducers.propertyRoomTypesFetching,
        propertyRoomTypes : state.propertyRoomAndRateReducers.propertyRoomTypes,

        roomRatesFetching : state.propertyRoomAndRateReducers.roomRatesFetching,
        roomRates : state.propertyRoomAndRateReducers.roomRates,

        roomAndBedAddStatus : state.propertyRoomAndRateReducers.roomAndBedAddStatus,
        updateStatus : state.propertyRoomAndRateReducers.updateStatus,

        allRoomTypes : state.roomTypesReducer.allRoomTypes,
        allBedType : state.bedTypesReducer.allBedType,
        roomViewTypes: state.RoomViewTypesReducer.roomViewTypes,
        roomSizeTypes: state.RoomSizeTypeReducer.roomSizeTypes,
    }
};

const mapDispatchToProps = {
    PropertyRoomAndRateList,
    PropertyRoomTypes,
    PropertyRoomRates,
    propertyAssignRate,
    roomTypes,
    allBedTypes,
    getRoomViewTypes,
    getRoomSizeTypes,
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ContentCreateRoom));
