import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import moment from 'moment';
import 'moment/min/locales.min';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import ButtonAtm from "../../../../atomic/Button/Button";
import {constructListOfIntervals, getNextDates, getPreviousDate, setInventoryDate} from "../../../../../utils";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

let t=null;

const dateFormat = 'YYYY-MM-DD';

class PriceDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: '',
            startDate: moment(this.props.startDate, dateFormat),
            endDate: moment(this.props.endDate, dateFormat),
            allDays: this.props.allDays,
        };
        this.onDatesChange = this.onDatesChange.bind(this)
        this.onFocusChange = this.onFocusChange.bind(this)
        t = props.t;
    }

    onDatesChange({startDate, endDate}) {

        const { getCpa, showCompetitor } = this.props;

        if (endDate != null || startDate != null) {

            if (this.state.focusedInput == "startDate") {
                let endDate = null;
                this.setState({startDate,endDate})
                return
            }

            if (this.state.focusedInput == "endDate") {
                if( (endDate != null && startDate != null) ){
                    let allDays = constructListOfIntervals(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"),'day');
                    this.setState({startDate, endDate, allDays}, () => {
                         this.props.getPropertyInvertoryDate( moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD") )
                    });

                    if (showCompetitor) {
                        this.props.getCpaClearState();

                        setTimeout(() => {
                            allDays.map((day) => {
                                getCpa.map((cpa) => {
                                    this.props.getCpaDailyPrice(day, cpa?.key)
                                });
                            });
                        }, 100);
                    }
                }
            }

        }
    }

    onFocusChange(focusedInput) {
        this.setState({focusedInput});
    }

    isOutsideRange=(day) => {
        if (this.state.focusedInput == "endDate") {
            return day.isAfter(moment(this.state.startDate, dateFormat).add(14, 'days')) || day.isBefore(this.state.startDate);
        }
        return false;
    }

    onClickPreviousDate  = () => {
        const {previousDate, getCpa, showCompetitor} = this.props;
        const {startDate,allDays} = this.state;

        let tempAllDays = getPreviousDate( moment(startDate).format("YYYY-MM-DD"), 7,allDays,'day');
        let tempStartDate = tempAllDays[0];
        let tempEndDate = tempAllDays[tempAllDays.length -1];

        previousDate( tempStartDate, tempEndDate );

        this.setState({
            startDate: moment(tempStartDate, dateFormat),
            endDate: moment(tempEndDate, dateFormat),
            allDays: tempAllDays,
        })

        if (showCompetitor) {
            this.props.getCpaClearState();
            window.stop();
            setTimeout(() => {
                tempAllDays.map((day) => {
                    getCpa.map((cpa) => {
                        this.props.getCpaDailyPrice(day, cpa?.key)
                    });
                });
            }, 100);
        }
    }

    onClickNextDate  = () => {
        const {nextDate, getCpa, showCompetitor} = this.props;
        const {endDate,allDays} = this.state;

        let tempAllDays = getNextDates(moment(endDate).format("YYYY-MM-DD"), 7, allDays, "day");
        let tempStartDate = tempAllDays[0];
        let tempEndDate = tempAllDays[tempAllDays.length -1];

        this.setState({
            startDate: moment(tempStartDate, dateFormat),
            endDate: moment(tempEndDate, dateFormat),
            allDays: tempAllDays,
        })

        if (showCompetitor) {
            this.props.getCpaClearState();
            window.stop();
            setTimeout(() => {
                tempAllDays.map((day) => {
                    getCpa.map((cpa) => {
                        this.props.getCpaDailyPrice(day, cpa?.key)
                    });
                });
            }, 100);
        }

        nextDate(tempStartDate, tempEndDate);
    }

    render() {
        const {focusedInput, startDate, endDate} = this.state;
        const appLanguage = localStorage.getItem("lng");
        moment.locale(appLanguage)

        setInventoryDate(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))

        localStorage.setItem("allDays", JSON.stringify(this.state.allDays))

        return (
                <Grid container>
                    <Grid item xs={12} sm={4} className="pos-relative">
                        <Grid className="offer-date-icon offer-start-icon">
                            <p>{ t('enw-input-from_date') }</p>
                            <i className="far fa-calendar-alt" />
                        </Grid>
                        <DateRangePicker
                            onDatesChange={this.onDatesChange}
                            onFocusChange={this.onFocusChange}
                            focusedInput={focusedInput}
                            startDate={moment(startDate).locale(appLanguage)}
                            endDate={moment(endDate).locale(appLanguage)}
                            displayFormat="MMM DD YYYY"
                            isOutsideRange={this.isOutsideRange}
                            minimumNights={0}
                        />
                        <Grid className="offer-date-icon offer-end-icon">
                            <p>{ t('enw-input-to_date') }</p>
                            <i className="far fa-calendar-alt" />
                        </Grid>
                    </Grid>
                    <Grid item sm={8} className="offer-price-dates">
                        <Grid container className="pos-relative price-dates-m-width">
                            <ButtonAtm
                                className="inventory-week-button m-r-inventory-auto"
                                startIcon={<i className="fas fa-caret-left" />}
                                onClick={this.onClickPreviousDate}
                                disabled={this.props.fetching}
                            />
                            {Object.entries(this.state.allDays).map(([arr, val])=>{
                                return <Grid item className={`inventory-price-day`} >
                                    <Grid className={`${(moment(val).locale('de').weekday() == 5 || moment(val).locale('de').weekday() == 6 ) ? 'weekend' : 'weekdays'}`}>
                                        <p>
                                            {moment(val).locale(appLanguage).format('ddd')}
                                        </p>
                                        <p>
                                            {moment(val).locale(appLanguage).format('D')}
                                        </p>
                                        <p>
                                            {moment(val).locale(appLanguage).format('MMM')}
                                        </p>
                                    </Grid>
                                </Grid>
                            })}

                            <ButtonAtm
                                className="inventory-week-button m-h-auto inventory-week-right-button"
                                startIcon={<i className="fas fa-caret-right" />}
                                onClick={this.onClickNextDate}
                                disabled={this.props.fetching}
                            />
                        </Grid>
                    </Grid>
                </Grid>
        );
    }
}


export default compose(withTranslation('common'))(PriceDateRange);
