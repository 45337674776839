import React, {Component} from 'react';
import ColAtm from "../../../../atomic/Col/Col";
import LabelAtm from "../../../../atomic/Label/Label";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import {TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {isDefined} from "../../../../../utils";
let t=null;


class SavedAddress extends Component {
    constructor(props) {
        super(props);
        this.state={
            activeStep: 0,
            address : this.props.address,
            hasAdress: isDefined(this.props.address) && this.props.address !== null ?  (this.props.address.length > 0 ) : false
        }
        t = props.t;
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.state.address.length > 0 ?
                this.setState({hasAdress: true})
                :
                this.setState({hasAdress: false})
        });
    };

    handleNext = () => {

       if(this.state.activeStep === 0){
           this.updatePropertyContant();
       }

    };

    updatePropertyContant= () => {
        const {address} = this.state;
        const {location} = this.props

        if( this.state.hasAdress){
            this.props.updatePropertyContant({
                "address"  : address,
                "latitude" : parseFloat(location.lat),
                "longitude": parseFloat(location.long),
            });
        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.address !== this.props.address){
            this.setState({
                address : this.props.address
            })
        }
    }

    render() {
        const {activeStep} = this.state
        const { propertyName,editingSavedAddress } = this.props

        return (
            <div className={ editingSavedAddress ?  "location-stepper-root" : "d-none" } >
                <div>
                    <h3 className="location-find-results"> { t('enw-general-location-edit_your_address') }</h3>
                </div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={0}>
                        <StepLabel> { t('enw-general-location-verify_your_address') } </StepLabel>
                        <StepContent>
                            <ColAtm className="location-card">
                                <LabelAtm color="textSecondary" component="h4" text={propertyName} />
                            </ColAtm>
                            <Typography className="pin-text">
                                { t('enw-general-location-map_pin_warning_message') }
                            </Typography>
                            <Typography>
                                <ColAtm className="location-card">
                                    <TextField
                                        multiline={true}
                                        type="text"
                                        name="address"
                                        label={ t('enw-input-address') }
                                        variant="outlined"
                                        rows={2}
                                        fullWidth
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        error={!this.state.hasAdress}
                                    />
                                </ColAtm>
                            </Typography>
                            <div className="mt-22">
                                <div>
                                    <Button
                                        onClick={this.props.backSeacrh}
                                        startIcon={<i className="fas fa-search-location" />}
                                    >
                                        { t('btn-back_search') }
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        startIcon={<i className="fas fa-check" />}
                                        disabled={!this.state.hasAdress}
                                    >
                                        { t('btn-finish') }
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

//export default SavedAddress;
export default compose(withTranslation('common'))(SavedAddress);
