import {inventoryConstants} from "../../../../constants/Network/inventory.constants";

const initialState = {
    fetching:false,
    quickPricing : {},
    error : {},

    getQuickPricing : {},
    errorGetQuickPricing : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case inventoryConstants.UPDATE_QUICK_PRICING_PENDING:
            return {
                ...state,
                fetching : false,
                quickPricing : {},
                error : {}
            };
        case inventoryConstants.UPDATE_QUICK_PRICING_FULFILLED:
            return {
                ...state,
                fetching: false,
                quickPricing : action.payload
            };
        case inventoryConstants.UPDATE_QUICK_PRICING_REJECTED:
            return {
                ...state,
                fetching : false,
                error    : action.payload
            };
        case inventoryConstants.GET_QUICK_PRICING_PENDING:
            return {
                ...state,
                fetching : true,
                getQuickPricing: {},
                error : {}
            };
        case inventoryConstants.GET_QUICK_PRICING_FULFILLED:
            return {
                ...state,
                fetching: false,
                getQuickPricing: action.payload
            };
        case inventoryConstants.GET_QUICK_PRICING_REJECTED:
            return {
                ...state,
                fetching : false,
                errorGetQuickPricing: action.payload
            };
        case inventoryConstants.UPDATE_QUICK_PRICING_CLEAR:
            return {
                ...state,
                quickPricing : {},
                error : {},
            };
        default:
            return state
    }
}
