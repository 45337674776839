import React, {Component}   from 'react';
import {PageTitle} from "../../../../../atomic/PageHeader/PageTitle";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {listChannels} from "../../../../../../redux/actions/channelListActions";
import PromotionChannelList from "./ChannelList";

let t =null;

class PromotionChannelIndex extends Component {

    constructor(props) {
        super(props);
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.listChannels();
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-channels-page_title') } />

                <PromotionChannelList channel_list={this.props.channel_list} />

            </>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        channel_list : props.channelListReducers.channel_list,
    }
};

const mapDispatchToProps ={
    listChannels
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(PromotionChannelIndex));
