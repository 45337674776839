import React, {useState} from "react";
import CheckboxAtm from "../../../../../atomic/Checkbox/Checkbox";

const PaymentCancellationPolicy = ({cancellation, handleClickCancellationPolicy, cancellation_list, index}) => {

    const [isSelected , setIsSelected ] = useState(false );


    const handleUpdateChild = (e, id) => {
        setIsSelected(!isSelected)
        handleClickCancellationPolicy(e, index, id);
    }

    return(
        <CheckboxAtm
            className="fact-box"
            checkboxId={cancellation.id+" "+cancellation.name}
            value={cancellation.id}
            checked={ cancellation_list.find((id) => {
                return id === cancellation.id;
            })}
            onChange={ (e) => { handleUpdateChild(e, cancellation.id) } }
        />
    )


}
export default PaymentCancellationPolicy;