import {contactConstants} from "../../../constants/Content/contact.constants";

const initialState = {
    fetching : false,
    updateFetching : false,
    buttonPassiveStatus : false,
    address  : null,
    latitude : null,
    longitude: null,
    updateData:null,
    error    :{},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case contactConstants.GET_PROPERTY_CONTACT_PENDING:
            return {
                ...state,
                fetching : true,
                buttonPassiveStatus:true,
            };
        case contactConstants.GET_PROPERTY_CONTACT_FULFILLED:
            return {
                ...state,
                fetching :false,
                buttonPassiveStatus:false,
                address: action.payload.property_contact.address,
                latitude: action.payload.property_contact.latitude,
                longitude: action.payload.property_contact.longitude
            };
        case contactConstants.GET_PROPERTY_CONTACT_REJECTED:
            return {
                ...state,
                fetching : false,
                buttonPassiveStatus:false,
                error: action.payload,
            };
        //UPDATE_PROPERTY_CONTACT
        case contactConstants.UPDATE_PROPERTY_CONTACT_PENDING:
            return {
                ...state,
                updateFetching : true,
                buttonPassiveStatus:true,
                updateData:null
            };
        case contactConstants.UPDATE_PROPERTY_CONTACT_FULFILLED:
            return {
                ...state,
                updateFetching : false,
                buttonPassiveStatus : false,
                updateData : action.payload.status,
            };
        case contactConstants.UPDATE_PROPERTY_CONTACT_REJECTED:
            return {
                ...state,
                updateFetching :false,
                buttonPassiveStatus:false,
                error: action.payload,
            };
        default:
            return state
    }
}
