import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../../atomic/Button/Button";
import ColAtm from "../../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../../utils";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CreateContactModal(props) {

    const { t } = useTranslation();

    const {channelContactReducers, getChannelContact, clearErrorMessage, addChannelContact, channelId} = props;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

        setName("")
        setEmail("")
    };
    const handleClose = () => {
        setOpen(false);
        clearErrorMessage();
    };

    const changeName = ({target}) => {
        setName(target.value)
    };

    const changeMail = ({target}) => {
        setEmail(target.value)
    };

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            channel_id: channelId,
            name: name,
            email: email,
        }

        addChannelContact(params)
    };



    useEffect( () => {
        if (channelContactReducers.channelContactCreate.status === 200) {
            if(open){
                getChannelContact(channelId);
            }
            handleClose();
        }

        return () => {
            if(isDefined(channelContactReducers.channelContactCreateError) &&
                isDefined(channelContactReducers.channelContactCreateError.response)){
                setName("")
                setEmail("")
            }
        }
    },[
        channelContactReducers.channelContactCreate,
        channelContactReducers.channelContactCreateError
    ]);

    return (
        <div className="w-100">
            <Button className="filter-action-button float-right" aria-controls="filter-action-menu" aria-haspopup="true" onClick={handleClickOpen}>
                <i className="fas fa-plus-circle" /> { t('enw-channels-contact_create-title') }
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="responsive-dialog-title"
                key={3}
            >
                <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                    { t('enw-channels-contact_create-title') }
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>

                            {
                                (isDefined(channelContactReducers.channelContactCreateError) &&
                                    isDefined(channelContactReducers.channelContactCreateError.response)
                                ) &&
                                <Alert severity="error">{channelContactReducers.channelContactCreateError.response.data.message}</Alert>
                            }

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextValidator
                                        label={ t('enw-input-name_surname') }
                                        name="name"
                                        fullWidth
                                        autoComplete="off"
                                        value={name}
                                        onChange={changeName}
                                        variant="outlined"
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidator
                                        label={ t('enw-input-email') }
                                        name="email"
                                        fullWidth
                                        autoComplete="off"
                                        value={email}
                                        onChange={changeMail}
                                        variant="outlined"
                                        validators={['required']}
                                        errorMessages={[t("enw-notification_this_is_required")]}
                                    />
                                </Grid>
                            </Grid>
                            <ColAtm className="mt-22" md={12}>
                                <ButtonAtm
                                    className="m-auto room-rate-modal-save bg-red"
                                    type="submit"
                                    buttontext={ t('btn-save') }
                                    color="primary"
                                />
                            </ColAtm>
                        </ValidatorForm>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
}
