import React, {Fragment, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ButtonAtm from "../../../../atomic/Button/Button";
import ColAtm from "../../../../atomic/Col/Col";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {isDefined} from "../../../../../utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function UpdatePaymentModal(props) {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [defaultPayment, setDefaultPayment] = useState(false);

    const [state, setState] = React.useState({
        bankCodes: props.paymentMappingListData.bank_params,
        bankCodesPost: props.paymentMappingListData.fields
    });

    const handleClickOpen = () => {
        setOpen(true);

    };
    const handleClose = () => {
        setOpen(false);
        props.updatePaymentErrorClear();
        props.successPaymentClear();
        props.GetPaymentType({ payment_type_id : props.paymentTypeGet.id });
    };
    const handleChangeSetDefault = (e) => {
        let checked = isDefined(e.target.checked) && e.target.checked
        setDefaultPayment(checked)
    }

    const handleChangeBankCodes = (e,key) => {

        let values = state.bankCodesPost;
        values[key] = e.target.value;
        setState({ ...state, values });

    }

    const { handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();

        const params = {
            property_payment_mapping_id : props.paymentMappingListData.id,
            currency: props.paymentMappingListData.code,
            fields: state.bankCodesPost,
            is_default: defaultPayment
        }

        props.UpdatePaymentMapping(params);
    };
    useEffect( () => {

        if (props.paymentMapping.updateMappingStatus === 200 && open) {
            props.GetPaymentType({ payment_type_id : props.paymentTypeGet.id });
            handleClose();
        }
    },[props.paymentMapping.updateMappingStatus] )

    return (
        <div>
            <ButtonAtm className="edit-channel-price-btn"
                       endIcon={<i className="fas fa-pen" />}
                       onClick={handleClickOpen}
                       title={t('btn-update')}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" className="red-bottom-border" onClose={handleClose}>
                        { t('enw-payment-update_payment-title') }
                    </DialogTitle>
                    <DialogContent>
                        <Fragment>
                            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
                                {props.paymentMapping.updateMappingStatus === 200 && <Alert severity="success">{ t('enw-redirect_to_save-message') }</Alert>}
                                {isDefined(props.paymentMapping.errorUpdatePaymentMappingData) && <Alert severity="error">{props.paymentMapping.errorUpdatePaymentMappingData.response.data.message}</Alert>}
                                <Grid container className="payment-create-dialog" spacing={3}>
                                    {
                                         Object.keys(props.paymentMappingListData.fields).map( (paymentCode) => (
                                            <Grid item xs={12} md={Object.keys(props.paymentMappingListData.fields).length > 1 ? 6 : 12}>
                                                <TextValidator
                                                    label={ t(state.bankCodes[paymentCode].name) }
                                                    type="text"
                                                    fullWidth
                                                    value={props.paymentMappingListData.fields[paymentCode]}
                                                    onChange={(e) => handleChangeBankCodes(e, paymentCode)}
                                                    name={paymentCode}
                                                    autoComplete="off"
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={[ t("enw-notification_this_is_required") ]}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <Grid item md={12} className="mt-22 d-block">
                                    <FormControlLabel
                                        className="desc-text"
                                        name="default"
                                        control={<Checkbox color="primary" value="false" defaultChecked={props.paymentMappingListData.is_default} />}
                                        label={ t('enw-input-is_default')+"?" }
                                        labelPlacement="left"
                                        onClick={handleChangeSetDefault}
                                    />
                                </Grid>
                                <ColAtm className="mt-32" md={12}>
                                    <ButtonAtm
                                        className="m-auto room-rate-modal-save bg-red"
                                        type="submit"
                                        buttontext={ t('btn-save') }
                                        color="primary"
                                    />
                                </ColAtm>
                            </ValidatorForm>
                        </Fragment>
                    </DialogContent>
                </Dialog>
            </Dialog>
        </div>
    );
}
