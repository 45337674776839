import React from 'react';
import ColAtm from "../../../../../atomic/Col/Col";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import LabelAtm from "../../../../../atomic/Label/Label";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {withTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FactCheckBox from "../../../../../molecules/FactContent/FactContent";
import {compose} from "redux";


let t =null;

class ChannelRoomRateList extends React.Component {
    constructor(props){
        super(props);
        t = props.t;
    }
    state = {
        payment: [{}],
        property_room_rate_mapping: [{}],
        is_selected: "",
        start: '',
        channel_id: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel_id : '',
        currency_code: this.props.channelSetup.channelSetupData.data ? this.props.channelSetup.channelSetupData.data.channel.currency : '',
    };

    componentDidMount() {
        let property_room_rate_mapping = [];
        let temp = [];

        if(this.props.getChannelRoomRateData.length  !== 0 ) {
            this.props.getChannelRoomRateData.map((cancellation) => {

                Object.keys(cancellation).map(key => {
                    switch (key) {
                        case 'property_room_rate_mapping':
                            Object.keys(cancellation[key]).length > 0 ?
                                cancellation[key].map(pay => {
                                    let tempRoomRate = {
                                        name: pay.name,
                                        room_id: pay.room_id,
                                        channel_id: this.state.channel_id,
                                        room_rate_mapping_id: pay.id,
                                        id: pay.id,
                                        end_date: pay.end_date,
                                        start_date: pay.start_date,
                                        is_selected: pay.is_selected,
                                        has_date: pay.has_date
                                    }
                                    property_room_rate_mapping = [...property_room_rate_mapping, tempRoomRate]

                                })
                                :
                                temp = {
                                    name: '',
                                    room_rate_mapping_id: '',
                                    id: '',
                                    channel_id: '',
                                    room_id: '',
                                    end_date: '',
                                    start_date: '',
                                    is_selected: '',
                                    has_date: false
                                }
                            property_room_rate_mapping = [...property_room_rate_mapping]
                            break;
                    }

                })

            })

        }

        this.setState({
            property_room_rate_mapping: property_room_rate_mapping.length > 0 ? property_room_rate_mapping : {
                name: "",
                id: "",
                room_rate_mapping_id: "",
                channel_id: "",
                room_id: "",
                end_date: "",
                start_date: "",
                is_selected: false,
                has_date: false
            },
        })
    }


    onPaymentSelected = (e, i) => {
        if(e.target.checked === true) {
            let temp_room_rate_mapping = this.state.property_room_rate_mapping;
            temp_room_rate_mapping[i]['is_selected'] = true;
            this.setState({ ...this.state, property_room_rate_mapping: temp_room_rate_mapping } );
        } else if (e.target.checked === false){
            let temp_room_rate_mapping = this.state.property_room_rate_mapping;
            temp_room_rate_mapping[i]['is_selected'] = false;
            this.setState({ ...this.state, property_room_rate_mapping: temp_room_rate_mapping } );
        }
        const param = {
            room_rate_channel_mapping: [this.state.property_room_rate_mapping[i]],
            room_id: this.state.channel_id
        }

        this.props.updateChannelRoomRate(param);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        return (
            <form onSubmit={this.onSubmit} name="general-form" id="general-form" >
                {
                    this.props.getChannelRoomRateData.map( (room) => (

                        <ExpansionPanel className="accordion-room-table" key={room.id} defaultExpanded>
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-caret-down" />}
                                aria-label="Expand">
                                <ColAtm>
                                    <ColAtm md={10} xl={11}>
                                        <i className="fas fa-bed room-rate-bed" />
                                        <LabelAtm labelClass="room-rate-list-title" text={room.name} />
                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item">
                                <ColAtm>
                                    <ColAtm>
                                        {this.state.property_room_rate_mapping.map((room_rate, key) => {
                                            return (

                                                room_rate.room_id === room.id &&
                                                <ExpansionPanelDetails className="room-rate-header MuiGrid-root MuiGrid-container MuiGrid-item" key={key}>
                                                    <ExpansionPanel className="accordion-rate-table" defaultExpanded>
                                                        <ExpansionPanelSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-label="Expand"
                                                            aria-controls="additional-actions1-content"
                                                            id="additional-actions1-header">
                                                            <ColAtm>
                                                                <ColAtm md={12}>
                                                                    <i className="fas fa-tags rate-tag-icon" />
                                                                    <LabelAtm labelClass="rate-title"  text={room_rate.name} />
                                                                </ColAtm>
                                                            </ColAtm>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                                            <Grid container sm={12}>
                                                                <Card className="w-100 channel-border">
                                                                    <CardContent>
                                                                        <Grid container sm={12} spacing={6}>
                                                                            <Grid container sm={12} className={"mt-12"}>
                                                                                <Grid item xs={6} sm={4} md={2}>
                                                                                    <FactCheckBox
                                                                                        checkboxId={room_rate.id+"payment"}
                                                                                        className="fact-box"
                                                                                        md={12}
                                                                                        blockClass="offer-fact-border-box fact-border-box gray-border"
                                                                                        labelIconClass="fact-icon-label"
                                                                                        iconClass={`${room_rate.is_selected ? "fas fa-toggle-on" : "fas fa-toggle-off"} offer-fact-icon fact-icon `}
                                                                                        key={room_rate.id}
                                                                                        value={room_rate.id+"payment"}
                                                                                        text={ room_rate.is_selected ? t('btn-on') : t('btn-off') }
                                                                                        tooltip={ t(room_rate.name) }
                                                                                        labelClass="fact-name"
                                                                                        htmlFor={room_rate.id+"payment"}
                                                                                        defaultChecked={ room_rate.is_selected }
                                                                                        onChange={(e) => { this.onPaymentSelected(e, key)}}
                                                                                    />
                                                                                </Grid>

                                                                                {!room_rate.is_selected &&

                                                                                <Grid item xs={6} md={3} className="channel-radio-center">
                                                                                    <label htmlFor={room_rate.id+"payment"}>
                                                                                        <Grid container>
                                                                                            <Grid item sm={12}>
                                                                                                <ColAtm className="mb-16">
                                                                                                    <LabelAtm variant="h6" component="h3" text={ t('enw-channels-channel_setup-booking-instant_confirmation-title') } />
                                                                                                </ColAtm>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </label>
                                                                                </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                        <img className="flip-transform" src="/assets/img/icons/channel-arrow.png" alt="channel-arrow"/>
                                                    </ExpansionPanel>
                                                </ExpansionPanelDetails>
                                            );
                                        })}

                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                }
            </form>
        );
    }
}


export default compose(withTranslation('common')) (ChannelRoomRateList);
