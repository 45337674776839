import {sendBookingMailConstants } from "../../../../../constants/Network/reservation.constants";

const initialState = {
    sendBookingMail_fetching :false,
    sendBookingMail : [],
    sendBookingMail_error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case sendBookingMailConstants.RESERVATION_UPDATE_MAIL_PENDING:
            return {
                sendBookingMail_fetching:true,
                sendBookingMail_error: {},
            };
        case sendBookingMailConstants.RESERVATION_UPDATE_MAIL_FULFILLED:
            return {
                ...state,
                sendBookingMail_fetching : false,
                sendBookingMail: action.payload,
                sendBookingMail_error: {},
            };
        case sendBookingMailConstants.RESERVATION_UPDATE_MAIL_REJECTED:
            return {
                ...state,
                sendBookingMail: [],
                sendBookingMail_error: action.payload,
                sendBookingMail_fetching : false,
            };
        case sendBookingMailConstants.RESERVATION_UPDATE_MAIL_CLEAR:
            return {
                ...state,
                sendBookingMail_fetching :false,
                sendBookingMail : [],
                sendBookingMail_error : {},
            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
