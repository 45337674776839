import {
    createChannelCancellationConstants,
    getChannelCancellationListConstants,
    updateChannelCancellationConstants} from "../../../../constants/Network/Channels/channelCancellation.constans";

const initialState = {
    fetching : false,
    error : '',
    submitError : [],
    channelCancellationData: [],
    channelCancellationListData: [],
    channelCancellationSubmitData: [],
    updateChannelData: [],
    updatePolicyError:  null,
    updatePolicy: [],
    updatePolicyStatus : null,
    updateStatus : null,
    updateError : null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getChannelCancellationListConstants.GET_CHANNEL_CANCELLATION_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                channelCancellationSubmitData: [],
                submitError: [],
                updatePolicyError:null,
                updateStatus:null,
                updatePolicyStatus:null,
                updateError:null,
            };
        case getChannelCancellationListConstants.GET_CHANNEL_CANCELLATION_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                channelCancellationSubmitData: [],
                submitError: [],
                channelCancellationListData: action.payload.data.rooms,
                updateError:null,
                updatePolicyError:null,
            };
        case getChannelCancellationListConstants.GET_CHANNEL_CANCELLATION_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                channelCancellationSubmitData: [],
                submitError: [],
                fetching: false,
                updateError:null,
                updatePolicyError:null,
            };
        case createChannelCancellationConstants.CREATE_CHANNEL_CANCELLATION_PENDING:
            return {
                ...state,
                fetching: false,
                submitError: [],
                channelCancellationSubmitData: [],
                updateError:null,
                updatePolicyError:null,
            };
        case createChannelCancellationConstants.CREATE_CHANNEL_CANCELLATION_FULFILLED:
            return {
                ...state,
                channelCancellationSubmitData: action.payload,
                updateStatus: action.payload.status,
                submitError: [],
                fetching: false,
                updateError:null,
                updatePolicyError:null,
            };
        case createChannelCancellationConstants.CREATE_CHANNEL_CANCELLATION_REJECTED:
            return {
                ...state,
                fetching: false,
                channelCancellationSubmitData: [],
                updateError:action.payload,
                updatePolicyError:null

            };

        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_PENDING:
            return {
                ...state,
                submitError: [],
                updateChannelData: [],
                updatePolicy: [],
                updateError:null,
                updatePolicyError:null,
                updatePolicyStatus :null
            };
        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_FULFILLED:
            return {
                ...state,
                updateChannelData: action.payload,
                submitError: [],
                updatePolicyStatus : action.payload.status
            };
        case updateChannelCancellationConstants.UPDATE_CHANNEL_CANCELLATION_POLICY_REJECTED:
            return {
                ...state,
                submitError: action.payload,
                updateChannelData: [],
                updateError:null,
                updatePolicyError:null,
                updatePolicyStatus :null

            };
        case "CLEAR_STATE" :
            return {
                ...initialState
            }
        default:
            return state
    }
}
