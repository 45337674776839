import {updateChannelConstants} from "../../../../constants/Network/Channels/updateChannel.constans";

const initialState = {
    fetching : false,
    error : '',
    channelUpdate: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case updateChannelConstants.CHANNEL_MAPPING_ADD_PENDING:
            return {
                ...state,
                channelUpdate: [],
                fetching: false,
            };
        case updateChannelConstants.CHANNEL_MAPPING_ADD_FULFILLED:
            return {
                ...state,
                fetching: false,
                channelUpdate: action.payload,
            };
        case updateChannelConstants.CHANNEL_MAPPING_ADD_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
