import React, {useEffect, useState} from 'react';
import RoomRateChannels from "../../../../molecules/RoomRateChannels/RoomRateChannels";
import {updateChannelRoomRate} from "../../../../../redux/actions/Network/Channel/channelRoomRateActions";
import {useDispatch,useSelector} from "react-redux";

let lastUpdete = null;
const RoomRateChannel = ({channel,rate}) => {

    const [ channelChecked,setChannelChecked] = useState(channel.is_checked);
    const updateChannelRoomRateStatus = useSelector( props => props.channelRoomRateUpdateReducer?.updateChannelRoomRate)
    const dispatch = useDispatch();

    const handleChannelUpdate = (e) => {

        if (e.target.value === undefined) return 0;

        let params = {
            room_rate_channel_mapping: [ {
                room_rate_mapping_id: rate.id,
                channel_id: e.target.value,
                is_selected: channelChecked ? false : true
            }]
        }
        dispatch( updateChannelRoomRate(params) )
        lastUpdete = rate.room_id+ channel.id + 'rate' + rate.id
    }

    useEffect( () => {

        if(updateChannelRoomRateStatus?.status === 200 && lastUpdete === rate.room_id+ channel.id + 'rate' + rate.id ){
            setChannelChecked( channelChecked ? false : true )
        }

    },[updateChannelRoomRateStatus])


    return(
            <RoomRateChannels
                checkboxId={rate.room_id+ channel.id + 'rate' + rate.id}
                key={rate.room_id+ channel.id + 'rate' + rate.id}
                className="fact-box"
                colClassname="offer-fact-col room-rate-channel-list"
                blockClass="channel-border-box offer-fact-border-box fact-border-box mb-16"
                src={`/assets/img/brands/${channel.id}.png`}
                value={channel.id}
                checked={channelChecked}
                factName={"hotwire"}
                text={channel.name}
                onClick={handleChannelUpdate}
                labelClass="fact-name"
                htmlFor={rate.room_id+ channel.id + 'rate' + rate.id}
            />

    )

}

export default RoomRateChannel
