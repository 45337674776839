import React, {Component}   from 'react';
import PageHeaderAtm from "../../../atomic/PageHeader/PageHeader";
import Container from "@material-ui/core/Container";
import ColAtm from "../../../atomic/Col/Col";
import {PageTitle} from "../../../atomic/PageHeader/PageTitle";
import {Animated} from "react-animated-css";
import ReservationList from "./ReservationList";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {NETWORK} from "../../../../constants/appUrls.constants";
import {connect} from "react-redux";
import {
    getReservationList,
    getReservationListFilter
} from "../../../../redux/actions/Network/Reservation/reservationList.action";
import { getPropertyName } from '../../../../utils';
import { USER_MENU } from '../../../../constants/api.constants';
import axios from 'axios';
import { getProperty } from '../../../../utils';
import { getAppLang } from '../../../../utils';

let t =null;

class Reservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyName : '',
        };
        t = props.t;
    }

    componentDidMount() {
        this.props.getReservationList();
        this.props.getReservationListFilter();

        const params = {
            params: {
                property_id: getProperty(),
            }
        }
        axios.post(USER_MENU, params, {
            headers: {
                "Accept": "application/json",
                authToken: localStorage.getItem('auth'),
                "Content-Type": "application/json",
                "language": getAppLang(),
            }
        }).then((result) => {
            this.setState({propertyName: result.data.data.property_list[0].name + " - "});
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        return (
            <>
                <PageTitle title={ t('enw-reservation-page_title') } />
                <PageHeaderAtm exitText={ t('btn-dashboard') } backButton={NETWORK} text={ this.state.propertyName + t('enw-reservation-title') } />
                <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                    <Container maxWidth="xl">
                        <div className="container-width pb-0">
                            <ColAtm className="booking-list">
                                {
                                    <ReservationList
                                        reservationList={this.props.reservationList}
                                        fetching={this.props.fetching}
                                        reservationListFilter={this.props.reservationListFilter}
                                    />
                                }
                            </ColAtm>
                        </div>
                    </Container>
                </Animated>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        fetching : state.reservationListReducer.fetching,
        reservationList : state.reservationListReducer.reservationList,
        reservationListFilter : state.reservationListReducer.reservationListFilter,
        fetchingListFilter : state.reservationListReducer.fetchingListFilter,
        error : state.reservationListReducer.error
    }
};

const mapDispatchToProps = {
    getReservationList,
    getReservationListFilter
};

export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(Reservation));
