import axios from 'axios';
import { getUserToken,getProperty ,getAppLang} from "../../../utils";
import { GET_PAYMENT_DASHBOARD } from "../../../constants/api.constants";

export function getPaymentDashboard() {

    const requestOptions = {
        headers: { 'Content-Type': 'application/json',  'authToken': getUserToken(), 'language' : getAppLang()},
        body: {
            params: {
                property_id: getProperty()
            }
        }
    };

    return dispatch => {
        dispatch({
            type: 'GET_PAYMENT_DASHBOARD',
            payload: axios.post(GET_PAYMENT_DASHBOARD, requestOptions.body,{headers: requestOptions.headers})
                .then(response => response.data.data)
        })
    }
}

