import {offersListConstants} from "../../../constants/Offer/offerList.constants";

const initialState = {
    fetching : true,
    make_update :false,
    status : false,
    updateStatus : false,
    errorUpdateStatus : false,
    errorUpdateError : false,
    error : '',
    get_offer_list: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case offersListConstants.OFFER_LIST_PENDING:
            return {
                ...state,
                fetching: true,
                status: false
            };
        case offersListConstants.OFFER_LIST_FULFILLED:
            return {
                ...state,
                fetching: false,
                status: true,
                get_offer_list: action.payload,
            };
        case offersListConstants.OFFER_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        default:
            return state
    }
}
