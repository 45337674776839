import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import ColAtm from "../../../atomic/Col/Col";
import LabelAtm from "../../../atomic/Label/Label";
import ColorPicker from "rc-color-picker";
import Button from "@material-ui/core/Button";
import BlockAtm from "../../../atomic/Block/Block";
import Card from "@material-ui/core/Card";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ButtonAtm from "../../../atomic/Button/Button";
import Toolbar from "@material-ui/core/Toolbar";
import {Hidden, TextField} from "@material-ui/core";
import Scrollchor from "react-scrollchor";
import {isDefined} from "../../../../utils";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

let t=null;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`room-rate-tabpanel-${index}`}
            aria-labelledby={`room-rate-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `room-rate-tab-${index}`,
        'aria-controls': `room-rate-tabpanel-${index}`,
    };
}

class FromBrandLast extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title : this.props.get_property_brand.title ? this.props.get_property_brand.title : {},
            color_code_1 : this.props.get_property_brand.color_code_1 ? this.props.get_property_brand.color_code_1 : '',
            color_code_2 : this.props.get_property_brand.color_code_2 ? this.props.get_property_brand.color_code_2 : '',
            color_code_3 : this.props.get_property_brand.color_code_3 ? this.props.get_property_brand.color_code_3 : '',
            photo : this.props.get_property_brand.photo ? this.props.get_property_brand.photo : '',
            logo_name : this.props.get_property_brand.logo_name ? this.props.get_property_brand.logo_name : '',
            imgRef: "",
            colors : this.props.get_property_brand.color_codes && Object.keys(this.props.get_property_brand.color_codes).length > 0 ? this.props.get_property_brand.color_codes :
                [
                    {color_number : 1, color_code : ''},
                    {color_number : 2, color_code : ''}
                ],
            titleCharCount : Object.keys(this.props.get_property_brand.title).length > 0  ? this.props.get_property_brand.title.map(value =>{
                   return value.description === null ? 0 : value.description.length
                }) : [],
            titleMaxCharCount : 1000,
            tabValue : 0,
            brandDelete : !this.props.get_property_brand.logo_file_ext || (this.props.get_property_brand.logo_file_ext === null && this.props.get_property_brand.logo_path === null) ? false : true
        };
        t = props.t;
    }



    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onBrandSubmitForm(this.state);
    };

    onFileChange = event => {
        const file = event.target.files[0];

        if(isDefined(file)) {
            this.setState({
                imgRef: URL.createObjectURL(file),
                photo: event.target.files[0]
            });
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleChangeColor = index => (e) => {

        const colors = [...this.state.colors];
        colors[index]['color_code'] = e.color;
        this.setState({
            colors : colors
        });

    }

    handleAddColor = () => {

        let colors = this.state.colors;
        let colorLength = this.state.colors.length + 1;
        let newColors = [
                ...colors,
                { color_number : (colorLength), color_code: '#ffffff'}
            ];
        this.setState({ colors : newColors});

    }

    handleDeleteColor = () => {

        let colorLength = this.state.colors.length;
        let colors = this.state.colors.filter( (color, index) => {
            return (colorLength - 1) !== index;
        });
        this.setState({
            colors: colors
        });

    }

    handleTabChange = ( event, newValue ) => {
        this.setState( {tabValue : newValue});
    };

    handleChangeCharCount = index => (e) => {

        let tempCount = this.state.titleCharCount;
        tempCount[index] = e.target.value.length;

        this.setState({
            titleCharCount:tempCount
        });

        let values = this.state.title;
        values[index]["description"] = e.target.value;

    };

    componentDidUpdate(prevProps) {
        if ((prevProps.brandReducer.brandLogoStatus !== this.props.brandReducer.brandLogoStatus) && this.props.brandReducer.brandLogoStatus === 200) {
           this.setState({
               imgRef : "",
               logo_name : "",
               brandDelete : false
           });
        }

        if((prevProps.brandReducer.updateData !== this.props.brandReducer.updateData) && this.props.brandReducer.updateData === 200){
            this.setState( {brandDelete : true});
        }
    }

    render() {
        const firstColor = this.state.colors[0].color_code
        const secondColor = this.state.colors[1].color_code

        const colorNumbers = { 0 : 'one', 1 : 'two', 2 : 'three', 3 : 'four', 4 : 'five'};

        const descriptionList = this.state.title.map((title, index)=>{

            return (
                <TabPanel value={this.state.tabValue} index={index} className="w-100">
                    <TextField name={title.language_code}
                               type="text"
                               variant="outlined"
                               className="text-capitalize"
                               value={title.description}
                               onChange={this.handleChangeCharCount(index)}
                               label={ t('enw-input-welcome_to_slogan') + " - " + title.language_code }
                               multiline={true}
                               fullWidth
                               required={false}
                               rows={2}
                               inputProps={{maxLength : this.state.titleMaxCharCount}} />
                    <div style={{ width: '100%'}}>
                        <p style={{ float : 'right'}}> { this.state.titleCharCount[index] }/ {this.state.titleMaxCharCount}</p>
                    </div>
                </TabPanel>
            );
        })

        const descriptionTabs = this.state.title.map((title,value)=>{
            const flag = <Grid container className="brand-desc-flag" spacing={1}> <Grid item sm={3}><img src={`${process.env.PUBLIC_URL}/assets/img/language/${title.language_code}.svg`} alt={title.language_code} /> </Grid> <Grid item sm={2}> {title.language_code}</Grid></Grid>
            return (
                    <Tab label={ flag } {...a11yProps(value)} />
            );
        })
        return (
            <ColAtm md={12} lg={12} xl={12}>
                <BlockAtm md={6} lg={6} xs={12}  >
                    <LabelAtm variant="h6" component="h2" text={ t('enw-general-brand-subtitle') } style={{paddingTop: 32}} />
                    <Hidden mdUp>
                        <ExpansionPanel className="location-map-accordion">
                            <ExpansionPanelSummary
                                expandIcon={<i className="fas fa-arrow-down mobile-map-arrow" />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header">
                                <ColAtm>
                                    <ColAtm md={11}>
                                        <i className="fas fa-browser mobile-map-icon" />
                                        <LabelAtm labelClass="room-rate-list-title" text={t('btn-preview')} />
                                    </ColAtm>
                                </ColAtm>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="room-rate-detail MuiGrid-root MuiGrid-container MuiGrid-item">
                                <BlockAtm md={6} lg={6} xs={12} className="fullWidthRow" style={{ backgroundColor: '#f4f7fc'}} >
                                    <BlockAtm>
                                        <Card className="mobile-brand-card brand-card">
                                            <ColAtm sm={12} className={"brand-preview"}>
                                                <ColAtm sm={12}>
                                                    <div className="flex-grow-1">
                                                        <Toolbar className="offer-top-menu">
                                                            <ColAtm sm={12}>
                                                                <ColAtm xs={7} sm={5} className="offer-menu-left">
                                                                    <Grid className="brand-logo-box">
                                                                        {
                                                                            this.state.imgRef !== ""  || this.state.logo_name !== "" ?
                                                                                this.state.imgRef !== "" ?
                                                                                    <img src={this.state.imgRef} alt="logo" />
                                                                                    :
                                                                                    <img src={this.state.logo_name} alt="logo" />

                                                                                :
                                                                                <h2>Logo</h2>
                                                                        }
                                                                    </Grid>
                                                                </ColAtm>
                                                                <ColAtm xs={5} sm={7} className="offer-brand-right" style={{backgroundColor: isDefined(firstColor) ? firstColor : "#e2e2e2" }}>
                                                                    <Hidden smDown>
                                                                        <Scrollchor to="#photos" className="nav-link">
                                                                            <Button color="inherit"> { t('enw-general-information-subtitle') } </Button>
                                                                        </Scrollchor>
                                                                    </Hidden>
                                                                    <Hidden smDown>
                                                                        <Scrollchor to="#footer" className="nav-link">
                                                                            <Button color="inherit"> { t('enw-general-tab-communication') }</Button>
                                                                        </Scrollchor>
                                                                    </Hidden>
                                                                </ColAtm>
                                                            </ColAtm>
                                                        </Toolbar>
                                                    </div>
                                                </ColAtm>
                                                <ColAtm sm={5} className="offer-top-left">
                                                    <ColAtm sm={12} className="offer-carousel-height">
                                                        <img src="/assets/img/examples/brand-image.jpg" style={{ width: '130%', height: "260px", marginTop: "10px" }} alt="brand-image" />
                                                    </ColAtm>
                                                </ColAtm>
                                                <ColAtm sm={7} className="brand-top-right" style={{backgroundColor: isDefined(firstColor) ? firstColor : "#e2e2e2" }}>
                                                    <ColAtm sm={2}>
                                                    </ColAtm>
                                                    <ColAtm sm={6} className="m-auto">
                                                        <ColAtm>
                                                            <LabelAtm labelClass="offer-slogan-text" text={this.props.hotel_name} />
                                                        </ColAtm>
                                                        <ColAtm>
                                                            <ButtonAtm
                                                                className={ "brand-show-button"}
                                                                fullwidth={true}
                                                                buttontext={ "Button" }
                                                                style={{backgroundColor: isDefined(secondColor) ? secondColor : "#eaeaea" }}
                                                            />
                                                        </ColAtm>
                                                    </ColAtm>
                                                </ColAtm>
                                                <i className="far fa-long-arrow-down brand-scroll-icon" style={{color: isDefined(this.state.colors[2]) ? this.state.colors[2].color_code : "#e2e2e2"}} />
                                            </ColAtm>
                                        </Card>
                                    </BlockAtm>
                                </BlockAtm>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Hidden>
                    <form onSubmit={this.onSubmit} name="general-form" id="general-form" autoComplete="off">
                    <ColAtm>
                            <ColAtm md={11} lg={11} xl={11}>
                                <Grid className="brand-col" item xs={12}>
                                    <ColAtm sm={12} className="d-block mb-55">
                                        <LabelAtm variant="h2" text={ t('enw-input-title-logo') } labelClass="mb-20" />
                                        <Grid container spacing={3}>
                                            <Grid item xs={9} sm={4} md={3}>
                                                <Grid className="brand-logo-box">
                                                    {
                                                        this.state.imgRef !== "" ?
                                                            <img src={this.state.imgRef} alt="logo" />
                                                            :
                                                            <img src={this.state.logo_name} alt="logo" />
                                                    }
                                                </Grid>
                                            </Grid>
                                            {
                                                this.state.brandDelete ? ( <Grid item xs={3} sm={1} className="brand-logo-upload">
                                                    <ButtonAtm className="delete-bed-button" fullwidth={true}
                                                               startIcon={<i className="fas fa-trash" />}
                                                               onClick={() => this.props.clearBrandLogo()}
                                                               disabled={this.props.brandReducer.brandLogoFetching}
                                                    />
                                                </Grid>) : ''
                                            }
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={5} className="brand-logo-upload">
                                                <input
                                                    className="d-none"
                                                    accept="image/png, image/jpeg"
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={this.onFileChange.bind(this)}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        className="room-rate-add soft-blue-button m-auto radius-8"
                                                        component="span"
                                                        startIcon={<i className="fas fa-cloud-upload-alt" />}>
                                                        { t('btn-logo_upload') }
                                                    </Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </ColAtm>
                                    <ColAtm className="d-block mb-55">
                                        <LabelAtm variant="h2" text={ t('enw-input-title-colors') } labelClass="mb-20" />
                                        <ColAtm className="color-select">
                                            {this.state.colors.map((color, index) => ( <ColAtm xs={4} sm={2} className="d-block">
                                                <LabelAtm component="h3" text={ t('enw-input-color_'+ colorNumbers[index] ) } />
                                                <ColorPicker
                                                    animation="slide-up"
                                                    defaultColor=""
                                                    color={color.color_code}
                                                    onChange={this.handleChangeColor(index)}
                                                />
                                            </ColAtm>))}
                                            {this.state.colors.length > 2 && (<>
                                                <ColAtm xs={4} sm={1} className="form-group" style={{ paddingTop:45,marginBottom:8,marginRight:45}}>
                                                    <ButtonAtm className="delete-bed-button" fullwidth={true}
                                                               startIcon={<i className="fas fa-minus-circle" />}
                                                               onClick={this.handleDeleteColor}
                                                    />
                                                </ColAtm>
                                            </> ) }
                                            {this.state.colors.length < 5 ? ( <ColAtm xs={4} sm={1} className="form-group" style={{paddingTop:45,marginBottom:8,marginLeft: '-25px'}}>
                                                <ButtonAtm className="new-bed-button w-100"
                                                           startIcon={<i className="fas fa-plus-circle" />}
                                                           onClick={this.handleAddColor}
                                                />
                                            </ColAtm>) : ('')}
                                        </ColAtm>
                                    </ColAtm>
                                    <ColAtm className="d-block mb-55">
                                        <LabelAtm variant="h2" text={ t('enw-input-title-slogan') } />
                                        <ColAtm>

                                            <AppBar className="room-rate-tab-title" position="static">
                                                <Tabs className="room-rate-tabFont"
                                                      value={this.state.tabValue}
                                                      onChange={this.handleTabChange}
                                                      aria-label="room-rate-tab"
                                                      variant="scrollable"
                                                      scrollButtons="on"
                                                >
                                                    {descriptionTabs}
                                                </Tabs>
                                            </AppBar>
                                            {descriptionList}
                                        </ColAtm>
                                    </ColAtm>
                                </Grid>
                            </ColAtm>
                    </ColAtm>
                    </form>
                </BlockAtm>
                <BlockAtm md={6} lg={6} xs={12} className="fullWidthRow mobile-d-none" style={{ backgroundColor: '#f4f7fc', height: '800px'}} >
                    <BlockAtm>
                        <Card className="brand-card">
                            <ColAtm sm={12} className={"brand-preview"}>
                                <ColAtm sm={12}>
                                    <div className="flex-grow-1">
                                        <Toolbar className="offer-top-menu">
                                            <ColAtm sm={12}>
                                                <ColAtm xs={7} sm={5} className="offer-menu-left">
                                                    <Grid className="brand-logo-box">
                                                        {
                                                            this.state.imgRef !== ""  || this.state.logo_name !== "" ?
                                                            this.state.imgRef !== "" ?
                                                                <img src={this.state.imgRef} alt="logo" />
                                                                :
                                                                <img src={this.state.logo_name} alt="logo" />

                                                                :
                                                                <h2>Logo</h2>
                                                        }
                                                    </Grid>
                                                </ColAtm>
                                                <ColAtm xs={5} sm={7} className="offer-brand-right" style={{backgroundColor: isDefined(firstColor) ? firstColor : "#e2e2e2" }}>
                                                    <Hidden smDown>
                                                        <Scrollchor to="#photos" className="nav-link">
                                                            <Button color="inherit"> { t('enw-general-information-subtitle') } </Button>
                                                        </Scrollchor>
                                                    </Hidden>
                                                    <Hidden smDown>
                                                        <Scrollchor to="#footer" className="nav-link">
                                                            <Button color="inherit"> { t('enw-general-tab-communication') }</Button>
                                                        </Scrollchor>
                                                    </Hidden>
                                                </ColAtm>
                                            </ColAtm>
                                        </Toolbar>
                                    </div>
                                </ColAtm>
                                <ColAtm sm={5} className="offer-top-left">
                                    <ColAtm sm={12} className="offer-carousel-height">
                                        <img src="/assets/img/examples/brand-image.jpg" style={{ width: '130%', height: "260px", marginTop: "10px" }} alt="brand-image" />
                                    </ColAtm>
                                </ColAtm>
                                <ColAtm sm={7} className="brand-top-right" style={{backgroundColor: isDefined(firstColor) ? firstColor : "#e2e2e2" }}>
                                    <ColAtm sm={2}>
                                    </ColAtm>
                                    <ColAtm sm={6} className="m-auto">
                                        <ColAtm>
                                            <LabelAtm labelClass="offer-slogan-text" text={this.props.hotel_name} />
                                        </ColAtm>
                                        <ColAtm>
                                            <ButtonAtm
                                                className={ "brand-show-button"}
                                                fullwidth={true}
                                                buttontext={ "Button" }
                                                style={{backgroundColor: isDefined(secondColor) ? secondColor : "#eaeaea" }}
                                            />
                                        </ColAtm>
                                    </ColAtm>
                                </ColAtm>
                                <i className="far fa-long-arrow-down brand-scroll-icon" style={{color: isDefined(this.state.colors[2]) ? this.state.colors[2].color_code : "#e2e2e2"}} />
                            </ColAtm>
                        </Card>
                    </BlockAtm>
                </BlockAtm>
            </ColAtm>
        );
    }
}

FromBrandLast.propTypes = {};

export default compose(withTranslation('common'))(FromBrandLast);
