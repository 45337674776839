import React, { useEffect, useState } from "react";
import Accommodation from "./Accommodation";


export default function Accommodations(props) {
    useEffect(() => {});
    const [roomRates] = useState(props.roomRates);


    return (
        <>
        {
            roomRates.map( ( roomRate ) => (
                <Accommodation roomRate={roomRate}
                               onChangeUpdateData={props.onChangeUpdateData}
                               updatedData={ props.updatedData }
                               channelAvailability={ props.channelAvailability }
                               showStopSell={ props.showStopSell }
                               showMinStay={ props.showMinStay }
                />
            ) )
        }
        </>
    );
}
