import React, {Component}       from 'react';
import {Link}                   from "react-router-dom";
import {Animated}               from "react-animated-css";

import {PageTitle}              from "../../../atomic/PageHeader/PageTitle";
import RowAtm                   from "../../../atomic/Row/Row";
import ExecutiveForm            from "./components/ExecutiveForm";
import Container                from "@material-ui/core/Container";
import FooterAtm                from "../../../atomic/Footer/Footer";
import Menu                     from "../../Global/Layout/Header/Submenu/General";
import {
    getExecutiveContact,
    addExecutiveContact,
    deleteExecutiveContact,
    updateExecutiveContact,clearExecutiveContact}    from "../../../../redux/actions/Content/ExecutiveContact/executiveContactAction";
import {connect}                from "react-redux";
import ErrorModal from "../../../organisms/Modal/ErrorModal/ErrorModal"
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {history} from "../../../../utils/history";
import EmployeesContactPageHeader from "../../../atomic/PageHeader/EmployeesContactPageHeader";
import {CONTENT, CONTENT_COMMUNICATION, CONTENT_LOCATION} from "../../../../constants/appUrls.constants";
let t=null;

class ExecutiveContact extends Component {

    constructor(props){
        super(props);
        this.state = {
            redirect: false,
            showSuccessHeader: false,
        };
        t = props.t;
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f7fc";
        this.props.getExecutiveContact();
    }

    componentWillUnmount() {
        this.props.clearExecutiveContact();
    }

    showMessage = () => {

        if(this.props.error &&
            Object.keys(this.props.error).length > 0 &&
            this.props.error.response
        ){
            let message = this.props.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    onClickNextButton = () => {
            this.setState({
                redirect: true,
                showSuccessHeader:true
            })

    };

    headerStatusShow = () =>{
        return  <EmployeesContactPageHeader backButton={CONTENT}
                               exitText={ t('btn-dashboard') }
                               text={ t('enw-general-title')}
                               error={this.props.error}
                               fetching={this.props.fetching}
        />
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            history.push(CONTENT_LOCATION);
        }
    }

    render() {

        return (
        <>
            <PageTitle title={ t('enw-general-employees-page_title') } />
            {
                this.headerStatusShow()
            }

            <Menu contactColClass="active-menu-bg" />
            <Animated animationIn="bounceInRight" animationInDuration={1000} isVisible={true}>
                <Container maxWidth="xl">
                    <div className="container-width">
                        <RowAtm>
                                <ExecutiveForm  fetching={this.props.fetching}
                                                buttonPassiveStatus={this.props.buttonPassiveStatus}
                                                executive_type={this.props.executive_type}
                                                executives={this.props.executives}
                                                updateExecutiveContact={this.props.updateExecutiveContact}
                                                addExecutiveContact={this.props.addExecutiveContact}
                                                deleteExecutiveContact={this.props.deleteExecutiveContact}
                                                getExecutiveContact={this.props.getExecutiveContact}
                                                executivesFirst={this.props.executivesFirst}
                                                country_code={this.props.country_code}


                                />
                            {
                                this.showMessage()
                            }
                        </RowAtm>
                    </div>
                </Container>
            </Animated>
            <FooterAtm
                buttonBackText={ t('btn-back') }
                iconRightClassName="fas fa-arrow-right"
                buttonBackStyle={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                }}
                buttonNextStyle={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                }}
                buttonNextText={ t('btn-next') }
                backTo={CONTENT_COMMUNICATION}
                nextDisabled={this.props.buttonPassiveStatus}
                backDisabled={this.props.buttonPassiveStatus}
                component={Link}
                nextComponent={Link}
                iconLeftClassName="fas fa-arrow-left"
                onClick={this.onClickNextButton}
            />
            {this.renderRedirect()}
        </>
        );
    }
}
const mapStateToProps = (props) => {

    return{

        fetching : props.ExecutiveContactReducers.fetching,
        buttonPassiveStatus : props.ExecutiveContactReducers.buttonPassiveStatus,
        updateStatus : props.ExecutiveContactReducers.updateStatus,
        executive_type : props.ExecutiveContactReducers.executive_type,
        executives : props.ExecutiveContactReducers.executives,
        executivesFirst : props.ExecutiveContactReducers.executivesFirst,
        country_code : props.ExecutiveContactReducers.country_code,
        changeStatus : props.ExecutiveContactReducers.changeStatus,
        error : props.ExecutiveContactReducers.error
    }
};

const mapDispatchToProps = {
    getExecutiveContact,
    updateExecutiveContact,
    addExecutiveContact,
    deleteExecutiveContact,
    clearExecutiveContact
};


export default compose(withTranslation('common')) (connect(mapStateToProps, mapDispatchToProps )(ExecutiveContact));
