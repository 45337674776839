import { roomSizeTypesConstants } from "../../../../constants/Network/RoomAndRates/roomSizeTypes.constants";

const initialState = {
    fetching : false,
    roomSizeTypes : [],
    error : {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case roomSizeTypesConstants.GET_ROOM_SIZE_TYPES_PENDING:
            return {
                ...state,
                fetching: true,
                error : {},
            };
        case roomSizeTypesConstants.GET_ROOM_SIZE_TYPES_FULFILLED:
            return {
                ...state,
                fetching: false,
                roomSizeTypes: action.payload
            };
        case roomSizeTypesConstants.GET_ROOM_SIZE_TYPES_REJECTED:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        default:
            return state
    }
}
