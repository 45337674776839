import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import moment from "moment";
import {isDefined} from "../../../../../utils";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function StopSellPrice(props) {
    useEffect(() => {},[]);

    const {key, value} = props.value
    const {updatedData} = props

    const [price, setPrice] = useState( updatedData !== undefined && updatedData.hasOwnProperty(key) ? updatedData[key] : value);
    const [hasData, setHasData] = useState(updatedData !== undefined && updatedData.hasOwnProperty(key) ? true : false);

    const onChange = event => {
        let newPrice = event.target.checked === true ? 1 : 0;
        let priceKey = event.target.name;
        setPrice( newPrice );
        setHasData (false);
        props.onChangeUpdateData(priceKey, newPrice);
    };

    return (
        <Grid className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) ? 'weekend-inputs' : ""} inventory-price t-center`}>
            <FormControlLabel
                control={<Checkbox
                    className={(hasData && isDefined(price)) ? "default-border" : ""}
                    icon={<i className="fal fa-square" />}
                    checkedIcon={<i className="fal fa-times-square" />}
                    name={key}
                    checked={price}
                    type="checkbox"
                    onChange={onChange}
                />
                }
            />

            <p className={`${(moment(props.date).locale('de').weekday() == 5 || moment(props.date).locale('de').weekday() == 6 ) && (isDefined(props.channelAvailability) && props.channelAvailability.id === 3 ? "guaranteed-weekend-radius" : isDefined(props.channelAvailability) && props.channelAvailability.id === 2 ? "weekend-radius-limited" : 'weekend-radius')} null-inventory`}> &nbsp;</p>
        </Grid>
    );
}
