export const roomFact = {
    ROOM_FACT_LIST_PENDING  : 'ROOM_FACT_LIST_PENDING',
    ROOM_FACT_LIST_FULFILLED: 'ROOM_FACT_LIST_FULFILLED',
    ROOM_FACT_LIST_REJECTED : 'ROOM_FACT_LIST_REJECTED',

    ROOM_FACT_UPDATE_PENDING  : 'ROOM_FACT_UPDATE_PENDING',
    ROOM_FACT_UPDATE_FULFILLED: 'ROOM_FACT_UPDATE_FULFILLED',
    ROOM_FACT_UPDATE_REJECTED : 'ROOM_FACT_UPDATE_REJECTED',

    ROOM_FACT_FEATURE_UPDATE_PENDING  : 'ROOM_FACT_FEATURE_UPDATE_PENDING',
    ROOM_FACT_FEATURE_UPDATE_FULFILLED: 'ROOM_FACT_FEATURE_UPDATE_FULFILLED',
    ROOM_FACT_FEATURE_UPDATE_REJECTED : 'ROOM_FACT_FEATURE_UPDATE_REJECTED',

    ROOM_FACT_RECOMMENDED_UPDATE_PENDING : 'ROOM_FACT_RECOMMENDED_UPDATE_PENDING',
    ROOM_FACT_RECOMMENDED_UPDATE_FULFILLED: 'ROOM_FACT_RECOMMENDED_UPDATE_FULFILLED',
    ROOM_FACT_RECOMMENDED_UPDATE_REJECTED: 'ROOM_FACT_RECOMMENDED_UPDATE_REJECTED',

    ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_PENDING : 'ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_PENDING',
    ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_FULFILLED: 'ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_FULFILLED',
    ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_REJECTED: 'ROOM_FACT_RECOMMENDED_FEATURE_UPDATE_REJECTED',

    ROOM_FACT_UPDATE_CLEAR : 'ROOM_FACT_UPDATE_CLEAR',
}