import React from "react";
import SuccessModal from "../../../../organisms/Modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";
import {NoSsr, withStyles} from "@material-ui/core";
import {isDefined} from "../../../../../utils";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import ColAtm from "../../../../atomic/Col/Col";
import {BASE_WEB} from "../../../../../constants/api.constants";

let t=null;

const styles = theme => ({
    root: {
        margin: 'auto',
        height: "100%"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className="close-button" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ShowLiveWeb extends React.Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    showMessages = () => {

        if( this.props.saveEditContentWeb.updateEditWebData && this.props.saveEditContentWeb.updateEditWebData.status === 200 ){

            var webLanguage = this.props.getEditContentWeb.editWebData.default_language;

            if(isDefined(this.props.getEditContentWeb.editWebData.property_web_language)){
                webLanguage = this.props.getEditContentWeb.editWebData.property_web_language.code;
            }

            const url = BASE_WEB+this.props.getEditContentWeb.editWebData.token+'/'+webLanguage;

            if (this.props.show_success_modal) {
                return  <SuccessModal status={true} message={t('enw-myw-site_save_msg')}/>
            } else if (!this.props.show_success_modal) {
                return <Dialog className="web-preview-dialog"
                               TransitionComponent={Transition}
                               aria-labelledby="customized-dialog-title"
                               fullScreen
                               open={this.props.open}
                               onClose={this.props.handleClose}
                >
                    <DialogTitle className="red-bottom-border"  id="rate-plan" onClose={this.props.handleClose}>
                        { t('btn-preview') }
                    </DialogTitle>
                    <DialogContent>
                        <ColAtm container className="h-100" direction="row" md={12}>
                            <iframe className="site-preview-frame" src={url} title="site-preview-frame" />
                        </ColAtm>
                    </DialogContent>
                </Dialog>
            }


        } else if (isDefined(this.props.saveEditContentWeb.error.response) ) {
            let message = this.props.saveEditContentWeb.error.response.data.message
            return  <ErrorModal status={true} message={message}/>
        }
    }

    render() {
        return (
            <NoSsr>
                {
                    !this.props.saveEditContentWeb.fetching &&
                    <NoSsr>
                        {
                            this.showMessages()
                        }
                    </NoSsr>
                }
            </NoSsr>
        )
    }
}

export default compose(withTranslation('common')) (ShowLiveWeb);
