import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";

import {withTranslation} from "react-i18next";
import ErrorModal from "../../../../organisms/Modal/ErrorModal/ErrorModal";
import AwardsCertificates from "./AwardsCertificates";
import {isDefined} from "../../../../../utils";

let t=null

class AwardsCertificatesList extends Component {

    constructor(props){
        super(props);
        t = props.t;
    }

    showMessage = () => {

        if(isDefined(this.props.awardsCertificatesUpdateError) && isDefined(this.props.awardsCertificatesUpdateError.response) && this.props.awardsCertificatesUpdateError?.response?.status !== 200)
        {
            let message = this.props.awardsCertificatesUpdateError?.response.data.message
            return  <ErrorModal status={true} message={t(message)}/>
        }
    }

    render() {
        return (
            <div>
                <AwardsCertificates
                    clearErrorMessage = {this.props.clearErrorMessage}
                    updateAwardsCertificates={this.props.updateAwardsCertificates}
                    awardsCertificatesReducers={this.props.awardsCertificatesReducers}
                    getAwardsCertificates={this.props.getAwardsCertificates}
                    deleteAwardsCertificates={this.props.deleteAwardsCertificates}

                />
                {
                    this.showMessage()
                }
            </div>
        );
    }
}


const mapStateToProps = (props) => {
    return {
        awardsCertificatesUpdateError : props.awardsCertificatesReducers?.awardsCertificatesUpdateStatusError,
    }
};

export default compose(withTranslation('common'))(connect(mapStateToProps)(AwardsCertificatesList));
